import {
    useBrandConfigurationCollectionChangedSubscription,
    useBrandConfigurationVersionChangedSubscription,
    useProgramVersionsAndBcCollectionsEditorChangedSubscription
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const useEditorBrandConfigurationVersionSubscriptions = (accountId: string): void => {
    useBrandConfigurationVersionChangedSubscription({
        variables: { accountId }
    });

    useBrandConfigurationCollectionChangedSubscription({
        variables: {}
    });

    useProgramVersionsAndBcCollectionsEditorChangedSubscription({
        variables: { accountId }
    });
};
