import type { PlayerAspectRatio } from "../../../../common/aspectRatioUtils";
import type { MediaNode } from "@sundaysky/landing-page-skeleton-types";
import type { GqlClientEditorProgramForEmbedVideoBuilderQuery, GqlClientEditorProgramForLandingPageBuilderQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getLocalIdFromGqlId } from "../../../../common/idUtils";

export function getPosterImage(aspectRatio: PlayerAspectRatio, defaultPosterImages: Map<PlayerAspectRatio, MediaNode>): MediaNode {
    if (aspectRatio === "vertical") {
        return defaultPosterImages.get("vertical")!;
    }
    if (aspectRatio === "vertical-4-5") {
        return defaultPosterImages.get("vertical-4-5")!;
    }
    return defaultPosterImages.get("horizontal")!;
}

export const defaultPosterImageAssetNameList: { [key in PlayerAspectRatio]?: string } = {
    ["horizontal"]: "Poster Image",
    ["vertical"]: "Poster Image Vertical",
    ["vertical-4-5"]: "Poster Image Vertical 4_5"

};

export function getDefaultPosterImagesPerProgram(editorProgram: GqlClientEditorProgramForLandingPageBuilderQuery["editorProgram"] | GqlClientEditorProgramForEmbedVideoBuilderQuery["editorProgram"]) {
    const posterImageAsset = editorProgram.programVersionDraft.assetLibrary.media.find(asset => asset.name === defaultPosterImageAssetNameList.horizontal);
    const posterImageVerticalAsset = editorProgram.programVersionDraft.assetLibrary.media.find(asset => asset.name === defaultPosterImageAssetNameList.vertical);
    const posterImageVertical4on5Asset = editorProgram.programVersionDraft.assetLibrary.media.find(asset => asset.name === defaultPosterImageAssetNameList["vertical-4-5"]);
    const defaultPosterImages: Map<PlayerAspectRatio, MediaNode> = new Map();
    defaultPosterImages.set("horizontal", { url: posterImageAsset?.mediaUrl ?? "", platformId: getLocalIdFromGqlId(posterImageAsset?.id) || "" });
    defaultPosterImages.set("vertical", { url: posterImageVerticalAsset?.mediaUrl ?? "", platformId: getLocalIdFromGqlId(posterImageVerticalAsset?.id) || "" });
    defaultPosterImages.set("vertical-4-5", { url: posterImageVertical4on5Asset?.mediaUrl ?? "", platformId: getLocalIdFromGqlId(posterImageVertical4on5Asset?.id) || "" });
    return defaultPosterImages;
}

export const updatePosterImageValue = (currentPosterImage: MediaNode, defaultPosterImages: Map<PlayerAspectRatio, MediaNode>, aspectRatio: PlayerAspectRatio): MediaNode => {
    const defaultPlatformIds: string[] = Array.from(defaultPosterImages.values()).map(image => image.platformId);
    const isCurrentImageDefaultPosterImage = currentPosterImage.platformId in defaultPlatformIds;
    if (isCurrentImageDefaultPosterImage) {
        return defaultPosterImages.get(aspectRatio) || defaultPosterImages.get("horizontal");
    }
    return currentPosterImage;
};
