import type { ThemeId } from "../../types";
import { useEditorProgramThemeIdQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseEditorProgramThemeId = {
    programThemeId: ThemeId;
};

export const useEditorProgramThemeId = (): UseEditorProgramThemeId => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorProgramThemeIdQuery({ variables: { programId, programVersionId } });

    const programThemeId = data?.editorProgram?.programVersion?.ccTheme?.id;

    return { programThemeId };
};
