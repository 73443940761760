import type { RaasPositionable } from "../../Utils";
import { renderDigest, renderVideoSpec } from "../../Utils";
import { createCache } from "./cache";
import type { RaasPlaceholderPosition, SceneId, VideoSpec } from "../../types";
import { v4 as uuid } from "uuid";
import type { AxiosResponse } from "axios";
import { axiosGetWithTtfbTimeout } from "../../../../utils/axiosUtils";
import type { LivePreviewAnalyticsBuilder, LivePreviewClient } from "./livePreviewRequestAnalytics";
import { initLivePreviewAnalyticsBuilder } from "./livePreviewRequestAnalytics";
import type { VideoSpecResponse } from "../../../../../common/external/videoSpecResponse";
import { isRaaSResponseCacheHit } from "../../../../../common/external/videoSpecResponseUtils";
import { reportTimedActivity } from "../../../../utils/timedActivityReportingUtils";

export type RaasPlaceholder = RaasPositionable & {
    videoSpecName: string;
}

export type Viewport = {
    width: number;
    height: number;
}

export type ImageAndPlaceholders = {
    imageSrc: string;
    placeholders: RaasPlaceholder[];
    viewport: Viewport;
    foregroundLayer: RaasPositionable | null;
    canvases: Record<string, RaasPositionable>;
};

type Metadata = {
    placeholders: RaasPlaceholder[];
    viewport: Viewport;
    foregroundLayer: RaasPositionable | null;
    canvases: Record<string, RaasPositionable>;
}

type QueryablePromise<T> = Promise<T> & {
    isPending: () => boolean;
    isFulfilled: () => boolean;
    isRejected: () => boolean;
};

const cache = createCache<SceneId, string, QueryablePromise<ImageAndPlaceholders>>(20);

export function generateImageAndPlaceholders(sceneId: SceneId, videoSpec: VideoSpec, livePreviewClient: LivePreviewClient): Promise<ImageAndPlaceholders> {
    //Analytics object for generateImageAndPlaceholders operation
    const livePreviewStartHandlingTime = Date.now();
    const livePreviewAnalytics = initLivePreviewAnalyticsBuilder()
        .withLivePreviewClient(livePreviewClient)
        .withLivePreviewStartHandlingTime(livePreviewStartHandlingTime);

    const cacheKey = renderDigest(videoSpec);
    let promise;
    const cachedPromise = cache.get(sceneId, cacheKey);
    if (cachedPromise && !cachedPromise.isRejected()) {
        //Return the cached promise if it is either pending or fulfilled
        livePreviewAnalytics.withClientCacheHit();
        promise = cachedPromise;
    }
    else {
        //Otherwise, make a real request
        promise = makeQueryablePromise<ImageAndPlaceholders>(render(videoSpec, undefined, livePreviewAnalytics));
        cache.set(sceneId, cacheKey, promise);
    }

    promise.then(result => {
        livePreviewAnalytics.withLivePreviewStatus("Succeeded");
        return result;
    }, reason => {
        livePreviewAnalytics.withLivePreviewStatus("Failed");
        throw reason;
    }).finally(() => {
        const livePreviewEndHandlingTime = Date.now();
        livePreviewAnalytics.withLivePreviewEndHandlingTime(livePreviewEndHandlingTime)
            .report();
    });

    return promise;
}

const raasAssetRequestTimeout = 20000;
const maxAttempts = 1;
type RenderOptions = {
    attempt: number;
    previousErrors: Record<string, { error: Error, rid: string, raasRequestTimestamp: number, fetchAssetsTimestamp: number, errorTimestamp: number}>
};

async function render(videoSpec: VideoSpec, options: RenderOptions = {
    attempt: 1,
    previousErrors: {}
}, analyticsBuilder: LivePreviewAnalyticsBuilder): Promise<ImageAndPlaceholders> {
    const raasRequestTimestamp = Date.now();

    const attemptNumber = options.attempt;
    analyticsBuilder
        .withRaaSRequestTime(raasRequestTimestamp, attemptNumber)
        .withRaaSRequestId(videoSpec.requestid, attemptNumber);

    const status: VideoSpecResponse = await renderVideoSpec(videoSpec)
        .then(result => {
            analyticsBuilder.withRaaSRequestStatus("Succeeded", attemptNumber);
            return result;
        }, reason => {
            analyticsBuilder.withRaaSRequestStatus("Failed", attemptNumber);
            throw reason;
        }).finally(() => {
            const raasResponseTimestamp = Date.now();
            analyticsBuilder
                .withRaaSResponseTime(raasResponseTimestamp, attemptNumber);
        });
    analyticsBuilder
        .withRaaSCacheHit(isRaaSResponseCacheHit(status), attemptNumber)
        .withJobStatusUrl(status._links["self"].href, attemptNumber)
        .withDimensions(status.dimensions, attemptNumber);

    const imageUrl = status._links["video-stream"].href;
    const metadataUrl = status._links["metadata"].href;
    const imageSrcPromise = getImageSrc(imageUrl, analyticsBuilder, attemptNumber).catch((e) => Promise.reject(`${e} Failed url: ${imageUrl}`));
    const metadataPromise = getMetadata(metadataUrl, analyticsBuilder, attemptNumber).catch((e) => Promise.reject(`${e} Failed url: ${metadataUrl}`));
    const fetchAssetsTimestamp = Date.now();
    try {
        const [imageSrc, metadata] = await Promise.all([imageSrcPromise, metadataPromise]);

        reportTimedActivity("Live Preview", raasRequestTimestamp, Date.now());

        return {
            imageSrc,
            placeholders: metadata.placeholders,
            viewport: metadata.viewport,
            foregroundLayer: metadata.foregroundLayer,
            canvases: metadata.canvases
        };
    }
    catch (e) {
        const { attempt, previousErrors } = options;
        if (attempt === maxAttempts) {
            throw {
                lastError: new Error(`LivePreview failed after ${attempt} attempts.\n${e}`),
                allErrors: { ...previousErrors,
                    ["attempt" + attempt]: {
                        error: e,
                        rid: videoSpec.requestid,
                        raasRequestTimestamp,
                        fetchAssetsTimestamp,
                        errorTimestamp: Date.now() } }
            };
        }
        return render({ ...videoSpec, requestid: uuid() },
            {
                attempt: attempt + 1,
                previousErrors: { ...previousErrors,
                    ["attempt" + attempt]: {
                        error: e,
                        rid: videoSpec.requestid,
                        raasRequestTimestamp,
                        fetchAssetsTimestamp,
                        errorTimestamp: Date.now() } }
            },
            analyticsBuilder
        );
    }
}

async function getImageSrc(url: string, analyticsBuilder: LivePreviewAnalyticsBuilder, attempt: number): Promise<string> {
    const fetchTime = Date.now();
    const response: AxiosResponse<any> = await axiosGetWithTtfbTimeout(url, raasAssetRequestTimeout, { responseType: "arraybuffer" })
        .then(result => {
            analyticsBuilder.withImageRequestStatus("Succeeded", attempt);
            return result;
        }, reason => {
            analyticsBuilder.withImageRequestStatus("Failed", attempt);
            throw reason;
        })
        .finally(() => {
            const responseTime = Date.now();
            analyticsBuilder
                .withImageRequestTime(fetchTime, attempt)
                .withImageUrl(url, attempt)
                .withImageResponseTime(responseTime, attempt);
        });
    analyticsBuilder.withImageCacheHit(getCloudfrontCacheHit(response), attempt);
    const base64 = Buffer.from(response.data, "binary").toString("base64");
    return `data:${response.headers["content-type"].toLowerCase()};base64,${base64}`;
}

async function getMetadata(url: string, analyticsBuilder: LivePreviewAnalyticsBuilder, attempt: number): Promise<Metadata> {
    const fetchTime = Date.now();
    const response: AxiosResponse<any> = await axiosGetWithTtfbTimeout(url, raasAssetRequestTimeout)
        .then(result => {
            analyticsBuilder.withMetadataRequestStatus("Succeeded", attempt);
            return result;
        }, reason => {
            analyticsBuilder.withMetadataRequestStatus("Failed", attempt);
            throw reason;
        })
        .finally(() => {
            const responseTime = Date.now();
            analyticsBuilder
                .withMetadataRequestTime(fetchTime, attempt)
                .withMetadataUrl(url, attempt)
                .withMetadataResponseTime(responseTime, attempt);
        });
    analyticsBuilder.withMetadataCacheHit(getCloudfrontCacheHit(response), attempt);

    const viewport = response.data.viewport;

    const placeholders: RaasPlaceholder[] = [];

    let foregroundLayer: RaasPositionable | null = null;
    const canvases: Record<string, RaasPositionable> = {};
    if (response.data.eventEmitters) {
        for (const eventEmitter of response.data.eventEmitters) {
            if (eventEmitter.data.name === "Foreground") {
                foregroundLayer = {
                    position: normalizePlaceholderPosition(eventEmitter.shape, viewport.width, viewport.height),
                    rawPosition: eventEmitter.shape
                };
            }
            else if (eventEmitter.data.type === "CANVAS") {
                canvases[eventEmitter.data.name] = {
                    position: normalizePlaceholderPosition(eventEmitter.shape, viewport.width, viewport.height),
                    rawPosition: eventEmitter.shape
                };
            }
            else {
                placeholders.push({
                    videoSpecName: eventEmitter.data.name,
                    position: normalizePlaceholderPosition(eventEmitter.shape, viewport.width, viewport.height),
                    rawPosition: eventEmitter.shape
                });
            }
        }
    }

    return { placeholders, viewport, foregroundLayer, canvases };
}

function makeQueryablePromise<T>(original: Promise<T>): QueryablePromise<T> {
    let isFulfilled = false;
    let isRejected = false;

    let promise = original.then(value => {
        isFulfilled = true;

        return value;
    }, reason => {
        isRejected = true;

        throw reason;
    });

    let queryablePromise = promise as QueryablePromise<T>;

    queryablePromise.isPending = () => !isFulfilled && !isRejected;
    queryablePromise.isFulfilled = () => isFulfilled;
    queryablePromise.isRejected = () => isRejected;

    return queryablePromise;
}

function normalizePlaceholderPosition(rawPosition: RaasPlaceholderPosition, viewportWidth: number, viewportHeight: number): RaasPlaceholderPosition {
    const x1 = clip(rawPosition.x, 0, viewportWidth);
    const x2 = clip(rawPosition.x + rawPosition.width, 0, viewportWidth);
    const y1 = clip(rawPosition.y, 0, viewportHeight);
    const y2 = clip(rawPosition.y + rawPosition.height, 0, viewportHeight);

    return {
        x: x1,
        y: y1,
        width: x2 - x1,
        height: y2 - y1
    };
}

function clip(value: number, minValue: number, maxValue: number): number {
    return Math.min(Math.max(value, minValue), maxValue);
}

const getCloudfrontCacheHit = (response: AxiosResponse<any>): boolean => {
    return response.headers["x-cache"] && response.headers["x-cache"].toLocaleLowerCase() === "hit from cloudfront";
};
