import { useRef, useState } from "react";

export type ToggleAction = {
    on: () => void;
    off: () => void;
    flip: () => void;
};

type UseToggle = [boolean, ToggleAction];

export function useToggle(initialValue: boolean): UseToggle {
    const [value, setValue] = useState<boolean>(initialValue);

    const action = useRef<ToggleAction>({
        on: () => {
            setValue(true);
        },
        off: () => {
            setValue(false);
        },
        flip: () => {
            setValue((v) => !v);
        }
    });

    return [value, action.current];
}
