import type { NestedMenuItem, NestedMenuFullItem } from "./Components/NestedMenu";
import type { ConnectorType } from "@sundaysky/customer-data-common-goblin-ds";
import type { GqlClientTrayServiceConnectorEntity } from "../../../../../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getTrayConnectorEntries } from "../../../../../../Nooks/AccountDataLibrary/trayService";
import { constructTrayEntityId, TRAY_ENTITY_ID_DELIMITER } from "../../../../../../../../../common/dataLibrary";
import type { SourceElement } from "./DataLibraryDataSource/FieldMappingDialog";

const convertTrayConnectorEntryToNestedMenuItem = (trayEntry: GqlClientTrayServiceConnectorEntity): NestedMenuItem => {
    return {
        id: trayEntry.id,
        label: trayEntry.name,
        hasNestedItems: !trayEntry.isField,
        exampleValue: trayEntry.sampleValue
    };
};

export const nestedItemsGetterByConnectorType = (
    connectorType: ConnectorType,
    dataLibraryId: string,
    authLastUpdated: string,
    connectorEntriesCacheInvalid: boolean,
    setConnectorEntriesCacheInvalid: (valid: boolean) => void
) => {
    return async function(item?: NestedMenuItem): Promise<NestedMenuItem[]> {
        const trayConnectorEntries = await getTrayConnectorEntries({
            dataLibraryId,
            connectorType,
            authLastUpdated,
            parentEntityId: item?.id,
            cacheInvalid: connectorEntriesCacheInvalid
        });
        if (connectorEntriesCacheInvalid) {
            setConnectorEntriesCacheInvalid(false);
        }
        return trayConnectorEntries.map(convertTrayConnectorEntryToNestedMenuItem);
    };
};

export const getSelectedItemStringRepresentation = (selectedItem: NestedMenuItem, parentItems?:NestedMenuItem[]): string => {
    const delimiter = ".";
    if (!parentItems?.length) {
        return selectedItem.label;
    }
    return `${parentItems.map(item => item.label).join(delimiter)}${delimiter}${selectedItem.label}`;
};

export const getSourceNameFromTrayEntityId = (trayEntityId: string): string => {
    const firstDelimiterIndex = trayEntityId.indexOf(TRAY_ENTITY_ID_DELIMITER);
    return trayEntityId.slice(firstDelimiterIndex + 1);
};

export interface INestedMenuFullItemMap {
    [id: string]: NestedMenuFullItem | null;
}

type TrayEntityAndParentIds = { entityId: string, parentIds: string[] };

const getTrayEntityAndParentIds = (sourceName: string, connectorType: ConnectorType): TrayEntityAndParentIds => {
    let trayParentEntityIds: string[] = [];

    // get all tray entity ids and their parent ids from the source name and connector type
    let currentParentEntityId = undefined;
    let currentEntityId: string = null;

    const entityIds = sourceName.split(TRAY_ENTITY_ID_DELIMITER);

    for (const entityId of entityIds) {
        currentEntityId = constructTrayEntityId(connectorType, entityId, currentParentEntityId);
        trayParentEntityIds.push(currentParentEntityId);
        currentParentEntityId = currentEntityId;
    }

    return {
        entityId: currentEntityId,
        parentIds: trayParentEntityIds
    };
};

const getNestedMenuItem = (itemId: string, parentEntries: GqlClientTrayServiceConnectorEntity[]): NestedMenuItem => {
    const matchingEntry = parentEntries.find(entry => entry.id === itemId);
    if (!matchingEntry) {
        throw new Error(`Could not resolve ${itemId} name as it does not appear in ${JSON.stringify(parentEntries)}'s children`);
    }
    return convertTrayConnectorEntryToNestedMenuItem(matchingEntry);
};

const getFullNestedItemData = (trayIds: TrayEntityAndParentIds, parentInfo: { [id: string]: GqlClientTrayServiceConnectorEntity[] }):
    NestedMenuFullItem | null => {
    const parents: NestedMenuItem[] = [];
    const parentIds = trayIds.parentIds;

    try {
        // we need to find the parent pretty name in its parent entry list
        // so for each parent, we search in the subsequent parent's entries
        // the first parent is the root ('undefined') element, so we do not try to resolve it.
        for (let i = 1; i < parentIds.length; i++) {
            const itemId: string = parentIds[i];
            const directParentId: string = parentIds[i - 1];
            parents.push(getNestedMenuItem(itemId, parentInfo[directParentId]));
        }

        const item: NestedMenuItem = getNestedMenuItem(trayIds.entityId, parentInfo[parentIds[parentIds.length - 1]]);

        return { item, parents };
    }
    catch (err) {
        return null;
    }
};

const getParentIdToParentEntriesMap = async (
    dataLibraryId: string,
    connectorType: ConnectorType,
    authLastUpdated: string,
    dataEntityIdToTrayEntitiesMap: { [id: string]: TrayEntityAndParentIds }
): Promise<{ [id: string]: GqlClientTrayServiceConnectorEntity[] }> => {

    const parentIdToParentEntriesMap = {};

    // 1. keep only unique parent ids to avoid redundant calls to retrieve the parent's entries
    const allParentsSet = new Set<string>();
    Object.values(dataEntityIdToTrayEntitiesMap).forEach(trayEntityAndParentId => {
        const parentList = trayEntityAndParentId.parentIds;
        parentList.forEach(parent => allParentsSet.add(parent));
    });
    const allParentsArray = Array.from(allParentsSet);

    // 2. For each parent, get all tray entries ("children"),
    const promises = allParentsArray.map(entityId => getTrayConnectorEntries({
        dataLibraryId,
        connectorType,
        authLastUpdated,
        parentEntityId: entityId
    }));
    const queryResults = await Promise.all(promises);

    allParentsArray.forEach((parent, index) => {
        parentIdToParentEntriesMap[parent] = queryResults[index];
    });

    return parentIdToParentEntriesMap;
};

/**
 * Gets the required data to populate nested menu visible value for multiple data element source fields
 * e.g. for a data element that maps 'SF Account Name' to a data field, we need to know the 'pretty name' of its parent names (in this case 'Account') and the item pretty name (in this case 'Name')
 * @param dataLibraryId the data library id
 * @param connectorType type of data connector from which the schema is retrieved (i.e Salesforce, hubspot etc.)
 * @param authLastUpdated the last time the authentication was updated. Used to invalidate the cache.
 * @param dataElementSourceItems the Data Element source fields, for which we need the nested menu data.
 */
export const getNestedMenuInitialState = async (dataLibraryId: string, connectorType: ConnectorType, authLastUpdated: string, dataElementSourceItems: SourceElement[]):
    Promise<INestedMenuFullItemMap> => {
    let itemsMap: INestedMenuFullItemMap = {};

    if (!connectorType || !dataElementSourceItems?.length) {
        return itemsMap;
    }
    const sourceElements = dataElementSourceItems.filter(elem => elem?.dataElementId && elem?.sourceName);

    // 1. get all tray entity ids and their parent ids and store them in a map
    let dataEntityIdToTrayEntityIdsMap = {};
    sourceElements.forEach(sourceElement => {
        dataEntityIdToTrayEntityIdsMap[sourceElement.dataElementId] = getTrayEntityAndParentIds(sourceElement.sourceName, connectorType);
    });

    // 2. get each parent's entries and store them in a map
    const parentIdToParentEntriesMap = await getParentIdToParentEntriesMap(dataLibraryId, connectorType, authLastUpdated, dataEntityIdToTrayEntityIdsMap);

    // 3. for each source element get NestedMenuItem objects for it and for each of its parents
    sourceElements.forEach(sourceElement => {
        const elementId = sourceElement.dataElementId;
        itemsMap[elementId] = getFullNestedItemData(dataEntityIdToTrayEntityIdsMap[elementId], parentIdToParentEntriesMap);
    });

    return itemsMap;
};
