import type { ButtonShape, ColorValues, GqlMediaById, LivePreviewSceneData, LogoUrl, VideoSpec } from "../types";
import { VideoSpecBuilder } from "./videoSpecBuilder";
import { getService } from "../../services";
import type RaasServices from "../../../logic/common/raasServices";
import type {
    GqlClientEditorAnimatedWireframeFragment,
    GqlClientEditorAssetLibraryAvatarFragment,
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorLibraryAvatarFragment,
    GqlClientEditorLibraryFontFragment,
    GqlClientEditorLibraryPlaceholderIntentFragment,
    GqlClientStudioElementFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEnumAspectRatio } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { deepCloneObj } from "../../../logic/common/generalUtils";
import { getAspectRatio } from "../../../../common/aspectRatioUtils";
import type { VideoAspectRatio, VideoQualityProfile } from "../../../../common/external/videoSpec";
import type { FontsManager } from "../../../../common/fontsUtils";


type BuildVideoSpecForImageParams = {
    requestId: string;
    projectId: string;
    assetsByMediaId: GqlMediaById;
    scene: LivePreviewSceneData;
    frame: number; // 1-based indexing
    layout: GqlClientEditorAnimatedWireframeFragment;
    colors: ColorValues;
    logoUrl: LogoUrl;
    textStyles: GqlClientEditorBrandTextStyleFragment[];
    buttonShape: ButtonShape;
    studioElements: GqlClientStudioElementFragment[];
    intents: GqlClientEditorLibraryPlaceholderIntentFragment[];
    // Optional aspect ratio. If not provided, 16:9 will be used when calling RAAS.
    aspectRatio?: GqlClientEnumAspectRatio;
    videoQualityProfile: VideoQualityProfile;
    backgroundMedia?: GqlClientEditorAssetLibraryMediaFragment;
    previewM2Mode?: boolean;
    ccFontsManager: FontsManager<GqlClientEditorLibraryFontFragment>;
    accountFontsManager: FontsManager<GqlClientEditorAssetLibraryFontFragment>;
    libraryAvatars: GqlClientEditorLibraryAvatarFragment[];
    customAvatars: GqlClientEditorAssetLibraryAvatarFragment[]
    useClientPlaceholderPreview?: boolean;
};

export function renderVideoSpec(videoSpec: VideoSpec): Promise<any> {
    const raasServices = getService<RaasServices>("raasServices");
    if (raasServices) {
        return raasServices.raasRequest(videoSpec);
    }

    return Promise.reject(new Error("Raas Service Unavailable"));
}

export function renderDigest(videoSpec: VideoSpec): string {
    let relevantVideoSpec = deepCloneObj(videoSpec);

    delete relevantVideoSpec.requestid;
    for (let scene of relevantVideoSpec.lineup) {
        delete scene.name;
    }

    return JSON.stringify(relevantVideoSpec);
}

export function buildVideoSpecForImage(params: BuildVideoSpecForImageParams): VideoSpec {
    const { requestId, assetsByMediaId, scene, frame, layout, colors, logoUrl, aspectRatio,
        textStyles, buttonShape, studioElements, projectId,
        intents, videoQualityProfile, previewM2Mode, backgroundMedia, ccFontsManager, accountFontsManager, libraryAvatars, customAvatars } = params;

    let videoAspectRatio: VideoAspectRatio = getAspectRatio(GqlClientEnumAspectRatio.AR_16_9).videoRatio();
    if (aspectRatio) {
        const ar = getAspectRatio(aspectRatio);
        if (ar) {
            videoAspectRatio = ar.videoRatio();
        }
    }

    return new VideoSpecBuilder()
        .withScene({
            name: scene.name,
            placeholders: scene.placeholders,
            colorCombination: scene.colorCombination,
            layout,
            buttonAlignment: scene.buttonAlignment
        })
        .withClientPlaceholderPreview(!!params.useClientPlaceholderPreview)
        .withLibraryAvatars(libraryAvatars)
        .withCustomAvatars(customAvatars)
        .withAssets(assetsByMediaId)
        .withColors(colors)
        .withLogoUrl(logoUrl)
        .withIntents(intents)
        .withTextStyles(textStyles)
        .withButtonShape(buttonShape)
        .withFormat({
            format: "png",
            frame: { label: `${frame + 1}` }
        })
        .withStudioElements(studioElements)
        // SD-3114 - enable RAAS cache in live-preview
        .withCache({})
        .withOutput(videoQualityProfile, videoAspectRatio)
        .withPreviewM2Mode(!!previewM2Mode)
        .withBackgroundMedia(backgroundMedia)
        .withFontsManagers(ccFontsManager, accountFontsManager)
        .build(requestId, projectId);
}
