import type {
    GqlClientCreateEditorLifecycleEntryInput,
    GqlClientCreateEditorLifecycleEntryMutation
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateEditorLifecycleEntryDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { convertStageType } from "../../../../logic/builder/graphql/convertTypes";
import { EDITING_MODE, IGNORE_SERVER_ERRORS, STAGE_MODE } from "../../../../logic/common/Consts";
import type { Stages } from "../../../../../common/types/lifecycle";
import { getApolloClient } from "../../../../apollo";

export const createEditorLifecycleEntry = async (programId: string, stage: Stages, projectSnapshotNumber: number) => {
    let input: GqlClientCreateEditorLifecycleEntryInput = {
        programId: programId,
        lifecycleStage: convertStageType(stage),
        snapshotNumber: projectSnapshotNumber
    };

    await getApolloClient().mutate<GqlClientCreateEditorLifecycleEntryMutation>({
        mutation: CreateEditorLifecycleEntryDocument,
        variables: {
            input: input,
            [IGNORE_SERVER_ERRORS]: true
        },
        context: {
            allowedModes: [EDITING_MODE, STAGE_MODE]
        }
    });
};