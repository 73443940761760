import { LOGIC_MEDIA_TYPES } from "../../../logic/vlx/consts";
import Immutable from "immutable";
import { generateValueId } from "../../../logic/Logics/ValueSetUtils";
class ValueSetController {
    static isValueSetEditSupportedForType(mediaType) {
        return mediaType === LOGIC_MEDIA_TYPES.String || mediaType === LOGIC_MEDIA_TYPES.Number;
    }
    static buildValueListFromDataElement(dataElement) {
        let values = [];
        let valueSet = dataElement.getValueSet && dataElement.getValueSet();
        if (valueSet) {
            valueSet = ValueSetController.upgradeValueSet(valueSet);
            values = valueSet.map((value) => (value ? { id: value.id, type: "valueGood", text: value.dn } : { id: "", type: "valueNothing", text: "Nothing" }));
        }
        return Immutable.List(values);
    }
    static trimAndValidateValueList(valueList, type, acceptNewValues) {
        let typeIsNumber = type === LOGIC_MEDIA_TYPES.Number;
        let filteredValues = valueList.filter((v) => (v.type === "valueNew" && acceptNewValues) || v.text.trim().length > 0).map((v) => ({ id: v.id, type: v.type, text: v.text.trim() }));
        let valueCount = new Map();
        filteredValues.forEach((v) => {
            if (v.type !== "valueNew" || !acceptNewValues) {
                let normalizedText = ValueSetController.normalizeValueText(v.text, typeIsNumber);
                let count = valueCount.get(normalizedText) || 0;
                valueCount.set(normalizedText, count + 1);
            }
        });
        let values = filteredValues.map((v) => {
            if (v.type === "valueNew" && acceptNewValues) {
                return { id: v.id || generateValueId(), type: v.type, text: v.text };
            }
            else if (typeIsNumber && isNaN(Number(v.text))) {
                return { id: v.id || generateValueId(), type: "valueBadWrongType", text: v.text };
            }
            else {
                let normalizedText = ValueSetController.normalizeValueText(v.text, typeIsNumber);
                let count = valueCount.get(normalizedText);
                let id = v.id || generateValueId();
                let type = count > 1 ? "valueBadDup" : "valueGood";
                let text = v.text;
                return { id, type, text };
            }
        });
        return Immutable.List(values);
    }
    static countIssues(valueList) {
        return valueList.reduce((count, value) => count + (value.type.startsWith("valueBad") ? 1 : 0), 0);
    }
    static normalizeValueText(text, typeIsNumber) {
        let normalizedText = text.trim().toLowerCase();
        if (typeIsNumber) {
            let num = Number(normalizedText);
            if (!isNaN(num)) {
                normalizedText = num.toString();
            }
        }
        return normalizedText;
    }
}
ValueSetController.upgradeValueSet = (valueSet) => {
    return valueSet.map((value) => (typeof value === "string" ? { id: "_bc_" + value.toLowerCase(), dn: value } : value));
};
export default ValueSetController;
