import React from "react";
import "./HubDataElementPicker.css";
import HubContentEditable from "./HubContentEditable";
import HubDatePicker from "./HubDatePicker";
import { DataElementContentTypes } from "../../../common/types/dataElement";
import type { Moment } from "moment";

interface HubDataElementPickerProps {
    dataElementName: string;
    onDataElementSelectionClick: (dataElementContentType: DataElementContentTypes, isElementSelected: boolean, pickedActionIndex: number, pickedParamIndex: number) => void;
    onWhenLogicPopupSelectionClick: (e: React.MouseEvent<HTMLInputElement>) => void;
    value: number | string | Moment;
    datePostfix?: string;
    datePickerClassname?: string;
    handleChange: (value) => void;
    dataElementCompareType: DataElementContentTypes;
    pickedActionIndex: number;
    pickedParamIndex: number;
    paramName?: string;
    isLogicWhenPopup?: boolean;
}

const HubDataElementPicker = ({
    dataElementName,
    onDataElementSelectionClick,
    onWhenLogicPopupSelectionClick,
    value,
    datePostfix,
    datePickerClassname,
    handleChange,
    dataElementCompareType,
    pickedActionIndex,
    pickedParamIndex,
    paramName,
    isLogicWhenPopup
}: HubDataElementPickerProps): JSX.Element => {
    const renderDatePicker = (): JSX.Element => {
        return <HubDatePicker
            id="operandContentEditable"
            className={datePickerClassname}
            inline={true}
            readOnly={false}
            handleChangeStart={handleChange}
            startDate={value as Moment}
            displayPostfix={datePostfix}
        />;
    };

    const renderPrimitivePicker = (): JSX.Element => {
        return <HubContentEditable value={value} placeholder={value} onConfirm={handleChange} />;
    };

    const handleWhenLogicPopupSelectionClick = (e: React.MouseEvent<HTMLInputElement>): void => {
        onWhenLogicPopupSelectionClick(e);
    };

    const handleDataElementPickedLine = (): void => {
        if (dataElementName) {
            onDataElementSelectionClick(dataElementCompareType, true, pickedActionIndex, pickedParamIndex);
        }
    };

    const renderDataElementPickedLine = (): JSX.Element => {
        return (
            <>
                <div className="param-name">{paramName}</div>
                <HubContentEditable
                    className="data-element-picked-line"
                    value={dataElementName}
                    placeholder={dataElementName}
                    onClick={!isLogicWhenPopup ? handleDataElementPickedLine : handleWhenLogicPopupSelectionClick}
                />
            </>
        );
    };

    const handlePickDataElementIconButton = (): void => {
        onDataElementSelectionClick(dataElementCompareType, false, pickedActionIndex, pickedParamIndex);
    };

    const renderPickDataElementIconButton = (): JSX.Element => {
        return <i className={"dotsIcon"} onClick={!isLogicWhenPopup ? handlePickDataElementIconButton : handleWhenLogicPopupSelectionClick} data-testid="data-element-selection-dots-icon" />;
    };

    const renderBasicPicker = (): JSX.Element => {
        if (dataElementCompareType === DataElementContentTypes.Date) {
            return renderDatePicker();
        }
        if (dataElementCompareType === DataElementContentTypes.Number || dataElementCompareType === DataElementContentTypes.String) {
            return renderPrimitivePicker();
        }
    };

    return (
        <div className="data-element-picker">
            {!dataElementName && renderBasicPicker()}
            {dataElementName && renderDataElementPickedLine()}
            {!dataElementName && renderPickDataElementIconButton()}
        </div>
    );
};

export default HubDataElementPicker;
