import {
    useLegacyAssetsBasePathQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export const useLegacyAssetsLocationBasePath = (): string => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useLegacyAssetsBasePathQuery({ variables: { programId, programVersionId } });

    return data?.editorProgram?.programVersion?.legacyAssetLocationBasePath ?? null;
};
