import { useReactiveVar } from "@apollo/client";
import { localEditorLivePreviewPendingSetVar } from "../../State";
import type { LivePreviewPendingSet } from "../../types";

export type UseLivePreviewPendingSet = {
    livePreviewPendingSet: LivePreviewPendingSet;
};

export const useLivePreviewPendingSet = (): UseLivePreviewPendingSet => {
    const livePreviewPendingSet = useReactiveVar(localEditorLivePreviewPendingSetVar);

    return { livePreviewPendingSet };
};
