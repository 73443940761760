import { Stages } from "../../../common/types/lifecycle";
import TimeUtils from "../../logic/common/timeUtils";
import React from "react";
import type { Snapshot } from "../../../common/types/snapshot";

export const getBasedOnSnapshotNumber = (snapshots: Snapshot[], graphQLParentProgramVersionId: string, latestSnapshotNumber: number): number | null => {
    if (graphQLParentProgramVersionId && latestSnapshotNumber) {
        const parentSnapshotNumber: number = snapshots?.find(version => version.graphQLId === graphQLParentProgramVersionId)?.snapshotNumber;
        return parentSnapshotNumber && parentSnapshotNumber !== latestSnapshotNumber ? parentSnapshotNumber : null;
    }
    else {
        return null;
    }
};

export const getBasedOnSnapshotNumberForSnapshot = (snapshots: Snapshot[], currentSnapshot: Snapshot): number | null => {
    if (currentSnapshot.graphQLParentProgramVersionId) {
        const previousSnapshots: Snapshot[] = snapshots?.filter(snapshot => snapshot.snapshotNumber < currentSnapshot.snapshotNumber);
        const previousSnapshotNumbers: number[] = previousSnapshots?.map(ver => ver.snapshotNumber);
        const latestSnapshotNumber: number = previousSnapshotNumbers && previousSnapshotNumbers.length > 1 && Math.max(...previousSnapshotNumbers);
        return getBasedOnSnapshotNumber(previousSnapshots, currentSnapshot.graphQLParentProgramVersionId, latestSnapshotNumber);
    }
    else {
        return null;
    }
};

export const getActiveVersion = (versions: { snapshotNumber: number }[]): number => {
    return versions[0] ? versions[0].snapshotNumber : null;
};

export const getActiveStage = (versionNumber: number, devVersion: number, uatVersion: number, prodVersion: number): Stages | "" => {
    if (versionNumber === prodVersion) {
        return Stages.prod;
    }
    if (versionNumber === uatVersion) {
        return Stages.UAT;
    }
    if (versionNumber === devVersion) {
        return Stages.dev;
    }

    return "";
};

export const getActiveStages = (versionNumber: number, devVersion: number, uatVersion: number, prodVersion: number): Stages[] => {
    let stages: Stages[] = [];
    if (versionNumber === prodVersion) {
        stages.push(Stages.prod);
    }
    if (versionNumber === uatVersion) {
        stages.push(Stages.UAT);
    }
    if (versionNumber === devVersion) {
        stages.push(Stages.dev);
    }

    return stages;
};

export const renderDateTimeColumn = (timestamp) => {
    if (!timestamp) {
        return "N/A";
    }
    return (
        <div className="date-time-cell" style={{ display: "flex", flexDirection: "column", margin: "-10px 0px" }}>
            <span>{TimeUtils.getReadableDate(timestamp)}</span>
            <span>{TimeUtils.getReadableTime(timestamp)}</span>
        </div>
    );
};
