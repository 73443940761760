import type { ApolloError } from "@apollo/client";

type ErrorPayload = EditorProgramVersionSetupState["error"];

type SuccessPayload = null;

type ActionPayload = ErrorPayload | SuccessPayload;

type GenericAction<T extends EditorProgramVersionActionType, P extends ActionPayload> = { type: T, payload: P }

export enum EditorProgramVersionActionType {
    Error = "error",
    Success = "success",
}

export type EditorProgramVersionErrorAction = GenericAction<EditorProgramVersionActionType.Error, ErrorPayload>;

export type EditorProgramVersionSuccessAction = GenericAction<EditorProgramVersionActionType.Success, SuccessPayload>;

export type EditorProgramVersionAction = EditorProgramVersionErrorAction | EditorProgramVersionSuccessAction;

export type EditorProgramVersionSetupState = {
    loading: boolean;
    error: ApolloError | Error | null;
};


export const editorProgramVersionInitialState: EditorProgramVersionSetupState = {
    loading: true, // useSetup() immediately calls useQuery(), so initial 'loading' state is true
    error: null
};

export const editorProgramVersionReducer = (state: EditorProgramVersionSetupState, action: EditorProgramVersionAction): EditorProgramVersionSetupState => {
    switch (action.type) {
        case EditorProgramVersionActionType.Error:
            return {
                loading: false,
                error: action.payload as ErrorPayload
            };

        case EditorProgramVersionActionType.Success:
            return {
                loading: false,
                error: null
            };
    }
};
