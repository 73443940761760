import { useReactiveVar } from "@apollo/client";
import { localAiAssetGenerationDialogInfo } from "../../State";
import type { AssetGenerationDialogInfo } from "../../types";

export type UseAiGenerationDialogOpen = {
    assetGenerationDialogInfo: AssetGenerationDialogInfo;
    setAssetGenerationDialogInfo: (info: AssetGenerationDialogInfo) => void;
};

export const useAiGenerationDialogOpen = (): UseAiGenerationDialogOpen => {

    const assetGenerationDialogInfo = useReactiveVar(localAiAssetGenerationDialogInfo);

    const setAssetGenerationDialogInfo = (info: AssetGenerationDialogInfo): void => {
        localAiAssetGenerationDialogInfo(info);
    };

    return { assetGenerationDialogInfo, setAssetGenerationDialogInfo };
};
