import type { AspectRatio, EvpConfiguration, MediaNode } from "@sundaysky/landing-page-skeleton-types";
import type { PlayerAspectRatio } from "../../../../common/aspectRatioUtils";
import { getAspectRatio } from "../../../../common/aspectRatioUtils";
import { getLocalIdFromGqlId } from "../../../../common/idUtils";
import type { GqlClientEditorProgramForEmbedVideoBuilderQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getDefaultPosterImagesPerProgram, getPosterImage, updatePosterImageValue } from "./EmbedVideoLandingPageUtils";

export type InitialEvpConfigurationInput = {
    logo: MediaNode;
    colors: Record<string, string>;
    defaultPosterImages: Map<PlayerAspectRatio, MediaNode>;
    analyticsToken: string;
    aspectRatio: PlayerAspectRatio;
};

export const createInitialEvpConfiguration = (input: InitialEvpConfigurationInput): EvpConfiguration => {
    return {
        __type: "EmbedVideoPageConfiguration",
        version: 2,
        getSessionEndpointPrefix: "", // if blank, the default "getSessionEndpointPrefix" is saved in the skeleton itself
        loader: {
            color: input.colors.primary_color
        },
        main: {
            playerConfiguration: {
                posterImage: getPosterImage(input.aspectRatio, input.defaultPosterImages),
                analyticsToken: input.analyticsToken,
                showLogo: true,
                playButtonColor: input.colors.primary_color,
                lockPlayerBar: false,
                addTranscriptButton: true,
                ccOnByDefault: false,
                largeCenterPlayIcon: false,
                rectangleCenterPlayIcon: false,
                logoOverlay: {
                    ...input.logo,
                    displayLogo: false
                },
                aspectRatio: input.aspectRatio as AspectRatio
            },
            surveyConfiguration: {
                surveyId: "",
                backgroundColor: "#FFFFFF",
                showAt: 0.9
            }
        },
        iframeMode: false
    };
};

export const updateEvpConfigurationWithCurrentSettings = (
    configuration: EvpConfiguration,
    input: InitialEvpConfigurationInput
): EvpConfiguration => {
    return {
        ...configuration,
        main: {
            ...configuration.main,
            playerConfiguration: {
                ...configuration.main.playerConfiguration,
                posterImage: updatePosterImageValue(
                    configuration.main.playerConfiguration.posterImage,
                    input.defaultPosterImages,
                    input.aspectRatio
                ),
                analyticsToken: input.analyticsToken,
                aspectRatio: input.aspectRatio as AspectRatio
            }
        }
    };
};

export const getInitialEvpConfigurationInput = (
    editorProgram: GqlClientEditorProgramForEmbedVideoBuilderQuery["editorProgram"]
): InitialEvpConfigurationInput => {
    const colors = editorProgram.programVersionDraft.selectedBrandConfiguration.colors.reduce((acc, color) => {
        acc[color.ccDof.name] = color.color;
        return acc;
    }, {});

    const logoAsset = editorProgram.programVersionDraft.selectedBrandConfiguration.logoMedia;

    return {
        logo: {
            url: logoAsset?.mediaUrl ?? "",
            platformId: getLocalIdFromGqlId(logoAsset?.id) || ""
        },
        colors,
        defaultPosterImages: getDefaultPosterImagesPerProgram(editorProgram),
        analyticsToken: editorProgram.saleForceId,
        aspectRatio: getAspectRatio(editorProgram.programVersionDraft.aspectRatio).playerFormat()
    };
};
