import type {
    GqlClientAccountDataLibrary,
    GqlClientAccountDataLibraryFragment,
    GqlClientAccountDataLibraryPublishedFragment,
    GqlClientAccountDataLibraryVersion,
    GqlClientAccountDataLibraryVersionFragment,
    GqlClientStudioElementFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientDataElementType } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ConnectorConfiguration, ValueSetItem, DataSourceEntityInfo } from "@sundaysky/customer-data-common-goblin-ds";
import { ALL_SYSTEM_ELEMENTS, ConnectorType, DynamicElement, ElementType } from "@sundaysky/customer-data-common-goblin-ds";
import type { AccountDataLibrary, DataLibraryDynamicElement, StudioElementExtended, StudioValueSetItem, VideoInformationWithUsedInData, FullAccountDataLibrary } from "../types";
import { StudioElementOrigin } from "../types";
import { getCustomExtractorNameFromConnectorConfiguration, getDataSourceEntityInfo, getSourceEntitiesFromSourceFields } from "../../../../common/dataLibrary";
import type { UsedIn, UsedInPlaceholder } from "../../../../common/dynamicElementsUtils";
import { CommonPlaceholderType } from "../../../../common/types/editorPlaceholder";
import { IDENTIFIER_DYNAMIC_ELEMENT_NAME } from "../../../../common/editorUtils";

export const getStudioElementName = (studioElementLocalId: string, studioElements: GqlClientStudioElementFragment[]): string | null => {
    return studioElements.find(element => element.localId === studioElementLocalId)?.name;
};

export const convertProgramDynamicElementToStudioElement = (dynamicElement: DataLibraryDynamicElement, ignoreValueSet: boolean = false): StudioElementExtended => {
    const serializedDynamicElement = DynamicElement.deserialize(dynamicElement.data);

    const dataType = convertDynamicElementDataTypeToStudioElementDataType(serializedDynamicElement.target.type);
    const name = serializedDynamicElement.target.name;
    const valueSet = ignoreValueSet ? [] : convertDynamicElementValueSet(serializedDynamicElement.valueSet);

    return {
        id: dynamicElement.id,
        localId: dynamicElement.localId,
        dataType,
        name,
        valueSet,
        origin: StudioElementOrigin.Program,
        sourceName: serializedDynamicElement.source?.name,
        order: serializedDynamicElement.metadata?.order,
        pii: serializedDynamicElement.pii
    };
};

export const convertSystemDynamicElementToStudioElement = (dynamicElement: DynamicElement): StudioElementExtended => {
    return {
        id: dynamicElement.metadata.localId,
        localId: dynamicElement.metadata.localId,
        name: dynamicElement.target.name,
        dataType: convertDynamicElementDataTypeToStudioElementDataType(dynamicElement.target.type),
        valueSet: convertDynamicElementValueSet(dynamicElement.valueSet),
        origin: StudioElementOrigin.System
    };
};

const convertDynamicElementValueSet = (valueSet: ValueSetItem[]): StudioValueSetItem[] => {
    return valueSet?.map((v: ValueSetItem) => {
        return { id: v.id, value: v.dn };
    });
};

const convertDynamicElementDataTypeToStudioElementDataType = (dynamicElementDataType: ElementType): GqlClientDataElementType => {
    switch (dynamicElementDataType) {
        case ElementType.BOOLEAN:
            return GqlClientDataElementType.BOOLEAN;
        case ElementType.DATE:
            return GqlClientDataElementType.DATETIME;
        case ElementType.NUMBER:
            return GqlClientDataElementType.NUMBER;
        case ElementType.STRING:
            return GqlClientDataElementType.STRING;
        default:
            return GqlClientDataElementType.STRING;
    }
};

export const getSystemElements = (): Array<DynamicElement> => {
    return ALL_SYSTEM_ELEMENTS;
};

export const getUrlParamNamesOfUsedSourceEntities = (connectorType: ConnectorType, sourceFieldIds: Array<string>): Array<string> => {
    const urlParamInfo: Set<DataSourceEntityInfo> = new Set<DataSourceEntityInfo>();
    const sourceEntitySet: Set<string> = getSourceEntitiesFromSourceFields(sourceFieldIds);
    const sourceEntityArray: Array<string> = Array.from(sourceEntitySet);
    sourceEntityArray.forEach(sourceEntity => {
        urlParamInfo.add(getDataSourceEntityInfo(connectorType, sourceEntity));
    });
    const urlParamInfoArray: Array<DataSourceEntityInfo> = Array.from(urlParamInfo);
    return urlParamInfoArray.map(object => object.entryIdQueryParamName);
};

export const getDataLibraryDataElementsFromProgramVersion = (programVersion?: {
    accountDataLibraryVersion?: Pick<GqlClientAccountDataLibraryVersionFragment, "dataFields">
}): DataLibraryDynamicElement[] => {
    return programVersion?.accountDataLibraryVersion?.dataFields ?? [];
};

export const getConnectorTypeFromProgramVersion = (programVersion?: {
    accountDataLibraryVersion?: Pick<GqlClientAccountDataLibraryVersion, "data">
}): ConnectorType => {
    return (programVersion?.accountDataLibraryVersion?.data as ConnectorConfiguration)?.type ?? ConnectorType.Dummy;
};

export const getDataLibraryFromProgramVersion = (programVersion?: {
    accountDataLibraryVersion?: Pick<GqlClientAccountDataLibraryVersionFragment, "accountDataLibrary">
}): Partial<GqlClientAccountDataLibrary> => {
    return programVersion?.accountDataLibraryVersion?.accountDataLibrary;
};

export const getConnectorCustomExtractorNameFromProgramVersion = (programVersion?: {
    accountDataLibraryVersion?: GqlClientAccountDataLibraryVersionFragment
}): string | undefined => {
    return getCustomExtractorNameFromConnectorConfiguration(programVersion?.accountDataLibraryVersion?.data);
};

export const convertGqlDataLibraryToFullDataLibrary = (connectorType: ConnectorType, dataLibrary: GqlClientAccountDataLibraryPublishedFragment): FullAccountDataLibrary => {
    const usedInVideosWithPersonalization = dataLibrary.publishedVideosUsingLibrary.
        filter(video => (video.prodVersion?.editorProgramVersion?.accountDataLibraryVersion?.data as ConnectorConfiguration).type === connectorType);

    const usedInVideos = dataLibrary.publishedVideosTargetLibrary.
        filter(video => (video.prodVersion?.editorProgramVersion?.accountDataLibraryVersion?.data as ConnectorConfiguration).type === connectorType);

    return {
        id: dataLibrary.id,
        name: dataLibrary.name,
        usedInVideosWithPersonalization,
        usedInVideos
    };
};
export const convertGqlDataLibraryToDataLibrary = (dataLibrary: GqlClientAccountDataLibraryFragment): AccountDataLibrary => {
    return {
        draftId: dataLibrary.draftAccountDataLibraryVersion.id,
        name: dataLibrary.name,
        description: dataLibrary.description,
        type: (dataLibrary.draftAccountDataLibraryVersion.data as ConnectorConfiguration).type ?? ConnectorType.CsvToDynamo,
        dataFieldNames: dataLibrary.draftAccountDataLibraryVersion.dataFields.map(dataField => DynamicElement.deserialize(dataField.data).target.name),
        usedInVideos: dataLibrary.draftAccountDataLibraryVersion.usedInVideos
    };
};

export const convertGqlDataLibraryVersionToDataLibrary = (dataLibraryVersion: GqlClientAccountDataLibraryVersionFragment): AccountDataLibrary => {
    return {
        draftId: dataLibraryVersion.id,
        name: dataLibraryVersion.accountDataLibrary.name,
        description: dataLibraryVersion.accountDataLibrary.description,
        type: (dataLibraryVersion.data as ConnectorConfiguration).type ?? ConnectorType.CsvToDynamo,
        dataFieldNames: dataLibraryVersion.dataFields.map(dataField => DynamicElement.deserialize(dataField.data).target.name),
        usedInVideos: dataLibraryVersion.usedInVideos
    };
};

export const getFieldsUsedInMapping = (dataLibraryVersion: GqlClientAccountDataLibraryVersionFragment) :Map<string, VideoInformationWithUsedInData[]> => {
    let mapDataFieldsToVideoInfo: Map<string, VideoInformationWithUsedInData[]> = new Map<string, VideoInformationWithUsedInData[]>();

    // Mapping used in video to field - program structure
    dataLibraryVersion.usedInVideos.map(program => {
        const usedElements: Record<string, UsedIn[]> = program.programVersionDraft?.usedElements ?? {};
        // go over all used elements, for every element in the program, keep distinct scene name
        Object.keys(usedElements).forEach((fieldId) => {

            // Check if there is a better way to extract scene name
            const sceneAndBrandNames = usedElements[fieldId].map(usedIn => {
                if ("sceneName" in usedIn) {
                    return usedIn.sceneName;
                }
                if (usedIn.usedInType === "brandByAudienceLogic") {
                    return "Brand by Audience";
                }
                return "";
            });

            const narrationPlaceholderNames = usedElements[fieldId]
                .filter(usedIn => usedIn.usedInType === "placeholder" && usedIn.placeholderType === CommonPlaceholderType.NARRATION)
                .map(usedIn => (usedIn as UsedInPlaceholder).placeholderName);

            const newVideoInfo: VideoInformationWithUsedInData = {
                id : program.id,
                scenesAndBrandInfo: new Set<string>(sceneAndBrandNames),
                narrationPlaceholders: new Set<string>(narrationPlaceholderNames),
                name: program.name,
                prodVersion: program.prodVersion,
                isArchive: program.isArchive,
                isLive: program.isLive,
                uatVersion: program.uatVersion,
                shareHistory: program.shareHistory
            };

            if (mapDataFieldsToVideoInfo.has(fieldId)) {
                mapDataFieldsToVideoInfo.get(fieldId).push(newVideoInfo);
            }
            else {
                mapDataFieldsToVideoInfo.set(fieldId, [newVideoInfo]);
            }
        });
    });

    return mapDataFieldsToVideoInfo;
};

export const convertSystemDynamicElementToDataLibraryDynamicElement = (dynamicElement: DynamicElement): DataLibraryDynamicElement => {
    return {
        id: dynamicElement.metadata.localId,
        localId: dynamicElement.metadata.localId,
        data: dynamicElement.serialize()
    };
};

export const isReservedIdWord = (name: string) => {
    return name && name.trim().toLowerCase() === IDENTIFIER_DYNAMIC_ELEMENT_NAME.toLowerCase();
};
