import { library } from "@fortawesome/fontawesome-svg-core";
// region faXXXXX
import { faAlignCenter } from "@fortawesome/pro-regular-svg-icons/faAlignCenter";
import { faAlignLeft } from "@fortawesome/pro-regular-svg-icons/faAlignLeft";
import { faAlignRight } from "@fortawesome/pro-regular-svg-icons/faAlignRight";
import { faAnalytics } from "@fortawesome/pro-regular-svg-icons/faAnalytics";
import { faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons/faAngleDoubleRight";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons/faAngleUp";
import { faArchive } from "@fortawesome/pro-regular-svg-icons/faArchive";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { faArrowsToLine } from "@fortawesome/pro-regular-svg-icons/faArrowsToLine";
import { faArrowUpToLine } from "@fortawesome/pro-regular-svg-icons/faArrowUpToLine";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faBold } from "@fortawesome/pro-regular-svg-icons/faBold";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faClone } from "@fortawesome/pro-regular-svg-icons/faClone";
import { faCogs } from "@fortawesome/pro-regular-svg-icons/faCogs";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faCommentAltLines } from "@fortawesome/pro-regular-svg-icons/faCommentAltLines";
import { faCompressAlt } from "@fortawesome/pro-regular-svg-icons/faCompressAlt";
import { faCrown } from "@fortawesome/pro-regular-svg-icons/faCrown";
import { faDesktop } from "@fortawesome/pro-regular-svg-icons/faDesktop";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons/faExclamationCircle";
import { faExpand } from "@fortawesome/pro-regular-svg-icons/faExpand";
import { faExpandAlt } from "@fortawesome/pro-regular-svg-icons/faExpandAlt";
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons/faExternalLinkSquare";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons/faFileAlt";
import { faFileChartLine } from "@fortawesome/pro-regular-svg-icons/faFileChartLine";
import { faFillDrip } from "@fortawesome/pro-regular-svg-icons/faFillDrip";
import { faFlask } from "@fortawesome/pro-regular-svg-icons/faFlask";
import { faFont } from "@fortawesome/pro-regular-svg-icons/faFont";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons/faGlobe";
import { faGlobeAmericas } from "@fortawesome/pro-regular-svg-icons/faGlobeAmericas";
import { faGripHorizontal } from "@fortawesome/pro-regular-svg-icons/faGripHorizontal";
import { faHandPointer } from "@fortawesome/pro-regular-svg-icons/faHandPointer";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { faImagePolaroid } from "@fortawesome/pro-regular-svg-icons/faImagePolaroid";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faItalic } from "@fortawesome/pro-regular-svg-icons/faItalic";
import { faLightbulb } from "@fortawesome/pro-regular-svg-icons/faLightbulb";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons/faLightbulbOn";
import { faLightbulbSlash } from "@fortawesome/pro-regular-svg-icons/faLightbulbSlash";
import { faLink } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faListOl } from "@fortawesome/pro-regular-svg-icons/faListOl";
import { faListUl } from "@fortawesome/pro-regular-svg-icons/faListUl";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faMobile } from "@fortawesome/pro-regular-svg-icons/faMobile";
import { faMobileAlt } from "@fortawesome/pro-regular-svg-icons/faMobileAlt";
import { faPaintbrush as faPaintBrush } from "@fortawesome/pro-regular-svg-icons/faPaintbrush";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons/faPenToSquare";
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faPlusSquare } from "@fortawesome/pro-regular-svg-icons/faPlusSquare";
import { faProjectDiagram } from "@fortawesome/pro-regular-svg-icons/faProjectDiagram";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faRedo } from "@fortawesome/pro-regular-svg-icons/faRedo";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { faSortAlt } from "@fortawesome/pro-regular-svg-icons/faSortAlt";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons/faSparkles";
import { faStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faStrikethrough } from "@fortawesome/pro-regular-svg-icons/faStrikethrough";
import { faSync } from "@fortawesome/pro-regular-svg-icons/faSync";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons/faSyncAlt";
import { faTabletAlt } from "@fortawesome/pro-regular-svg-icons/faTabletAlt";
import { faText } from "@fortawesome/pro-regular-svg-icons/faText";
import { faThLarge } from "@fortawesome/pro-regular-svg-icons/faThLarge";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons/faTrashCan";
import { faUnderline } from "@fortawesome/pro-regular-svg-icons/faUnderline";
import { faUndoAlt } from "@fortawesome/pro-regular-svg-icons/faUndoAlt";
import { faUserFriends } from "@fortawesome/pro-regular-svg-icons/faUserFriends";
import { faVolumeMute } from "@fortawesome/pro-regular-svg-icons/faVolumeMute";
import { faVolumeUp } from "@fortawesome/pro-regular-svg-icons/faVolumeUp";
import { faVolumeSlash } from "@fortawesome/pro-regular-svg-icons/faVolumeSlash";
import { faBell } from "@fortawesome/pro-regular-svg-icons/faBell";
import { faRectangleWide } from "@fortawesome/pro-regular-svg-icons/faRectangleWide";
import { faRectanglePortrait } from "@fortawesome/pro-regular-svg-icons/faRectanglePortrait";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faPlug } from "@fortawesome/pro-regular-svg-icons/faPlug";
import { faSmile } from "@fortawesome/pro-regular-svg-icons/faSmile";
import { faHeart } from "@fortawesome/pro-regular-svg-icons/faHeart";
import { faWaveform } from "@fortawesome/pro-regular-svg-icons/faWaveform";
import { faShareAlt } from "@fortawesome/pro-regular-svg-icons/faShareAlt";
import { faShare } from "@fortawesome/pro-regular-svg-icons/faShare";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faSquareArrowUpRight } from "@fortawesome/pro-regular-svg-icons/faSquareArrowUpRight";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faWaveformLines } from "@fortawesome/pro-regular-svg-icons/faWaveformLines";
import { faCommentLines } from "@fortawesome/pro-regular-svg-icons/faCommentLines";
import { faReply } from "@fortawesome/pro-regular-svg-icons/faReply";
import { faCaretDown } from "@fortawesome/pro-regular-svg-icons/faCaretDown";
import { faRobot } from "@fortawesome/pro-regular-svg-icons/faRobot";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faPlay } from "@fortawesome/pro-regular-svg-icons/faPlay";
import { faComments } from "@fortawesome/pro-regular-svg-icons/faComments";
import { faVideo as farVideo } from "@fortawesome/pro-regular-svg-icons/faVideo";
import { faPalette } from "@fortawesome/pro-regular-svg-icons/faPalette";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faUsersSlash } from "@fortawesome/pro-regular-svg-icons/faUsersSlash";
import { faObjectsColumn } from "@fortawesome/pro-regular-svg-icons/faObjectsColumn";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons/faLayerGroup";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import { faStars } from "@fortawesome/pro-regular-svg-icons/faStars";
// endregion
// region fasXXXXX
import { faAngleDown as fasAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft as fasAngleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight as fasAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faArrowAltToBottom as fasArrowAltToBottom } from "@fortawesome/pro-solid-svg-icons/faArrowAltToBottom";
import { faAt as fasAt } from "@fortawesome/pro-solid-svg-icons/faAt";
import { faBolt as fasBolt } from "@fortawesome/pro-solid-svg-icons/faBolt";
import { faBrowser as fasBrowser } from "@fortawesome/pro-solid-svg-icons/faBrowser";
import { faCaretDown as fasCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp as fasCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCheck as fasCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faClipboardCheck as fasClipboardCheck } from "@fortawesome/pro-solid-svg-icons/faClipboardCheck";
import { faCloudDownloadAlt as fasCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons/faCloudDownloadAlt";
import { faCog as fasCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faCrown as fasCrown } from "@fortawesome/pro-solid-svg-icons/faCrown";
import { faDesktopAlt as fasDesktopAlt } from "@fortawesome/pro-solid-svg-icons/faDesktopAlt";
import { faEllipsisH as fasEllipsisH } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { faEllipsisV as fasEllipsisV } from "@fortawesome/pro-solid-svg-icons/faEllipsisV";
import { faExchangeAlt as fasExchangeAlt } from "@fortawesome/pro-solid-svg-icons/faExchangeAlt";
import { faFile as fasFile } from "@fortawesome/pro-solid-svg-icons/faFile";
import { faInboxIn as fasInboxIn } from "@fortawesome/pro-solid-svg-icons/faInboxIn";
import { faLink as fasLink } from "@fortawesome/pro-solid-svg-icons/faLink";
import { faMinusCircle as fasMinusCircle } from "@fortawesome/pro-solid-svg-icons/faMinusCircle";
import { faPen as fasPen } from "@fortawesome/pro-solid-svg-icons/faPen";
import { faPlay as fasPlay } from "@fortawesome/pro-solid-svg-icons/faPlay";
import { faPlayCircle as fasPlayCircle } from "@fortawesome/pro-solid-svg-icons/faPlayCircle";
import { faPause as fasPause } from "@fortawesome/pro-solid-svg-icons/faPause";
import { faPlus as fasPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faPlusCircle as fasPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faShareSquare as fasShareSquare } from "@fortawesome/pro-solid-svg-icons/faShareSquare";
import { faShare as fasShare } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faTimesCircle as fasTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faVolumeDown as fasVolumeDown } from "@fortawesome/pro-solid-svg-icons/faVolumeDown";
import { faVolumeMute as fasVolumeMute } from "@fortawesome/pro-solid-svg-icons/faVolumeMute";
import { faVolumeUp as fasVolumeUp } from "@fortawesome/pro-solid-svg-icons/faVolumeUp";
import { faVolumeSlash as fasVolumeSlash } from "@fortawesome/pro-solid-svg-icons/faVolumeSlash";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faBars as fasBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faMapMarkerAlt as fasMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faEnvelope as fasEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faCommentAlt as fasCommentAlt } from "@fortawesome/pro-solid-svg-icons/faCommentAlt";
import { faSms as fasSms } from "@fortawesome/pro-solid-svg-icons/faSms";
import { faTimes as fasTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faTv as fasTV } from "@fortawesome/pro-solid-svg-icons/faTv";
import { faListOl as fasListOL } from "@fortawesome/pro-solid-svg-icons/faListOl";
import { faQuestion as fasQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { faQuestionCircle as fasQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faFileChartLine as fasFileChartLine } from "@fortawesome/pro-solid-svg-icons/faFileChartLine";
import { faMinus as fasMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faFileDownload as fasFileDownload } from "@fortawesome/pro-solid-svg-icons/faFileDownload";
import { faInfoCircle as fasInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faHistory as fasHistory } from "@fortawesome/pro-solid-svg-icons/faHistory";
import { faVideo as fasVideo } from "@fortawesome/pro-solid-svg-icons/faVideo";
import { faImage as fasImage } from "@fortawesome/pro-solid-svg-icons/faImage";
import { faEye as fasEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faCopy as fasCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faMicrophone as fasMicrophon } from "@fortawesome/pro-solid-svg-icons/faMicrophone";
import { faClock as fasClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faCaretCircleDown as fasCaretCircleDown } from "@fortawesome/pro-solid-svg-icons/faCaretCircleDown";
import { faTrashAlt as fasTrashAlt } from "@fortawesome/pro-solid-svg-icons/faTrashAlt";
import { faSpinner as fasSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner";
import { faFilter as fasFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";
import { faExpand as fasExpand } from "@fortawesome/pro-solid-svg-icons/faExpand";
import { faWifi as fasWifi } from "@fortawesome/pro-solid-svg-icons/faWifi";
import { faWifiSlash as fasWifiSlash } from "@fortawesome/pro-solid-svg-icons/faWifiSlash";
import { faSyncAlt as fasSyncAlt } from "@fortawesome/pro-solid-svg-icons/faSyncAlt";
import { faSlidersV as fasSlidersV } from "@fortawesome/pro-solid-svg-icons/faSlidersV";
import { faEyeSlash as fasEyeSlash } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faUsersSlash as fasUsersSlash } from "@fortawesome/pro-solid-svg-icons/faUsersSlash";
import { faExclamationTriangle as fasExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faFileCsv as fasFileCsv } from "@fortawesome/pro-solid-svg-icons/faFileCsv";
import { faSearch as fasSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faSortDown as fasSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown";
import { faVolume as fasVolume } from "@fortawesome/pro-solid-svg-icons/faVolume";
import { faHeart as fasHeart } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faReply as fasReply } from "@fortawesome/pro-solid-svg-icons/faReply";
import { faRefresh as faRefresh } from "@fortawesome/pro-solid-svg-icons/faRefresh";
// endregion
// region falXXXXX
import { faArrowDown as falArrowDown } from "@fortawesome/pro-light-svg-icons/faArrowDown";
import { faArrowLeft as falArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faArrowRight as falArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";
import { faArrowUp as falArrowUp } from "@fortawesome/pro-light-svg-icons/faArrowUp";
import { faBars as falBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { faBullseyeArrow as falBullseyeArrow } from "@fortawesome/pro-light-svg-icons/faBullseyeArrow";
import { faCheck as falCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faClipboardList as falClipboardList } from "@fortawesome/pro-light-svg-icons/faClipboardList";
import { faCloudUpload as falCloudUpload } from "@fortawesome/pro-light-svg-icons/faCloudUpload";
import { faCogs as falCogs } from "@fortawesome/pro-light-svg-icons/faCogs";
import { faCommentLines as falCommentLines } from "@fortawesome/pro-light-svg-icons/faCommentLines";
import { faCopyright as falCopyright } from "@fortawesome/pro-light-svg-icons/faCopyright";
import { faDatabase as falDatabase } from "@fortawesome/pro-light-svg-icons/faDatabase";
import { faExclamationTriangle as falExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { faFill as falFill } from "@fortawesome/pro-light-svg-icons/faFill";
import { faFilmAlt as falFilmAlt } from "@fortawesome/pro-light-svg-icons/faFilmAlt";
import { faFont as falFont } from "@fortawesome/pro-light-svg-icons/faFont";
import { faHandPointer as falHandPointer } from "@fortawesome/pro-light-svg-icons/faHandPointer";
import { faImage as falImage } from "@fortawesome/pro-light-svg-icons/faImage";
import { faImagePolaroid as falImagePolaroid } from "@fortawesome/pro-light-svg-icons/faImagePolaroid";
import { faListUl as falListUl } from "@fortawesome/pro-light-svg-icons/faListUl";
import { faMapMarkerAlt as falMapMarkerAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt";
import { faMicrophone as falMicrophone } from "@fortawesome/pro-light-svg-icons/faMicrophone";
import { faMouse as falMouse } from "@fortawesome/pro-light-svg-icons/faMouse";
import { faMusic as falMusic } from "@fortawesome/pro-light-svg-icons/faMusic";
import { faPalette as falPalette } from "@fortawesome/pro-light-svg-icons/faPalette";
import { faPaperPlane as falPaperPlane } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import { faPhotoVideo as falPhotoVideo } from "@fortawesome/pro-light-svg-icons/faPhotoVideo";
import { faProjectDiagram as falProjectDiagram } from "@fortawesome/pro-light-svg-icons/faProjectDiagram";
import { faStar as falStar } from "@fortawesome/pro-light-svg-icons/faStar";
import { faStars as falStars } from "@fortawesome/pro-light-svg-icons/faStars";
import { faText as falText } from "@fortawesome/pro-light-svg-icons/faText";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faTrashAlt as falTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";
import { faTrophyAlt as falTrophyAlt } from "@fortawesome/pro-light-svg-icons/faTrophyAlt";
import { faUser as falUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faVectorSquare as falVectorSquare } from "@fortawesome/pro-light-svg-icons/faVectorSquare";
import { faVideo as falVideo } from "@fortawesome/pro-light-svg-icons/faVideo";
import { faVolumeMute as falVolumeMute } from "@fortawesome/pro-light-svg-icons/faVolumeMute";
import { faCloudDownload as falCloudDownload } from "@fortawesome/pro-light-svg-icons/faCloudDownload";
import { faRectangleWide as falRectangleWide } from "@fortawesome/pro-light-svg-icons/faRectangleWide";
import { faRectanglePortrait as falRectanglePortrait } from "@fortawesome/pro-light-svg-icons/faRectanglePortrait";
import { faSquare as falSquare } from "@fortawesome/pro-light-svg-icons/faSquare";
import { faGlobe as falGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faFileCsv as falFileCsv } from "@fortawesome/pro-light-svg-icons/faFileCsv";
import { faPlug as falPlug } from "@fortawesome/pro-light-svg-icons/faPlug";
import { faLink as falLink } from "@fortawesome/pro-light-svg-icons/faLink";
import { faFilter as falFilter } from "@fortawesome/pro-light-svg-icons/faFilter";
// endregion
// region fabXXXXX
import { faFacebook as fabFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faFacebookSquare as fabFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faYoutube as fabYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLinkedin as fabLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faLinkedinIn as fabLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faInstagram as fabInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faUserGear } from "@fortawesome/pro-regular-svg-icons/faUserGear";

// endregion

function loadIcons() {
    library.add(
        // region faXXXXX
        faAlignCenter,
        faAlignLeft,
        faAlignRight,
        faAngleDoubleLeft,
        faAnalytics,
        faAngleDown,
        faAngleUp,
        faAngleLeft,
        faAngleRight,
        faArrowDownToLine,
        faArrowsToLine,
        faArrowUpToLine,
        faCircleNotch,
        faCheck,
        faCrown,
        faEllipsisH,
        faEllipsisV,
        faExclamationCircle,
        faExpand,
        faFont,
        faMinus,
        faMobileAlt,
        faMobile,
        faDesktop,
        faPen,
        faPenToSquare,
        faPlayCircle,
        faPlus,
        faPlusSquare,
        faQuestionCircle,
        faRedo,
        faStar,
        faTabletAlt,
        faVolumeMute,
        faVolumeSlash,
        faVolumeUp,
        faAngleDoubleRight,
        faLink,
        faPaintBrush,
        faCommentAltLines,
        faCheckCircle,
        faChevronRight,
        faTimes,
        faProjectDiagram,
        fasHistory,
        fasEye,
        faInfoCircle,
        faBold,
        faItalic,
        faStrikethrough,
        faUnderline,
        faListOl,
        faListUl,
        faTrashAlt,
        faTrashCan,
        faFileChartLine,
        faUndoAlt,
        faSync,
        fasEye,
        faHandPointer,
        faHistory,
        faFillDrip,
        faCogs,
        faText,
        faImagePolaroid,
        faExpandAlt,
        faCompressAlt,
        faGlobeAmericas,
        faGlobe,
        faEye,
        faLightbulbSlash,
        faLightbulb,
        faLightbulbOn,
        faClone,
        faSyncAlt,
        faCircle,
        faExternalLinkSquare,
        faSparkles,
        faSearch,
        faSortAlt,
        faThLarge,
        faUserFriends,
        faSignOut,
        faBell,
        faArchive,
        faClock,
        faCopy,
        faFileAlt,
        faRectangleWide,
        faRectanglePortrait,
        faSquare,
        faDownload,
        faFlask,
        faPlug,
        faSmile,
        faHeart,
        faWaveform,
        faShareAlt,
        faShare,
        faExclamationTriangle,
        faGripHorizontal,
        faBars,
        faArrowDown,
        faArrowLeft,
        faArrowRight,
        faArrowUp,
        faSquareArrowUpRight,
        faXmark,
        faWaveformLines,
        faCommentLines,
        faReply,
        faRefresh,
        faCaretDown,
        faRobot,
        faPlay,
        faUserPlus,
        faComments,
        farVideo,
        faUsers,
        faUsersSlash,
        faObjectsColumn,
        faPalette,
        faUserGear,
        faLayerGroup,
        faShieldCheck,
        faStars,
        // endregion

        // region fasXXXXX
        fasAngleDown,
        fasAngleLeft,
        fasAngleRight,
        fasArrowAltToBottom,
        fasAt,
        fasBolt,
        fasBrowser,
        fasCaretDown,
        fasCaretUp,
        fasCheck,
        fasCheckCircle,
        fasCloudDownloadAlt,
        fasClipboardCheck,
        fasCog,
        fasCrown,
        fasDesktopAlt,
        fasEllipsisH,
        fasEllipsisV,
        fasExchangeAlt,
        fasFile,
        fasInboxIn,
        fasLink,
        fasMinusCircle,
        fasPen,
        fasPlay,
        fasPlayCircle,
        fasPause,
        fasPlus,
        fasPlusCircle,
        fasShareSquare,
        fasShare,
        fasStar,
        fasTimesCircle,
        fasVolumeDown,
        fasVolumeMute,
        fasVolumeUp,
        fasVolumeSlash,
        fasBars,
        fasMapMarkerAlt,
        fasEnvelope,
        fasCommentAlt,
        fasSms,
        fasTimes,
        fasTV,
        fasListOL,
        fasQuestion,
        fasQuestionCircle,
        fasMinus,
        fasFileDownload,
        fasInfoCircle,
        fasImage,
        fasVideo,
        fasCopy,
        fasEye,
        fasMicrophon,
        fasClock,
        fasCaretCircleDown,
        fasTrashAlt,
        fasSpinner,
        fasFileChartLine,
        fasFilter,
        fasExpand,
        fasWifi,
        fasWifiSlash,
        fasSyncAlt,
        fasSlidersV,
        fasEyeSlash,
        fasUsersSlash,
        fasExclamationTriangle,
        fasFileCsv,
        fasSearch,
        fasSortDown,
        fasVolume,
        fasHeart,
        faFileExport,
        falPaperPlane,
        falRectangleWide,
        falRectanglePortrait,
        falSquare,
        falGlobe,
        falFileCsv,
        falPlug,
        falLink,
        falFilter,
        falExclamationTriangle,
        falCommentLines,
        // endregion

        // region falXXXXX
        falStar,
        falVolumeMute,
        falCloudUpload,
        falDatabase,
        falListUl,
        falArrowUp,
        falArrowDown,
        falMapMarkerAlt,
        falBullseyeArrow,
        falCopyright,
        falFilmAlt,
        falPalette,
        falImagePolaroid,
        falClipboardList,
        falProjectDiagram,
        falBars,
        falTimes,
        falUser,
        falArrowRight,
        falArrowLeft,
        falStars,
        falFill,
        falFont,
        falMicrophone,
        falMusic,
        falText,
        falImage,
        falVideo,
        falTrashAlt,
        falVectorSquare,
        falPhotoVideo,
        falMouse,
        falCogs,
        falHandPointer,
        falTrophyAlt,
        falCheck,
        falCloudDownload,
        fasReply,
        // endregion

        // region fabXXXXX
        fabFacebookSquare,
        fabYoutube,
        fabFacebook,
        fabLinkedin,
        fabLinkedinIn,
        fabTwitter,
        fabInstagram,
        // endregion
    );
}

export default loadIcons;
