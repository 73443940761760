import type {
    GqlClientEditorAccountLevelDataFontsQuery,
    GqlClientEditorAccountLevelDataFontsQueryVariables,
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientUploadEditorAssetFontInput,
    GqlClientUploadEditorAssetFontMutation,
    GqlClientUploadEditorAssetFontMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    EditorAccountLevelDataFontsDocument,
    GqlClientAssetLifecycleStage,
    UploadEditorAssetFontDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { fileTypesWhitelist } from "../../../../../common/commonConst";
import { getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../../../common/errors";
import { getApolloClient } from "../../../../apollo";
import AssetUtils from "../../../../logic/common/assetUtils";
import { v4 as uuid } from "uuid";
import type { QueryOptions } from "@apollo/client/core/watchQueryOptions";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import { assetLibraryFontsManagerVar } from "../../State";

const allowedMimeTypes = fileTypesWhitelist.filter((fileType) => fileType.startsWith("font") && !fileType.endsWith("woff"));

export const uploadEditorFont = async (
    input: Omit<GqlClientUploadEditorAssetFontInput, "fileType" | "uploadId">
): Promise<void> => {
    let fileMimeType: string;
    const uploadId = uuid();
    
    try {
        fileMimeType = await AssetUtils.getFileType(input.file);
        if (!allowedMimeTypes.includes(fileMimeType)) {
            throw new Error("Illegal font file was uploaded. You may upload only otf, ttf files");
        }
    }
    catch (err) {
        throw new EnhancedError(err, SskyErrorCode.UnsupportedAssetFontType);
    }

    try {
        await getApolloClient().mutate<GqlClientUploadEditorAssetFontMutation, GqlClientUploadEditorAssetFontMutationVariables>({
            mutation: UploadEditorAssetFontDocument,
            variables: { input: { ...input, fileType: fileMimeType, uploadId } }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }

    return new Promise<void>((resolve, reject) => {
        const checkUploadSlugStatus = async (): Promise<void> => {
            try {
                const fetchOptions: QueryOptions<GqlClientEditorAccountLevelDataFontsQueryVariables> = {
                    query: EditorAccountLevelDataFontsDocument
                };
                if (process.env.JEST_WORKER_ID === undefined) {
                    fetchOptions.fetchPolicy = "network-only";
                }
                const { data } = await getApolloClient().query<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>(
                    fetchOptions
                );
                const fonts = data.editorAccountLevelData.assetLibraryVersion.fonts;
                const uploadedFont = fonts.find(font => font.uploadId === uploadId);

                switch (uploadedFont?.lifecycleStage) {
                    case GqlClientAssetLifecycleStage.FAILED:
                        return reject(new EnhancedError(
                            new Error("Slug creation error"),
                            SskyErrorCode.AssetFontSlugCreationError
                        ));
                    case GqlClientAssetLifecycleStage.DISABLED:
                        setTimeout(async () => await checkUploadSlugStatus(), 1000);
                        break;
                    case GqlClientAssetLifecycleStage.ENABLED:
                        return resolve();   
                    default:
                        return reject(new EnhancedError(
                            new Error(`Unknown lifecycle stage: ${uploadedFont?.lifecycleStage}`),
                            SskyErrorCode.AssetFontSlugCreationError
                        ));
                }
            }
            catch (err) {
                const gqlError = err.graphQLErrors?.[0];
                const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.AssetFontSlugCreationError;

                reject(new EnhancedError(gqlError || err, sskyErrorCode));
            }
        };

        checkUploadSlugStatus();
    });
};

export const getAssetLibraryFonts = (): GqlClientEditorAssetLibraryFontFragment[] => {
    const fontManager = assetLibraryFontsManagerVar();
    return fontManager.getFonts();
};
