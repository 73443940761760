import { useReactiveVar } from "@apollo/client";
import { localEditorSelectedPlaceholderIdVar } from "../../State";
import type { PlaceholderId, StandardSetterFn } from "../../types";
import { selectPlaceholder } from "../../Nooks";

export type UseProjectSelectedPlaceholderId = {
    projectSelectedPlaceholderId: PlaceholderId;
    setProjectSelectedPlaceholderId: StandardSetterFn<PlaceholderId>;
};

export const useProjectSelectedPlaceholderId = (): UseProjectSelectedPlaceholderId => {
    const projectSelectedPlaceholderId = useReactiveVar(localEditorSelectedPlaceholderIdVar);

    return { projectSelectedPlaceholderId, setProjectSelectedPlaceholderId: selectPlaceholder };
};
