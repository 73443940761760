import { useReactiveVar } from "@apollo/client";
import { localEditorShowPronunciationLibraryVar } from "../../State";
import { setShowPronunciationLibrary } from "../../Nooks";

export type UseEditorShowPronunciationLibrary = {
    showPronunciationLibrary: boolean;
    setShowPronunciationLibrary: (isOpen: boolean) => void;
};

export const useEditorShowPronunciationLibrary = (): UseEditorShowPronunciationLibrary => {
    const showPronunciationLibrary = useReactiveVar(localEditorShowPronunciationLibraryVar);

    return { showPronunciationLibrary, setShowPronunciationLibrary };
};
