import type {
    GqlClientEditorLibraryThemeFragment,
    GqlClientEditorLibraryWireframeFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";

const SEPARATOR = "_";

export const constructWireframeThemeSupportKey = (wireframeId: string, themeId: string): string => {
    return `${wireframeId}${SEPARATOR}${themeId}`;
};

export const deconstructWireframeThemeSupportKey = (key: string): { wireframeId: string; themeId: string } => {
    const [wireframeId, themeId] = key.split(SEPARATOR);
    return { wireframeId, themeId };
};

export const createWireframeThemeSupport = (wireframes: GqlClientEditorLibraryWireframeFragment[], themes: GqlClientEditorLibraryThemeFragment[]) => {
    const wireframeThemeSupport = {};

    for (const wireframe of wireframes) {
        for (const theme of themes) {
            wireframeThemeSupport[constructWireframeThemeSupportKey(wireframe.id, theme.id)] = wireframe.animatedWireframes.some(aw => aw.theme.id === theme.id);
        }
    }

    return wireframeThemeSupport;
};