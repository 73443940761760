import { gql } from "@apollo/client";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreateEditorAssetFolderMutation,
    GqlClientCreateEditorAssetFolderMutationVariables,
    GqlClientDeleteEditorAssetFolderMutation,
    GqlClientDeleteEditorAssetFolderMutationVariables,
    GqlClientDeleteEditorAssetFolderOutput,
    GqlClientDeleteEditorLibraryAssetMediaMutation,
    GqlClientDeleteEditorLibraryAssetMediaMutationVariables,
    GqlClientEditorAccountLevelDataMediaQuery,
    GqlClientEditorAccountLevelDataMediaQueryVariables,
    GqlClientEditorAssetFolder,
    GqlClientEditorAssetFolderFragment,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientMoveEditorAssetToFolderMutation,
    GqlClientMoveEditorAssetToFolderMutationVariables,
    GqlClientMoveEditorFolderToFolderMutation,
    GqlClientMoveEditorFolderToFolderMutationVariables,
    GqlClientRenameEditorAssetFolderMutation,
    GqlClientRenameEditorAssetFolderMutationVariables,
    GqlClientRenameEditorAssetFolderOutput,
    GqlClientUpdateEditorAssetMediaMutation,
    GqlClientUpdateEditorAssetMediaMutationVariables,
    GqlClientUpdateEditorAssetMediaResult
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientMediaClassification,
    CreateEditorAssetFolderDocument,
    DeleteEditorAssetFolderDocument,
    DeleteEditorLibraryAssetMediaDocument,
    EditorAccountLevelDataMediaDocument,
    EditorAssetLibraryAccountMediaDocument,
    GqlClientAssetLifecycleStage,
    GqlClientDeleteEditorAssetMediaResult,
    GqlClientMediaOrigin,
    GqlClientMoveEditorAssetToFolderResult,
    GqlClientMoveEditorFolderToFolderResult,
    MoveEditorAssetToFolderDocument,
    MoveEditorFolderToFolderDocument,
    RenameEditorAssetFolderDocument,
    UpdateEditorAssetMediaDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { IGNORE_UPDATED } from "../../../../logic/common/Consts";
import type { GqlMediaById, ProgramId, ProgramVersionId } from "../../types";
import { MediaLibraryActiveTab } from "../../types";
import { closeNotification, setSuccessNotification } from "../Notification";
import { assetMediaVar } from "../../../model";
import { convertArrayOfObjectsToObject } from "../../../../../common/arrayUtils";
import { getLocalIdFromGqlId } from "../../../../../common/idUtils";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";

export const goToFolder = (
    destinationFolderId: string,
    setActiveTab: (activeTab: MediaLibraryActiveTab) => void,
    setCurrentFolderId: (currentFolderId: string | null) => void
) => {
    closeNotification();
    setActiveTab(MediaLibraryActiveTab.YourLibrary);
    setCurrentFolderId(destinationFolderId);
};

export const deleteTruffleAssetMedia = async (id: string): Promise<boolean> => {
    try {
        const result = await getApolloClient().mutate<GqlClientDeleteEditorLibraryAssetMediaMutation, GqlClientDeleteEditorLibraryAssetMediaMutationVariables>({
            mutation: DeleteEditorLibraryAssetMediaDocument,
            variables: { input: { id, updated: IGNORE_UPDATED } }
        });

        if (result?.errors) {
            return false;
        }

        return result?.data.deleteEditorAssetMedia.result !== GqlClientDeleteEditorAssetMediaResult.USED_IN_REFUSAL;
    }
    catch {
        return false;
    }
};

export const getFolderName = (folderId: string): string | null | undefined => {
    const folder = getApolloClient().cache.readFragment<{name: string; parentFolder: GqlClientEditorAssetFolder;}>({
        id: `EditorAssetFolder:${folderId}`,
        fragment: gql`
            fragment FolderName on EditorAssetFolder {
                name
                parentFolder {
                    id
                }
            }
        `
    });

    //Return null for the root folder
    return folder ? (folder.parentFolder ? folder.name : null) : undefined;
};

export const updateEditorAssetMedia = async (assetId: string, name: string, description: string): Promise<GqlClientUpdateEditorAssetMediaResult> => {
    const inputVars = { assetId, name, description };
    const result = await getApolloClient().mutate<GqlClientUpdateEditorAssetMediaMutation, GqlClientUpdateEditorAssetMediaMutationVariables>({
        mutation: UpdateEditorAssetMediaDocument,
        variables: { input: inputVars }
    });
    return result?.data?.updateEditorAssetMedia?.result;

};

export const renameEditorAssetFolder = async (folderId: string, folderName: string, parentFolderId: string): Promise<GqlClientRenameEditorAssetFolderOutput> => {
    const result = await getApolloClient().mutate<GqlClientRenameEditorAssetFolderMutation, GqlClientRenameEditorAssetFolderMutationVariables>({
        mutation: RenameEditorAssetFolderDocument,
        variables: { input: { folderId, folderName, parentFolderId } }
    });
    return result.data.renameEditorAssetFolder;
};

export const deleteEditorAssetFolder = async (folderId: string): Promise<GqlClientDeleteEditorAssetFolderOutput> => {
    const result = await getApolloClient().mutate<GqlClientDeleteEditorAssetFolderMutation, GqlClientDeleteEditorAssetFolderMutationVariables>({
        mutation: DeleteEditorAssetFolderDocument,
        variables: { input: { folderId } }
    });
    return result.data.deleteEditorAssetFolder;
};

export const moveEditorAssetToFolder = async (
    assetId: string,
    assetName: string,
    destinationFolderId: string,
    setActiveTab: (activeTab: MediaLibraryActiveTab) => void,
    setCurrentFolderId: (currentFolderId: string | null) => void,
    onMoveSuccess?: (folderId: string) => void,
    showSuccessNotification: boolean = true
): Promise<void> => {
    const destinationFolderName = getFolderName(destinationFolderId);

    const result = await getApolloClient().mutate<GqlClientMoveEditorAssetToFolderMutation, GqlClientMoveEditorAssetToFolderMutationVariables>({
        mutation: MoveEditorAssetToFolderDocument,
        variables: { input: { assetId, destinationFolderId, assetName } }
    });

    if (result.data.moveEditorAssetToFolder.result === GqlClientMoveEditorAssetToFolderResult.SUCCESS) {
        showSuccessNotification && setSuccessNotification({
            message: `Media asset moved to ${destinationFolderName ? "folder" : "Your Media"}`,
            showCloseButton: true,
            actionButton: {
                label: `Go to ${destinationFolderName ? "Folder" : "Your Media"}`,
                onClick: () => goToFolder(destinationFolderId, setActiveTab, setCurrentFolderId)
            }
        });
        onMoveSuccess?.(destinationFolderId);
    }
};

export const moveEditorFolderToFolder = async (
    folderId: string,
    folderName: string,
    destinationFolderId: string,
    setActiveTab: (activeTab: MediaLibraryActiveTab) => void,
    setCurrentFolderId: (currentFolderId: string | null) => void,
    onMoveSuccess?: (folderId: string) => void,
    showSuccessNotification: boolean = true
): Promise<void> => {
    const destinationFolderName = getFolderName(destinationFolderId);

    const result = await getApolloClient().mutate<GqlClientMoveEditorFolderToFolderMutation, GqlClientMoveEditorFolderToFolderMutationVariables>({
        mutation: MoveEditorFolderToFolderDocument,
        variables: { input: { folderId, destinationFolderId, folderName } }
    });

    if (result.data.moveEditorFolderToFolder.result === GqlClientMoveEditorFolderToFolderResult.SUCCESS) {
        showSuccessNotification && setSuccessNotification({
            message: `Folder moved to ${destinationFolderName ? "folder" : "Your Media"}`,
            showCloseButton: true,
            actionButton: {
                label: `Go to ${destinationFolderName ? "Folder" : "Your Media"}`,
                onClick: () => {
                    closeNotification();
                    setActiveTab(MediaLibraryActiveTab.YourLibrary);
                    setCurrentFolderId(destinationFolderId);
                }
            }
        });
        onMoveSuccess?.(destinationFolderId);
    }
};

export function getAssetLibrary(
    programId: ProgramId,
    programVersionId: ProgramVersionId
) {
    const data = getApolloClient().readQuery({
        query: EditorAssetLibraryAccountMediaDocument,
        variables: {
            programId,
            programVersionId
        }
    });

    return data?.editorProgram?.programVersion?.assetLibrary;
}

export function getRootFolder(
    programId: ProgramId,
    programVersionId: ProgramVersionId
): GqlClientEditorAssetFolder {
    const assetLibrary = getAssetLibrary(programId, programVersionId);
    if (assetLibrary) {
        const folders = assetLibrary.mediaFolders ?? [];
        return folders.find(folder => folder.parentFolder === null);
    }
}

export const createEditorAssetFolder = async (newFolderName: string, parentFolderId: string): Promise<string> => {
    const mutationResponse = await getApolloClient().mutate<GqlClientCreateEditorAssetFolderMutation, GqlClientCreateEditorAssetFolderMutationVariables>({
        mutation: CreateEditorAssetFolderDocument,
        variables: {
            input: {
                name: newFolderName,
                parentId: parentFolderId
            }
        }
    });

    if (mutationResponse.data.createEditorAssetFolder.__typename === "CreateEditorAssetFolderOutputSuccess") {
        return mutationResponse.data.createEditorAssetFolder.folder.id;
    }
    else {
        return null;
    }
};

export const generateAssetNonGettyPremiumFilter = (): ((asset: GqlClientEditorAssetLibraryMediaFragment) => boolean) => {
    const isGettyPremiumExpiredEnabled = isFeatureEnabled(featureFlagConst._EDITOR_EXPIRE_GETTY_PREMIUM_ASSETS);
    return isGettyPremiumExpiredEnabled
        // We only allow assets that aren't from Getty, OR that are from Getty but aren't premium
        ? (asset) => asset.mediaOrigin !== "external" || asset.externalLibrary !== "getty" || asset.externalSublibrary !== "premium"
        : () => true;
};


export type AssetMediaVar = {
    loading: boolean;
    assetLibraryMedia: GqlClientEditorAssetLibraryMediaFragment[];
    assetLibraryMediaByGqlMediaId: GqlMediaById;
    assetLibraryMediaByLocalMediaId: GqlMediaById;
    surrogateAssetMediaByMediaId: GqlMediaById;
    surrogateAssetMediaByName: GqlMediaById;
    availableAssetsInYourLibraryById: GqlMediaById;
    availableAssetsForSaveAsById: GqlMediaById;
    hiddenGettyPremiumExpiredMedia: GqlClientEditorAssetLibraryMediaFragment[];
    assetLibraryId: string;
    mediaFolders: GqlClientEditorAssetFolderFragment[];
    rootFolder: GqlClientEditorAssetFolderFragment;
    foldersById: Map<string, GqlClientEditorAssetFolderFragment>;
    pathFromRootToFoldersById: Map<string, { id: string, name: string }[]>;
    foldersByParentFolderId: Map<string, GqlClientEditorAssetFolderFragment[]>;
    mediaByFolderId: Map<string, GqlClientEditorAssetLibraryMediaFragment[]>;
}

export function handleLibraryMediaUpdate(assetLibrary: GqlClientEditorAccountLevelDataMediaQuery["editorAccountLevelData"]["assetLibraryVersion"]) {
    const assetLibraryMedia: GqlClientEditorAssetLibraryMediaFragment[] = assetLibrary?.media ?? [];
    const assetLibraryId = assetLibrary?.id ?? "";
    const mediaFolders = assetLibrary?.mediaFolders ?? [];
    const assetLibraryMediaByGqlMediaId: GqlMediaById = convertArrayOfObjectsToObject(assetLibraryMedia, "id");
    const assetLibraryMediaByLocalMediaId: GqlMediaById = assetLibraryMedia.reduce((acc: GqlMediaById, asset: GqlClientEditorAssetLibraryMediaFragment) => {
        acc[getLocalIdFromGqlId(asset.id)] = asset;
        return acc;
    }, {});

    const surrogateAssets = assetLibraryMedia.filter(asset => asset.mediaOrigin === GqlClientMediaOrigin.SURROGATE);
    const surrogateAssetMediaByMediaId: GqlMediaById = convertArrayOfObjectsToObject(surrogateAssets, "id");
    const surrogateAssetMediaByName: GqlMediaById = convertArrayOfObjectsToObject(surrogateAssets, "name");

    const nonGettyPremiumFilter = generateAssetNonGettyPremiumFilter();

    // Filter the available media, but don't run the "getty premium filter" yet, we'll do that in the next block.
    const initialAvailableAssetsForSaveAs = assetLibraryMedia.filter(asset => (
        (asset.mediaOrigin === GqlClientMediaOrigin.USER
            || asset.mediaOrigin === GqlClientMediaOrigin.EXTERNAL
            || asset.mediaOrigin === GqlClientMediaOrigin.RECORDER
            || (isFeatureEnabled(featureFlagConst._EDITOR_GENERATE_IMAGE_WITH_AI) && asset.mediaOrigin === GqlClientMediaOrigin.AI_GENERATED))
    ));

    const initialAvailableAssetsInYourLibrary = initialAvailableAssetsForSaveAs.filter(asset => (
        asset.lifecycleStage === GqlClientAssetLifecycleStage.ENABLED &&
        asset.mediaClassification !== GqlClientMediaClassification.HIDDEN
    ));

    const availableAssetsForSaveAs = initialAvailableAssetsForSaveAs.filter(asset => nonGettyPremiumFilter(asset));
    const availableAssetsInYourLibrary = initialAvailableAssetsInYourLibrary.filter(asset => nonGettyPremiumFilter(asset));
    const hiddenGettyPremiumExpiredMedia = initialAvailableAssetsInYourLibrary.filter(asset => !nonGettyPremiumFilter(asset));

    const availableAssetsForSaveAsById = convertArrayOfObjectsToObject(availableAssetsForSaveAs, "id");
    const availableAssetsInYourLibraryById = convertArrayOfObjectsToObject(availableAssetsInYourLibrary, "id");

    // Folder stuff
    const rootFolder = mediaFolders.find((folder) => !folder.parentFolder);
    const foldersById: Map<string, GqlClientEditorAssetFolderFragment> = new Map(mediaFolders.map((folder) => [folder.id, folder]));
    const foldersByParentFolderId = new Map<string, GqlClientEditorAssetFolderFragment[]>();
    for (const folder of mediaFolders) {
        if (!folder.parentFolder) continue;
        const folders = foldersByParentFolderId.get(folder.parentFolder.id) ?? [];
        folders.push(folder);
        foldersByParentFolderId.set(folder.parentFolder.id, folders);
    }

    const pathFromRootToFoldersById: Map<string, { id: string, name: string }[]> = new Map<string, {id: string; name: string}[]>();
    for (const folder of mediaFolders) {
        const path: { id: string, name: string }[] = [];
        let curFolder = folder;
        while (curFolder) {
            path.unshift({ id: curFolder.id, name: curFolder.parentFolder ? curFolder.name : "Your Media" });
            curFolder = curFolder.parentFolder ? foldersById.get(curFolder.parentFolder.id) : null;
        }

        pathFromRootToFoldersById.set(folder.id, path);
    }

    const mediaByFolderId = new Map<string, GqlClientEditorAssetLibraryMediaFragment[]>();
    for (const asset of assetLibraryMedia) {
        if (!asset.folder) continue;
        const assets = mediaByFolderId.get(asset.folder.id) ?? [];
        assets.push(asset);
        mediaByFolderId.set(asset.folder.id, assets);
    }

    assetMediaVar({
        loading: false,
        assetLibraryMedia,
        assetLibraryMediaByGqlMediaId,
        assetLibraryMediaByLocalMediaId,
        availableAssetsInYourLibraryById,
        availableAssetsForSaveAsById,
        surrogateAssetMediaByMediaId,
        surrogateAssetMediaByName,
        hiddenGettyPremiumExpiredMedia,
        assetLibraryId,
        mediaFolders,
        foldersById,
        pathFromRootToFoldersById,
        foldersByParentFolderId,
        rootFolder,
        mediaByFolderId
    });
}

export const loadMediaData = () => {
    const { watchQuery } = getApolloClient();
    const subscription = watchQuery<GqlClientEditorAccountLevelDataMediaQuery, GqlClientEditorAccountLevelDataMediaQueryVariables>({
        query: EditorAccountLevelDataMediaDocument,
        variables: {}
    }).subscribe(({ data }) => {
        handleLibraryMediaUpdate(data?.editorAccountLevelData?.assetLibraryVersion);
    });
    return subscription.unsubscribe;
};
