import type { GqlClientGalleryStoryTemplateFragment, GqlClientStoryTemplatesReviewGalleryIndication,
    GqlClientCreateOrUpdateEditorApplicationInput } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientStoryTemplatesGalleryType,
    useGetGalleryStoryTemplatesQuery,
    useStoryTemplatesReviewGalleryStatusQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { publishStoryTemplateToProduction, removeStoryTemplateFromAllEnvs, removeStoryTemplateFromGallery, updateApplication } from "../../Nooks";
import type { ApplicationMetadata } from "../../../../../common/types/applicationTypes";


export type UseTemplatesReviewGalleryStatus = {
    storyTemplateId: string
    indication: GqlClientStoryTemplatesReviewGalleryIndication
};

export type UseReviewGalleryStoryTemplates = {
    storyTemplates: GqlClientGalleryStoryTemplateFragment[];
    galleryStatus: UseTemplatesReviewGalleryStatus[];
    publishToProduction: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]) => Promise<void>;
    removeFromProduction: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]) => Promise<void>;
    removeFromAllEnvs: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"], stIndication: GqlClientStoryTemplatesReviewGalleryIndication) => Promise<void>;
    updateApplication: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]) => Promise<void>;
};

const updateMetadata = async (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"], storyTemplates: GqlClientGalleryStoryTemplateFragment[]) => {
    const storyTemplate: GqlClientGalleryStoryTemplateFragment = storyTemplates.find(item => item.id == storyTemplateId);
    const metadata: ApplicationMetadata = storyTemplate.metadata as ApplicationMetadata;
    let newMetadata: ApplicationMetadata = Object.assign({}, metadata);
    newMetadata.newStoryTemplate = metadata.newStoryTemplate ? !metadata.newStoryTemplate : true;

    const updatedApplicationInput: GqlClientCreateOrUpdateEditorApplicationInput = {
        editorProgramVersionId: storyTemplate.id,
        useApplication: true,
        metadata: newMetadata
    };
    await updateApplication(updatedApplicationInput);
};

export const useReviewGalleryStoryTemplates = (): UseReviewGalleryStoryTemplates => {
    const { data, refetch } = useGetGalleryStoryTemplatesQuery({
        variables: {
            galleryType: GqlClientStoryTemplatesGalleryType.QA,
            includeChildren: true
        }
    });
    const storyTemplates = data?.galleryStoryTemplates?.slice().sort((template1, template2) => template1.name?.localeCompare(template2.name)) || [];

    const { data: dataStatus, refetch: refetchStatus } = useStoryTemplatesReviewGalleryStatusQuery();
    const galleryStatus = dataStatus?.storyTemplatesReviewGalleryStatus;


    return {
        storyTemplates,
        galleryStatus,
        publishToProduction: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]) => publishStoryTemplateToProduction(storyTemplateId).then(() => {
            refetchStatus().catch(() => {
            });
        }),
        removeFromProduction: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]) =>
            removeStoryTemplateFromGallery(storyTemplateId, GqlClientStoryTemplatesGalleryType.PRODUCTION).then(() => {
                refetchStatus().catch(() => {
                });
            }),
        removeFromAllEnvs: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"], stIndication: GqlClientStoryTemplatesReviewGalleryIndication) =>
            removeStoryTemplateFromAllEnvs(storyTemplateId, stIndication).then(() => {
                refetch().catch(() => {
                });
            }),
        updateApplication: (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]) => updateMetadata(storyTemplateId, storyTemplates).then(() => {
            refetch().catch((() => {}));
        })
    };
};
