const helpCenterArticleTemplate: (
    variables: {
        title: string;
        body: string;
        articleId: string;
    }
) => string = require("../../../../../resources/help-center/article.template.ejs");

const zendeskAPI = "https://sundaysky.zendesk.com/api/v2";

type Article = {
    id: string;
    title: string;
    body: string;
    section_id: string;
}

type ListArticlesResponse = {
    articles: Article[];
};

type ShowArticleResponse = {
    article: Article;
};

export type ArticleData = {
    title: string;
    body: string;
    relatedArticles: Article[];
};

export const fetchZendeskArticle = async (articleId: string): Promise<ArticleData> => {
    if (!articleId) {
        return null;
    }

    const articleURL = `${zendeskAPI}/help_center/en-us/articles/${articleId}.json`;

    let relatedArticles: Article[] = [];

    try {
        const response = await fetch(articleURL);
        if (!response.ok) {
            throw new Error(`Error fetching article: ${response.statusText}`);
        }
        const data = await response.json() as ShowArticleResponse;

        if (data.article.section_id) {
            const relatedArticlesURL = `${zendeskAPI}/help_center/sections/${data.article.section_id}/articles.json`;
            const relatedArticlesResponse = await fetch(relatedArticlesURL);

            if (!relatedArticlesResponse.ok) {
                throw new Error(`Error fetching related articles: ${relatedArticlesResponse.statusText}`);
            }

            relatedArticles = (await relatedArticlesResponse.json() as ListArticlesResponse).articles
                .filter((article) => article.id !== articleId);
        }

        return {
            body:
                helpCenterArticleTemplate({
                    title: data.article.title,
                    body: data.article.body,
                    articleId
                }),
            title: data.article.title,
            relatedArticles
        };
    }
    catch (error) {
        throw new Error("Error fetching articles: error");
    }
};

export type SearchResult = {
    id: string;
    title: string;
};

export const searchZendeskArticles = async (searchTerm: string): Promise<SearchResult[]> => {
    try {
        const response = await fetch(`${zendeskAPI}/help_center/articles/search?query=${searchTerm}`);

        if (!response.ok) {
            throw new Error(`Error fetching articles: ${response.statusText}`);
        }

        const data = await response.json();
        return data.results.map((result) => ({
            id: result.id,
            title: result.title
        }));
    }
    catch (error) {
        throw new Error("Error fetching articles: error");
    }
};
