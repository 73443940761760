import { localEditorProcessedFilesVar, localEditorUploadedGettyUrlsVar } from "../../State";
import type { DistributiveOmit, ProcessedFile, UploadedGettyUrl } from "../../types";
import type { SskyErrorCode } from "../../../../../common/errors";
import { NotificationErrorText, NotificationInfoText } from "../Notification";

const PROCESSED_FILE_SUCCESS_TTL = 2000;
const PROCESSED_FILE_LONG_PROCESSING = 6000;

type FileParams = Pick<ProcessedFile, "fileName"> & { fileId?: string };

type ValidFileParams = FileParams & Partial<Pick<Blob, "type">> & {
    status: Extract<ProcessedFile["status"], "success" | "loading">;
};

type ErrorFileParams = FileParams & {
    sskyCode: SskyErrorCode;
};

type UpsertFileParams = ValidFileParams | ErrorFileParams;

export const getProcessedFiles = (): ProcessedFile[] => {
    return localEditorProcessedFilesVar();
};

export const getProcessedFileIndex = (fileName: ProcessedFile["fileName"]): number => {
    return localEditorProcessedFilesVar().findIndex((processedFile) => fileName === processedFile.fileName);
};

const updateLongProcessing = (processedFile: ProcessedFile): void => {
    const index = getProcessedFileIndex(processedFile.fileName);

    // Checking if the processed file still in the list. If not, do nothing.
    if (index < 0) {
        return;
    }

    // Getting the list of processed files
    const updatedProcessedFiles = [...localEditorProcessedFilesVar()];
    const oldProcessedFile = updatedProcessedFiles[index];
    
    if (oldProcessedFile.status === "loading") {
        const updatedProcessedFile = { ...oldProcessedFile, info: NotificationInfoText.MightTakeAWhile };

        updatedProcessedFiles.splice(index, 1, updatedProcessedFile);
        localEditorProcessedFilesVar(updatedProcessedFiles);
    }
};

const insertProcessedFile = (processedFile: ProcessedFile): void => {
    localEditorProcessedFilesVar([processedFile, ...localEditorProcessedFilesVar()]);
    setTimeout(() => updateLongProcessing(processedFile), PROCESSED_FILE_LONG_PROCESSING);
};

const updateProcessedFile = (fileIndex: number, type: string, updatedData: Omit<ProcessedFile, "fileName" | "fileId">): void => {
    const updatedProcessedFiles = [...localEditorProcessedFilesVar()];
    const updatedProcessedFile = {
        ...updatedProcessedFiles[fileIndex],
        ...updatedData,
        type
    };
    updatedProcessedFiles.splice(fileIndex, 1, updatedProcessedFile);
    localEditorProcessedFilesVar(updatedProcessedFiles);
};

const deleteProcessedFile = (processedFile: ProcessedFile): void => {
    const index = getProcessedFileIndex(processedFile.fileName);
    if (index >= 0) {
        const processedFiles = [...localEditorProcessedFilesVar()];
        processedFiles.splice(index, 1);
        localEditorProcessedFilesVar(processedFiles);
    }
};

const createErrorProcessedFile = ({ fileName, sskyCode }: ErrorFileParams): ProcessedFile => {
    return {
        fileName,
        status: "error",
        info: NotificationErrorText[sskyCode]
    };
};

const clearInfoIfSuccess = ({ fileName, fileId, status } :ValidFileParams) => {
    const processedFile: ProcessedFile = {
        fileName,
        fileId,
        status
    };

    // If the processedFile has status success we show no info.
    if (status === "success") {
        processedFile.info = null;
    }

    return processedFile;
};

export const upsertProcessedFile = (params: UpsertFileParams): void => {
    const processedFile: ProcessedFile = "sskyCode" in params
        ? createErrorProcessedFile(params)
        : clearInfoIfSuccess(params);

    const index = getProcessedFileIndex(processedFile.fileName);
    index < 0
        ? insertProcessedFile(processedFile)
        : updateProcessedFile(index, (params as ValidFileParams).type, processedFile);

    processedFile.status === "success" && setTimeout(() => deleteProcessedFile(processedFile), PROCESSED_FILE_SUCCESS_TTL);
};

// Adds the given getty url, or updates the urls and ids if exists
// We can use this util not only for getty urls. You can change its name if you are brave enough.
export const upsertEditorUploadedGettyUrl = (uploadedGettyUrlToUpsert: UploadedGettyUrl): void => {
    const removedUploadedGettyUrls = localEditorUploadedGettyUrlsVar().filter(uploadedGettyUrl =>
        Object.keys(uploadedGettyUrl).filter(key => key !== "assetId" && key !== "mediaUrl" && key !== "thumbnailUrl").some(key => uploadedGettyUrlToUpsert[key] !== uploadedGettyUrl[key])
    );
    localEditorUploadedGettyUrlsVar([...removedUploadedGettyUrls, uploadedGettyUrlToUpsert]);
};

export const removeEditorUploadedGettyUrl = (data: DistributiveOmit<UploadedGettyUrl, "mediaUrl" | "thumbnailUrl" | "assetId">) => {
    const updatedUploadedGettyUrls = localEditorUploadedGettyUrlsVar().filter(uploadedGettyUrl =>
        Object.keys(data).filter(key => key !== "mediaUrl" && key !== "thumbnailUrl").some(key => data[key] !== uploadedGettyUrl[key])
    );

    localEditorUploadedGettyUrlsVar(updatedUploadedGettyUrls);
};

export const getEditorUploadedGettyUrlPlaceholders = () => localEditorUploadedGettyUrlsVar();
