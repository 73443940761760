import numeral from "numeral";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import Spinner from "react-spinner-material";

interface HubSpinnerProps {
    width?: number,
    height?: number,
    spinnerColor?: string,
    spinnerWidth?: number,
    show?: boolean,
    progress?: number,
    delay?: number
}

export const HubSpinner: FC<HubSpinnerProps> = (props) => {
    const [isRendered, setRendered] = useState(!props.delay);

    useEffect(
        () => {
            if (!isRendered) {
                const timeoutId = setTimeout(
                    () => setRendered(true),
                    props.delay || 0
                );
                return () => clearTimeout(timeoutId);
            }
        },
        []
    );

    if (!isRendered) {
        return null;
    }

    const size = Math.min(props.width, props.height);
    const progress = typeof props.progress === "number"
        ? numeral(props.progress / 100).format("0.0%")
        : undefined;

    return (
        <React.Fragment>
            {progress && (
                <div
                    className="spinner-progress"
                    style={{ color: props.spinnerColor }}
                >
                    {" "}
                    {progress}
                </div>
            )}
            <Spinner
                size={size}
                spinnerColor={props.spinnerColor}
                spinnerWidth={props.spinnerWidth}
                visible={props.show}
            />
        </React.Fragment>
    );
};
