import type { GqlClientProjectAudioSettingsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useProjectAudioSettingsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ProgramVersionId } from "../../types";
import { setProjectAudioSettings } from "../../Nooks";
import type { EditorAudioSettings } from "../../../../../common/types/editorAudioSettings";
import type { ApolloQueryResult } from "@apollo/client";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseProjectAudioSettings = {
    audioSettings: EditorAudioSettings;
    setAudioSettings: (
        programVersionId: ProgramVersionId,
        audioSettings: EditorAudioSettings
    ) => void;
    refetch: () => Promise<ApolloQueryResult<GqlClientProjectAudioSettingsQuery>>;
};

export const useProjectAudioSettings = (): UseProjectAudioSettings => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data, refetch } = useProjectAudioSettingsQuery({ variables: { programId, programVersionId } });

    const audioSettings = data?.editorProgram?.programVersion?.audioSettings ?? null;

    return { audioSettings, setAudioSettings: setProjectAudioSettings, refetch };
};
