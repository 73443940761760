import type BaseServices from "../logic/common/BaseServices";

let services = new Map<string, BaseServices>();

export function getService<T extends BaseServices>(name: string): T {
    return services.get(name) as T;
}

export function addService(name: string, service: BaseServices) {
    services.set(name, service);
}
