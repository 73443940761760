import React from "react";
import type { FC } from "react";
import "./HubDatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import type { Moment } from "moment";
import { SskyDateTimePicker } from "@sundaysky/ui-component-library/Components";

interface HubDatePickerProps {
    id?: string;
    className?: string;
    startDate: Moment | null,
    handleChangeStart: (date: string | null) => void,
    readOnly: boolean;
    inline: boolean;
    displayPostfix?: string;
}

const DISPLAY_FORMAT: string = "MM/DD/YYYY HH:mm";
const DATA_FORMAT: string = "YYYY-MM-DD HH:mm";

const HubDatePicker: FC<HubDatePickerProps> = (props: HubDatePickerProps) => {
    const postfix = props.displayPostfix || "";
    const placeholder: string = `MM/DD/YYYY HH:mm${postfix}`;

    const onDateTimeChange = (date: Moment) => {
        let dateString = date?.isValid() ? date.format(DATA_FORMAT) : null;
        props.handleChangeStart(dateString);
    };

    return <SskyDateTimePicker
        id={props.id}
        dataTestId="date-picker"
        className={props.className}
        variant={props.inline ? "inline" : "dialog"}
        value={props.startDate || null}
        onChange={onDateTimeChange}
        disabled={props.readOnly}
        format={DISPLAY_FORMAT}
        placeHolder={placeholder}
    />;
};

export default HubDatePicker;
