import { useMemo } from "react";
import type {
    GqlClientEditorLibraryThemeFragment,
    GqlClientEditorLibraryWireframeFragment
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientAssetLifecycleStage,
    useEditorLibraryWireframesQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";
import { useLibraryThemes } from "./useLibraryThemes";
import { useWireframeThemeSupport } from "../UseWireframeThemeSupport";

export type UseLibraryWireframes = {
    activeLibraryWireframes: GqlClientEditorLibraryWireframeFragment[];
    testLibraryWireframes: GqlClientEditorLibraryWireframeFragment[];
};

export const useLibraryWireframes = (): UseLibraryWireframes => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorLibraryWireframesQuery({ variables: { programId, programVersionId } });
    const { libraryThemes } = useLibraryThemes();
    const { isThemeImplementedForWireframe } = useWireframeThemeSupport();

    const libraryWireframes = data?.editorProgram?.programVersion?.ccLibraryVersion?.wireframes ?? [];

    // product: wireframes should be sorted by - number of supported themes (desc) -> number of frames (asc) -> name (asc)
    const sortedWireframes = useMemo(() => {
        const getSupportedThemes = (wireframe: GqlClientEditorLibraryWireframeFragment): GqlClientEditorLibraryThemeFragment[] => {
            return libraryThemes.filter((theme) => isThemeImplementedForWireframe(wireframe.id, theme.id));
        };
    
        const sortFn = (wireframeA: GqlClientEditorLibraryWireframeFragment, wireframeB: GqlClientEditorLibraryWireframeFragment): number => {
            const byCategory = wireframeA.category.localeCompare(wireframeB.category);
            const byNumOfSupportedThemes = getSupportedThemes(wireframeA).length - getSupportedThemes(wireframeB).length;
            const byNumOfFrames = wireframeA.frames.length - wireframeB.frames.length;
            const byName = wireframeA.name.localeCompare(wireframeB.name);
    
            return byCategory || -byNumOfSupportedThemes || byNumOfFrames || byName;
        };
        
        return [...libraryWireframes].sort(sortFn);
    }, [libraryWireframes, libraryThemes]);
    
    const activeSortedWireframes = useMemo(() => {
        return sortedWireframes.filter(wireframe => wireframe.lifecycleStage === GqlClientAssetLifecycleStage.ACTIVE);
    }, [sortedWireframes]);

    const testSortedWireframes = useMemo(() => {
        return sortedWireframes.filter(wireframe => wireframe.lifecycleStage === GqlClientAssetLifecycleStage.TEST);
    }, [sortedWireframes]);

    return { activeLibraryWireframes: activeSortedWireframes, testLibraryWireframes: testSortedWireframes };
};
