import type { ProgramVersionId } from "../../types";
import type { GqlClientEditorProgramVersion } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorTtsFullEnablementQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { setEditorTtsFullEnablement } from "../../Nooks";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseEditorTtsFullEnablement = {
    editorTtsFullEnablement: GqlClientEditorProgramVersion["editorTtsFullEnablement"];
    setEditorTtsFullEnablement: (
        programVersionId: ProgramVersionId,
        isEnabled: boolean
    ) => void;
}

export const useEditorTtsFullEnablement = (): UseEditorTtsFullEnablement => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorTtsFullEnablementQuery({ variables: { programId, programVersionId } });
    
    const editorTtsFullEnablement = data?.editorProgram?.programVersion?.editorTtsFullEnablement ?? false;
    
    return { editorTtsFullEnablement, setEditorTtsFullEnablement };
};
