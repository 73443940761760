import type React from "react";
import { fileExtByMediaType } from "../../../../common/commonConst";
import type { FontsManager } from "../../../../common/fontsUtils";
import type { DynamicPlaceholderContentInfo } from "../../../../common/placeholderUtils";
import {
    getDefaultTextStyleSetting,
    getPlaceholderContentDynamicInfo,
    isPlaceholderContentNoRulesWithPersonalization,
    isPlaceholderOverrideLogo,
    someAtomsRichTextFormattingApplied
} from "../../../../common/placeholderUtils";
import type {
    ButtonShowContentTextItemV9,
    ButtonShowContentUrlItemV9,
    DataElementDescriptor,
    DynamicRichTextAtom,
    DynamicRichTextAtoms,
    DynamicTextAtom,
    DynamicTextAtoms,
    MediaCaseV8,
    MediaShowV8,
    StaticAvatarShowV1,
    StaticMediaShowV8,
    StaticRichTextAtoms,
    StaticTextAtoms,
    TextPlaceholderContent
} from "../../../../common/types/editorPlaceholder";
import { AvatarSource, CommonPlaceholderType, PlaceholderIntentName, PlaceholderShowType } from "../../../../common/types/editorPlaceholder";
import type {
    GqlClientEditorAssetLibraryAvatarFragment,
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorLibraryAvatarFragment,
    GqlClientEditorLibraryFontFragment,
    GqlClientEditorLibraryPlaceholderIntentFragment,
    GqlClientEditorPlaceholderFragment,
    GqlClientStudioElementFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientCcPlaceholderCategory,
    GqlClientCcPlaceholderType,
    GqlClientEnumAspectRatio,
    GqlClientMediaOrigin
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";

import AssetUtils from "../../../logic/common/assetUtils";
import type { PendoService } from "../../pendoService";
import type { GqlMediaById, LivePreviewScenePlaceholderData, MediaPlaceholderContentTypes, ProcessFileInputResponse } from "../types";
import { getStudioElementName } from "./studioElement";
import type { RichTextShowValue } from "./videoSpecBuilder";

export type MediaMetadata = {
    previewUrl: string,
    mediaUrl: string,
    mediaOrigin: GqlClientMediaOrigin
    duration?: number,
    mediaSri?: string,
    mediaId? :string
}

export type MediaType = "image" | "video" | "none";

export const getPlaceholderName = (placeholder: LivePreviewScenePlaceholderData): string => {
    if (placeholder.ccPlaceholder.category === GqlClientCcPlaceholderCategory.PRE_DEFINED) {
        //Traditional Placeholders
        return placeholder.ccPlaceholder.name;
    }

    //User-definable placeholders and implicitly user-definable placeholders (e.g., avatars and overlays)
    return `${placeholder.ccPlaceholder.name}_${placeholder.id}`;
};

export const getPlaceholderType = (placeholder: LivePreviewScenePlaceholderData): CommonPlaceholderType => {
    if (!placeholder) {
        return null;
    }

    const typeFromPlaceholder = placeholder.placeholderContent.placeholderType;
    if (typeFromPlaceholder) {
        return typeFromPlaceholder as CommonPlaceholderType;
    }

    if (!placeholder?.ccPlaceholder) {
        return null;
    }
    return clientGqlPlaceholderTypeToCommonPlaceholderType(placeholder?.ccPlaceholder.intent.type);
};

function isPlaceholderNoRulesWithPersonalization(placeholder: LivePreviewScenePlaceholderData): boolean {
    return isPlaceholderContentNoRulesWithPersonalization(placeholder?.placeholderContent);
}

export const getPlaceholderDynamicInfo = (placeholder: LivePreviewScenePlaceholderData): DynamicPlaceholderContentInfo => {
    if (!placeholder) {
        return { isPersonalized: false };
    }
    const placeholderType = getPlaceholderType(placeholder);
    return getPlaceholderContentDynamicInfo(placeholder.placeholderContent, placeholderType);
};

export const isPlaceholderDynamic = (placeholder: LivePreviewScenePlaceholderData): boolean => {
    if (isPlaceholderUsingRules(placeholder)) return true;
    switch (getPlaceholderType(placeholder)) {
        case CommonPlaceholderType.MEDIA: {
            return placeholder.placeholderContent.noRules?.show.showType === PlaceholderShowType.Dynamic;
        }
        case CommonPlaceholderType.AVATAR: {
            // todo: Zoe/Tali (Avatars) - implement live preview
            return placeholder.placeholderContent.noRules?.show.showType === PlaceholderShowType.Dynamic;
        }
        case CommonPlaceholderType.BUTTON: {
            // at the moment buttons does not use isPlaceholderDynamic in meaningful ways
            return false;
        }
        default: {
            return isPlaceholderNoRulesWithPersonalization(placeholder);
        }
    }
};

export const NARRATION_SHOW_CHAR_LIMIT: number = 900;

export const isButtonShowContentItemDynamic = (buttonShowContentItem: ButtonShowContentTextItemV9<DynamicRichTextAtoms> | ButtonShowContentUrlItemV9<DynamicTextAtoms>): boolean => {
    return Array.isArray(buttonShowContentItem.content) && buttonShowContentItem.content.some(item => item?.content && typeof item.content === "object" && typeof item.content.localId === "string");
};

export const isPlaceholderUsingRules = (placeholder: Pick<LivePreviewScenePlaceholderData, "placeholderContent">): boolean => {
    return Boolean(placeholder?.placeholderContent.useRules);
};

export const getPlainTextPlaceholderValueStatic = (phValue: StaticRichTextAtoms | StaticTextAtoms): string => {
    return (phValue || []).reduce((acc: string, fragment) => {
        return acc + fragment.content;
    }, "");
};

export const getRichTextPlaceholderValueStatic = (phValue: StaticRichTextAtoms,
    fontId: string, fontsManager: FontsManager<GqlClientEditorLibraryFontFragment | GqlClientEditorAssetLibraryFontFragment>): string | RichTextShowValue => {
    if (someAtomsRichTextFormattingApplied(phValue)) {
        const parts = (phValue || []).map((fragment) => {
            const fontUrl: string = fontsManager?.getFontVariationUrlForRichTextStyle(fontId, fragment.style);
            return {
                text: fragment.content,
                "@styleProps": {
                    _sskyFontUrl: fontUrl
                }
            };
        });
        return {
            "@type": "text",
            text: getPlainTextPlaceholderValueStatic(phValue),
            parts
        };
    }
    else {
        return getPlainTextPlaceholderValueStatic(phValue);
    }
};

const getPartText = (fragment: DynamicRichTextAtom | DynamicTextAtom, studioElements: GqlClientStudioElementFragment[]): string => {
    return typeof fragment.content === "string" ?
        fragment.content : `{${getStudioElementName((fragment.content as DataElementDescriptor).localId, studioElements)}}`;
};

export const getPlainTextPlaceholderValueDynamic = (phValue: DynamicRichTextAtoms | DynamicTextAtoms, studioElements: GqlClientStudioElementFragment[]): string => {
    return (phValue || []).reduce((acc: string, fragment) => acc + getPartText(fragment, studioElements), "");
};

export const getRichTextPlaceholderValueDynamic = (phValue: DynamicRichTextAtoms, studioElements: GqlClientStudioElementFragment[],
    fontId: string, fontsManager: FontsManager<GqlClientEditorLibraryFontFragment | GqlClientEditorAssetLibraryFontFragment>): string | RichTextShowValue => {
    if (someAtomsRichTextFormattingApplied(phValue)) {
        const parts = (phValue || []).map((fragment) => {
            const fontUrl: string = fontsManager?.getFontVariationUrlForRichTextStyle(fontId, fragment.style);
            const partText = getPartText(fragment, studioElements);
            return {
                text: partText,
                "@styleProps": {
                    _sskyFontUrl: fontUrl
                }
            };
        });
        return {
            "@type": "text",
            text: getPlainTextPlaceholderValueDynamic(phValue, studioElements),
            parts
        };
    }
    else {
        return getPlainTextPlaceholderValueDynamic(phValue, studioElements);
    }
};

export const isPlaceholderLogo = (placeholder: LivePreviewScenePlaceholderData): boolean => {
    return getPlaceholderType(placeholder) === CommonPlaceholderType.MEDIA &&
        placeholder?.ccPlaceholder?.intent?.name === PlaceholderIntentName.Logo;
};

function getMediaSource(staticMediaShow: StaticMediaShowV8, assetsByMediaId: GqlMediaById) {
    const asset = getStaticShowMedia(staticMediaShow, assetsByMediaId);
    return asset.mediaSri || asset.mediaUrl;
}

/**
 *
 * @param placeholder
 * @param staticMediaShow
 * @param assetsByMediaId - temporary since we have a bug with the placeholder assets
 * @param logoUrl
 */
export const getMediaValue = (
    placeholder: LivePreviewScenePlaceholderData,
    staticMediaShow: StaticMediaShowV8 | null,
    assetsByMediaId: GqlMediaById,
    logoUrl?: string | null
): string => {
    const isLogoOverride = isPlaceholderOverrideLogo(placeholder.placeholderSettings);

    if (isPlaceholderLogo(placeholder) && !isLogoOverride) {
        return logoUrl;
    }
    else {
        return getMediaSource(staticMediaShow, assetsByMediaId);
    }
};

// temporarily replaces getStaticShowMedia until we solve the assets update issue inside a PH
export const getStaticShowMedia = (
    staticShow: StaticMediaShowV8,
    libraryAssetsByMediaId: GqlMediaById
): MediaMetadata => {
    let media: MediaMetadata;

    const mediaId = staticShow?.mediaId;
    if (mediaId) {
        const asset: GqlClientEditorAssetLibraryMediaFragment = libraryAssetsByMediaId?.[mediaId];
        media = {
            previewUrl: asset?.mediaPreviewUrl ?? "",
            mediaUrl: asset?.mediaUrl ?? "",
            mediaOrigin: asset?.mediaOrigin ?? GqlClientMediaOrigin.SURROGATE,
            duration: asset?.mediaMetadata.duration,
            mediaSri: asset?.mediaSri ?? ""
        };
    }
    else {
        media = {
            previewUrl: "",
            mediaUrl: "",
            mediaOrigin: GqlClientMediaOrigin.SURROGATE
        };
    }

    return media;
};

export const isImage = (mediaUrl: string): boolean => {
    const url = new URL(mediaUrl);
    const pathname = url.pathname;
    const fileExtension = pathname.slice(pathname.lastIndexOf("."));
    return fileExtByMediaType.image.includes(fileExtension);
};

export const getMediaType = (mediaUrl: string): MediaType => {
    if (!mediaUrl) {
        return "none";
    }
    if (isImage(mediaUrl)) {
        return "image";
    }
    return "video";
};

export function getStaticShowMediaType(staticShow: StaticMediaShowV8, assetsByMediaId: GqlMediaById, mediaPhContentType: MediaPlaceholderContentTypes) {
    const mediaData = getStaticShowMedia(staticShow, assetsByMediaId);
    const mediaUrl = mediaData?.mediaUrl;
    switch (getMediaType(mediaUrl)) {
        case "image":
            mediaPhContentType.image = true;
            break;
        case "video":
            mediaPhContentType.video = true;
            break;
    }
}

export const accumulatePlaceholderMediaTypes = (ruleCase: MediaCaseV8, surrogateAssetsByMediaId: GqlMediaById,
    assetLibraryMediaByGqlMediaId: GqlMediaById, mediaTypes: MediaPlaceholderContentTypes) : MediaPlaceholderContentTypes => {
    const type = getPlaceholderRuleMediaType(ruleCase, surrogateAssetsByMediaId, assetLibraryMediaByGqlMediaId);

    return {
        image: mediaTypes.image || type.image,
        video: mediaTypes.video || type.video
    };
};

const getPlaceholderRuleMediaType = (placeholderContentRule: MediaCaseV8,
    surrogateAssetsByMediaId: GqlMediaById,
    assetsByMediaId: GqlMediaById) => {
    const mediaPhContentType: MediaPlaceholderContentTypes = {
        image: false,
        video: false
    };

    if (placeholderContentRule?.show?.showType === PlaceholderShowType.Dynamic) {
        mediaPhContentType.image = true;
        mediaPhContentType.video = true;
    }
    else {
        const staticShow: StaticMediaShowV8 = placeholderContentRule?.show?.static as MediaShowV8["static"];
        const mediaId = staticShow?.mediaId;

        if (!surrogateAssetsByMediaId[mediaId]) {
            getStaticShowMediaType(staticShow, assetsByMediaId, mediaPhContentType);
        }
    }

    return mediaPhContentType;
};

export function getPlaceholderContentType(placeholder: GqlClientEditorPlaceholderFragment,
    contentType: MediaPlaceholderContentTypes,
    surrogateAssetsByMediaId: GqlMediaById,
    assetLibraryMediaByGqlMediaId: GqlMediaById): MediaPlaceholderContentTypes {

    const isUsingRules = isPlaceholderUsingRules(placeholder);

    if (isUsingRules) {
        getStaticShowMediaType(placeholder.placeholderContent.staticFallback, assetLibraryMediaByGqlMediaId, contentType);

        const ruleCases = placeholder.placeholderContent.rules.cases as [MediaCaseV8];
        ruleCases.forEach(ruleCase => {
            contentType = accumulatePlaceholderMediaTypes(ruleCase, surrogateAssetsByMediaId, assetLibraryMediaByGqlMediaId, contentType);
        });
    }
    else {
        const placeholderContentRule = placeholder.placeholderContent.noRules;
        contentType = accumulatePlaceholderMediaTypes(placeholderContentRule, surrogateAssetsByMediaId, assetLibraryMediaByGqlMediaId, contentType);
    }

    return contentType;
}

export function clientGqlPlaceholderTypeToCommonPlaceholderType(type: GqlClientCcPlaceholderType): CommonPlaceholderType {
    switch (type) {
        case GqlClientCcPlaceholderType.TEXT:
            return CommonPlaceholderType.TEXT;
        case GqlClientCcPlaceholderType.IMAGE:
        case GqlClientCcPlaceholderType.VIDEO:
        case GqlClientCcPlaceholderType.MEDIA:
            return CommonPlaceholderType.MEDIA;
        case GqlClientCcPlaceholderType.NARRATION:
            return CommonPlaceholderType.NARRATION;
        case GqlClientCcPlaceholderType.BUTTON:
            return CommonPlaceholderType.BUTTON;
        case GqlClientCcPlaceholderType.AVATAR:
            return CommonPlaceholderType.AVATAR;
        default:
            throw new Error(`Unsupported client GQL placeholder type ${type}`);
    }
}

export const getButtonIntentStyleId = (
    editorPlaceholder: Pick<LivePreviewScenePlaceholderData, "ccPlaceholder">,
    buttonInnerPhName: string,
    intents: GqlClientEditorLibraryPlaceholderIntentFragment[]
): string => {
    const currName = editorPlaceholder?.ccPlaceholder?.content?.find((item) => item["name"] === buttonInnerPhName);
    const intentName = currName?.intent;
    const intent = intents?.find((intent) => intent.name === intentName);
    return intent?.textStyle?.id ?? "";
};

export const processUploadAssetMediaInput = async (file: File, requiredMediaType?: string[], overrideFileLimits = false): ProcessFileInputResponse => {
    let fileType: string;
    if (file) {
        try {
            fileType = await AssetUtils.getFileType(file);
            AssetUtils.validateMediaFileType(file.name, fileType, requiredMediaType);
            if (!overrideFileLimits) {
                AssetUtils.validateSizeLimitExceeded(fileType, file.size);
            }
        }
        catch (error) {
            return { error };
        }
    }

    return { fileData: { fileType } };
};

export function trackPlaceholderPersonalizationChangeEvent(initial: React.MutableRefObject<boolean>, placeholderDynamicInfo: DynamicPlaceholderContentInfo,
    pendo: PendoService, projectSelectedSceneId: string, placeholder: GqlClientEditorPlaceholderFragment) {
    if (initial.current !== placeholderDynamicInfo.isPersonalized) {
        initial.current = placeholderDynamicInfo.isPersonalized;
        pendo.trackEvent("PH personalized state change",
            {
                sceneId: projectSelectedSceneId,
                phIntent: placeholder?.ccPlaceholder.intent.name,
                isPersonalized: placeholderDynamicInfo.isPersonalized.toString(),
                byAudienceDataField: placeholderDynamicInfo.byAudienceDataField?.localId,
                byAudienceVariationNum: placeholderDynamicInfo.byAudienceVariationNum?.toString(),
                dynamicDataField: placeholderDynamicInfo.dynamicDataField !== undefined ? "true" : "false"
            });
    }
}



export const someRichTextFormattingApplied = (placeholderContent: TextPlaceholderContent): boolean => {
    return someAtomsRichTextFormattingApplied(placeholderContent.staticFallback) ||
        someAtomsRichTextFormattingApplied(placeholderContent.noRules.show) ||
        placeholderContent.rules.cases.some(variation => {
            return someAtomsRichTextFormattingApplied(variation.show);
        });
};

export const getButtonOrTextPlaceholderTextStyle = (
    placeholder: GqlClientEditorPlaceholderFragment,
    brandTextStyles: GqlClientEditorBrandTextStyleFragment[],
    libraryPlaceholderIntents: GqlClientEditorLibraryPlaceholderIntentFragment[]) => {
    const phType = getPlaceholderType(placeholder);
    if (phType === CommonPlaceholderType.BUTTON) {
        const textStyleId = getButtonIntentStyleId(placeholder, "text", libraryPlaceholderIntents);
        return brandTextStyles.find(ts => ts.ccTextStyle.id === textStyleId);
    }
    if (phType === CommonPlaceholderType.TEXT) {
        const textStyleSetting = getDefaultTextStyleSetting(placeholder.placeholderSettings);
        return (textStyleSetting?.useValue && brandTextStyles.find(ts => ts.ccTextStyle.name === textStyleSetting.value.style))
            || brandTextStyles.find(brandStyle => brandStyle?.ccTextStyle.id === placeholder.ccPlaceholder.intent.textStyle.id);
    }
};

export const getAvatarInfoFromPlaceholder = (placeholder: GqlClientEditorPlaceholderFragment): StaticAvatarShowV1 => {
    //todo: handle personalization
    return placeholder.placeholderContent.noRules.show.static;
};

export const getLibraryAvatarFromPlaceholder = (
    avatarPlaceholder: GqlClientEditorPlaceholderFragment,
    libraryAvatars: GqlClientEditorLibraryAvatarFragment[],
    customAvatars: GqlClientEditorAssetLibraryAvatarFragment[]
): GqlClientEditorLibraryAvatarFragment | GqlClientEditorAssetLibraryAvatarFragment | null => {
    const usedAvatarInPH = getAvatarInfoFromPlaceholder(avatarPlaceholder);
    if (!usedAvatarInPH) return null;
    return usedAvatarInPH.avatarLibrary === AvatarSource.CC
        ? libraryAvatars.find(avatar => avatar.localId === usedAvatarInPH.avatarId)
        : customAvatars.find(avatar => avatar.localId === usedAvatarInPH.avatarId);
};

export const ASPECT_RATIO_MAP: Record<GqlClientEnumAspectRatio, number> = {
    [GqlClientEnumAspectRatio.AR_1_1]: 1,
    [GqlClientEnumAspectRatio.AR_4_5]: 4 / 5,
    [GqlClientEnumAspectRatio.AR_9_16]: 9 / 16,
    [GqlClientEnumAspectRatio.AR_14_9]: 14 / 9,
    [GqlClientEnumAspectRatio.AR_16_9]: 16 / 9,
    [GqlClientEnumAspectRatio.AR_191_100]: 191 / 100
};

export const trigger = "{";
