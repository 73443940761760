import { reportInfoToSplunkRedux } from "../../../../logic/common/logReportUtils";
import type { Dimension } from "../../../../../common/external/videoSpecResponse";

export enum LivePreviewClient {
    MAIN_SCENE = "main_scene",
    LINEUP = "lineup",
    LAYOUT_TAB = "layout_tab",
    SCENES_LIBRARY = "scenes_library",
    SWITCH_SCENE_TEMPLATE_LIBRARY = "switch_scene_template_library"
}

export enum LivePreviewAssetType {
    METADATA = "metadata",
    IMAGE = "image"
}

type LivePreviewRequestStatus = "Succeeded" | "Failed";

type LivePreviewAnalytics = {
    //live preview analytics
    livePreviewClient: LivePreviewClient
    clientCacheHit: boolean
    livePreviewStartHandlingTime: number
    livePreviewEndHandlingTime: number
    livePreviewHandlingDuration: number
    livePreviewStatus: LivePreviewRequestStatus

    //live preview (non cache) stages analytics
    raasRequestAnalytics: Record<number, LivePreviewRaaSRequestAnalytics>
    metadataRequestAnalytics: Record<number, LivePreviewAssetAnalytics>
    imageRequestAnalytics: Record<number, LivePreviewAssetAnalytics>
}

type BasicRequestAnalytics = {
    attemptNumber: number
    url?: string
    requestTime: number
    responseTime: number
    firstBitResponseTime?: number
    requestDuration: number
    status: LivePreviewRequestStatus
}

type LivePreviewAssetAnalytics = BasicRequestAnalytics & {
    cloudfrontHit: boolean
}

type LivePreviewRaaSRequestAnalytics = BasicRequestAnalytics & {
    raasRequestId: string
    jobStatusUrl: string
    raasCacheHit: boolean
    dimension: Dimension
}

export type LivePreviewAnalyticsBuilder = ReturnType<typeof initLivePreviewAnalyticsBuilder>;

// Percent of live preview requests we should report.
const livePreviewAnalyticsReportPercent = 0.2;

const shouldReportLivePreviewAnalytics = (): boolean => {
    return Math.random() <= livePreviewAnalyticsReportPercent;
};

const initBasicRequestAnalytics = (attempt: number): BasicRequestAnalytics => {
    return {
        attemptNumber: attempt,
        requestTime: undefined,
        responseTime: undefined,
        requestDuration: undefined,
        status: undefined
    };
};

const initLivePreviewRaaSRequestAnalytics = (attempt: number): LivePreviewRaaSRequestAnalytics => {
    return {
        raasRequestId: undefined,
        ...initBasicRequestAnalytics(attempt),
        raasCacheHit: false,
        jobStatusUrl: undefined,
        dimension: undefined
    };
};

const initLivePreviewAssetRequestAnalytics = (attempt: number): LivePreviewAssetAnalytics => {
    return {
        ...initBasicRequestAnalytics(attempt),
        cloudfrontHit: false
    };
};

const updateLivePreviewRaaSRRequestAnalyticsForAttempt = (analytics: LivePreviewAnalytics, attempt: number, raasRequestAnalytics: Partial<LivePreviewRaaSRequestAnalytics>) => {
    analytics.raasRequestAnalytics[attempt] = {
        ...(analytics.raasRequestAnalytics[attempt] ? analytics.raasRequestAnalytics[attempt] : initLivePreviewRaaSRequestAnalytics(attempt)),
        ...raasRequestAnalytics
    };
};

const updateLivePreviewAssetRRequestAnalyticsForAttempt = (
    analytics: LivePreviewAnalytics,
    attempt: number,
    assetType: LivePreviewAssetType,
    assetRequestAnalytics: Partial<LivePreviewAssetAnalytics>) => {
    const fieldName = assetType === LivePreviewAssetType.METADATA ? "metadataRequestAnalytics" : "imageRequestAnalytics";
    analytics[fieldName][attempt] = {
        ...(analytics[fieldName][attempt] ? analytics[fieldName][attempt] : initLivePreviewAssetRequestAnalytics(attempt)),
        ...assetRequestAnalytics
    };
};

const setDuration = (analytics: LivePreviewAnalytics) => {
    analytics.livePreviewHandlingDuration = analytics.livePreviewEndHandlingTime - analytics.livePreviewStartHandlingTime;
    [analytics.raasRequestAnalytics, analytics.imageRequestAnalytics, analytics.metadataRequestAnalytics]
        .forEach((requestsAnalytics) => {
            for (const attempt in requestsAnalytics) {
                const ra = requestsAnalytics[attempt];
                ra.requestDuration = ra.responseTime - ra.requestTime;
            }
        });
};

export const initLivePreviewAnalyticsBuilder = () => {
    const analytics: LivePreviewAnalytics = {
        livePreviewClient: undefined,
        clientCacheHit: false,
        livePreviewStartHandlingTime: undefined,
        livePreviewEndHandlingTime: undefined,
        livePreviewHandlingDuration: undefined,
        livePreviewStatus: undefined,

        raasRequestAnalytics: {},
        imageRequestAnalytics: {},
        metadataRequestAnalytics: {}
    };

    const livePreviewAnalyticsBuilder = {
        //live preview analytics
        withLivePreviewClient: (livePreviewClient: LivePreviewClient) => {
            analytics.livePreviewClient = livePreviewClient;
            return livePreviewAnalyticsBuilder;
        },
        withClientCacheHit: (cacheHit?: boolean) => {
            analytics.clientCacheHit = cacheHit === undefined ? true : cacheHit;
            return livePreviewAnalyticsBuilder;
        },
        withLivePreviewStartHandlingTime: (time: number) => {
            analytics.livePreviewStartHandlingTime = time;
            return livePreviewAnalyticsBuilder;
        },
        withLivePreviewEndHandlingTime: (time: number) => {
            analytics.livePreviewEndHandlingTime = time;
            return livePreviewAnalyticsBuilder;
        },
        withLivePreviewStatus: (status: LivePreviewRequestStatus) => {
            analytics.livePreviewStatus = status;
            return livePreviewAnalyticsBuilder;
        },

        //raas request analytics
        withRaaSRequestId: (id: string, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { raasRequestId: id });
            return livePreviewAnalyticsBuilder;
        },
        withJobStatusUrl: (jobStatusUrl: string, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { jobStatusUrl });
            return livePreviewAnalyticsBuilder;
        },
        withDimensions: (dimension: Dimension, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { dimension });
            return livePreviewAnalyticsBuilder;
        },
        withRaaSCacheHit: (cacheHit?: boolean, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { raasCacheHit: cacheHit === undefined ? true : cacheHit });
            return livePreviewAnalyticsBuilder;
        },
        withRaaSRequestTime: (time: number, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { requestTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withRaaSResponseTime: (time: number, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { responseTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withRaaSRequestStatus: (status: LivePreviewRequestStatus, attempt: number = 0) => {
            updateLivePreviewRaaSRRequestAnalyticsForAttempt(analytics, attempt, { status });
            return livePreviewAnalyticsBuilder;
        },

        //metadata request analytics
        withMetadataRequestTime: (time: number, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.METADATA, { requestTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withMetadataResponseTime: (time: number, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.METADATA, { responseTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withMetadataFirstBitResponseTime: (time: number, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.METADATA, { firstBitResponseTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withMetadataCacheHit: (cacheHit: boolean, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.METADATA, { cloudfrontHit: cacheHit === undefined ? true : cacheHit });
            return livePreviewAnalyticsBuilder;
        },
        withMetadataUrl: (url: string, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.METADATA, { url });
            return livePreviewAnalyticsBuilder;
        },
        withMetadataRequestStatus: (status: LivePreviewRequestStatus, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.METADATA, { status });
            return livePreviewAnalyticsBuilder;
        },

        //image request analytics
        withImageRequestTime: (time: number, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.IMAGE, { requestTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withImageResponseTime: (time: number, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.IMAGE, { responseTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withImageFirstBitResponseTime: (time: number, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.IMAGE, { firstBitResponseTime: time });
            return livePreviewAnalyticsBuilder;
        },
        withImageCacheHit: (cacheHit: boolean, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.IMAGE, { cloudfrontHit: cacheHit === undefined ? true : cacheHit });
            return livePreviewAnalyticsBuilder;
        },
        withImageUrl: (url: string, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.IMAGE, { url });
            return livePreviewAnalyticsBuilder;
        },
        withImageRequestStatus: (status: LivePreviewRequestStatus, attempt: number = 0) => {
            updateLivePreviewAssetRRequestAnalyticsForAttempt(analytics, attempt, LivePreviewAssetType.IMAGE, { status });
            return livePreviewAnalyticsBuilder;
        },
        report: () => {
            if (shouldReportLivePreviewAnalytics()) {
                setDuration(analytics);
                reportInfoToSplunkRedux("Live preview analytics report", analytics);
            }
        }
    };
    return livePreviewAnalyticsBuilder;
};
