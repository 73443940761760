import { setErrorNotification } from "../../../Nooks";
import { countdownAnimation, createAnimatedRecordWindow, RecordingEvents } from "../animations/RecordAnimationUtils";
import { ELEMENT_IDS, pipStyles } from "./pipStyles";
import { startMicVisualizer } from "./volumeBar";
import { RecorderLoggingEventType, reportRecorderError } from "../../../../../logic/common/logging/recorderClientLogging";

const settingsSvg = require("../../../../../images/svg/settings-white.svg");
const blueRevertSvg = require("../../../../../images/svg/blue-revert-icon.svg");
const closeIconWhite = require("../../../../../images/svg/white-x-icon.svg");
const closeIcon = require("../../../../../images/svg/x-icon.svg");
const videoSlashSvg = require("../../../../../images/svg/video-slash-icon.svg");

export const MAX_RECORDING_TIME = 300;
const FORMATTED_MAX_RECORDING_TIME = `${MAX_RECORDING_TIME / 60}:00`;

/**
 * Creates and styles an HTML element.
 */
const createStyledElement = (tag: string, cssText: string) => {
    const element = document.createElement(tag);
    element.style.cssText = cssText;
    return element;
};

/**
 * Opens a Picture-in-Picture (PiP) window with recording UI.
 */
export const openPendingPipWindow = async (
    streamType: "camera" | "screen",
    messagePort: MessagePort,
    dimensions: { width: number, height: number }
) => {
    try {
        // @ts-ignore - Experimental PiP API
        const pipWindow: Window = await window.documentPictureInPicture.requestWindow({
            width: dimensions.width,
            height: dimensions.height,
            preferInitialWindowPlacement: true,
            disallowReturnToOpener: true
        });

        // Apply global styles
        const style = createStyledElement("style", "");
        style.textContent = pipStyles.global;
        pipWindow.document.head.appendChild(style);

        // Create main wrapper
        const wrapper = createStyledElement("div", streamType === "screen" ? pipStyles.screenWrapper : pipStyles.cameraWrapper);
        wrapper.setAttribute("id", ELEMENT_IDS.PIP_WRAPPER);

        // Create wrapper for bottom container
        const bottomContainer = createStyledElement("div", pipStyles.bottomContainer);
        bottomContainer.setAttribute("id", ELEMENT_IDS.BOTTOM_CONTAINER);

        // Create control bar
        const controlBar = pipWindow.document.createElement("div");
        controlBar.setAttribute("id", ELEMENT_IDS.CONTROL_BAR);

        // Create canvas container
        const canvasContainer = createStyledElement("div", pipStyles.canvasContainer);
        canvasContainer.id = ELEMENT_IDS.CANVAS_CONTAINER;

        const canvas = createStyledElement("canvas", pipStyles.canvas) as HTMLCanvasElement;
        canvasContainer.appendChild(canvas);

        // Assemble control bar
        controlBar.append(canvasContainer);

        // Initialize Rive animation
        const rive = createAnimatedRecordWindow(pipWindow, canvas, messagePort);

        // Append elements to PiP window
        pipWindow.document.body.append(wrapper);
        bottomContainer.append(controlBar);
        wrapper.append(bottomContainer);

        // Handle manual PiP window closure
        pipWindow.addEventListener("unload", () => {
            rive.cleanup();
            messagePort.postMessage(RecordingEvents.Stop);
        }, { once: true });

        pipWindow.addEventListener("resize", () => {
            rive.resizeDrawingSurfaceToCanvas();
        });

        // Prevent mouse right click in PIP
        pipWindow.document.addEventListener("contextmenu", event => event.preventDefault());

        return pipWindow;
    }
    catch (err) {
        setErrorNotification({ message: "Something went wrong. Try again or contact Support." });
        reportRecorderError(err, {
            recorderEventType: RecorderLoggingEventType.FailedToOpenPipWindow,
            metadata: { streamType, dimensions }
        });
    }
};

const removePipVideo = (pipWindow: Window) => {
    pipWindow.document.querySelector("video")?.remove();
};

/*
   Make settings button to be visible and add image to pip
   Handler for on click is added in addSettingPopover function
 */
export const addSettingsAndMicToPip = (pipWindow: Window) => {
    if (!pipWindow) return;

    // Create settings wrapper container
    const settingsWrapper = createStyledElement("div", pipStyles.settingsButton);
    settingsWrapper.id = ELEMENT_IDS.SETTINGS_WRAPPER;

    const micContainer = document.createElement("div");
    micContainer.setAttribute("id", ELEMENT_IDS.MIC_WRAPPER);

    const controlBar = pipWindow.document.getElementById(ELEMENT_IDS.CONTROL_BAR);
    controlBar.style.cssText = pipStyles.controlBar;
    controlBar.prepend(settingsWrapper);
    controlBar.append(micContainer);

    const settingsButton = document.createElement("img") as HTMLImageElement;
    settingsButton.src = settingsSvg;
    settingsButton.alt = "settings";
    settingsButton.id = ELEMENT_IDS.SETTINGS_IMAGE;

    settingsWrapper.appendChild(settingsButton);
};

/**
 * Adds a video stream to the PiP window.
 */
export const addVideoStreamToPip = (pipWindow: Window, stream: MediaStream) => {
    if (!pipWindow) return;

    //find video tag element and remove it
    removePipVideo(pipWindow);

    const pipVideo = createStyledElement("video", pipStyles.video) as HTMLVideoElement;
    pipVideo.srcObject = stream;
    pipVideo.autoplay = true;
    pipVideo.muted = true;

    pipWindow.document.body.prepend(pipVideo);
};

/**
 * Shows a countdown animation in the PiP window.
 */
export const showCountdownAnimationPipWindow = (pipWindow: Window, messagePort: MessagePort) => {
    if (!pipWindow) return;

    const wrapper = createStyledElement("div", pipStyles.countdownWrapper);
    wrapper.setAttribute("id", ELEMENT_IDS.COUNTDOWN_ANIMATION);

    const canvas = createStyledElement("canvas", "height: 232px;") as HTMLCanvasElement;

    const rive = countdownAnimation(canvas, messagePort);

    pipWindow.document.body.appendChild(wrapper);
    wrapper.appendChild(canvas);

    // Handle manual PiP window closure
    pipWindow.addEventListener("unload", () => {
        rive.cleanup();
    }, { once: true });

    pipWindow.addEventListener("resize", () => {
        rive.resizeDrawingSurfaceToCanvas();
    });
};

export const removeCountdownAnimation = (pipWindow: Window) => {
    pipWindow?.document.getElementById(ELEMENT_IDS.COUNTDOWN_ANIMATION)?.remove();
};

export const addMicBarToPip = (pipWindow: Window) => {
    if (pipWindow.document) {
        const container = pipWindow.document.getElementById("mic-wrapper");

        const micVisualizer = pipWindow.document.createElement("div");
        micVisualizer.id = ELEMENT_IDS.MIC_VISUALIZER;
        micVisualizer.style.cssText = pipStyles.micVisualizer;
        container.appendChild(micVisualizer);
    }
};

export const addRecordLabel = (pipWindow: Window) => {
    pipWindow?.document.getElementById(ELEMENT_IDS.RECORD_LABEL)?.remove();

    if (pipWindow.document) {
        const recordLabel = createStyledElement("label", pipStyles.recordLabel) as HTMLLabelElement;
        recordLabel.innerText = "Click to record";
        recordLabel.id = ELEMENT_IDS.RECORD_LABEL;

        const bottomContainer = pipWindow.document.getElementById(ELEMENT_IDS.BOTTOM_CONTAINER);
        bottomContainer.prepend(recordLabel);
    }
};


export const removeSettings = (pipWindow?: Window) => {
    pipWindow?.document.getElementById(ELEMENT_IDS.SETTINGS_IMAGE)?.remove();
    const settingsWrapper = pipWindow?.document.getElementById(ELEMENT_IDS.SETTINGS_WRAPPER) as HTMLButtonElement;
    if (!settingsWrapper) return;

    settingsWrapper.setAttribute("disabled", "true");
    settingsWrapper.style.pointerEvents = "none";
    settingsWrapper.onclick = () => {};
};

// Function to clean up device labels
const cleanLabel = (label: string) => label
    .replace(/^(Default - |Communications - )/, "") // Remove common prefixes
    .replace(/\s*\((\w{4}:\w{4})\)$/, "") // Remove hardware IDs like (046d:085b)
    .replace(/(\s*\(.*?(Audio|Driver|HD|Bluetooth|USB).*?\))$/, "") // Remove technical info
    .trim();

export const addSettingScreen = (
    pipWindow: Window,
    selectedCamera: MediaDeviceInfo,
    cameras: MediaDeviceInfo[] = [],
    selectedMicrophone: MediaDeviceInfo,
    microphones: MediaDeviceInfo[] = [],
    handleInputOnChange: (cameraId: string, microphoneId: string) => void,
    onSettingsButtonClick: () => void
) => {
    if (!pipWindow) return;
    //get setting wrapper element
    const settingsWrapper = pipWindow.document.getElementById(ELEMENT_IDS.SETTINGS_WRAPPER);
    if (!settingsWrapper) return;

    //remove settings screen from dom
    pipWindow.document.getElementById(ELEMENT_IDS.SETTINGS_SCREEN)?.remove();

    //create new settings screen
    const settingsScreen = createStyledElement("div", pipStyles.settingsScreen);
    settingsScreen.id = ELEMENT_IDS.SETTINGS_SCREEN;

    const settingsContent = createStyledElement("div", pipStyles.settingsContent);

    //create camera select container
    const cameraSelectContainer = createStyledElement("div", pipStyles.selectContainer);

    //create select element
    const cameraSelect = createStyledElement("select", pipStyles.select) as HTMLSelectElement;
    cameraSelect.value = selectedCamera.deviceId;

    //create sub-options for cameras
    cameras.forEach(({ label, deviceId }) => {
        const cameraOption = createStyledElement("option", "") as HTMLOptionElement;
        cameraOption.setAttribute("value", deviceId);
        cameraOption.textContent = cleanLabel(label);
        if (selectedCamera.deviceId === deviceId) {
            cameraOption.selected = true;
        }
        cameraSelect.append(cameraOption);
    });
    const cameraLabel = createStyledElement("label", pipStyles.selectLabel);
    cameraLabel.innerText = "Camera";

    cameraSelectContainer.append(cameraLabel, cameraSelect);

    //create camera select container
    const microphoneSelectContainer = createStyledElement("div", pipStyles.selectContainer);

    //create microphone element
    const microphoneSelect = createStyledElement("select", pipStyles.select) as HTMLSelectElement;
    microphoneSelect.value = selectedMicrophone.deviceId;

    //create sub-options for microphones
    microphones.forEach(({ label, deviceId }) => {
        const micOption = createStyledElement("option", "") as HTMLOptionElement;
        micOption.setAttribute("value", deviceId);
        micOption.textContent = cleanLabel(label);
        if (selectedMicrophone.deviceId === deviceId) {
            micOption.selected = true;
        }
        microphoneSelect.append(micOption);
    });

    const microphoneLabel = createStyledElement("label", pipStyles.selectLabel);
    microphoneLabel.innerText = "Microphone";

    microphoneSelectContainer.append(microphoneLabel, microphoneSelect);

    //create apply button
    const applyButton = createStyledElement("button", "");
    applyButton.textContent = "Apply";
    applyButton.style.cssText = pipStyles.applyButton;
    applyButton.onclick = () => {
        const selectedCameraOptionId = cameraSelect.value;
        const selectedMicrophone = microphoneSelect.value;
        settingsScreen.style.display = "none";
        handleInputOnChange(selectedCameraOptionId, selectedMicrophone);
    };

    const exitButton = createStyledElement("button", pipStyles.exitButton);

    const cancelIcon = createStyledElement("img", "cursor: pointer;") as HTMLImageElement;
    cancelIcon.src = closeIconWhite;
    cancelIcon.alt = "cancelIcon";
    cancelIcon.style.fill = "#FFFFFF";

    exitButton.append(cancelIcon);

    exitButton.onclick = () => {
        settingsScreen.style.display = "none";
        cameraSelect.value = selectedCamera.deviceId;
        microphoneSelect.value = selectedMicrophone.deviceId;
    };

    settingsScreen.append(settingsContent);
    settingsContent.append(exitButton);
    settingsContent.append(cameraSelectContainer);
    settingsContent.append(microphoneSelectContainer);
    settingsContent.append(applyButton);
    pipWindow.document.body.append(settingsScreen);

    settingsWrapper.onclick = function() {
        settingsScreen.style.display = settingsScreen.style.display === "flex" ? "none" : "flex";
        onSettingsButtonClick();
    };
};

export const updateTimeLabel = (pipWindow: Window, time: number) => {
    if (!pipWindow) return;

    const minuets = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinuets = minuets < 10 ? `0${minuets}` : minuets;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    const recordLabel = pipWindow.document.getElementById(ELEMENT_IDS.RECORD_LABEL);

    if (recordLabel) {
        recordLabel.innerText = `${formattedMinuets}:${formattedSeconds} / ${FORMATTED_MAX_RECORDING_TIME}`;
    }
};

export const removeTimeLimitScreen = (pipWindow: Window) => {
    pipWindow.document.getElementById(ELEMENT_IDS.TIME_LIMIT_SCREEN)?.remove();
};

export const addTimeLimitScreen = (pipWindow: Window, onSaveHandler: () => void, onRetakeHandler: () => void) => {
    if (!pipWindow) return;

    const screenWrapper = createStyledElement("div", pipStyles.timesUpScreenWrapper);
    screenWrapper.id = ELEMENT_IDS.TIME_LIMIT_SCREEN;

    const container = createStyledElement("div", pipStyles.timesUpContainer);

    const label = createStyledElement("label", pipStyles.recordLabel);
    label.innerText = "Recording stopped. \n The 5 minuet time limit was exceeded.";
    label.style.textAlign = "center";

    const saveButton = createStyledElement("button", pipStyles.saveButton);
    saveButton.innerText = "Save";
    saveButton.onclick = onSaveHandler;

    const reRecordButton = createStyledElement("button", pipStyles.reRecordButton);
    reRecordButton.onclick = onRetakeHandler;

    const revertIcon = document.createElement("img") as HTMLImageElement;
    revertIcon.src = blueRevertSvg;
    revertIcon.alt = "revertSvg";
    revertIcon.style.width = "13px";
    revertIcon.style.height = "13px";
    revertIcon.style.marginRight = "8px"; // Adds spacing between icon and label

    reRecordButton.append(revertIcon, "Re-record");

    container.appendChild(label);
    container.appendChild(saveButton);
    container.appendChild(reRecordButton);

    screenWrapper.append(container);
    pipWindow.document.body.append(screenWrapper);
};

export const setupCameraPipWindow = (
    pipWindow: Window | null,
    stream: MediaStream,
    cameras: MediaDeviceInfo[],
    mics: MediaDeviceInfo[],
    width: number,
    height: number,
    handleInputOnChange: (cameraId: string, microphoneId: string, width: number, height: number) => void,
    onSettingsButtonClick: () => void,
) => {
    if (!pipWindow) return;

    addVideoStreamToPip(pipWindow, stream);
    addSettingsAndMicToPip(pipWindow);
    addMicBarToPip(pipWindow);
    startMicVisualizer(stream, pipWindow);

    const selectedCamera = cameras.find(({ label }) => stream.getVideoTracks()[0].label.toLowerCase().includes(label.toLowerCase()));
    const selectedMicrophone = mics.find(({ label }) => stream.getAudioTracks()[0].label.toLowerCase().includes(label.toLowerCase()));

    addSettingScreen(
        pipWindow,
        selectedCamera ?? cameras[0],
        cameras,
        selectedMicrophone ?? mics[0],
        mics,
        (cameraId, microphoneId) => handleInputOnChange(cameraId, microphoneId, width, height),
        onSettingsButtonClick
    );
};

export const openRetakeDialog = (pipWindow: Window, onRetakeHandler: () => void, onCancelHandler: () => void) => {
    if (!pipWindow) return;

    const dialogWrapper = createStyledElement("div", pipStyles.retakeDialogWrapper);
    dialogWrapper.id = ELEMENT_IDS.RETAKE_DIALOG;

    const retakeDialog = createStyledElement("div", pipStyles.retakeDialog);

    // Title + Close
    const titleWrapper = createStyledElement("div", pipStyles.retakeDialogTitleWrapper);

    const dialogTitle = createStyledElement("label", pipStyles.retakeDialogTitle);
    dialogTitle.innerText = "Re-record your footage?";


    const closeButtonWrapper = createStyledElement("div", pipStyles.retakeDialogCloseButtonWrapper);
    closeButtonWrapper.onclick = onCancelHandler;

    const closeButton = createStyledElement("img", pipStyles.retakeDialogCloseButton) as HTMLImageElement;
    closeButton.src = closeIcon;
    closeButton.alt = "Close";

    closeButtonWrapper.appendChild(closeButton);
    titleWrapper.append(dialogTitle, closeButtonWrapper);

    // Confirmation text
    const retakeDialogContent = createStyledElement("label", pipStyles.retakeDialogContent);
    retakeDialogContent.innerText = "Your existing footage will be deleted and cannot be recovered.";

    // Dialog actions
    const dialogActionsWrapper = createStyledElement("div", pipStyles.dialogActionsWrapper);

    const cancelButton = createStyledElement("button", pipStyles.retakeDialogCancelButton);
    cancelButton.innerText = "Cancel";
    cancelButton.onclick = onCancelHandler;

    const retakeButton = createStyledElement("button", pipStyles.retakeDialogRetakeButton);
    retakeButton.innerText = "Re-record";
    retakeButton.onclick = onRetakeHandler;

    dialogActionsWrapper.append(cancelButton, retakeButton);

    retakeDialog.append(titleWrapper, retakeDialogContent, dialogActionsWrapper);
    dialogWrapper.append(retakeDialog);
    pipWindow.document.body.append(dialogWrapper);
};

export const closeRetakeDialog = (pipWindow: Window) => {
    pipWindow.document.getElementById(ELEMENT_IDS.RETAKE_DIALOG)?.remove();
};


export const addNoCameraAvailableScreen = (pipWindow: Window, onClose: () => void) => {
    if (!pipWindow) return;

    pipWindow.document.getElementById(ELEMENT_IDS.PIP_WRAPPER)?.remove();

    const screenWrapper = createStyledElement("div", pipStyles.settingsScreen);
    screenWrapper.style.display = "flex";
    const screenContent = createStyledElement("div", pipStyles.noCameraAvailableContent);

    const videoSlashIcon = createStyledElement("img", "") as HTMLImageElement;
    videoSlashIcon.src = videoSlashSvg;
    videoSlashIcon.alt = "videoSlashIcon";

    const titleContainer = createStyledElement("div", pipStyles.noCameraAvailableTitleContainer);

    const title = createStyledElement("label", pipStyles.noAvailableCameraTitle);
    title.innerText = "Camera is unavailable";
    const description = createStyledElement("label", pipStyles.noAvailableCameraTitle);
    description.style.fontSize = "14px";
    description.innerText = "Another app may be using it, try closing it and try again";

    titleContainer.append(title, description);

    const closeButton = createStyledElement("button", pipStyles.noAvailableCameraCloseButton);
    closeButton.textContent = "Close";
    closeButton.onclick = onClose;

    screenContent.append(videoSlashIcon, titleContainer, closeButton);
    screenWrapper.append(screenContent);
    pipWindow.document.body.append(screenWrapper);
};
