import type { EvpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { getSskyErrorCodeFromGqlError } from "../../../../../common/errors";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreateOrUpdateEmbedVideoCodeMutation,
    GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables,
    GqlClientEditorProgram,
    GqlClientEditorProgramForEmbedVideoBuilderQuery,
    GqlClientEditorProgramForEmbedVideoBuilderQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { CreateOrUpdateEmbedVideoCodeDocument, EditorProgramForEmbedVideoBuilderDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { localEditorEmbedVideoPageConfigurationVar, localEditorInitialEmbedVideoPageConfigurationVar } from "../../State";
import { getCurrentFullStoryUrl } from "../../Utils";
import { createInitialEvpConfiguration, getInitialEvpConfigurationInput, updateEvpConfigurationWithCurrentSettings } from "../../Utils/EmbedVideoPageBuilder";
import type { ConnectorType } from "@sundaysky/customer-data-common-goblin-ds";


export type EmbedVideoCodeDetails = {
    id: string;
    updated: string;
    updatedBy: string;
};

export const setEmbedVideoPageConfiguration = (configuration: EvpConfiguration) => {
    localEditorEmbedVideoPageConfigurationVar(configuration);
};
export const setEmbedVideoPageInitialConfiguration = (configuration: EvpConfiguration) => {
    localEditorInitialEmbedVideoPageConfigurationVar(configuration);
};

export const getEmbedVideoPageConfiguration = () => {
    return localEditorEmbedVideoPageConfigurationVar();
};

export type CreateOrUpdateEmbedVideoCodeInput = {
    programId: GqlClientEditorProgram["id"],
    embedVideoCodeDetails: EmbedVideoCodeDetails;
    editedConfiguration?: EvpConfiguration;
    connectorType: ConnectorType;
};

export const createOrUpdateEmbedVideoCode = async ({
    programId,
    embedVideoCodeDetails,
    editedConfiguration,
    connectorType
}: CreateOrUpdateEmbedVideoCodeInput) => {
    const client = getApolloClient();
    const isNewVideoPageEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);

    const configuration = isNewVideoPageEnabled
        ? editedConfiguration || await getUpdatedEmbedCodeConfiguration(programId)
        : getEmbedVideoPageConfiguration();
    const createOrUpdateEmbedVideoCodeInput = {
        program_id: programId,
        embed_video_code_id: embedVideoCodeDetails?.id,
        configuration,
        fsUrl: getCurrentFullStoryUrl(),
        connectorType
    };

    try {
        await client.mutate<
            GqlClientCreateOrUpdateEmbedVideoCodeMutation,
            GqlClientCreateOrUpdateEmbedVideoCodeMutationVariables
        >({
            mutation: CreateOrUpdateEmbedVideoCodeDocument,
            variables: { createOrUpdateEmbedVideoCodeInput }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export async function getUpdatedEmbedCodeConfiguration(programId: string): Promise<EvpConfiguration> {
    const client = getApolloClient();
    const { data } = await client.query<
        GqlClientEditorProgramForEmbedVideoBuilderQuery,
        GqlClientEditorProgramForEmbedVideoBuilderQueryVariables
    >({
        query: EditorProgramForEmbedVideoBuilderDocument,
        variables: {
            programId
        }
    });
    const updateConfigurationInput = getInitialEvpConfigurationInput(data.editorProgram);
    if (data?.editorProgram?.embedVideoCode?.configuration) {
        return updateEvpConfigurationWithCurrentSettings(
            data.editorProgram.embedVideoCode.configuration,
            updateConfigurationInput
        );
    }
    else {
        return createInitialEvpConfiguration(updateConfigurationInput);
    }
}
