import type { ProgramName } from "../../types";
import { useReactiveVar } from "@apollo/client";
import { localEditorProgramNameVar } from "../../State";

export type UseEditorProgramName = {
    programName: ProgramName
};

export const useEditorProgramName = (): UseEditorProgramName => {
    const programName = useReactiveVar(localEditorProgramNameVar);

    return { programName };
};