import { getSskyErrorCodeFromGqlError } from "../../../../../common/errors";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientLibraryViewType,
    GqlClientUpdateMediaLibraryGridColumnsMutation,
    GqlClientUpdateMediaLibraryGridColumnsMutationVariables,
    GqlClientUpdateMediaLibraryViewTypeMutation,
    GqlClientUpdateMediaLibraryViewTypeMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    UpdateMediaLibraryGridColumnsDocument,
    UpdateMediaLibraryViewTypeDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import {
    localEditorMediaDragAndDropSnackbarState,
    localEditorMediaLibraryByAudienceCaseIndex,
    localEditorMediaLibraryDraggingFromFolderId, localEditorMediaLibraryGridColumns
} from "../../State";
import type { EditorMediaDragAndDropSnackbarState } from "../../types";

export function getMediaLibraryByAudienceCaseIndex() {
    return localEditorMediaLibraryByAudienceCaseIndex();
}

export function setMediaLibraryByAudienceCaseIndex(value: number | null) {
    localEditorMediaLibraryByAudienceCaseIndex(value);
}

export function getMediaDragAndDropSnackbarState() {
    return localEditorMediaDragAndDropSnackbarState();
}

export function setMediaDragAndDropSnackbarState(value: EditorMediaDragAndDropSnackbarState) {
    localEditorMediaDragAndDropSnackbarState(value);
}

export const setMediaLibraryViewType = async (newViewType: GqlClientLibraryViewType) => {
    try {
        await getApolloClient().mutate<GqlClientUpdateMediaLibraryViewTypeMutation, GqlClientUpdateMediaLibraryViewTypeMutationVariables>({
            mutation: UpdateMediaLibraryViewTypeDocument,
            variables: { input: { mediaLibraryViewType: newViewType } }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export function getMediaLibraryGridColumns() {
    return localEditorMediaLibraryGridColumns();
}

export function setMediaLibraryGridColumns(value: number) {
    localEditorMediaLibraryGridColumns(value);
}

export async function persistMediaLibraryGridColumns() {
    try {
        await getApolloClient().mutate<
            GqlClientUpdateMediaLibraryGridColumnsMutation,
            GqlClientUpdateMediaLibraryGridColumnsMutationVariables
        >({
            mutation: UpdateMediaLibraryGridColumnsDocument,
            variables: {
                columns: getMediaLibraryGridColumns()
            }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
}

export function getMediaLibraryDraggingFromFolderId() {
    return localEditorMediaLibraryDraggingFromFolderId();
}

export function setMediaLibraryDraggingFromFolderId(value: string | null) {
    localEditorMediaLibraryDraggingFromFolderId(value);
}
