import { PROGRAM_ERROR_TEXT } from "../../logic/common/programUtils";

const parser = require("ua-parser-js");
import { FILTER_ALL_SCENES, FILTER_ALL_UNUSED_SCENES, SUPPORTED_BROWSERS } from "./Consts";
import { VideoQualityProfile } from "../../../common/external/videoSpec";

/**
 * Returns true if both parameters are empty arrays or empty objects, otherwise false
 * @param param1 an array or object
 * @param param2 an array or object
 * @returns {boolean}
 */
export function bothEmpty(param1, param2) {
    if (Array.isArray(param1) && Array.isArray(param2)) {
        return param1.length === 0 && param2.length === 0;
    }
    if (param1 && param1.constructor === Object && param2 && param2.constructor === Object) {
        return Object.keys(param1).length === 0 && Object.keys(param2).length === 0;
    }
    return false;
}

export function getBooleanDropDownItems(positiveText, negativeText?) {
    return [
        { name: true, title: positiveText },
        { name: false, title: negativeText }
    ];
}

export function valueExists(value): boolean {
    return value !== undefined && value !== null && value !== "" && (!Array.isArray(value) || value.length > 0);
}

export function handleOutputFormatForDownload(jsonSpecObj) {
    jsonSpecObj.output = jsonSpecObj.output || {};

    jsonSpecObj.output.formats = {
        mp4: {
            format: "mp4"
        }
    };
}

export function getDisplayValueForSystemDataElement(dataElementName, value) {
    if (dataElementName === "TimeZone") {
        return value.replace(new RegExp("_", "g"), "-");
    }
    return value;
}

export function stableSort(arr, compare) {
    let original = arr.slice(0);

    arr.sort(function(a, b) {
        let result = compare(a, b);
        return result === 0 ? original.indexOf(a) - original.indexOf(b) : result;
    });

    return arr;
}

export function isChrome(userAgent) {
    let ua = parser(userAgent);
    // @ts-ignore
    return ua && ua.browser && SUPPORTED_BROWSERS.includes(ua.browser.name);
}

export function renameFile(file, newName) {
    return new File([file.slice(0, file.size, file.type)], newName, { lastModified: file.lastModified, type: file.type });
}

export function sortArrayByOrder(array: any[], order: any[], key: string) {
    let arr = array.slice(0);

    let orderMap = array.reduce((acc, item) => Object.assign({ ...acc, [item[key]]: order.indexOf(item[key]) }), {});

    arr.sort((a, b) => {
        let A = a[key];
        let B = b[key];

        if (orderMap[A] > orderMap[B]) {
            return 1;
        }
        else {
            return -1;
        }
    });

    return arr;
}

export function sortObjectsByField<T extends object>(arr: T[], fieldName: string & keyof T) {
    return arr.sort((obj1: T, obj2: T) => {

        const val1 = obj1[fieldName];
        const val2 = obj2[fieldName];

        if (typeof val1 === "string" && typeof val2 === "string") {
            return val1.localeCompare(val2);
        }

        if (typeof val1 === "number" && typeof val2 === "number") {
            return val1 - val2;
        }

        throw `The given field: ${fieldName}, is illegal`;
    });
}

export function isStorySelected(selectedStoryId: string) {
    return selectedStoryId !== FILTER_ALL_SCENES && selectedStoryId !== FILTER_ALL_UNUSED_SCENES;
}

export function isRenderResolutionFactorRelevant(videoQualityProfile: VideoQualityProfile | undefined): boolean {
    // renderResolutionFactor is not relevant for HD videos
    return ![VideoQualityProfile.HD, VideoQualityProfile.HD_READY, VideoQualityProfile.HD_READY_HIGH, VideoQualityProfile.HD_READY_INTERMEDIATE].includes(videoQualityProfile);
}

export function validateProgramName(trimmedProgramName: string, usedProgramNames: Set<string>): PROGRAM_ERROR_TEXT {
    if (!trimmedProgramName) {
        return PROGRAM_ERROR_TEXT.NoValue;
    }
    if (usedProgramNames.has(trimmedProgramName.toLowerCase())) {
        return PROGRAM_ERROR_TEXT.IsInUse;
    }
    return PROGRAM_ERROR_TEXT.None;
}

export function isSundayskyUser(email: string) {
    return email.toLowerCase().endsWith("@sundaysky.com") && !email?.toLowerCase().match("support[0-9]@sundaysky.com");
}

/**
 * Debounce function that will only call the function if the arguments are the same as the previous call.
 *
 * The first call starts a timer.
 * If the function is called again with different arguments, the timer is reset.
 * If the function is called again with the same arguments, it will be executed when the timer expires.
 * If the timer expires, the function is called.
 * If the component unmounts, the timer is cancelled.
 * The timer can be cancelled manually by calling the cancel function.
 *
 * @param func Function to debounce
 * @param delay Time to wait before calling the function
 * @param compareArgs Function to compare the arguments of the previous call and the current call
 */
export function sameArgsDebounce<F extends(...args: any[]) => any>(
    func: F,
    delay: number,
    compareArgs: (prevArgs: Parameters<F>, currArgs: Parameters<F>) => boolean
): SameArgsDebouncedFunction<F> {
    let prevArgs: Parameters<F>;
    let timeoutId: number;

    function debounced(...currArgs: Parameters<F>) {
        if (!prevArgs || compareArgs(prevArgs, currArgs)) {
            if (!timeoutId) {
                timeoutId = setTimeout(
                    () => {
                        func(...currArgs);
                    },
                    delay
                ) as unknown as number; // Our current setup uses Node types instead of browser types
            }
        }
        else {
            clearTimeout(timeoutId);
            timeoutId = undefined;
        }
        prevArgs = currArgs;
    }

    debounced.cancel = () => {
        clearTimeout(timeoutId);
        timeoutId = undefined;
    };

    return debounced;
}

export type SameArgsDebouncedFunction<F extends (...args: any[]) => any> = {
    /**
     * Function to call
     */
    (...args: Parameters<F>): void;

    /**
     * Manually cancel timeout
     */
    cancel: () => void;
};
