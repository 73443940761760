import * as FullStory from "@fullstory/browser";
import type { AuthServiceInterface, GetServiceTypeForUser, GetServiceTypeForUserResponse, SskyAuth0Config, SskyCognitoConfig, SskyOktaConfig } from "@sundaysky/smartvideo-hub-auth";
import { AuthService, normalizeUsername } from "@sundaysky/smartvideo-hub-auth";
import React, { lazy, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { newNavLoginPath, newNavRootPath, newNavYourVideosPath } from "@sundaysky/smartvideo-hub-urls";
import type { Account, GetConfResponse, GetUserAuthServiceResponse } from "../../common/types/restApiTypes";

import { getApolloClient, initApolloClient } from "../apollo";
import { initAIImageGenPromptChatService } from "../logic/common/aiImageGenPromptChatService";
import ConfigServices from "../logic/common/BaseServices";
import { reportErrorRV } from "../logic/common/commonActions";
import CommonServices from "../logic/common/commonServices";
import { EDITING_MODE, STAGE_MODE } from "../logic/common/Consts";
import LandingPagePreviewServices from "../logic/common/landingPagePreviewServices";
import { reportErrorToSplunk } from "../logic/common/logReportUtils";
import { OktaForgotPasswordServices } from "../logic/common/oktaForgotPasswordServices";
import RaasServices from "../logic/common/raasServices";
import UrlUtils from "../logic/common/urlUtils";
import VideoPreviewServices from "../logic/common/videoPreviewServices";
import PreviewServices from "../logic/preview/PreviewServices";
import ProjectServices from "../logic/projects/ProjectsServices";
import { getAccountIdFromPath } from "../logic/common/routingUtils";
import type { UserData } from "../logic/user/UserServices";
import { UserServices } from "../logic/user/UserServices";
import { FeatureFlagsServices } from "../logic/featureFlags/featureFlagsServices";
import { isAxiosError } from "../utils/axiosUtils";
import type { BuilderModeRetrieverInterface } from "./BuilderModeRetriever";
import { BuilderModeRetriever } from "./BuilderModeRetriever";
import { isRedirectFromLogin, redirectAfterLogin, saveRedirectTo } from "./login/loginUtils";
import { activeAccountIdVar, activeAccountNameVar, featureFlagsVar, userDataVar } from "./model";
import { DelayedSpinner } from "./newNav/components/common/DelayedSpinner";
import { NewNavigationRouter } from "./NewNavigationRouter";
import loadIcons from "./loadFontAwesomeIcons";
import "../css/newNavigationIndex.css";
import { HUB_CONTAINER_ID } from "../consts";
import { addService } from "./services";
import { NewNavThemeProvider } from "../utils/themeUtils";
import TableauRestService from "../logic/common/tableauRestService";
import { StyledEngineProvider } from "@mui/material/styles";
import TestSftpConnectionService from "../logic/common/testSftpConnectionService";
import CordServices from "../logic/common/cordServices";
import OAuth2Services from "../logic/common/oAuth2Services";
import { MainErrorBoundary } from "./errorBoundary/MainErrorBoundary";
import { ErrorFallbackPage } from "./errorBoundary/Components/ErrorFallbackPage";
import { ApolloProvider } from "@apollo/client";
import "@fortawesome/fontawesome-pro/css/all.css";
// import { initConvergenceService } from "../logic/convergence/convergenceService";
import FileToVideoServices from "../logic/common/fileToVideoService";
import PromptToVideoServices from "../logic/common/promptToVideoService";
import { reportTimedActivity } from "../utils/timedActivityReportingUtils";
import { initPromptToVideoChatServices } from "../logic/common/promptToVideoChatService";
import { initScheduledMemoryMeasurements } from "../logic/common/memoryMeasurement";


const ERROR_IN_HASH_REGEX = /error=([^&]+)/;
ConfigServices.setActiveAccountIdGetter(() => activeAccountIdVar());
ConfigServices.setActiveAccountNameGetter(() => activeAccountNameVar());
const builderModeRetriever: BuilderModeRetrieverInterface = new BuilderModeRetriever();
const request = new ConfigServices(undefined, builderModeRetriever);
const oktaForgotPasswordServices = new OktaForgotPasswordServices(undefined, builderModeRetriever);
const getServiceTypeForUser: GetServiceTypeForUser = async (username: string): Promise<GetServiceTypeForUserResponse> => {
    const url: string = `/auth?username=${encodeURIComponent(normalizeUsername(username))}`;
    const userAuthServiceResponse: GetUserAuthServiceResponse = await request.get<GetUserAuthServiceResponse>([EDITING_MODE, STAGE_MODE], url);
    return {
        authServiceType: userAuthServiceResponse?.service,
        isFirstLogin: userAuthServiceResponse?.isFirstLogin,
        isUserDeactivated: userAuthServiceResponse?.isUserDeactivated,
        lastLogin: userAuthServiceResponse?.lastLogin
    };
};

const AccessDeniedPage = lazy(() => import("./login/AccessDeniedPage").then(m => ({ default: m.AccessDeniedPage })));
function renderAccessDeniedPage() {
    render(
        <StyledEngineProvider injectFirst>
            <NewNavThemeProvider>
                <Suspense fallback={<DelayedSpinner/>}>
                    <AccessDeniedPage/>
                </Suspense>
            </NewNavThemeProvider>
        </StyledEngineProvider>,
        document.getElementById(HUB_CONTAINER_ID)
    );
}

function renderErrorPage(error: Error) {
    render(
        <StyledEngineProvider injectFirst>
            <NewNavThemeProvider>
                <Suspense fallback={<DelayedSpinner/>}>
                    <ErrorFallbackPage error={error}/>
                </Suspense>
            </NewNavThemeProvider>
        </StyledEngineProvider>,
        document.getElementById(HUB_CONTAINER_ID)
    );
}

const PostLoginPage = lazy(() => import("./login/PostLoginPage").then(m => ({ default: m.PostLoginPage })));
function renderPostLoginPage() {
    render(
        <StyledEngineProvider injectFirst>
            <NewNavThemeProvider>
                <Suspense fallback={<DelayedSpinner/>}>
                    <ApolloProvider client={getApolloClient()}>
                        <PostLoginPage/>
                    </ApolloProvider>
                </Suspense>
            </NewNavThemeProvider>
        </StyledEngineProvider>,
        document.getElementById(HUB_CONTAINER_ID)
    );
}

const PendingInvitationPage = lazy(() => import("./login/PendingInvitationPage").then(m => ({ default: m.PendingInvitationPage })));
function renderPendingInvitationPage(pendingAccount: Account) {
    render(
        <StyledEngineProvider injectFirst>
            <NewNavThemeProvider>
                <Suspense fallback={<DelayedSpinner/>}>
                    <ApolloProvider client={getApolloClient()}>
                        <PendingInvitationPage pendingAccount={pendingAccount}/>
                    </ApolloProvider>
                </Suspense>
            </NewNavThemeProvider>
        </StyledEngineProvider>,
        document.getElementById(HUB_CONTAINER_ID)
    );
}

function renderPage(authService: AuthServiceInterface) {
    loadIcons();
    render(
        <StyledEngineProvider injectFirst>
            <NewNavThemeProvider>
                <BrowserRouter>
                    <MainErrorBoundary>
                        <NewNavigationRouter authService={authService}/>
                    </MainErrorBoundary>
                </BrowserRouter>
            </NewNavThemeProvider>
        </StyledEngineProvider>,
        document.getElementById(HUB_CONTAINER_ID)
    );

    const startTime = window["data-startTime"];
    if (startTime) {
        reportTimedActivity("Initial Page Render", startTime, Date.now());
        delete window["data-startTime"];
    }
}

async function main() {
    FullStory.init({ orgId: "G7HQH" });

    const { authServices: { cognito, okta, auth0 } } = await request.get<GetConfResponse>(
        [EDITING_MODE, STAGE_MODE],
        "/conf"
    );

    const sskyCognitoConfig: SskyCognitoConfig = {
        UserPoolId: cognito.userPoolId,
        ClientId: cognito.clientId,
        IdentityPoolId: cognito.identityPoolId
    };

    const shouldRedirectToGetTokens = (): boolean => {
        const url: URL = new URL(window.location.href);
        const error: string = ERROR_IN_HASH_REGEX.exec(url.hash)?.[1];

        return !isRedirectFromLogin() && error !== "login_required" && error !== "access_denied";
    };

    const sskyOktaConfig: SskyOktaConfig = okta && {
        issuer: okta.issuer,
        clientId: okta.clientId,
        scopes: ["openid", "email"],
        responseMode: "fragment",
        tokenManager: {
            storage: "localStorage",
            secure: true
        },
        pkce: true,
        headers: {
            Pragma: "no-cache"
        },
        restoreOriginalUri: async () => {
            // This is where we know login is successful
            redirectAfterLogin();
        },
        redirectUri: `${window.location.origin}${newNavRootPath()}`,
        postLogoutRedirectUri: `${window.location.origin}${newNavLoginPath()}`,
        initiateForgotPasswordCB: oktaForgotPasswordServices.initiateForgotPassword,
        confirmForgotPasswordCB: oktaForgotPasswordServices.confirmForgotPassword,
        shouldRedirectToGetTokens: shouldRedirectToGetTokens()
    };

    const sskyAuth0Config: SskyAuth0Config = auth0 && {
        domain: new URL(auth0.issuer).hostname,
        clientID: auth0.clientId,
        redirectUri: `${window.location.origin}${newNavRootPath()}`,
        responseMode: "fragment",
        responseType: "id_token",
        scope: "openid email",
        postLogoutRedirectUri: `${window.location.origin}${newNavLoginPath()}`
    };

    const authService = new AuthService(
        sskyCognitoConfig,
        sskyOktaConfig,
        sskyAuth0Config,
        getServiceTypeForUser
    );
    // Save redirect-to, in case login is required
    authService.subscribe(state => {
        if (
            state.isPending === false
            && state.isAuthenticated === false
            && window.location.pathname !== newNavLoginPath()
            && window.location.pathname !== newNavRootPath()
        ) {
            saveRedirectTo();
        }
    });

    /**
     * authServiceGetterPromise is a promise returning authService, only after it's been initialized
     * authServiceInitFinished is the resolve function that releases the promise, and is called after the authService.init() is done
     */
    let authServiceInitFinished;
    const authServiceGetterPromise: Promise<AuthServiceInterface> = new Promise((resolve) => {
        authServiceInitFinished = resolve;
    }).then(() => authService);

    addService("commonServices", new CommonServices(authService, builderModeRetriever));

    if (window.location.pathname !== newNavLoginPath()) {
        try {
            await authService.init();
        }
        catch (err) {
            if (
                err?.errorCode === "login_required"
                || err?.error === "login_required"
            ) {
                // Redirect to login page to authenticate the user
                window.location.href = newNavLoginPath();
                return;
            }
            if (err?.errorCode === "access_denied") {
                renderAccessDeniedPage();
                return;
            }
        }

        // release promise to indicate authService can be used
        authServiceInitFinished();

        const accountIdFromPath = getAccountIdFromPath();
        activeAccountIdVar(accountIdFromPath);

        const userServices = new UserServices(authService, builderModeRetriever);
        let userData: UserData;
        try {
            userData = await userServices.getUserData();
        }
        catch (err) {
            if (isAxiosError(err)) {
                if (err.response.status === 401) {
                    // Redirect to login page to authenticate the user
                    window.location.href = newNavLoginPath();
                    return;
                }
                if (err.response.status === 403) {
                    renderAccessDeniedPage();
                    return;
                }
                if (err.response.status === 500) {
                    renderErrorPage(err);
                    return;
                }
            }
        }
        userDataVar(userData);

        FullStory.identify(userData.userId, {
            userId_str: userData.userId || "Empty UserId",
            email: userData.userName,
            roles_strs: userData.userRoles
        });

        initApolloClient({
            authServiceGetterPromise,
            builderModeRetriever,
            reportError: reportErrorRV,
            getActiveAccountId: activeAccountIdVar,
            getActiveAccountName: activeAccountNameVar,
            getProgramNameFromPath: () => UrlUtils.getAccountAndProgramFromPath().program
        });

        if (userData.accounts.length === 0) {
            if (userData.pendingAccounts.length) {
                renderPostLoginPage();
                return;
            }

            reportErrorToSplunk(
                `Authorization Error: user ${userData.userName} is not assigned to any account`,
                {
                    requestUrl: window.location.href,
                    crashed: false
                }
            );
            renderAccessDeniedPage();
            return;
        }

        const activeAccountId = accountIdFromPath || userData.lastAccessedAccountId;
        activeAccountIdVar(activeAccountId);

        const activePendingAccount = userData.pendingAccounts.find(account => account.accountId === activeAccountId);
        if (activePendingAccount) {
            activeAccountNameVar(activePendingAccount.displayName);
            renderPendingInvitationPage(activePendingAccount);
            return;
        }

        const activeAccount = userData.accounts.find(account => account.accountId === activeAccountId);
        if (!activeAccount) {
            window.location.href = newNavYourVideosPath({ accountId: userData.lastAccessedAccountId });
        }
        activeAccountNameVar(activeAccount?.displayName);

        // Background services
        // initConvergenceService(
        //     userData?.convergenceServerEndpoint,
        //     userData?.convergenceToken
        // );

        const featureFlagsServices = new FeatureFlagsServices(authService, builderModeRetriever);
        const featureFlagsPromise = featureFlagsServices.getFeatureFlags();

        addService("user", userServices);

        //Make selected services globally available
        addService("projectServices", new ProjectServices(authService, builderModeRetriever));
        addService("raasServices", new RaasServices(authService, builderModeRetriever));
        addService("videoPreviewServices", new VideoPreviewServices(authService, builderModeRetriever));
        addService("previewServices", new PreviewServices(authService, builderModeRetriever));
        addService("testSftpConnectionServices", new TestSftpConnectionService(authService, builderModeRetriever));
        addService("landingPagePreviewServices", new LandingPagePreviewServices(authService, builderModeRetriever));
        addService("tableauRestService", new TableauRestService(authService, builderModeRetriever));
        addService("cordServices", new CordServices(authService, builderModeRetriever));
        addService("oAuth2Services", new OAuth2Services(authService, builderModeRetriever));
        addService("fileToVideoServices", new FileToVideoServices(authService, builderModeRetriever));
        addService("promptToVideoServices", new PromptToVideoServices(authService, builderModeRetriever));

        initPromptToVideoChatServices(authService);
        initAIImageGenPromptChatService(authService);

        featureFlagsVar(await featureFlagsPromise);

        initScheduledMemoryMeasurements();
    }
    renderPage(authService);
}

void main();
