import { useCallback, useEffect, useRef, useState } from "react";

const mutationObserverConfig: MutationObserverInit = { attributes: true, childList: true };

export type UseScrollable = {
    canScrollLeft: boolean
    canScrollRight: boolean
    scrollContainerLeft: () => void
    scrollContainerRight: () => void
    scrollerContainerRefCallback: (node: HTMLElement) => void
}

export const useScrollable = (scrollingDelta: number, hideScroller: boolean): UseScrollable => {

    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

    const scrollerContainer = useRef<HTMLElement>(null);
    const mutationObserver = useRef<MutationObserver>(new MutationObserver(() => updateScrollabilty()));
    const resizeObserver = useRef<ResizeObserver>(new ResizeObserver(() => updateScrollabilty()));

    const scrollerContainerRefCallback = useCallback((element: HTMLElement) => {
        if (element) {
            //scrollerContainer is attached
            scrollerContainer.current = element;
            setScrollContainerStyle();
            updateScrollabilty();
            mutationObserver.current.observe(scrollerContainer.current, mutationObserverConfig);
            resizeObserver.current.observe(scrollerContainer.current);
            scrollerContainer.current.addEventListener("scroll", updateScrollabilty);
        }
        else {
            //scrollerContainer is detached
            mutationObserver.current.disconnect();
            resizeObserver.current.disconnect();
            scrollerContainer.current.removeEventListener("scroll", updateScrollabilty);
            scrollerContainer.current = element;
        }
    }, []);

    const setScrollContainerStyle = useCallback((): void => {
        if (scrollerContainer.current) {
            scrollerContainer.current.style["overflow-x"] = hideScroller ? "hidden" : "auto";
        }
    }, [hideScroller]);

    useEffect(() => {
        setScrollContainerStyle();
    }, [setScrollContainerStyle]);

    const scrollContainerBy = useCallback((distance: number): void => {
        scrollerContainer.current.scrollBy({ left: distance, behavior: "smooth" });
    }, []);

    const scrollContainerLeft = useCallback((): void => {
        scrollerContainer.current && scrollContainerBy(-scrollingDelta);
    }, [scrollContainerBy, scrollingDelta]);

    const scrollContainerRight = useCallback((): void => {
        scrollerContainer.current && scrollContainerBy(scrollingDelta);
    }, [scrollContainerBy, scrollingDelta]);

    const updateScrollabilty = useCallback((): void => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollerContainer.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(0 < scrollWidth - clientWidth - scrollLeft);
    }, []);

    return {
        canScrollLeft,
        canScrollRight,
        scrollContainerLeft,
        scrollContainerRight,
        scrollerContainerRefCallback
    };
};
