import React from "react";
import PropTypes from "prop-types";
import { SskyTooltip } from "@sundaysky/ui-component-library/Components";

const IconWithTooltip = (props) => (
    <span style={{ display: "inherit" }}>
        <SskyTooltip title={props.tooltipText} enterDelay={100}>
            <span className={`data-type-icon dsl-icon-${props.iconName}`} />
        </SskyTooltip>
        {props.title}
    </span>
);

IconWithTooltip.propTypes = {
    iconName: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default IconWithTooltip;
