import { localEditorEditRecordingToolDialogState } from "../../State";
import { useReactiveVar } from "@apollo/client";
import type { MediaStreamType } from "../UseRecordingTool";


export const useEditRecordingToolDialog = () => {
    const editRecordingToolDialogState = useReactiveVar(localEditorEditRecordingToolDialogState);

    const openEditRecordingToolDialog = (mediaStreamType: MediaStreamType) => {
        localEditorEditRecordingToolDialogState({ open: true, mediaStreamType });
    };
    const closeEditRecordingToolDialog = () => {
        localEditorEditRecordingToolDialogState({ open: false, mediaStreamType: null });
    };
    return { editRecordingToolDialogState, openEditRecordingToolDialog, closeEditRecordingToolDialog };
};
