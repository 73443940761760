import type { ApolloClientOptions, NormalizedCacheObject, Operation } from "@apollo/client";
import { ApolloClient, ApolloLink, InMemoryCache, Observable, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import type { ErrorResponse } from "@apollo/client/link/error";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import type { AuthServiceInterface } from "@sundaysky/smartvideo-hub-auth";
import generatedIntrospection from "../graphql/graphqlGeneratedTypes/graphqlFragmentTypes";
import type { BuilderModeRetrieverInterface } from "../components/BuilderModeRetriever";
import {
    studioApiVersion,
    studioApiVersionHeaderName,
    studioClientVersionHeaderName,
    X_SSKY_ACCOUNT_DATA_VERSION_HEADER,
    X_SSKY_PAYLOAD_SIZE_HEADER,
    X_SSKY_SERVER_TIMING_HEADER
} from "../../common/commonConst";
import { EDITING_MODE, IGNORE_SERVER_ERRORS, STAGE_MODE } from "../logic/common/Consts";
import { addNetworkActionPerformance } from "../logic/globals/globalPerformance";
import { typePolicies } from "./typePolicies";
import { getLocalEditorAccountDataVersion, handleEditorError, setLocalEditorAccountDataVersion, setNoInternetNotification } from "../components/editor/Nooks";
import { getSskyErrorCodeFromGqlError } from "../../common/errors";
import { getMainDefinition } from "@apollo/client/utilities";
import ApolloLinkTimeout from "apollo-link-timeout";
import { Base64 } from "js-base64";
import { isNewNav } from "../utils/newNavUtils";
import { newNavEditorPath, newNavVideoPagePath } from "@sundaysky/smartvideo-hub-urls";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { sleepMs } from "@sundaysky/common";
import { handleError } from "../logic/common/errorHandlingUtils";

const APOLLO_OPERATION_NAME_HEADER = "x-apollo-operation-name";
const APOLLO_FEDERATION_INCLUDE_TRACE_HEADER = "apollo-federation-include-trace";
const APOLLO_FEDERATION_INCLUDE_TRACE_VALUE = "ftv1";

class StudioModeError extends Error {
    constructor(currentMode) {
        super(`Action not allowed in mode ${currentMode}`);

        this.name = this.constructor.name;

        Error.captureStackTrace(this, this.constructor);
    }
}

type GqlErrorObj = {
    operationData: {
        name: Operation["operationName"];
        variables: Operation["variables"];
    };
    graphQLErrors: ErrorResponse["graphQLErrors"];
    networkError: ErrorResponse["networkError"];
};

export type ApolloClientInstance = ApolloClient<NormalizedCacheObject>;

type CreateApolloClientParams = {
    authServiceGetterPromise: Promise<AuthServiceInterface>;
    builderModeRetriever: BuilderModeRetrieverInterface;
    reportError: (error: any, reportErrorToUser: boolean, userErrorMessage?: string) => void;
    getActiveAccountId: () => string;
    getActiveAccountName: () => string;
    getProgramNameFromPath: () => string;
};

const CLIENT_VERSION = process.env.CLIENT_VERSION; // injected via Webpack DefinePlugin
let apolloClientInstance: ApolloClientInstance;

export const getApolloClient = (): ApolloClientInstance => {
    if (!apolloClientInstance) {
        throw new Error("Apollo client is not initialized");
    }
    return apolloClientInstance;
};

export const initApolloClient = (
    {
        authServiceGetterPromise,
        builderModeRetriever,
        reportError,
        getActiveAccountId,
        getActiveAccountName,
        getProgramNameFromPath
    }: CreateApolloClientParams
): void => {
    if (apolloClientInstance) {
        return;
    }

    const defaultAllowedModes = (operation: Operation): string[] => {
        let definitions = operation && operation.query && operation.query.definitions;
        if (definitions && definitions.some(definition => definition.kind === "OperationDefinition" && definition.operation === "mutation")) {
            return [EDITING_MODE];
        }
        return [EDITING_MODE, STAGE_MODE];
    };

    const createStudioModeLink = (builderModeRetriever: BuilderModeRetrieverInterface): ApolloLink => {
        return new ApolloLink((operation, forward) => {
            const allowedModes = operation.getContext().allowedModes || defaultAllowedModes(operation);
            const currentMode = builderModeRetriever.getMode();

            if (allowedModes.includes(currentMode)) {
                return forward(operation);
            }

            return new Observable(observer => observer.error(new StudioModeError(currentMode)));
        });
    };

    const createAccountDataVersionLink = (): ApolloLink => {
        return new ApolloLink((operation, forward) => {
            return forward(operation).map(response => {
                const context = operation.getContext();
                const { response: { headers } } = context;

                const accountVersion: string = headers.get(X_SSKY_ACCOUNT_DATA_VERSION_HEADER);
                if (accountVersion) {
                    setLocalEditorAccountDataVersion(accountVersion);
                }
                return response;
            });
        });
    };

    const createReportPerformanceLink = (): ApolloLink => {
        return new ApolloLink((operation, forward) => {
            const startTime = Date.now();
            return forward(operation).map((data) => {
                const context = operation.getContext();
                const headers = context?.response?.headers;
                const serverDuration = Number(headers?.get(X_SSKY_SERVER_TIMING_HEADER));
                const payload = Number(headers?.get(X_SSKY_PAYLOAD_SIZE_HEADER));
                addNetworkActionPerformance({
                    kind: "GraphQL",
                    name: operation.operationName,
                    startTime: startTime,
                    endTime: Date.now(),
                    serverDuration: !Number.isNaN(serverDuration) ? serverDuration : 0,
                    payload: !Number.isNaN(payload) ? payload : 0
                });
                return data;
            });
        });
    };

    const createAuthLink = (): ApolloLink =>
        setContext(async (operation, prevContext) => {
            const authService: AuthServiceInterface = await authServiceGetterPromise;
            const token = await authService.getUserAccessToken();
            let studioHeaders: Record<string, string> = {
                [studioApiVersionHeaderName]: studioApiVersion.toString(),
                Authorization: token ? `Bearer ${token}` : "",
                [APOLLO_FEDERATION_INCLUDE_TRACE_HEADER]: APOLLO_FEDERATION_INCLUDE_TRACE_VALUE,
                "account-id": getActiveAccountId(), // TODO change active account id to be on apollo cache
                "account-name": Base64.encode(getActiveAccountName()),
                "legacy-program-id": getProgramNameFromPath() // todo - remove when writing to dynamo via graphql resolver is not needed anymore
            };

            // Add the "x-ssky-account-data-version" header if there's version data, and if we're in the video page or the editor page
            const editorAccountDataVersion: string = getLocalEditorAccountDataVersion();
            const isVideoPage = newNavVideoPagePath.match(window.location.pathname);
            const isEditor = newNavEditorPath.match(window.location.pathname);
            if ((isVideoPage || isEditor) && editorAccountDataVersion) {
                studioHeaders[X_SSKY_ACCOUNT_DATA_VERSION_HEADER] = editorAccountDataVersion;
            }

            // Add apollo specific operation name header for CORS
            const operationName: any = operation?.operationName;
            if (operationName) {
                studioHeaders[APOLLO_OPERATION_NAME_HEADER] = operationName;
            }

            return {
                ...prevContext,
                headers: {
                    ...prevContext.headers,
                    ...studioHeaders
                }
            };
        });

    const createFSLink = (): ApolloLink =>
        setContext((_, prevContext) => {
            const fullStorySessionURL = window && (window as any).FS && typeof (window as any).FS.getCurrentSessionURL === "function" && (window as any).FS.getCurrentSessionURL(true);
            let studioHeaders: Record<string, string> = {
                "x-ssky-fsurl": fullStorySessionURL ? Buffer.from(fullStorySessionURL).toString("base64") : ""
            };
            return {
                ...prevContext,
                headers: {
                    ...prevContext.headers,
                    ...studioHeaders
                }
            };
        });

    const createClientVersionLink = (): ApolloLink =>
        setContext((_, prevContext) => {
            const clientVersionHeader: Record<string, string> = {};

            if (CLIENT_VERSION) {
                clientVersionHeader[studioClientVersionHeaderName] = CLIENT_VERSION;
            }

            return {
                ...prevContext,
                headers: {
                    ...prevContext.headers,
                    ...clientVersionHeader
                }
            };
        });

    const handleErrorInEditorContext = (gqlErrorObj: GqlErrorObj): void => {
        const isNetworkConnectionError = !gqlErrorObj.graphQLErrors && gqlErrorObj.networkError;

        if (isNetworkConnectionError) {
            setNoInternetNotification();
            handleError({ error: gqlErrorObj }).catch(() => {});
        }
        else {
            const firstGqlError = gqlErrorObj.graphQLErrors[0];
            const sskyErrorCode = getSskyErrorCodeFromGqlError(firstGqlError);
            handleEditorError({ error: firstGqlError, sskyCode: sskyErrorCode });
        }
    };

    const handleErrorInNonEditorContext = (gqlErrorObj: GqlErrorObj, showErrorToUser: boolean): void => {
        let userErrorMessage: string;
        //TODO create map between GQL server errors to user facing message.
        if (showErrorToUser) {
            if (gqlErrorObj.networkError && gqlErrorObj.networkError instanceof StudioModeError) {
                userErrorMessage = gqlErrorObj.networkError.message;
            }
            else {
                userErrorMessage = "An error occurred. If the problem persists, please contact support.";
            }
        }
        reportError(gqlErrorObj, showErrorToUser, userErrorMessage);
    };

    const createErrorLink = (): ApolloLink =>
        onError(({ graphQLErrors, networkError, operation }) => {
            const gqlErrorObj: GqlErrorObj = {
                operationData: {
                    name: operation.operationName,
                    variables: operation.variables
                },
                graphQLErrors,
                networkError
            };

            const showErrorToUser: boolean = !gqlErrorObj.operationData.variables[IGNORE_SERVER_ERRORS];

            if (isNewNav) {
                handleErrorInEditorContext(gqlErrorObj);
            }
            else {
                handleErrorInNonEditorContext(gqlErrorObj, showErrorToUser);
            }
        });

    const createTerminatingSplitLink = (httpLink: ApolloLink): ApolloLink => {
        const webSocketProtocol = window.location.protocol === "https:" ? "wss" : "ws";
        const wsLink = new GraphQLWsLink(createClient({
            url: `${webSocketProtocol}://${window.location.host}/subscriptions`,
            shouldRetry: (errOrCloseEvent) => {
                // eslint-disable-next-line no-console
                // console.error("Subscriptions: shouldRetry", errOrCloseEvent);
                return true;
            },
            retryWait: async () => {
                await sleepMs(2000);
            },
            retryAttempts: 1800,
            connectionParams: () => authServiceGetterPromise
                .then((authService: AuthServiceInterface) => {
                    return authService.getUserAccessToken?.();
                }).then(authToken => ({
                    "studio-client-name": "Studio",
                    [studioApiVersionHeaderName]: studioApiVersion.toString(),
                    authorization: authToken ? `Bearer ${authToken}` : "",
                    "account-id": getActiveAccountId()
                }))
        }));

        return split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === "OperationDefinition" &&
                    definition.operation === "subscription"
                );
            },
            wsLink,
            httpLink,
        );
    };

    const createInstance = (): ApolloClientInstance => {

        const timeoutLink = new ApolloLinkTimeout(-1);
        const authLink = createAuthLink();
        const fsLink = createFSLink();
        const errorLink = createErrorLink();
        const studioModeLink = createStudioModeLink(builderModeRetriever);
        const accountDataVersionLink = createAccountDataVersionLink();
        const reportPerformanceLink = createReportPerformanceLink();
        const uploadLink = createUploadLink();
        const clientVersionLink = createClientVersionLink();

        const httpLink = ApolloLink.from([
            // add additional links here
            // order is important
            timeoutLink,
            authLink,
            fsLink,
            clientVersionLink, // Adding client version header
            errorLink,
            studioModeLink, // must be anywhere after errorLink
            accountDataVersionLink,
            reportPerformanceLink, // the later the better
            uploadLink // must be last
        ]);

        const link = createTerminatingSplitLink(httpLink);

        const cache = new InMemoryCache({
            possibleTypes: generatedIntrospection.possibleTypes,
            typePolicies
        });

        const options: ApolloClientOptions<NormalizedCacheObject> = {
            link,
            cache,
            name: "Studio",
            version: CLIENT_VERSION
        };

        // prevent network requests when running tests
        if (process.env.JEST_WORKER_ID !== undefined) {
            options.defaultOptions = {
                query: { fetchPolicy: "cache-only", errorPolicy: "all" }
            };
            options.link = httpLink;
        }

        return new ApolloClient(options);
    };

    apolloClientInstance = createInstance();
};
