import type { GqlClientEditorProgramQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloQueryResult } from "@apollo/client";

export type UseEditorProgram = {
    editorProgram: GqlClientEditorProgramQuery["editorProgram"];
    loading: boolean;
    refetchEditorProgram: () => Promise<ApolloQueryResult<GqlClientEditorProgramQuery>>;
};

export const useEditorProgram = (programId: string): UseEditorProgram => {
    const { data, loading, refetch } = useEditorProgramQuery({ variables: { programId } });
    const editorProgram = data?.editorProgram;
    return {
        loading: loading || !editorProgram,
        editorProgram,
        refetchEditorProgram: refetch
    };
};
