import type { CsvParseResult } from "@sundaysky/csv";
import { csvParse } from "@sundaysky/csv";
import type { ConnectorType, ExtractorConfiguration, SerializedDynamicElement, ValueSetItem } from "@sundaysky/customer-data-common-goblin-ds";
import { v4 as uuid } from "uuid";
import { convertArrayOfObjectsToObject } from "../../../../../common/arrayUtils";
import type { DynamicElementValidationResult, UsedIn } from "../../../../../common/dynamicElementsUtils";
import { buildDynamicElement, createSerializedDynamicElement, getStringElementSource, getStringElementTarget } from "../../../../../common/dynamicElementsUtils";
import { SskyErrorCode } from "../../../../../common/errors";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreateOrUpdateAccountDataFieldsInput,
    GqlClientCreateOrUpdateAccountDataFieldsMutation,
    GqlClientCreateOrUpdateAccountDataFieldsMutationVariables,
    GqlClientEditorProgramDataConnectorQuery,
    GqlClientEditorProgramDataConnectorQueryVariables,
    GqlClientEditorProgramVersionUsedDataFieldsQuery,
    GqlClientEditorProgramVersionUsedDataFieldsQueryVariables,
    GqlClientStudioElementFragment,
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingInput,
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation,
    GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables,
    GqlClientUpdatedDynamicElement
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateOrUpdateAccountDataFieldsDocument,
    EditorProgramDataConnectorDocument,
    EditorProgramVersionUsedDataFieldsDocument,
    UpdateAccountDataLibraryConnectorTypeAndMappingDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import type { DataLibraryDynamicElement, ProgramId, ProgramVersionId, StudioElementExtended, UploadResponse } from "../../types";
import { convertProgramDynamicElementToStudioElement, getConnectorTypeFromProgramVersion, getDataLibraryDataElementsFromProgramVersion } from "../../Utils";
import { getCsvFileValidationError, getElementNamesValidationError } from "../../Utils/dynamicElementsCsvUtils";
import { handleEditorError } from "../Notification";

const maxValuesInValueSet = 2000;

type ElementClassification = Pick<GqlClientCreateOrUpdateAccountDataFieldsInput, "newDynamicElements" | "updatedDynamicElements" | "removedDynamicElements"> & {
    numOfUpdatedDynamicElementsWithChanges: number;
};
type ElementClassificationUpdateOnly = Pick<GqlClientCreateOrUpdateAccountDataFieldsInput, "newDynamicElements" | "updatedDynamicElements">;

export type CreateStudioElementsFromCsvResult = {
    created: number
    updated: number
    removed: number
    validationResult: DynamicElementValidationResult
}

export type UpdateDataElementsInput = Omit<GqlClientCreateOrUpdateAccountDataFieldsInput, "removedDynamicElements">;
export const createValueSetItem = (value: string, id?: string): ValueSetItem => ({ dn: value, id: id ?? uuid() });

const mergeElements = (
    dynamicElementName: string,
    valueSetArray: string[],
    existingElement: GqlClientStudioElementFragment,
    index: number,
    connectorType: ConnectorType
): { dynamicElementData: SerializedDynamicElement; isChanged: boolean } => {
    let isChanged = false;

    const inputValueSet = new Set<string>(valueSetArray);
    const existingValueSetItemsByValue: { [key: string]: { value: string, id: string }} = existingElement.valueSet.reduce((acc, valueSetItem) => {
        if (!inputValueSet.has(valueSetItem.value)) {
            isChanged = true;
        }
        acc[valueSetItem.value] = valueSetItem;
        return acc;
    }, {});

    const mergedValueSet: ValueSetItem[] = valueSetArray.map(value => {
        if (!existingValueSetItemsByValue[value]?.id) {
            isChanged = true;
        }
        return createValueSetItem(value, existingValueSetItemsByValue[value]?.id);
    });

    return {
        isChanged,
        dynamicElementData: buildDynamicElement(dynamicElementName, mergedValueSet, index, connectorType)
    };
};
const classifyElements = (parsedCsv: CsvParseResult, existingDynamicElementsAsStudioElements: GqlClientStudioElementFragment[], connectorType: ConnectorType): ElementClassification => {
    let numOfUpdatedDynamicElementsWithChanges = 0;
    // map existing elements by name
    const existingDynamicElementsByName: { [key: string]: GqlClientStudioElementFragment } =
        convertArrayOfObjectsToObject<GqlClientStudioElementFragment>(existingDynamicElementsAsStudioElements, "name");

    const classifiedDynamicElements = parsedCsv.header.reduce((acc, headerName: string | number | boolean, index: number) => {
        const dynamicElementName: string = (headerName || headerName === 0 || headerName === false) ? String(headerName) : "";
        const valueSet: string[] = (Array.from(parsedCsv.valueSets?.[dynamicElementName] || [])).filter(value => (value || value === 0 || value === false)).map(value => String(value));

        if (acc.removedDynamicElements.has(dynamicElementName)) {
            const existingElement: GqlClientStudioElementFragment = existingDynamicElementsByName[dynamicElementName];
            const { dynamicElementData, isChanged } = mergeElements(dynamicElementName, valueSet, existingElement, index, connectorType);
            acc.updatedDynamicElements.push({
                id: existingElement.localId,
                dynamicElementData
            });
            acc.removedDynamicElements.delete(dynamicElementName);
            if (isChanged) {
                numOfUpdatedDynamicElementsWithChanges++;
            }
        }
        else {
            acc.newDynamicElements.push(buildDynamicElement(dynamicElementName, valueSet.map(value => createValueSetItem(value)), index, connectorType));
        }
        return acc;
    }, {
        newDynamicElements: [],
        updatedDynamicElements: [] as Array<GqlClientUpdatedDynamicElement>,
        removedDynamicElements: new Set<string>(Object.keys(existingDynamicElementsByName))
    });

    return {
        newDynamicElements: classifiedDynamicElements.newDynamicElements,
        updatedDynamicElements: classifiedDynamicElements.updatedDynamicElements,
        removedDynamicElements: Array.from(classifiedDynamicElements.removedDynamicElements).map(elementName => existingDynamicElementsByName[elementName].localId),
        numOfUpdatedDynamicElementsWithChanges
    };
};

const parseLibraryCsv = async (file: File): Promise<CsvParseResult> => {
    const csvValidationError = await getCsvFileValidationError(file);
    if (csvValidationError !== null) {
        throw csvValidationError;
    }

    return await csvParse({
        input: file,
        skipValueSets: false,
        previewRows: 0,
        maxValuesInValueSet,
        removeValueSetIfExceedsValueLimit: false
    });
};

const classifyElementsUpdateOnly = (parsedCsv: CsvParseResult, existingDynamicElementsAsStudioElements: StudioElementExtended[], connectorType: ConnectorType):
    ElementClassificationUpdateOnly => {
    // map existing elements by name
    const existingDynamicElementsByName: { [key: string]: StudioElementExtended } =
        convertArrayOfObjectsToObject<StudioElementExtended>(existingDynamicElementsAsStudioElements, "name");
    const existingElementNames = new Set<string>(Object.keys(existingDynamicElementsByName));

    const dynamicElementNames = parsedCsv.header.filter(headerName => headerName && headerName.trim());
    const elementNamesError = getElementNamesValidationError(dynamicElementNames);
    if (elementNamesError !== null) {
        throw elementNamesError;
    }

    const classifiedDynamicElements = dynamicElementNames.reduce((acc, dynamicElementName, index: number) => {
        const valueSet: string[] = (Array.from(parsedCsv.valueSets?.[dynamicElementName] || [])).filter(value => (value || value === 0 || value === false)).map(value => String(value));

        if (existingElementNames.has(dynamicElementName)) {
            const existingElement: StudioElementExtended = existingDynamicElementsByName[dynamicElementName];
            const { dynamicElementData, isChanged } = updateElementsAddNewValuesOnly(valueSet, existingElement, index);
            if (isChanged) {
                acc.updatedDynamicElements.push({
                    id: existingElement.localId,
                    dynamicElementData
                });
            }
        }
        else {
            acc.newDynamicElements.push(buildDynamicElement(dynamicElementName, valueSet.map(value => createValueSetItem(value)), index, connectorType));
        }
        return acc;
    }, {
        newDynamicElements: [],
        updatedDynamicElements: [] as Array<GqlClientUpdatedDynamicElement>
    });

    return {
        newDynamicElements: classifiedDynamicElements.newDynamicElements,
        updatedDynamicElements: classifiedDynamicElements.updatedDynamicElements
    };
};

const updateElementsAddNewValuesOnly = (
    valueSetArray: string[],
    existingElement: StudioElementExtended,
    index: number
): { dynamicElementData: SerializedDynamicElement; isChanged: boolean } => {

    const existingValueSetItemsByValue: { [key: string]: { value: string, id: string }} = existingElement.valueSet.reduce((acc, valueSetItem) => {
        acc[valueSetItem.value] = valueSetItem;
        return acc;
    }, {});

    // Build a set of all values (existing and new)
    const allValues = valueSetArray.concat(existingElement.valueSet.map(valueSetItem => valueSetItem.value));
    const allValuesSet = new Set<string>(allValues);
    // Create a combined set value, using existing IDs for existing values, and creating IDs for new ones.
    const updatedValues: ValueSetItem[] = Array.from(allValuesSet).map(value => createValueSetItem(value, existingValueSetItemsByValue[value]?.id));
    const isChanged = updatedValues.length !== existingElement.valueSet.length;
    const target = getStringElementTarget(existingElement.name);
    const source = getStringElementSource(existingElement.sourceName);
    return {
        isChanged,
        dynamicElementData: createSerializedDynamicElement(source, target, updatedValues, index, existingElement.pii)
    };
};

export const createUpdateStudioElementsFromCsvFn = (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
) => async (file: File): UploadResponse => {
    try {
        let parsedCsv: CsvParseResult;
        try {
            parsedCsv = await parseLibraryCsv(file);
        }
        catch (e) {
            return { error: e };
        }

        // Read existing library elements.
        const client = getApolloClient();
        const cachedQuery = client.cache.readQuery<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>({
            query: EditorProgramDataConnectorDocument,
            variables: { programId, programVersionId }
        });
        const connectorType = getConnectorTypeFromProgramVersion(cachedQuery?.editorProgram.programVersion);
        const dynamicElements: DataLibraryDynamicElement[] =
            getDataLibraryDataElementsFromProgramVersion(cachedQuery?.editorProgram.programVersion);

        const existingDynamicElementsAsStudioElements: StudioElementExtended[] = dynamicElements.map(de => convertProgramDynamicElementToStudioElement(de, false));

        // Classify which elements are updated and which are new (if any)
        let newDynamicElements: GqlClientCreateOrUpdateAccountDataFieldsInput["newDynamicElements"];
        let updatedDynamicElements: GqlClientCreateOrUpdateAccountDataFieldsInput["updatedDynamicElements"];
        try {
            ({ newDynamicElements, updatedDynamicElements } = classifyElementsUpdateOnly(parsedCsv, existingDynamicElementsAsStudioElements, connectorType));
        }
        catch (e) {
            return { error: e };
        }

        return {
            uploadResult: {
                newDynamicElements,
                updatedDynamicElements,
                accountDataLibraryVersionId: cachedQuery?.editorProgram.programVersion.accountDataLibraryVersion.id
            }
        };
    }
    catch (err) {
        return { error: new EnhancedError(err, SskyErrorCode.StudioElementsCreationErrorParsingCsv) };
    }
};

export const updateLibraryElementsInServer = async (input: UpdateDataElementsInput): Promise<void> => {
    // Only invoke the mutation if there are actual changes.
    const isChanged = input.newDynamicElements?.length > 0 || input.updatedDynamicElements?.length > 0;
    if (isChanged) {
        const variables: GqlClientCreateOrUpdateAccountDataFieldsInput = { ...input, removedDynamicElements: [] };
        const client = getApolloClient();
        await client.mutate<
                GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables
            >({ mutation: CreateOrUpdateAccountDataFieldsDocument, variables: { input: variables } })
            .catch(() => { /* do nothing */ });
    }

};

export const deleteAccountDataField = async (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    localId: string
) => {
    try {
        const client = getApolloClient();
        const cachedQuery = client.cache.readQuery<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>({
            query: EditorProgramDataConnectorDocument,
            variables: { programId, programVersionId }
        });
        const input: GqlClientCreateOrUpdateAccountDataFieldsInput = {
            accountDataLibraryVersionId: cachedQuery?.editorProgram.programVersion.accountDataLibraryVersion.id,
            newDynamicElements: [],
            updatedDynamicElements: [],
            removedDynamicElements: [localId]
        };
        await client.mutate<
            GqlClientCreateOrUpdateAccountDataFieldsMutation, GqlClientCreateOrUpdateAccountDataFieldsMutationVariables
            >({ mutation: CreateOrUpdateAccountDataFieldsDocument, variables: { input } })
            .catch(() => { /* do nothing */ });
    }
    catch (err) {
        handleEditorError({ error: err, sskyCode: SskyErrorCode.UnexpectedClientError });
    }
};

export const updateConnectorTypeAndMapping = async (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    dataConnectorType: ConnectorType,
    extractorConfiguration?: Omit<ExtractorConfiguration, "version" | "type">
) => {
    try {
        const client = getApolloClient();

        const cachedQuery = client.cache.readQuery<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>({
            query: EditorProgramDataConnectorDocument,
            variables: { programId, programVersionId }
        });

        const input: GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingInput = {
            accountDataLibraryVersionId: cachedQuery?.editorProgram.programVersion.accountDataLibraryVersion?.id,
            dataConnectorType: dataConnectorType,
            extractorConfiguration: extractorConfiguration
        };

        await client.mutate<GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutation, GqlClientUpdateAccountDataLibraryConnectorTypeAndMappingMutationVariables>(
            { mutation: UpdateAccountDataLibraryConnectorTypeAndMappingDocument, variables: { input } }
        ).catch(() => { /* do nothing */ });

    }
    catch (err) {
        handleEditorError({ error: err, sskyCode: SskyErrorCode.UnexpectedClientError });
    }
};

export const FOR_TESTING = {
    classifyElements
};

export const getUsedElements = async (programId: string, programVersionId: string): Promise<Record<string, UsedIn[]>> => {

    if (!programId || !programVersionId) return null;

    const { data } = await getApolloClient().query<GqlClientEditorProgramVersionUsedDataFieldsQuery, GqlClientEditorProgramVersionUsedDataFieldsQueryVariables>({
        query: EditorProgramVersionUsedDataFieldsDocument,
        variables: { programId, programVersionId },
        fetchPolicy: "network-only"
    });
    const programVersion = data?.editorProgram?.programVersion;
    return programVersion?.usedElements ?? {};
};
