import { useEditorProgramVersionUsedDataFieldsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { UsedIn } from "../../../../../common/dynamicElementsUtils";
import type { SerializedDynamicElement } from "../UseEditorDynamicElements";
import { getConnectorTypeFromProgramVersion, getDataLibraryDataElementsFromProgramVersion, getSystemElements, getUrlParamNamesOfUsedSourceEntities } from "../../Utils";
import { useMemo } from "react";
import type { DataLibraryDynamicElement, EditorProgramVersionPersonalizationInfo } from "../../types";
import { ConnectorType, DynamicElement, isTrayConnector } from "@sundaysky/customer-data-common-goblin-ds";

type UseEditorProgramVersionUsedDataFields = {
    usedElements: Record<string, UsedIn[]>;
    loading: boolean;
};


export const useEditorProgramVersionUsedDataFields = (programId: string, programVersionId: string, skip: boolean = false): UseEditorProgramVersionUsedDataFields => {
    const { data, loading } = useEditorProgramVersionUsedDataFieldsQuery({
        variables: {
            programId,
            programVersionId
        },
        skip: skip || !programId || !programVersionId
    });

    const programVersion = data?.editorProgram?.programVersion;
    const usedElements: Record<string, UsedIn[]> = programVersion?.usedElements ?? {};

    return { usedElements, loading };
};
export const useEditorProgramVersionPersonalizationInfo = (usedElements: Record<string, UsedIn[]>, programVersion):
    EditorProgramVersionPersonalizationInfo => {
    const dynamicElements: DataLibraryDynamicElement[] =
        getDataLibraryDataElementsFromProgramVersion(programVersion);

    const serializedDynamicElements: SerializedDynamicElement[] = dynamicElements.map(de => {
        return {
            ...de,
            serializedData: DynamicElement.deserialize(de.data),
            origin: "account"
        };
    });

    const allUsedFieldIds = Object.entries(usedElements || {}).filter(([key, usedIn]) => usedIn.filter(item => item.type === "element").length > 0).map(([key, usedIn]) => key);
    const systemElements = getSystemElements();
    const systemElementsIDs = useMemo(() => new Set<string>(systemElements.map(systemElement => systemElement.metadata.localId)), [systemElements]);

    const nonSystemElementUsedFieldIds = allUsedFieldIds.filter(id => !systemElementsIDs.has(id));

    const sourceIds =
        nonSystemElementUsedFieldIds
            .map(elementId => {
                const sde = serializedDynamicElements.find(sde => sde.localId === elementId);
                return sde?.serializedData?.source?.name;
            })
            .filter(id => !!id);

    // In case of tray connector - translate the source
    const dataConnectorType = getConnectorTypeFromProgramVersion(programVersion);
    const paramNames: Array<string> = isTrayConnector(dataConnectorType) ? getUrlParamNamesOfUsedSourceEntities(dataConnectorType, sourceIds) : sourceIds;

    return {
        isPersonalized: allUsedFieldIds.length > 0,
        isPersonalizedWithNonSystemFields: nonSystemElementUsedFieldIds.length > 0,
        usedSourceEntityIdsWithoutSystemFields: paramNames,
        // todo: Zoe/Tali - use the "connectorType" and "isConnectorMissing" fields in other places where we currently recalculate them.
        connectorType: dataConnectorType,
        isConnectorMissing: nonSystemElementUsedFieldIds.length > 0 && (dataConnectorType === ConnectorType.Dummy || !dataConnectorType)
    };

};
