import { CommonPlaceholderType, NarratorLibrary, type NarratorVoiceData } from "../../../../common/types/editorPlaceholder";
import type {
    GqlClientEditorAssetLibraryAvatarFragment,
    GqlClientEditorLibraryAvatarFragment,
    GqlClientEditorLibraryNarratorFragment,
    GqlClientEditorSceneFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const doesSceneHaveAvatar = (scene: GqlClientEditorSceneFragment): boolean => {
    return !!scene.placeholders.find(placeholder => {
        if (placeholder.placeholderContent.placeholderType === CommonPlaceholderType.AVATAR) {
            return true;
        }
    });
};

export const getSceneAvatarPlaceholder = (scene: Pick<GqlClientEditorSceneFragment, "placeholders">) => {
    return scene.placeholders.find(placeholder => placeholder.placeholderContent.placeholderType === CommonPlaceholderType.AVATAR);
};

export const getSceneNarrationPlaceholder = (scene: Pick<GqlClientEditorSceneFragment, "placeholders">) => {
    return scene.placeholders.find(placeholder => placeholder.placeholderContent.placeholderType === CommonPlaceholderType.NARRATION);
};

export const getSceneNarrator = (isAvatarsEnabled: boolean,
    sceneAvatar: GqlClientEditorLibraryAvatarFragment | GqlClientEditorAssetLibraryAvatarFragment,
    alternativeNarratorVoice: NarratorVoiceData,
    projectSelectedNarrator: GqlClientEditorLibraryNarratorFragment,
    libraryAvatars: GqlClientEditorLibraryAvatarFragment[],
    customAvatars: GqlClientEditorAssetLibraryAvatarFragment[]): GqlClientEditorLibraryNarratorFragment | any => {
    if (!isAvatarsEnabled) {
        return projectSelectedNarrator;
    }
    if (sceneAvatar) {
        return sceneAvatar.narrator;
    }
    if (alternativeNarratorVoice) {
        return alternativeNarratorVoice.narratorLibrary === NarratorLibrary.CC_AVATARS
            ? libraryAvatars.find(avatar => avatar.localId === alternativeNarratorVoice.narratorId).narrator
            : customAvatars.find(avatar => avatar.localId === alternativeNarratorVoice.narratorId).narrator;
    }
    return projectSelectedNarrator;
};
