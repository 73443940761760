import { localEditorLivePreviewPendingSetVar } from "../../State";

export const reportPending = (id: string, pending: boolean): void => {
    const pendingSet = new Set(localEditorLivePreviewPendingSetVar());

    if (pending) {
        pendingSet.add(id);
    }
    else {
        pendingSet.delete(id);
    }

    localEditorLivePreviewPendingSetVar(pendingSet);
};
