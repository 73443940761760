import type { ProgramSummary } from "../../../common/types/program";
import { TypesOfProjectSummary } from "../../../common/types/program";
import memoizeOne from "memoize-one";
import { isPermitted, permissions, roles } from "../../../common/rolesPermissionsConst";
import type { HubRouterPathParams } from "../../logic/common/routingUtils";
import { getEditorPaths, getFrameworkPaths, studioBasePath, studioPath, studioStageBasePath, studioVersionBasePath } from "../../logic/common/routingUtils";

import type { match } from "react-router-dom";
import { matchPath } from "react-router-dom";
import { legacyRootPath, newNavEditorPath, StudioMainCategoriesPaths } from "@sundaysky/smartvideo-hub-urls";
import { isNewNav } from "../../utils/newNavUtils";
export enum DrawerFilters {
    AllButArchive = "notArchived",
    Archive = "archived"
}

export type builderProgramItem = {
    key: string;
    value: string;
    text: string;
};

export const findProgramSummaryForProgramId = memoizeOne(
    (programSummaries: ProgramSummary[], programId: string): ProgramSummary => {
        return programSummaries.find((program) => {
            const { id, projectIds: { builders, canvas, dashboards } } = program;
            return [id, builders, canvas, dashboards].includes(programId);
        });
    }
);

export const findProgramSummaryIndexForProgramId = memoizeOne((programSummaries: ProgramSummary[], programId: string): number => {
    return programSummaries.findIndex((program) => {
        const { id, projectIds: { builders, canvas, dashboards } } = program;
        return [id, builders, canvas, dashboards].includes(programId);
    });
});

export const getBuildersProgramsSummariesWithoutCanvas = memoizeOne((programSummaries: ProgramSummary[]): ProgramSummary[] => programSummaries.filter((program) => program.projectIds.builders));

export const getBuildersProgramsToCombine = memoizeOne((programSummaries: ProgramSummary[]): builderProgramItem[] => {
    let buildersProgramsSummaries = getBuildersProgramsSummariesWithoutCanvas(programSummaries);

    let buildersPrograms = buildersProgramsSummaries.reduce((acc: builderProgramItem[], p: ProgramSummary) => {
        acc.push({
            key: p.projectIds.builders,
            value: p.projectIds.builders,
            text: p.displayName
        });

        return acc;
    }, []);

    return [{ key: "none", value: "", text: "None" }].concat(buildersPrograms);
});

export const shouldShowAddBuilderProgramButton = memoizeOne((userRoles: string[]): boolean => {
    return userRoles.includes(roles.admin) || userRoles.includes(roles.builderEditor);
});

export const shouldShowAddFrameWorkProgramButton = memoizeOne((userRoles: string[]): boolean => {
    return userRoles.includes(roles.admin) || userRoles.includes(roles.frameworkEditor);
});

export const shouldShowAddEditorProgramButton = memoizeOne((userRoles: string[]): boolean => {
    return isPermitted(userRoles, permissions.createEditorPrograms);
});

export const shouldShowAddProgramButton = memoizeOne((userRoles: string[]): boolean => {
    return shouldShowAddBuilderProgramButton(userRoles) || shouldShowAddFrameWorkProgramButton(userRoles) || shouldShowAddEditorProgramButton(userRoles);
});

export const getDashboardsProgramIds = memoizeOne((programSummaries: ProgramSummary[], drawerFilter?: DrawerFilters): Set<string> => {
    let ids = new Set<string>();
    programSummaries.forEach((programSummary) => {
        if (isProgramBelongToFilter(programSummary, drawerFilter)
            && !!programSummary.projectIds[TypesOfProjectSummary.dashboards]) {
            ids.add(programSummary.projectIds.sfId.toLowerCase());
        }
    });
    return ids;
});

export const isProgramBelongToFilter = (programSummary: ProgramSummary, drawerFilter?: DrawerFilters) : boolean => {
    return !drawerFilter ||
        drawerFilter === DrawerFilters.AllButArchive && !programSummary.isArchive ||
        drawerFilter === DrawerFilters.Archive && programSummary.isArchive;
};

export const getNumOfDashboardsPrograms = memoizeOne((programSummaries: ProgramSummary[]): number => {
    return getNumOfProgramsByType(programSummaries, TypesOfProjectSummary.dashboards);
});

export const getNumOfBuilderPrograms = memoizeOne((programSummaries: ProgramSummary[]): number => {
    return getNumOfProgramsByType(programSummaries, TypesOfProjectSummary.builders);
});

export const getNumOfCanvasPrograms = memoizeOne((programSummaries: ProgramSummary[]): number => {
    return getNumOfProgramsByType(programSummaries, TypesOfProjectSummary.canvas);
});

export const getNumOfProgramsByType = (programSummaries: ProgramSummary[], type: TypesOfProjectSummary): number => {
    return programSummaries.filter((program) => !!program.projectIds[type]).length;
};

export const getNavigationInfoFromPathname = (pathname: string): Pick<HubRouterPathParams, "programName" | "accountId" | "navigationItem" | "navigationItemType"> => {
    const match = matchPath(pathname, {
        path: studioBasePath,
        exact: false,
        strict: false
    });
    const params: HubRouterPathParams = match && match.params;
    return params && {
        accountId: params.accountId,
        programName: params.programName,
        navigationItem: params.navigationItem,
        navigationItemType: params.navigationItemType
    };
};

export const getStageFromPathname = (pathname: string) => {
    const match = matchPath(pathname, {
        path: studioStageBasePath,
        exact: false,
        strict: false
    });
    const params: HubRouterPathParams = match && match.params;
    return params && params.stage;
};

const builderVersionPaths = [
    legacyRootPath.slash("account/:accountId/program/:programName/:navigationItem/:navigationItemType/version/:version"),
    legacyRootPath.slash("account/:accountId/program/:programName/:navigationItem/version/:version")
];
export const getVersionFromPathname = (pathname: string) => {
    for (const path of builderVersionPaths) {
        const match = matchPath<HubRouterPathParams>(pathname, {
            path: path.pattern,
            exact: false,
            strict: false
        });
        if (match) {
            return match.params.version;
        }
    }
    return null;
};

export const getProgramNameFromPathname = (pathname: string, template: string = studioPath) => {
    const match = matchPath(pathname, {
        path: template,
        exact: false,
        strict: false
    });
    const params: HubRouterPathParams = match && match.params;
    return params && params.programName;
};

export const getAccountIdFromPathname = (pathname: string, template: string = studioPath) => {
    const match = matchPath(pathname, {
        path: template,
        exact: false,
        strict: false
    });
    const params: HubRouterPathParams = match && match.params;
    return params && params.accountId;
};

export const isFrameworkRoute = (pathname: string): boolean => {
    const match: match<{ accountId: string; programName: string; navigationItem: string }> = matchPath(pathname, { path: getFrameworkPaths()[0], exact: false, strict: false });
    return match && match.params && match.params.navigationItem === StudioMainCategoriesPaths.Framework;
};

export const isEditorRoute = (pathname: string): boolean => {
    if (isNewNav) {
        return !!matchPath(
            pathname,
            {
                path: newNavEditorPath.pattern,
                exact: false,
                strict: false
            }
        );
    }
    else {
        return matchPath<{
            accountId: string;
            programName: string;
            navigationItem: string
        }>(
            pathname,
            {
                path: getEditorPaths(),
                exact: false,
                strict: false
            }
        )?.params?.navigationItem === StudioMainCategoriesPaths.Editor;
    }
};
