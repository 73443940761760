import { v4 as uuid } from "uuid";
import type { VideoSpecResponse } from "../../../../common/external/videoSpecResponse";
import { Status } from "../../../../common/external/videoSpecResponse";
import type { StatusResponse } from "../../../logic/common/downloadUtils";
import DownloadUtils from "../../../logic/common/downloadUtils";
import type PreviewServices from "../../../logic/preview/PreviewServices";
import { getService } from "../../services";

const downloadUtils = new DownloadUtils(undefined, undefined);

const getVideoStatus = async (previewResponse: VideoSpecResponse, videoFileName: string, resolveFn: (value?: any) => void, rejectFn: (value?: any) => void, downloadFunction): Promise<void> => {
    const previewServices = getService<PreviewServices>("previewServices");
    const selfUrl = `${previewResponse._links.self.href}?internal=true`;
    const videoSpecResponse: VideoSpecResponse = await previewServices.getStatus(selfUrl);
    await downloadFunction(videoSpecResponse, videoFileName, resolveFn, rejectFn);
};

const handleDownloadVideoFromResponse = async (videoSpecResponse: VideoSpecResponse, videoFileName: string, 
    resolveFn: (value?: any) => void, rejectFn: (value?: any) => void): Promise<void> => {
    switch (videoSpecResponse.status) {
        case Status.Finished: {
            const videoStream = videoSpecResponse._links["video-stream"];
            const downloadVideoResponse: StatusResponse = await downloadUtils.downloadVideoFile(videoStream.href, videoFileName);
            if (downloadVideoResponse !== "finished") {
                rejectFn("Could not download video");
            }
            resolveFn();
            break;
        }
        case Status.Error: {
            rejectFn("Could not render video");
            break;
        }
        default: {
            setTimeout(getVideoStatus, 1000, videoSpecResponse, videoFileName, resolveFn, rejectFn, handleDownloadVideoFromResponse);
            break;
        }
    }
};

const handleDownloadVideoAndCaptionsFromResponse = async (videoSpecResponse: VideoSpecResponse, videoFileName: string,
    resolveFn: (value?: any) => void, rejectFn: (value?: any) => void): Promise<void> => {
    switch (videoSpecResponse.status) {
        case Status.Finished: {
            try {
                const linksToDownload = [
                    { url: videoSpecResponse._links["video-stream"], type: "application/octet-stream" },
                    { url: videoSpecResponse._links.captions, type: "text/vtt" }
                ];
                const files = await Promise.all(linksToDownload.map(link => downloadUtils.getFileFromUrl(link.url.href, link.type)));
                const filesToZip = [];
                files.forEach((file, index) => {
                    const url = new URL(linksToDownload[index].url.href);
                    const fileExtension = url.pathname.substring(url.pathname.lastIndexOf("."));
                    filesToZip.push({
                        fileContent: file,
                        fileName: videoFileName.replace(".mp4", fileExtension)
                    });
                });
                await downloadUtils.createAndDownloadZipFile(filesToZip, videoFileName.replace(".mp4", ""));
                resolveFn();
                break;
            }
            catch (e) {
                rejectFn("Could not download video with captions. " + e);
                break;
            }
        }
        case Status.Error: {
            rejectFn("Could not render video");
            break;
        }
        default: {
            setTimeout(getVideoStatus, 1000, videoSpecResponse, videoFileName, resolveFn, rejectFn, handleDownloadVideoAndCaptionsFromResponse);
            break;
        }
    }
};

export async function downloadVideo(renderVideoFunc: (requestId: string) => Promise<VideoSpecResponse>, videoName: string, withCaptions: boolean = false): Promise<void> {
    const requestId = uuid();
    const videoSpecResponse: VideoSpecResponse = await renderVideoFunc(requestId);
    return new Promise((resolve, reject) => {
        withCaptions ? handleDownloadVideoAndCaptionsFromResponse(videoSpecResponse, videoName, resolve, reject)
            : handleDownloadVideoFromResponse(videoSpecResponse, videoName, resolve, reject);
    });
}
