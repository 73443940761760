import { useEffect } from "react";
import type { PendoAccountInfo } from "../../../pendoService";
import { PendoService } from "../../../pendoService";

/**
 * usePendo
 * @param  pendoAccountInfo required account info for updating pendo service
 * @param  enable whether to track using pendo or not, temporary feature flag
 */
export const usePendo = (pendoAccountInfo: PendoAccountInfo, enable = true) => {
    useEffect(() => {
        const pendo = PendoService.getInstance();
        if (enable) {
            pendo.startTracking();
            pendo.updateAccountInfoIfChanged(pendoAccountInfo);
        }
        return () => enable && pendo.stopTracking();
    }, []);
};
