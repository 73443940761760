import { assetLibraryFontsManagerVar, cCFontsManagerVar, localEditedBrandTextStylesVar } from "../../State";
import { findEntityById, getTextStyleFont } from "../../Utils";
import type { LocalStateTextStyle } from "../../types";
import type { GqlClientEditorBrandTextStyleFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEditorAssetSource } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const setEditedBrandTextStyle = (textStyleId: string, textStyleUpdatedValues: LocalStateTextStyle): void => {
    const textStyles: LocalStateTextStyle[] = localEditedBrandTextStylesVar();
    const textStyleToUpdate: LocalStateTextStyle = findEntityById<LocalStateTextStyle>(textStyles, textStyleId);

    if (textStyleToUpdate !== undefined) {
        Object.assign(textStyleToUpdate, textStyleUpdatedValues);
    }
    else {
        const newTextStyleItem: LocalStateTextStyle = {
            id: textStyleId,
            ...textStyleUpdatedValues
        };

        textStyles.push(newTextStyleItem);
    }
    localEditedBrandTextStylesVar([...textStyles]);
};

export const resetTextStylesLocalVar = () => {
    localEditedBrandTextStylesVar([]);
};

export const isTextStyleFontFullySupportStyling = (textStyle: GqlClientEditorBrandTextStyleFragment): boolean => {
    const font = getTextStyleFontForStyling(textStyle);
    return font.fullySupportsStyling;
};

export const someTextStyleFontNotFullySupportStyling = (textStyles: GqlClientEditorBrandTextStyleFragment[]): boolean => {
    return textStyles.some((textStyle) => !isTextStyleFontFullySupportStyling(textStyle));
};

export const getTextStyleFontForStyling = (textStyle: GqlClientEditorBrandTextStyleFragment) => {
    const ccFontManager = cCFontsManagerVar();
    const accountFontManager = assetLibraryFontsManagerVar();
    const fontManager = textStyle.fontSource === GqlClientEditorAssetSource.CC ? ccFontManager : accountFontManager;
    const { id: fontId } = getTextStyleFont(textStyle);
    const font = fontManager.getFont(fontId);
    return {
        ...font,
        fontVariations: fontManager.getFontVariationsForFormatting(fontId),
        fullySupportsStyling: fontManager.isFontFullySupportStyling(fontId),
        partiallySupportsStylingOnly: fontManager.isFontPartiallySupportStylingOnly(fontId),
        doNotSupportsStyling: fontManager.isFontDoNotSupportStyling(fontId),
        supportsBold: fontManager.doesFontSupportBold(fontId),
        supportsItalic: fontManager.doesFontSupportItalic(fontId),
        supportsBoldItalic: fontManager.doesFontSupportBoldItalic(fontId)
    };
};
