import { bulkUploadFilesLength } from "../../../../../common/commonConst";
import { SskyErrorCode } from "../../../../../common/errors";
import { isNewNav } from "../../../../utils/newNavUtils";
import { SPECIAL_CHAR_VALIDATION } from "../../../../../common/dynamicElementsUtils";

export const NotificationInfoText = {
    NoInternetConnection: "Looks like you're offline. Editing is disabled to prevent any issues. Check your connection and",
    InternetConnectionNotStable: "Looks like you're offline. Some actions might not work.",
    InternetConnectionRestored: "Your internet connection was restored.",
    UploadingAsset: "Uploading...",
    Downloading: "Downloading...",
    PreparingDownload: "Preparing download...",
    SharingVideo: "Sharing Video...",
    SendingRecordingRequest: "Sending recording request...",
    BrandUploadInProgress: "Upload is still in process, brand couldn't be saved. Try again when upload is completed",
    CopyProgramInProgress: "Copying program...",
    CreateStudioElementsUploadCsv: "Uploading CSV file...",
    AssetDeleted: "1 file deleted",
    PreviewVideoPartialNarrations: "Couldn't play all voiceover files. Try again or",
    GenerateVideoLinks: "Generating video links...",
    GetVideoLinks: "Creating a file of unique links",
    ImportingData: "Importing data...",
    MightTakeAWhile: "This might take a while...",
    VideoArchived: (name: string): `${string} was Archived` => `${name} was Archived`,
    TruffleVideoArchived: "Video moved to Archive",
    VideoRestoredFromArchive: (name: string): `${string} was Restored` => `${name} was Restored`,
    TruffleVideoRestoredFromArchive: "Video restored",
    DownloadingScript: "Downloading script",
    ExportingScript: "Exporting script ...",
    ReviewInvitationSent: (pluralSuffix: string): `${string}` => `Invitation${pluralSuffix} sent`,
    RemoveAllExternalUsersProcessing: "Processing your request to remove all external users...",
    DefaultAssetNotEditable: "Default asset can't be edited",
    VideoDuplicated: "Video duplicated",
    FolderCreated: "Folder created",
    noVersionNotificationText: "To share this video, a version must be approved first",
    restoreVideoToShare: "To share this video, restore it from the archive first",
    viewOnlyCantShare: "Only users with Editor permissions can share this video",
    duringApprovalCantShare: "Once this version is approved, the video can be shared",
    PublishButton: "No changes to be approved",
    RecordingStopped: "Something stopped the recording",
    ActionBlockedWhileRenderingAvatar: "This action is unavailable while Avatars are being animated."
} as const;

const CreateStudioElementsUploadCsvSuccess = (created: number, updated: number, removed: number): `Upload completed successfully. ${string}` => {

    const getMessage = (action: string, amount: number, isFirst: boolean): string => {
        return isFirst ? `${amount} data element${amount > 1 ? "s" : ""} ${action}.` : `${amount} ${action}.`;
    };

    let isFirstMessage = true;
    let noChangesWereMade = true;
    let messages: string[] = ["Upload completed successfully."];

    if (created > 0) {
        messages.push(getMessage("created", created, isFirstMessage));
        isFirstMessage = false;
        noChangesWereMade = false;
    }
    if (updated > 0) {
        messages.push(getMessage("updated", updated, isFirstMessage));
        isFirstMessage = false;
        noChangesWereMade = false;
    }
    if (removed > 0) {
        messages.push(getMessage("deleted", removed, isFirstMessage));
        noChangesWereMade = false;
    }

    if (noChangesWereMade) {
        messages.push("No changes were made.");
    }

    return messages.join(" ") as `Upload completed successfully. ${string}`;
};

const UpdateStudioElementsUploadCsvSuccess = (created: number, updated: number): string => {
    const getMessage = (action: string, amount: number): string => {
        return `${amount} data field${amount > 1 ? "s" : ""} ${action}`;
    };

    if (created > 0 && updated > 0) {
        return `${getMessage("created", created)} and ${updated} updated`;
    }

    if (created > 0) {
        return `${getMessage("created", created)} successfully`;
    }

    if (updated > 0) {
        return `${getMessage("updated", updated)} successfully`;
    }

    return "No changes were made";
};

export const NotificationSuccessText = {
    PublishCompleted: "Published successfully",
    ApproveCompleted: "Approved successfully",
    VideoApproved: "Video Approved",
    DownloadCompleted: "Download completed",
    RecordingRequestSent: "Recording request submitted successfully. Your SundaySky team will give you status updates.",
    CopyProgramSuccess: isNewNav ? "Video copied" : "Program copied",
    SaveViewerProfileSuccess: "Viewer profile saved",
    CreateViewerProfileSuccess: "Viewer profile created",
    ShareVideoCompleted: "Shared",
    PostUrlCopied: "Post URL copied!",
    LandingPageCopyLinkSuccess: "Link copied",
    LandingPagePreviewUrlSuccess: "Preview generated successfully and copied to clipboard. The preview URL will be valid for 1 week",
    CreateStudioElementsUploadCsvSuccess,
    UpdateStudioElementsUploadCsvSuccess,
    DataFieldAddedSuccess: "Data field added successfully",
    DataFieldEditedSuccess: "Data field edited successfully",
    DataFieldDeletedSuccess: "Data field deleted successfully",
    DataLibraryDetachedSuccess: "Data Library removed",
    LogicAndDataLibraryRemovedSuccess: "Data Library removed, personalization deleted",
    DataLibraryDeletedSuccess: "Data Library deleted",
    DataLibraryCreatedSuccess: (libraryName: string): `${string}` => `Data Library "${libraryName}" created`,
    ConnectorAuthenticationSuccess: (connectorName: string): `${string}` => `The ${connectorName} data connector has been authenticated`,
    RemoveAllExternalUsersFinished: "All external users were successfully removed",
    CodeCopied: "Code copied",
    LinkCopied: "Link copied",
    BriefCopied: "Brief copied to clipboard",
    BriefSaved: "Brief saved",
    BriefDeleted: "Brief deleted",
    EditorVideoDeleted: "The video has been deleted",
    MediaAssetSaved: "Asset saved",
    AccountProgramFolderDeleted: "Folder deleted",
    LayoutsUpgradeSuccess: "Video upgraded to the Optimized Scene Library",
    CustomAvatarReady: "Your Custom Avatar is ready",
    ImportDataSuccess: (numSuccessfulUsers: number, numFailedUsers: number) => numFailedUsers > 0
        ? `${numSuccessfulUsers} Data Fields imported, ${numFailedUsers} failed`
        : `${numSuccessfulUsers} Data Fields imported`,
    VideoDeleted: "Video deleted",
    MailSentConfirmation: "A SundaySky rep will be in touch!"
} as const;

export const NotificationErrorText = {
    UnexpectedError: "Something went wrong. Try again or",

    [SskyErrorCode.ImageAssetSizeTooBig]: "Image files cannot be larger than 10MB",

    [SskyErrorCode.VideoAssetSizeTooBig]: "Video files cannot be larger than 100MB",

    [SskyErrorCode.AudioAssetSizeTooBig]: "Audio files cannot be larger than 25MB",

    [SskyErrorCode.UnsupportedAssetTypeImageAndVideo]: "File must be one of the following formats: jpg, png, gif, mov, flv, mp4, webm",

    [SskyErrorCode.UnsupportedAssetTypeMusic]: "File must be mp3 format",

    [SskyErrorCode.RecordingRequestFailed]: "Whoops! Your request for recording didn't go through! Try again or contact your SundaySky team directly.",

    [SskyErrorCode.OutdatedEditorVersion]: "A new version is available. To start using it",

    [SskyErrorCode.PreviewError]: "Preview couldn't be displayed. Try again or",

    [SskyErrorCode.DownloadVideoError]: "File couldn't be downloaded. Try again or",

    [SskyErrorCode.UnsupportedAssetFontType]: "Font couldn't be uploaded. Font must be one of the following formats: OTF, TTF",

    [SskyErrorCode.AssetFontUploadError]: "Font couldn't be uploaded. Try again",

    [SskyErrorCode.CopyProgramFailed]: "Program couldn't be copied",

    [SskyErrorCode.CopyProgramBetweenAccountsFailed]: "Program couldn't be copied to a different account",

    [SskyErrorCode.ViewerProfileSaveError]: "Viewer profile couldn't be saved. Try again or",

    [SskyErrorCode.ViewerProfileCreateError]: "Viewer profile couldn't be created. Try again or",

    [SskyErrorCode.ViewerProfileDeleteError]: "Viewer profile couldn't be deleted. Try again or",

    [SskyErrorCode.CsvUploadUnsupportedFile]: "This file could not be uploaded. File format must be CSV.",

    [SskyErrorCode.CsvUploadFileNotUtf8]: "This file could not be uploaded because some of the content is not encoded in UTF-8.",

    [SskyErrorCode.CsvUploadFileTooBig]: "File couldn't be uploaded. File cannot be larger than 100MB.",

    [SskyErrorCode.CsvUploadFileEmpty]: "This file couldn't be uploaded because it is empty.",

    [SskyErrorCode.CsvUploadDuplicateHeaders]: "This file could not be uploaded. Column headings must be unique.",

    [SskyErrorCode.CsvUploadIllegalCharacterInHeaders]: `This file could not be uploaded, one or more of the fields contain illegal characters. ${SPECIAL_CHAR_VALIDATION}`,

    [SskyErrorCode.StudioElementsCreationErrorParsingCsv]: "CSV couldn't be uploaded. Try again or",

    [SskyErrorCode.EditorProgramGenerateVideoLinksFailed]: "Links couldn't be generated.",

    [SskyErrorCode.BulkUploadAssetsTooMany]: `Upload cannot exceed ${bulkUploadFilesLength} files at a time`,

    [SskyErrorCode.GettyImagesGeneralError]: "Error getting stock media. Try again later or",

    [SskyErrorCode.ShareVideoToSocialError]: "There has been an error sharing your video. Try again or",

    [SskyErrorCode.LandingPagePreviewError]: "There has been an error previewing this landing page",

    [SskyErrorCode.LandingPageSaveError]: "There has been an error saving this landing page",

    [SskyErrorCode.LandingPageActivationError]: "There has been an error activating this landing page",

    [SskyErrorCode.LandingPageDeactivationError]: "There has been an error deactivating this landing page",

    [SskyErrorCode.PublishToChannelsError]: "Your program couldn't be published to your channel. please try again later",

    [SskyErrorCode.LandingPageDeletionError]: "There has been an error deleting this landing page",

    [SskyErrorCode.PromptToVideoError]: "There has been an error creating the video",

    [SskyErrorCode.DuplicateProgramWithRemovedWireframeError]: "This video uses discontinued scene templates. Please switch to current templates before duplicating the video",

    [SskyErrorCode.DataImportFailed]: "Data import failed, please verify the file contents",

    [SskyErrorCode.AiImageGenerationFailedGeneral]: "AI Image Generation failed - please contact support or try again later",

    [SskyErrorCode.AiImageGenerationFailedBadPrompt]: "AI Image Generation failed - your prompt is not suitable for this service",

    [SskyErrorCode.DeprecatedProviderError]: "Preview video failed - AI voice is expired",

    [SskyErrorCode.OpeningImglyFailed]: "Media Editor could not be opened. Please contact support or try again later"

} as const;

type KeyValuesOrReturnValuesTypes<T> = Extract<T[keyof T], Function> extends never ?
    T[keyof T] :
    Exclude<T[keyof T], (...args: any) => any>
    | ReturnType<Extract<T[keyof T], (...args: any) => any>>;

export type TNotificationInfoText =
    KeyValuesOrReturnValuesTypes<typeof NotificationInfoText>;

export type TNotificationSuccessText = KeyValuesOrReturnValuesTypes<typeof NotificationSuccessText>;

export type TNotificationErrorText = KeyValuesOrReturnValuesTypes<typeof NotificationErrorText>;

export type NotificationText = TNotificationInfoText | TNotificationSuccessText | TNotificationErrorText;
