import { useReactiveVar } from "@apollo/client";
import { localEditorSelectedAudioPreview } from "../../State";
import type { StandardSetterFn } from "../../types";
import { setSelectedAudioPreview } from "../../Nooks";
import type ReactAudioPlayer from "react-audio-player";

export type UseSelectedAudioPreview = {
    selectedAudioPreview: ReactAudioPlayer;
    setSelectedAudioPreview: StandardSetterFn<ReactAudioPlayer>;
};

export const useSelectedAudioPreview = (): UseSelectedAudioPreview => {
    const selectedAudioPreview = useReactiveVar(localEditorSelectedAudioPreview);

    return { selectedAudioPreview, setSelectedAudioPreview: setSelectedAudioPreview };
};