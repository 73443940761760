import React from "react";
import { SskyFab, SskyTooltip } from "@sundaysky/ui-component-library/Components";
import type { placement } from "@sundaysky/ui-component-library/Components/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";

interface HubAddActionButtonProps {
    onClick: (event) => void;
    tooltip?: string;
    dataTestId?: string;
    dataTrackingId?: string;
    tooltipPlacement?: placement;
}

const StyledSskyFab = styled(SskyFab)`
    z-index: 1;
`;

const HubAddActionButton = (props: HubAddActionButtonProps) => {
    const placement = props.tooltipPlacement || "bottom";

    let btn = <StyledSskyFab
        className="add-action-btn"
        dataTestId={props.dataTestId}
        onClick={props.onClick}
        icon={<FontAwesomeIcon icon={["far", "plus"]} style={{ fontSize: "18px" }} />}
    />;

    if (props.tooltip) {
        return (
            <SskyTooltip title={props.tooltip} placement={placement}>
                <span data-tracking-id={props.dataTrackingId}>{btn}</span>
            </SskyTooltip>
        );
    }
    else {
        return btn;
    }
};

export default HubAddActionButton;
