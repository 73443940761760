import React from "react";
import type { MouseEvent } from "react";
import Dropzone from "react-dropzone";
import HubMappingTableData from "./HubMappingTableData";
import HubContentEditable from "../../legacyCommon/HubContentEditable";
import HubNotifications from "../../../logic/common/hubNotifications";
import MappingTablesUtils from "../../../logic/MappingTables/MappingTablesUtils";
import { HubSpinner } from "../../legacyCommon/HubSpinner";
import { Button, Icon } from "semantic-ui-react";
import * as csv from "csv";
import "./HubMappingTable.css";
import { v4 as uuid } from "uuid";
import { findDataElementByLogicValue, getDataElementDisplayName, getDataElementId } from "../../../logic/DataElements/DataElementsManager";
import { SskyDialog } from "@sundaysky/ui-component-library/Components";
import { DATA_TABLE_OUTPUT_TYPES } from "../../../logic/vlx/consts";
import type { Asset, AssetTypes } from "../../../../common/types/asset";
import type { DataElement } from "../../../../common/types/dataElement";
import type { ActionableData } from "../../../../common/types/logic";
import type { DataTable, DataTableBody } from "../../../../common/types/dataTable";

export const CELL = {
    String: {
        cellType: DATA_TABLE_OUTPUT_TYPES.String,
        title: "String",
        cellKey: "text",
        defaultValue: () => "",
        char: "S"
    },
    URL: {
        cellType: DATA_TABLE_OUTPUT_TYPES.Url,
        title: "URL",
        cellKey: "text",
        defaultValue: () => "",
        char: "U"
    },
    Media: {
        cellType: DATA_TABLE_OUTPUT_TYPES.Media,
        title: "Media",
        cellKey: "value",
        defaultValue: () => ({ name: "" }),
        char: "M"
    },
    Color: {
        cellType: DATA_TABLE_OUTPUT_TYPES.Color,
        title: "Color",
        cellKey: "color",
        defaultValue: () => null,
        char: "C"
    }
};

export interface HubMappingTableProps {
    handleSaveChanges: (title: string, data: DataTableBody) => Promise<void>,
    handleCancelChanges: () => any,
    downloadUrl: (url: string, cb: any) => any,
    updateAsset: (accountId: string, projectName: string, type: AssetTypes.mappingTable, assetName: string, assetUpdates: Partial<DataTable>, cb: any) => any,
    assets: Asset[],
    dataElements: DataElement[],
    projectName: string,
    accountId: string,
    handleClose: () => void,
    mappingTableElement: DataTable,
    isViewOnly: boolean,
    isStageView: boolean
}

export interface HubMappingTableState {
    inputHeaders: any[],
    outputHeaders: any[],
    rows: any[],
    isFocused: boolean,
    showUploadCsv: boolean,
    showSpinner: boolean,
    isChanged: boolean,
    showDialog: boolean,
    showOverrideDialog: boolean
    title: string
}

class HubMappingTable extends React.Component<HubMappingTableProps, HubMappingTableState> {
    csvRows = [];

    static initCell = () => {
        const defaultCell = {
            [CELL.String.cellKey]: CELL.String.defaultValue(),
            [CELL.Media.cellKey]: CELL.Media.defaultValue(),
            [CELL.URL.cellKey]: CELL.URL.defaultValue()
        };
        return { ...defaultCell, [CELL.Color.cellKey]: CELL.Color.defaultValue() };
    };

    constructor(props) {
        super(props);

        let inputHeaders = [
            {
                value: { name: "Select Data" }
            }
        ];

        let outputHeaders = [
            {
                text: "",
                id: "",
                type: { name: CELL.String.char, title: CELL.String.title }
            }
        ];

        let rows = [
            {
                cells: [HubMappingTable.initCell(), HubMappingTable.initCell()]
            }
        ];

        this.state = {
            inputHeaders,
            outputHeaders,
            rows,
            isFocused: true,
            showUploadCsv: true,
            showSpinner: false,
            isChanged: false,
            showDialog: false,
            showOverrideDialog: false,
            title: undefined
        };
    }

    componentDidMount() {
        if (this.props.mappingTableElement) {
            this.buildTableData(this.props.mappingTableElement);
        }
    }

    onInputHeaderChange = (dataElementAD: ActionableData) => {
        if (this.state.showUploadCsv) {
            this.closeCsvUploadIcon();
        }

        let { inputHeaders } = this.state;
        const dataElement = findDataElementByLogicValue(dataElementAD, this.props.dataElements);
        inputHeaders[0].value = dataElement || { name: "Select Data" };
        inputHeaders[0].missing = false;

        this.setState({ inputHeaders, isChanged: true });
    };

    onOutputHeaderChange = (value, e) => {
        if (this.state.showUploadCsv) {
            this.closeCsvUploadIcon();
        }

        let { outputHeaders } = this.state;
        let headerIndex = e.target.id;

        outputHeaders[headerIndex].text = value.trim();

        this.setState({ outputHeaders, isChanged: true });
    };

    onOutputTypeChange = (type) => {
        let { outputHeaders, inputHeaders } = this.state;
        let prevType = outputHeaders[type.id].type;
        outputHeaders[type.id].type = type;

        if (prevType && (prevType.title === CELL.String.title || prevType.title === CELL.URL.title) && type.title === CELL.Media.title) {
            this.validateAssets(parseInt(type.id) + inputHeaders.length, true);
        }

        this.setState({ outputHeaders, isChanged: true });
    };

    onOutputColumnAdd = (index) => {
        if (this.state.showUploadCsv) {
            this.closeCsvUploadIcon();
        }

        let { outputHeaders, inputHeaders, rows } = this.state;

        outputHeaders.splice(index, 0, { text: "", id: uuid() });
        rows.forEach((row) => {
            row.cells.splice(index + inputHeaders.length, 0, HubMappingTable.initCell());
        });

        this.setState({ outputHeaders, rows, isChanged: true });
    };

    onOutputColumnRemove = (index) => {
        let { outputHeaders, inputHeaders, rows } = this.state;

        outputHeaders.splice(index, 1);
        rows.forEach((row) => {
            row.cells.splice(index + inputHeaders.length, 1);
        });

        this.setState({ outputHeaders, rows, isChanged: true });
    };

    onOutputColumnClear = (index) => {
        let { outputHeaders, inputHeaders, rows } = this.state;

        outputHeaders[index] = { text: "" };
        rows.forEach((row) => {
            row.cells[index + inputHeaders.length] = HubMappingTable.initCell();
        });

        this.setState({ outputHeaders, rows, isChanged: true });
    };

    onCellChanged = (value: any, cellIndex, rowIndex, type) => {
        if (this.state.showUploadCsv) {
            this.closeCsvUploadIcon();
        }

        let { rows } = this.state;

        let cell = rows[rowIndex].cells[cellIndex];
        if (type && type === CELL.Media.cellType) {
            cell.value = value || { name: "" };
            cell.text = "";
            cell.missing = false;
        }
        else if (type && type === CELL.Color.cellType) {
            cell.color = value;
        }
        else {
            // applicable for CELL.URL && CELL.String
            cell.text = value;
            cell.value = { name: "" };
        }

        this.setState({ rows, isChanged: true });
    };

    onRowAdd = (index) => {
        if (this.state.showUploadCsv) {
            this.closeCsvUploadIcon();
        }

        let { rows } = this.state;
        let cellsCount = this.state.inputHeaders.length + this.state.outputHeaders.length;
        let cells = [];

        for (let i = 0; i < cellsCount; i++) {
            cells.push(HubMappingTable.initCell());
        }
        rows.splice(index, 0, { cells });

        this.setState({ rows, isChanged: true });
    };

    onRowRemove = (index) => {
        let { rows } = this.state;

        rows.splice(index, 1);

        this.setState({ rows, isChanged: true });
    };

    onRowClear = (index) => {
        let { rows } = this.state;

        rows[index].cells = rows[index].cells.map((cell, index) => {
            return HubMappingTable.initCell();
        });

        this.setState({ rows, isChanged: true });
    };

    onSave = (e: MouseEvent, doneCB?: () => void) => {
        this.setState({ showSpinner: true });

        const { inputHeaders, outputHeaders, rows } = this.state;

        let mappingTableData: DataTableBody = {
            scheme: {
                inputs: [],
                outputs: []
            },
            data: []
        };

        inputHeaders.forEach((header) => {
            mappingTableData.scheme.inputs.push({
                id: getDataElementId(header.value),
                displayName: getDataElementDisplayName(header.value),
                name: getDataElementDisplayName(header.value),
                type: header.value.type
            });
        });

        outputHeaders.forEach((header) => {
            header.id = header.id ? header.id : uuid();
            mappingTableData.scheme.outputs.push({ name: header.text, id: header.id, type: MappingTablesUtils.getTypeFromTitle(header.type.title) });
        });

        rows.forEach((row) => {
            let property = "";
            let rowData = {};

            row.cells.forEach((cell) => {
                const cellIndex = row.cells.indexOf(cell);

                if (cellIndex > inputHeaders.length - 1) {
                    const outputColumn = cellIndex - inputHeaders.length;
                    const cellType = MappingTablesUtils.getTypeFromTitle(outputHeaders[outputColumn].type.title);
                    property = outputHeaders[outputColumn].id ? outputHeaders[outputColumn].id : outputHeaders[outputColumn].text;

                    if (cellType === CELL.Media.cellType) {
                        rowData[property] = cell.value.name ? { "repo-url": cell.value.name, repository: "curated" } : null;
                    }
                    else if (cellType === CELL.Color.cellType) {
                        rowData[property] = cell.color;
                    }
                    else {
                        // applicable for type string and type url
                        rowData[property] = cell.text;
                    }
                }
                else {
                    // cellIndex === input column
                    property = getDataElementDisplayName(inputHeaders[cellIndex].value);

                    rowData[property] = cell.text;
                }
            });
            mappingTableData.data.push(rowData);
        });

        this.props.handleSaveChanges(this.state.title, mappingTableData).then(() => {
            this.setState({ showSpinner: false, isChanged: false });
            doneCB && doneCB();
        });
    };

    onCancel = () => {
        HubNotifications.confirm("Cancel Changes", "Are you sure you want to revert the data to last save?", () => {
            this.props.handleCancelChanges();
        });
    };

    onClose = (e: MouseEvent) => {
        if (this.state.isChanged && !this.props.isViewOnly) {
            HubNotifications.confirmWithCancel(
                "Close Table",
                "Want to save your changes?",
                () => {
                    this.onSave(e, this.props.handleClose);
                },
                () => {
                    this.props.handleClose();
                }
            );
        }
        else {
            this.props.handleClose();
        }
    };

    onUpload = (files) => {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // @ts-ignore
                csv.parse(reader.result, (err, data) => {
                    this.parseCsvFile(data);
                });
            };

            reader.readAsText(files[0]);

            this.setState({ showUploadCsv: false, showSpinner: true });
        }
    };

    validateName = (name, prevName) => {
        //empty
        if (!name) {
            return {
                result: false,
                message: "Missing data table name"
            };
        }

        return true;
    };

    validateOutputHeader = (header) => {
        if (header && (!header.text || !header.type)) {
            return {
                result: false
            };
        }
        return {
            result: true
        };
    };

    /*    activeVersionIsLatest = (mappingTable) => {
            return mappingTable.activeVersion === undefined || mappingTable.activeVersion === mappingTable.version || mappingTable.activeVersionItem === undefined ;
        };

        getMappingTableDownloadSelf = (mappingTable) => {
            return this.activeVersionIsLatest(mappingTable) || !mappingTable.activeVersionItem.downloadSelf ? mappingTable.downloadSelf : mappingTable.activeVersionItem.downloadSelf;
        };*/

    buildTableData(mappingTable: DataTable) {
        this.setState({
            showSpinner: true,
            showUploadCsv: false,
            title: mappingTable.title
        });

        let inputHeaders = [];
        let outputHeaders = [];
        let rows = [];

        MappingTablesUtils.fetchData(mappingTable, this.props.isStageView, this.props.downloadUrl).then((mappingTableData: DataTableBody) => {
            let { scheme, data } = mappingTableData;

            scheme.inputs.forEach((input) => {
                // Inputs evolved like data elements, this means it should be backward completable, and hence will work.
                let inputDataElement = this.props.dataElements.find((element) => getDataElementId(element) === (input.id || input.name));

                if (inputDataElement) {
                    inputHeaders.push({ value: inputDataElement });
                }
                else {
                    inputHeaders.push({ value: { name: input.displayName || input.name || input.id }, missing: true });
                }
            });

            scheme.outputs.forEach((output) => {
                outputHeaders.push({
                    text: output.name,
                    id: output.id,
                    type: { name: MappingTablesUtils.getCharFromType(output.type), title: MappingTablesUtils.getTitleFromType(output.type) }
                });
            });

            data.forEach((row) => {
                let cells = [];
                inputHeaders.forEach((input) => {
                    cells.push({ text: row[getDataElementId(input.value)] || row[getDataElementDisplayName(input.value)] || row[input.value.name], value: { name: "" } });
                });

                outputHeaders.forEach((output) => {
                    const cellData = row[output.id ? output.id : output.text];
                    const cellType = MappingTablesUtils.getTypeFromTitle(output.type.title);

                    if (cellType === CELL.Media.cellType) {
                        cells.push({
                            ...HubMappingTable.initCell(),
                            [CELL.Media.cellKey]: { name: cellData ? cellData["repo-url"] : "" }
                        });
                    }
                    else if (cellType === CELL.Color.cellType) {
                        cells.push({
                            ...HubMappingTable.initCell(),
                            [CELL.Color.cellKey]: cellData
                        });
                    }
                    else if (cellType === CELL.URL.cellType) {
                        cells.push({
                            ...HubMappingTable.initCell(),
                            [CELL.URL.cellKey]: cellData
                        });
                    }
                    else {
                        cells.push({
                            ...HubMappingTable.initCell(),
                            [CELL.String.cellKey]: cellData
                        });
                    }
                });

                rows.push({ cells });
            });

            this.setState({
                inputHeaders,
                outputHeaders,
                rows,
                showSpinner: false
            });
        });
    }

    parseCsvFile(data) {
        let outputHeaders = [];
        let notEmptyCells = [];
        let notEmptyRows = [];

        data.map((row, rowIndex) => {
            row.map((cell, cellIndex) => {
                if (cell) {
                    notEmptyCells[cellIndex] = true;
                    notEmptyRows[rowIndex] = true;
                }
            });
        });

        let columnsCount = notEmptyCells.filter((cell) => cell).length;

        if (!this.props.mappingTableElement) {
            let rows = HubMappingTable.fillData(data, notEmptyRows, notEmptyCells);

            // fill the headers
            for (let i = 0; i < columnsCount - this.state.inputHeaders.length; i++) {
                outputHeaders.push({
                    text: "",
                    type: { name: CELL.String.char, title: MappingTablesUtils.getTitleFromType(CELL.String.cellType) },
                    color: null
                });
            }

            this.setState({ outputHeaders, rows, isChanged: true });
        }
        else {
            if (this.state.inputHeaders.length + this.state.outputHeaders.length !== columnsCount) {
                this.setState({ showDialog: true });
            }
            else {
                this.csvRows = HubMappingTable.fillData(data, notEmptyRows, notEmptyCells);
                this.setState({ showOverrideDialog: true });
            }
        }

        this.setState({ showSpinner: false });
    }

    // fill the data
    static fillData(data, notEmptyRows, notEmptyCells) {
        let rows = [];
        for (let rowIndex = 0; rowIndex < notEmptyRows.length; rowIndex++) {
            if (notEmptyRows[rowIndex]) {
                let row = { cells: [] };
                for (let cellIndex = 0; cellIndex < notEmptyCells.length; cellIndex++) {
                    if (notEmptyCells[cellIndex]) {
                        if (data[rowIndex][cellIndex] && data[rowIndex][cellIndex].charAt(0) === "{" && data[rowIndex][cellIndex].charAt(data[rowIndex][cellIndex].length - 1) === "}") {
                            row.cells.push({
                                ...HubMappingTable.initCell(),
                                [CELL.Color.cellKey]: JSON.parse(data[rowIndex][cellIndex])
                            });
                        }
                        else {
                            row.cells.push({
                                ...HubMappingTable.initCell(),
                                [CELL.String.cellKey]: data[rowIndex][cellIndex]
                            });
                        }
                    }
                }
                rows.push(row);
            }
        }
        return rows;
    }

    confirmUpload = () => {
        this.setState({ rows: this.csvRows, isChanged: true, showOverrideDialog: false });
        this.csvRows = [];
    };

    validateAssets(columnIndex, override) {
        let { rows } = this.state;

        let assets = this.props.assets.filter((asset) => {
            return asset.mediaType && (asset.mediaType === "video" || asset.mediaType === "image");
        });

        rows.forEach((row) => {
            let cell = row.cells[columnIndex];
            let asset = assets.find((asset) => asset.title.toLowerCase() === cell.text.toLowerCase());

            if (!asset) {
                cell.missing = true;
                cell.value.name = cell.text;
            }
            else if (override) {
                cell.text = "";
                cell.value.name = asset.name;
                cell.missing = false;
            }
        });

        this.setState({ rows });
    }

    closeCsvUploadIcon = () => {
        this.setState({ showUploadCsv: false });
    };

    static renameInput(mappingTableData: DataTableBody, inputId, newName): DataTableBody {
        let oldInput = mappingTableData.scheme.inputs.find((input) => inputId === (input.id || input.name));
        if (!oldInput) {
            return mappingTableData;
        }

        let newMappingTableData: DataTableBody = {
            scheme: {
                inputs: [],
                outputs: mappingTableData.scheme.outputs
            },
            data: []
        };

        newMappingTableData.scheme.inputs = mappingTableData.scheme.inputs.map((input) => {
            if (input === oldInput) {
                return {
                    id: inputId,
                    displayName: newName,
                    name: newName,
                    type: input.type
                };
            }
            else {
                return input;
            }
        });

        newMappingTableData.data = mappingTableData.data.map((rowData) => {
            let newRowData = { ...rowData };
            let value = newRowData[oldInput.name];
            delete newRowData[oldInput.name];
            newRowData[newName] = value;
            return newRowData;
        });

        return newMappingTableData;
    }

    render() {
        const outputHeadersValidation = this.state.outputHeaders.map((header) => this.validateOutputHeader(header));
        const isMappingTableValid = this.state.title && outputHeadersValidation.every((validation) => validation.result);
        return (
            <div className="hubMappingTable">
                {this.state.showSpinner && <HubSpinner width={100} height={100} spinnerColor={"#0099FF"} show={this.state.showSpinner} />}

                <div className="header">
                    <HubContentEditable
                        className="tableName"
                        value={this.state.title}
                        isFocused={this.state.isFocused}
                        disabled={this.props.isViewOnly}
                        placeholder="Enter Name"
                        validateFunc={this.validateName}
                        onBlur={() => this.setState({ isFocused: false })}
                        onConfirm={(value) => this.setState({ title: value.trim(), isFocused: false, isChanged: true })}
                    />
                    <Icon className="close-icon" onClick={this.onClose} />
                    {!this.props.isViewOnly && (
                        <span className="mappingTableButtonsArea">
                            <Button className="save" size="tiny" onClick={(e) => this.onSave(e)} disabled={!isMappingTableValid}>
                                Save
                            </Button>
                            <Button className="revert" size="tiny" onClick={this.onCancel}>
                                Revert
                            </Button>
                            <Icon
                                className="import"
                                onClick={() => {
                                    this.setState({ showUploadCsv: true });
                                }}
                            />
                        </span>
                    )}
                </div>

                <HubMappingTableData
                    assets={this.props.assets}
                    dataElements={this.props.dataElements}
                    inputHeaders={this.state.inputHeaders}
                    outputHeaders={this.state.outputHeaders}
                    outputHeadersValidation={outputHeadersValidation}
                    rows={this.state.rows}
                    onRowAdd={this.onRowAdd}
                    onRowRemove={this.onRowRemove}
                    onRowClear={this.onRowClear}
                    onInputHeaderChange={this.onInputHeaderChange}
                    onOutputHeaderChange={this.onOutputHeaderChange}
                    onOutputColumnAdd={this.onOutputColumnAdd}
                    onOutputColumnRemove={this.onOutputColumnRemove}
                    onOutputColumnClear={this.onOutputColumnClear}
                    onOutputTypeChange={this.onOutputTypeChange}
                    onCellChanged={this.onCellChanged}
                    isViewOnly={this.props.isViewOnly}
                />

                {this.state.showUploadCsv && !this.props.isViewOnly && (
                    <div className="uploadCsv">
                        <Icon className="close-csv" onClick={this.closeCsvUploadIcon} />
                        <Dropzone onDrop={this.onUpload} className="dndCuratedAssets" activeClassName="active" accept=".csv, application/vnd.ms-excel">
                            <div className="uploadFiles" />
                            <p>Drop .csv file here</p>
                            <div className="extra">
                                or &nbsp;
                                <a>browse</a>
                                &nbsp; your computer
                            </div>
                        </Dropzone>
                    </div>
                )}

                <SskyDialog
                    open={this.state.showDialog}
                    title="Upload new CSV"
                    contentText="Number of columns in the table doesn’t match the number of columns in the selected file. Fix the issue and upload again."
                    onPositiveClick={() => this.setState({ showDialog: false })}
                    positiveButtonText="got it!"
                    promptType="error"
                />

                <SskyDialog
                    open={this.state.showOverrideDialog}
                    title="Upload new CSV"
                    contentText="Uploading a new CSV will override the existing data in the table. Upload file?"
                    onPositiveClick={this.confirmUpload}
                    onNegativeClick={() => this.setState({ showOverrideDialog: false })}
                    positiveButtonText="Upload"
                />
            </div>
        );
    }
}

export default HubMappingTable;
