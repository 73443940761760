import { useState, useEffect } from "react";

type UseWindowSize = {
    width: number;
};

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

export const useWindowSize = (): UseWindowSize => {
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };

        window.addEventListener("resize", resizeListener, true);

        return () => window.removeEventListener("resize", resizeListener, true);
    }, []);

    return { width };
};