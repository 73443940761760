import { useEditorPronunciationDefinitionsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { GqlClientAccountPronunciationDefinitionFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UsePronunciationDefinitions = {
    definitions: GqlClientAccountPronunciationDefinitionFragment[];
    pronunciationLibraryVersionId: string;
};

export const usePronunciationDefinitions = (): UsePronunciationDefinitions => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorPronunciationDefinitionsQuery({ variables: { programId, programVersionId } });

    const definitions = data?.editorProgram?.programVersion?.pronunciationLibraryVersion?.definitions ?? [];
    const pronunciationLibraryVersionId = data?.editorProgram?.programVersion?.pronunciationLibraryVersion?.id;

    return { definitions, pronunciationLibraryVersionId };
};
