import type { VideoSpecResponse } from "../../../../common/external/videoSpecResponse";

type SuccessCb = (token: string) => void;
type FailureCb = (error: any) => void;
type GetSessionFunction = (successCb: SuccessCb, failureCB: FailureCb, requestId: string) => void;

/**
 * GetSessionFunction type is the signature player's handlePlayRequest props is expecting
 * */
export function buildPlayRequest(renderVideoFunc: (requestId?: string) => Promise<VideoSpecResponse>): GetSessionFunction {
    async function handlePlayRequest(successCb, failureCB, requestId) {
        try {
            const previewVideoResponse: VideoSpecResponse = await renderVideoFunc(requestId);
            successCb(previewVideoResponse.playerToken);
        }
        catch (error) {
            failureCB(error);
        }
    }

    return handlePlayRequest;
}