import { useReactiveVar } from "@apollo/client";
import { localEditorShowReplaceAvatarHint } from "../../State";
import { setShowReplaceAvatarHint } from "../../Nooks/ShowReplaceAvatarHint";

type UseShowReplaceAvatarHint = {
    showReplaceAvatarHint: boolean;
    setShowReplaceAvatarHint: (value: boolean) => void;
}

export const useShowReplaceAvatarHint = (): UseShowReplaceAvatarHint => {
    const showReplaceAvatarHint = useReactiveVar(localEditorShowReplaceAvatarHint);

    return { showReplaceAvatarHint, setShowReplaceAvatarHint };
};
