import { useReactiveVar } from "@apollo/client";
import { useMemo } from "react";
import { activeAccountIdVar, scrollToBottomVar, userDataVar, isBreadCrumbsVisibleVar, mainContainerRefVar } from "../../model";
import type { Account } from "../../../../common/types/restApiTypes";

export const useUserData = () => useReactiveVar(userDataVar);
export const useActiveAccountId = () => useReactiveVar(activeAccountIdVar);

export const useActiveAccount = (): Account => {
    const userData = useUserData();
    const activeAccountId = useActiveAccountId();
    return useMemo(
        () => userData.accounts.find(
            account => account.accountId === activeAccountId
        ),
        [userData.accounts, activeAccountId]
    );
};

export const useScrollToBottom = () => useReactiveVar(scrollToBottomVar);
export const useMainContainerRef = () => useReactiveVar(mainContainerRefVar);

export const useIsBreadCrumbsVisible = () => useReactiveVar(isBreadCrumbsVisibleVar);
