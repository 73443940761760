import { useActiveBrandId } from "./useActiveBrandId";
import { useBrand } from "./useBrand";
import { useEffect, useRef } from "react";
import { getTextStyleFontForStyling } from "../../Nooks/BrandLibrary/textStyles";

const loadFont = async (name: string, src: string) => {
    const fontFace = new FontFace(name, `url(${src})`, { display: "swap" });
    await fontFace.load();
    document.fonts.add(fontFace);
};

export const useLoadBrandFonts = () => {
    const { activeBrandId } = useActiveBrandId();
    const { brandTextStyles } = useBrand(activeBrandId);
    const textStylesFontsVariations = brandTextStyles.map(style => getTextStyleFontForStyling(style).fontVariations);
    const loadedFonts = useRef<Set<string>>(new Set());
    
    const uploadFonts = async () => {
        textStylesFontsVariations.forEach((fontVariations) => {
            Object.values(fontVariations).forEach((fontVariation) => {
                if (!loadedFonts.current.has(fontVariation.id)) {
                    loadedFonts.current.add(fontVariation.id);
                    //calling font loading function async, ignoring errors
                    loadFont(fontVariation.name, fontVariation.webFontUrl).catch(() => {/* do nothing */});
                }
            });
        });
    };
    useEffect(() => {
        uploadFonts();
    }, [JSON.stringify(textStylesFontsVariations)]);
};
