import type { Store } from "redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../logic/common/rootReducer";
import dynamicMiddlewares from "redux-dynamic-middlewares";

let store: Store<any>;

export const getStore = (): Store<any> => {
    if (!store) {
        store = createStore(rootReducer, composeWithDevTools(applyMiddleware(dynamicMiddlewares)));
    }

    return store;
};

export const getReduxState = () => {
    return getStore().getState();
};
