import {
    changeAndSetUsedInScene,
    findDataElementById,
    getDataElementDisplayName,
    getDataElementId,
    getDataElementOrigin,
    getDataElementType,
    getDataElementUsedInScene,
    getDerivedDataElementLogicId,
    getOriginDisplayNameAndIndefiniteArticle
} from "../../../../logic/DataElements/DataElementsManager";
import HubNotifications from "../../../../logic/common/hubNotifications";
import DownloadUtils from "../../../../logic/common/downloadUtils";
import { extractDataElementIdsFromLogic, getAssetTitleFromName } from "../../../../logic/vlx/editorLogicUtils";
import { LogicContainers } from "../../../../../common/commonConst";
import { LOGIC_DATA_TYPES, LOGIC_MEDIA_TYPES, LOGIC_TYPE } from "../../../../logic/vlx/consts";
import { extractScenesByType } from "../../../../logic/vlx/utils";
import { extractScenes } from "../../../../logic/vlx/dataElements";
import MappingTablesUtils from "../../../../logic/MappingTables/MappingTablesUtils";
import { CELL } from "../HubMappingTable";
import StateReaderUtils from "../../../../logic/common/StateReaderUtils";
import type { DataElement, UsedIn } from "../../../../../common/types/dataElement";
import { DataElementOrigins } from "../../../../../common/types/dataElement";
import type { Asset } from "../../../../../common/types/asset";
import { AssetTypes } from "../../../../../common/types/asset";
import type { DataTable, DataTableBody } from "../../../../../common/types/dataTable";
import type { Program } from "../../../../../common/types/program";
import * as csv from "csv";

type MappingTableContent = {
    fileName: string;
    fileContent: string;
};

class DataLibraryController {
    // Mapping Tables static functions

    static handleDownloadMappingTable = (mappingTableIds: string[], assets: Asset[], downloadUrl: (url: string) => void, isStageView: boolean) => {
        const mappingTables = mappingTableIds.map((mappingTableId) => {
            return MappingTablesUtils.findMappingTableByID(mappingTableId, assets as DataTable[]);
        });
        const promiseArray: Promise<MappingTableContent | null>[] = mappingTables.map((mappingTable) => {
            if (mappingTable) {
                return DataLibraryController.downloadMappingTableAndConvertToCsvContent(mappingTable, assets, downloadUrl, isStageView).catch((error) => null);
            }
            else {
                return Promise.resolve(null);
            }
        });

        Promise.all(promiseArray).then((mappingTableCsvContentArray) => {
            mappingTableCsvContentArray = mappingTableCsvContentArray.filter((content) => !!content);

            const downloadUtils = new DownloadUtils(undefined, undefined);
            if (mappingTableCsvContentArray.length === 1) {
                downloadUtils.createAndDownloadFile(mappingTableCsvContentArray[0].fileName, mappingTableCsvContentArray[0].fileContent, "text/csv");
            }
            else {
                downloadUtils.createAndDownloadZipFile(mappingTableCsvContentArray);
            }
        });
    };

    static addStageAssetItem = (assets, snapshotAssets) => {
        assets = snapshotAssets.map((snapshotAsset) => {
            let projectAsset = assets.find((projectAsset) => projectAsset.name === snapshotAsset.name && projectAsset.type === snapshotAsset.type);
            return { ...projectAsset, stageVersion: snapshotAsset.version, stageAssetItem: { ...snapshotAsset } };
        });
        return assets;
    };

    static downloadMappingTableAndConvertToCsvContent = (mappingTable: DataTable, assets: Asset[], downloadUrl, isStageView): Promise<MappingTableContent> => {
        return new Promise((resolve, reject) => {
            MappingTablesUtils.fetchData(mappingTable, isStageView, downloadUrl).then((mappingTableData: DataTableBody) => {
                if (mappingTableData) {
                    let csvContent = [];
                    let { scheme, data } = mappingTableData;
                    let cells = [];
                    scheme.inputs.forEach((input) => {
                        cells.push(input.name);
                    });

                    scheme.outputs.forEach((output) => {
                        cells.push(output.name);
                    });

                    csvContent.push(cells);

                    data.forEach((row) => {
                        let cells = [];
                        scheme.inputs.forEach((input) => {
                            cells.push(row[input.name]);
                        });

                        scheme.outputs.forEach((output) => {
                            const outputIdent = output.id || output.text || output.name;
                            if (output.type === CELL.Media.cellType) {
                                let asset = row[outputIdent];
                                cells.push(asset ? getAssetTitleFromName(asset["repo-url"], assets) : "");
                            }
                            else if (output.type === CELL.Color.cellType) {
                                cells.push(JSON.stringify(row[outputIdent]));
                            }
                            else {
                                cells.push(row[outputIdent]);
                            }
                        });

                        csvContent.push(cells);
                    });

                    // @ts-ignore
                    csv.stringify(csvContent, (err, output) => {
                        if (err) {
                            reject(err);
                        }
                        else {
                            resolve({ fileName: `${mappingTable.title}.csv`, fileContent: output });
                        }
                    });
                }
                else {
                    reject("No Data");
                }
            });
        });
    };

    static handleDeleteMappingTables = (mappingTableIds: string[], assets: Asset[], accountId: string, project: Program, deleteAsset, cb) => {
        let mappingTablesToBeDeleted = mappingTableIds.map((mappingTableId) => {
            return MappingTablesUtils.findMappingTableByID(mappingTableId, assets as DataTable[]);
        });
        let title;
        let subtitle;
        if (mappingTablesToBeDeleted.length > 1) {
            title = "Delete Data Tables";
            subtitle = `Are you sure you want to delete ${mappingTablesToBeDeleted.length} data tables?`;
        }
        else {
            let prettyMappingTableName = mappingTablesToBeDeleted[0].title;
            title = "Delete Data Table";
            subtitle = `Are you sure you want to delete ${prettyMappingTableName}?`;
        }

        HubNotifications.confirm(title, subtitle, () => {
            mappingTablesToBeDeleted.forEach((mappingTable) => {
                deleteAsset(accountId, project.projectName, AssetTypes.mappingTable, mappingTable.name, cb);
            });
        });
    };

    static addUsedInScenesForMappingTable(assets, wireframeScenes, logicData) {
        let mappingTables = MappingTablesUtils.extractMappingTablesFromAssets(assets);
        let allScenesLogic = Object.keys(wireframeScenes).reduce((acc, sceneId) => {
            if (!(<any>Object).values(LogicContainers).includes(sceneId)) {
                let sceneLogic = logicData[sceneId] || {};
                acc[sceneId] = { ...wireframeScenes[sceneId], ...sceneLogic };
            }
            return acc;
        }, {});
        let mappingTableToSceneMap = extractScenesByType(allScenesLogic, LOGIC_DATA_TYPES.MappingTable);
        //change scene id to scene name
        Object.keys(mappingTableToSceneMap).forEach((mt) => {
            mappingTableToSceneMap[mt] = mappingTableToSceneMap[mt].map((id) => wireframeScenes[id].name).join(", ");
        });
        //add attribute usedInScenes
        let mappingTablesWithUsedInScene = mappingTables.map((mt) =>
            Object.assign({}, mt, {
                usedInScenes: mappingTableToSceneMap[mt.name]
            })
        );
        return mappingTablesWithUsedInScene;
    }

    // Data Elements Static Function

    static handleUpdateDataElements = (
        arrayOfOldAndNewDataElementsPairs: Array<[DataElement, DataElement]>,
        accountId: string,
        projectName: string,
        updateDataElement,
        addDataElement,
        setInputLogic,
        getInputLogic
    ) => {
        arrayOfOldAndNewDataElementsPairs.forEach((dataElementsPair) => {
            let oldDataElement = dataElementsPair[0];
            let newDataElement = dataElementsPair[1];
            if (oldDataElement && newDataElement) {
                DataLibraryController.handleUpdateDataElement(
                    oldDataElement,
                    newDataElement,
                    undefined,
                    false,
                    accountId,
                    projectName,
                    updateDataElement,
                    addDataElement,
                    setInputLogic,
                    getInputLogic
                );
            }
        });
    };

    static handleUpdateDataElement = (oldDataElement, newDataElement, newLogicData, isNewDataElement, accountId, projectName, updateDataElement, addDataElement, setInputLogic, getInputLogic) => {
        if (!isNewDataElement) {
            updateDataElement({
                accountId,
                projectName,
                dataElementId: getDataElementId(newDataElement),
                data: newDataElement,
                logic: newLogicData
            });
            //case derived type has been changed - do we need it?
            if (getDataElementType(oldDataElement) !== getDataElementType(newDataElement) && getDataElementOrigin(newDataElement) === DataElementOrigins.Derived) {
                const derivedDataElementLogicId = getDerivedDataElementLogicId(newDataElement);
                let logic = getInputLogic(derivedDataElementLogicId, DataElementOrigins.Derived);
                if (logic) {
                    logic.outputType = DataLibraryController.convertToLogicType(getDataElementType(newDataElement));
                    setInputLogic(accountId, projectName, DataElementOrigins.Derived, derivedDataElementLogicId, logic);
                }
            }
            //case converting data element to derived
            if (getDataElementOrigin(oldDataElement) === DataElementOrigins.Feed && getDataElementOrigin(newDataElement) === DataElementOrigins.Derived && newLogicData) {
                setInputLogic(accountId, projectName, DataElementOrigins.Derived, getDerivedDataElementLogicId(newDataElement), newLogicData);
            }
        }
        else {
            addDataElement(accountId, projectName, newDataElement, newLogicData, (createdDataElement) => {
                // Wait for data element to be created with id
                if (newLogicData) {
                    setInputLogic(accountId, projectName, DataElementOrigins.Derived, getDerivedDataElementLogicId(createdDataElement), newLogicData);
                }
            });
        }
    };

    static getUsedInDeleteMessage = (dataElementToBeDeleted: DataElement[], analyticsDataElements, assets: Asset[], dataElements, logicData) => {
        let message = "";
        const dataTables: DataTable[] = MappingTablesUtils.extractMappingTablesFromAssets(assets);
        const usedDataElementIds: string[] = extractDataElementIdsFromLogic(logicData[LogicContainers.Derived], dataTables);

        dataElementToBeDeleted.forEach((de) => {
            const usedInScene = getDataElementUsedInScene(de);
            const usedInAnalytics = DataLibraryController.isUsedInAnalytics(de, analyticsDataElements);
            const usedInStudio = usedDataElementIds.includes(de.id) || usedDataElementIds.includes(de.name);

            if (usedInScene || usedInAnalytics || usedInStudio) {
                message += `\n${getDataElementDisplayName(de)}: `;
                const usedInSceneMessage = `Used in ${usedInScene === "Master" ? "" : "scenes - "}${usedInScene}`;

                if (usedInScene && usedInAnalytics && usedInStudio) {
                    message += `${usedInSceneMessage}, And in Studio Data, And in Analytics Settings.`;
                }
                else if (usedInScene && usedInStudio) {
                    message += `${usedInSceneMessage}, And in Studio Data.`;
                }
                else if (usedInAnalytics && usedInStudio) {
                    message += "Used in Studio Data, And in Analytics Settings.";
                }
                else if (usedInScene && usedInAnalytics) {
                    message += `${usedInSceneMessage}, And in Analytics Settings.`;
                }
                else if (usedInScene) {
                    message += `${usedInSceneMessage}.`;
                }
                else if (usedInStudio) {
                    message += "Used in Studio Data.";
                }
                else {
                    message += "Used in Analytics Settings.";
                }
            }
        });

        return message;
    };

    static isUsedInAnalytics = (de, analyticsDataElements) => {
        return analyticsDataElements && analyticsDataElements.includes(getDataElementId(de));
    };

    static handleDeleteDataElements = (
        dataElementIds: string[],
        dataElements: DataElement[],
        accountId: string,
        projectName: string,
        deleteInputLogic,
        deleteDataElement,
        deleteDataElements,
        analyticsDataElements: string[],
        assets: Asset[],
        wireframesLogic: object,
        cb,
        isFullDemoFlowBuilder: boolean
    ) => {
        let dataElementToBeDeleted = dataElementIds.map((dataElementId) => {
            return findDataElementById(dataElementId, dataElements);
        });
        let numOfDataElementToBeDeleted = dataElementToBeDeleted.length;
        let { displayName: elementEntityName, indefiniteArticle } = getOriginDisplayNameAndIndefiniteArticle(dataElementToBeDeleted[0].origin, isFullDemoFlowBuilder);

        //Parameters to be returned to DataElementsTabContent
        let text;
        let subHeader;
        let handleFunction;
        let isDeleteMessage;

        let usedInMessage = DataLibraryController.getUsedInDeleteMessage(dataElementToBeDeleted, analyticsDataElements, assets, dataElements, wireframesLogic);

        if (!usedInMessage) {
            //Set message for single delete
            if (numOfDataElementToBeDeleted === 1) {
                subHeader = "Permanently delete " + elementEntityName + " " + getDataElementDisplayName(dataElementToBeDeleted[0]) + "?";
                text = "Once " + indefiniteArticle + " " + elementEntityName + " is deleted, it cannot be restored.";
            }
            //Set message for multiple delete
            else if (numOfDataElementToBeDeleted > 1) {
                subHeader = "Permanently delete the following " + elementEntityName + "s?" + "\n" + dataElementToBeDeleted.map((element) => getDataElementDisplayName(element)).join(", ");
                text = "Once " + indefiniteArticle + " " + elementEntityName + " is deleted, it cannot be restored.";
            }
            //Set delete function
            handleFunction = (usedInEditorCallback?: (usedInData: UsedIn) => void) => {
                dataElementToBeDeleted.forEach((toBeDeleted) => {
                    if (toBeDeleted.origin === DataElementOrigins.Derived) {
                        deleteInputLogic(accountId, projectName, DataElementOrigins.Derived, getDerivedDataElementLogicId(toBeDeleted));
                    }
                    let dataElementId = getDataElementId(toBeDeleted);
                    deleteDataElement(accountId, projectName, dataElementId, cb);
                });
                deleteDataElements(accountId, projectName, dataElementIds, (deletedIds: string[]) => deletedIds.forEach((deId) => cb(deId)), usedInEditorCallback);
            };
            //Set in order to choose between 1 button and 2 button dialog
            isDeleteMessage = true;
        }
        else {
            numOfDataElementToBeDeleted === 1
                ? (subHeader = "The following " + elementEntityName + " is in use and cannot be deleted.")
                : (subHeader = "The following " + elementEntityName + "s are in use and cannot be deleted.");
            subHeader = subHeader + "\n" + usedInMessage;
            text = "";
            handleFunction = () => {};
            isDeleteMessage = false;
        }

        return { subHeader, text, isDeleteMessage, handleFunction };
    };

    static handleEditorUsedInResponse = (usedInData: Array<UsedIn>) => {
        const numOfDataElementToBeDeleted = usedInData.length;
        const text = "";
        const handleFunction = () => {};
        const isDeleteMessage = false;
        const subHeaderFirstLine = `The following Data Element${numOfDataElementToBeDeleted === 1 ? " is" : "s are"} in use and cannot be deleted.`;

        const subHeaderArray: string[] = usedInData.reduce((acc: string[], usedInResult: UsedIn): string[] => {
            acc.push(`${usedInResult.dataElementName} - used in:`);
            usedInResult.usedInScenes.forEach((usedIn, index) => {
                acc.push(`${index + 1}. ${usedIn.programName} > ${usedIn.sceneName}`);
            });
            return acc;
        }, [subHeaderFirstLine, "\u00a0"]);
        const subHeader = subHeaderArray.join("\n");

        return { subHeader, text, isDeleteMessage, handleFunction };
    };

    static getSearchFieldPlaceholderText = (selectedTabId, isFullDemoFlowBuilder?: boolean) => {
        if (selectedTabId === DataLibraryController.studioDataTabId) {
            return isFullDemoFlowBuilder ? "Find Audience" : "Find Studio Data";
        }
        else if (selectedTabId === DataLibraryController.dataTablesTabId) {
            return "Find Data Table";
        }
        else if (selectedTabId === DataLibraryController.creativeDataTabId) {
            return "Find Content Set Data";
        }
        return "Find Data Element";
    };

    static addUsedInScene(dataElementsArr, wireframes, wireframeScenes, logicData, assets) {
        let dataElementToScenesMap = DataLibraryController.getUsedInScenes(wireframes, dataElementsArr, wireframeScenes, logicData, assets);
        //adding usedInScene attribute to data elements
        return dataElementsArr.map((de) => changeAndSetUsedInScene(de, dataElementToScenesMap[getDataElementId(de)]));
    }

    static getScenesDataAndLogic = (wireframeScenes, logicData) => {
        return Object.keys(wireframeScenes).reduce((acc, sceneId) => {
            if (sceneId !== LogicContainers.Master || !Object.values(LogicContainers).includes(sceneId)) {
                let sceneLogic = logicData[sceneId] || {};
                acc[sceneId] = { ...wireframeScenes[sceneId], ...sceneLogic };
            }
            return acc;
        }, {});
    };

    static getMasterSceneDataAndLogic = (masterSceneData, logicObj) => {
        return {
            [LogicContainers.Master]: {
                ...masterSceneData, //id, name, sceneParts
                ...logicObj[LogicContainers.Master],
                ...logicObj[LogicContainers.Story],
                ...logicObj[LogicContainers.Analytics]
            }
        };
    };

    static getUsedInScenes = (wireframes, dataElementsArr, wireframeScenes, logicData, assets) => {
        let scenesDataAndLogic = DataLibraryController.getScenesDataAndLogic(wireframeScenes, logicData);
        let masterDataAndLogic = DataLibraryController.getMasterSceneDataAndLogic(wireframeScenes[LogicContainers.Master], logicData);
        let allScenesLogic = { ...scenesDataAndLogic, ...masterDataAndLogic };
        let mappingTables = MappingTablesUtils.extractMappingTablesFromAssets(assets);
        let deToScenesMap = extractScenes({ wireframes, logicData: allScenesLogic, dataElementsArr, derivedLogic: logicData[LogicContainers.Derived], mappingTables });
        // Replacing scene ids with scene name.
        Object.keys(deToScenesMap).forEach((de) => {
            deToScenesMap[de] = deToScenesMap[de].map((id) => wireframeScenes[id].name).join(", ");
        });
        return deToScenesMap;
    };

    static convertToLogicType(dataElementType) {
        switch (dataElementType) {
            case LOGIC_MEDIA_TYPES.String:
                return LOGIC_TYPE.Text;
            case LOGIC_MEDIA_TYPES.Number:
                return LOGIC_TYPE.DataElementNumber;
            case LOGIC_MEDIA_TYPES.Date:
                return LOGIC_TYPE.DataElementDate;
            case LOGIC_MEDIA_TYPES.Boolean:
                return LOGIC_TYPE.DataElementBoolean;
            case LOGIC_MEDIA_TYPES.URL:
                return LOGIC_TYPE.Image;
        }
        return LOGIC_TYPE.Text;
    }

    static isDataElementInUseInMappingTables(dataElementId, assets) {
        let result = false;
        let mappingTables = MappingTablesUtils.extractMappingTablesFromAssets(assets);
        mappingTables.forEach((mappingTable) => {
            let inputs = mappingTable.mappingTableScheme.inputs;
            if (inputs && !result) {
                result = inputs.some((input) => {
                    return input.id === dataElementId || input.name === dataElementId;
                });
            }
        });
        return result;
    }

    static getDataElementUsedInScenesIds(dataElement, scenes) {
        let scenesIds = [];

        let scenesNamesString = getDataElementUsedInScene(dataElement);
        let scenesNamesArr = scenesNamesString.split(",");
        scenesNamesArr.forEach((sceneName) => {
            let scene = scenes.find((s) => s.name === sceneName.trim());
            if (scene) {
                scenesIds.push(scene.id);
            }
        });

        return scenesIds;
    }

    static getDataElementsUsedInScenesMap(dataElements, scenes, dataElementsFilterFunc) {
        let scenesToDataElementsMap = new Map();

        let dataElementsArr = dataElements;
        if (dataElementsFilterFunc && typeof dataElementsFilterFunc === "function") {
            dataElementsArr = dataElements.filter(dataElementsFilterFunc);
        }
        dataElementsArr.forEach((dataElement) => {
            let scenesIds = DataLibraryController.getDataElementUsedInScenesIds(dataElement, scenes);
            scenesIds.forEach((sceneId) => {
                if (!scenesToDataElementsMap.has(sceneId)) {
                    scenesToDataElementsMap.set(sceneId, []);
                }
                let sceneDataElements = scenesToDataElementsMap.get(sceneId);
                if (!sceneDataElements.includes(dataElement)) {
                    sceneDataElements.push(dataElement);
                }
            });
        });

        //sort each scene's data elements by name
        scenesToDataElementsMap.forEach((value, key, map) => {
            let sortedDataElementsArr = value.sort(StateReaderUtils.objectComparator("displayName", 1));
            map.set(key, sortedDataElementsArr);
        });

        return scenesToDataElementsMap;
    }

    static dataElementsTabId = 0;
    static studioDataTabId = 1;
    static dataTablesTabId = 2;
    static creativeDataTabId = 3;
}
export default DataLibraryController;
