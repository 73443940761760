import type { MutationUpdaterFn, QueryOptions } from "@apollo/client";
import type {
    GqlClientButtonShapeValues,
    GqlClientCreateBrandMutation,
    GqlClientCreateBrandMutationVariables,
    GqlClientCreateColor,
    GqlClientCreateTextStyle,
    GqlClientDeleteBrandCollectionMutation,
    GqlClientDeleteBrandCollectionMutationVariables,
    GqlClientEditorAssetMedia,
    GqlClientEditorBrandColorFragment,
    GqlClientEditorBrandFragment,
    GqlClientEditorBrandsQuery,
    GqlClientEditorBrandsQueryVariables,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorDefaultBrandIdQuery,
    GqlClientEditorDefaultBrandIdQueryVariables,
    GqlClientEditorProgramVersionBrandByAudienceQuery,
    GqlClientEditorProgramVersionBrandByAudienceQueryVariables,
    GqlClientEditorSelectedBrandIdQuery,
    GqlClientEditorSelectedBrandIdQueryVariables,
    GqlClientLocalEditorBrandQuery,
    GqlClientLocalEditorBrandQueryVariables,
    GqlClientSwitchBcCollectionAndDeleteCurrentMutation,
    GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables,
    GqlClientUpdateBrandMutation,
    GqlClientUpdateBrandMutationVariables,
    GqlClientUpdateColor,
    GqlClientUpdateEditorSelectedBrandMutation,
    GqlClientUpdateEditorSelectedBrandMutationVariables,
    GqlClientUpdateTextStyle
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateBrandDocument,
    DeleteBrandCollectionDocument,
    EditorBrandsDocument,
    EditorDefaultBrandIdDocument,
    EditorProgramVersionBrandByAudienceDocument,
    EditorSelectedBrandIdDocument,
    GqlClientEditorAssetSource,
    GqlClientUpdateEditorSelectedBcCollectionResult,
    LocalEditorBrandDocument,
    SwitchBcCollectionAndDeleteCurrentDocument,
    UpdateBrandDocument,
    UpdateEditorSelectedBrandDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getApolloClient } from "../../../../apollo";
import { localBrandByAudienceFallbackIdVar, localDeleteCandidateBrandIdVar, localEditedBrandIdVar, localIsNewBrandVar } from "../../State";
import { IGNORE_UPDATED } from "../../../../logic/common/Consts";
import { setSuccessNotification } from "../Notification";
import { resetButtonShapeLocalVar, resetColorsLocalVar, resetLogoLocalVar, resetTextStylesLocalVar } from "./";
import type { BrandId, ColorValues, LogoLocation, ProgramId, ProgramVersionId } from "../../types";
import type { BrandRules } from "../../../../../common/types/editorBrandRules";
import { findEntityByExtractingLocalIdFromId } from "../../Utils";

export const getBrand = (brandId: BrandId): GqlClientEditorBrandFragment => {
    const cachedQuery = getApolloClient().readQuery<GqlClientLocalEditorBrandQuery, GqlClientLocalEditorBrandQueryVariables>({
        query: LocalEditorBrandDocument,
        variables: { brandId }
    });

    return cachedQuery.localEditorBrand;
};

export const getBrands = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): GqlClientEditorBrandFragment[] => {
    const cachedQuery = getApolloClient().readQuery<GqlClientEditorBrandsQuery, GqlClientEditorBrandsQueryVariables>({
        query: EditorBrandsDocument,
        variables: {
            programId,
            programVersionId
        }
    });
    return cachedQuery.editorProgram?.programVersion?.brandConfigurationVersion.brandConfigurationCollections ?? [];
};

const getDefaultBrandId = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): BrandId => {
    const cachedQuery = getApolloClient().readQuery<GqlClientEditorDefaultBrandIdQuery, GqlClientEditorDefaultBrandIdQueryVariables>({
        query: EditorDefaultBrandIdDocument,
        variables: {
            programId,
            programVersionId
        }
    });

    return cachedQuery.editorProgram?.programVersion?.brandConfigurationVersion.defaultBrandConfigurationCollection.id;
};

export const getSelectedBrandId = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): BrandId => {
    const cachedQuery = getApolloClient().readQuery<GqlClientEditorSelectedBrandIdQuery, GqlClientEditorSelectedBrandIdQueryVariables>({
        query: EditorSelectedBrandIdDocument,
        variables: {
            programId,
            programVersionId
        }
    });

    return cachedQuery.editorProgram?.programVersion?.selectedBrandConfiguration?.id;
};

const mergeBrandTextStyles = (
    editorTextStyles: GqlClientEditorBrandTextStyleFragment[],
    remoteTextStyles: GqlClientEditorBrandTextStyleFragment[],
    withUpdated: boolean
): GqlClientUpdateTextStyle[] | GqlClientCreateTextStyle[] => {

    return remoteTextStyles.map(remoteTextStyle => {
        const localTextStyle: GqlClientEditorBrandTextStyleFragment = findEntityByExtractingLocalIdFromId(editorTextStyles, remoteTextStyle.id);
        const fontSource = localTextStyle?.fontSource ?? remoteTextStyle.fontSource;

        return {
            id: remoteTextStyle.id,
            letterCase: localTextStyle?.letterCase ?? remoteTextStyle.letterCase,
            lineSpacing: localTextStyle?.lineSpacing ?? remoteTextStyle.lineSpacing,
            letterSpacing: localTextStyle?.letterSpacing ?? remoteTextStyle.letterSpacing,
            fontSource,
            accountFontId: fontSource === GqlClientEditorAssetSource.ACCOUNT ? localTextStyle?.accountFont.id ?? remoteTextStyle.accountFont.id : undefined,
            ccFontId: fontSource === GqlClientEditorAssetSource.CC ? localTextStyle?.ccFont.id ?? remoteTextStyle.ccFont.id : undefined,
            updated: withUpdated ? remoteTextStyle.updated : undefined
        };
    });
};

const mergeBrandColors = (editorColors: ColorValues, remoteColors: GqlClientEditorBrandColorFragment[], withUpdated: boolean): GqlClientUpdateColor[] | GqlClientCreateColor[] => {
    return remoteColors.map(remoteColor => {
        return {
            id: remoteColor.id,
            color: editorColors?.[remoteColor.ccDof.localId]?.color ?? remoteColor.color,
            updated: withUpdated ? remoteColor.updated : undefined
        };
    });
};

type UpdateBrandParams = {
    programId: ProgramId;
    programVersionId: ProgramVersionId;
    brandId: BrandId;
    brandName: GqlClientEditorBrandFragment["name"];
    brandColorValues: ColorValues;
    brandLogoLocation: LogoLocation;
    brandTextStyles: GqlClientEditorBrandTextStyleFragment[];
    logoMediaId: GqlClientEditorAssetMedia["id"];
    brandButtonShape: GqlClientButtonShapeValues;
};

export const updateBrand = async ({ brandId, brandName, brandColorValues, brandLogoLocation, brandTextStyles, logoMediaId, brandButtonShape }: UpdateBrandParams): Promise<void> => {
    const remoteBrand = getBrand(brandId);

    await getApolloClient().mutate<GqlClientUpdateBrandMutation, GqlClientUpdateBrandMutationVariables>({
        mutation: UpdateBrandDocument,
        variables: {
            dryRun: false,
            input: {
                id: brandId,
                name: brandName,
                updated: remoteBrand.updated, //Consider using "ignore updated", since we do not handle/notify on conflicts at client-side. Currently, the behaviour looks buggy.
                logoLocation: brandLogoLocation,
                logoMediaId: logoMediaId,
                textStyles: mergeBrandTextStyles(brandTextStyles, remoteBrand.textStyles, true) as GqlClientUpdateTextStyle[],
                colors: mergeBrandColors(brandColorValues, remoteBrand.colors, true) as GqlClientUpdateColor[],
                buttonShape: brandButtonShape
            }
        }
    });
};

const updateCacheAfterBrandCreation = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): MutationUpdaterFn<GqlClientCreateBrandMutation> => (cache, { data }) => {
    const editorBrandsQueryOptions: QueryOptions<GqlClientEditorBrandsQueryVariables, GqlClientEditorBrandsQuery> = {
        query: EditorBrandsDocument,
        variables: { programId, programVersionId }
    };
    const cachedEditorBrandsQuery = cache.readQuery(editorBrandsQueryOptions);

    const existingBrands = cachedEditorBrandsQuery.editorProgram.programVersion.brandConfigurationVersion.brandConfigurationCollections;
    const brandConfigurationVersionId = cachedEditorBrandsQuery.editorProgram.programVersion.brandConfigurationVersion.id;
    const newBrand = data.createBcCollection.brandConfigurationCollection;

    cache.writeQuery({
        ...editorBrandsQueryOptions,
        data: {
            editorProgram: {
                __typename: "EditorProgram",
                id: programId,
                programVersion: {
                    __typename: "EditorProgramVersion",
                    id: programVersionId,
                    brandConfigurationVersion: {
                        __typename: "BrandConfigurationVersion",
                        id: brandConfigurationVersionId,
                        brandConfigurationCollections: [...existingBrands, newBrand]
                    }
                }
            }
        }
    });
};

type CreateBrandParams = Omit<UpdateBrandParams, "brandId"> & {
    showSuccessNotification?: boolean;
};

export const createBrand = async ({
    programId,
    programVersionId,
    brandName,
    brandColorValues,
    brandLogoLocation,
    brandTextStyles,
    logoMediaId,
    brandButtonShape,
    showSuccessNotification
}: CreateBrandParams):
    Promise<void> => {
    const defaultBrand = getBrand(getDefaultBrandId(programId, programVersionId));

    const { data } = await getApolloClient().mutate<GqlClientCreateBrandMutation, GqlClientCreateBrandMutationVariables>({
        mutation: CreateBrandDocument,
        variables: {
            input: {
                name: brandName,
                logoLocation: brandLogoLocation,
                logoMediaId,
                buttonShape: brandButtonShape,
                textStyles: mergeBrandTextStyles(brandTextStyles, defaultBrand.textStyles, false),
                colors: mergeBrandColors(brandColorValues, defaultBrand.colors, false)
            }
        },
        update: updateCacheAfterBrandCreation(programId, programVersionId)
    });

    const brandId = data?.createBcCollection?.brandConfigurationCollection?.id;
    if (brandId) {
        await selectBrand(programVersionId, brandId);
        if (showSuccessNotification) {
            setSuccessNotification({ message: "Brand added to library" });
        }
    }
};

export const selectBrand = async (
    programVersionId: ProgramVersionId,
    brandId: BrandId
): Promise<void> => {
    await getApolloClient().mutate<GqlClientUpdateEditorSelectedBrandMutation, GqlClientUpdateEditorSelectedBrandMutationVariables>({
        mutation: UpdateEditorSelectedBrandDocument,
        variables: {
            input: {
                editorProgramVersionId: programVersionId,
                selectedBcCollectionId: brandId,
                updated: IGNORE_UPDATED
            }
        },
        optimisticResponse: {
            __typename: "Mutation",
            updateEditorSelectedBcCollection: {
                __typename: "UpdateEditorSelectedBcCollectionOutputSuccess",
                result: GqlClientUpdateEditorSelectedBcCollectionResult.SUCCESS,
                editorProgramVersion: {
                    __typename: "EditorProgramVersion",
                    id: programVersionId,
                    selectedBrandConfiguration: {
                        __typename: "BrandConfigurationCollection",
                        id: brandId
                    }
                }
            }
        }
    });
};

export const deleteBrand = async (brandId: BrandId): Promise<void> => {
    await getApolloClient().mutate<GqlClientDeleteBrandCollectionMutation, GqlClientDeleteBrandCollectionMutationVariables>({
        mutation: DeleteBrandCollectionDocument,
        variables: {
            input: {
                brandConfigurationCollectionId: brandId
            }
        }
    });
};

export const switchBrandAndDeleteCurrent = async (editorProgramVersionIds: string[], currentBcCollectionId: BrandId, targetBcCollectionId: BrandId): Promise<void> => {
    await getApolloClient().mutate<GqlClientSwitchBcCollectionAndDeleteCurrentMutation, GqlClientSwitchBcCollectionAndDeleteCurrentMutationVariables>({
        mutation: SwitchBcCollectionAndDeleteCurrentDocument,
        variables: {
            input: {
                editorProgramVersionIds,
                currentBcCollectionId,
                targetBcCollectionId
            }
        }
    }).catch(() => { /* do nothing */ });
};

export const resetBrandLocalData = (): void => {
    resetLogoLocalVar();
    resetColorsLocalVar();
    resetTextStylesLocalVar();
    resetButtonShapeLocalVar();
};

export const setEditedBrandId = (brandId: BrandId): void => {
    localEditedBrandIdVar(brandId);
};

export const setIsNewBrand = (isNewBrand: boolean): void => {
    localIsNewBrandVar(isNewBrand);
};

export const setBrandByAudienceFallbackId = (brandId: BrandId): void => {
    localBrandByAudienceFallbackIdVar(brandId);
};

export const getBrandByAudience = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): { bcUseRules: boolean, bcRules: BrandRules } => {
    const cachedQuery = getApolloClient().cache.readQuery<GqlClientEditorProgramVersionBrandByAudienceQuery, GqlClientEditorProgramVersionBrandByAudienceQueryVariables>({
        query: EditorProgramVersionBrandByAudienceDocument,
        variables: {
            programId,
            programVersionId
        }
    });
    return {
        bcUseRules: cachedQuery.editorProgram.programVersion.bcUseRules,
        bcRules: cachedQuery.editorProgram.programVersion.bcRules
    };
};

export const setDeleteCandidateBrandId = (brandId: BrandId): void => {
    localDeleteCandidateBrandIdVar(brandId);
};
