import { useEffect, useState } from "react";
import { setErrorNotification } from "../../../Nooks";
import { RecorderLoggingEventType, reportRecorderError } from "../../../../../logic/common/logging/recorderClientLogging";

export const useMediaDevices = () => {
    const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
    const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const fetchDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            if (devices?.length > 0) {
                const groupedDevices = new Map<string, { mic?: MediaDeviceInfo; camera?: MediaDeviceInfo }>();

                devices.forEach(device => {
                    if (device.kind !== "audioinput" && device.kind !== "videoinput") return;

                    const key = device.groupId || device.deviceId; // Use groupId if available
                    const cleanedDevice: MediaDeviceInfo = {
                        deviceId: device.deviceId,
                        groupId: device.groupId,
                        kind: device.kind,
                        label: device.label,
                        toJSON: device.toJSON // Preserve method for consistency
                    };

                    if (!groupedDevices.has(key)) {
                        groupedDevices.set(key, {});
                    }

                    if (device.kind === "audioinput") {
                        groupedDevices.get(key)!.mic = cleanedDevice;
                    }
                    else if (device.kind === "videoinput") {
                        groupedDevices.get(key)!.camera = cleanedDevice;
                    }
                });

                const newCameras = Array.from(groupedDevices.values())
                    .map(({ camera }) => camera)
                    .filter(Boolean) as MediaDeviceInfo[];

                const newMics = Array.from(groupedDevices.values())
                    .map(({ mic }) => mic)
                    .filter(Boolean) as MediaDeviceInfo[];

                setCameras(newCameras);
                setMics(newMics);
                setIsInitialized(true);

                return { cameras: newCameras, mics: newMics };
            }
        }
        catch (err) {
            setErrorNotification({ message: "Something went wrong. Try again or contact Support." });
            reportRecorderError(err, {
                recorderEventType: RecorderLoggingEventType.FailedToFetchMediaDevices,
                metadata: { deviceType: cameras, mics, isInitialized }
            });
        }

        return { cameras: [], mics: [] }; // Return empty arrays in case of failure
    };

    useEffect(() => {
        if (isInitialized) {
            navigator.mediaDevices?.addEventListener("devicechange", fetchDevices);

            return () => {
                navigator.mediaDevices?.removeEventListener("devicechange", fetchDevices);
            };
        }
    }, [isInitialized]);

    return {
        fetchDevices,
        cameras,
        mics
    };
};
