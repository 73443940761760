/* eslint-disable */
import type { MuxerOptions } from "mp4-muxer";
import { ArrayBufferTarget, Muxer } from "mp4-muxer";

type ParsedMimeType = {
    container: string,
    videoCodec: string,
    audioCodec: string
}

type EncoderOptions = {
    videoBitsPerSecond?: number,
    audioBitsPerSecond?: number,
    videoCodec?: string,
    audioCodec?: string
}

export class StreamRecorder {

    #muxer: Muxer<ArrayBufferTarget> | null = null;
    #videoEncoder: VideoEncoder | null = null;
    #audioEncoder: AudioEncoder | null = null;
    readonly #videoTrack: MediaStreamTrack;
    readonly #audioTrack: MediaStreamTrack;
    #isRecording = false;
    #videoReader: ReadableStreamDefaultReader;
    #audioReader: ReadableStreamDefaultReader;
    #totalPauseDuration = 0;
    #pauseStartTime = 0;
    #isPaused = false;
    readonly #encoderOptions: EncoderOptions;
    #startTime: number | null = null;
    public readonly state: "inactive" | "recording" | "paused" = "inactive";

    public onStop: (buffer: ArrayBuffer[]) => void;
    public onError: (error) => void;

    constructor(mediaStream: MediaStream, options?: MediaRecorderOptions) {
        if (!("VideoEncoder" in window) || !("MediaStreamTrackProcessor" in window)) {
            throw new Error("VideoEncoder API not supported in this browser");
        }

        const videoTrack = mediaStream.getVideoTracks()?.[0];
        const audioTrack = mediaStream.getAudioTracks()?.[0];

        if (audioTrack && !("AudioEncoder" in window)) {
            throw new Error("AudioEncoder API not supported in this browser");
        }

        if (!videoTrack) {
            throw new Error("No video track found.");
        }

        this.state = "inactive";

        this.#videoTrack = videoTrack;
        this.#audioTrack = audioTrack;

        this.#encoderOptions = this.#createEncoderOptions(options);

        this.#init(videoTrack, audioTrack);
    }

    #init(videoTrack: MediaStreamTrack, audioTrack: MediaStreamTrack) {

        const muxer = this.#createMuxer(videoTrack, audioTrack);
        this.#videoEncoder = (videoTrack) ? this.#createVideoEncoder(videoTrack, muxer, this.#encoderOptions) : undefined;
        this.#audioEncoder = (audioTrack) ? this.#createAudioEncoder(audioTrack, muxer, this.#encoderOptions) : undefined;

        this.#muxer = muxer;
    }

    public start() {
        if (!this.#muxer) {
            throw new Error("Muxer not initialized");
        }

        this.#isRecording = true;
        this.#isPaused = false;
        (this as any).state = "recording";

        try {
            this.#videoReader = this.#createReader(this.#videoTrack);
            // Start recording video
            this.#processVideoFrames(this.#videoReader);

            if (this.#audioTrack) {
                this.#audioReader = this.#createReader(this.#audioTrack);
                // Start recording audio
                this.#processAudioFrames(this.#audioReader);
            }
        }
        catch (error) {
            if (this.onError) {
                this.onError(error);
            }
        }
    }

    public async stop() {
        if (!this.#isRecording) {
            return;
        }

        this.#isRecording = false;
        (this as any).state = "inactive";

        try {
            // Gracefully release readers - ignore errors id reader has already been released
            if (this.#videoReader) {
                await this.#videoReader.cancel().catch(() => {});
            }
            if (this.#audioReader) {
                await this.#audioReader.cancel().catch(() => {});
            }

            if (this.#videoEncoder && this.#videoEncoder.state !== "closed") {
                await this.#videoEncoder.flush();
                this.#videoEncoder.close();
            }

            if (this.#audioEncoder) {
                // encoder doesn't reach state closed so we need to catch the error.
                try {
                    await this.#audioEncoder.flush();
                    this.#audioEncoder.close();
                }
                catch (error) {}
            }

            if (this.#muxer) {
                this.#muxer.finalize();
                const buffer = this.#muxer.target.buffer;
                if (this.onStop && buffer) {
                    this.onStop([buffer]);
                }
            }
        }
        catch (error) {
            if (this.onError) {
                this.onError(error);
            }
        }
    }

    public pause() {
        if (!this.#isPaused) {
            this.#isPaused = true;
            this.#pauseStartTime = performance.now();
            (this as any).state = "paused";
        }
    }

    public resume() {
        if (this.#isPaused) {
            const pauseDuration = (performance.now() - this.#pauseStartTime) * 1000;
            this.#totalPauseDuration += pauseDuration;
            this.#isPaused = false;
            (this as any).state = "recording";
        }
    }

    #createReader(track: MediaStreamTrack): ReadableStreamDefaultReader {
        // @ts-ignore
        const processor = new MediaStreamTrackProcessor({ track: track });
        const frameStream: ReadableStream = processor.readable;

        return frameStream.getReader();
    }

    public async cleanUp() {
        this.onStop = null;
        this.onError = null;

        this.#totalPauseDuration = 0;
        this.#pauseStartTime = 0;
        this.#isPaused = false;
        await this.stop();
        this.#videoEncoder = null;
        this.#audioEncoder = null;
        this.#muxer = null;
        (this as any).state = "inactive";
    }

    async #processVideoFrames(reader: ReadableStreamDefaultReader<VideoFrame>) {
        let frameCounter = 0;

        try {
            while (this.#isRecording) {
                const { value: frame, done } = await reader.read();
                if (done || !this.#isRecording) {
                    break;
                }

                if (this.#isPaused) {
                    frame.close(); // Drop frames while paused
                    continue;
                }

                // First frame sets the reference
                if (!this.#startTime) {
                    this.#startTime = frame.timestamp;
                }

                // Adjust timestamp to account for paused duration
                let adjustedTimestamp = frame.timestamp - this.#startTime - this.#totalPauseDuration;

                const newFrame = new VideoFrame(frame, { timestamp: adjustedTimestamp });

                frameCounter++;
                const keyFrame = frameCounter % 25 === 0;

                //console.log(`Frame TS: ${frame.timestamp}, Adjusted TS: ${adjustedTimestamp}, Pause duration: ${this.#totalPauseDuration}`);
                if (this.#videoEncoder) {
                    this.#videoEncoder.encode(newFrame, { keyFrame });
                }

                frame.close();
                newFrame.close();
            }
        }
        catch (error) {
            if (this.onError) {
                this.onError(error);
            }
        }
        finally {
            reader.releaseLock();
        }
    }

    async #processAudioFrames(reader: ReadableStreamDefaultReader<AudioData>) {

        try {
            while (this.#isRecording) {
                const { value: audioData, done } = await reader.read();

                if (done || !this.#isRecording) {
                    break;
                }

                // First frame sets the reference
                if (!this.#startTime) {
                    this.#startTime = audioData.timestamp;
                }

                // Adjust timestamp to account for paused duration
                let adjustedTimestamp = audioData.timestamp - this.#startTime - this.#totalPauseDuration;

                const newAudioData = await this.#cloneAudioData(audioData, adjustedTimestamp);

                if (!this.#isPaused && this.#audioEncoder) {
                    this.#audioEncoder.encode(audioData);
                }
                audioData.close();
                newAudioData.close();
            }
        }
        catch (error) {
            if (this.onError) {
                this.onError(error);
            }
        }
        finally {
            reader.releaseLock();
        }
    }

    async #cloneAudioData(original: AudioData, newTimestamp: number): Promise<AudioData> {
        const numberOfChannels = original.numberOfChannels;
        let totalSize = 0;
        const planeBuffers: ArrayBuffer[] = [];

        // Allocate and copy data from each plane
        for (let i = 0; i < numberOfChannels; i++) {
            const planeSize = original.allocationSize({ planeIndex: i });
            totalSize += planeSize;
            const buffer = new ArrayBuffer(planeSize);
            original.copyTo(buffer, { planeIndex: i });
            planeBuffers.push(buffer);
        }

        // Merge all plane buffers into a single ArrayBuffer
        const mergedBuffer = new ArrayBuffer(totalSize);
        const mergedView = new Uint8Array(mergedBuffer);
        let offset = 0;

        for (const buffer of planeBuffers) {
            mergedView.set(new Uint8Array(buffer), offset);
            offset += buffer.byteLength;
        }

        // Create new AudioData
        return new AudioData({
            format: original.format,
            sampleRate: original.sampleRate,
            numberOfChannels: numberOfChannels,
            timestamp: newTimestamp,
            numberOfFrames: original.numberOfFrames,
            data: mergedBuffer, // Now a single ArrayBuffer!
        });
    }

    #onError = (error) => {
        if (this.onError) {
            this.onError(error);
        }
    };

    #createVideoEncoder = (videoTrack: MediaStreamTrack, muxer: Muxer<ArrayBufferTarget>, options?: EncoderOptions) => {
        const settings = videoTrack.getSettings();

        // Create video encoder
        const encoder = new VideoEncoder({
            output: (chunk: EncodedVideoChunk, meta: EncodedVideoChunkMetadata) => muxer.addVideoChunk(chunk, meta),
            error: this.#onError
        });

        // Configure encoder
        encoder.configure({
            codec: options.videoCodec || "avc1.640033", //"avc1.424028",
            width: settings.width,
            height: settings.height,
            bitrate: options?.videoBitsPerSecond || 2_000_000,
            framerate: settings.frameRate || 25,
            latencyMode: "quality",
            avc: { format: "avc" }
        });
        return encoder;
    };

    #createAudioEncoder = (audioTrack: MediaStreamTrack, muxer: Muxer<ArrayBufferTarget>, options?: EncoderOptions) => {
        if (!audioTrack) {
            throw new Error("No audio track found");
        }

        const settings = audioTrack.getSettings();

        const encoder = new AudioEncoder({
            output: (chunk: EncodedAudioChunk, meta: EncodedAudioChunkMetadata) => muxer.addAudioChunk(chunk, meta),
            error: this.#onError
        });

        encoder.configure({
            codec: options?.audioCodec || "mp4a.40.2",
            sampleRate: 48_000, // settings.sampleRate
            numberOfChannels: settings.channelCount || 2,
            bitrate: options?.audioBitsPerSecond || 192_000
        });
        return encoder;
    };

    #createMuxer = (videoTrack: MediaStreamTrack, audioTrack?: MediaStreamTrack) : Muxer<ArrayBufferTarget> => {
        const videoSettings = videoTrack.getSettings();
        let audioSettings;

        if (audioTrack) {
            audioSettings = audioTrack.getSettings();
        }

        // 1. Create muxer configuration
        const configuration: MuxerOptions<ArrayBufferTarget> = {
            target: new ArrayBufferTarget(),
            video: {
                frameRate: videoSettings.frameRate,
                codec: "avc",
                width: videoSettings.width,
                height: videoSettings.height
            },
            audio: audioSettings ? {
                codec: "aac",
                sampleRate: audioSettings.sampleRate,
                numberOfChannels: audioSettings.channelCount || 2
            } : undefined,

            fastStart: "in-memory",
            firstTimestampBehavior: "offset"
        };

        return new Muxer(configuration);
    };

    #createEncoderOptions(options: MediaRecorderOptions) {
        let encoderOptions: EncoderOptions = {
            videoBitsPerSecond: options?.videoBitsPerSecond,
            audioBitsPerSecond: options?.audioBitsPerSecond
        };

        if (options?.mimeType) {
            const {container, videoCodec, audioCodec} = this.#parseMimeType(options.mimeType);
            encoderOptions = {
                ...encoderOptions,
                videoCodec,
                audioCodec
            };
        }
        return encoderOptions;
    }

    #parseMimeType = (mimeType: string): ParsedMimeType => {
        const mimeRegex = /^(video\/mp4);\s*codecs="([^"]+)"$/;
        const match = mimeType.match(mimeRegex);

        if (!match) {
            throw new Error("Invalid MIME type format or unsupported container");
        }

        const [, container, codecs] = match;
        const codecList = codecs.split(/,\s*/);

        let videoCodec: string | null = null;
        let audioCodec: string | null = null;

        for (const codec of codecList) {
            if (codec.startsWith("avc1")) {
                videoCodec = codec;
            }
            else if (codec.startsWith("mp4a")) {
                if (/mp4a\.40\.(2|5)/.test(codec)) { // Only allow AAC (LC or HE-AAC)
                    audioCodec = codec;
                }
            }
        }

        if (!videoCodec || !audioCodec) {
            throw new Error("Unsupported codecs. Only AVC for video and AAC for audio are allowed.");
        }

        return { container, videoCodec, audioCodec };
    };
}
