import { pendoPublicKey } from "../../common/publicKeys";

const usersToDisable = ["test-user@sundaysky.com"];

export type PendoAccountInfo = {
    id?: string;
    name?: string;
}

export type PendoVisitorInfo = {
    id: string,
    userType: string,
    role: string,
    email: string
}

export type PendoSessionInfo = {
    visitor: PendoVisitorInfo,
    account: PendoAccountInfo,
    disableGuides: boolean
}

export type PendoEventData = {[key: string]: string}

const escapeSavedWords = (eventData?: PendoEventData) => {
    if (eventData?.videoId) {
        const newEventData = { ...eventData, _videoId: eventData.videoId };
        delete newEventData["videoId"];
        return newEventData;
    }
    return eventData;
};

export class PendoService {
    private static instance?: PendoService;

    private readonly pendoObject;

    private pendoSessionInfo: PendoSessionInfo = {
        visitor: {
            id: "",
            userType: "",
            role: "",
            email: ""
        },
        account: {},
        disableGuides: false
    };

    private videoId = "";

    private constructor() {
        if (window.pendo) {
            this.pendoObject = window.pendo;
        }
    }

    /**
     * initUserData
     * @param  userId required identifier - user email
     * @param  userRoles user roles
     * @param accountInfo account info to be initialised
     */
    public initUserInfoIfRequired(userId: string, userRoles: string, accountInfo: PendoAccountInfo) {
        if (!this.isReady()) {
            this.updateAccountInfoInternally(accountInfo);
            this.updateVisitorInfoInternally({
                id: userId,
                userType: "Core Platform",
                role: userRoles,
                email:userId
            });
            //remove Pendo for users
            if (usersToDisable.includes(userId)) {
                this.pendoSessionInfo.disableGuides = true;
            }
            this.pendoObject?.initialize(this.pendoSessionInfo);
        }
    }

    /**
     * getInstance
     * Get single pendo service
     */
    public static getInstance() {
        if (!PendoService.instance) {
            PendoService.instance = new PendoService();
        }
        return PendoService.instance;
    }

    /**
     * stopTracking
     * Stop sending events of user actions to pendo
     */
    public stopTracking() {
        this.pendoObject?.stopSendingEvents();
    }

    /**
     * startTracking
     * Start sending events of user actions to pendo
     */
    public startTracking() {
        this.pendoObject?.startSendingEvents();
    }

    public showGuideById(id: string) {
        this.pendoObject?.showGuideById(id);
    }

    /**
     * updateInfo
     * Initializes pendo if not initialized yet
     * Sends update event with updated account information
     * @param  pendoPartialAccountInfo required account info for updating pendo object
     */
    public updateAccountInfoIfChanged(pendoPartialAccountInfo: Partial<PendoAccountInfo>) {
        if (this.pendoAccountNeedsUpdate(pendoPartialAccountInfo)) {
            this.updateAccountInfoInternally(pendoPartialAccountInfo);
            this.pendoObject?.updateOptions(this.pendoSessionInfo);
        }
    }

    /**
     * trackEvent
     * Sends track event with various information.
     * A track event can be sent with or without data.
     * @param  eventName required the name of the event - a new track event will be created for every eventName (in Pendo dashboard)
     * @param  eventData data to be passed in the form of key: value. Video context will be added to eventData.
     */
    public trackEvent(eventName: string, eventData?: PendoEventData) {
        eventData = escapeSavedWords(eventData);

        if (this.videoId) {
            eventData = eventData ? { ...eventData, videoId: this.videoId } : { videoId: this.videoId };
        }

        if (eventData) {
            this.pendoObject?.track(eventName, eventData);
        }
        else {
            this.pendoObject?.track(eventName);
        }
    }

    public updateVideoId(videoId: string) {
        this.videoId = videoId;
    }

    private updateAccountInfoInternally(pendoPartialAccountInfo: Partial<PendoAccountInfo>) {
        Object.keys(pendoPartialAccountInfo).map((key) => {
            if (pendoPartialAccountInfo.id !== undefined || pendoPartialAccountInfo.name !== undefined) {
                localStorage.removeItem(`_pendo_feedback_ping_sent.${pendoPublicKey}`);
            }
            if (pendoPartialAccountInfo[key] !== undefined) {
                this.pendoSessionInfo.account[key] = pendoPartialAccountInfo[key];
            }
        });
    }

    private updateVisitorInfoInternally(pendoVisitorInfo: Partial<PendoVisitorInfo>) {
        Object.keys(pendoVisitorInfo).map((key) => {
            if (pendoVisitorInfo[key] !== undefined) {
                this.pendoSessionInfo.visitor[key] = pendoVisitorInfo[key];
            }
        });
    }

    private pendoAccountNeedsUpdate(pendoPartialAccountInfo: Partial<PendoAccountInfo>): boolean {
        let needsUpdate = false;
        Object.keys(pendoPartialAccountInfo).map((key) => {
            if (pendoPartialAccountInfo[key] !== this.pendoSessionInfo?.account[key]) {
                needsUpdate = true;
            }
        });
        return needsUpdate;
    }

    private isReady() {
        return this.pendoObject?.isReady?.();
    }
}
