import { getApolloClient } from "../../../../apollo";
import type { GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { UserSettingsDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";


export const preloadUserSettings = async (userId: string) : Promise<void> => {
    await getApolloClient().query<GqlClientUserSettingsQuery, GqlClientUserSettingsQueryVariables>({
        query: UserSettingsDocument,
        variables: {
            id: userId
        }
    });
};


