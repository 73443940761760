import { getAspectRatio } from "../../../../../common/aspectRatioUtils";
import type { VideoAspectRatio } from "../../../../../common/external/videoSpec";
import { GqlClientEnumAspectRatio } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { RaasPlaceholderPosition, SceneId } from "../../types";
import type { RaasPositionable } from "../../Utils";
import type { RaasPlaceholder, Viewport } from "./loader";

export type RenderedPlaceholder = RaasPlaceholder & {
    id: SceneId;
}

export type LivePreviewState = {
    sceneId: SceneId,
    imageSrc: string | null | undefined,
    renderedPlaceholders: RenderedPlaceholder[],
    foregroundLayer: RaasPositionable | null;
    canvases: Record<string, RaasPositionable>;

    fallbackSceneId: SceneId,
    fallbackImageSrc: string | null | undefined,
    fallbackRenderedPlaceholders: RenderedPlaceholder[],
    fallbackForegroundLayer: RaasPositionable | null;
    fallbackCanvases: Record<string, RaasPositionable>;
    //represent latest successful live preview video aspect ratio
    videoAspectRatio: VideoAspectRatio | undefined,

    viewport: Viewport | null | undefined,
    fallbackViewport: Viewport | null | undefined;

    pending: boolean,
    error: any,

    renderStartTime: number | undefined,
    renderEndTime: number | undefined
}

export const livePreviewInitialState: LivePreviewState = {
    sceneId: undefined,
    imageSrc: undefined,
    renderedPlaceholders: [],
    foregroundLayer: null,
    canvases: {},

    fallbackSceneId: undefined,
    fallbackImageSrc: undefined,
    fallbackRenderedPlaceholders: [],
    fallbackForegroundLayer: null,
    fallbackCanvases: {},
    videoAspectRatio: undefined,

    viewport: undefined,
    fallbackViewport: undefined,

    pending: false,
    error: undefined,

    renderStartTime: undefined,
    renderEndTime: undefined
};

export enum LivePreviewActionType {
    Reset = "Reset",
    RenderStart = "RenderStart",
    RenderSuccess = "RenderSuccess",
    RenderError = "RenderError",
    UpdateFlexiblePosition = "UpdateFlexiblePosition"
}

type ResetActionPayload = undefined;

type RenderStartActionPayload = {
    sceneId: string,
};

type RenderSuccessActionPayload = {
    imageSrc: string;
    renderedPlaceholders: RenderedPlaceholder[];
    viewport: Viewport;
    foregroundLayer: RaasPositionable | null;
    canvases: Record<string, RaasPositionable>;
}

type RenderErrorActionPayload = {
    error: NonNullable<any>
};

type UpdateFlexiblePositionActionPayload = {
    placeholderId: string;
    position: RaasPlaceholderPosition;
}

type GenericAction<T, P> = { type: T, payload: P };

export type ResetAction = GenericAction<LivePreviewActionType.Reset, ResetActionPayload>;
export type RenderStartAction = GenericAction<LivePreviewActionType.RenderStart, RenderStartActionPayload>;
export type RenderSuccessAction = GenericAction<LivePreviewActionType.RenderSuccess, RenderSuccessActionPayload>;
export type RenderErrorAction = GenericAction<LivePreviewActionType.RenderError, RenderErrorActionPayload>;
export type RenderFlexiblePositionAction = GenericAction<LivePreviewActionType.UpdateFlexiblePosition, UpdateFlexiblePositionActionPayload>;

export type Action =
    ResetAction
    | RenderStartAction
    | RenderSuccessAction
    | RenderErrorAction
    | RenderFlexiblePositionAction
    ;

const getVideoAspectRatioForViewPort = (viewport: Viewport): VideoAspectRatio | undefined => {
    const aspectRatio = viewport.width / viewport.height;
    let ar: GqlClientEnumAspectRatio = GqlClientEnumAspectRatio.AR_16_9;
    switch (aspectRatio) {
        case 4 / 5:
            ar = GqlClientEnumAspectRatio.AR_4_5;
            break;
        case 9 / 16:
            ar = GqlClientEnumAspectRatio.AR_9_16;
            break;
        case 16 / 9:
            ar = GqlClientEnumAspectRatio.AR_16_9;
            break;
        default:
            ar = GqlClientEnumAspectRatio.AR_16_9;
    }
    return getAspectRatio(ar)?.videoRatio();
};

export const livePreviewReducer = (state: LivePreviewState, action: Action): LivePreviewState => {
    const now = new Date().getTime();

    switch (action.type) {
        case LivePreviewActionType.Reset:
            return livePreviewInitialState;

        case LivePreviewActionType.RenderStart:
            return {
                sceneId: action.payload.sceneId,

                imageSrc: undefined,
                renderedPlaceholders: state.renderedPlaceholders,
                foregroundLayer: state.foregroundLayer,
                canvases: state.canvases,

                fallbackSceneId: state.fallbackSceneId,
                fallbackImageSrc: state.fallbackImageSrc,
                fallbackRenderedPlaceholders: state.fallbackRenderedPlaceholders,
                fallbackForegroundLayer: state.fallbackForegroundLayer,
                fallbackCanvases: state.fallbackCanvases,
                videoAspectRatio: state.videoAspectRatio,

                viewport: undefined,
                fallbackViewport: state.fallbackViewport,

                pending: true,
                error: undefined,

                renderStartTime: now,
                renderEndTime: undefined
            };

        case LivePreviewActionType.RenderSuccess:
            return {
                sceneId: state.sceneId,

                imageSrc: action.payload.imageSrc,
                renderedPlaceholders: action.payload.renderedPlaceholders,
                foregroundLayer: action.payload.foregroundLayer,
                canvases: action.payload.canvases,

                fallbackSceneId: state.sceneId,
                fallbackImageSrc: action.payload.imageSrc,
                fallbackRenderedPlaceholders: action.payload.renderedPlaceholders,
                fallbackForegroundLayer: action.payload.foregroundLayer,
                fallbackCanvases: action.payload.canvases,
                videoAspectRatio: getVideoAspectRatioForViewPort(action.payload.viewport),

                viewport: action.payload.viewport,
                fallbackViewport: action.payload.viewport,

                pending: false,
                error: null,

                renderStartTime: state.renderStartTime,
                renderEndTime: now
            };

        case LivePreviewActionType.RenderError:
            return {
                sceneId: state.sceneId,

                imageSrc: null,
                renderedPlaceholders: [],
                foregroundLayer: null,
                canvases: {},

                fallbackSceneId: state.fallbackSceneId,
                fallbackImageSrc: state.fallbackImageSrc,
                fallbackRenderedPlaceholders: state.fallbackRenderedPlaceholders,
                fallbackForegroundLayer: state.fallbackForegroundLayer,
                fallbackCanvases: state.fallbackCanvases,
                videoAspectRatio: state.videoAspectRatio,

                viewport: null,
                fallbackViewport: state.fallbackViewport,

                pending: false,
                error: action.payload.error,

                renderStartTime: state.renderStartTime,
                renderEndTime: now
            };

        case LivePreviewActionType.UpdateFlexiblePosition: {
            const renderedPlaceholders = state.renderedPlaceholders.map(
                (placeholder) => placeholder.id === action.payload.placeholderId
                    ? { ...placeholder, position: action.payload.position, rawPosition: action.payload.position }
                    : placeholder
            );
            return {
                ...state,
                renderedPlaceholders,
                fallbackRenderedPlaceholders: renderedPlaceholders
            };
        }

        default:
            return state;
    }
};
