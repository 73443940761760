import { useCallback, useRef, useState } from "react";

export type UseComponentSize = {
    width: number,
    height: number,
    refCallback: (element: HTMLElement) => void
};

export const useComponentSize = (): UseComponentSize => {
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    const resizeObserver = useRef<ResizeObserver>(new ResizeObserver((entries) => {
        const rect = entries[0].target.getBoundingClientRect();
        setWidth(rect.width);
        setHeight(rect.height);
    }));

    const refCallback = useCallback(element => {
        if (element !== null) {
            resizeObserver.current.observe(element);
        }
        else {
            resizeObserver.current.disconnect();
        }
    }, []);

    return { width, height, refCallback };
};
