import type {
    GqlClientAccountDataFieldFragment,
    GqlClientAccountInvitationStatus,
    GqlClientAiAspectRatio,
    GqlClientAiImageService,
    GqlClientBcColor,
    GqlClientBrandConfigurationCollection,
    GqlClientBusinessRole,
    GqlClientButtonShapeValues,
    GqlClientCcAnimatedWireframeVersionPlaceholdersSettings,
    GqlClientCcDof,
    GqlClientCcFont,
    GqlClientCcTextStyle,
    GqlClientCcTheme,
    GqlClientDerivedDataElement,
    GqlClientEditorAssetEditedPayloadError,
    GqlClientEditorAssetEditedPayloadSuccess,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientEditorBrandFragment,
    GqlClientEditorLibraryDofFragment,
    GqlClientEditorLibraryWireframeFragment,
    GqlClientEditorPlaceholder,
    GqlClientEditorPlaceholderFragment,
    GqlClientEditorProgram,
    GqlClientEditorProgramShareHistoryFragment,
    GqlClientEditorProgramVersion,
    GqlClientEditorScene,
    GqlClientEditorSceneFragment,
    GqlClientEditorViewerProfileFragment,
    GqlClientEditorWireframePlaceholderFragment,
    GqlClientExternalLibrary,
    GqlClientImageCroppingData,
    GqlClientMediaOrigin,
    GqlClientPostMediaProcessingData,
    GqlClientStatus,
    GqlClientStudioElementFragment,
    GqlClientTextStyleLetterCase,
    GqlClientUserManagementRole,
    GqlClientUserRole,
    GqlClientVideoProcessingData
} from "../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { CreateStudioElementsFromCsvResult, NotificationText, UpdateDataElementsInput } from "./Nooks";
import type { File as ProgressDialogFile } from "../legacyCommon/FilesProgressDialog";
import type { EditorPublishRecordingErrorData } from "../../../common/types/publishTypes";
import type { ConnectorType } from "@sundaysky/customer-data-common-goblin-ds";
import type { VideoStatus } from "./Components/OverviewPage/OverviewProgramDetails";
import type { DamAsset, DamFolder, ItemType, MediaFolder, StockAsset, YourLibraryAsset } from "./Components/TruffleEditingWorkSpace/Components/Libraries/Components/TruffleMediaLibrary/common";
import type { EditorLibraryMediaType } from "../../../common/types/asset";
import type { MediaStreamType } from "./Hooks";

export type VideoSpec = {
    projectid: string;
    requestid: string;
    lineup: NonNullable<VideoSpecScene>[];
    output: NonNullable<any>;
    [propName: string]: any;
}

export type VideoSpecScene = {
    name: string;
    animation: {
        href: string; // path to vsb file
    };
    data: {
        Narrations?: {
            [narrationName: string]: {
                source: string; // path to recording file
                transcript: string;
            };
        };
        isFirstScene: "true" | "false";
        isLastScene: "true" | "false";
        hasMediaBackground: "true" | "false";
        buttonShape: ButtonShape;
        [propName: string]: any;
    };
    transition: {
        duration: number;
        style: "OVERLAY" | "UNDERLAY";
    };
}

export type StandardSetterFn<T> = (value: T) => void;

type ID = { id: string };

type EntityId<T extends ID> = T["id"];

export type WithId<T> = T & ID;

export type ProcessedFile = ProgressDialogFile & {
    info?: string;
};

export type MultiSelectOption = {
    id: string;
    label: string;
    checked: boolean;
    disabled?: boolean;
}

export type BackgroundMutationBasePayload = {
    status: GqlClientStatus;
    mutationId: string;
}

export interface BackgroundMutationPayloadResult<T extends BackgroundMutationBasePayload> {
    data: {
        [key: string]: T
    }
}

export type BackgroundMutationCallbackFn<T extends BackgroundMutationBasePayload> = (data: T) => void;

export type BackgroundMutationCallbacks<T extends BackgroundMutationBasePayload> = {
    onSuccess: BackgroundMutationCallbackFn<T>;
    onFailure: BackgroundMutationCallbackFn<T>;
    onProgress: BackgroundMutationCallbackFn<T>;
}

export type BackgroundMutation<T extends BackgroundMutationBasePayload> = {
    callbacks: BackgroundMutationCallbacks<T> ;
    subscription: any;
}

export enum PreviewDialogType {
    Video = "video",
    Scene = "scene"
}

export enum BooleanType {
    True = "true",
    False = "false"
}
export type UploadMediaNonLibraryAssetData = {
    mediaUrl: string;
};

export type UploadMediaAssetType = UploadMediaNonLibraryAssetData | UploadMediaAssetData;

export type UploadMediaAssetData = Pick<GqlClientEditorAssetLibraryMediaFragment, "id" | "mediaLocation" | "mediaUrl" | "mediaAgifUrl" | "mediaMetadata">;
export type UploadDataFeedFileResult = {
    isValid: boolean
    fileName: string
    isMissingIdColumn: boolean
    isMissingDataRows: boolean
    missingIdValueRows: number[]
    emptyColumns: string[]
    nonUniqueColumns: string[]

};
export type UploadResult = UploadMediaNonLibraryAssetData | UploadMediaAssetData | CreateStudioElementsFromCsvResult | UpdateDataElementsInput | UploadDataFeedFileResult | FileToVideoResult;
export type UploadResponse = Promise<{ uploadResult?: UploadResult, error?: Error }>;
export type UploadResponseAsync = Promise<void>
export type ProcessFileInputResponse = Promise<{ error?: Error; fileData?: { "fileType": string } }>;

export type LegacyAssetEditMetadata = {
    parentMediaId: GqlClientEditorAssetLibraryMediaFragment["id"];
    processingData: GqlClientVideoProcessingData;
    postEditingData?: GqlClientPostMediaProcessingData;
    presentedMediaName: string;
}

export type AssetEditMetadata = {
    parentMediaId: GqlClientEditorAssetLibraryMediaFragment["id"];
    processingData: GqlClientImageCroppingData;
    enhancedProcessingData?: string;
    postUploadingData?: GqlClientPostMediaProcessingData;
    saveEditedMediaToLibrary?: boolean;
}

export type UploadFileFn = (
    file: File,
    programId?: ProgramId,
    metadata?: AssetEditMetadata,
    parentFolderId?: string,
    overrideSizeLimits?: boolean,
    mediaOrigin?: GqlClientMediaOrigin
) => UploadResponse;
export type UploadFileFnAsync = (
    file: File,
    programId: ProgramId,
    onSuccess?: (fileName: string, fileType: string, uploadResult: UploadResult) => void,
    onError?: (name: string, error?: Error) => void,
    metadata?: AssetEditMetadata,
    parentFolderId?: string
) => UploadResponseAsync;
export type OnFileEditSuccessFn = (payload: GqlClientEditorAssetEditedPayloadSuccess) => void;
export type OnFileEditErrorFn = (payload: GqlClientEditorAssetEditedPayloadError) => void;

export type HelpCenterData = {
    articleHistory: {
        articleId: string;
        sectionId?: string;
    }[];
    anchorPosition: {
        left: number;
        top: number;
    };
}

export type EditHelpCenterData = {
    buttonId: string;
    variants: Record<string, string>;
    selectedVariant: string | null;
    anchorEl: HTMLElement;
}

export type ColorName = {
    localId: GqlClientCcDof["localId"];
    name: GqlClientCcDof["name"];
    displayName: GqlClientCcDof["displayName"];
};

export type ColorData = {
    id: GqlClientCcDof["id"];
    localId: GqlClientCcDof["localId"];
    name: GqlClientCcDof["name"];
    displayName: GqlClientCcDof["displayName"];
    color: GqlClientBcColor["color"];
}

export type ColorValues = Record<GqlClientCcDof["localId"], ColorData>;

export type ButtonShape = GqlClientButtonShapeValues;

export type LivePreviewPendingSet = Set<string>;

export type Lineup = GqlClientEditorProgramVersion["sceneLineup"];

export type ProgramId = EntityId<GqlClientEditorProgram>;

export type ProgramName = GqlClientEditorProgram["name"];

export type EditorDataConnectorEnabled = GqlClientEditorProgramVersion["editorDataConnectorEnabled"];

export type ProgramVersionId = EntityId<GqlClientEditorProgramVersion>;

export type MusicId = EntityId<GqlClientEditorProgramVersion["selectedCcMusic"]> | EntityId<GqlClientEditorProgramVersion["selectedAccountMusic"]>;

export type NarratorId = EntityId<GqlClientEditorProgramVersion["selectedNarrator"]>;

export type PlaceholderId = EntityId<GqlClientEditorPlaceholder>;

export type SceneId = EntityId<GqlClientEditorScene>;

export type ThemeId = EntityId<GqlClientCcTheme>;

export type WireframeId = EntityId<GqlClientEditorLibraryWireframeFragment>;

export type BrandId = EntityId<GqlClientEditorBrandFragment>;

export type LogoUrl = GqlClientBrandConfigurationCollection["logoUrl"];

export type LogoLocation = GqlClientBrandConfigurationCollection["logoLocation"];

export type LogoAssetInfo = { logoLocation: LogoLocation, logoMediaId?: GqlClientEditorAssetLibraryMediaFragment["id"] };

export type TextStyleId = EntityId<GqlClientCcTextStyle>;

export type RaasPlaceholderPosition = EmptyObject | {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type RaasPlaceholderData = {
    position: RaasPlaceholderPosition;
};

// start of viewer profile section
export type ViewerProfileItemValue = string | StudioValueSetItem;
export type StudioValueSetItem = { id: string, value: string };

export type ViewerProfileDataElementValues = Record<GqlClientDerivedDataElement["localId"], ViewerProfileItemValue>;

export type ExtendedViewerProfileItem = {
    id: GqlClientDerivedDataElement["localId"],
    name: GqlClientDerivedDataElement["name"],
    dataType: GqlClientDerivedDataElement["dataType"],
    valueSet: GqlClientDerivedDataElement["valueSet"],
    value: ViewerProfileItemValue
}

export type ViewerProfileContent = {
    version: number,
    items: ViewerProfileDataElementValues
}

export type EditedViewerProfile = {
    editedViewerProfileValues: ViewerProfileDataElementValues;
    editedViewerProfileId?: GqlClientEditorViewerProfileFragment["id"];
    editedViewerProfileName?: GqlClientEditorViewerProfileFragment["name"];
}

// end of viewer profile section

export type LocalStateTextStyle = WithId<Partial<{
    textStyleId: TextStyleId;
    fontId: EntityId<GqlClientCcFont>;
    lineSpacing: number;
    letterSpacing: number;
    letterCase: GqlClientTextStyleLetterCase;
}>>;

export type LivePreviewScenePlaceholderData =
    Pick<GqlClientEditorPlaceholderFragment, "placeholderSettings" | "id" | "placeholderContent" | "parentType" | "parentPlaceholder" | "flexibleSettings" >
    & { ccPlaceholder: GqlClientEditorWireframePlaceholderFragment }
export type LivePreviewSceneData =
    Pick<GqlClientEditorSceneFragment, "id" | "name" | "colorCombination" | "skipSceneMode" | "buttonAlignment" | "ccAnimatedWireframe">
    & { placeholders: Array<LivePreviewScenePlaceholderData> }

export type EmptyObject = {
    [K in any]: never
}

export type EditorPermissions = EmptyObject | {
    canCreateEditorProgram: boolean;
    canEditEditorProgram: boolean;
    canOnlyViewProgram: boolean;
    canEditViewerProfiles: boolean;
    canGenerateVideoLinks: boolean;
    canGenerateVideoLinksProd: boolean;
    canEditLandingPageBuilderApiGateway: boolean;
    canEditVideo: boolean;
    canUpdateCustomConnector: boolean;
};

export type NotificationData = {
    type: "success" | "error" | "neutral" | "white" | "info";
    message: NotificationText;
    icon?: JSX.Element | false;
    showCloseButton?: boolean;
    blockUserActions?: boolean;
    actionButton?: {
        label: string;
        onClick: () => void;
        dataTestId?: string;
        dataTrackingId?: string;
    };
    duration?: number | null;
    isOpen: boolean;
    position?: "left" | "center" | "right";
    noWrap?: boolean;
};

export type RestrictedActionDialogData = {
    messageTitle: string;
    message: string;
}

export type StudioElementExtended = GqlClientStudioElementFragment & { origin?: StudioElementOrigin, order?: number, sourceName?: string, pii?: boolean };

export enum StudioElementOrigin {
    Program = "program",
    System = "system"
}

export interface ExternalLibraryDetails {
    acceptedUsage: boolean;
    dismissedChangeMessageInAccounts?: string[];
}

export type ExternalLibrarySettings = {
    [libraryId in GqlClientExternalLibrary]: ExternalLibraryDetails
};

export type ManagedUserMinimalData = {
    id: string,
    firstName?: string,
    lastName?: string,
}

export type ManagedUser = ManagedUserMinimalData & {
    email: string,
    businessRole?: GqlClientBusinessRole,
    userRoles: GqlClientUserRole[],
    hasMultipleAccounts: boolean,
    accountRoles: GqlClientUserManagementRole[],
    isRegistered: boolean,
    lastLogin?: string | null,
    created: string | null,
    accountInvitationStatus: GqlClientAccountInvitationStatus
}

export type MusicSearchParams = {
    searchTerm: string;
    tagID: string;
    page: number;
};


export type AnimatedWireframePlaceholderDefaultsSettings = Pick<GqlClientCcAnimatedWireframeVersionPlaceholdersSettings, "id" | "horizontalAlignment" | "verticalAlignment"> &
    { colorCcDof?: Pick<GqlClientEditorLibraryDofFragment, "localId"> };

export enum Libraries {
    BrandsPanel = "BrandsPanel",
    MusicPanel = "MusicPanel",
    NarratorsPanel = "NarratorsPanel",
    DataPanel = "DataPanel",
    AspectRatio = "AspectRatio",
    ThemePanel = "ThemePanel",
    TruffleMediaLibrary = "TruffleMediaLibrary",
    AiGenerator = "AiGenerator",
    AvatarPanel = "AvatarPanel"
}

export enum LibraryOpener {
    Default = "Default",
    LibraryIcon = "LibraryIcon",
    LibraryMenu = "LibraryMenu",
    MediaPlaceholderConfiguration = "MediaPlaceholderConfiguration",
    NarrationPlaceholderConfiguration = "NarrationPlaceholderConfiguration",
    NoDataLibrary = "NoDataLibrary",
    AvatarPlaceholderConfiguration = "AvatarPlaceholderConfiguration",
    EditorContainer = "EditorContainer",
    VideoOverviewPage = "VideoOverviewPage"
}

export type SelectedLibrary = {
    library: Libraries;
    opener: LibraryOpener;
}

export enum MediaLibraryActiveTab {
    YourLibrary = "YourLibrary",
    Stock = "Stock",
    Dam = "Dam"
}

export type SuccessPublishState = {
    publishStatus: GqlClientStatus.SUCCESS|GqlClientStatus.PARTIAL_SUCCESS;
}
export type PendingPublishState = {
    publishStatus: GqlClientStatus.PENDING;
    progress: number;
}
export type ErrorPublishState = {
    publishStatus: GqlClientStatus.ERROR,
    publishError?: EditorPublishRecordingErrorData[];
}

export type PublishState = SuccessPublishState|PendingPublishState|ErrorPublishState;

export type FileToVideoResult = {
    videoId: string;
}

export type DataLibraryDynamicElement = GqlClientAccountDataFieldFragment;

export type VideoInformation = {
    __typename?: string;
    name?: string;
    id?: string;
    isArchive?: boolean;
    isLive?: boolean;
    programVersionDraft?: {
        id?: string;
        aspectRatio?: string;
        thumbnail?: {
            url?: string;
        }
    }
    prodVersion?: {
        id?: string;
        created?:string;
        editorProgramVersion?: {
            thumbnail?: {
                url?: string;
            }
        }
    }
    uatVersion?: {
        id?: string;
        created?: string;
    }
    shareHistory?: Array<GqlClientEditorProgramShareHistoryFragment>
};

export type AccountDataLibrary = {
    draftId: string;
    type: ConnectorType;
    name: string;
    description: string;
    dataFieldNames: string[];
    usedInVideos: VideoInformation[];
}

export type FullAccountDataLibrary = {
    id: string;
    name: string;
    usedInVideosWithPersonalization: VideoInformation[];
    usedInVideos: VideoInformation[];
}

export type VideoInformationWithStatus = VideoInformation & {
    status?: VideoStatus;
};

export type VideoInformationWithUsedInData = VideoInformationWithStatus & {
    scenesAndBrandInfo: Set<string>;
    narrationPlaceholders: Set<string>;
};

export type DataLibrariesUsedInVideosData = {
    dataLibraryName: string;
    videos: VideoInformationWithStatus[];
}

export type MediaPlaceholderContentTypes = {
    image: boolean;
    video: boolean;
}

export enum PlaceholderToolIntent {
    Logo = "logo",
    Media = "media",
    Text = "text",
    Button = "button",
    Avatar = "avatar"
}

export type UseActiveBrandLogo = {
    logoMediaId: GqlClientEditorAssetLibraryMediaFragment["id"];
    logoUrl: string;
    logoPreviewUrl: string;
    logoName: string;
    logoOrigin: GqlClientMediaOrigin
};

export enum MediaLibrarySortBy {
    LAST_ADDED = "LAST_ADDED",
    A_Z = "A_Z"
}

export enum MediaLibrarySortDirection {
    TOP_TO_BOTTOM = "TOP_TO_BOTTOM",
    BOTTOM_TO_TOP = "BOTTOM_TO_TOP"
}

export enum MediaLibraryFilterMediaType {
    ALL = "ALL",
    IMAGES = "IMAGES",
    IMAGES_AND_ANIMATIONS = "IMAGES_AND_ANIMATIONS",
    VIDEOS = "VIDEOS"
}

export enum MediaLibraryFilterMediaSource {
    ALL = "ALL",
    USER = "USER",
    GETTY = "GETTY",
    AI_IMAGE = "AI_IMAGE",
    ACQUIA_DAM = "ACQUIA_DAM"
}

export enum MediaLibraryFilterMediaOrientation {
    ALL = "ALL",
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
    SQUARE = "square",
}

export type DistributiveOmit<T, U> = T extends any
    ? Pick<T, Exclude<keyof T, U>>
    : never

export enum UploadedGettyUrlTarget {
    LOGO = "logo",
    PLACEHOLDER = "ph",
    LANDING_PAGE_POSTER_IMAGE = "lpb_poster",
    LANDING_PAGE_LOGO = "lpb_logo",
    VIDEO_CUSTOM_BACKGROUND = "video_custom_background"
}
export type UploadedGettyUrl = ({
    target: UploadedGettyUrlTarget.LOGO | UploadedGettyUrlTarget.LANDING_PAGE_POSTER_IMAGE | UploadedGettyUrlTarget.LANDING_PAGE_LOGO | UploadedGettyUrlTarget.VIDEO_CUSTOM_BACKGROUND;
} | {
    target: UploadedGettyUrlTarget.PLACEHOLDER;
    placeholderId: string;
    byAudienceCaseIndex: number | null;
}) & {
    mediaUrl: string;
    thumbnailUrl: string;
    assetId: string;
}

export type EditorProgramVersionPersonalizationInfo = {
    isPersonalized: boolean;
    isPersonalizedWithNonSystemFields: boolean;
    usedSourceEntityIdsWithoutSystemFields: Array<string>;
    connectorType: ConnectorType;
    // Connector missing means that we have at least one non-system field in the video, but the connector is not configured.
    isConnectorMissing: boolean;
}

export enum EditorMediaDragAndDropSnackbarState {
    CLOSED = "CLOSED",
    INFO = "INFO",
    ERROR = "ERROR"
}

export type YourLibraryAssetData = Pick<YourLibraryAsset, "type" | "id" | "mediaLocation" | "mediaUrl" | "mediaAgifUrl">;
export type MediaFolderData = Pick<MediaFolder, "id" | "type">;
export type StockAssetData = Pick<StockAsset, "type" | "id" | "name" | "downloadSizes" | "mediaType" | "mediaUrl" | "thumbnailUrl">;
export type DamAssetData = Pick<DamAsset, "type" | "id" | "name" | "downloadUrl" | "externalLibrary" | "mediaUrl" | "thumbnailUrl" | "mediaType" >;
export type DamFolderData = Pick<DamFolder, "type" | "id">;

export type AiImageAsset = {
    type: ItemType.AI_IMAGE;
    id: string;
    name: string;
    mediaType: EditorLibraryMediaType.Image;
    mediaUrl: string;
    thumbnailUrl: string;
    service: GqlClientAiImageService;
    prompt: string;
    duration?: number;
}

export type AiVideoAsset = {
    type: ItemType.AI_VIDEO;
    id: string;
    name: string;
    mediaType: EditorLibraryMediaType.Video;
    mediaUrl: string;
    thumbnailUrl: string;
    service: GqlClientAiImageService;
    prompt: string;
    duration?: number;
}

export type ItemData = YourLibraryAssetData | MediaFolderData | StockAssetData | DamAssetData | DamFolderData| AiImageAsset ;

export type GqlMediaById = Record<string, GqlClientEditorAssetLibraryMediaFragment>;

export type AssetGenerationDialogInfo = {
    isDialogOpen: boolean,
    onDialogConfirmation: (asset: AiImageAsset) => void,
    aspectRatioForGeneratedAssets: GqlClientAiAspectRatio
}

export enum UploadOrigin {
    COMPUTER_DRAG_AND_DROP = "computer drag and drop",
    COMPUTER_BROWSE = "computer browse",
    STOCK_THROUGH_USAGE = "stock through usage",
    AI_IMAGE_THROUGH_USAGE = "ai image generator use",
    STOCK_3_DOTS_MENU = "stock 3 dots menu",
    AI_IMAGE_GENERATOR_ADD = "ai image generator add",
    ACQUIA_USE = "acquia use",
    ACQUIA_ADD = "acquia add",
    SUNDAYSKY_RECORDER = "sundaysky recorder",
    SAVE_AS = "save as",
}

export type EditRecordingToolDialogState = {
    open: boolean;
    mediaStreamType: MediaStreamType | null;
}

export type RecordingToolPreviewData = {
    assetUrl: string;
    progress: number;
    mutationId: string;
}

export enum RecordingState {
    NO_SESSION = "no session",
    SESSION_STARTED = "session started",
    RECORDING_STARTED = "recording started",
    RECORDING_ENDED = "recording ended",
    SESSION_ENDED = "session ended"
}

export type RecordingToolPermissionDeniedType = "camera" | "microphone" | "cameraAndMicrophone"
