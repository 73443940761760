import type { GqlClientEditorLibraryFontFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { FontsManager, FontVariations, WithFontMetadata } from "../../../../../common/fontsUtils";
import { cCFontsManagerVar } from "../../State";
import { useReactiveVar } from "@apollo/client";

export type UseLibraryFonts = {
    libraryFonts: GqlClientEditorLibraryFontFragment[];
};

export const useLibraryFonts = (): UseLibraryFonts => {
    const fontManager = useReactiveVar<FontsManager<GqlClientEditorLibraryFontFragment>>(cCFontsManagerVar);
    const libraryFonts = fontManager?.getFonts() ?? [];
    return { libraryFonts };
};

export type UseLibraryFontForStyling = WithFontMetadata<GqlClientEditorLibraryFontFragment> & {
    fontVariations: Required<FontVariations<WithFontMetadata<GqlClientEditorLibraryFontFragment>>>
    isFontSupportStyling: boolean;
    isFontDoNotSupportStyling: boolean;
    supportsBold: boolean;
    supportsItalic: boolean;
    supportsBoldItalic: boolean;
    partiallySupportStylingOnly: boolean;
};

export const useLibraryFontForStyling = (fontId: string): UseLibraryFontForStyling => {
    const fontManager = useReactiveVar<FontsManager<GqlClientEditorLibraryFontFragment>>(cCFontsManagerVar);
    return {
        ...fontManager.getFont(fontId),
        fontVariations: fontManager.getFontVariationsForFormatting(fontId),
        isFontSupportStyling: fontManager.isFontFullySupportStyling(fontId),
        isFontDoNotSupportStyling: fontManager.isFontDoNotSupportStyling(fontId),
        supportsBold: fontManager.doesFontSupportBold(fontId),
        supportsItalic: fontManager.doesFontSupportItalic(fontId),
        supportsBoldItalic: fontManager.doesFontSupportBoldItalic(fontId),
        partiallySupportStylingOnly: fontManager.isFontPartiallySupportStylingOnly(fontId)
    };
};

export type UseLibraryFontsManager = {
    ccFontsManager: FontsManager<GqlClientEditorLibraryFontFragment>
}

export const useLibraryFontsManager = (): UseLibraryFontsManager => {
    return {
        ccFontsManager: useReactiveVar<FontsManager<GqlClientEditorLibraryFontFragment>>(cCFontsManagerVar)
    };
};
