import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { useUserSettingsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { setMediaLibraryGridColumns } from "../../Nooks";
import { localEditorMediaLibraryGridColumns, localEditorUserNameVar } from "../../State";

export function useMediaLibraryGridColumns(): number {
    const { data } = useUserSettingsQuery({ variables: { id: localEditorUserNameVar() } });
    const mediaLibraryGridColumns = useReactiveVar(localEditorMediaLibraryGridColumns);

    useEffect(
        () => {
            if (data?.userSettings?.mediaLibraryGridColumns) {
                setMediaLibraryGridColumns(data.userSettings.mediaLibraryGridColumns);
            }
        },
        [data?.userSettings?.mediaLibraryGridColumns]
    );

    return mediaLibraryGridColumns;
}
