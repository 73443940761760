import type { PlaceholderId } from "../../types";
import type { ButtonPlaceholderSetters, MediaPlaceholderSetters, NarrationPlaceholderSetters, SharedSetters, TextPlaceholderSetters } from "../../Nooks";
import { getPlaceholder, getPlaceholderSetters } from "../../Nooks";
import type { GqlClientEditorPlaceholderFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useLocalEditorPlaceholderQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UsePlaceholder = {
    placeholder: GqlClientEditorPlaceholderFragment | null;
    textPlaceholderSetters: TextPlaceholderSetters;
    mediaPlaceholderSetters: MediaPlaceholderSetters;
    narrationPlaceholderSetters: NarrationPlaceholderSetters;
    buttonPlaceholderSetters: ButtonPlaceholderSetters;
    sharedSetters: SharedSetters;
};

export const usePlaceholder = (placeholderId: PlaceholderId): UsePlaceholder => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    // We need to call in in order to make sure we get changes of the ph in the cache
    useLocalEditorPlaceholderQuery({ variables: { placeholderId }, skip: placeholderId === null });
    const {
        sharedSetters,
        textPlaceholderSetters,
        mediaPlaceholderSetters,
        narrationPlaceholderSetters,
        buttonPlaceholderSetters
    } = getPlaceholderSetters(programId, programVersionId, placeholderId);

    return {
        placeholder: getPlaceholder(placeholderId),
        textPlaceholderSetters,
        mediaPlaceholderSetters,
        narrationPlaceholderSetters,
        buttonPlaceholderSetters,
        sharedSetters
    };
};
