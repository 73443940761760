import type { FetchResult } from "@apollo/client";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreateEditorAvatarInput,
    GqlClientCreateEditorAvatarMutation,
    GqlClientCreateEditorAvatarMutationVariables,
    GqlClientCreateEditorOverlayPlaceholderInput,
    GqlClientCreateEditorOverlayPlaceholderMutation,
    GqlClientCreateEditorOverlayPlaceholderMutationVariables,
    GqlClientDeleteEditorFlexiblePlaceholderMutation,
    GqlClientDeleteEditorFlexiblePlaceholderMutationVariables,
    GqlClientEditorPlaceholderFragment,
    GqlClientLocalEditorPlaceholderQuery,
    GqlClientLocalEditorPlaceholderQueryVariables,
    GqlClientUpdateEditorAvatarPlaceholderInput,
    GqlClientUpdateEditorAvatarPlaceholderMutation,
    GqlClientUpdateEditorAvatarPlaceholderMutationVariables,
    GqlClientUpdateEditorPlaceholderInput,
    GqlClientUpdateEditorPlaceholderMutation,
    GqlClientUpdateEditorPlaceholderMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateEditorAvatarDocument,
    CreateEditorOverlayPlaceholderDocument,
    DeleteEditorFlexiblePlaceholderDocument,
    GqlClientUpdateEditorPlaceholderResult,
    LocalEditorPlaceholderDocument,
    UpdateEditorAvatarPlaceholderDocument,
    UpdateEditorPlaceholderDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { IGNORE_UPDATED } from "../../../../logic/common/Consts";
import { PendoService } from "../../../pendoService";
import { localEditorRaasPlaceholderData } from "../../State";
import type { PlaceholderId, RaasPlaceholderData } from "../../types";
import { setIsSelectedSceneIsNew, setSelectedPlaceholderId } from "../Project";

export const getRaasPlaceholderData = (placeholderId: PlaceholderId): RaasPlaceholderData => {
    const defaultRaasPlaceholderData: RaasPlaceholderData = { position: {} };

    return localEditorRaasPlaceholderData()[placeholderId] || defaultRaasPlaceholderData;
};

export const setRaasPlaceholderData = (placeholderId: PlaceholderId, data: RaasPlaceholderData): void => {
    localEditorRaasPlaceholderData({
        ...localEditorRaasPlaceholderData(),
        [placeholderId]: {
            ...getRaasPlaceholderData(placeholderId),
            ...data
        }
    });
};

export const getPlaceholder = (placeholderId: PlaceholderId): GqlClientEditorPlaceholderFragment | null => {
    if (!placeholderId) {
        return null;
    }

    // We use here optimistic: true, since we use optimistic response and we want to get the optimistic data, without waiting for the
    // actual result from server to be reflected in cache.
    // There are in the cache two origins for data, optimised and actual.
    // In case there is no optimistic data we get the actual data that we have updated in the cache.
    const cachedQuery = getApolloClient().cache.readQuery<GqlClientLocalEditorPlaceholderQuery, GqlClientLocalEditorPlaceholderQueryVariables>({
        query: LocalEditorPlaceholderDocument,
        variables: { placeholderId },
        optimistic: true
    });
    return cachedQuery.localEditorPlaceholder;
};

export const updatePlaceholder = async (updatePlaceholderInput: Omit<GqlClientUpdateEditorPlaceholderInput, "updated">): Promise<void> => {
    try {
        const client = getApolloClient();
        const currentPlaceholder = getPlaceholder(updatePlaceholderInput.id);
        await client.mutate<GqlClientUpdateEditorPlaceholderMutation, GqlClientUpdateEditorPlaceholderMutationVariables>({
            mutation: UpdateEditorPlaceholderDocument,
            variables: {
                updateEditorPlaceholderInput: {
                    id: updatePlaceholderInput.id,
                    placeholderContent: updatePlaceholderInput.placeholderContent,
                    placeholderSettings: updatePlaceholderInput.placeholderSettings,
                    flexibleSettings: updatePlaceholderInput.flexibleSettings
                        ? {
                            top: updatePlaceholderInput.flexibleSettings.top,
                            left: updatePlaceholderInput.flexibleSettings.left,
                            width: updatePlaceholderInput.flexibleSettings.width,
                            height: updatePlaceholderInput.flexibleSettings.height
                        }
                        : null,
                    updated: IGNORE_UPDATED
                }
            },
            optimisticResponse: {
                __typename: "Mutation",
                updateEditorPlaceholder: {
                    __typename: "UpdateEditorPlaceholderOutputSuccess",
                    result: GqlClientUpdateEditorPlaceholderResult.SUCCESS,
                    editorPlaceholder: {
                        __typename: "EditorPlaceholder",
                        ...currentPlaceholder,
                        ...updatePlaceholderInput
                    }
                }
            }
        });
    }
    catch (e) {
        // ignore
    }
};

export const selectPlaceholder = (placeholderId: PlaceholderId) => {
    setIsSelectedSceneIsNew(false);
    setSelectedPlaceholderId(placeholderId);
};

export function createAvatar(input: GqlClientCreateEditorAvatarInput): Promise<FetchResult<GqlClientCreateEditorAvatarMutation>> {
    const client = getApolloClient();
    return client.mutate<
        GqlClientCreateEditorAvatarMutation,
        GqlClientCreateEditorAvatarMutationVariables
    >({
        mutation: CreateEditorAvatarDocument,
        variables: {
            input
        }
    });
}

export function createOverlayPlaceholder(
    input: GqlClientCreateEditorOverlayPlaceholderInput
): Promise<FetchResult<GqlClientCreateEditorOverlayPlaceholderMutation>> {
    const client = getApolloClient();
    return client.mutate<
        GqlClientCreateEditorOverlayPlaceholderMutation,
        GqlClientCreateEditorOverlayPlaceholderMutationVariables
    >({
        mutation: CreateEditorOverlayPlaceholderDocument,
        variables: {
            input
        }
    });
}

export async function deleteFlexiblePlaceholder(
    placeholderId: string,
    trackingLayoutId: string,
    trackingLayoutName: string,
    trackingPhIntentName: string,
    trackingTrigger: "Toolbar" | "Keyboard",
    extraTrackingInfo: { [key: string]: string } = {}
): Promise<void> {
    const client = getApolloClient();
    await client.mutate<
        GqlClientDeleteEditorFlexiblePlaceholderMutation,
        GqlClientDeleteEditorFlexiblePlaceholderMutationVariables
    >({
        mutation: DeleteEditorFlexiblePlaceholderDocument,
        variables: {
            input: {
                placeholderId
            }
        }
    });
    PendoService.getInstance().trackEvent(
        "PH Deleted",
        {
            deletedPhIntentName: trackingPhIntentName,
            layoutId: trackingLayoutId,
            layoutName: trackingLayoutName,
            trigger: trackingTrigger,
            ...extraTrackingInfo
        }
    );
}

export function updateAvatarPlaceholder(
    updateAvatarPlaceholderInput: Omit<GqlClientUpdateEditorAvatarPlaceholderInput, "updated">
) {
    const client = getApolloClient();
    return client.mutate<
        GqlClientUpdateEditorAvatarPlaceholderMutation,
        GqlClientUpdateEditorAvatarPlaceholderMutationVariables
    >({
        mutation: UpdateEditorAvatarPlaceholderDocument,
        variables: {
            input: {
                id: updateAvatarPlaceholderInput.id,
                placeholderContent: updateAvatarPlaceholderInput.placeholderContent,
                placeholderSettings: updateAvatarPlaceholderInput.placeholderSettings,
                updated: IGNORE_UPDATED
            }
        }
    });
}
