import type { GqlClientEditorScene } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEditorSceneAvatarVideoStatus, GqlClientEditorSceneSkippingMode, useEditorScenesAvatarRenderedStatusQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { convertArrayOfObjectsToObject } from "../../../../../common/arrayUtils";

export type UseEditorScenesAvatarRenderedStatus = {
    scenesAvatarRenderedStatusById: { [key: string]: Pick<GqlClientEditorScene, "id" | "avatarVideoStatus" | "skipSceneMode"> };
    numOfAvatarsToGenerate: number;
};

export const useEditorScenesAvatarRenderedStatus = (programId: string, programVersionId: string): UseEditorScenesAvatarRenderedStatus => {
    const { data } = useEditorScenesAvatarRenderedStatusQuery({ variables: { programId, programVersionId } });
    const scenes = data?.editorProgram?.programVersion?.scenes || [];
    const scenesAvatarRenderedStatusById = convertArrayOfObjectsToObject(scenes, "id");
    const numOfAvatarsToGenerate = scenes.filter(scene => scene.avatarVideoStatus === GqlClientEditorSceneAvatarVideoStatus.AVATAR_VIDEO_MISSING 
        && scene.skipSceneMode !== GqlClientEditorSceneSkippingMode.ALWAYS).length;
    return { scenesAvatarRenderedStatusById, numOfAvatarsToGenerate };
};
