import type { GqlClientEditorBrandFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorBrandsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseBrands = {
    brands: GqlClientEditorBrandFragment[];
};

export const useBrands = (): UseBrands => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorBrandsQuery({ variables: { programId, programVersionId } });

    const brands = data?.editorProgram?.programVersion?.brandConfigurationVersion.brandConfigurationCollections ?? [];

    return { brands };
};
