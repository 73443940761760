import type {
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorLibraryFontFragment,
    GqlClientEditorBrandColorFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientEditorAssetSource
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ColorName, ColorValues } from "../types";

type ParsedRemoteColors = {
    remoteColorValues: ColorValues;
    remoteColorNames: ColorName[];
};

export const getTextStyleFont = (textStyle: GqlClientEditorBrandTextStyleFragment): GqlClientEditorLibraryFontFragment | GqlClientEditorAssetLibraryFontFragment => {
    return textStyle.fontSource === GqlClientEditorAssetSource.CC ? textStyle.ccFont : textStyle.accountFont;
};

export const parseRemoteBrandColors = (remoteColors: GqlClientEditorBrandColorFragment[]): ParsedRemoteColors => {
    const initialParsedRemoteColors: ParsedRemoteColors = { remoteColorValues: {}, remoteColorNames: [] };

    return remoteColors.reduce<ParsedRemoteColors>((parsedRemoteColors, remoteColor) => {
        parsedRemoteColors.remoteColorValues[remoteColor.ccDof.localId] = {
            id: remoteColor.ccDof.id,
            localId: remoteColor.ccDof.localId,
            name: remoteColor.ccDof.name,
            displayName: remoteColor.ccDof.displayName,
            color: remoteColor.color
        };

        parsedRemoteColors.remoteColorNames.push({
            localId: remoteColor.ccDof.localId,
            name: remoteColor.ccDof.name,
            displayName: remoteColor.ccDof.displayName
        });

        return parsedRemoteColors;

    }, initialParsedRemoteColors);
};
