import type { MediaStreamType } from "./useRecordingTool";
import type {
    GqlClientRecorderMediaUploadedToS3Payload,
    GqlClientRecorderMediaUploadedToS3PayloadError,
    GqlClientRecorderMediaUploadedToS3PayloadPending,
    GqlClientRecorderMediaUploadedToS3PayloadSuccess,
    GqlClientRecorderMediaUploadedToS3SubscriptionVariables,
    GqlClientUploadRecordingToS3Input,
    GqlClientUploadRecordingToS3Mutation,
    GqlClientUploadRecordingToS3MutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { RecorderMediaUploadedToS3Document, UploadRecordingToS3Document } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { FetchResult, MutationOptions } from "@apollo/client";
import type { BackgroundMutationCallbacks, BackgroundMutationPayloadResult } from "../../types";
import type { SubscriptionOptions } from "@apollo/client/core/watchQueryOptions";
import { executeBackgroundMutation } from "../../Nooks";
import { RecorderLoggingEventType, reportRecorderError } from "../../../../logic/common/logging/recorderClientLogging";


export const getMediaStream = async (
    type: MediaStreamType,
    constraints?: MediaStreamConstraints
): Promise<MediaStream> => {
    try {
        if (type === "screen") {
            return navigator.mediaDevices.getDisplayMedia({
                ...constraints,
                // @ts-ignore
                selfBrowserSurface: "include"
            });
        }
        else {
            return navigator.mediaDevices.getUserMedia(constraints);
        }
    }
    catch (error) {
        reportRecorderError(error, {
            recorderEventType: RecorderLoggingEventType.FailedToGetMediaStream,
            metadata: { type, constraints }
        });
    }
};

export const stopMediaStream = (stream: MediaStream) => {
    stream?.getTracks().forEach(track => track.stop());
};

export const getVideoDimensions = (videoBlob: Blob): Promise<{ width: number, height: number }> => {
    return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        const url = URL.createObjectURL(videoBlob);
        video.src = url;

        video.onloadedmetadata = () => {
            resolve({ width: video.videoWidth, height: video.videoHeight });
        };
        video.onerror = (event: Event) => {
            reject(new Error("Failed to load video metadata"));
            URL.revokeObjectURL(url);
            reportRecorderError(event as ErrorEvent, {
                recorderEventType: RecorderLoggingEventType.GetVideoDimensionsError,
                metadata: { bloblSize: videoBlob?.size, blobType: videoBlob?.type, url }
            });
        };
    });
};

export type onUploadRecordingError = (payload: GqlClientRecorderMediaUploadedToS3PayloadError) => void;
export type onUploadingRecordingSuccess = (payload: GqlClientRecorderMediaUploadedToS3PayloadSuccess) => void;
export type onUploadingRecordingProgress = (payload: GqlClientRecorderMediaUploadedToS3PayloadPending) => void;

export const uploadRecordingMutation = async (
    mutationId: string,
    file: File,
    onSuccess: onUploadingRecordingSuccess,
    onFailure: onUploadRecordingError,
    onProgress: onUploadingRecordingProgress
): Promise<{ result: FetchResult<GqlClientUploadRecordingToS3Mutation> }> => {

    try {
        const uploadRecordingToS3: GqlClientUploadRecordingToS3Input = {
            file,
            fileType: file.type,
            fileName: file.name,
            fileSize: file.size
        };

        const options: MutationOptions<GqlClientUploadRecordingToS3Mutation, GqlClientUploadRecordingToS3MutationVariables> = {
            mutation: UploadRecordingToS3Document,
            variables: {
                input: uploadRecordingToS3,
                mutationId
            }
        };

        const callbacks: BackgroundMutationCallbacks<GqlClientRecorderMediaUploadedToS3Payload> = {
            onSuccess,
            onFailure,
            onProgress
        };

        const subscriptionOptions: SubscriptionOptions<GqlClientRecorderMediaUploadedToS3SubscriptionVariables, BackgroundMutationPayloadResult<GqlClientRecorderMediaUploadedToS3Payload>> = {
            query: RecorderMediaUploadedToS3Document,
            variables: {
                mutationId
            }
        };

        return { result: await executeBackgroundMutation<GqlClientRecorderMediaUploadedToS3Payload, GqlClientUploadRecordingToS3Mutation>(options, subscriptionOptions, callbacks, mutationId) };
    }
    catch (e) {
        reportRecorderError(e, {
            recorderEventType: RecorderLoggingEventType.UploadRecordingFailed,
            metadata: { mutationId, fileType: file.type, fileName: file.name, fileSize: file.size }
        });
    }
};
