import type { ColorValues } from "../../types";
import { localEditedBrandColorsVar } from "../../State";

export const setEditedBrandColors = (colorValues: ColorValues): void => {
    localEditedBrandColorsVar({
        ...localEditedBrandColorsVar(),
        ...colorValues
    });
};

export const resetColorsLocalVar = () => {
    localEditedBrandColorsVar({});
};
