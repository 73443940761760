import { getApolloClient } from "../../../../apollo";
import {
    DisconnectTrayConnectorDocument,
    GqlClientGoogleSheetResult,
    RemoveConnectorFromAccountDocument,
    TrayGoogleSheetColumnsDocument,
    TrayGoogleSheetsDocument,
    TrayServiceAuthUrlDocument,
    TrayServiceConnectorEntitiesDocument,
    TrayTestConnectorAuthenticationDocument,
    UpdateTrayConnectorAuthenticationDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type {
    GqlClientDisconnectTrayConnectorMutation,
    GqlClientDisconnectTrayConnectorMutationVariables,
    GqlClientTrayServiceAuth,
    GqlClientTrayServiceAuthUrlQuery,
    GqlClientTrayServiceAuthUrlQueryVariables,
    GqlClientTrayServiceConnectorEntitiesQuery,
    GqlClientTrayServiceConnectorEntitiesQueryVariables,
    GqlClientTrayServiceConnectorEntity,
    GqlClientUpdateTrayConnectorAuthenticationMutation,
    GqlClientUpdateTrayConnectorAuthenticationMutationVariables,
    GqlClientGoogleSheet,
    GqlClientGoogleSheetError,
    GqlClientGoogleSheetResultOutputError,
    GqlClientGoogleSheetResultOutputSuccess,
    GqlClientTrayGoogleSheetColumnsQuery,
    GqlClientTrayGoogleSheetColumnsQueryVariables,
    GqlClientTrayGoogleSheetsQuery,
    GqlClientTrayGoogleSheetsQueryVariables,
    GqlClientRemoveConnectorFromAccountMutation,
    GqlClientRemoveConnectorFromAccountMutationVariables,
    GqlClientTrayTestConnectorAuthenticationQuery,
    GqlClientTrayTestConnectorAuthenticationQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

import { getSskyErrorCodeFromGqlError } from "../../../../../common/errors";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import type { ConnectorType } from "@sundaysky/customer-data-common-goblin-ds";

type trayConnectorEntriesParams = {
    dataLibraryId: string;
    connectorType: ConnectorType;
    authLastUpdated: string;
    parentEntityId?: string;
    cacheInvalid?: boolean;
};

export const getTrayConnectorEntries = async (params: trayConnectorEntriesParams): Promise<GqlClientTrayServiceConnectorEntity[]> => {
    const { dataLibraryId, connectorType, parentEntityId, authLastUpdated, cacheInvalid } = params;
    const { data } = await getApolloClient().query<GqlClientTrayServiceConnectorEntitiesQuery, GqlClientTrayServiceConnectorEntitiesQueryVariables>({
        query: TrayServiceConnectorEntitiesDocument,
        variables: { dataLibraryId, connectorType, parentEntityId, authLastUpdated },
        fetchPolicy: cacheInvalid ? "network-only" : "cache-first"
    });
    return data.trayServiceConnectorEntities;
};

export const getAuthUrl = async (connectorType: ConnectorType): Promise<GqlClientTrayServiceAuth> => {
    const { data } = await getApolloClient().query<GqlClientTrayServiceAuthUrlQuery, GqlClientTrayServiceAuthUrlQueryVariables>({
        query: TrayServiceAuthUrlDocument,
        variables: { connectorType },
        fetchPolicy: "no-cache"
    });

    return data?.trayServiceAuthUrl;
};

export const updateTrayConnectorAuthentication = async (connectorType: ConnectorType, authId: string): Promise<string> => {

    try {
        const { data } = await getApolloClient().mutate<GqlClientUpdateTrayConnectorAuthenticationMutation, GqlClientUpdateTrayConnectorAuthenticationMutationVariables>({
            mutation: UpdateTrayConnectorAuthenticationDocument,
            variables: { updateTrayConnectorAuthentication: { connectorType, authId } }
        });
        return data?.updateTrayConnectorAuthentication?.solutionInstanceTriggerUrl;
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export const testTrayConnectorAuthentication = async (connectorType: ConnectorType): Promise<boolean> => {

    const { data } = await getApolloClient().query<GqlClientTrayTestConnectorAuthenticationQuery, GqlClientTrayTestConnectorAuthenticationQueryVariables>({
        query: TrayTestConnectorAuthenticationDocument,
        variables: { connectorType },
        fetchPolicy: "no-cache"
    });

    return data?.trayTestConnectorAuthentication?.authenticated;
};

export const getGoogleSheets = async (spreadSheetId: string): Promise<{error?: GqlClientGoogleSheetError, sheets?: GqlClientGoogleSheet[]}> => {
    const { data } = await getApolloClient().query<GqlClientTrayGoogleSheetsQuery, GqlClientTrayGoogleSheetsQueryVariables>({
        query: TrayGoogleSheetsDocument,
        variables: { spreadSheetId },
        fetchPolicy: "no-cache"
    });

    if (data?.trayGoogleSheets.result === GqlClientGoogleSheetResult.ERROR) {
        const output = data.trayGoogleSheets as GqlClientGoogleSheetResultOutputError;
        return { error: output.error };

    }

    if (data?.trayGoogleSheets.result === GqlClientGoogleSheetResult.SUCCESS) {
        const output = data.trayGoogleSheets as GqlClientGoogleSheetResultOutputSuccess;
        return { sheets: output.sheets };
    }
};

export const isGoogleSheetValid = async (spreadSheetId: string, sheetName: string): Promise<boolean> => {
    const { data } = await getApolloClient().query<GqlClientTrayGoogleSheetColumnsQuery, GqlClientTrayGoogleSheetColumnsQueryVariables>({
        query: TrayGoogleSheetColumnsDocument,
        variables: { spreadSheetId, sheetName },
        fetchPolicy: "no-cache"
    });

    return data?.trayGoogleSheetColumns?.some(column => column?.name.toLowerCase() === "id");
};


// Removes si from tray - for test purposes
export const disconnectTrayConnector = async (connectorType: ConnectorType): Promise<void> => {

    try {
        await getApolloClient().mutate<GqlClientDisconnectTrayConnectorMutation, GqlClientDisconnectTrayConnectorMutationVariables>({
            mutation: DisconnectTrayConnectorDocument,
            variables: { disconnectTrayConnector: { connectorType } }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

/*
    Indicates in connection type "is_connected" false
    Removes all draft data libraries from using this connector and reset fields
 */
export const removeConnectorFromAccount = async (connectorType: ConnectorType): Promise<void> => {

    try {
        await getApolloClient().mutate<GqlClientRemoveConnectorFromAccountMutation, GqlClientRemoveConnectorFromAccountMutationVariables>({
            mutation: RemoveConnectorFromAccountDocument,
            variables: { removeConnectorFromAccount: { connectorType } }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};
