import { GqlClientAssetLifecycleStage, useEditorProgramAllowedToUpgradeLayoutsInfoQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export type UseIsAllowedToUpgradeLayouts = {
    isAllowedToUpgradeLayouts: boolean;
};

export const useIsAllowedToUpgradeLayouts = (programId: string, programVersionId: string, skip: boolean): UseIsAllowedToUpgradeLayouts => {
    const { loading, data } = useEditorProgramAllowedToUpgradeLayoutsInfoQuery({
        variables: { programId, programVersionId },
        skip: skip || !programId || !programVersionId
    });
    const scenesWithWireframeLifecycleStage = data?.editorProgram?.programVersion?.scenes ?? [];
    const programThemeName = data?.editorProgram?.programVersion?.ccTheme?.name;

    const isAllowedToUpgradeLayouts = programId && programVersionId && !skip && !loading && programThemeName !== "SundaySky" &&
        !scenesWithWireframeLifecycleStage.some(scene => scene.ccWireframe.lifecycleStage === GqlClientAssetLifecycleStage.REMOVED);


    return { isAllowedToUpgradeLayouts };
};
