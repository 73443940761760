import type { GqlClientEditorCcLibraryVersionFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useCcLibraryVersionQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEffect, useState } from "react";
import { initCcLibraryLocalStateForEditor } from "../../State";

export type UseCcLibraryVersion = {
    ccLibraryVersion: GqlClientEditorCcLibraryVersionFragment;
    loading: boolean;
};

export const useCcLibraryVersion = (libraryId: string, libraryVersionId: string): UseCcLibraryVersion => {
    const [isLocalStateInit, setIsLocalStateInit] = useState(false);
    const { data, loading } = useCcLibraryVersionQuery({ variables: { libraryId, libraryVersionId }, skip: !libraryId || !libraryVersionId });
    const ccLibraryVersion = data?.ccLibrary?.libraryVersion;

    useEffect(() => {
        if (!loading && ccLibraryVersion) {
            initCcLibraryLocalStateForEditor(ccLibraryVersion);
            setIsLocalStateInit(true);
        }
    }, [loading]);


    return {
        loading: loading || !ccLibraryVersion || !isLocalStateInit,
        ccLibraryVersion
    };
};
