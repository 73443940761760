import { localEditorAccountDataVersion, localEditorAccountIdVar, localEditorAccountNameVar, localEditorAccountTypeVar, updateAssetLibraryFontsManagerVar } from "../../State";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientAccountCcLibraryVersionQuery,
    GqlClientAccountCcLibraryVersionQueryVariables,
    GqlClientCcLibraryVersionQuery,
    GqlClientCcLibraryVersionQueryVariables,
    GqlClientEditorAccountLevelDataAvatarsQuery,
    GqlClientEditorAccountLevelDataAvatarsQueryVariables,
    GqlClientEditorAccountLevelDataBrandsQuery,
    GqlClientEditorAccountLevelDataBrandsQueryVariables,
    GqlClientEditorAccountLevelDataFontsQuery,
    GqlClientEditorAccountLevelDataFontsQueryVariables,
    GqlClientEditorAccountLevelDataMusicQuery,
    GqlClientEditorAccountLevelDataMusicQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    AccountCcLibraryVersionDocument,
    CcLibraryVersionDocument,
    EditorAccountLevelDataAvatarsDocument,
    EditorAccountLevelDataBrandsDocument,
    EditorAccountLevelDataFontsDocument,
    EditorAccountLevelDataMusicDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloQueryResult } from "@apollo/client";
import { PendoService } from "../../../pendoService";

export const setLocalEditorAccountDataVersion = (accountDataVersion: string): void => {
    localEditorAccountDataVersion(accountDataVersion);
};

export const getLocalEditorAccountDataVersion = (): string => {
    return localEditorAccountDataVersion();
};

export const setEditorAccountDetails = (accountName: string, accountId: string, accountType: string): void => {
    localEditorAccountNameVar(accountName);
    localEditorAccountIdVar(accountId);
    localEditorAccountTypeVar(accountType);
};

export const getEditorAccountId = (): string => {
    return localEditorAccountIdVar();
};

export const getEditorAccountName = (): string => {
    return localEditorAccountNameVar();
};

export const getEditorAccountType = (): string => {
    return localEditorAccountTypeVar();
};

export const loadAccountCcLibraryVersion = async () => {
    const client = getApolloClient();
    const accountCcLibraryResult = await client.query<GqlClientAccountCcLibraryVersionQuery, GqlClientAccountCcLibraryVersionQueryVariables>({
        query: AccountCcLibraryVersionDocument
    });
    const libraryId = accountCcLibraryResult?.data?.account?.ccLibraryVersion?.library?.id;
    const libraryVersionId = accountCcLibraryResult?.data?.account?.ccLibraryVersion?.id;
    if (libraryId && libraryVersionId) {
        return client.query<GqlClientCcLibraryVersionQuery, GqlClientCcLibraryVersionQueryVariables>({
            query: CcLibraryVersionDocument,
            variables: {
                libraryId,
                libraryVersionId
            }
        });
    }
    else {
        return null;
    }
};

export const getAccountEditorLevelBrandData = (): Promise<ApolloQueryResult<GqlClientEditorAccountLevelDataBrandsQuery>> => {
    return getApolloClient().query<GqlClientEditorAccountLevelDataBrandsQuery, GqlClientEditorAccountLevelDataBrandsQueryVariables>(
        { query: EditorAccountLevelDataBrandsDocument, variables: {} });
};

export const getAccountEditorLevelMusicData = (): Promise<ApolloQueryResult<GqlClientEditorAccountLevelDataMusicQuery>> => {
    return getApolloClient().query<GqlClientEditorAccountLevelDataMusicQuery, GqlClientEditorAccountLevelDataMusicQueryVariables>(
        { query: EditorAccountLevelDataMusicDocument, variables: {} });
};

export const getAccountEditorLevelAvatarsData = (): Promise<ApolloQueryResult<GqlClientEditorAccountLevelDataAvatarsQuery>> => {
    return getApolloClient().query<GqlClientEditorAccountLevelDataAvatarsQuery, GqlClientEditorAccountLevelDataAvatarsQueryVariables>(
        { query: EditorAccountLevelDataAvatarsDocument, variables: {} });
};

export const loadAccountEditorLevelFontsData = (): () => void => {
    const { watchQuery } = getApolloClient();
    const subscription = watchQuery<GqlClientEditorAccountLevelDataFontsQuery, GqlClientEditorAccountLevelDataFontsQueryVariables>({
        query: EditorAccountLevelDataFontsDocument
    }).subscribe(({ data }) => {
        updateAssetLibraryFontsManagerVar(data.editorAccountLevelData.assetLibraryVersion?.fonts);
    });
    return subscription.unsubscribe;
};

export const reportNumSelectedGetLinksItems = (numItems: number): void => {

    const eventName = "Selected Get Links Items";
    const eventData = {
        numItems: numItems?.toString() || "N/A"
    };

    const pendo = PendoService.getInstance();
    pendo.trackEvent(eventName, eventData);
};
