import type { GqlClientEditorAssetLibraryAvatarFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorAccountLevelDataAvatarsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

type UseAccountAvatars = {
    customAvatars: GqlClientEditorAssetLibraryAvatarFragment[];
}

export const useAccountAvatars = () : UseAccountAvatars => {
    const { data } = useEditorAccountLevelDataAvatarsQuery();

    const customAvatars = data?.editorAccountLevelData.assetLibraryVersion?.avatars ?? [];

    return { customAvatars };
};
