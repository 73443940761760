import type { MutationOptions, SubscriptionOptions } from "@apollo/client/core/watchQueryOptions";
import { getApolloClient } from "../../../../apollo";
import type { BackgroundMutation, BackgroundMutationBasePayload, BackgroundMutationCallbackFn, BackgroundMutationCallbacks, BackgroundMutationPayloadResult } from "../../types";
import { GqlClientStatus } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { reportInfoToSplunk } from "../../../../logic/common/logReportUtils";
import type { FetchResult } from "@apollo/client";
import type { OperationDefinitionNode } from "graphql";
import { v4 as uuid } from "uuid";

const MUTATION_KEEPALIVE_TIMEOUT: number = 60000;

const mutationsMap = new Map<string, BackgroundMutation<BackgroundMutationBasePayload>>();

const getBackgroundMutation = (mutationId: string): BackgroundMutation<BackgroundMutationBasePayload> => {
    return mutationsMap.get(mutationId);
};

const getBackgroundMutationOnSuccess = (mutationId: string): BackgroundMutationCallbackFn<BackgroundMutationBasePayload> => {
    return getBackgroundMutation(mutationId)?.callbacks?.onSuccess;
};

const getBackgroundMutationOnFailure = (mutationId: string): BackgroundMutationCallbackFn<BackgroundMutationBasePayload> => {
    return getBackgroundMutation(mutationId)?.callbacks?.onFailure;
};

const getBackgroundMutationOnProgress = (mutationId: string): BackgroundMutationCallbackFn<BackgroundMutationBasePayload> => {
    return getBackgroundMutation(mutationId)?.callbacks?.onProgress;
};

const unsubscribeBackgroundMutation = (mutationId: string): void => {
    const mutation = getBackgroundMutation(mutationId);
    if (mutation) {
        mutation.subscription.unsubscribe();
    }
};
export const removeBackgroundMutation = (mutationId: string): void => {
    unsubscribeBackgroundMutation(mutationId);
    mutationsMap.delete(mutationId);
};
const registerMutation = <TPayload extends BackgroundMutationBasePayload>(mutationId: string, callbacks: BackgroundMutationCallbacks<TPayload>, subscription) => {
    mutationsMap.set(mutationId, { callbacks, subscription });
};

function createSubscription<TPayload extends BackgroundMutationBasePayload>(subscriptionOptions: SubscriptionOptions<any, BackgroundMutationPayloadResult<TPayload>>) {
    const createTimeout = (timeoutId?) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        return setTimeout(() => {
            const subscriptionName: string = (subscriptionOptions?.query?.definitions?.[0] as OperationDefinitionNode)?.name?.value ?? "N/A";
            const variables = subscriptionOptions?.variables ?? "N/A";
            const errorMessage: string = `Subscription "${subscriptionName}" did not send keep alive for ${MUTATION_KEEPALIVE_TIMEOUT}ms`;
            reportInfoToSplunk(errorMessage, { variables });
        }, MUTATION_KEEPALIVE_TIMEOUT);
    };

    // Timout
    let timeoutId = createTimeout();

    return getApolloClient().subscribe(subscriptionOptions).subscribe((payload: FetchResult<BackgroundMutationPayloadResult<TPayload>>) => {
        const payloadData: BackgroundMutationBasePayload = (Object.values(payload.data))[0];
        if (payloadData) {
            const { status, mutationId } = payloadData;
            switch (status) {
                case GqlClientStatus.KEEP_ALIVE: {
                    // Reset timeout
                    timeoutId = createTimeout(timeoutId);
                    break;
                }
                case GqlClientStatus.PENDING: {
                    const onProgress = getBackgroundMutationOnProgress(mutationId);
                    if (onProgress) {
                        onProgress(payloadData);
                    }
                    break;
                }
                case GqlClientStatus.PARTIAL_SUCCESS:
                case GqlClientStatus.SUCCESS: {
                    clearTimeout(timeoutId);
                    const onSuccess = getBackgroundMutationOnSuccess(mutationId);
                    if (onSuccess) {
                        onSuccess(payloadData);
                    }
                    removeBackgroundMutation(mutationId);
                    break;
                }
                case GqlClientStatus.ERROR: {
                    clearTimeout(timeoutId);
                    const onFailure = getBackgroundMutationOnFailure(mutationId);
                    if (onFailure) {
                        onFailure(payloadData);
                    }
                    removeBackgroundMutation(mutationId);
                    break;
                }
            }
        }
    });
}

export function executeBackgroundMutation<TPayload extends BackgroundMutationBasePayload, TResult >(options: MutationOptions<TResult, any>,
    subscriptionOptions: SubscriptionOptions<any, BackgroundMutationPayloadResult<TPayload>>,
    callbacks : BackgroundMutationCallbacks<TPayload>, existingMutationId?: string): Promise<FetchResult<TResult>> {

    const mutationId = existingMutationId ?? uuid();

    const { mutate } = getApolloClient();
    const subscription = createSubscription<TPayload>(subscriptionOptions);

    registerMutation<TPayload>(mutationId, callbacks, subscription);

    return mutate({ mutation: options.mutation, variables: { ...options.variables, mutationId } });
}
