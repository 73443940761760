import React, { Component } from "react";
import "./PopupFooter.css";
import PropTypes from "prop-types";

class PopupFooter extends Component {
    constructor(props) {
        super(props);
    }

    handleKeyPress = (e) => {
        if (this.props.enterKeyConfirms && e.key === "Enter") {
            this.props.handleOkClicked();
        }
        if (this.props.escapeKeyCancels && e.key === "Escape") {
            this.props.handleCancelClicked();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, true);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, true);
    }

    render() {
        return (
            <div className="logic-data-selector-footer" onKeyDown={this.handleKeyPress}>
                {!this.props.showOnlyPositiveButton && (
                    <button className="negative" onClick={this.props.handleCancelClicked}>
                        {this.props.btnNegativeText}
                    </button>
                )}
                <button id="btn_ok" className="positive" disabled={this.props.btnPositiveDisabled} onClick={this.props.handleOkClicked}>
                    {this.props.btnPositiveText}
                </button>
            </div>
        );
    }
}

PopupFooter.defaultProps = {
    btnPositiveText: "OK",
    btnNegativeText: "Cancel",
    enterKeyConfirms: false,
    escapeKeyCancels: false
};

PopupFooter.propTypes = {
    handleOkClicked: PropTypes.func.isRequired,
    handleCancelClicked: PropTypes.func.isRequired,
    showOnlyPositiveButton: PropTypes.bool,
    btnPositiveText: PropTypes.string,
    btnNegativeText: PropTypes.string,
    btnPositiveDisabled: PropTypes.bool,
    enterKeyConfirms: PropTypes.bool,
    escapeKeyCancels: PropTypes.bool
};

export default PopupFooter;
