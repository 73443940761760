import { useEditorProgram } from "../UseEditorProgram";
import { useEditorProgramId } from "../UseEditorProgramId";

export type UseEditorProgramAvatarBeingRendered = {
    isAvatarRendering: boolean;
    loading: boolean;
};

export const useEditorProgramAvatarBeingRendered = (): UseEditorProgramAvatarBeingRendered => {

    const programId = useEditorProgramId();
    const { editorProgram, loading } = useEditorProgram(programId);

    return { isAvatarRendering: editorProgram?.programVersionDraft.isAvatarRendering, loading };
};
