import { ELEMENT_IDS } from "./pipStyles";

const barStyle = `
    width: 3px;
    height: 4px;
    background-color: white;
    transition: height 0.1s ease-in-out;
    border-radius: 16px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.95);
`;

export const startMicVisualizer = (stream: MediaStream, pipWindow: Window) => {
    const doc = pipWindow.document;
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    analyser.fftSize = 32;
    microphone.connect(analyser);

    const micVisualizer = doc.getElementById(ELEMENT_IDS.MIC_VISUALIZER);
    if (!micVisualizer) return;

    const numBars = 6;
    micVisualizer.innerHTML = "";

    const bars: HTMLDivElement[] = [];

    // Define default heights for bars from middle outward
    const defaultHeights = [8, 8, 6, 6, 4, 4]; // Middle to sides

    // Create bars starting from the middle and going outward
    for (let i = 0; i < numBars / 2; i++) {
        // Create right bar (added first to be on the right)
        const rightBar = doc.createElement("div");
        rightBar.style.cssText = barStyle;
        rightBar.style.height = `${defaultHeights[i * 2]}px`;
        micVisualizer.appendChild(rightBar);

        // Create left bar
        const leftBar = doc.createElement("div");
        leftBar.style.cssText = barStyle;
        leftBar.style.height = `${defaultHeights[i * 2 + 1]}px`;
        micVisualizer.insertBefore(leftBar, micVisualizer.firstChild);

        // Store bars in order from middle to sides
        bars.push(rightBar);
        bars.unshift(leftBar);
    }

    function updateBars() {
        analyser.getByteFrequencyData(dataArray);

        // Process bars from middle outward
        for (let i = 0; i < numBars / 2; i++) {
            const volume = dataArray[i] / 255;
            const height = Math.max(4, Math.floor(volume * 20));

            // Update middle bars first, then outer bars
            const middleIndex = Math.floor(numBars / 2) - 1;
            bars[middleIndex - i].style.height = `${height}px`; // Left from middle
            bars[middleIndex + 1 + i].style.height = `${height}px`; // Right from middle
        }

        requestAnimationFrame(updateBars);
    }

    updateBars();
};
