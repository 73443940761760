import type { SelectItem } from "@sundaysky/ui-component-library/Components/SskySelect/SskySelect";
import type { WithId } from "../types";
import { Environments } from "@sundaysky/smartvideo-hub-config";
import moment from "moment";

type SelectableEntity = {
    id: string;
    name: string;
};

export const findEntityById = <T>(collection: Array<WithId<T>>, id: string): T => {
    return collection.find((entity) => entity.id === id);
};

export const findEntityByExtractingLocalIdFromId = <T>(collection: Array<WithId<T>>, id: string): T => {
    return collection.find((entity) => entity.id.split("|").pop() === id.split("|").pop());
};

export const createSelectItems = (collection?: Array<SelectableEntity>): SelectItem[] => {
    return collection
        ? collection.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name
        }))
        : [];
};

export const removeFileExtension = (filename: string): string => {
    const lastDotIndex = filename.lastIndexOf(".");
    return lastDotIndex === -1 ? filename : filename.slice(0, lastDotIndex);
};

// returns a new string as the original but replace multiple new lines with one new line, replace multiple spaces with one space.
export const removeStringWhitespaces = (str: string): string => {
    str = str.trim();
    str = str.replace(/\n+/g, "\n");
    str = str.replace(/ +/g, " ");
    return str.replace(/\n /g, "\n");
};

export const extractFilenameFromPath = (url: string): string => {
    return url?.split("/")?.pop() ?? "";
};

export const getEnvironment = () => {
    switch (window.location.hostname) {
        case "app.sundaysky.com":
            return Environments.prod;
        case "app-stg.sundaysky.com":
            return Environments.stg;
        case "app-test.sundaysky.com":
            return Environments.test;
        case "app-dev.sundaysky.com":
        default:
            return Environments.dev;
    }
};

export const getCurrentFullStoryUrl = () => {
    const fullStorySessionURL = (window as any)?.FS?.getCurrentSessionURL?.(true);
    return fullStorySessionURL && Buffer.from(fullStorySessionURL).toString("base64");
};

export const durationToString = (duration: number): string => {
    const parsedDuration = moment.duration(duration, "seconds");
    const hours: number = parsedDuration.get("h");
    const minutes: number = parsedDuration.get("m");
    const seconds: number = parsedDuration.get("s");

    if (hours > 0) {
        return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
    else {
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
};

export const truncate = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

export const getVideoDuration = (url: string): Promise<{ duration: number }> => {
    const video = document.createElement("video");

    const promise = new Promise<{ duration: number }>((resolve, reject) => {
        video.onloadedmetadata = () => {
            const duration = video.duration;
            resolve({ duration });
        };
        video.onerror = reject;
    });
    video.src = url;
    return promise;
};
