import type { GqlClientEditorAssetLibraryFontFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { FontsManager, FontVariations, WithFontMetadata } from "../../../../../common/fontsUtils";
import { assetLibraryFontsManagerVar } from "../../State";
import { useReactiveVar } from "@apollo/client";

type UseAssetLibraryFonts = {
    assetLibraryFonts: GqlClientEditorAssetLibraryFontFragment[];
};

export const useAssetLibraryFonts = (): UseAssetLibraryFonts => {
    const fontManager = useReactiveVar<FontsManager<GqlClientEditorAssetLibraryFontFragment>>(assetLibraryFontsManagerVar);
    const assetLibraryFonts = fontManager.getFonts();
    return { assetLibraryFonts };
};

export type UseAssetLibraryFontForStyling = WithFontMetadata<GqlClientEditorAssetLibraryFontFragment> & {
    fontVariations: Required<FontVariations<WithFontMetadata<GqlClientEditorAssetLibraryFontFragment>>>
    isFontSupportStyling: boolean;
    isFontDoNotSupportStyling: boolean;
    supportsBold: boolean;
    supportsItalic: boolean;
    supportsBoldItalic: boolean;
    partiallySupportStylingOnly: boolean;
}

export const useAssetLibraryFontForStyling = (fontId: string): UseAssetLibraryFontForStyling => {
    const fontManager = useReactiveVar<FontsManager<GqlClientEditorAssetLibraryFontFragment>>(assetLibraryFontsManagerVar);
    return {
        ...fontManager.getFont(fontId),
        fontVariations: fontManager.getFontVariationsForFormatting(fontId),
        isFontSupportStyling: fontManager.isFontFullySupportStyling(fontId),
        isFontDoNotSupportStyling: fontManager.isFontDoNotSupportStyling(fontId),
        supportsBold: fontManager.doesFontSupportBold(fontId),
        supportsItalic: fontManager.doesFontSupportItalic(fontId),
        supportsBoldItalic: fontManager.doesFontSupportBoldItalic(fontId),
        partiallySupportStylingOnly: fontManager.isFontPartiallySupportStylingOnly(fontId)
    };
};

export type UseAssetLibraryFontsManager = {
    accountFontsManager: FontsManager<GqlClientEditorAssetLibraryFontFragment>;
};

export const useAssetLibraryFontsManager = (): UseAssetLibraryFontsManager => {
    return {
        accountFontsManager: useReactiveVar<FontsManager<GqlClientEditorAssetLibraryFontFragment>>(assetLibraryFontsManagerVar)
    };
};
