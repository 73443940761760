import type { GqlClientEditorSceneFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorScenesQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseProgramScenes = {
    programScenes: GqlClientEditorSceneFragment[];
};

export const useProgramScenes = (): UseProgramScenes => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorScenesQuery({ variables: { programId, programVersionId } });
    const programScenes = data?.editorProgram?.programVersion?.scenes ?? [];

    return { programScenes };
};
