import type { MultiSelectOption } from "../types";

export function getSelectedSceneNumbers(scenes: MultiSelectOption[]) {
    return scenes
        .map(({ checked }, index) => checked ? (index + 1) : null)
        .filter(Boolean);
}

export function areAllScenesSelected(scenes: MultiSelectOption[]) {
    return scenes.every(scene => scene.disabled || scene.checked);
}
