import {
    GqlClientEditorAssetSource,
    useEditorSelectedMusicIdQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import type { MusicId, ProgramVersionId } from "../../types";
import { setProjectSelectedMusicId } from "../../Nooks";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseProjectSelectedMusicId = {
    projectSelectedMusicId: MusicId;
    projectSelectedMusicSource: GqlClientEditorAssetSource;
    setProjectSelectedMusicId: (
        programVersionId: ProgramVersionId,
        selectedMusicId: MusicId,
        selectedMusicSource: GqlClientEditorAssetSource
    ) => void;
};

export const useProjectSelectedMusicId = (): UseProjectSelectedMusicId => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorSelectedMusicIdQuery({ variables: { programId, programVersionId } });

    const projectSelectedMusicSource = data?.editorProgram?.programVersion?.selectedMusicSource;

    let projectSelectedMusicId: MusicId = null;

    if (projectSelectedMusicSource === GqlClientEditorAssetSource.CC) {
        projectSelectedMusicId = data?.editorProgram?.programVersion?.selectedCcMusic?.id ?? null;
    }
    else if (projectSelectedMusicSource === GqlClientEditorAssetSource.ACCOUNT) {
        projectSelectedMusicId = data?.editorProgram?.programVersion?.selectedAccountMusic?.id ?? null;
    }

    return { projectSelectedMusicId, projectSelectedMusicSource, setProjectSelectedMusicId };
};
