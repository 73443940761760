import type { SxProps } from "@mui/system";
import type { Theme } from "@mui/material";

export const selectedCheckIconSxProps: SxProps<Theme> = {
    minWidth: "16px", // In some cases the icon container gets squished by flex
    width: "16px",
    height: "16px",
    backgroundColor: "primary.main",
    color: "common.white",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& svg": {
        width: "8px",
        height: "8px"
    }
};

export const dialogTitleWithXSxProps: SxProps<Theme> = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 4px 8px 32px"
};

export const dialogTitleTextSxProps: SxProps<Theme> = {
    padding: "10px 10px 10px 0"
};

export const dialogXButtonSxProps: SxProps<Theme> = {
    width: "32px",
    height: "32px",
    gap: "10px",

    ":hover": {
        color: "primary.main",
        backgroundColor: "action.hover"
    }
};

export const dialogContentSxProps: SxProps<Theme> = {
    padding: "8px 32px"
};

export const tabsSxProps: SxProps<Theme> = {
    height: "32px",
    minHeight: "32px",
    "& .MuiTabs-flexContainer": { height: "32px", gap: "4px" }
};

export const tabSxProps: SxProps<Theme> = {
    minHeight: "32px",
    height: "32px",
    textTransform: "unset",
    borderBottom: theme => `2px solid ${theme.palette.divider}`,

    "&:not(.Mui-selected)": {
        color: "secondary.light"
    },

    "&.Mui-selected": {
        color: "secondary.main"
    }
};
