import { v4 as uuid } from "uuid";
import type { SskyErrorCode } from "../../../../common/errors";
import { ERROR_ID_KEY, SSKY_ERROR_CODE_KEY } from "../../../../common/errors";

type EnhancedErrorType = Error & {
    [ERROR_ID_KEY]?: string
    extensions?: {
        [ERROR_ID_KEY]?: string
    };
};

const combineStackTraces = (currentStackTrace: string, originalErrorStackTrace: string | undefined): string => {
    const extendedMessage = originalErrorStackTrace ? `\nOriginal error:\n${originalErrorStackTrace}` : "";
    return `${currentStackTrace}${extendedMessage}`;
};

export class EnhancedError extends Error {
    private [ERROR_ID_KEY]: string;
    private [SSKY_ERROR_CODE_KEY]: SskyErrorCode;
    private details: Record<string, unknown>;

    constructor(error: EnhancedErrorType, sskyCode: SskyErrorCode, details?: Record<string, unknown>) {
        super(error.message);

        this.name = this.constructor.name;
        this.stack = combineStackTraces(this.stack, error.stack);
        this.details = details;
        this[ERROR_ID_KEY] = details?.[ERROR_ID_KEY] as string ?? error?.[ERROR_ID_KEY] ?? error?.extensions?.[ERROR_ID_KEY] ?? uuid();
        this[SSKY_ERROR_CODE_KEY] = sskyCode;
    }

    public getSskyErrorCode = (): SskyErrorCode => {
        return this[SSKY_ERROR_CODE_KEY];
    };

    public getErrorId = (): string => {
        return this[ERROR_ID_KEY];
    };
}
