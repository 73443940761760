import type { SceneSkippingAudience } from "../../../../common/types/editorSceneAudience";
import type { GqlClientEditorSceneFragment, GqlClientEditorSceneWithoutCcDataFragment } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEditorSceneSkippingMode } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ExtendedViewerProfileItem, StudioValueSetItem } from "../types";
import { PreviewDialogType } from "../types";


export const getSkipValueWarning = (previewType: PreviewDialogType) => {
    return previewType === PreviewDialogType.Scene ?
        "This scene is not eligible for this audience." :
        "Some scenes in this video are not shown for this audience.";
};

export const scenesFilteredByViewerProfile = <T extends GqlClientEditorSceneWithoutCcDataFragment>(scenesToPreview: T[], viewerProfile: ExtendedViewerProfileItem[]) => {
    const filteredScenes = scenesToPreview.filter(scene => {
        const skipSceneDataElementValue = viewerProfile.find(dataElement => dataElement.id === scene.skipSceneAudience?.by?.localId)?.value as StudioValueSetItem;
        switch (scene.skipSceneMode) {
            case GqlClientEditorSceneSkippingMode.ALWAYS:
                return false;
            case GqlClientEditorSceneSkippingMode.NEVER:
                return true;
            case GqlClientEditorSceneSkippingMode.SHOW_BY_AUDIENCE:
                if (!scene.skipSceneAudience?.by?.localId) return false;
                return scene.skipSceneAudience?.valueIds?.includes(skipSceneDataElementValue?.id);
            case GqlClientEditorSceneSkippingMode.BY_AUDIENCE:
                if (!scene.skipSceneAudience?.by?.localId) return true;
                return !scene.skipSceneAudience?.valueIds?.includes(skipSceneDataElementValue?.id);
        }
    });
    return filteredScenes.map(scene => scene.id);
};

export const checkIfVideoSkippedByAudience = <T extends GqlClientEditorSceneWithoutCcDataFragment>(scenesToPreview: T[], viewerProfile: ExtendedViewerProfileItem[]) => {
    const filteredScenes = scenesFilteredByViewerProfile(scenesToPreview, viewerProfile);
    return filteredScenes.length === 0;
};

export type SkippingValuesForScene = Record<GqlClientEditorSceneFragment["id"], {
    valueIds: SceneSkippingAudience["valueIds"];
    skipSceneMode: GqlClientEditorSceneSkippingMode;
}>;
export type SceneSkippingDataElements = Record<SceneSkippingAudience["by"]["localId"], SkippingValuesForScene>;

export const getSceneSkippingDataElements = <T extends GqlClientEditorSceneWithoutCcDataFragment>(scenesToPreview: T[]): SceneSkippingDataElements => {
    return scenesToPreview.reduce<SceneSkippingDataElements>((acc, scene) => {
        if (
            (scene.skipSceneMode === GqlClientEditorSceneSkippingMode.BY_AUDIENCE
            || scene.skipSceneMode === GqlClientEditorSceneSkippingMode.SHOW_BY_AUDIENCE)
            && scene.skipSceneAudience?.by?.localId
        ) {
            if (scene.skipSceneAudience?.valueIds) {
                acc[scene.skipSceneAudience.by.localId] = {
                    ...acc[scene.skipSceneAudience.by.localId],
                    [scene.id]: {
                        valueIds: scene.skipSceneAudience.valueIds,
                        skipSceneMode: scene.skipSceneMode
                    }
                };
            }
        }
        return acc;
    }, {});
};
