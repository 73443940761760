import type { CsvParseResult, HeaderRow, Row } from "@sundaysky/csv";
import { csvParse } from "@sundaysky/csv";
import isUtf8 from "is-utf8";
import { csvMaxUploadSize } from "../../../../common/commonConst";
import { IDENTIFIER_DYNAMIC_ELEMENT_NAME } from "../../../../common/editorUtils";
import { SskyErrorCode } from "../../../../common/errors";
import { EnhancedError } from "../../errorBoundary/Components/EnhancedError";
import type { UploadResponse } from "../types";
import { isNameWithInvalidChars, SPECIAL_CHAR_VALIDATION } from "../../../../common/dynamicElementsUtils";

export const getCsvFileValidationError = async (file: File): Promise<Error | null> => {
    const csvExtension = /\.csv$/;
    if (!csvExtension.test(file.name)) {
        const err = new Error("File couldn't be uploaded. File must be a CSV.");
        return new EnhancedError(err, SskyErrorCode.CsvUploadUnsupportedFile);
    }

    if (file.size > csvMaxUploadSize) {
        const err = new Error("File couldn't be uploaded. File cannot be larger than 2MB.");
        return new EnhancedError(err, SskyErrorCode.CsvUploadFileTooBig);
    }

    if (file.size === 0) {
        const err = new Error("File couldn't be uploaded. File is empty");
        return new EnhancedError(err, SskyErrorCode.CsvUploadFileEmpty);
    }

    const arrayBuffer = await file.arrayBuffer();
    const utf8 = isUtf8(new Uint8Array(arrayBuffer));
    if (!utf8) {
        const err = new Error("File couldn't be uploaded. File must be UTF-8 encoded.");
        return new EnhancedError(err, SskyErrorCode.CsvUploadFileNotUtf8);
    }
    return null;
};

export const validateFeedCsv = async (file: File): UploadResponse => {
    const csvValidationError = await getCsvFileValidationError(file);
    if (csvValidationError !== null) {
        return { error: csvValidationError };
    }

    let isMissingIdColumn = true;
    let missingIdValueRows = [];
    let emptyColumns = [];
    let nonUniqueColumns = [];
    let isMissingDataRows = true;

    let rowIndex = 1;

    const onRowCallback = (onRowInput: { header: HeaderRow, row: Row }) => {
        rowIndex++;
        let idIndex = onRowInput.header.findIndex(header => header === IDENTIFIER_DYNAMIC_ELEMENT_NAME);
        const rowHasAnyValue = onRowInput.row.some(e => !!e);
        if (rowHasAnyValue && isMissingDataRows) {
            isMissingDataRows = false;
        }
        if (idIndex > -1 && !onRowInput.row[idIndex] && rowHasAnyValue) {
            missingIdValueRows.push(rowIndex);
        }
    };

    const parsedCsv: CsvParseResult = await csvParse({
        input: file,
        skipValueSets: true,
        onRowCallback
    });

    parsedCsv.header.forEach((header: string, index: number, array: string[]) => {
        if (!header?.trim()) {
            emptyColumns.push(`Column ${index + 1}`);
        }
        else if (index !== array.indexOf(header)) {
            nonUniqueColumns.push(header);
        }
        if (header === IDENTIFIER_DYNAMIC_ELEMENT_NAME) {
            isMissingIdColumn = false;
        }
    });

    const isValid = !isMissingIdColumn && !isMissingDataRows && missingIdValueRows.length === 0 && emptyColumns.length === 0 && nonUniqueColumns.length === 0;

    return { uploadResult: { isValid, fileName: file.name, isMissingIdColumn, missingIdValueRows, isMissingDataRows, emptyColumns, nonUniqueColumns } };
};

export const getElementNamesValidationError = (names: string[]): Error | null => {
    const hasDuplicates = (new Set(names)).size !== names.length;
    if (hasDuplicates) {
        const err = new Error("File couldn't be uploaded. File has duplicate column names");
        return new EnhancedError(err, SskyErrorCode.CsvUploadDuplicateHeaders);
    }

    const firstIllegalName = names.find(name => isNameWithInvalidChars(name));
    if (firstIllegalName) {
        const err = new Error(`File couldn't be uploaded. The field name ${firstIllegalName} contains illegal characters. ${SPECIAL_CHAR_VALIDATION}`);
        return new EnhancedError(err, SskyErrorCode.CsvUploadIllegalCharacterInHeaders);
    }


    return null;
};
