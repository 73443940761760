import { useReactiveVar } from "@apollo/client";
import { localEditorVideoPlayerControlVar } from "../../State";
import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";

export type UseVideoPlayerControl = {
    resetPlayer: () => void;
    playerKey: string;
    abortControllerRef: MutableRefObject<AbortController>
};

export const useVideoPlayerControl = (): UseVideoPlayerControl => {
    const videoPlayerControl = useReactiveVar(localEditorVideoPlayerControlVar);
    const abortControllerRef = useRef<AbortController>(new AbortController());
    useEffect(() => {
        if (!videoPlayerControl.playerKey || !videoPlayerControl.abortControllerRef) {
            localEditorVideoPlayerControlVar({
                playerKey: uuid(),
                abortControllerRef
            });
        }
        return () => {
            localEditorVideoPlayerControlVar({
                playerKey: "",
                abortControllerRef: null
            });
        };
    }, []);

    const resetPlayer = (): void => {
        videoPlayerControl.abortControllerRef?.current?.abort();
        abortControllerRef.current = new AbortController();
        localEditorVideoPlayerControlVar({
            playerKey: uuid(),
            abortControllerRef
        });
    };

    return { playerKey: videoPlayerControl.playerKey, resetPlayer, abortControllerRef: videoPlayerControl.abortControllerRef };
};
