import type { GqlClientExternalLibrary, GqlClientUpsertUserSettingsInput } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useUserSettingsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { setEditorExternalLibraries } from "../../Nooks";
import type { ExternalLibraryDetails, ExternalLibrarySettings } from "../../types";
import { localEditorUserNameVar } from "../../State";
import { useActiveAccountId } from "../../../newNav/hooks/model";
import { deepCloneObj } from "../../../../logic/common/generalUtils";

export type UseEditorUsageAcceptance = {
    isLoadingUserAcceptedTerms: boolean;
    hasUserAcceptedTerms: boolean;
    acceptUsage: () => Promise<void>;
    declineUsage: () => Promise<void>;
    hasUserDismissedLibraryChangeMessage: boolean;
    dismissLibraryChangeMessage: () => Promise<void>;
};

export const useEditorUsageAcceptance = (libraryId: GqlClientExternalLibrary): UseEditorUsageAcceptance => {
    const { loading, data } = useUserSettingsQuery({ variables: { id: localEditorUserNameVar() } });
    const accountId = useActiveAccountId();
    const externalLibrariesSettings: ExternalLibrarySettings[] = data?.userSettings?.externalLibraries || [];
    const hasUserAcceptedTerms: boolean = !!externalLibrariesSettings[libraryId]?.acceptedUsage;
    const hasUserDismissedLibraryChangeMessage = externalLibrariesSettings[libraryId]?.dismissedChangeMessageInAccounts?.includes(accountId);

    const getUpdatedExternalLibraries = (acceptedUsage: boolean): GqlClientUpsertUserSettingsInput => {
        return {
            externalLibraries: {
                ...externalLibrariesSettings,
                ...{
                    [libraryId]: {
                        ...(externalLibrariesSettings[libraryId] || {}),
                        acceptedUsage
                    }
                }
            }
        };
    };

    const getUpdatedLibraryAfterDismissChangeMessage = (): GqlClientUpsertUserSettingsInput => {
        let librarySettings: ExternalLibraryDetails = externalLibrariesSettings[libraryId]
            ? deepCloneObj(externalLibrariesSettings[libraryId])
            : { acceptedUsage: false };
        if (!librarySettings.dismissedChangeMessageInAccounts) {
            librarySettings.dismissedChangeMessageInAccounts = [];
        }
        librarySettings.dismissedChangeMessageInAccounts.push(accountId);

        return {
            externalLibraries: {
                ...externalLibrariesSettings,
                [libraryId]: librarySettings
            }
        };
    };

    const acceptUsage = async (): Promise<void> => {
        setEditorExternalLibraries(getUpdatedExternalLibraries(true));
    };

    const declineUsage = async (): Promise<void> => {
        setEditorExternalLibraries(getUpdatedExternalLibraries(false));
    };

    const dismissLibraryChangeMessage = async (): Promise<void> => {
        setEditorExternalLibraries(getUpdatedLibraryAfterDismissChangeMessage());
    };

    return {
        isLoadingUserAcceptedTerms: loading, hasUserAcceptedTerms, acceptUsage, declineUsage,
        hasUserDismissedLibraryChangeMessage, dismissLibraryChangeMessage
    };
}
;
