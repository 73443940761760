
// Element IDs
export const ELEMENT_IDS = {
    RECORD_LABEL: "record-label",
    MIC_VISUALIZER: "mic-visualizer",
    CANVAS_CONTAINER: "canvas-container",
    SETTINGS_WRAPPER: "settings-wrapper",
    MIC_WRAPPER: "mic-wrapper",
    COUNTDOWN_ANIMATION: "countdown_animation",
    TIMER_LABEL: "timer-label",
    PIP_WRAPPER: "pip-wrapper",
    BOTTOM_CONTAINER: "bottom-container",
    CONTROL_BAR: "control-bar",
    TIME_LIMIT_SCREEN: "time-limit-screen",
    SETTINGS_SCREEN: "settings-screen",
    SETTINGS_IMAGE: "settings-image",
    RETAKE_DIALOG: "retake-dialog"
} as const;

const PRIMARY_COLOR = "#0053E5";

export const pipStyles = {
    /* Global PIP Styles */
    global: `
        *, *::before, *::after {
            box-sizing: border-box;
        }
        * {
            margin: 0;
        }
        body { 
            margin: 0;
            height: 100vh;
            width: 100vw;
            position: relative;
            font-family: sans-serif;
        }
    `,
    /* PIP Window Styles */
    screenWrapper: `
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-clip: content-box;
        background-color: rgba(50, 50, 50, 1);
    `,
    cameraWrapper: `
        position: relative;
        height: 100%; 
    `,
    video: `
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    `,
    bottomContainer: `
        position: absolute;
        bottom: 0;
        left: 0px;
        right: 0px;
    `,
    controlBar: `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
    `,
    settingsButton: `
        width: 36px;
        height: 36px;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
    `,
    canvasContainer: `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    `,
    recordLabel: `
        width: 100%;
        display: block;
        text-align: center;
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        letter-spacing: 0.15px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.95);
        margin-bottom: -10px;
    `,
    canvas: `
        height: 100px;
    `,
    micContainer: `
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    countdownWrapper: `
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    `,
    micVisualizer: `
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        height: 36px;
        width: 36px;
    `,
    popoverText: `
        color: white;
    `,
    timesUpScreenWrapper: `
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-clip: content-box;
        background-color: rgba(50, 50, 50, 1);
    `,
    timesUpContainer: `
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    `,
    saveButton: `
        height: 24px;
        border-radius: 12px;
        background-color: #4CAF50;
        color: white;
        border: none;
        cursor: pointer;
        padding: 0 8px;
        font-weight: 600;
    `,
    applyButton: `
        height: 32px;
        border-radius: 16px;
        background-color: ${PRIMARY_COLOR};
        color: white;
        border: none;
        cursor: pointer;
        padding: 0 15px;
        font-weight: 600;
    `,
    reRecordButton: `
        display: flex;
        height: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background-color: white;
        color: ${PRIMARY_COLOR};
        border: none;
        cursor: pointer;
        padding: 0 8px;
        font-weight: 600;
    `,
    settingsScreen: `
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #323232;
        z-index: 5;
    `,
    exitButton: `
        position: absolute;
        top: -11px;
        right: -30px;
        background: none;
        border: none;
        width: 32px;
        height: 32px;
    `,
    selectContainer: `
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
    `,
    select: `
        background: #494949;
        color: white;
        border-radius: 8px;
        padding: 8px;
        border: none;
        cursor: pointer;
        border-right: 14px solid transparent;
        height: 40px;
        font-size: 12px;
    `,
    selectLabel: `
        color: white;
        font-family: sans-serif;
        font-size: 12px;
    `,
    settingsContent: `
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        max-width: 300px;
    `,
    /* Retake dialog styles */
    retakeDialogWrapper: `
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.50);
        z-index: 5;
    `,
    retakeDialog: `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0 16px;
        border-radius: 8px;
        max-width: 300px;
    `,
    retakeDialogTitleWrapper: `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        width: 100%;
    `,
    retakeDialogTitle: `
        font-size: 18px;
        font-weight: 400;
    `,
    retakeDialogCloseButtonWrapper: `
        cursor: pointer;
        width: 32px;
        height: 32px;
        padding: 8px;
    `,
    retakeDialogCloseButton: `
        width: 10px;
        height: 16px;
    `,
    retakeDialogContent: `
        display: inline;
        font-size: 14px;
    `,
    dialogActionsWrapper: `
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        width: 100%;
        padding: 8px 0;
    `,
    retakeDialogCancelButton: `
        background-color: white;
        color: ${PRIMARY_COLOR};
        border: none;
        border-radius: 18px;
        padding: 6px 16px;
        cursor: pointer;
        font-size: 14px;
    `,
    retakeDialogRetakeButton: `
        background-color: ${PRIMARY_COLOR};
        color: white;
        border: none;
        border-radius: 18px;
        padding: 6px 16px;
        cursor: pointer;
        font-size: 14px;
    `,
    noCameraAvailableContent: `
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    `,
    noCameraAvailableTitleContainer: `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        max-width: 325px;
    `,
    noAvailableCameraCloseButton: `
        height: 24px;
        width: 52px;
        border-radius: 12px;
        background-color: ${PRIMARY_COLOR};
        color: white;
        border: none;
        cursor: pointer;
        font-weight: 400;
    `,
    noAvailableCameraTitle: `
        width: 100%;
        display: block;
        text-align: center;
        color: white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;
        letter-spacing: 0.15px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.95);
    `
};
