import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, Fade, Paper, Popover, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import useId from "@mui/utils/useId";
import type { FC, FormEvent } from "react";
import React from "react";
import { useUpsertHelpCenterEntryMutation } from "../../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useTextField } from "../../../../../utils/hooks/useTextField";
import { useEditHelpCenterData, useEditHelpCenterOpen, useHelpCenterEntry } from "../../../../newNav/hooks/useEditHelpCenter";
import { setEditHelpCenterData, setEditHelpCenterOpen } from "../../../../newNav/nooks/helpCenter";

export const EditHelpCenterPopover: FC = () => {
    const open = useEditHelpCenterOpen();
    const data = useEditHelpCenterData();

    if (!data) return null;

    function handleClose() {
        setEditHelpCenterOpen(false);
    }

    return (
        <Popover
            disableEscapeKeyDown
            open={open && !!data?.anchorEl}
            anchorEl={data?.anchorEl}
            onClose={handleClose}
            TransitionProps={{
                onExited() {
                    setEditHelpCenterData(null);
                }
            }}
        >
            <EditHelpCenterPopoverContent
                buttonId={data.buttonId}
                variants={data.variants}
                selectedVariant={data.selectedVariant}
                close={handleClose}
            />
        </Popover>
    );
};

type EditHelpCenterPopoverContentProps = {
    buttonId: string;
    variants: Record<string, string> | null;
    selectedVariant: string | null;
    close: () => void;
}

const EditHelpCenterPopoverContent: FC<EditHelpCenterPopoverContentProps> = (props) => {
    return (
        <Paper>
            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {props.variants && (
                                <TableCell>
                                    Variant
                                </TableCell>
                            )}
                            <TableCell>
                                Article ID
                            </TableCell>
                            <TableCell>
                                Section ID
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.variants
                            ? Object.values(props.variants).map(variant => (
                                <EditEntryRow
                                    key={variant}
                                    buttonId={props.buttonId}
                                    variant={variant}
                                    selected={variant === props.selectedVariant}
                                />
                            ))
                            : <EditEntryRow
                                buttonId={props.buttonId}
                                variant={null}
                                selected={false}
                            />
                        }
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    onClick={props.close}
                >
                    Close
                </Button>
            </DialogActions>
        </Paper>
    );
};

type EditEntryRowProps = {
    buttonId: string;
    variant: string | null;
    selected: boolean;
}

const EditEntryRow: FC<EditEntryRowProps> = (props) => {
    const entry = useHelpCenterEntry(props.buttonId, props.variant);

    const initialArticleId = entry?.articleId ?? "";
    const articleId = useTextField({
        initialValue: initialArticleId,
        validate: validateArticleId
    });
    const initialSectionId = entry?.sectionId ?? "";
    const sectionId = useTextField({
        initialValue: initialSectionId
    });

    const [mutate, { loading }] = useUpsertHelpCenterEntryMutation({
        variables: {
            input: {
                buttonId: props.buttonId,
                variant: props.variant,
                articleId: articleId.value.trim() || null,
                sectionId: sectionId.value.trim() || null
            }
        }
    });
    const formId = useId();

    const hasChanges = (
        articleId.value !== initialArticleId
        || sectionId.value !== initialSectionId
    );

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        articleId.setDirty(true);
        sectionId.setDirty(true);
        if (!articleId.isValid || !sectionId.isValid) return;
        if (!hasChanges) return;
        void mutate();
    }

    return (
        <TableRow selected={props.selected}>
            {!!props.variant && (
                <TableCell>
                    <Typography sx={{ marginBottom: "20px" }}>
                        {props.variant}
                    </Typography>
                </TableCell>
            )}
            <TableCell>
                <TextField
                    {...articleId.textFieldProps}
                    required
                    autoComplete="off"
                    margin="dense"
                    placeholder="12345678912345"
                    InputProps={{ inputProps: { form: formId } }}
                    disabled={loading}
                />
            </TableCell>
            <TableCell>
                <TextField
                    {...sectionId.textFieldProps}
                    autoComplete="off"
                    margin="dense"
                    placeholder="links_non_ssky_page"
                    InputProps={{ inputProps: { form: formId } }}
                    disabled={loading}
                />
            </TableCell>
            <TableCell>
                <Fade in={hasChanges}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        loading={loading}
                        form={formId}
                        sx={{ marginBottom: "20px" }}
                        disabled={!articleId.isValid || !sectionId.isValid}
                    >
                        Save
                    </LoadingButton>
                </Fade>
                <form
                    hidden
                    id={formId}
                    onSubmit={handleSubmit}
                />
            </TableCell>
        </TableRow>
    );
};

function validateArticleId(value: string) {
    if (value.trim().length === 0) return "Missing article ID";
    return null;
}
