import type { GqlClientEditorAssetLibraryMediaFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { assetMediaVar } from "../../../model";
import type { AssetMediaVar } from "../../Nooks";
import { useReactiveVar } from "@apollo/client";

export const useAssetLibraryMedia = (): AssetMediaVar => {
    return useReactiveVar(assetMediaVar);
};

export const useLibraryAsset = (id: string): GqlClientEditorAssetLibraryMediaFragment => {
    const { assetLibraryMediaByGqlMediaId } = useAssetLibraryMedia();
    return assetLibraryMediaByGqlMediaId[id];
};

export const useLibraryOriginalAsset = (id: string): GqlClientEditorAssetLibraryMediaFragment => {
    const { assetLibraryMediaByGqlMediaId } = useAssetLibraryMedia();

    const media = assetLibraryMediaByGqlMediaId[id];
    return media?.originalMediaId ? assetLibraryMediaByGqlMediaId[media.originalMediaId] : media;
};

export const useLibraryDirectAncestorMedia = (id: string): GqlClientEditorAssetLibraryMediaFragment => {
    const { assetLibraryMediaByGqlMediaId } = useAssetLibraryMedia();

    const media = assetLibraryMediaByGqlMediaId[id];
    return media?.directAncestorId ? assetLibraryMediaByGqlMediaId[media.directAncestorId] : media;
};
