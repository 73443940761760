import {
    addAssignedStory,
    addTag,
    ASSIGN_TO_ALL_STORIES_LABEL,
    buildDataElement,
    changeAssignedStories,
    changeAssignedToAllStories,
    changeContentType,
    changeDescription,
    changeName,
    changeStatus,
    findDataElementById,
    getDataElementAssignedStories,
    getDataElementAssignedToAllStories,
    getDataElementDisplayName,
    getDataElementOrigin,
    getDataElementTagKey,
    getOriginDisplayName,
    hasTag,
    removeAssignedStory,
    removeTag
} from "../../../../logic/DataElements/DataElementsManager";
import { LOGIC_MEDIA_TYPES } from "../../../../logic/vlx/consts";
import ValueSetController from "../ValueSetController";
import DataLibraryController from "./DataLibraryController";
import type { CreativeDataElement, DataElement, FeedDataElement } from "../../../../../common/types/dataElement";
import { DataElementContentTypes, DataElementOrigins } from "../../../../../common/types/dataElement";
import type { Story } from "../../../../../common/types/story";

let { adsDataElements } = require("../../../../../common/commonConst");

class DataElementsTabContentController {
    static popUpLabels = {
        Status: "Status",
        Type: "Type",
        Name: "Name",
        Description: "Description",
        ValueSet: "Value Set",
        Logic: "Logic",
        Tag: "Tag",
        Convert: "Convert To Studio Data",
        AssignToStory: "Assign To Story"
    };

    static editDataElementAttribute = (editedDataElement, value, editPopUpLabel) => {
        let newDataElement = editedDataElement;
        switch (editPopUpLabel) {
            case DataElementsTabContentController.popUpLabels.Name:
                newDataElement = changeName(editedDataElement, value);
                break;
            case DataElementsTabContentController.popUpLabels.Description:
                newDataElement = changeDescription(editedDataElement, value);
                break;
            case DataElementsTabContentController.popUpLabels.Status:
                newDataElement = changeStatus(editedDataElement, value);
                break;
            case DataElementsTabContentController.popUpLabels.Type:
                newDataElement = changeContentType(editedDataElement, value);
                break;
            case DataElementsTabContentController.popUpLabels.AssignToStory:
                newDataElement = changeAssignedStories(editedDataElement, value);
                break;
        }
        return newDataElement;
    };

    /**
     * Verifies value list values matches the data element type
     * Returns True if the data element value set is valid, false otherwise
     * @param dataElement
     * @returns {boolean|*}
     */
    static validateValueSetForDataElement = (dataElement) => {
        let valueList = ValueSetController.buildValueListFromDataElement(dataElement);
        let typeIsNumber = dataElement.type === LOGIC_MEDIA_TYPES.Number;
        return valueList.every((value) => {
            return !(typeIsNumber && isNaN(Number(value.text)));
        });
    };

    static createStubDataElement(origin = DataElementOrigins.Feed) {
        return buildDataElement({
            id: undefined,
            type: DataElementContentTypes.String,
            origin
        });
    }

    static getTextForNoItemSelected = (tabId, isFullDemoFlowBuilder?: boolean) => {
        if (tabId === DataLibraryController.studioDataTabId) {
            return isFullDemoFlowBuilder ? "Select an Audience to view its detail" : "Select a Studio Data to view its detail";
        }
        else if (tabId === DataLibraryController.creativeDataTabId) {
            return "Select a Content Set Data to view its details";
        }
        return "Select a data element to view its details";
    };

    static validateDataElementName = (name, origin, allDataElements, isFullDemoFlowBuilder: boolean) => {
        if (name.trim() === "") {
            return {
                isValid: false,
                helperText: "Value must not be blank"
            };
        }

        const format = /^[a-zA-Z\s\.\,\-\_\d]+$/;
        if (!format.test(name)) {
            return {
                isValid: false,
                helperText: "Enter only letters, numbers, spaces, commas, periods, underscores & hyphens."
            };
        }

        if (origin === DataElementOrigins.Derived) {
            if (name.includes(".")) {
                return {
                    isValid: false,
                    helperText: getOriginDisplayName(origin, isFullDemoFlowBuilder) + " name must not include '.'"
                };
            }
        }

        // Check if name does not exist in the dataElements list
        let existingDE = (allDataElements || []).find((de) => getDataElementDisplayName(de).toLowerCase() === name.toLowerCase());
        if (existingDE) {
            let deType = getOriginDisplayName(existingDE.origin, isFullDemoFlowBuilder);
            return {
                isValid: false,
                helperText: deType + " with this name already exists"
            };
        }

        return {
            isValid: true,
            helperText: ""
        };
    };

    static anySystemDataElements = (dataElementsIdsArray: string[], dataElements: DataElement[]): boolean => {
        return dataElementsIdsArray.some((dataElementId) => {
            let dataElement = findDataElementById(dataElementId, dataElements);
            return Boolean(dataElement && dataElement.origin === DataElementOrigins.System);
        });
    };

    static anyAdsDataElements = (dataElementsIdsArray: string[], dataElements: DataElement[]): boolean => {
        return dataElementsIdsArray.some((dataElementId) => {
            let dataElement = findDataElementById(dataElementId, dataElements);
            return Boolean(dataElement && DataElementsTabContentController.isAdsDataElement(dataElement));
        });
    };
    static allSystemOrAdsDataElements = (dataElementsIdsArray: string[], dataElements: DataElement[]) => {
        return dataElementsIdsArray.every((dataElementId) => {
            const dataElement = findDataElementById(dataElementId, dataElements);
            return DataElementsTabContentController.isSystemOrAdsDataElements(dataElement);
        });
    };

    static removeSystemAndAdsDataElements = (dataElementsIdsArray: string[], dataElements: DataElement[]) => {
        return dataElementsIdsArray.filter((id) => {
            const dataElement = findDataElementById(id, dataElements);
            return !DataElementsTabContentController.isSystemOrAdsDataElements(dataElement);
        });
    };

    static isSystemOrAdsDataElements = (dataElement: DataElement): boolean =>
        dataElement.origin === DataElementOrigins.System || DataElementsTabContentController.isAdsDataElement(dataElement);

    static getDataElementDisplayNameForTable = (dataElement) => {
        if (
            getDataElementOrigin(dataElement) === DataElementOrigins.System ||
            (adsDataElements as FeedDataElement[]).find((de) => getDataElementDisplayName(de) === getDataElementDisplayName(dataElement))
        ) {
            return `Sys: ${getDataElementDisplayName(dataElement)}`;
        }

        return getDataElementDisplayName(dataElement);
    };

    static removeTag = ({ tag, selected, dataElements }) => {
        return selected.reduce((acc, selectedId) => {
            const oldDe = findDataElementById(selectedId, dataElements);

            if (!hasTag(oldDe, getDataElementTagKey(tag))) {
                return acc;
            }

            const newDe = removeTag(oldDe, getDataElementTagKey(tag));
            // Avi Garfunkel - 26th sep 18
            // -------------------------------
            // I have no idea why, but dataLibraryContainer
            // expects an array of topples with the old data element(de) in index 0 and new de in index 1
            // (AS IF
            //   A.Object isn't more readable? and
            //   B. The action to update a data element doesn't need the old one i
            //      think this is pretty redundant)
            // therefor what I suggest is:
            // TODO: get rid of the handleUpdateDataElements in DataLibraryContainer and just update the actions
            //  (this will require to refactor this function and the other ones in DataElementTabContent)
            return [...acc, [oldDe, newDe]];
        }, []);
    };

    static addTag = ({ tag, selected, dataElements }) => {
        return selected.reduce((acc, selectedId) => {
            const oldDe = findDataElementById(selectedId, dataElements);

            if (hasTag(oldDe, getDataElementTagKey(tag))) {
                return acc;
            }
            const newDe = addTag(oldDe, getDataElementTagKey(tag));

            return [...acc, [oldDe, newDe]];
        }, []);
    };

    static addAssignedStory = (storyId, selectedDataElementIds, allDataElements) => {
        return selectedDataElementIds.map((selectedId) => {
            const oldDe: CreativeDataElement = findDataElementById(selectedId, allDataElements) as CreativeDataElement;
            if (!getDataElementAssignedStories(oldDe).includes(storyId)) {
                const newDe = addAssignedStory(oldDe, storyId);
                return [oldDe, newDe];
            }
        });
    };

    static removeAssignedStory = (storyId, selectedDataElementIds, allDataElements, allStoriesIds: string[]) => {
        return selectedDataElementIds.map((selectedId) => {
            const oldDe: CreativeDataElement = findDataElementById(selectedId, allDataElements) as CreativeDataElement;
            if (getDataElementAssignedStories(oldDe).includes(storyId)) {
                const newDe = removeAssignedStory(oldDe, storyId);
                return [oldDe, newDe];
            }
            else if (getDataElementAssignedToAllStories(oldDe)) {
                const allOtherStories = allStoriesIds.filter((sId) => sId !== storyId);
                let newDe = changeAssignedStories(oldDe, allOtherStories);
                newDe = changeAssignedToAllStories(newDe, false);
                return [oldDe, newDe];
            }
        });
    };

    static handleAssignedToAllStories = (checked: boolean, selectedDataElementIds: string[], allDataElements: DataElement[]) => {
        return selectedDataElementIds.map((selectedId) => {
            const oldDe: CreativeDataElement = findDataElementById(selectedId, allDataElements) as CreativeDataElement;
            let newDe = changeAssignedStories(oldDe, []);
            newDe = changeAssignedToAllStories(newDe, checked);
            return [oldDe, newDe];
        });
    };

    static getAssignedStoriesNames = (dataElement: CreativeDataElement, stories: Story[]) => {
        const assignedStories: string[] = getDataElementAssignedStories(dataElement);
        const assignedToAllStories: boolean = getDataElementAssignedToAllStories(dataElement);
        if (assignedToAllStories) {
            return ASSIGN_TO_ALL_STORIES_LABEL;
        }
        else if (assignedStories.length > 0) {
            return stories
                .filter((story) => assignedStories.includes(story.id))
                .map((story) => story.name)
                .join(", ");
        }
        else {
            return "";
        }
    };

    /**
     * Returns whether the data element is ads new system data element
     * @param dataElement - A data element object
     * @return {boolean} whether the data element is ads new system data element
     */
    static isAdsDataElement = (dataElement: DataElement): boolean => {
        return Boolean(adsDataElements.find((de) => getDataElementDisplayName(de) === getDataElementDisplayName(dataElement)));
    };
}

export default DataElementsTabContentController;
