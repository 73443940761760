import { debounce } from "lodash";
import { useEffect } from "react";
import { setMediaDragAndDropSnackbarState } from "../../Nooks";
import { EditorMediaDragAndDropSnackbarState } from "../../types";

const debouncedSetMediaDragAndDropSnackbarState = debounce(setMediaDragAndDropSnackbarState, 100);

export function useEditorMediaDragAndDropSnackbar(
    isFileDragActive: boolean,
    isFileDragAccept: boolean,
    isFileDragReject: boolean
) {
    useEffect(
        () => {
            if (isFileDragActive) {
                if (isFileDragAccept) {
                    debouncedSetMediaDragAndDropSnackbarState(EditorMediaDragAndDropSnackbarState.INFO);
                }
                else if (isFileDragReject) {
                    debouncedSetMediaDragAndDropSnackbarState(EditorMediaDragAndDropSnackbarState.ERROR);
                }
            }
            return () => {
                setMediaDragAndDropSnackbarState(EditorMediaDragAndDropSnackbarState.CLOSED);
            };
        },
        [
            isFileDragActive,
            isFileDragAccept,
            isFileDragReject
        ]
    );
}
