import { useReactiveVar } from "@apollo/client";
import { localEditorSelectedSceneIdVar } from "../../State";
import type { SceneId, StandardSetterFn } from "../../types";
import { selectScene } from "../../Nooks";

export type UseProjectSelectedSceneId = {
    projectSelectedSceneId: SceneId;
    setProjectSelectedSceneId: StandardSetterFn<SceneId>;
};

export const useProjectSelectedSceneId = (): UseProjectSelectedSceneId => {
    const projectSelectedSceneId = useReactiveVar(localEditorSelectedSceneIdVar);

    return { projectSelectedSceneId, setProjectSelectedSceneId: selectScene };
};