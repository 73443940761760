import type {
    GqlClientStudioElementFragment,
    GqlClientEditorViewerProfileFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientDataElementType } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type {
    ExtendedViewerProfileItem,
    ViewerProfileDataElementValues,
    ViewerProfileItemValue,
    StudioValueSetItem
} from "../types";

function getExtendedViewerProfileItem(viewerProfileItemValue: ViewerProfileItemValue, studioElement: GqlClientStudioElementFragment): ExtendedViewerProfileItem {
    let value: ViewerProfileItemValue = null;

    // here we make some adjustments to align the value from the viewer profile to the current studio elements definition
    // because it is possible that the studio elements definition has been changed since the viewer profile was last saved
    const isStudioElementValueSet: boolean = !!studioElement.valueSet?.length;
    const isProfileItemValueSet: boolean = isValueOfValueSet(viewerProfileItemValue);

    if (isStudioElementValueSet && isProfileItemValueSet) {
        const viewerProfileValueSetValue: StudioValueSetItem = viewerProfileItemValue as StudioValueSetItem;
        // option1: Both values are of value set and the id of the profile value exists in the studio element's value set
        // (it's possible that the textual value has been changed so we take it from the studio element definition and not from the profile value)
        value = studioElement.valueSet.find(valueSetItem => valueSetItem.id === viewerProfileValueSetValue.id);
        // option 2: the value used to be in the value set, but it was deleted from the value set
        // so we are setting it as a custom value ==> just a string
        if (!value) {
            value = viewerProfileValueSetValue.value;
        }
    }
    else if (isStudioElementValueSet && !isProfileItemValueSet) {
        // option 3: the value wasn't part of the value set, but now it is
        value = studioElement.valueSet.find(valueSetItem => valueSetItem.value === viewerProfileItemValue);

        // option 4: the value wasn't and still isn't part of the value set
        if (!value) {
            value = viewerProfileItemValue;
        }
    }
    else if (!isStudioElementValueSet && isProfileItemValueSet) {
        const viewerProfileValueSetValue: StudioValueSetItem = viewerProfileItemValue as StudioValueSetItem;
        // option 5: the value was part of a value set but the studio element doesn't have value set anymore
        value = viewerProfileValueSetValue.value;
    }
    else {
        // option 6: both are non value set
        value = viewerProfileItemValue;
    }

    return {
        id: studioElement.localId,
        name: studioElement.name,
        dataType: studioElement.dataType,
        valueSet: studioElement.valueSet,
        value
    };
}

export function getExtendedViewerProfileByStudioElements(viewerProfile: ViewerProfileDataElementValues, studioElements: GqlClientStudioElementFragment[]): ExtendedViewerProfileItem[] {
    return studioElements.map(studioElement => {
        const viewerProfileItemValue: ViewerProfileItemValue = viewerProfile[studioElement.localId];
        return getExtendedViewerProfileItem(viewerProfileItemValue, studioElement);
    });
}

export function isValueOfValueSet(elementValue: ViewerProfileItemValue): elementValue is StudioValueSetItem {
    return elementValue && (elementValue as StudioValueSetItem).value !== undefined;
}

export function getViewerProfileItemStringValue(elementValue : ViewerProfileItemValue): string {
    return elementValue ? (isValueOfValueSet(elementValue) ? elementValue.value : elementValue) : "";
}

export function getMostRecentlyUpdatedViewerProfileId(profiles: GqlClientEditorViewerProfileFragment[]): string {
    if (!profiles?.length) return null;
    return profiles.slice().sort((a, b) => {
        const dateA: Date = new Date(a.updated);
        const dateB: Date = new Date(b.updated);
        return dateB.getTime() - dateA.getTime();
    })[0].id;
}

export function getViewerProfileDataElementItems(viewerProfileValues: ExtendedViewerProfileItem[]): ViewerProfileDataElementValues {
    const items: ViewerProfileDataElementValues = {};

    viewerProfileValues.forEach(viewerProfileItem => {
        const viewerProfileItemValue: ViewerProfileItemValue = viewerProfileItem.value;
        if (viewerProfileItemValue) {
            let itemValueToUpdate: ViewerProfileItemValue = null;
            if (isValueOfValueSet(viewerProfileItemValue)) { //value is of value set
                itemValueToUpdate = {
                    id: viewerProfileItemValue.id,
                    value: viewerProfileItemValue.value
                };
            }
            else if (viewerProfileItem.valueSet?.length > 0) { //value is custom, in an element that is a value set
                itemValueToUpdate = {
                    id: "custom-value-id",
                    value: viewerProfileItemValue as string
                };
            }
            else { // straightforward value
                itemValueToUpdate = viewerProfileItemValue;
            }
            items[viewerProfileItem.id] = itemValueToUpdate;
        }
    });

    return items;
}

export const getPresets = (viewerProfileValues: ViewerProfileDataElementValues, usedStudioElements: GqlClientStudioElementFragment[]): Record<string, string> => {
    const viewerProfile = getExtendedViewerProfileByStudioElements(viewerProfileValues, usedStudioElements);

    return viewerProfile.reduce((acc, item) => {
        acc[item.name] = getViewerProfileItemStringValue(item.value);
        return acc;
    }, {});
};
