export const STATUS_NEW = 0;
export const STATUS_UPDATED = 1;
export const STATUS_NONE = 2;

export const FILTER_ALL_SCENES = "all";
export const FILTER_ALL_UNUSED_SCENES = "unused";

export const PROPERTIES_PANEL_TABS = {
    SCENE_CONTENT_TAB: 0,
    SCENE_VALIDATION_TAB: 1,
    NEXT_SCENE_TAB: 2,
    LOGIC_TAB: 3,
    STORY_SETTINGS_TAB: 4,
    DESCRIPTIVE_TRANSCRIPT_TAB: 5
};

export const DERIVED = "derived";

export const UPLOAD_ACTIONS = {
    UPLOAD: "upload",
    REPLACE: "replace",
    REPLACE_AND_UNARCHIVE: "replaceAndUnarchive",
    CANCEL: "cancel"
};

export const DATA_ELEMENT_ORIGIN = {
    FEED: "feed",
    DERIVED: "derived",
    SYSTEM: "system",
    CREATIVE: "Creative"
};

export const DATA_ELEMENT_STATUS = {
    PENDING: "pending",
    APPROVED: "approved",
    MISSING: "missing"
};

export const ANALYTICS_FLOW_COMBINATION = {
    MIXED: "mixed",
    CRM_ONLY: "crm",
    ADS_ONLY: "ads"
};

export const ANALYTICS_PRODUCT_TYPE = {
    CRM: "Owned Media",
    ADS: "Paid Media"
};

export const ANALYTICS_VIEW_LEVEL = {
    ACCOUNT: "account",
    PROGRAM: "program"
};

export const STATUS_REQUEST = "request";

export const HUB_SF_ID = "SF-00000402";
export const PLATFORM_SF_ID = "SF-00000792";

export const Utf8Bom = "\uFEFF";

// minified player loaded only in production
export const SSKY_PLAYER_MIN = "https://play.sundaysky.com/main/sundaysky-player.grey.en-us.min.js";
export const SSKY_PLAYER = "https://play.sundaysky.com/main/sundaysky-player.grey.en-us.js";

export const LOCAL_PREVIEW_SERVICE_URL = "http://localhost:3456/create-preview";
export const STG_PREVIEW_SERVICE_URL = "https://hyxlyoplp2.execute-api.us-east-1.amazonaws.com/staging/create-preview";
export const PROD_PREVIEW_SERVICE_URL = "https://api.sundaysky.com/hub-preview/create-preview";

export const PLAYER_ERROR_MESSAGES = {
    NO_INPUT: "Can't play video because there's no input",
    INVALID_JSON: "Can't play video because of invalid JSON format",
    NO_SCENES: "Select scenes to preview from the list above"
};

// Google Analytics Event Categories, Actions and Labels
export const GA = {
    CAT: {
        SMARTVIDEO_SINGLE_PREVIEW: "SmartVideo Single Preview",
        PROJECT_ACCOUNT_SELECTION: "Projects Account Selection",
        PROJECT_DEFINITION: "Project Definition",
        PROJECT_VERSIONING: "Project Versioning",
        ENGAGEMENT_ANALYTICS: "Engagement Analytics",
        HIGHLIGHT_PARTICIPATING_SCENES: "Highlight Participating Scenes",
        PROGRAM_SELECTION: "Program Selection",
        SMARTVIDEO_PREVIEW: "SmartVideo Preview",
        EDIT_PREVIEW_DATA: "Edit Preview Data",
        PROGRAM_OVERVIEW: "Program Overview",
        CHANGE_PRESETS: "Change Presets",
        PLACEHOLDER_HIGHLIGHT_PREVIEW: "Placeholder Highlights Preview",
        LAUNCH_VIEWER_EXPERIENCES: "Launch Viewer Experiences",
        SHARE_SMARTVIDEO_PREVIEW: "Share SmartVideo Preview",
        SMARTVIDEO_SIDE_BY_SIDE_PREVIEW: "SmartVideo Side by Side View",
        DELIVERY_CHANNELS: "Delivery channels"
    },
    ACT: {
        // player single
        PLAYER_LOADED: "Player Loaded",
        PLAY_REQUESTED: "Play Requested",
        STARTED_PLAYING: "Started Playing",
        VIEW_PROGRESS: "View Progress",
        LAST_SCENE_VIEWED: "Last Scene Viewed",
        ERROR: "Error",
        // project account selection
        OPEN_SEARCH: "Open Search",
        CHANGED_ACCOUNT: "Changed account",
        // project definition
        CHANGE_PROJECT_DESC: "Changed project description",
        UPDATE_PROJECT_THUMB: "Uploaded project thumbnail",
        CREATED_VERSION: "Created version",
        // Engagement Analytics
        CHANGE_CRM_START: "Change CRM start date",
        CHANGE_CRM_END: "Change CRM end date",
        CHANGE_ADS_DATE: "Change Ads date range",
        CHART_TAB_CHANGED: "Chart tab changed to",
        // Highlight participating scenes
        CLICK_PROGRAM_LINK: "Click program link",
        SELECTED_PROGRAM_CARD: "Select program card",
        DESELECT_PROGRAM_CARD: "Deselect program card",
        // Program Selection
        CLICK_RIGHT_CAROUSEL_BUTTON: "Click right Carousel button",
        CLICK_LEFT_CAROUSEL_BUTTON: "Click left Carousel button",
        SELECT_PROGRAM_CARD: "Select program card",
        CLICK_PLAYER_BUTTON: "Click the play button on the Video Player",
        // edit preview data
        RADIO_BTN: "Radio button",
        FREE_TXT: "Free Text",
        DROPDOWN: "Dropdown",
        DATE_FLD: "date field",
        IMG_UPLOAD: "Image Upload Field",
        // change preset
        SELECT_PRESET: "Make Selection from Preset Dropdown",
        // share
        CLICK_SHARE_ICN: "Click on Share URL Icon",
        CLICK_COPY: "Click on 'Copy'",
        // smartvideo side by side preview (+ player singe actions)
        GLOBAL_PLAY: "Global Control Play",
        GLOBAL_PAUSE: "Global Control Pause",
        GLOBAL_MUTE: "Global Control Mute",
        GLOBAL_AUDIO_SWITCH: "Global Control Audio Switch",
        GLOBAL_RESTART: "Global Control Restart Videos",
        // Delivery channels
        DC_MOCK: "Open Delivery Channel Mock up",
        // Placeholder Highlights Preview
        TOGGLE_PLACEHOLDER_HIGHLIGHTS: "Toggle Placeholder Highlights",
        // program overview
        TOGGLE_SBS: "Toggle Single Player \\ Side by Side"
    },
    LBL: {
        PLAY_CONTENT: "Plays content based on the selected preset / edited preview data",
        CHANGE_SCENE: "Change scene library selection and highlights on the same page",
        EXTRA_PROGRAMS: "Shows additional program cards on the same page"
    }
};

export const PRIORITIZED_LIST_PADDING = 5;
export const CANVAS_DIMENSIONS = { width: 480, height: 270 }; // scenePart's canvas dimensions
export const SUPPORTED_BROWSERS = ["Chrome", "Chrome Headless"];

export const ALPHA_NUMERIC_MESSAGE = "Enter A-Z, 0-9 value";

export const DATA_ELEMENT_NAME_MAX_LENGTH = 40;
export const DATA_ELEMENT_DESCRIPTION_MAX_LENGTH = 400;

export const DATA_ELEMENT_SPECIAL_VALUE_PREFIX = "~~~";
export const DATA_ELEMENT_VALUE_OTHER = "~~~OTHER~~~";
export const DATA_ELEMENT_VALUE_EMPTY = "~~~EMPTY~~~";
export const DATA_ELEMENT_VALUE_MISSING = "~~~MISSING~~~";
export const DATA_ELEMENT_VALUE_OTHER_OR_MISSING = "~~~OTHER~OR~MISSING~~~";
