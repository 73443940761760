import { useReactiveVar } from "@apollo/client/react/hooks";
import { helpCenterDataVar, helpCenterOpenVar } from "../../model";

export function useHelpCenterData() {
    return useReactiveVar(helpCenterDataVar);
}

export function useHelpCenterOpen() {
    return useReactiveVar(helpCenterOpenVar);
}
