import {
    useProgramVersionAnimatedWireframesCompatabilityChangedSubscription,
    useProgramVersionEditorAspectRatioUpdatedSubscription,
    useProgramVersionEditorCustomBackgroundUpdatedSubscription,
    useProgramVersionEditorDataLibraryChangedSubscription,
    useProgramVersionEditorLineupUpdatedSubscription,
    useProgramVersionEditorPlaceholderUpdatedSubscription,
    useProgramVersionEditorSceneCreatedSubscription,
    useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription,
    useProgramVersionEditorScenesCreatedSubscription,
    useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription,
    useProgramVersionEditorSceneUpdatedSubscription,
    useProgramVersionEditorSelectedBcCollectionUpdatedSubscription,
    useProgramVersionEditorThemeUpdatedSubscription,
    useProgramVersionEditorScenesUpdatedSubscription,
    useProgramVersionEditorScenePlaceholdersUpdatedSubscription,
    useEditorProgramVersionLogicRemovedSubscription
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getEditorIsPreviewM2, getSelectedSceneId, selectScene, setEditorIsPreviewM2 } from "../../Nooks";

export const useEditorProgramVersionSceneSubscriptions = (programVersionId: string): void => {
    useProgramVersionEditorSceneCreatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorSceneUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorSceneSelectedAnimatedWireframeUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorScenePlaceholdersAndSelectedAnimatedWireframeUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorScenePlaceholdersUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorPlaceholderUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorDataLibraryChangedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorAspectRatioUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorSelectedBcCollectionUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorThemeUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorCustomBackgroundUpdatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionEditorLineupUpdatedSubscription({
        variables: { programVersionId },
        onData: (options) => {
            const newSceneLineup = options.data.data?.programVersionEditorLineupUpdated?.sceneLineup;
            const projectSelectedSceneId = getSelectedSceneId();
            if (newSceneLineup && projectSelectedSceneId && !newSceneLineup.includes(projectSelectedSceneId)) {
                selectScene(newSceneLineup[0] || null);
            }
        }
    });

    useProgramVersionEditorScenesCreatedSubscription({
        variables: { programVersionId }
    });

    useProgramVersionAnimatedWireframesCompatabilityChangedSubscription({
        variables: { programVersionId },
        onData: () => {
            if (getEditorIsPreviewM2()) {
                setEditorIsPreviewM2(false);
            }
        }
    });

    useProgramVersionEditorScenesUpdatedSubscription(
        { variables: { programVersionId } }
    );

    useEditorProgramVersionLogicRemovedSubscription(
        { variables: { programVersionId } }
    );
};
