import { useReactiveVar } from "@apollo/client";
import type { LpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import type { MutableRefObject } from "react";
import { useEffect, useRef } from "react";
import { useEditorProgramForLandingPageBuilderQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { deepCloneObj } from "../../../../logic/common/generalUtils";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { setLandingPageConfiguration, setLandingPageInitialConfiguration } from "../../Nooks";
import { localEditorLandingPageConfigurationVar, updateAssetLibraryFontsManagerVar, updateCcFontManagerVar } from "../../State";
import { createInitialLpConfiguration, getInitialLpConfigurationInput, updateConfigurationWithCurrentSettings } from "../../Utils/LandingPageBuilder";


export type UseUpdatedLandingPageConfiguration = {
    loading: boolean;
    configuration: LpConfiguration;
    originalConfiguration: MutableRefObject<LpConfiguration>;
}

export const useUpdatedLandingPageConfiguration = (programId: string): UseUpdatedLandingPageConfiguration => {
    const { loading, data } = useEditorProgramForLandingPageBuilderQuery({ variables: { programId } });
    const configuration = useReactiveVar(localEditorLandingPageConfigurationVar);
    const originalConfiguration = useRef(deepCloneObj(configuration));
    const isNewVideoPageEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);

    useEffect(() => {
        if (loading) return;
        const updateConfigurationInput = getInitialLpConfigurationInput(data.editorProgram);
        const updatedInitialConfiguration = createInitialLpConfiguration(updateConfigurationInput);
        setLandingPageInitialConfiguration(updatedInitialConfiguration);
        const programVersionDraft = data.editorProgram.programVersionDraft;
        updateCcFontManagerVar(programVersionDraft.ccLibraryVersion?.fonts);
        updateAssetLibraryFontsManagerVar(programVersionDraft.assetLibrary?.fonts);
        if (data?.editorProgram?.landingPage?.configuration) {
            const updatedConfiguration = updateConfigurationWithCurrentSettings(data.editorProgram.landingPage.configuration, updateConfigurationInput);
            setLandingPageConfiguration(updatedConfiguration);
            originalConfiguration.current = deepCloneObj(updatedConfiguration);
        }
        else {
            setLandingPageConfiguration(updatedInitialConfiguration);
            originalConfiguration.current = deepCloneObj(updatedInitialConfiguration);
        }
    }, [loading]);

    return {
        loading: isNewVideoPageEnabled && (loading || !configuration),
        configuration,
        originalConfiguration
    };
};
