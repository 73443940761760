import { useReactiveVar } from "@apollo/client";
import type { GqlClientEditorSceneFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { localEditorSelectedFramePerSceneVar } from "../../State";
import { selectFrame } from "../../Nooks";

export type UseSelectedFrame = {
    frameIndex: number;
    setFrameIndex: (frameIndex: number) => void;
};

export const useSelectedFrame = (sceneId: GqlClientEditorSceneFragment["id"]): UseSelectedFrame => {
    const frameIndex = useReactiveVar(localEditorSelectedFramePerSceneVar)[sceneId] ?? 0;

    const setFrameIndex = (selectedFrameIndex: number): void => {
        return selectFrame(sceneId, selectedFrameIndex);
    };

    return { frameIndex, setFrameIndex };
};
