import {
    addNoCameraAvailableScreen,
    addRecordLabel,
    addSettingScreen,
    addTimeLimitScreen,
    addVideoStreamToPip,
    MAX_RECORDING_TIME,
    openPendingPipWindow,
    setupCameraPipWindow,
    updateTimeLabel
} from "./pipWindow/pipWindow";
import { RecordingEvents, RiveEvents } from "./animations/RecordAnimationUtils";
import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from "react";
import { NotificationInfoText, setErrorNotification, setNeutralNotification } from "../../Nooks";
import { useEditRecordingToolDialog } from "../UseEditRecordingToolDialogState/useEditRecordingToolDialog";
import type { onUploadingRecordingProgress, onUploadingRecordingSuccess, onUploadRecordingError } from "./recordingToolUtils";
import { getMediaStream, getVideoDimensions, uploadRecordingMutation } from "./recordingToolUtils";
import { useMediaDevices } from "./hooks/useMediaDevices";
import type { RecorderConfig } from "./hooks/useRecorder";
import { useRecorder } from "./hooks/useRecorder";
import { PendoService } from "../../../pendoService";
import { startMicVisualizer } from "./pipWindow/volumeBar";
import { RecorderLoggingEventType, reportRecorderError } from "../../../../logic/common/logging/recorderClientLogging";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { getUserData } from "../../../newNav/nooks/model";
import TimeUtils from "../../../../logic/common/timeUtils";
import { useRecordingToolUploadedFile } from "../useRecordingToolUploadedFile/useRecordingToolUploadedFile";
import { v4 as uuid } from "uuid";
import { useRecordingState } from "./useRecoringState";
import type { RecordingToolPermissionDeniedType } from "../../types";
import { RecordingState } from "../../types";
import { reportErrorToSplunk } from "../../../../logic/common/logReportUtils";

export type MediaStreamType = "screen" | "camera";

const VIDEO_TRACK_CONSTRAINTS: MediaTrackConstraints = {
    frameRate: 25,
    width: { ideal: 1920 },
    height: { ideal: 1080 }
};

const AUDIO_TRACK_CONSTRAINTS: MediaTrackConstraints = {
    channelCount: 2,
    noiseSuppression: false,
    echoCancellation: false,
    sampleRate: { ideal: 48_000 }
};

type UseRecordingTool = {
    startScreenCapture: () => Promise<void>;
    startCameraCapture: () => Promise<void>;
}

type UseRecordingToolProps = {
    setRecordedFile: Dispatch<SetStateAction<Blob | null>>
    setVideoDimensions: Dispatch<SetStateAction<{ width: number, height: number } | null>>
    setPermissionDenied: Dispatch<SetStateAction<RecordingToolPermissionDeniedType>>
}

export const useRecordingTool = ({ setRecordedFile, setVideoDimensions, setPermissionDenied }: UseRecordingToolProps): UseRecordingTool => {

    const { openEditRecordingToolDialog } = useEditRecordingToolDialog();
    const { userName } = getUserData();
    const pipWindowRef = useRef<Window | null>(null);
    const channelRef = useRef<MessageChannel | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const { fetchDevices } = useMediaDevices();
    const mediaStreamType = useRef<MediaStreamType | null>(null);
    const fakeProcessNumberId = useRef<NodeJS.Timeout | null>(null);

    const { setRecordingToolMutationId, setRecordingToolProgress, setRecordingToolAssetUrl } = useRecordingToolUploadedFile();
    const { setRecordingState, recordingState } = useRecordingState();

    const [recordingTime, setRecordingTime] = useState(0);
    const recordingTimeRef = useRef<NodeJS.Timeout>(null);

    const isRecorderToolPreviewDialog = !isFeatureEnabled(featureFlagConst._EDITOR_RECORDER_TOOL_SKIP_PREVIEW_DIALOG);

    const uploadRecording = (blob: Blob) => {
        const fileName = `Recording ${TimeUtils.getEditorReadableTimeDate(Date.now())} ${userName}.mp4`;
        const file = new File([blob], fileName, { type: "video/mp4" });

        const onSuccess: onUploadingRecordingSuccess = (payload) => {
            setRecordingToolAssetUrl(payload?.data?.assetURL);
        };

        const onFailure: onUploadRecordingError = (payload) => {
            setErrorNotification({ message: payload?.error });
        };

        const onProgress: onUploadingRecordingProgress = (payload) => {
            if (payload?.progress < 80) {
                setRecordingToolProgress(payload.progress);
                return;
            }
            if (payload?.progress === 80) {
                fakeProcessNumberId.current = setInterval(() => {
                    setRecordingToolProgress((prev) => prev + 1);
                }, 5000);
                return;
            }
            if (payload?.progress === 99) {
                setRecordingToolProgress(payload.progress);
                clearInterval(fakeProcessNumberId.current);
            }
        };

        const mutationId = uuid();
        setRecordingToolMutationId(mutationId);
        void uploadRecordingMutation(mutationId, file, onSuccess, onFailure, onProgress);
    };

    const recorderConfig: RecorderConfig = {
        mimeType: "video/mp4; codecs=\"avc1.640033, mp4a.40.2\"",
        onStop: async (blob) => {
            let videoDimensions;
            try {
                openEditRecordingToolDialog(mediaStreamType.current);
                setRecordingState(RecordingState.RECORDING_ENDED);

                videoDimensions = await getVideoDimensions(blob);
                setVideoDimensions(videoDimensions);

                setRecordedFile(blob);

                if (isRecorderToolPreviewDialog) {
                    uploadRecording(blob);
                }
            }
            catch (e) {
                reportRecorderError(e, {
                    recorderEventType: RecorderLoggingEventType.ErrorInOnStopFlow,
                    metadata: {
                        videoDimensions,
                        blobSize: blob?.size,
                        recordingTime,
                        recordingState
                    }
                });
            }
        },
        onError: (error) => {
            reportRecorderError(error,
                {
                    recorderEventType: RecorderLoggingEventType.FailedToRecord,
                    metadata: {
                        recordingTime,
                        recordingState
                    }
                }
            );
        }
    };

    const { initializeRecorder, startRecording, resumeRecording, pauseRecording, stopRecording, restartRecording } = useRecorder(recorderConfig);

    useEffect(() => {
        if (!recordingTimeRef.current) return;

        if (recordingTime >= MAX_RECORDING_TIME) {
            maxRecordingTimeReachedHandler();
        }

        updateTimeLabel(pipWindowRef.current, recordingTime);
    }, [recordingTime]);

    const handleError = (error: Error, splunkTitle: string, message: string, shouldShowErrorSnackbar: boolean = true) => {
        if (shouldShowErrorSnackbar) {
            setErrorNotification({ message });
        }
        else if (recordingState === RecordingState.RECORDING_STARTED) {
            setNeutralNotification({ message: NotificationInfoText.RecordingStopped, showCloseButton: true });
        }

        reportErrorToSplunk(
            splunkTitle,
            {
                requestUrl: window.location.href,
                crashed: false,
                error: {
                    message: error.message,
                    stack: error.stack
                }
            }
        );

        setRecordingState(RecordingState.NO_SESSION);
        cleanup();
    };

    const maxRecordingTimeReachedHandler = () => {
        pauseRecording();
        pauseRecordingTimer();

        const onSaveHandler = () => {
            PendoService.getInstance().trackEvent("recorder finish recording ", { mediaStreamType: mediaStreamType.current, maxRecordingTimeReached: "true" });
            window.focus();
            void stopRecording();
            cleanup();
        };
        const onRetakeHandler = () => {
            PendoService.getInstance().trackEvent("recorder retake recording  ", { mediaStreamType: mediaStreamType.current, maxRecordingTimeReached: "true" });
            channelRef.current?.port2.postMessage(RecordingEvents.Restart);
        };
        addTimeLimitScreen(
            pipWindowRef.current,
            onSaveHandler,
            onRetakeHandler
        );
        return;
    };

    const startRecordingTimer = () => {
        recordingTimeRef.current = setInterval(() => {
            setRecordingTime((prev) => prev + 1);
        }, 1000);
    };
    const pauseRecordingTimer = () => {
        if (recordingTimeRef.current) {
            clearInterval(recordingTimeRef.current);
        }
    };
    const stopRecordingTimer = () => {
        if (recordingTimeRef.current) {
            clearInterval(recordingTimeRef.current);
            setRecordingTime(0);
        }
    };

    const onSettingsButtonClick = () => {
        PendoService.getInstance().trackEvent("recorder settings clicked ", {});
    };

    const handleInputOnChange = async (cameraId: string, microphoneId: string, width: number, height: number) => {
        stopStream();
        try {
            const cameraStream = await getMediaStream("camera", {
                video: { ...VIDEO_TRACK_CONSTRAINTS, deviceId: cameraId },
                audio: { ...AUDIO_TRACK_CONSTRAINTS, deviceId: microphoneId }
            });
            streamRef.current = cameraStream;

            await initializeRecorder(cameraStream);

            const { cameras, mics } = await fetchDevices();
            const selectedCamera = cameras.find(({ deviceId }) => deviceId === cameraId);
            const selectedMic = mics.find(({ deviceId }) => deviceId === microphoneId);

            PendoService.getInstance().trackEvent("recorder settings changed", {
                selectedCamera: selectedCamera?.label,
                selectedMic: selectedMic?.label
            });

            addVideoStreamToPip(pipWindowRef.current, streamRef.current);
            startMicVisualizer(streamRef.current, pipWindowRef.current);
            addSettingScreen(
                pipWindowRef.current,
                selectedCamera,
                cameras,
                selectedMic,
                mics,
                (cameraId, microphoneId) => handleInputOnChange(cameraId, microphoneId, width, height),
                onSettingsButtonClick
            );
        }
        catch (err) {
            reportRecorderError(err, {
                recorderEventType: RecorderLoggingEventType.FailedToChangeCameraOrMic,
                metadata: {
                    recordingTime,
                    recordingState
                }
            });
        }
    };

    const handlePermissionsDenied = () => {
        // @ts-ignore - navigator.permissions camera is not yet in the types
        navigator.permissions.query({ name: "camera" }).then((result) => {
            result.onchange = () => {
                if (result.state === "denied") {
                    reportRecorderError(new Error("Camera permissions denied"), {
                        recorderEventType: RecorderLoggingEventType.CameraPermissionsDenied,
                        metadata: { permissionQueryResult: result }
                    });
                }
            };
        });
        // @ts-ignore - navigator.permissions camera is not yet in the types
        navigator.permissions.query({ name: "microphone" }).then((result) => {
            result.onchange = () => {
                if (result.state === "denied") {
                    reportRecorderError(new Error("Mic permissions denied"), {
                        recorderEventType: RecorderLoggingEventType.MicPermissionsDenied,
                        metadata: { permissionQueryResult: result }
                    });
                }
            };
        });
    };

    const attemptAlternativeDevices = async (
        type: MediaStreamType,
        streamOptions: { video: MediaTrackConstraints, audio: MediaTrackConstraints }
    ) => {
        try {
            let camerasInUseNum = 0;
            const { cameras, mics } = await fetchDevices();
            if (cameras.length <= 1 || mics.length <= 1) {
                addNoCameraAvailableScreen(pipWindowRef.current, cleanup);
                return;
            }
            for (const camera of cameras) {
                try {
                    const stream = await getMediaStream(
                        type,
                        { ...streamOptions, video: { ...streamOptions.video, deviceId: camera.deviceId } }
                    );
                    mediaStreamType.current = type;
                    streamRef.current = stream;

                    await initializeRecorder(stream);

                    // Update PIP UI
                    channelRef.current?.port2.postMessage(RiveEvents.LoadingFinished);
                    addRecordLabel(pipWindowRef.current);

                    return stream;
                }
                catch (cameraErr) {
                    if (cameraErr?.name === "NotReadableError") {
                        camerasInUseNum++;

                        if (camerasInUseNum === cameras.length) {
                            addNoCameraAvailableScreen(pipWindowRef.current, cleanup);
                            return;
                        }

                        continue;
                    }
                    if (cameraErr?.name !== "NotReadableError") {
                        setRecordingState(RecordingState.NO_SESSION);
                        cleanup();
                        break;
                    }
                }
            }
        }
        catch (err) {
            setRecordingState(RecordingState.NO_SESSION);
            cleanup();
        }
    };

    const hasPermission = async (name: "camera" | "microphone"): Promise<boolean> => {
        try {
            // @ts-ignore - navigator.permissions camera is not yet in the types
            const { state } = await navigator.permissions.query({ name });
            return state === "granted";
        }
        catch {
            return false;
        }
    };

    const handleNotAllowedError = async () => {
        const isVideoPermissionGranted = await hasPermission("camera");
        const isAudioPermissionGranted = await hasPermission("microphone");

        if (isVideoPermissionGranted) {
            setPermissionDenied("microphone");
            return;
        }
        if (isAudioPermissionGranted) {
            setPermissionDenied("camera");
            return;
        }
        setPermissionDenied("cameraAndMicrophone");
    };

    const initializeCapture = async (
        type: MediaStreamType,
        dimensions: { width: number, height: number },
        streamOptions: { video: MediaTrackConstraints, audio: MediaTrackConstraints }
    ): Promise<MediaStream | undefined> => {
        try {
            initChannel();
            const streamPromise = getMediaStream(type, streamOptions);
            // Consider adding timeout so to avoid permissions dialog prompt in PIP window

            const pipWindowPromise = openPendingPipWindow(type, channelRef.current?.port1, {
                width: dimensions.width,
                height: dimensions.height
            });

            pipWindowRef.current = await pipWindowPromise;
            mediaStreamType.current = type;

            const stream = await streamPromise;

            streamRef.current = stream;
            await initializeRecorder(stream);

            // Update PIP UI
            channelRef.current?.port2.postMessage(RiveEvents.LoadingFinished);
            addRecordLabel(pipWindowRef.current);

            return stream;
        }
        catch (err) {
            if (err?.name === "NotReadableError") {
                try {
                    return await attemptAlternativeDevices(type, streamOptions);
                }
                catch (e) {
                    reportRecorderError(e, {
                        recorderEventType: RecorderLoggingEventType.RecordingFailedToStart,
                        metadata: { deviceType: type, dimensions, streamOptions, afterNonReadableError: true, originalError: err }
                    });
                    return;
                }
            }

            if (type === "camera" && err.name === "NotAllowedError") {
                await handleNotAllowedError();
                setRecordingState(RecordingState.NO_SESSION);
                cleanup();
                return;
            }

            reportRecorderError(err, {
                recorderEventType: RecorderLoggingEventType.RecordingFailedToStart,
                metadata: { deviceType: type, dimensions, streamOptions, afterNonReadableError: false }
            });
            return;
        }
    };

    const startScreenCapture = async () => {
        const stream = await initializeCapture("screen", { width: 304, height: 148 }, {
            video: { ...VIDEO_TRACK_CONSTRAINTS },
            audio: { ...AUDIO_TRACK_CONSTRAINTS }
        });
        if (!stream) {
            pipWindowRef.current?.close();
            return;
        }

        setRecordingState(RecordingState.SESSION_STARTED);
        streamStoppedHandler(stream);
    };

    const getCameraWindowSize = () => {
        const halfScreenWidth = Math.floor(window.innerWidth / 2);
        const halfScreenHeight = Math.floor(window.innerHeight / 2);

        // Ensure even width and height for encoding
        const width = halfScreenWidth % 2 === 0 ? halfScreenWidth : halfScreenWidth - 1;
        const height = halfScreenHeight % 2 === 0 ? halfScreenHeight : halfScreenHeight - 1;
        return { width, height };
    };

    const startCameraCapture = async () => {
        const { width, height } = getCameraWindowSize();

        const stream = await initializeCapture(
            "camera",
            { width, height },
            { video: VIDEO_TRACK_CONSTRAINTS, audio: AUDIO_TRACK_CONSTRAINTS }
        );

        handlePermissionsDenied();

        if (!stream) return;

        PendoService.getInstance().trackEvent("recorder settings initialized ", {
            selectedCamera: stream.getTracks().find(track => track.kind === "video")?.label,
            selectedMic: stream.getTracks().find(track => track.kind === "audio")?.label
        });
        const { cameras, mics } = await fetchDevices();
        setupCameraPipWindow(pipWindowRef.current, stream, cameras, mics, width, height, handleInputOnChange, onSettingsButtonClick);

        setRecordingState(RecordingState.SESSION_STARTED);
        streamStoppedHandler(stream);
    };

    const stopStream = () => {
        if (streamRef.current) {
            streamRef.current?.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
        }
    };

    const initChannel = () => {
        channelRef.current = new MessageChannel();
        channelRef.current.port2.onmessage = handleMessage;
    };

    const messageHandlers: Record<string, () => void> = {
        [RecordingEvents.StartRecording]:
                () => {
                    PendoService.getInstance().trackEvent("recorder start recording ", { mediaStreamType: mediaStreamType.current });
                    channelRef.current?.port2.postMessage(RecordingEvents.StartRecording);
                    startRecording();
                    startRecordingTimer();
                    setRecordingState(RecordingState.RECORDING_STARTED);
                },
        [RecordingEvents.Pause]:
                () => {
                    PendoService.getInstance().trackEvent("recorder paused recording ", { mediaStreamType: mediaStreamType.current });
                    pauseRecording();
                    pauseRecordingTimer();
                },
        [RecordingEvents.Play]:
                () => {
                    PendoService.getInstance().trackEvent("recorder resumed recording ", { mediaStreamType: mediaStreamType.current });
                    resumeRecording();
                    startRecordingTimer();
                },
        [RecordingEvents.Stop]:
                () => {
                    setRecordingState(RecordingState.NO_SESSION);
                    PendoService.getInstance().trackEvent("recorder finish recording ", { mediaStreamType: mediaStreamType.current });
                    void stopRecording();
                    stopRecordingTimer();
                    cleanup();
                },
        [RecordingEvents.Restart]:
                () => {
                    pauseRecordingTimer();
                    channelRef.current?.port2.postMessage(RecordingEvents.Restart);
                },
        [RecordingEvents.Retake]:
                () => {
                    PendoService.getInstance().trackEvent("recorder retake recording ", { mediaStreamType: mediaStreamType.current });
                    void restartRecording(streamRef.current);
                    stopRecordingTimer();
                }
    };

    const handleMessage = (event: MessageEvent) => {
        messageHandlers[event.data]?.();
    };

    /* Stream stopped handler. will be triggered when:
            1. There is no more data left to send.
            2. The user revoked the permissions needed for the data to be sent.
            3. The hardware generating the source data has been removed or ejected.
            4. A remote peer has permanently stopped sending data.
            5. The only case where the track ends but the ended event is not fired is when calling MediaStreamTrack.stop.
        */
    const streamStoppedHandler = (stream: MediaStream) => {
        const handler = () => {
            if (recordingState === RecordingState.RECORDING_STARTED) {
                setNeutralNotification({ message: NotificationInfoText.RecordingStopped, showCloseButton: true });
            }
            setRecordingState(RecordingState.NO_SESSION);
            cleanup();
        };

        stream.getTracks().forEach((track) => {
            track.onended = handler;
        });
    };

    const cleanup = () => {
        stopStream();
        pipWindowRef.current?.close();
        pipWindowRef.current = null;
    };

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            cleanup();
        };
    }, []);

    return {
        startScreenCapture,
        startCameraCapture
    };
}
;
