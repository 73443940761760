import type { DeltaNode, LpConfiguration, MediaNode } from "@sundaysky/landing-page-skeleton-types";
import { deepCloneObj } from "../../../../logic/common/generalUtils";
import { setLandingPageConfiguration } from "../../Nooks";
import type { PlayerAspectRatio } from "../../../../../common/aspectRatioUtils";
import { useUpdatedLandingPageConfiguration } from "./useUpdatedLandingPageConfiguration";
import { NpsSurveyId } from "../../../../../common/commonConst";

export const NpsBackgroundColor = "#FFFFFF";

export type LpConfigurationNode<T> = {
    value: T,
    setter: (newValue: T) => void
}

export type UseLandingPageConfiguration = {
    loading: true;
    configuration: null;
    originalConfiguration: null;
    abortChangesToLocalConfiguration: null;
    landingPageNodes: null;
    playerConfigurationNodes: null;
    surveyConfigurationNodes: null;
    privacyPolicyConfigurationNodes: null;
    fontNodes: null;
} | {
    loading: false;
    configuration: LpConfiguration;
    originalConfiguration: LpConfiguration;
    abortChangesToLocalConfiguration: () => void;
    landingPageNodes: {
        headline: LpConfigurationNode<DeltaNode | string>;
        subheadline: LpConfigurationNode<DeltaNode | string>;
        disclaimer: LpConfigurationNode<DeltaNode | string>;
        buttonText: LpConfigurationNode<DeltaNode | string>;
        buttonUrl: LpConfigurationNode<string>;
        buttonColor: LpConfigurationNode<string>;
        isButtonVisible: LpConfigurationNode<boolean>;
        logo: LpConfigurationNode<MediaNode>;
        endPointUrlPrefix: LpConfigurationNode<string>;
        posterImage: LpConfigurationNode<MediaNode>;
        removeSundaySkyBranding: LpConfigurationNode<boolean>;
    };
    playerConfigurationNodes: {
        lockPlayerBar: LpConfigurationNode<boolean>;
        addTranscriptButton: LpConfigurationNode<boolean>;
        ccOnByDefault: LpConfigurationNode<boolean>;
        largeCenterPlayIcon: LpConfigurationNode<boolean>;
        rectangleCenterPlayIcon: LpConfigurationNode<boolean>;
        logoOverlay: LpConfigurationNode<boolean>;
        aspectRatio: PlayerAspectRatio;
        playButtonColor: LpConfigurationNode<string>;
    };
    surveyConfigurationNodes: {
        surveyId: LpConfigurationNode<string>;
        backgroundColor: LpConfigurationNode<string>;
        npsSurvey: LpConfigurationNode<boolean>;
    };
    privacyPolicyConfigurationNodes: {
        isPrivacyPolicyVisible: LpConfigurationNode<boolean>
        privacyPolicyUrl: LpConfigurationNode<string>;
    };
};

export const useLandingPageConfiguration = (programId: string): UseLandingPageConfiguration => {
    const {
        loading,
        configuration,
        originalConfiguration
    } = useUpdatedLandingPageConfiguration(programId);

    if (loading) return {
        loading: true,
        configuration: null,
        originalConfiguration: null,
        abortChangesToLocalConfiguration: null,
        landingPageNodes: null,
        playerConfigurationNodes: null,
        surveyConfigurationNodes: null,
        privacyPolicyConfigurationNodes: null,
        fontNodes: null
    };

    const setHeadline = (richText: DeltaNode | string) => {
        const newConfiguration = deepCloneObj(configuration);

        newConfiguration.main.headline.delta = richText as DeltaNode;
        setLandingPageConfiguration(newConfiguration);
    };

    const setSubheadline = (richText: DeltaNode | string) => {
        const newConfiguration = deepCloneObj(configuration);

        newConfiguration.main.subheadline.delta = richText as DeltaNode;
        setLandingPageConfiguration(newConfiguration);
    };

    const setDisclaimer = (richText: DeltaNode | string) => {
        const newConfiguration = deepCloneObj(configuration);

        newConfiguration.footer.disclaimer.delta = richText as DeltaNode;
        setLandingPageConfiguration(newConfiguration);
    };

    const setButtonText = (richText: DeltaNode | string) => {
        const newConfiguration = deepCloneObj(configuration);

        newConfiguration.main.buttons[0].text.delta = richText as DeltaNode;
        setLandingPageConfiguration(newConfiguration);
    };

    const setButtonUrl = (url: string) => {
        const newConfiguration = deepCloneObj(configuration);

        // If there's no protocol, add https
        if (url &&
            !url.toLowerCase().startsWith("mailto:") &&
            !url.toLowerCase().startsWith("tel:") &&
            !url.toLowerCase().startsWith("http://") &&
            !url.toLowerCase().startsWith("https://")
        ) {
            url = "https://" + url;
        }

        newConfiguration.main.buttons[0].url = url;

        setLandingPageConfiguration(newConfiguration);
    };

    const setButtonColor = (color: string) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.buttons[0].color = color;
        setLandingPageConfiguration(newConfiguration);
    };

    const setButtonVisible = (isVisible: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.buttons[0].isVisible = isVisible;
        setLandingPageConfiguration(newConfiguration);
    };

    const setLogo = (logo: MediaNode) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.loader.logo = logo;
        newConfiguration.header.logo = logo;
        setLandingPageConfiguration(newConfiguration);
    };

    const setPosterImage = (image: MediaNode) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.posterImage = image;
        setLandingPageConfiguration(newConfiguration);
    };

    const setEndPointUrlPrefix = (url: string) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.getSessionEndpointPrefix = url;
        setLandingPageConfiguration(newConfiguration);
    };

    const abortChangesToLocalConfiguration = () => {
        setLandingPageConfiguration(originalConfiguration.current);
    };

    const setLockPlayerBar = (lockPlayerBar: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.lockPlayerBar = lockPlayerBar;
        setLandingPageConfiguration(newConfiguration);
    };

    const setAddTranscriptButton = (addTranscriptButton: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.addTranscriptButton = addTranscriptButton;
        setLandingPageConfiguration(newConfiguration);
    };

    const setCcOnByDefault = (ccOnByDefault: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.ccOnByDefault = ccOnByDefault;
        setLandingPageConfiguration(newConfiguration);
    };

    const setLargeCenterPlayIcon = (largeCenterPlayIcon: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.largeCenterPlayIcon = largeCenterPlayIcon;
        setLandingPageConfiguration(newConfiguration);
    };

    const setRectangleCenterPlayIcon = (rectangleCenterPlayIcon: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.rectangleCenterPlayIcon = rectangleCenterPlayIcon;
        setLandingPageConfiguration(newConfiguration);
    };

    const setPlayButtonColor = (color: string) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.playButtonColor = color;
        setLandingPageConfiguration(newConfiguration);
    };

    //todo: anything related to logo is hidden from the user, fix this setter to add actual logo media selection
    const setLogoOverlay = (displayLogo: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.main.playerConfiguration.logoOverlay.displayLogo = displayLogo;
        setLandingPageConfiguration(newConfiguration);
    };

    const setSurveyId = (surveyId: string) => {
        const newConfiguration = deepCloneObj(configuration);
        if (newConfiguration.main.surveyConfiguration.surveyId === NpsSurveyId) {
            return;
        }
        newConfiguration.main.surveyConfiguration.surveyId = surveyId;
        setLandingPageConfiguration(newConfiguration);
    };

    const setSurveyBackgroundColor = (bgColor: string) => {
        const newConfiguration = deepCloneObj(configuration);
        if (newConfiguration.main.surveyConfiguration.surveyId === NpsSurveyId) {
            return;
        }
        newConfiguration.main.surveyConfiguration.backgroundColor = bgColor;
        setLandingPageConfiguration(newConfiguration);
    };

    const setNpsSurvey = (toggleOn: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        if (!!newConfiguration.main.surveyConfiguration.surveyId && newConfiguration.main.surveyConfiguration.surveyId !== NpsSurveyId) {
            return;
        }
        newConfiguration.main.surveyConfiguration.surveyId = (toggleOn ? NpsSurveyId : "");
        newConfiguration.main.surveyConfiguration.backgroundColor = (toggleOn ? NpsBackgroundColor : "#FFFFFF");
        setLandingPageConfiguration(newConfiguration);
    };

    const setPrivacyPolicyUrl = (url: string) => {
        const newConfiguration = deepCloneObj(configuration);
        if (url && !url.toLowerCase().startsWith("http://") && !url.toLowerCase().startsWith("https://")) {
            url = "https://" + url;
        }
        newConfiguration.footer.privacyPolicy.url = url;
        setLandingPageConfiguration(newConfiguration);
    };

    const setPrivacyPolicyVisible = (isPrivacyPolicyVisible: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.footer.privacyPolicy.isVisible = isPrivacyPolicyVisible;
        setLandingPageConfiguration(newConfiguration);
    };

    const setRemoveSundaySkyBranding = (removeSundaySkyBranding: boolean) => {
        const newConfiguration = deepCloneObj(configuration);
        newConfiguration.footer.showWatermark = !removeSundaySkyBranding;
        newConfiguration.main.playerConfiguration.showLogo = !removeSundaySkyBranding;
        setLandingPageConfiguration(newConfiguration);
    };

    return {
        loading: false,
        configuration,
        originalConfiguration: originalConfiguration.current,
        abortChangesToLocalConfiguration,
        landingPageNodes: {
            headline: {
                value: configuration.main.headline.delta,
                setter: setHeadline
            },
            subheadline: {
                value: configuration.main.subheadline.delta,
                setter: setSubheadline
            },
            disclaimer: {
                value: configuration.footer.disclaimer.delta,
                setter: setDisclaimer
            },
            buttonText: {
                value: configuration.main.buttons[0].text.delta,
                setter: setButtonText
            },
            buttonUrl: {
                value: configuration.main.buttons[0].url,
                setter: setButtonUrl
            },
            buttonColor: {
                value: configuration.main.buttons[0].color,
                setter: setButtonColor
            },
            isButtonVisible: {
                value: configuration.main.buttons[0].isVisible,
                setter: setButtonVisible
            },
            logo: {
                value: configuration.loader.logo,
                setter: setLogo
            },
            endPointUrlPrefix: {
                value: configuration.getSessionEndpointPrefix,
                setter: setEndPointUrlPrefix
            },
            posterImage: {
                value: configuration.main.playerConfiguration.posterImage,
                setter: setPosterImage
            },
            removeSundaySkyBranding: {
                value: !configuration.footer.showWatermark && !configuration.main.playerConfiguration.showLogo,
                setter: setRemoveSundaySkyBranding
            }
        },
        playerConfigurationNodes: {
            lockPlayerBar: {
                value: configuration.main.playerConfiguration.lockPlayerBar,
                setter: setLockPlayerBar
            },
            addTranscriptButton: {
                value: configuration.main.playerConfiguration.addTranscriptButton,
                setter: setAddTranscriptButton
            },
            ccOnByDefault: {
                value: configuration.main.playerConfiguration.ccOnByDefault,
                setter: setCcOnByDefault
            },
            largeCenterPlayIcon: {
                value: configuration.main.playerConfiguration.largeCenterPlayIcon,
                setter: setLargeCenterPlayIcon
            },
            rectangleCenterPlayIcon: {
                value: configuration.main.playerConfiguration.rectangleCenterPlayIcon,
                setter: setRectangleCenterPlayIcon
            },
            logoOverlay: {
                value: configuration.main.playerConfiguration.logoOverlay.displayLogo,
                setter: setLogoOverlay
            },
            aspectRatio: configuration.main.playerConfiguration.aspectRatio,
            playButtonColor: {
                value: configuration.main.playerConfiguration.playButtonColor,
                setter: setPlayButtonColor
            }
        },
        surveyConfigurationNodes: {
            surveyId: {
                value: configuration.main.surveyConfiguration.surveyId,
                setter: setSurveyId
            },
            backgroundColor: {
                value: configuration.main.surveyConfiguration.backgroundColor,
                setter: setSurveyBackgroundColor
            },
            npsSurvey: {
                value: configuration.main.surveyConfiguration.surveyId === NpsSurveyId,
                setter: setNpsSurvey
            }
        },
        privacyPolicyConfigurationNodes: {
            isPrivacyPolicyVisible: {
                value: configuration.footer.privacyPolicy.isVisible,
                setter: setPrivacyPolicyVisible
            },
            privacyPolicyUrl: {
                value: configuration.footer.privacyPolicy.url,
                setter: setPrivacyPolicyUrl
            }
        }
    };
};
