import type { VideoSpec } from "../types";
import { getService } from "../../services";
import type { ExportVideoBody, PreviewNarrationBody, PreviewNarrationResponse, PreviewPrepareResponse, PreviewSpecBody, PreviewVideoBody } from "../../../../common/types/restApiTypes";
import type VideoPreviewServices from "../../../logic/common/videoPreviewServices";
import type { PreviewVideoResponse } from "../../../../common/external/videoSpecResponse";

export async function getVideoSpec(accountId: string, programId: string, previewSpecBody: PreviewSpecBody): Promise<VideoSpec> {
    return getService<VideoPreviewServices>("videoPreviewServices").getVideoSpec(accountId, programId, previewSpecBody);
}

export function getVideo(accountId: string, programId: string, previewVideoBody: PreviewVideoBody): Promise<PreviewVideoResponse> {
    return getService<VideoPreviewServices>("videoPreviewServices").getVideo(accountId, programId, previewVideoBody);
}

export function getVideoDescriptionPdf(accountId: string, programId: string, previewVideoBody: ExportVideoBody): Promise<any> {
    return getService<VideoPreviewServices>("videoPreviewServices").getVideoDescriptionPdf(accountId, programId, previewVideoBody);
}

export function prepareInlineStory(accountId: string, programId: string, programVersionId: string, previewM2Mode: boolean = false): Promise<PreviewPrepareResponse> {
    return getService<VideoPreviewServices>("videoPreviewServices")
        .prepareInlineStory(accountId, programId, { programVersionId, previewM2Mode });
}

export function getMockNarrationPreview(data: PreviewNarrationBody): Promise<PreviewNarrationResponse> {
    return getService<VideoPreviewServices>("videoPreviewServices").getMockNarrationPreview(data);
}

