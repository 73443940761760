import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import type { ThemeId, WireframeId } from "../../types";
import { localEditorWireframeThemeSupport } from "../../State";
import { constructWireframeThemeSupportKey } from "../../Utils";
import { useEditorProgramThemeId } from "../UseEditorProgramThemeId";

export type UseWireframeThemeSupport = {
    isThemeImplementedForWireframe: (wireframeId: WireframeId, themeId: ThemeId) => boolean;
    isActiveThemeImplementedForWireframe: (wireframeId: WireframeId) => boolean;
};

export const useWireframeThemeSupport = (): UseWireframeThemeSupport => {
    const wireframeThemeSupport = useReactiveVar(localEditorWireframeThemeSupport);
    const { programThemeId } = useEditorProgramThemeId();

    const isThemeImplementedForWireframe = useCallback((wireframeId: WireframeId, themeId: ThemeId): boolean => {
        return wireframeThemeSupport[constructWireframeThemeSupportKey(wireframeId, themeId)];
    }, [wireframeThemeSupport]);

    const isActiveThemeImplementedForWireframe = useCallback((wireframeId: WireframeId) => {
        return isThemeImplementedForWireframe(wireframeId, programThemeId);
    }, [isThemeImplementedForWireframe, programThemeId]);

    return { isThemeImplementedForWireframe, isActiveThemeImplementedForWireframe };
};
