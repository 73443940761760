import { localEditorRecordingToolUploadedFile } from "../../State";
import { useReactiveVar } from "@apollo/client";

type RecordingToolData = {
    assetUrl: string;
    progress: number;
    mutationId: string;
}

type UseRecordingToolUploadedFile = {
    recordingToolData: RecordingToolData;
    setRecordingToolData: (data: RecordingToolData) => void;
    setRecordingToolAssetUrl: (assetUrl: string) => void;
    setRecordingToolProgress: (progress: number | ((prevProgress: number) => number)) => void;
    setRecordingToolMutationId: (mutationId: string) => void;
}

const setRecordingToolData = (data: RecordingToolData) => {
    localEditorRecordingToolUploadedFile(data);
};

const setRecordingToolAssetUrl = (assetUrl: string) => {
    localEditorRecordingToolUploadedFile({ ...localEditorRecordingToolUploadedFile(), assetUrl });
};

const setRecordingToolProgress = (progress: number | ((prevProgress: number) => number)) => {
    if (typeof progress !== "function") {
        return localEditorRecordingToolUploadedFile({ ...localEditorRecordingToolUploadedFile(), progress });
    }

    const currentProgress = localEditorRecordingToolUploadedFile();
    if (!currentProgress) {
        return;
    }
    const newProgressValue = progress(currentProgress.progress);
    localEditorRecordingToolUploadedFile({ ...currentProgress, progress: newProgressValue });

};

const setRecordingToolMutationId = (mutationId: string) => {
    localEditorRecordingToolUploadedFile({ ...localEditorRecordingToolUploadedFile(), mutationId });
};

export const useRecordingToolUploadedFile = (): UseRecordingToolUploadedFile => {
    const recordingToolData = useReactiveVar(localEditorRecordingToolUploadedFile);

    return {
        recordingToolData,
        setRecordingToolData,
        setRecordingToolAssetUrl,
        setRecordingToolProgress,
        setRecordingToolMutationId
    };
};
