import type { GqlClientEditorBrandTextStyleFragment, GqlClientEditorLibraryFontFragment } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientAssetLifecycleStage } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getLocalIdFromGqlId } from "../../../../common/idUtils";
import { getTextStyleFont } from "./brandLibrary";

const italicFontsToShowInFontsSelection =
    new Set(["3574c698-aa6a-4728-954a-faec75fc2cb1", "5383d8d0-74aa-4cc9-9192-2c739036d58e", "5c0edf0d-eaad-4821-ba46-9d2ea307b6c3", "95f30e61-dfab-4fdc-b0d4-fafdbedcd272"]);

//filter out non-enabled fonts and italic fonts, excluding existing italic fonts
export const filterCcLibraryFontsForSelection = (libraryFonts: GqlClientEditorLibraryFontFragment[]):GqlClientEditorLibraryFontFragment[] =>
    libraryFonts.filter((font) =>
        font.lifecycleStage === GqlClientAssetLifecycleStage.ENABLED && (!font.isItalic || italicFontsToShowInFontsSelection.has(getLocalIdFromGqlId(font.id))));

export const hasTextStyleFontChanged = (initialTextStyle: GqlClientEditorBrandTextStyleFragment, updatedTextStyle: GqlClientEditorBrandTextStyleFragment) => {
    const initialFont = getTextStyleFont(initialTextStyle);
    const updatedFont = getTextStyleFont(updatedTextStyle);
    return initialFont.id !== updatedFont.id;
};

export const changedBrandTextStyles = (initialBrandTextStyles: GqlClientEditorBrandTextStyleFragment[], updatedBrandTextStyles: GqlClientEditorBrandTextStyleFragment[])
    : GqlClientEditorBrandTextStyleFragment[] => {
    return updatedBrandTextStyles.filter(style => {
        const initialStyle = initialBrandTextStyles.find(s => s.ccTextStyle.id === style.ccTextStyle.id);
        return hasTextStyleFontChanged(initialStyle, style);
    });
};
