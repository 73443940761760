import {
    useEditorViewerProfilesQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

import type {
    GqlClientEditorViewerProfileFragment
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";

export type UseViewerProfiles = {
    viewerProfiles: GqlClientEditorViewerProfileFragment[];
};

export function useViewerProfiles(): UseViewerProfiles {
    const programId = useEditorProgramId();
    const { data } = useEditorViewerProfilesQuery({ variables: { programId } });
    const viewerProfiles = data?.editorProgram?.viewerProfiles ?? [];
    return { viewerProfiles };
}
