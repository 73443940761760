import { useAccountPronunciationDefinitionChangedSubscription, useAccountPronunciationLibraryVersionChangedSubscription } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const useAccountPronunciationLibrarySubscriptions = (accountId: string): void => {
    useAccountPronunciationLibraryVersionChangedSubscription({
        variables: { accountId }
    });

    useAccountPronunciationDefinitionChangedSubscription({
        variables: { accountId }
    });
};
