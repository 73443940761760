import type { NotificationData } from "../../types";
import { SskyErrorCode } from "../../../../../common/errors";
import { localEditorNotificationDataVar } from "../../State";
import type { TNotificationInfoText, TNotificationSuccessText } from "./notificationText";
import { NotificationErrorText, NotificationInfoText } from "./notificationText";
import { openContactSupportForm, reloadPage } from "../../Utils";
import { handleError } from "../../../../logic/common/errorHandlingUtils";
import { upsertProcessedFile } from "../ProcessedFiles";
import { v4 as uuid } from "uuid";
import { PendoService } from "../../../pendoService";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";

type ActionButton = NotificationData["actionButton"];

type SetNeutralNotificationParams = Omit<NotificationData, "type" | "message" | "isOpen"> & {
    message: TNotificationInfoText;
};

type SetNeutralWhiteNotificationParams = Omit<NotificationData, "type" | "message" | "isOpen"> & {
    message: TNotificationInfoText;
};

type SetSuccessNotificationParams = Omit<NotificationData, "type" | "message" | "isOpen"> & {
    message: TNotificationSuccessText;
};

type SetErrorNotificationParams = Omit<NotificationData, "type" | "message" | "isOpen"> & {
    message: string;
};

type SetInfoNotificationParams = Omit<NotificationData, "type" | "message" | "isOpen"> & {
    message: string;
};

type notificationPosition = "left" | "center" | "right";

type HandleEditorErrorParams = {
    error: Error;
    sskyCode: SskyErrorCode;
    details?: Record<string, unknown>;
    fileName?: string;
    notificationDuration?: number;
    position?: notificationPosition;
};

const getContactSupportActionButton = (errorId: string): ActionButton => {
    return {
        label: "Contact support",
        onClick: () => openContactSupportForm(errorId)
    };
};

const reloadPageActionButton: ActionButton = {
    label: "Reload page",
    onClick: reloadPage
};

const setNotification = (notificationData: NotificationData): void => {
    localEditorNotificationDataVar(notificationData);
};

export const setNeutralNotification = (notificationData: SetNeutralNotificationParams): void => {
    setNotification({ ...notificationData, type: "neutral", isOpen: true });
};

export const setNeutralWhiteNotification = (notificationData: SetNeutralWhiteNotificationParams): void => {
    setNotification({ ...notificationData, type: "white", isOpen: true });
};

export const setSuccessNotification = (notificationData: SetSuccessNotificationParams): void => {
    setNotification({ ...notificationData, type: "success", isOpen: true });
};

export const setErrorNotification = (notificationData: SetErrorNotificationParams): void => {
    setNotification({ ...notificationData, type: "error", isOpen: true });
};

export const setInfoNotification = (notificationData: SetInfoNotificationParams): void => {
    setNotification({ ...notificationData, type: "info", isOpen: true });
};

export const closeNotification = (): void => {
    setNotification({ ...localEditorNotificationDataVar(), isOpen: false });
};

export const setNoInternetNotification = (): void => {
    setNeutralNotification({
        message: NotificationInfoText.NoInternetConnection,
        actionButton: reloadPageActionButton,
        blockUserActions: true
    });
};

const notifyUsingProcessedFilesSnackbar = (sskyCode: SskyErrorCode, fileName: string) => {
    upsertProcessedFile({ fileName, sskyCode });
};

const notifyUsingNotificationSnackbar = (sskyCode: SskyErrorCode, editorError: EnhancedError, duration?: number) => {
    const errorId = editorError.getErrorId();
    const notificationData: NotificationData = { type: "error", message: null, duration, isOpen: true };

    switch (sskyCode) {
        // no notification
        case SskyErrorCode.AssetFontUploadError:
        case SskyErrorCode.UnsupportedAssetFontType:
        case SskyErrorCode.LivePreviewError:
        case SskyErrorCode.Unauthorized:
        case SskyErrorCode.LandingPageOutOfAppPreviewError:
        case SskyErrorCode.LandingPageOutOfAppPreviewOpenNewTabError:
        case SskyErrorCode.EmbedVideoPageOutOfAppPreviewError:
        case SskyErrorCode.EmbedVideoPageOutOfAppPreviewOpenNewTabError:
        case SskyErrorCode.MediaCropHintsError:
        case SskyErrorCode.ShareToSocialPreviewError:
            return;

        // text only
        case SskyErrorCode.ImageAssetSizeTooBig:
        case SskyErrorCode.VideoAssetSizeTooBig:
        case SskyErrorCode.AudioAssetSizeTooBig:
        case SskyErrorCode.UnsupportedAssetTypeImageAndVideo:
        case SskyErrorCode.UnsupportedAssetTypeMusic:
        case SskyErrorCode.CsvUploadUnsupportedFile:
        case SskyErrorCode.CsvUploadFileTooBig:
        case SskyErrorCode.CsvUploadFileEmpty:
        case SskyErrorCode.CsvUploadFileNotUtf8:
        case SskyErrorCode.CsvUploadDuplicateHeaders:
        case SskyErrorCode.CsvUploadIllegalCharacterInHeaders:
        case SskyErrorCode.BulkUploadAssetsTooMany:
        case SskyErrorCode.LandingPagePreviewError:
        case SskyErrorCode.LandingPageSaveError:
        case SskyErrorCode.LandingPageActivationError:
        case SskyErrorCode.LandingPageDeactivationError:
        case SskyErrorCode.PublishToChannelsError:
        case SskyErrorCode.LandingPageDeletionError:
        case SskyErrorCode.DuplicateProgramWithRemovedWireframeError:
            notificationData.message = NotificationErrorText[sskyCode];
            break;

        // text + "contact support" button
        case SskyErrorCode.RecordingRequestFailed:
        case SskyErrorCode.CopyProgramFailed:
        case SskyErrorCode.CopyProgramBetweenAccountsFailed:
        case SskyErrorCode.PreviewError:
        case SskyErrorCode.DownloadVideoError:
        case SskyErrorCode.ShareVideoToSocialError:
        case SskyErrorCode.ViewerProfileSaveError:
        case SskyErrorCode.ViewerProfileDeleteError:
        case SskyErrorCode.ViewerProfileCreateError:
        case SskyErrorCode.StudioElementsCreationErrorParsingCsv:
        case SskyErrorCode.EditorProgramGenerateVideoLinksFailed:
        case SskyErrorCode.GettyImagesGeneralError:
        case SskyErrorCode.PromptToVideoError:
            notificationData.message = NotificationErrorText[sskyCode];
            notificationData.actionButton = getContactSupportActionButton(errorId);
            break;

        case SskyErrorCode.OutdatedEditorVersion:
            notificationData.type = "neutral";
            notificationData.message = NotificationErrorText[sskyCode];
            notificationData.actionButton = reloadPageActionButton;
            notificationData.blockUserActions = true;
            break;

        case SskyErrorCode.PreviewVideoPartialNarrations:
            notificationData.type = "neutral";
            notificationData.message = NotificationInfoText[sskyCode];
            notificationData.actionButton = getContactSupportActionButton(uuid());
            break;

        default:
            notificationData.type = "neutral"; // product: unexpected error should be classified as info
            notificationData.message = NotificationErrorText.UnexpectedError;
            notificationData.actionButton = getContactSupportActionButton(errorId);
            break;
    }

    setNotification(notificationData);
};

export const handleEditorError = ({ error, sskyCode, details, fileName, notificationDuration }: HandleEditorErrorParams): void => {
    const editorError = new EnhancedError(error, sskyCode, details);

    handleError({ error: editorError }).catch(() => {});

    fileName ? notifyUsingProcessedFilesSnackbar(sskyCode, fileName) : notifyUsingNotificationSnackbar(sskyCode, editorError, notificationDuration);
};

export const getNotificationData = (): NotificationData & { isOpen: boolean } => {
    const notificationData = localEditorNotificationDataVar();

    return {
        ...notificationData
    };
};

export enum UploadFileContext {
    DataLibrary = "DataLibrary",
    GenerateLinks = "GenerateLinks",
    MediaAssetLibrary = "MediaAssetLibrary",
    MusicLibrary = "MusicLibrary",
    FontsLibrary = "FontsLibrary",
    NonLibraryMedia = "NonLibraryMedia",
    FileToVideo = "FileToVideo"
}

const UploadFileClientSideValidations: SskyErrorCode[] = [
    SskyErrorCode.UnsupportedAssetTypeImageAndVideo,
    SskyErrorCode.UnsupportedAssetTypeMusic,
    SskyErrorCode.ImageAssetSizeTooBig,
    SskyErrorCode.VideoAssetSizeTooBig,
    SskyErrorCode.AudioAssetSizeTooBig,
    SskyErrorCode.UnsupportedAssetFontType,
    SskyErrorCode.CsvUploadUnsupportedFile,
    SskyErrorCode.CsvUploadFileNotUtf8,
    SskyErrorCode.CsvUploadFileTooBig,
    SskyErrorCode.CsvUploadFileEmpty,
    SskyErrorCode.CsvUploadDuplicateHeaders,
    SskyErrorCode.CsvUploadIllegalCharacterInHeaders
];

export const handleFileUploadError = (fileName: string, error: EnhancedError, context: UploadFileContext, notUsingProcessedFilesSnackbar?: boolean) => {
    const sskyErrorCode = error?.getSskyErrorCode?.();
    if (UploadFileClientSideValidations.includes(sskyErrorCode)) {
        const pendo = PendoService.getInstance();
        fileName && !notUsingProcessedFilesSnackbar ? notifyUsingProcessedFilesSnackbar(sskyErrorCode, fileName) : notifyUsingNotificationSnackbar(sskyErrorCode, error);
        pendo.trackEvent("Invalid File Upload", { context, sskyErrorCode, message: NotificationErrorText[sskyErrorCode], fileName });
    }
    else {
        handleEditorError({ error, sskyCode: sskyErrorCode, fileName });
    }
};
