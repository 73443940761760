import type { GqlClientEditorLibraryThemeFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorLibraryThemesQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseLibraryThemes = {
    libraryThemes: GqlClientEditorLibraryThemeFragment[];
};

export const useLibraryThemes = (): UseLibraryThemes => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorLibraryThemesQuery({ variables: { programId, programVersionId } });

    const allLibraryThemes = data?.editorProgram?.programVersion?.ccLibraryVersion?.themes ?? [];

    const libraryThemes = allLibraryThemes.filter(theme => Boolean(theme.posterUrl));

    return { libraryThemes };
};
