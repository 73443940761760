import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import type {
    GqlClientAccountDataLibraryVersion,
    GqlClientAccountPronunciationLibraryVersion,
    GqlClientBrandConfigurationCollection,
    GqlClientEditorProgramVersion
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramVersionLastEditedQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { useEditorProgramVersionUsedDataFields } from "../UseEditorProgramVersionUsedDataFields";

export type UseEditorLibrariesVersionSaved = {
    librariesSavedTimeDate?: Date;
    librariesSavedBy?: string;
};

type EditorLibrariesSaved = {
    librariesSavedTimeDate: Date;
    librariesSavedBy: string;
};

type EditorProgramLibrariesLastEditedInfo = Pick<GqlClientEditorProgramVersion, "id" > &
    {
        accountDataLibraryVersion?:
            Pick<GqlClientAccountDataLibraryVersion, "id" | "anyUpdatedBy" | "anyUpdated">
        selectedBrandConfiguration:
            Pick<GqlClientBrandConfigurationCollection, "id" | "updated" | "updatedBy">
        pronunciationLibraryVersion?:
            Pick<GqlClientAccountPronunciationLibraryVersion, "id" | "anyUpdated" | "anyUpdatedBy">
    };

const calcEditorLibrariesVersionSaved = (
    editorProgramVersion: EditorProgramLibrariesLastEditedInfo,
    isPersonalized: boolean
): EditorLibrariesSaved => {
    const isNewVideoPageEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);
    const savedTimesOptions: EditorLibrariesSaved[] = [
        {
            librariesSavedTimeDate: new Date(editorProgramVersion.selectedBrandConfiguration.updated),
            librariesSavedBy: editorProgramVersion.selectedBrandConfiguration.updatedBy
        }];
    if (editorProgramVersion.accountDataLibraryVersion && (!isNewVideoPageEnabled || isPersonalized)) {
        savedTimesOptions.push({
            librariesSavedTimeDate: new Date(editorProgramVersion.accountDataLibraryVersion.anyUpdated),
            librariesSavedBy: editorProgramVersion.accountDataLibraryVersion.anyUpdatedBy
        });
    }
    if (editorProgramVersion.pronunciationLibraryVersion) {
        savedTimesOptions.push({
            librariesSavedTimeDate: new Date(editorProgramVersion.pronunciationLibraryVersion.anyUpdated),
            librariesSavedBy: editorProgramVersion.pronunciationLibraryVersion.anyUpdatedBy
        });
    }
    return savedTimesOptions.reduce((prev, current) => (prev.librariesSavedTimeDate.getTime() > current.librariesSavedTimeDate.getTime()) ? prev : current);
};

export const useEditorLibrariesVersionSaved = (programId: string, programVersionId: string, skip?: boolean): UseEditorLibrariesVersionSaved => {
    const isNewVideoPageEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);
    const { usedElements, loading } = useEditorProgramVersionUsedDataFields(programId, programVersionId, skip || !isNewVideoPageEnabled);
    const { data } = useEditorProgramVersionLastEditedQuery(
        { variables: { programId, programVersionId }, skip: skip || !programId || !programVersionId }
    );
    const programVersion = data?.editorProgram.programVersion;
    const isPersonalized = Object.keys(usedElements).length > 0;
    return (programVersion && (!isNewVideoPageEnabled || !loading))
        ? calcEditorLibrariesVersionSaved(programVersion, isPersonalized)
        : {};
};
