import type { FC } from "react";
import React from "react";
import type { Theme } from "@mui/material";
import { CircularProgress } from "@mui/material";
import type { SxProps } from "@mui/system";

export type TruffleCircularProgressProps = {
    thickness?: number;
    size?: number | string;
    sx?: SxProps<Theme>
}

export const TruffleCircularProgress: FC<TruffleCircularProgressProps> = (props) => {
    const { thickness = 4, size = "100%", sx } = props;
    return <CircularProgress
        thickness={thickness}
        size={size}
        color="gradient"
        sx={sx}
    />;
};
