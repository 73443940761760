import type { MutableRefObject } from "react";
import { useLayoutEffect, useState } from "react";

type UseDimensions = {
    width: number,
    height: number
}

export const useDimensions = (element: MutableRefObject<HTMLElement>): UseDimensions => {
    const [dimensions, setDimensions] = useState<UseDimensions>({ width: element.current?.clientWidth ?? 0, height: element.current?.clientHeight ?? 0 });
    
    useLayoutEffect(() => {
        function handleResize() {
            const { current } = element;
            if (current) {
                const newDimensions = { width: current.clientWidth, height: current.clientHeight };
                setDimensions(newDimensions);
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize, true);
        return () => window.removeEventListener("resize", handleResize, true);
    }, [element.current]);

    return dimensions;
};