import { useReactiveVar } from "@apollo/client";
import type { BrandId } from "../../types";
import { useEditorSelectedBrandIdQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { setBrandByAudienceFallbackId, setEditedBrandId, setIsNewBrand } from "../../Nooks";
import { localBrandByAudienceFallbackIdVar, localEditedBrandIdVar, localIsNewBrandVar } from "../../State";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

type UseSelectedBrandId = BrandId;

export const useSelectedBrandId = (programId: string, programVersionId: string): UseSelectedBrandId => {
    // const { programId, programVersionId } = useEditorProgramIds();
    const { data } = useEditorSelectedBrandIdQuery({ variables: { programId, programVersionId } });

    return data?.editorProgram?.programVersion?.selectedBrandConfiguration?.id;
};

export type UseActiveBrandId = {
    activeBrandId: BrandId;
    selectedBrandId: BrandId;
    localEditedBrandId: BrandId;
    localBrandByAudienceFallbackId: BrandId;
    setEditedBrandId: (brandId: BrandId) => void;
    setBrandByAudienceFallbackId: (brandId: BrandId) => void;
    isCurrentlyEdited: boolean;
    localIsNewBrand: boolean;
    setIsNewBrand: (isNew: boolean) => void;
};

export const useActiveBrandId = (): UseActiveBrandId => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const selectedBrandId = useSelectedBrandId(programId, programVersionId);
    const localEditedBrandId = useReactiveVar(localEditedBrandIdVar);
    const localBrandByAudienceFallbackId = useReactiveVar(localBrandByAudienceFallbackIdVar);
    const localIsNewBrand = useReactiveVar(localIsNewBrandVar);

    return {
        activeBrandId: localEditedBrandId || localBrandByAudienceFallbackId || selectedBrandId,
        selectedBrandId,
        localEditedBrandId,
        localBrandByAudienceFallbackId,
        setEditedBrandId,
        setBrandByAudienceFallbackId,
        isCurrentlyEdited: Boolean(localEditedBrandId),
        localIsNewBrand,
        setIsNewBrand
    };
};
