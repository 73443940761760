import type { FetchResult, MutationOptions } from "@apollo/client";
import type { SubscriptionOptions } from "@apollo/client/core/watchQueryOptions";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { newNavEditorPath } from "@sundaysky/smartvideo-hub-urls";
import type ReactAudioPlayer from "react-audio-player";
import { v4 as uuid } from "uuid";
import { SskyErrorCode } from "../../../../../common/errors";
import { isPermitted, permissions } from "../../../../../common/rolesPermissionsConst";
import type { EditorAudioSettings } from "../../../../../common/types/editorAudioSettings";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreateEditorProgramSnapshotInput,
    GqlClientCreateEditorProgramSnapshotMutation,
    GqlClientCreateEditorProgramSnapshotMutationVariables,
    GqlClientEditorAssetSource,
    GqlClientEditorProgramTotalViewsQuery,
    GqlClientEditorProgramTotalViewsQueryVariables,
    GqlClientEditorSceneFragment,
    GqlClientEditorSceneLineupQuery,
    GqlClientEditorSceneLineupQueryVariables,
    GqlClientEnumAspectRatio,
    GqlClientProgramEditorPublishedPayload,
    GqlClientProgramEditorPublishedPayloadError,
    GqlClientProgramEditorPublishedPayloadPending,
    GqlClientProgramEditorPublishedPayloadSuccess,
    GqlClientProgramEditorPublishedSubscriptionVariables,
    GqlClientProgramVersionNarrationRecordingStateCalculatedPayload,
    GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadError,
    GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadSuccess,
    GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables,
    GqlClientRecalculateEditorNarrationRecordingStateInput,
    GqlClientRecalculateEditorNarrationRecordingStateMutation,
    GqlClientRecalculateEditorNarrationRecordingStateMutationVariables,
    GqlClientUpdateEditorChapteringMutation,
    GqlClientUpdateEditorChapteringMutationVariables,
    GqlClientUpdateEditorIsLiveMutation,
    GqlClientUpdateEditorIsLiveMutationVariables,
    GqlClientUpdateEditorLineupInput,
    GqlClientUpdateEditorLineupMutation,
    GqlClientUpdateEditorLineupMutationVariables,
    GqlClientUpdateEditorNarrationLibraryInput,
    GqlClientUpdateEditorNarrationLibraryMutation,
    GqlClientUpdateEditorNarrationLibraryMutationVariables,
    GqlClientUpdateEditorProgramLifecycleStageInput,
    GqlClientUpdateEditorProgramLifecycleStageMutation,
    GqlClientUpdateEditorProgramLifecycleStageMutationVariables,
    GqlClientUpdateEditorProgramNameInput,
    GqlClientUpdateEditorProgramNameMutation,
    GqlClientUpdateEditorProgramNameMutationVariables,
    GqlClientUpdateEditorProgramThemeInput,
    GqlClientUpdateEditorProgramThemeMutation,
    GqlClientUpdateEditorProgramThemeMutationVariables,
    GqlClientUpdateEditorProgramVersionAspectRatioInput,
    GqlClientUpdateEditorProgramVersionAspectRatioMutation,
    GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables,
    GqlClientUpdateEditorSelectedMusicMutation,
    GqlClientUpdateEditorSelectedMusicMutationVariables,
    GqlClientUpdateEditorSelectedNarratorMutation,
    GqlClientUpdateEditorSelectedNarratorMutationVariables,
    GqlClientUpdateEditorTtsFullEnablementMutation,
    GqlClientUpdateEditorTtsFullEnablementMutationVariables,
    GqlClientUpdateProjectAudioSettingsMutation,
    GqlClientUpdateProjectAudioSettingsMutationVariables,
    GqlClientUpgradeEditorProgramToM2LayoutsMutation,
    GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateEditorProgramSnapshotDocument,
    EditorProgramTotalViewsDocument,
    EditorSceneLineupDocument,
    GqlClientAssetLifecycleStage,
    GqlClientStatus,
    GqlClientUpdateEditorLineupResult,
    GqlClientUpdateEditorProgramLifecycleStageResult,
    GqlClientUpdateEditorProgramThemeResult,
    ProgramEditorPublishedDocument,
    ProgramVersionNarrationRecordingStateCalculatedDocument,
    RecalculateEditorNarrationRecordingStateDocument,
    UpdateEditorChapteringDocument,
    UpdateEditorIsLiveDocument,
    UpdateEditorLineupDocument,
    UpdateEditorNarrationLibraryDocument,
    UpdateEditorProgramLifecycleStageDocument,
    UpdateEditorProgramNameDocument,
    UpdateEditorProgramThemeDocument,
    UpdateEditorProgramVersionAspectRatioDocument,
    UpdateEditorSelectedMusicDocument,
    UpdateEditorSelectedNarratorDocument,
    UpdateEditorTtsFullEnablementDocument,
    UpdateProjectAudioSettingsDocument,
    UpgradeEditorProgramToM2LayoutsDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { IGNORE_SERVER_ERRORS, IGNORE_UPDATED } from "../../../../logic/common/Consts";
import type { VideoStatus } from "../../../newNav/components/videos/videoStatusUtils";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { PendoService } from "../../../pendoService";
import {
    localEditorDataConnectorEnabledVar,
    localEditorIsPreviewM2Layouts,
    localEditorIsSelectedSceneIsNewVar,
    localEditorPermissionsVar,
    localEditorPublishStateVar,
    localEditorSelectedAudioPreview,
    localEditorSelectedPlaceholderIdVar,
    localEditorSelectedPlaceholderOpenEditVar,
    localEditorSelectedNarrationPlaceholderOpenEditVar,
    localEditorSelectedSceneIdVar,
    localEditorShouldOpenVoiceLibrary
} from "../../State";
import type {
    BackgroundMutationCallbackFn,
    BackgroundMutationCallbacks,
    BackgroundMutationPayloadResult,
    EditorDataConnectorEnabled,
    EditorPermissions,
    Lineup,
    MusicId,
    NarratorId,
    PlaceholderId,
    ProgramId,
    ProgramVersionId,
    PublishState,
    SceneId
} from "../../types";
import { executeBackgroundMutation } from "../BackgroundMutation";
import { handleEditorError, setSuccessNotification } from "../Notification";
import { getScenes } from "../Scene";

export const setProjectLineup = async (
    programVersionId: ProgramVersionId,
    projectLineup: Lineup,
    optimistic: boolean
): Promise<void> => {
    try {
        const { mutate } = getApolloClient();

        const input: GqlClientUpdateEditorLineupInput = {
            editorProgramVersionId: programVersionId,
            sceneLineup: projectLineup
        };
        let options: MutationOptions<GqlClientUpdateEditorLineupMutation, GqlClientUpdateEditorLineupMutationVariables & { [IGNORE_SERVER_ERRORS]: boolean }> = {
            mutation: UpdateEditorLineupDocument,
            variables: {
                input,
                [IGNORE_SERVER_ERRORS]: true
            }
        };

        if (optimistic) {
            options.optimisticResponse = {
                __typename: "Mutation",
                updateEditorLineup: {
                    __typename: "UpdateEditorLineupOutput",
                    result: GqlClientUpdateEditorLineupResult.SUCCESS,
                    editorProgramVersion: {
                        id: programVersionId,
                        __typename: "EditorProgramVersion",
                        sceneLineup: projectLineup
                    }
                }
            };
        }

        await mutate(options);
    }
    catch (e) {
        // ignore
    }
};

export const getProjectLineup = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): Lineup => {
    const cachedQuery = getApolloClient().cache.readQuery<GqlClientEditorSceneLineupQuery, GqlClientEditorSceneLineupQueryVariables>({
        query: EditorSceneLineupDocument,
        variables: {
            programId,
            programVersionId
        }
    });

    return cachedQuery.editorProgram.programVersion.sceneLineup;
};

export const setProjectSelectedMusicId = (
    programVersionId: ProgramVersionId,
    selectedMusicId: MusicId,
    selectedMusicSource: GqlClientEditorAssetSource
): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdateEditorSelectedMusicMutation, GqlClientUpdateEditorSelectedMusicMutationVariables>({
        mutation: UpdateEditorSelectedMusicDocument,
        variables: {
            updateEditorSelectedMusicInput: {
                editorProgramVersionId: programVersionId,
                selectedMusicId,
                selectedMusicSource
            }
        }
    });
};

export const setEditorTtsFullEnablement = (
    programVersionId: ProgramVersionId,
    isEnabled: boolean
): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdateEditorTtsFullEnablementMutation, GqlClientUpdateEditorTtsFullEnablementMutationVariables>({
        mutation: UpdateEditorTtsFullEnablementDocument,
        variables: {
            updateEditorTtsFullEnablementInput: {
                editorProgramVersionId: programVersionId,
                isEnabled,
                updated: IGNORE_UPDATED
            }
        }
    }).catch(() => {});
};

export const setSelectedAudioPreview = (audioEl: ReactAudioPlayer): void => {
    localEditorSelectedAudioPreview(audioEl);
};

export const setProjectSelectedNarrator = async (
    programVersionId: ProgramVersionId,
    selectedNarratorId: NarratorId
): Promise<void> => {
    const client = getApolloClient();

    await client.mutate<GqlClientUpdateEditorSelectedNarratorMutation, GqlClientUpdateEditorSelectedNarratorMutationVariables>({
        mutation: UpdateEditorSelectedNarratorDocument,
        variables: {
            updateEditorSelectedNarratorInput: {
                editorProgramVersionId: programVersionId,
                selectedNarratorId
            }
        }
    }).catch(/* ignore */);
};

export const setSelectedSceneId = (sceneId: SceneId): void => {
    localEditorSelectedSceneIdVar(sceneId);
};

export const setIsSelectedSceneIsNew = (isNewScene: boolean): void => {
    localEditorIsSelectedSceneIsNewVar(isNewScene);
};

export const setSelectedPlaceholderId = (placeholderId: PlaceholderId): void => {
    localEditorSelectedPlaceholderIdVar(placeholderId);
};

export const setSelectedPlaceholderOpenEdit = (isOpen: boolean): void => {
    localEditorSelectedPlaceholderOpenEditVar(isOpen);
};

export const setSelectedNarrationPlaceholderOpenEdit = (isOpen: boolean): void => {
    localEditorSelectedNarrationPlaceholderOpenEditVar(isOpen);
};

export const getSelectedSceneId = (): SceneId => {
    return localEditorSelectedSceneIdVar();
};

export const createEditorProgramSnapshot = async (
    programVersionId: ProgramVersionId,
    snapshotName?: string,
    snapshotComment?: string
): Promise<FetchResult<GqlClientCreateEditorProgramSnapshotMutation>> => {
    setEditorPublishState({
        publishStatus: GqlClientStatus.PENDING,
        progress: 0
    });

    const { mutate } = getApolloClient();

    const input: GqlClientCreateEditorProgramSnapshotInput = {
        editorProgramVersionId: programVersionId,
        snapshotComment: snapshotComment,
        snapshotName: snapshotName
    };

    const options: MutationOptions<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables> = {
        mutation: CreateEditorProgramSnapshotDocument,
        variables: {
            input,
            mutationId: uuid()
        }
    };

    return mutate<GqlClientCreateEditorProgramSnapshotMutation, GqlClientCreateEditorProgramSnapshotMutationVariables>(options);
};

export const updateEditorNarrationLibrary = (
    programVersionId: ProgramVersionId
): Promise<FetchResult<GqlClientUpdateEditorNarrationLibraryMutation>> => {
    const { mutate } = getApolloClient();

    const input: GqlClientUpdateEditorNarrationLibraryInput = {
        editorProgramVersionId: programVersionId
    };

    const options: MutationOptions<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables> = {
        mutation: UpdateEditorNarrationLibraryDocument,
        variables: {
            input
        }
    };

    return mutate<GqlClientUpdateEditorNarrationLibraryMutation, GqlClientUpdateEditorNarrationLibraryMutationVariables>(options);
};

export const setEditorPermissions = (userRoles: string[]): void => {
    const canOnlyViewProgram: boolean = isPermitted(userRoles, permissions.getEditorPrograms) && !isPermitted(userRoles, permissions.editEditorPrograms);
    const canCreateEditorProgram: boolean = isPermitted(userRoles, permissions.createEditorPrograms);
    const canEditEditorProgram: boolean = isPermitted(userRoles, permissions.editEditorPrograms);
    const canEditBrandConfiguration: boolean = isPermitted(userRoles, permissions.editBrandConfigurations);
    const canEditViewerProfiles: boolean = isPermitted(userRoles, permissions.editViewerProfiles);
    const canGenerateVideoLinks: boolean = isPermitted(userRoles, permissions.generateVideoLinks);
    const canGenerateVideoLinksProd: boolean = isPermitted(userRoles, permissions.generateVideoLinksProd);
    const canEditLandingPageBuilderApiGateway: boolean = isPermitted(userRoles, permissions.editLandingPageBuilderApiGateway);
    const canEditVideo: boolean = canCreateEditorProgram || canEditEditorProgram || canEditBrandConfiguration;
    const canUpdateCustomConnector: boolean = isPermitted(userRoles, permissions.editEditorCustomIntegrations);

    const editorPermissions: EditorPermissions = {
        canCreateEditorProgram,
        canEditEditorProgram,
        canOnlyViewProgram,
        canEditViewerProfiles,
        canGenerateVideoLinks,
        canGenerateVideoLinksProd,
        canEditLandingPageBuilderApiGateway,
        canEditVideo,
        canUpdateCustomConnector
    };
    localEditorPermissionsVar(editorPermissions);
};

export const getEditorDataConnectorEnabled = (): EditorDataConnectorEnabled => {
    return localEditorDataConnectorEnabledVar();
};

export const setProjectAudioSettings = (
    programVersionId: ProgramVersionId,
    audioSettings: EditorAudioSettings
): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdateProjectAudioSettingsMutation, GqlClientUpdateProjectAudioSettingsMutationVariables>({
        mutation: UpdateProjectAudioSettingsDocument,
        variables: {
            updateProjectAudioSettingsInput: {
                editorProgramVersionId: programVersionId,
                audioSettings
            }
        }
    });

};

export const updateEditorProgramName = async (programId: string, programName: string): Promise<FetchResult<GqlClientUpdateEditorProgramNameMutation>> => {
    const { mutate } = getApolloClient();

    const input: GqlClientUpdateEditorProgramNameInput = {
        programId,
        updated: IGNORE_UPDATED,
        name: programName
    };

    const options: MutationOptions<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables> = {
        mutation: UpdateEditorProgramNameDocument,
        variables: {
            input
        }
    };

    return mutate<GqlClientUpdateEditorProgramNameMutation, GqlClientUpdateEditorProgramNameMutationVariables>(options);
};

export const updateEditorProgramLifecycleStage = async (programId: string, lifecycleStage: GqlClientAssetLifecycleStage): Promise<FetchResult<GqlClientUpdateEditorProgramLifecycleStageMutation>> => {
    const { mutate } = getApolloClient();
    const input: GqlClientUpdateEditorProgramLifecycleStageInput = {
        id: programId,
        updated: IGNORE_UPDATED,
        lifecycleStage: lifecycleStage
    };

    const options: MutationOptions<GqlClientUpdateEditorProgramLifecycleStageMutation, GqlClientUpdateEditorProgramLifecycleStageMutationVariables> = {
        mutation: UpdateEditorProgramLifecycleStageDocument,
        variables: {
            input
        }
    };
    return mutate<GqlClientUpdateEditorProgramLifecycleStageMutation, GqlClientUpdateEditorProgramLifecycleStageMutationVariables>(options);
};

export const navigateToEditVideo = (history, accountId: string, programId: string) => {
    history.push(newNavEditorPath({ accountId, videoId: programId }));
};

export const onEditCopilotVideo = async (programId: string) => {
    let output = await updateEditorProgramLifecycleStage(programId, GqlClientAssetLifecycleStage.ENABLED);
    const isNewVideoPageEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);

    if (output?.data?.updateEditorProgramLifecycleStage?.result === GqlClientUpdateEditorProgramLifecycleStageResult.SUCCESS) {

        const message = `Video moved from Copilot drafts folder to ${isNewVideoPageEnabled ? "Video Library" : "Your Videos"} page`;

        // set success notification
        setSuccessNotification({
            message,
            showCloseButton: true,
            duration: 10 * 1000
        });
    }
    else {
        handleEditorError({
            error: new Error(`Failed to move video from Copilot drafts to ${isNewVideoPageEnabled ? "Video Library" : "Your Videos" } page`), sskyCode: SskyErrorCode.UnexpectedServerError
        });
    }
};

export function getEditorPublishState() {
    return localEditorPublishStateVar();
}

export const setEditorPublishState = (publishState: PublishState) => {
    localEditorPublishStateVar(publishState);
};

export const updateEditorChaptering = (editorProgramVersionId: string, chapteringEnabled: boolean): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdateEditorChapteringMutation, GqlClientUpdateEditorChapteringMutationVariables>({
        mutation: UpdateEditorChapteringDocument,
        variables: {
            updateEditorChapteringInput: {
                editorProgramVersionId,
                chapteringEnabled,
                updated: IGNORE_UPDATED
            }
        }
    });
};

export const updateEditorProgramThemeId = async (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    programThemeId: string
): Promise<void> => {
    const scenes: GqlClientEditorSceneFragment[] = getScenes(programId, programVersionId);
    const { mutate } = getApolloClient();

    const input: GqlClientUpdateEditorProgramThemeInput = {
        programVersionId,
        updated: IGNORE_UPDATED,
        theme: programThemeId
    };

    const options: MutationOptions<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables> = {
        mutation: UpdateEditorProgramThemeDocument,
        variables: {
            input
        },
        optimisticResponse: {
            __typename: "Mutation",
            updateEditorProgramTheme: {
                __typename: "UpdateEditorProgramThemeOutputSuccess",
                result: GqlClientUpdateEditorProgramThemeResult.SUCCESS,
                editorProgramVersion: {
                    id: programVersionId,
                    __typename: "EditorProgramVersion",
                    ccTheme: {
                        id: programThemeId,
                        __typename: "CcTheme"
                    },
                    scenes: scenes
                }
            }
        }
    };

    await mutate<GqlClientUpdateEditorProgramThemeMutation, GqlClientUpdateEditorProgramThemeMutationVariables>(options).catch(() => {});
};

export const updateEditorProgramVersionAspectRatio = async (programVersionId: string, aspectRatio: GqlClientEnumAspectRatio): Promise<void> => {
    const { mutate } = getApolloClient();

    const input: GqlClientUpdateEditorProgramVersionAspectRatioInput = {
        id: programVersionId,
        aspectRatio: aspectRatio,
        updated: IGNORE_UPDATED
    };

    const options: MutationOptions<GqlClientUpdateEditorProgramVersionAspectRatioMutation, GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables> = {
        mutation: UpdateEditorProgramVersionAspectRatioDocument,
        variables: {
            input
        }
    };

    await mutate<GqlClientUpdateEditorProgramVersionAspectRatioMutation, GqlClientUpdateEditorProgramVersionAspectRatioMutationVariables>(options).catch(() => {});
};

export const publishEditorProgram = async (
    programId: string,
    programVersionId: string,
    onProgress: BackgroundMutationCallbackFn<GqlClientProgramEditorPublishedPayloadPending>,
    onFailure: BackgroundMutationCallbackFn<GqlClientProgramEditorPublishedPayloadError>,
    onSuccess: BackgroundMutationCallbackFn<GqlClientProgramEditorPublishedPayloadSuccess>
): Promise<FetchResult<GqlClientCreateEditorProgramSnapshotMutation>> => {
    setEditorPublishState({
        publishStatus: GqlClientStatus.PENDING,
        progress: 0
    });

    const input: GqlClientCreateEditorProgramSnapshotInput = { editorProgramVersionId: programVersionId };

    const options: MutationOptions<GqlClientCreateEditorProgramSnapshotMutation, Omit<GqlClientCreateEditorProgramSnapshotMutationVariables, "mutationId">> = {
        mutation: CreateEditorProgramSnapshotDocument,
        variables: {
            input
        }
    };

    const callbacks: BackgroundMutationCallbacks<GqlClientProgramEditorPublishedPayload> = {
        onProgress,
        onFailure,
        onSuccess
    };

    const subscriptionOptions: SubscriptionOptions<GqlClientProgramEditorPublishedSubscriptionVariables, BackgroundMutationPayloadResult<GqlClientProgramEditorPublishedPayload>> = {
        query: ProgramEditorPublishedDocument,
        variables: {
            programId
        }
    };

    return executeBackgroundMutation<GqlClientProgramEditorPublishedPayload, GqlClientCreateEditorProgramSnapshotMutation>(options, subscriptionOptions, callbacks)
        .catch(e => {
            setEditorPublishState(null);
            throw e;
        });
};

export const getEditorIsPreviewM2 = (): boolean => {
    return localEditorIsPreviewM2Layouts();
};

export const setEditorIsPreviewM2 = (isPreviewM2: boolean) => {
    localEditorIsPreviewM2Layouts(isPreviewM2);
};

export const getShouldOpenVoiceLibrary = (): boolean => {
    return localEditorShouldOpenVoiceLibrary();
};

export const setShouldOpenVoiceLibrary = (openVoiceLibrary: boolean) => {
    localEditorShouldOpenVoiceLibrary(openVoiceLibrary);
};

export const upgradeEditorProgramToM2Layouts = (
    programVersionId: ProgramVersionId
): Promise<FetchResult<GqlClientUpgradeEditorProgramToM2LayoutsMutation>> => {
    const { mutate } = getApolloClient();


    const options: MutationOptions<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables> = {
        mutation: UpgradeEditorProgramToM2LayoutsDocument,
        variables: {
            input: {
                editorProgramVersionId: programVersionId,
                allowPartialMapping: false
            }
        }
    };

    return mutate<GqlClientUpgradeEditorProgramToM2LayoutsMutation, GqlClientUpgradeEditorProgramToM2LayoutsMutationVariables>(options);
};

export const recalculateEditorNarrationRecordingState = async (
    programVersionId: ProgramVersionId,
    onFailure: BackgroundMutationCallbackFn<GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadError>,
    onSuccess: BackgroundMutationCallbackFn<GqlClientProgramVersionNarrationRecordingStateCalculatedPayloadSuccess>
): Promise<FetchResult<GqlClientRecalculateEditorNarrationRecordingStateMutation>> => {
    const input: GqlClientRecalculateEditorNarrationRecordingStateInput = { editorProgramVersionId: programVersionId };

    const options: MutationOptions<GqlClientRecalculateEditorNarrationRecordingStateMutation, Omit<GqlClientRecalculateEditorNarrationRecordingStateMutationVariables, "mutationId">> = {
        mutation: RecalculateEditorNarrationRecordingStateDocument,
        variables: {
            input
        }
    };

    const callbacks: BackgroundMutationCallbacks<GqlClientProgramVersionNarrationRecordingStateCalculatedPayload> = {
        onProgress: null,
        onFailure,
        onSuccess
    };

    const subscriptionOptions: SubscriptionOptions<
        GqlClientProgramVersionNarrationRecordingStateCalculatedSubscriptionVariables,
        BackgroundMutationPayloadResult<GqlClientProgramVersionNarrationRecordingStateCalculatedPayload>
    > = {
        query: ProgramVersionNarrationRecordingStateCalculatedDocument,
        variables: {
            programVersionId
        }
    };

    return executeBackgroundMutation<
        GqlClientProgramVersionNarrationRecordingStateCalculatedPayload,
        GqlClientRecalculateEditorNarrationRecordingStateMutation
    >(options, subscriptionOptions, callbacks);
};

export enum ChangeProgramToLiveContext {
    VIDEO_PAGE_BANNER = "video page banner",
    ANALYZE_TAB = "analyze tab"
}

export async function changeProgramToLive(
    programId: ProgramId,
    context: ChangeProgramToLiveContext,
    previousStatus: VideoStatus
): Promise<void> {
    const client = getApolloClient();
    await client.mutate<
        GqlClientUpdateEditorIsLiveMutation,
        GqlClientUpdateEditorIsLiveMutationVariables
    >({
        mutation: UpdateEditorIsLiveDocument,
        variables: {
            input: {
                programId
            }
        }
    });
    client.query<
        GqlClientEditorProgramTotalViewsQuery,
        GqlClientEditorProgramTotalViewsQueryVariables
    >({
        query: EditorProgramTotalViewsDocument,
        variables: {
            programId
        }
    }).then((totalViews) => {
        PendoService.getInstance().trackEvent(
            "Video changed to live",
            {
                context,
                previousStatus,
                totalViews: `${totalViews.data?.editorProgram?.totalViews}`
            }
        );
    });
}
