import { useUserSettingsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { localEditorUserNameVar } from "../../State";
import { setUserSettings } from "../../Nooks";

export type UseGenerativeAIUsageAcceptance = {
    isLoadingUserAcceptedTerms: boolean;
    hasUserAcceptedTerms: boolean;
    acceptUsage: () => Promise<void>;
    declineUsage: () => Promise<void>;
};

export const useGenerativeAIUsageAcceptance = (): UseGenerativeAIUsageAcceptance => {
    const { loading, data } = useUserSettingsQuery({ variables: { id: localEditorUserNameVar() } });
    const hasUserAcceptedTerms = !!data?.userSettings?.acceptedGenerativeAiTerms;

    const acceptUsage = async (): Promise<void> => {
        setUserSettings({ acceptedGenerativeAiTerms: true });
    };

    const declineUsage = async (): Promise<void> => {
        // todo? maybe this is not needed
    };

    return { isLoadingUserAcceptedTerms: loading, hasUserAcceptedTerms, acceptUsage, declineUsage };
};
