import type { GqlClientAccountFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { localEditorAccountIdVar, localEditorAccountNameVar, localEditorAccountTypeVar } from "../../State";
import { useReactiveVar } from "@apollo/client";

export type UseEditorAccount = {
    editorAccountName: GqlClientAccountFragment["displayName"],
    editorAccountId: GqlClientAccountFragment["id"],
    isEditorAccountInternal: boolean
};

export const useEditorAccount = (): UseEditorAccount => {
    return { 
        editorAccountName: useReactiveVar(localEditorAccountNameVar),
        editorAccountId: useReactiveVar(localEditorAccountIdVar),
        isEditorAccountInternal: useReactiveVar(localEditorAccountTypeVar) === "Internal"
    };
};
