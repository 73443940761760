import type {
    GqlClientUploadEditorAssetMusicInput,
    GqlClientUploadEditorAssetMusicMutation,
    GqlClientUploadEditorAssetMusicMutationVariables,
    GqlClientUploadStockEditorAssetMusicInput,
    GqlClientUploadStockEditorAssetMusicMutation,
    GqlClientUploadStockEditorAssetMusicMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    UploadEditorAssetMusicDocument,
    UploadStockEditorAssetMusicDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getApolloClient } from "../../../../apollo";
import type { UploadMediaAssetData, UploadResponse } from "../../types";
import { processUploadAssetMediaInput } from "../../Utils";
import type { MutationOptions } from "@apollo/client";
import type { GraphQLError } from "graphql";
import { getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../../../common/errors";
import AssetUtils from "../../../../logic/common/assetUtils";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";


export async function uploadEditorAssetMusic(file: File): UploadResponse {
    const { mutate } = getApolloClient();

    try {
        const { isValid, fileExtension } = AssetUtils.isFileExtensionValid(file.name, ["mp3"]);
        if (!isValid) {
            return {
                error: new EnhancedError(
                    new Error(`File extension: "${fileExtension}" isn't supported. Music upload supports mp3 files.`),
                    SskyErrorCode.UnsupportedAssetTypeMusic)
            };
        }

        const { error, fileData } = await processUploadAssetMediaInput(file, ["audio"]);
        if (error) {
            return { error };
        }

        if (fileData) {
            const filename: string = file.name;
            const uploadEditorAssetInput: GqlClientUploadEditorAssetMusicInput = {
                file,
                filename,
                fileType: fileData.fileType
            };

            const options: MutationOptions<GqlClientUploadEditorAssetMusicMutation, GqlClientUploadEditorAssetMusicMutationVariables> = {
                mutation: UploadEditorAssetMusicDocument,
                variables: {
                    input: uploadEditorAssetInput
                }
            };

            const { data } = await mutate(options);

            const { id, url } = data.uploadEditorAssetMusic.editorAssetMusic;
            if (data) {
                const asset: Omit<UploadMediaAssetData, "mediaMetadata"> = {
                    id,
                    mediaLocation: url,
                    mediaUrl: url
                };

                return { uploadResult: asset };
            }
        }
    }
    catch (error) {
        const gqlError: GraphQLError = error.graphQLErrors?.[0];
        const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.UnexpectedClientError;

        return { error: new EnhancedError(gqlError || error, sskyErrorCode) };
    }
}

export async function uploadStockEditorAssetMusic(assetId: string, programId: string, title: string, accountId: string): UploadResponse {
    const { mutate } = getApolloClient();

    try {
        const uploadEditorAssetInput: GqlClientUploadStockEditorAssetMusicInput = {
            assetId,
            title,
            projectID: programId,
            userID: accountId
        };

        const options: MutationOptions<GqlClientUploadStockEditorAssetMusicMutation, GqlClientUploadStockEditorAssetMusicMutationVariables> = {
            mutation: UploadStockEditorAssetMusicDocument,
            variables: {
                input: uploadEditorAssetInput
            }
        };

        const { data } = await mutate(options);

        const { id, url } = data.uploadStockEditorAssetMusic.editorAssetMusic;
        if (data) {
            const asset: Omit<UploadMediaAssetData, "mediaMetadata"> = {
                id,
                mediaLocation: url,
                mediaUrl: url
            };

            return { uploadResult: asset };
        }
    }
    catch (error) {
        const gqlError: GraphQLError = error.graphQLErrors?.[0];
        const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.UnexpectedClientError;

        return { error: new EnhancedError(gqlError || error, sskyErrorCode) };
    }
}
