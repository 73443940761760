import {
    useProgramVersionEditorAudioSettingsUpdatedSubscription,
    useProgramVersionEditorAvatarRenderingUpdatedSubscription,
    useProgramVersionEditorSelectedMusicUpdatedSubscription,
    useProgramVersionEditorSelectedNarratorUpdatedSubscription
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const useEditorProgramVersionSubscriptions = (programVersionId: string): void => {

    useProgramVersionEditorSelectedMusicUpdatedSubscription({
        variables: { programVersionId },
        skip: !programVersionId
    });

    useProgramVersionEditorSelectedNarratorUpdatedSubscription({
        variables: { programVersionId },
        skip: !programVersionId
    });

    useProgramVersionEditorAudioSettingsUpdatedSubscription({
        variables: { programVersionId },
        skip: !programVersionId
    });

    useProgramVersionEditorAvatarRenderingUpdatedSubscription({
        variables: { programVersionId },
        skip: !programVersionId
    });
};
