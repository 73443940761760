import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientAccountsInvitationQuery,
    GqlClientAccountsInvitationQueryVariables,
    GqlClientApproveAccountInvitationMutation,
    GqlClientApproveAccountInvitationMutationVariables,
    GqlClientCreateEditorAccountUserMutation,
    GqlClientCreateEditorAccountUserMutationVariables,
    GqlClientDeleteAccountInvitationMutation,
    GqlClientDeleteAccountInvitationMutationVariables,
    GqlClientDeleteEditorAccountUserMutation,
    GqlClientDeleteEditorAccountUserMutationVariables,
    GqlClientEditorAccountUsersQuery,
    GqlClientEditorAccountUsersQueryVariables,
    GqlClientUserManagementRole
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    AccountsInvitationDocument,
    ApproveAccountInvitationDocument,
    CreateEditorAccountUserDocument,
    DeleteAccountInvitationDocument,
    DeleteEditorAccountUserDocument,
    EditorAccountUsersDocument,
    GqlClientAccountInvitationStatus,
    GqlClientApproveAccountInvitationResult,
    GqlClientDeleteAccountInvitationResult,
    GqlClientDeleteEditorAccountUserResult
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getUserData } from "../../../newNav/nooks/model";
import { localEditorUserNameVar } from "../../State";


export const getLocalEditorUserDetails = (): string => {
    return localEditorUserNameVar();
};

export const setLocalEditorUserDetails = (userName: string): void => {
    localEditorUserNameVar(userName);
};

export const approveAccountInvitation = async (accountId: string) => {
    const apolloClient = getApolloClient();
    const { userName } = getUserData();

    const { data } = await apolloClient.mutate<GqlClientApproveAccountInvitationMutation, GqlClientApproveAccountInvitationMutationVariables>({
        mutation: ApproveAccountInvitationDocument,
        variables: {
            input: { accountId }
        },
        optimisticResponse: {
            __typename: "Mutation",
            approveAccountInvitation: {
                __typename: "ApproveAccountInvitationOutput",
                result: GqlClientApproveAccountInvitationResult.SUCCESS,
                accountUser: {
                    __typename: "AccountUser",
                    id: userName + "|" + accountId,
                    invitationStatus: GqlClientAccountInvitationStatus.ACCEPTED
                }
            }
        }
    });

    return data;
};

export const deleteAccountInvitation = async (accountId: string) => {
    const apolloClient = getApolloClient();

    const { loggedInEditorAccountUser } = apolloClient.readQuery<GqlClientAccountsInvitationQuery, GqlClientAccountsInvitationQueryVariables>({
        query: AccountsInvitationDocument
    });

    const { data } = await apolloClient.mutate<GqlClientDeleteAccountInvitationMutation, GqlClientDeleteAccountInvitationMutationVariables>({
        mutation: DeleteAccountInvitationDocument,
        variables: {
            input: { accountId }
        },
        optimisticResponse: {
            __typename: "Mutation",
            deleteAccountInvitation: {
                __typename: "DeleteAccountInvitationOutput",
                result: GqlClientDeleteAccountInvitationResult.SUCCESS,
                loggedInEditorAccountUser: {
                    __typename: "EditorAccountUser",
                    id: loggedInEditorAccountUser?.id,
                    accounts: loggedInEditorAccountUser?.accounts?.filter(account => account.account.id !== accountId)
                }
            }
        }
    });

    return data;
};

export const createEditorAccountUser = async (email: string, accountRoles: GqlClientUserManagementRole[]) => {
    const apolloClient = getApolloClient();
    const { data } = await apolloClient.mutate<GqlClientCreateEditorAccountUserMutation, GqlClientCreateEditorAccountUserMutationVariables>({
        mutation: CreateEditorAccountUserDocument,
        variables: {
            input: {
                email,
                accountRoles
            }
        }
    });
    const { currentAccount } = apolloClient.readQuery<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>({
        query: EditorAccountUsersDocument
    });
    if ("accountUser" in data.createEditorAccountUser) {
        apolloClient.cache.writeQuery<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>({
            query: EditorAccountUsersDocument,
            data: {
                currentAccount: {
                    ...currentAccount,
                    users: [...currentAccount.users, data.createEditorAccountUser.accountUser]
                }
            }
        });
    }
    return data;
};

export const deleteEditorAccountUser = async (email: string) => {
    const apolloClient = getApolloClient();
    const { data } = await apolloClient.mutate<GqlClientDeleteEditorAccountUserMutation, GqlClientDeleteEditorAccountUserMutationVariables>({
        mutation: DeleteEditorAccountUserDocument,
        variables: {
            input: { email }
        }
    });
    const { currentAccount } = apolloClient.readQuery<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>({
        query: EditorAccountUsersDocument
    });
    if (data.deleteEditorAccountUser.result === GqlClientDeleteEditorAccountUserResult.SUCCESS) {
        apolloClient.cache.writeQuery<GqlClientEditorAccountUsersQuery, GqlClientEditorAccountUsersQueryVariables>({
            query: EditorAccountUsersDocument,
            data: {
                currentAccount: {
                    ...currentAccount,
                    users: currentAccount.users.filter(accountUser => accountUser.user.email !== email)
                }
            }
        });
    }
    return data;
};
