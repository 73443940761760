import type { PpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { getEmptyPpConfiguration, PageRetention } from "@sundaysky/landing-page-skeleton-types";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { newNavVideoPagePath } from "@sundaysky/smartvideo-hub-urls";
import { getAspectRatio } from "../../../../../common/aspectRatioUtils";
import { getTranscriptFormattedDate } from "../../../../../common/editorUtils";
import { SskyErrorCode } from "../../../../../common/errors";
import { PreviewContext, VideoSessionRetention } from "../../../../../common/types/restApiTypes";
import { getApolloClient } from "../../../../apollo";
import type { GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EditorProgramForReviewPageBuilderDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { activeAccountIdVar, activeAccountNameVar } from "../../../model";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { getCurrentFullStoryUrl } from "../../Utils";
import { getLandingPagePreviewUrl } from "../../Utils/landingPageServices";
import { closeNotification, handleEditorError, setErrorNotification, setNeutralNotification, setSuccessNotification } from "../Notification";
import { createReviewLink } from "../ReviewPage";

export async function getPreviewPageConfiguration(
    trigger: PpConfiguration["trigger"],
    programId: string,
    videoName: string,
    profile?: PpConfiguration["video"]["profile"],
    selectedSceneNames?: string[]
): Promise<PpConfiguration> {
    const client = getApolloClient();
    const { data } = await client.query<
        GqlClientEditorProgramForReviewPageBuilderQuery,
        GqlClientEditorProgramForReviewPageBuilderQueryVariables
    >({
        query: EditorProgramForReviewPageBuilderDocument,
        variables: {
            programId
        }
    });
    const emptyConfiguration = getEmptyPpConfiguration();
    return {
        ...emptyConfiguration,
        video: {
            ...emptyConfiguration.video,
            url: window.location.origin + newNavVideoPagePath({
                accountId: activeAccountIdVar(),
                videoId: programId
            }),
            name: videoName,
            profile,
            selectedSceneNames
        },
        playerConfiguration: {
            ...emptyConfiguration.playerConfiguration,
            aspectRatio: getAspectRatio(data.editorProgram.programVersionDraft.aspectRatio).playerFormat(),
            posterImage: data.editorProgram.programVersionDraft.thumbnail && {
                url: data.editorProgram.programVersionDraft.thumbnail.url,
                platformId: data.editorProgram.programVersionDraft.thumbnail.url
            }
        },
        trackingAccountId: activeAccountIdVar(),
        trackingAccountName: activeAccountNameVar(),
        trackingVideoId: programId,
        trigger
    };
}

export async function createPreviewLink(
    trigger: PpConfiguration["trigger"],
    accountId: string,
    programId: string,
    programName: string,
    programVersionId: string,
    profileName: string,
    presets: Record<string, string>,
    sceneOrder?: string[],
    allScenes = true,
    selectedSceneNumbers?: number[],
    renderAvatars = false
): Promise<void> {
    if (!isFeatureEnabled(featureFlagConst._EDITOR_WELLSAID_SUNSET_EXPIRED_NOTIFICATIONS)) {
        return createReviewLink(
            accountId,
            programId,
            programName,
            programVersionId,
            profileName,
            presets,
            sceneOrder
        );
    }

    try {
        if (!renderAvatars) {
            setNeutralNotification({
                message: "Creating preview link...",
                duration: null
            });
        }
        const configuration = await getPreviewPageConfiguration(
            trigger,
            programId,
            programName,
            Object.keys(presets).length > 0
                ? { name: profileName, presets }
                : undefined,
            allScenes
                ? undefined
                : sceneOrder
        );
        // Set retention to 1 year for the USI account.
        // If more accounts need the same retention consider using a feature flag instead.
        if (accountId === USI_ACCOUNT_ID) {
            configuration.retention = PageRetention.YEAR;
        }
        const { previewUrl } = await getLandingPagePreviewUrl(
            accountId,
            programId,
            {
                programVersionId,
                presets,
                // Set retention to 1 year for the USI account.
                // If more accounts need the same retention consider using a feature flag instead.
                retention: accountId === USI_ACCOUNT_ID
                    ? VideoSessionRetention.YEAR
                    : VideoSessionRetention.MONTH,
                configuration,
                fsUrl: getCurrentFullStoryUrl(),
                overridingFields: {
                    context: PreviewContext.DRAFT_REVIEWLINK
                },
                sceneOrder,
                //represent the time of request considering client timezone
                formattedDate: getTranscriptFormattedDate(),
                renderAvatars,
                selectedSceneNumbers
            }
        );
        if (renderAvatars) {
            window.open(previewUrl, previewUrl);
        }
        else {
            closeNotification();
            await navigator.clipboard.writeText(previewUrl);
            setSuccessNotification({
                message: `Link Copied - Will Expire in ${configuration.retention} Days`,
                actionButton: {
                    label: "Open page",
                    onClick() {
                        window.open(previewUrl, "_blank");
                    }
                }
            });
        }
    }
    catch (error) {
        handleEditorError({
            error,
            sskyCode: SskyErrorCode.ReviewPageOutOfAppPreviewError
        });
        setErrorNotification({
            message: "Failed to create preview link",
            actionButton: {
                label: "Try Again",
                onClick: () => {
                    if (isFeatureEnabled(featureFlagConst._EDITOR_WELLSAID_SUNSET_EXPIRED_NOTIFICATIONS)) {
                        createPreviewLink(
                            trigger,
                            accountId,
                            programId,
                            programName,
                            programVersionId,
                            profileName,
                            presets,
                            sceneOrder,
                            allScenes,
                            selectedSceneNumbers,
                            renderAvatars
                        );
                    }
                    else {
                        createReviewLink(
                            accountId,
                            programId,
                            programName,
                            programVersionId,
                            profileName,
                            presets,
                            sceneOrder
                        );
                    }
                }
            }
        });
    }
}

const USI_ACCOUNT_ID = "0015a00002vOuD5AAK";
