import { localEditorUploadedGettyUrlsVar } from "../../State";
import { useReactiveVar } from "@apollo/client";
import {
    upsertEditorUploadedGettyUrl, removeEditorUploadedGettyUrl
} from "../../Nooks/ProcessedFiles/processedFiles";

export const useUploadedGettyUrls = () => {
    const editorUploadedGettyUrls = useReactiveVar(localEditorUploadedGettyUrlsVar);

    return {
        editorUploadedGettyUrls,
        upsertEditorUploadedGettyUrl,
        removeEditorUploadedGettyUrl
    };
};
