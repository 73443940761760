import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./HubSemanticDropDown.css";
import { valueExists } from "./utils";

class HubSemanticDropDown extends Component {
    onClick = (item, index) => {
        this.props.onChange ? this.props.onChange(item, index) : null;
        this.props.items[index].onChange ? this.props.items[index].onChange(index) : null;
    };

    render() {
        return (
            <Dropdown defaultOpen={this.props.defaultOpen} text={this.props.value} icon={this.props.icon} className={`hub-semantic-drop-down ${this.props.className ? this.props.className : ""}`}>
                <Dropdown.Menu>
                    {this.props.items &&
                        this.props.items.map((item, index) =>
                            item === "divider" ? (
                                <Dropdown.Divider key={index} />
                            ) : (
                                <Dropdown.Item
                                    onClick={() => this.onClick(item, index)}
                                    text={item.title ? item.title : item.name ? item.name.toString() : item.name}
                                    name={valueExists(item.name) ? item.name.toString() : item.name}
                                    key={index}
                                    content={item.content}
                                    disabled={item.disabled}
                                    active={item.content === this.props.value}
                                />
                            )
                        )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
HubSemanticDropDown.defaultProps = {
    defaultOpen: false
};

HubSemanticDropDown.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                disabled: PropTypes.bool,
                title: PropTypes.string,
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
                content: PropTypes.object,
                onChange: PropTypes.func
            })
        ])
    ),
    icon: PropTypes.element,
    onChange: PropTypes.func,
    value: PropTypes.string,
    defaultOpen: PropTypes.bool,
    className: PropTypes.string
};

export default HubSemanticDropDown;
