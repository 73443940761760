import { GqlClientEditorFlexiblePlaceholderParentType } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { LivePreviewScenePlaceholderData } from "../types";
import type { SceneData } from "./videoSpecBuilder";

export type LayerablePlaceholderNode = {
    placeholder: LivePreviewScenePlaceholderData;
    childPlaceholders: LayerablePlaceholderNode[];
}

export type PlaceholderTree = {
    rootPlaceholderNodes: LayerablePlaceholderNode[];
    foregroundPlaceholderNodes: LayerablePlaceholderNode[];
    canvases: Map<string, LayerablePlaceholderNode[]>;
}

export function buildPlaceholderTree(scene: SceneData): PlaceholderTree {
    const layerablePlaceholderNodes: LayerablePlaceholderNode[] = scene.placeholders.map(
        placeholder => ({ placeholder, childPlaceholders: [] })
    );

    const layerablePlaceholderNodeMap = layerablePlaceholderNodes.reduce(
        (map, layerablePlaceholderNode) => map.set(
            layerablePlaceholderNode.placeholder.id,
            layerablePlaceholderNode
        ),
        new Map<string, LayerablePlaceholderNode>()
    );

    for (const layerablePlaceholderNode of layerablePlaceholderNodes) {
        const parentPlaceholderNode = layerablePlaceholderNodeMap.get(layerablePlaceholderNode.placeholder.parentPlaceholder?.id);
        if (parentPlaceholderNode) {
            parentPlaceholderNode.childPlaceholders.push(layerablePlaceholderNode);
        }
    }

    const canvasNamesByCcPlaceholderId = new Map(
        scene.layout?.placeholdersSettings.map(s => [s.ccPlaceholder.id, s.canvasName])
    );
    const canvases = new Map<string, LayerablePlaceholderNode[]>();
    for (const layerablePlaceholderNode of layerablePlaceholderNodes) {
        if (layerablePlaceholderNode.placeholder.parentType !== GqlClientEditorFlexiblePlaceholderParentType.CANVAS) continue;
        const canvasName = canvasNamesByCcPlaceholderId.get(layerablePlaceholderNode.placeholder.ccPlaceholder.id);
        if (!canvasName) continue;
        const canvasPlaceholderNodes = canvases.get(canvasName) || [];
        canvasPlaceholderNodes.push(layerablePlaceholderNode);
        canvases.set(canvasName, canvasPlaceholderNodes);
    }

    return {
        rootPlaceholderNodes: layerablePlaceholderNodes.filter(
            layerablePlaceholderNode => !layerablePlaceholderNode.placeholder.parentType
        ),
        foregroundPlaceholderNodes: layerablePlaceholderNodes.filter(
            layerNode => layerNode.placeholder.parentType === GqlClientEditorFlexiblePlaceholderParentType.FOREGROUND
        ),
        canvases
    };
}
