import { isNameEmpty, isNameWithInvalidChars, SPECIAL_CHAR_VALIDATION } from "../../../../../../../../../common/dynamicElementsUtils";
import { isReservedIdWord } from "../../../../../../Utils";
import type { SxProps } from "@mui/system";
import type { Theme } from "@mui/material";
import type { ConnectorType } from "@sundaysky/customer-data-common-goblin-ds";
import { isTrayConnector } from "@sundaysky/customer-data-common-goblin-ds";
import type { SourceElement } from "./DataLibraryDataSource/FieldMappingDialog";
import type { INestedMenuFullItemMap } from "./trayServiceUtils";
import { getNestedMenuInitialState } from "./trayServiceUtils";
import { SYSTEM_ELEMENT_ORIGIN } from "../../../../../../../../../common/editorUtils";
import type { MarketplaceConnectorType } from "./DataLibraryDataSource/DataSourceUtils";
import { PendoService } from "../../../../../../../pendoService";

export const HELPER_NAME = "Enter data field name";
export const UNIQUE_VALIDATION = "Enter a name that isn't already being used";
export const RESERVED_ID_FIELD = "“ID” may be in use by SundaySky, please choose another name";

export const SpinnerContainerSxProps: SxProps<Theme> = {
    width: "40px",
    height: "40px",
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%,-50%)"
};

export type ValidationResult = {
    isValid: boolean;
    helperText: string;
}


export type DataElementSource = ValidationResult & {
    name: string
};


export type EditNameState = ValidationResult & {
    name: string;
}

const DATA_LIBRARY_DESCRIPTION_MAX_LENGTH = 50;
export const VALID_RESULT = { isValid: true, helperText: "" };

export const validateDataLibraryDescription = (description: string): ValidationResult => {
    if (description.length > DATA_LIBRARY_DESCRIPTION_MAX_LENGTH) {
        return { isValid: false, helperText: `Must not exceed ${DATA_LIBRARY_DESCRIPTION_MAX_LENGTH} characters` };
    }
    return VALID_RESULT;
};

export const validateDataLibraryName = (libraryName: string, existingLibraryNamesLowerCase: string[]): ValidationResult => {
    if (isNameEmpty(libraryName)) {
        return { isValid: false, helperText: "Enter library name" };
    }
    if (existingLibraryNamesLowerCase.includes(libraryName.toLowerCase())) {
        return { isValid: false, helperText: "This name is already in use" };
    }
    return VALID_RESULT;
};

export const getDataElementTargetFromName = (name: string, usedDataElementNames: Set<string>): EditNameState => {
    if (isNameEmpty(name)) {
        return { name, isValid: false, helperText: HELPER_NAME };
    }
    else if (isNameWithInvalidChars(name)) {
        return { name, isValid: false, helperText: SPECIAL_CHAR_VALIDATION };
    }
    else if (isReservedIdWord(name)) {
        return { name, isValid: false, helperText: RESERVED_ID_FIELD };
    }
    else if (usedDataElementNames.has(name)) {
        return { name, isValid: false, helperText: UNIQUE_VALIDATION };
    }
    else {
        return { ...VALID_RESULT, name };
    }
};

export const getDataElementSourceFromName = (name: string, checkValidation: boolean = true): DataElementSource => {
    if (checkValidation && !isNameEmpty(name) && isNameWithInvalidChars(name)) {
        return { name, isValid: false, helperText: SPECIAL_CHAR_VALIDATION };
    }
    else {
        return { ...VALID_RESULT, name };
    }
};

export const shouldShowNestedMenu = (dataConnectorType: ConnectorType): boolean => {
    return isTrayConnector(dataConnectorType);
};

export const prepareMappingData = async (dataLibraryId: string, connectorType: ConnectorType, connectorAuthLastUpdated: string, sourceElements: SourceElement[]):
    Promise<INestedMenuFullItemMap> => {
    const hasSchema = shouldShowNestedMenu(connectorType);
    return (hasSchema && sourceElements?.length) ? await getNestedMenuInitialState(dataLibraryId, connectorType, connectorAuthLastUpdated, sourceElements) : {};
};

export const isSystemDataElement = (origin: string) => {
    return origin === SYSTEM_ELEMENT_ORIGIN;
};

export const trackConnectorChange = (from: MarketplaceConnectorType, to: MarketplaceConnectorType, dataLibraryId: string) => {
    const pendo = PendoService.getInstance();
    pendo.trackEvent("Connector Marketplace - Select-Switch-Remove Connector", {
        from: from || "unknown-connector",
        to: to || "unknown-connector",
        dataLibraryId
    });
};

export const trackGoogleSheetsConfigurationInvalidSheet = (dataLibraryId: string) => {
    const pendo = PendoService.getInstance();
    pendo.trackEvent("Google Sheets Configuration - sheet missing id header", { dataLibraryId });
};

export enum UsedInType {
    DATA_LIBRARY = "Data Libraries",
    VIDEOS = "Videos"
}
