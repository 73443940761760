import { getColorNameByCombination } from "../../../../common/colorCombinationUtils";
import { M2_Logo_Aspect_Ratio_Value } from "../../../../common/commonConst";
import { RendererGeneration, VideoAspectRatio, VideoQualityProfile } from "../../../../common/external/videoSpec";
import type { FontsManager } from "../../../../common/fontsUtils";
import {
    AVATAR_OBJECT_FIT,
    AVATAR_OBJECT_POSITION,
    getAvatarObjectViewBox,
    getDefaultColorSetting,
    getDefaultFontSizeSetting,
    getDefaultTextBackgroundColorSetting,
    getDefaultTextFitSetting,
    getDefaultTextHorizontalAlignmentSetting,
    getDefaultTextVerticalAlignmentSetting,
    getDurationSetting,
    isPlaceholderHidden,
    isPlaceholderOverrideLogo,
    MediaSettingsMapping,
    resolveBackgroundColorValue,
    resolveColorValue,
    resolveFontSizeValue,
    resolveTextFitValue,
    resolveTextHorizontalAlignmentValue,
    resolveTextVerticalAlignmentValue
} from "../../../../common/placeholderUtils";
import type {
    ButtonPlaceholderContent,
    ButtonShowContentTextItemV9,
    ButtonShowContentV9,
    DynamicRichTextAtom,
    DynamicRichTextAtoms,
    DynamicTextAtom,
    DynamicTextAtoms,
    NarrationPlaceholderContent,
    NarrationShow,
    StaticRichTextAtoms,
    StaticTextAtoms,
    TextPlaceholderContent
} from "../../../../common/types/editorPlaceholder";
import { CommonPlaceholderType, DurationType, FontSizeUnit, PlaceholderIntentName } from "../../../../common/types/editorPlaceholder";
import type { Filters, MinimumContentEnd, ObjectFit, ObjectPosition, PlayAt } from "../../../../common/types/vlxTypes";
import type { BrandConfigurationTextStyleLetterCase } from "../../../../common/vlxUtils";
import {
    brandConfigurationTextStyleToVideoSpecStyleProps,
    MinContentEndPlayToEndOfContentIcon,
    MinContentEndPlayToEndOfContentNonIcon,
    MinContentEndTruncateAtSceneEnd
} from "../../../../common/vlxUtils";
import type {
    GqlClientEditorAnimatedWireframeFragment,
    GqlClientEditorAssetLibraryAvatarFragment,
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorLibraryAvatarFragment,
    GqlClientEditorLibraryFontFragment,
    GqlClientEditorLibraryPlaceholderIntentFragment,
    GqlClientEditorPlaceholderFragment,
    GqlClientEditorSceneColorCombination,
    GqlClientEnumHorizontalAlignment,
    GqlClientStudioElementFragment
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEditorAssetSource, GqlClientEnumAnimationWireframeCompatibility } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ButtonShape, ColorValues, GqlMediaById, LivePreviewSceneData, LivePreviewScenePlaceholderData, LogoUrl, VideoSpec, VideoSpecScene } from "../types";
import { getTextStyleFont } from "./brandLibrary";
import {
    getButtonOrTextPlaceholderTextStyle,
    getLibraryAvatarFromPlaceholder,
    getMediaValue,
    getPlaceholderName,
    getPlaceholderType,
    getPlainTextPlaceholderValueDynamic,
    getPlainTextPlaceholderValueStatic,
    getRichTextPlaceholderValueDynamic,
    getRichTextPlaceholderValueStatic,
    isPlaceholderDynamic,
    isPlaceholderUsingRules
} from "./placeholder";
import type { LayerablePlaceholderNode } from "./parentedPlaceholders";
import { buildPlaceholderTree } from "./parentedPlaceholders";

const BUTTON_ALIGNMENT_INPUT_NAME = "buttonAlignment";
// source: https://sundaysky.atlassian.net/wiki/spaces/PROD/pages/1564966938/Property+Reference

export type VideoSpecArgument = VideoSpecTextArgument | VideoSpecMediaArgument | ButtonSpecMediaArgument;

export type PlainTextShowValue = { text: string }
export type RichTextShowValue = {
    "@type": "text",
    text: string,
    parts: Array<{
        text: string,
        "@styleProps": {
            _sskyFontUrl: string
        }
    }>
}
export type TextShowValue = PlainTextShowValue | RichTextShowValue;

type VideoSpecTextArgumentNoText = {
    "@type": "text";
    "@styleProps": {
        _sskyFitFontSize?: "none" | "shrink" | "enlarge" | "any";
        _sskyFontUrl?: string;
        fill?: string;
        textFit?: "resize-font" | "scroll-down" | "split-into-sections";
        textFitFreeze?: boolean;
        fontSize?: {
            value: number;
            unit: FontSizeUnit;
        };
        fontVariantCaps?: "normal" | "small-caps" | "all-small-caps";
        letterSpacing?: number;
        lineHeight?: number;
        textAlign?: "left" | "center" | "right";
        textTransform?: "none" | "uppercase" | "lowercase" | "capitalize" | "capitalize-first-letter";
        textWrap?: "nowrap" | "wrap";
        verticalAlign?: "top" | "middle" | "bottom";
        backgroundColor?: string;
        _sskyTextLinePadding?: [{
            value: number;
            unit: FontSizeUnit;
        }];
    };
};

export type VideoSpecTextArgument = TextShowValue & VideoSpecTextArgumentNoText;

type VideoSpecMediaArgument = {
    source: string;
    "@type": "media";
    "@styleProps": {
        objectFit?: ObjectFit;
        objectPosition?: ObjectPosition;
        filter?: Filters;
    };
    minContentEnd?: MinimumContentEnd;
    playAt?: PlayAt;
};

type ButtonSpecMediaArgument = {
    "@type": "button";
    action: { name: string };
    content: { [key: string]: VideoSpecArgument };
};

export type SceneData = Pick<LivePreviewSceneData, "name" | "placeholders" | "colorCombination" | "buttonAlignment"> & {
    layout?: GqlClientEditorAnimatedWireframeFragment;
};

export type Format = {
    format: "png" | "m3u8" | "mp4";
    frame?: {
        label: string; // "1" for the first frame, "2" for the second and so on
    };
};

export class VideoSpecBuilder {
    private sceneData: SceneData;
    private assetsByMediaId: GqlMediaById;
    private logoUrl: LogoUrl;
    private textStyles: GqlClientEditorBrandTextStyleFragment[];
    private intents: GqlClientEditorLibraryPlaceholderIntentFragment[];
    private colors: ColorValues;
    private format: Format;
    private studioElements: GqlClientStudioElementFragment[];
    private videoQualityProfile: VideoQualityProfile;
    private videoAspectRatio: VideoAspectRatio;
    private cache: any = undefined;
    private buttonShape: ButtonShape;
    private previewM2Mode = false;
    private backgroundMedia: GqlClientEditorAssetLibraryMediaFragment;
    private fontsManagers: { ccFontsManager: FontsManager<GqlClientEditorLibraryFontFragment>, accountFontsManager: FontsManager<GqlClientEditorAssetLibraryFontFragment> };
    private libraryAvatars: GqlClientEditorLibraryAvatarFragment[];
    private customAvatars: GqlClientEditorAssetLibraryAvatarFragment[];
    private useClientPlaceholderPreview: boolean;

    withLibraryAvatars(avatars: GqlClientEditorLibraryAvatarFragment[]): VideoSpecBuilder {
        this.libraryAvatars = avatars;
        return this;
    }

    withCustomAvatars(avatars: GqlClientEditorAssetLibraryAvatarFragment[]): VideoSpecBuilder {
        this.customAvatars = avatars;
        return this;
    }

    withAssets(assetsByMediaId: GqlMediaById): VideoSpecBuilder {
        this.assetsByMediaId = assetsByMediaId;
        return this;
    }

    withScene(sceneData: SceneData): VideoSpecBuilder {
        this.sceneData = sceneData;

        return this;
    }

    withColors(colors: ColorValues): VideoSpecBuilder {
        this.colors = colors;

        return this;
    }

    withLogoUrl(logoUrl: LogoUrl): VideoSpecBuilder {
        this.logoUrl = logoUrl;

        return this;
    }

    withIntents(intents: GqlClientEditorLibraryPlaceholderIntentFragment[]): VideoSpecBuilder {
        this.intents = intents;

        return this;
    }

    withTextStyles(textStyles: GqlClientEditorBrandTextStyleFragment[]): VideoSpecBuilder {
        this.textStyles = textStyles;

        return this;
    }

    withButtonShape(buttonShape: ButtonShape) {
        this.buttonShape = buttonShape;

        return this;
    }

    withFormat(format: Format): VideoSpecBuilder {
        this.format = format;

        return this;
    }

    withStudioElements(studioElements: GqlClientStudioElementFragment[]): VideoSpecBuilder {
        this.studioElements = studioElements;

        return this;
    }

    withOutput(videoQualityProfile: VideoQualityProfile, videoAspectRatio: VideoAspectRatio): VideoSpecBuilder {
        this.videoQualityProfile = videoQualityProfile;
        this.videoAspectRatio = videoAspectRatio;

        return this;
    }

    withCache(cache: any): VideoSpecBuilder {
        this.cache = cache;

        return this;
    }

    withPreviewM2Mode(previewM2Mode: boolean): VideoSpecBuilder {
        this.previewM2Mode = previewM2Mode;

        return this;
    }
    withBackgroundMedia(backgroundMedia: GqlClientEditorAssetLibraryMediaFragment): VideoSpecBuilder {
        this.backgroundMedia = backgroundMedia;

        return this;
    }
    
    withFontsManagers(ccFontsManager: FontsManager<GqlClientEditorLibraryFontFragment>, accountFontsManager: FontsManager<GqlClientEditorAssetLibraryFontFragment>): VideoSpecBuilder {
        this.fontsManagers = { ccFontsManager, accountFontsManager };

        return this;
    }

    withClientPlaceholderPreview(enabled: boolean): VideoSpecBuilder {
        this.useClientPlaceholderPreview = enabled;
        return this;
    }

    build(requestId: string, projectId: string): VideoSpec {
        let videoSpec = VideoSpecBuilder.videoSpecOutline(projectId, requestId);

        this.applyLineup(videoSpec);
        this.applyFormat(videoSpec);
        this.applyOutput(videoSpec);
        this.applyBackgrounds(videoSpec);

        if (this.cache) {
            videoSpec.cache = this.cache;
        }

        return videoSpec;
    }

    private applyLineup(videoSpec: VideoSpec): void {
        if (!this.sceneData) {
            return;
        }

        const { name, layout, colorCombination, buttonAlignment } = this.sceneData;
        const videoSpecScene = { data: {} } as VideoSpecScene;

        VideoSpecBuilder.applySceneName(videoSpecScene, `Scene_${name}`);
        let actualVsmlUrl = this.previewM2Mode && layout.m2AnimatedWireframe?.vsmlUrl
            ? layout.m2AnimatedWireframe?.vsmlUrl
            : layout?.vsmlUrl ?? "";

        VideoSpecBuilder.applySceneAnimationUrl(videoSpecScene, actualVsmlUrl);
        VideoSpecBuilder.applySceneFirstLast(videoSpecScene, false, false);
        VideoSpecBuilder.applyHasMediaBackground(videoSpecScene, this.backgroundMedia);
        VideoSpecBuilder.applySceneTransition(videoSpecScene);
        VideoSpecBuilder.applySceneColors(videoSpecScene, this.colors, colorCombination);
        VideoSpecBuilder.applySceneButtonAlignment(videoSpecScene, buttonAlignment, this.previewM2Mode ? layout?.m2AnimatedWireframe?.defaultButtonAlignment : layout?.defaultButtonAlignment);
        VideoSpecBuilder.applyButtonShape(videoSpecScene, this.buttonShape);

        this.applyScenePlaceholders(videoSpecScene, this.previewM2Mode || layout?.compatibility === GqlClientEnumAnimationWireframeCompatibility.M2);

        videoSpec.lineup.push(videoSpecScene);
    }

    private static applySceneName(videoSpecScene: VideoSpecScene, sceneName: string): void {
        videoSpecScene.name = sceneName.split(" ").join("_").toLowerCase();
    }

    private static applySceneAnimationUrl(videoSpecScene: VideoSpecScene, animationUrl: string): void {
        videoSpecScene.animation = { href: animationUrl };
    }

    private static applySceneFirstLast(videoSpecScene: VideoSpecScene, isFirst: boolean, isLast: boolean): void {
        videoSpecScene.data.isFirstScene = isFirst ? "true" : "false";
        videoSpecScene.data.isLastScene = isLast ? "true" : "false";
    }

    private static applyHasMediaBackground(videoSpecScene: VideoSpecScene, backgroundMedia: GqlClientEditorAssetLibraryMediaFragment): void {
        videoSpecScene.data.hasMediaBackground = backgroundMedia?.mediaUrl ? "true" : "false";
    }

    private static applySceneTransition(videoSpecScene: VideoSpecScene): void {
        // product: currently all themes use a single and constant transition
        videoSpecScene.transition = {
            duration: 0.6,
            style: "OVERLAY"
        };
    }

    private static applySceneColors(videoSpecScene: VideoSpecScene, colors: ColorValues, colorCombination: GqlClientEditorSceneColorCombination): void {
        if (!colors) {
            return;
        }

        for (const colorData of Object.values(colors)) {
            const { name: placeholderName, color: colorValue } = colorData;
            const mappedPlaceholderName = getColorNameByCombination(colorCombination, placeholderName);
            if (videoSpecScene.data[mappedPlaceholderName]) {
                // If the color already exists, just change Tint1
                // Not strictly required, but could be useful to support gradients
                videoSpecScene.data[mappedPlaceholderName].Tint1 = colorValue;
            }
            else {
                videoSpecScene.data[mappedPlaceholderName] = {
                    IsOneTint: true,
                    Ratio: 0.5,
                    Tint1: colorValue,
                    Tint2: "#FFFFFF"
                };
            }
        }
    }

    private static applySceneButtonAlignment(videoSpecScene: VideoSpecScene, buttonAlignment: GqlClientEnumHorizontalAlignment, defaultButtonAlignment: GqlClientEnumHorizontalAlignment): void {
        if (buttonAlignment || defaultButtonAlignment) {
            videoSpecScene.data[BUTTON_ALIGNMENT_INPUT_NAME] = buttonAlignment || defaultButtonAlignment;
        }
    }

    private applyScenePlaceholders(videoSpecScene: VideoSpecScene, isM2Layout: boolean) {
        const {
            rootPlaceholderNodes,
            foregroundPlaceholderNodes,
            canvases
        } = buildPlaceholderTree(this.sceneData);

        for (const layerablePlaceholderNode of rootPlaceholderNodes) {
            const placeholderName = getPlaceholderName(layerablePlaceholderNode.placeholder);
            videoSpecScene.data[placeholderName] = this.generateLayerablePlaceholderContent(layerablePlaceholderNode, isM2Layout);
        }

        for (const [canvasName, placeholderNodes] of canvases) {
            videoSpecScene.data[canvasName] = {
                "@type": "layered",
                layers: placeholderNodes.map(placeholderNode => ({
                    "@type": phTypeToVideoSpecType(getPlaceholderType(placeholderNode.placeholder)),
                    name: getPlaceholderName(placeholderNode.placeholder),
                    prototype: placeholderNode.placeholder.ccPlaceholder.name,
                    left: { value: placeholderNode.placeholder.flexibleSettings.left, unit: "%" },
                    top: { value: placeholderNode.placeholder.flexibleSettings.top, unit: "%" },
                    width: { value: placeholderNode.placeholder.flexibleSettings.width, unit: "%" },
                    height: { value: placeholderNode.placeholder.flexibleSettings.height, unit: "%" },
                    content: this.generateLayerablePlaceholderContent(placeholderNode, isM2Layout)
                }))
            };
        }

        if (foregroundPlaceholderNodes.length) {
            videoSpecScene.data.Foreground = {
                "@type": "layered",
                layers: foregroundPlaceholderNodes
                    .map(placeholderNode => ({
                        "@type": phTypeToVideoSpecType(getPlaceholderType(placeholderNode.placeholder)),
                        name: getPlaceholderName(placeholderNode.placeholder),
                        left: { value: placeholderNode.placeholder.flexibleSettings.left, unit: "%" },
                        top: { value: placeholderNode.placeholder.flexibleSettings.top, unit: "%" },
                        width: { value: placeholderNode.placeholder.flexibleSettings.width, unit: "%" },
                        height: { value: placeholderNode.placeholder.flexibleSettings.height, unit: "%" },
                        content: this.generateLayerablePlaceholderContent(placeholderNode, isM2Layout)
                    }))
                    .sort((a, b) => layerTypeSortOrder[a["@type"]] - layerTypeSortOrder[b["@type"]])
            };
        }
    }

    private generateLayerablePlaceholderContent(layerablePlaceholderNode: LayerablePlaceholderNode, isM2Layout: boolean) {
        if (layerablePlaceholderNode.childPlaceholders.length) {
            const rootContent = this.generatePlacholderContent(layerablePlaceholderNode.placeholder, isM2Layout);
            const layers = layerablePlaceholderNode.childPlaceholders
                .map(placeholderNode => ({
                    "@type": phTypeToVideoSpecType(getPlaceholderType(placeholderNode.placeholder)),
                    name: getPlaceholderName(placeholderNode.placeholder),
                    left: { value: placeholderNode.placeholder.flexibleSettings.left, unit: "%" },
                    top: { value: placeholderNode.placeholder.flexibleSettings.top, unit: "%" },
                    width: { value: placeholderNode.placeholder.flexibleSettings.width, unit: "%" },
                    height: { value: placeholderNode.placeholder.flexibleSettings.height, unit: "%" },
                    content: this.generateLayerablePlaceholderContent(placeholderNode, isM2Layout)
                }))
                .sort((a, b) => layerTypeSortOrder[a["@type"]] - layerTypeSortOrder[b["@type"]]);

            return {
                "@type": "layered",
                rootContent,
                layers
            };
        }
        else {
            return this.generatePlacholderContent(layerablePlaceholderNode.placeholder, isM2Layout);
        }
    }

    private generatePlacholderContent(placeholder: LivePreviewScenePlaceholderData, isM2Layout: boolean): any {
        const placeholderName = getPlaceholderName(placeholder);
        const placeholderType = getPlaceholderType(placeholder);

        const isHidden = isPlaceholderHidden(placeholder.placeholderSettings);
        if (!isHidden) {
            const placeholderValue: string | RichTextShowValue = this.calcPlaceholderValue(placeholder, placeholderType);
            return this.buildVideoSpecArgument(placeholder, placeholderType, placeholderValue, placeholderName, isM2Layout);
        }
        else {
            return this.getHiddenVideoSpecArgument(placeholderType, placeholderName);
        }
    }

    // Some animations expect a placeholder to be present in the lineup, even if it's hidden
    private getHiddenVideoSpecArgument(placeholderType: CommonPlaceholderType, placeholderName: string) {
        if (placeholderType === CommonPlaceholderType.BUTTON) {
            return {
                "@type": "button",
                "action": { "name": placeholderName },
                "content": {
                    "text": {
                        "text": "",
                        "@type": "text"
                    }
                }
            };
        }
        else if (placeholderType === CommonPlaceholderType.TEXT) {
            return {
                "text": "",
                "@type": "text"
            };
        }
        else if (placeholderType === CommonPlaceholderType.MEDIA) {
            return {
                "source": "",
                "@type": "media"
            };
        }
    }

    private getFontsManager(textStyle: GqlClientEditorBrandTextStyleFragment): FontsManager<GqlClientEditorLibraryFontFragment | GqlClientEditorAssetLibraryFontFragment> {
        return textStyle.fontSource === GqlClientEditorAssetSource.ACCOUNT ? this.fontsManagers?.accountFontsManager : this.fontsManagers?.ccFontsManager;
    }

    private getFontIdAndFontsManager(placeholder: Pick<LivePreviewScenePlaceholderData, "id" | "placeholderContent" | "ccPlaceholder" | "placeholderSettings">) {
        const textStyle: GqlClientEditorBrandTextStyleFragment = getButtonOrTextPlaceholderTextStyle(placeholder, this.textStyles, this.intents);
        if (!textStyle) {
            return { fontId: undefined, fontsManager: undefined };
        }
        const fontsManager = this.getFontsManager(textStyle);
        const font: GqlClientEditorLibraryFontFragment | GqlClientEditorAssetLibraryFontFragment = getTextStyleFont(textStyle);
        return { fontId: font.id, fontsManager };
    }

    private buildVideoSpecArgument(
        placeholder: LivePreviewScenePlaceholderData,
        placeholderType: CommonPlaceholderType,
        placeholderValue: string | RichTextShowValue,
        placeholderName?: string,
        isM2Layout?: boolean
    ): VideoSpecArgument {

        switch (placeholderType) {
            case CommonPlaceholderType.TEXT: {
                const textStyle = getButtonOrTextPlaceholderTextStyle(placeholder, this.textStyles, this.intents);
                return this.buildTextArgument(placeholder, placeholderValue, textStyle);
            }
            case CommonPlaceholderType.MEDIA: {
                return this.buildMediaArgument(placeholder, placeholderValue as string, isM2Layout);
            }
            case CommonPlaceholderType.BUTTON: {
                return this.buildButtonArgument(placeholder, placeholderName, this.studioElements);
            }
            case CommonPlaceholderType.AVATAR: {
                return this.buildAvatarArgument(placeholder, placeholderValue as string);
            }
        }
    }

    private buildButtonArgument(
        placeholder: Pick<LivePreviewScenePlaceholderData, "id" | "placeholderContent" | "ccPlaceholder" | "placeholderSettings">,
        placeholderName: string,
        studioElements: GqlClientStudioElementFragment[]
    ): ButtonSpecMediaArgument {
        const placeholderContent: ButtonPlaceholderContent = placeholder.placeholderContent;

        const isUsingRules = isPlaceholderUsingRules(placeholder);
        const showContent: ButtonShowContentV9<DynamicTextAtom[], DynamicRichTextAtom[]> = isUsingRules
            ? placeholderContent.staticFallback.actionShow[placeholderContent.staticFallback.useAction]
            : placeholderContent.noRules.show.actionShow[placeholderContent.noRules.show.useAction];

        let contentButton: ButtonSpecMediaArgument["content"] = {};
        const contentItem: ButtonShowContentTextItemV9<DynamicRichTextAtom[]> = showContent.text;
        if (contentItem) {
            const { fontId, fontsManager } = this.getFontIdAndFontsManager(placeholder);
            const staticContent: string | RichTextShowValue = getRichTextPlaceholderValueDynamic(contentItem.content, studioElements, fontId, fontsManager);
            const textStyle = getButtonOrTextPlaceholderTextStyle(placeholder, this.textStyles, this.intents);
            const innerArgument = this.buildTextArgument(placeholder, staticContent, textStyle, { _sskyTextLinePadding: [{ value: 0, unit: FontSizeUnit.PX }] });
            contentButton = { ...contentButton, ["text"]: innerArgument };
        }

        return {
            "@type": "button",
            "action": { "name": placeholderName }, // "url" redundant in live preview
            "content": contentButton
        };
    }

    private buildMediaArgument(placeholder: Pick<GqlClientEditorPlaceholderFragment, "placeholderSettings" | "ccPlaceholder">, placeholderValue: string, isM2Layout?: boolean): VideoSpecMediaArgument {
        const styleProps: VideoSpecMediaArgument["@styleProps"] = {};
        for (let setting in MediaSettingsMapping) {
            const value = MediaSettingsMapping[setting];
            const settingCalculatedValue = value.resolveFn(placeholder.placeholderSettings);
            settingCalculatedValue && Object.assign(styleProps, { [value.elementName]: settingCalculatedValue });
        }

        const colorSetting = getDefaultColorSetting(placeholder.placeholderSettings);
        const floodColor = resolveColorValue(colorSetting, (localId) => this.colors?.[localId]?.color);
        if (floodColor) {
            // Note: the order of filters matters
            const filter: Filters = [{ name: "--ssky-flood", floodColor }];
            Object.assign(styleProps, { filter });
        }

        if (isM2Layout && placeholder?.ccPlaceholder?.intent?.name === PlaceholderIntentName.Logo && !isPlaceholderOverrideLogo(placeholder.placeholderSettings)) {
            Object.assign(styleProps, M2_Logo_Aspect_Ratio_Value);
        }

        let minContentEnd: MinimumContentEnd = MinContentEndTruncateAtSceneEnd;
        const durationSetting = getDurationSetting(placeholder.placeholderSettings);
        if (durationSetting?.useValue) {
            if (durationSetting.value.duration === DurationType.PlayToEndOfContent) {
                minContentEnd = placeholder?.ccPlaceholder?.intent?.name === PlaceholderIntentName.Icon ? MinContentEndPlayToEndOfContentIcon : MinContentEndPlayToEndOfContentNonIcon;
            }
            else if (durationSetting.value.duration === DurationType.LoopContent) {
                Object.assign(styleProps, {
                    loop: true,
                    _sskyVolume: { value: 0, unit: "%" }
                });
            }
        }

        return {
            source: placeholderValue,
            "@type": "media",
            "@styleProps": styleProps,
            minContentEnd
        };
    }

    private buildAvatarArgument(placeholder: LivePreviewScenePlaceholderData, placeholderValue: string): VideoSpecMediaArgument {
        const styleProps: VideoSpecMediaArgument["@styleProps"] = {};
        Object.assign(styleProps, {
            objectFit: AVATAR_OBJECT_FIT,
            objectPosition: AVATAR_OBJECT_POSITION
        });

        const avatar = getLibraryAvatarFromPlaceholder(placeholder, this.libraryAvatars, this.customAvatars);
        if (avatar) {
            const objectViewBox = getAvatarObjectViewBox(placeholder, avatar);
            if (objectViewBox) {
                Object.assign(styleProps, { objectViewBox });
            }
        }

        return {
            source: this.useClientPlaceholderPreview ? "" : placeholderValue,
            "@type": "media",
            "@styleProps": styleProps
        };
    }

    private buildTextArgument(
        placeholder: Pick<GqlClientEditorPlaceholderFragment, "placeholderSettings">,
        placeholderValue: string | RichTextShowValue,
        textStyle: GqlClientEditorBrandTextStyleFragment,
        initialTextStyles: Partial<VideoSpecTextArgument["@styleProps"]> = {}
    ): VideoSpecTextArgument {

        const styleProps: VideoSpecTextArgument["@styleProps"] = initialTextStyles;
        if (textStyle) {
            const textStyleForSpec = {
                letterCase: textStyle.letterCase as unknown as BrandConfigurationTextStyleLetterCase,
                lineSpacing: textStyle.lineSpacing,
                letterSpacing: textStyle.letterSpacing,
                fontUrl: getTextStyleFont(textStyle).url
            };
            Object.assign(styleProps, brandConfigurationTextStyleToVideoSpecStyleProps(textStyleForSpec));
        }

        const fontSizeSetting = getDefaultFontSizeSetting(placeholder.placeholderSettings);
        const fontSize = resolveFontSizeValue(fontSizeSetting);
        if (fontSize) {
            styleProps.fontSize = fontSize;
        }

        const textFitSetting = getDefaultTextFitSetting(placeholder.placeholderSettings);
        const textFit = resolveTextFitValue(textFitSetting);
        if (textFit) {
            styleProps.textFit = textFit;
            styleProps.textFitFreeze = true;
        }

        const textHorizontalAlignmentSetting = getDefaultTextHorizontalAlignmentSetting(placeholder.placeholderSettings);
        const textHorizontalAlignment = resolveTextHorizontalAlignmentValue(textHorizontalAlignmentSetting);
        if (textHorizontalAlignment) {
            styleProps.textAlign = textHorizontalAlignment;
        }

        const textVerticalAlignmentSetting = getDefaultTextVerticalAlignmentSetting(placeholder.placeholderSettings);
        const textVerticalAlignment = resolveTextVerticalAlignmentValue(textVerticalAlignmentSetting);
        if (textVerticalAlignment) {
            styleProps.verticalAlign = textVerticalAlignment;
        }

        const colorSetting = getDefaultColorSetting(placeholder.placeholderSettings);
        const color = resolveColorValue(colorSetting, (localId) => this.colors?.[localId]?.color);
        if (color) {
            styleProps.fill = color;
        }

        const textBackgroundColorSetting = getDefaultTextBackgroundColorSetting(placeholder.placeholderSettings);
        const textBackgroundColor = resolveBackgroundColorValue(textBackgroundColorSetting, (localId) => this.colors?.[localId]?.color);
        if (textBackgroundColor) {
            styleProps.backgroundColor = textBackgroundColor;
        }

        const textPart: TextShowValue = typeof placeholderValue === "string" ? { text: placeholderValue } : placeholderValue;
        return {
            ...textPart,
            "@type": "text",
            "@styleProps": styleProps
        };
    }

    private applyFormat(videoSpec: VideoSpec): void {
        if (this.format) {
            videoSpec.output.formats = {
                output: this.format
            };
        }
    }

    private applyOutput(videoSpec: VideoSpec): void {
        if (this.videoQualityProfile) {
            videoSpec.output.videoQualityProfile = this.videoQualityProfile;
        }

        if (this.videoAspectRatio) {
            videoSpec.output.videoAspectRatio = this.videoAspectRatio;
        }
    }
    
    private applyBackgrounds(videoSpec: VideoSpec): void {
        const backgrounds = [];
        if (this.backgroundMedia?.mediaUrl) {
            backgrounds.push({
                content: {
                    source: this.backgroundMedia.mediaUrl,
                    "@type": "media",
                    "@styleProps": {
                        objectFit: "cover",
                        loop: true,
                        "_sskyVolume": { "value": 0, "unit": "%" }
                    }
                }
            });
        }
        
        videoSpec.backgrounds = backgrounds;
    }

    private calcPlaceholderValue(placeholder: LivePreviewScenePlaceholderData, placeholderType: CommonPlaceholderType): string | RichTextShowValue {
        const isUsingRules = isPlaceholderUsingRules(placeholder);
        const isDynamic = isPlaceholderDynamic(placeholder);

        if (placeholderType === CommonPlaceholderType.BUTTON) {
            return "";
        }

        if (placeholderType === CommonPlaceholderType.AVATAR) {
            const avatar = getLibraryAvatarFromPlaceholder(placeholder, this.libraryAvatars, this.customAvatars);
            return avatar?.thumbnailUrl;
        }

        // in live preview we can only show media we have. For static asses we show the static asset,
        // for all other cases (from studio elements or from logic) we show the fallback media
        // https://sundaysky.atlassian.net/browse/SD-2280
        if (placeholderType === CommonPlaceholderType.MEDIA) {
            return isDynamic ?
                getMediaValue(placeholder, placeholder.placeholderContent.staticFallback, this.assetsByMediaId, this.logoUrl)
                : getMediaValue(placeholder, placeholder.placeholderContent.noRules.show.static, this.assetsByMediaId, this.logoUrl);
        }

        const placeholderContent: TextPlaceholderContent | NarrationPlaceholderContent = placeholder.placeholderContent;
        if (placeholderType === CommonPlaceholderType.TEXT) {
            const { fontId, fontsManager } = this.getFontIdAndFontsManager(placeholder);

            return isUsingRules ?
                getRichTextPlaceholderValueStatic(placeholderContent.staticFallback as StaticRichTextAtoms, fontId, fontsManager)
                : getRichTextPlaceholderValueDynamic(placeholderContent.noRules.show as DynamicRichTextAtoms, this.studioElements, fontId, fontsManager);
        }
        else {
            return isUsingRules
                ? getPlainTextPlaceholderValueStatic((placeholderContent.staticFallback as NarrationShow<StaticTextAtoms>).text)
                : getPlainTextPlaceholderValueDynamic((placeholderContent.noRules.show as NarrationShow<DynamicTextAtoms>).text, this.studioElements);
        }
    }

    private static videoSpecOutline(projectid: string, requestid: string): VideoSpec {


        return {
            projectid,
            requestid,

            lineup: [],

            output: {
                renderer: {
                    rendererGeneration: RendererGeneration.V2019
                },

                videoQualityProfile: VideoQualityProfile.SD,
                videoAspectRatio: VideoAspectRatio.FULL_LANDSCAPE
            },

            settings: {
                "status.extended": true,
                streaming: true,
                requestedEmitterCategories: "placeholders,interaction"
            }
        };
    }

    private static applyButtonShape(videoSpecScene: VideoSpecScene, buttonShape: ButtonShape) {
        videoSpecScene.data.buttonShape = buttonShape;
    }
}

const layerTypeSortOrder = {
    "media placeholder": 0,
    "text placeholder": 1
};

function phTypeToVideoSpecType(phType: CommonPlaceholderType): string {
    switch (phType) {
        case CommonPlaceholderType.TEXT:
            return "text placeholder";
        case CommonPlaceholderType.MEDIA:
        case CommonPlaceholderType.AVATAR:
            return "media placeholder";
        default:
            throw new Error(`Unsupported placeholder type: ${phType}`);
    }
}
