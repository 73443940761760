import React from "react";
import PropTypes from "prop-types";

import { Dropdown } from "semantic-ui-react";
import HubContentEditable from "../../../legacyCommon/HubContentEditable";

import "./ModifyDataElementPanel.css";
import { LOGIC_MEDIA_TYPES, VLX_FUNCTION_TAGS, VLX_PARAMTYPE } from "../../../../logic/vlx/consts";
import {
    capitalizeFirstLetter,
    getAvailableFunctions,
    getElementInfoFromActionableData
} from "../../../../logic/vlx/editorLogicUtils";
import HubDataElementPicker from "../../../legacyCommon/HubDataElementPicker";
import HubDatePicker from "../../../legacyCommon/HubDatePicker";
import moment from "moment";
import SelectedItemSection from "./SelectedItemSection";
import { DataElementContentTypes } from "../../../../../common/types/dataElement";

const numeral = require("numeral");

const dateTypeComparableFunctions = new Set(["datediff"]);
const numberTypeComparableFunctions = new Set(["add", "subtract", "multiply", "absdiff"]);
const stringTypeComparableFunctions = new Set(["replace"]);

const isDataElementComparable = (act) => (
    act &&
    (
        dateTypeComparableFunctions.has(act.name) ||
        numberTypeComparableFunctions.has(act.name) ||
        stringTypeComparableFunctions.has(act.name)
    )
);


const ModifyDataElementPanel = (props) => {
    let actions = getAvailableFunctions();

    let getDropDownActions = (mediaType) => {
        // Work around 'number' being 'numeric' in VLX; Work around 'datetime' being 'date' in VLX
        if (mediaType === LOGIC_MEDIA_TYPES.Number) {
            mediaType = VLX_PARAMTYPE.Numeric;
        }
        else if (mediaType === LOGIC_MEDIA_TYPES.Date) {
            mediaType = VLX_PARAMTYPE.Date;
        }

        let formatters = actions
            .filter(
                (act) =>
                    act.tag.some((tag) => tag === VLX_FUNCTION_TAGS.Formatter) &&
                    // Make sure we work on the right input
                    act.input.some((inTypes) => inTypes === mediaType)
            )
            .map((act) => ({
                key: act.name,
                text: capitalizeFirstLetter(act.text),
                value: act.name
            }));

        let functions = actions
            .filter(
                (act) =>
                    act.tag.some((tag) => tag === VLX_FUNCTION_TAGS.Function) &&
                    // Make sure we work on the right input
                    act.input.some((inTypes) => inTypes === mediaType)
            )
            .map((act) => ({
                key: act.name,
                text: capitalizeFirstLetter(act.text),
                value: act.name
            }));

        if (formatters && formatters.length) {
            formatters = [{ key: "format", text: "Format", className: "header", type: "header" }, ...formatters];
        }

        if (functions && functions.length) {
            functions = [{ key: "function", text: "Function", className: "header", type: "header" }, ...functions];
        }

        return formatters.concat(functions);
    };

    let onActionSelected = (index, data) => {
        if (!props.selectedValue.actions[index] || props.selectedValue.actions[index].name !== data.value) {
            props.onActionSelected(index, data);
        }
    };

    const selectedValueObj = getElementInfoFromActionableData(props.selectedValue, props.dataFields) || {};
    const selectedValue = selectedValueObj.displayName;
    const mediaType = selectedValueObj.mediaType;
    let initialAvailableActions = getDropDownActions(mediaType);

    let showFirstAddActionsLink = (!props.selectedValue.actions || !props.selectedValue.actions.length) && initialAvailableActions && initialAvailableActions.length > 0;

    let validateMomentFormatting = (format) => {
        return {
            result: true,
            message: moment().format(format)
        };
    };

    let validateNumberFormatting = (format) => {
        return {
            result: true,
            message: numeral("123456.78").format(format)
        };
    };

    let parameterTypeToDataElementCompareType = (parameter) => {
        switch (parameter.type) {
            case VLX_PARAMTYPE.Date:
                return DataElementContentTypes.Date;
            case VLX_PARAMTYPE.Numeric:
                return DataElementContentTypes.Number;
            case VLX_PARAMTYPE.String:
                return DataElementContentTypes.String;
            case "dataElement":
                return parameter.mediaType;
            default:
                return null;
        }
    };

    const getDefaultParam = (actionName, paramIdx) => {
        const action = actions.find(({ name }) => name === actionName);
        const param = action.params[paramIdx];
        return param.text;
    };

    const renderActionParams = (actionIdx, parameter, paramIdx, arr, isDataElementComparableFunction, actionName) => (
        <span key={paramIdx} className={"action-parameter " + parameter.type}>
            {((parameter.type === VLX_PARAMTYPE.Numeric && !isDataElementComparableFunction) || parameter.type === VLX_PARAMTYPE.String) && (
                <HubContentEditable
                    value={parameter.value ? parameter.value : ""}
                    placeholder={capitalizeFirstLetter(parameter.text)}
                    onConfirm={(value) => props.onActionParameterChanged(actionIdx, paramIdx, value)}
                />
            )}
            {parameter.type === VLX_PARAMTYPE.DateTimeFormat && (
                <HubContentEditable
                    value={parameter.value ? parameter.value : ""}
                    placeholder={capitalizeFirstLetter(parameter.text)}
                    onConfirm={(value) => props.onActionParameterChanged(actionIdx, paramIdx, value)}
                    validateFunc={validateMomentFormatting}
                    isFocused={!parameter.value}
                />
            )}
            {parameter.type === VLX_PARAMTYPE.NumberFormat && (
                <HubContentEditable
                    value={parameter.value ? parameter.value : ""}
                    placeholder={capitalizeFirstLetter(parameter.text)}
                    onConfirm={(value) => props.onActionParameterChanged(actionIdx, paramIdx, value)}
                    validateFunc={validateNumberFormatting}
                    isFocused={!parameter.value}
                />
            )}
            {parameter.type === VLX_PARAMTYPE.Choice && (
                <Dropdown
                    className={"param-choice"}
                    options={parameter.choices.map((c) => ({ key: c, text: c, value: c }))}
                    onChange={(event, data) => props.onActionParameterChanged(actionIdx, paramIdx, data.value)}
                    value={parameter && parameter.value ? parameter.value : null}
                    placeholder="Choose"
                    id={`params-choices-${paramIdx}`}
                    floating
                />
            )}
            {parameter.type === VLX_PARAMTYPE.Boolean && (
                <Dropdown
                    className={"param-choice"}
                    options={[
                        { key: "False", text: parameter.choices[0], value: "false" },
                        { key: "True", text: parameter.choices[1], value: "true" }
                    ]}
                    onChange={(event, data) => {
                        return props.onActionParameterChanged(actionIdx, paramIdx, data.value === "true");
                    }}
                    value={(parameter && parameter.value ? parameter.value : !!parameter.defaultValue).toString()}
                    placeholder={capitalizeFirstLetter(parameter.text)}
                    id={`params-choices-${paramIdx}`}
                    floating
                />
            )}
            {parameter.type === VLX_PARAMTYPE.Date && !isDataElementComparableFunction && (
                <div className="param-date">
                    <HubDatePicker
                        id="operandContentEditable"
                        inline={true}
                        readOnly={false}
                        handleChangeStart={(date) => props.onActionParameterChanged(actionIdx, paramIdx, date)}
                        startDate={parameter && parameter.value && moment(parameter.value)}
                        shouldCloseOnSelect={true}
                    />
                </div>
            )}
            {(parameter.type === VLX_PARAMTYPE.Date || parameter.type === VLX_PARAMTYPE.Numeric || parameter.type === "dataElement" || parameter.type === VLX_PARAMTYPE.String) &&
            isDataElementComparableFunction && (
                <div className="param-data-element">
                    <HubDataElementPicker
                        handleChange={(value) => props.onActionParameterChanged(actionIdx, paramIdx, value)}
                        value={(parameter.type === VLX_PARAMTYPE.Date && parameter?.value) ? moment(parameter?.value) : parameter?.value}
                        dataElementName={parameter?.displayName}
                        onDataElementSelectionClick={props.onDataElementComparison}
                        dataElementCompareType={parameterTypeToDataElementCompareType(parameter)}
                        pickedActionIndex={actionIdx && actionIdx}
                        pickedParamIndex={paramIdx}
                        paramName={parameter.text || getDefaultParam(actionName, paramIdx)}
                    />
                </div>
            )}
        </span>
    );

    let renderActions = (act, idx, array) => {
        // gets dropdown actions by the previous selected output type
        let options = !idx || !array[idx - 1] ? initialAvailableActions : getDropDownActions(array[idx - 1].output);
        const isDataElementComparableFunction = isDataElementComparable(act);
        return (
            <ul key={idx}>
                <li className="action-title">Modify data element</li>
                <li className="actions-list-item" data-testid={"actions-select-list-item"}>
                    <Dropdown
                        options={options}
                        onChange={(event, data) => onActionSelected(idx, data)}
                        value={act && act.name ? act.name : null}
                        placeholder="Choose"
                        text={act ? capitalizeFirstLetter(act.text) : undefined}
                        id={`actions-dropdown-${idx}`}
                        floating
                    />
                    <button id={"remove-action-" + idx} className="remove-action" onClick={() => props.onDeleteAction(idx)} data-testid={"remove-action-" + idx} />
                </li>
                {act && act.params && act.params.length > 0 && (
                    <li className="actions-list-item params">
                        {act.params
                            .filter((param) => param.type !== VLX_PARAMTYPE.TimeZone)
                            .map((parameter, paramIdx, arr) => renderActionParams(idx, parameter, paramIdx, arr, isDataElementComparableFunction, act.name))}
                    </li>
                )}
            </ul>
        );
    };

    return (
        <SelectedItemSection selectedValue={props.selectedValue} dataFields={props.dataFields} onSelectedItemRemoveClicked={props.onRemoveSelectedItem}>
            {showFirstAddActionsLink && selectedValue !== "\n" && !props.isDataElementCompareMode && (
                <button parent-class="action-lint" id="add-first-action-button" className="add-actions" onClick={props.onAddActionClick} data-testid="modify-data-element-button">
                    Modify data element
                </button>
            )}
            {props.selectedValue.actions && props.selectedValue.actions.map(renderActions)}
            {props.selectedValue.actions && props.selectedValue.actions.length > 0 && (
                <div parent-class="action-lint" className="action-link" onClick={props.onAddActionClick}>
                    <span className="add-icon with-hover">&nbsp;</span>
                    <span className="add-icon-text" data-testid="add-another-modification-button">
                        Add another modification
                    </span>
                </div>
            )}
        </SelectedItemSection>
    );
};

// TODO: move actions prop types to a module that would be imported
ModifyDataElementPanel.propTypes = {
    onRemoveSelectedItem: PropTypes.func,
    selectedValue: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
        mediaType: PropTypes.string,
        type: PropTypes.string,
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                params: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.string,
                        text: PropTypes.string,
                        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool])
                    })
                ),
                text: PropTypes.string,
                input: PropTypes.arrayOf(PropTypes.string),
                output: PropTypes.string
            })
        )
    }),
    onActionSelected: PropTypes.func,
    onDeleteAction: PropTypes.func,
    onActionParameterChanged: PropTypes.func,
    onAddActionClick: PropTypes.func,
    dataFields: PropTypes.shape({
        assets: PropTypes.array.isRequired,
        dataElements: PropTypes.array.isRequired
    }),
    onDataElementComparison: PropTypes.func,
    isDataElementCompareMode: PropTypes.bool
};

export default ModifyDataElementPanel;
