import type { GqlClientEditorAssetLibraryMediaFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramCustomBackgroundDataQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseEditorProgramCustomBackgroundData = {
    useCustomBackground: boolean;
    backgroundMedia: GqlClientEditorAssetLibraryMediaFragment;
};

export const useEditorProgramCustomBackgroundData = (): UseEditorProgramCustomBackgroundData => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorProgramCustomBackgroundDataQuery({ variables: { programId, programVersionId } });

    const useCustomBackground = data?.editorProgram?.programVersion?.useCustomVideoBackground;
    const backgroundMedia = data?.editorProgram?.programVersion?.videoBackgroundMedia;

    return { useCustomBackground, backgroundMedia };
};
