import type { LogoAssetInfo, ProgramId, ProgramVersionId } from "../../types";
import { localEditedBrandIdVar, localEditedBrandLogoAssetInfoVar } from "../../State";
import { getBrand, getSelectedBrandId } from "./brand";


export const setEditedBrandLogoAssetInfo = (logoAssetInfo: LogoAssetInfo): void => {
    if (localEditedBrandIdVar()) {
        localEditedBrandLogoAssetInfoVar(logoAssetInfo);
    }
};

export const getEditedBrandLogoAssetInfo = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
): LogoAssetInfo => {
    const editorBrand = getBrand(getSelectedBrandId(programId, programVersionId));
    const localLogoAssetInfo = localEditedBrandLogoAssetInfoVar();

    return {
        logoLocation: localLogoAssetInfo.logoLocation ?? editorBrand.logoLocation,
        logoMediaId: localLogoAssetInfo.logoMediaId ?? editorBrand.logoMedia?.id
    };
};

export const resetLogoLocalVar = () => {
    localEditedBrandLogoAssetInfoVar({ logoMediaId: null, logoLocation: null });
};
