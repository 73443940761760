import { useReactiveVar } from "@apollo/client";
import { localEditorSelectedLibraryVar } from "../../State";
import type { Libraries, LibraryOpener } from "../../types";

export type UseSelectedLibrary = {
    selectedLibrary: Libraries;
    selectedLibraryOpener: LibraryOpener;
    openLibrary: (library: Libraries, opener: LibraryOpener) => void;
    closeSelectedLibrary: () => void;
};

export const useSelectedLibrary = (): UseSelectedLibrary => {
    const { library: selectedLibrary, opener: selectedLibraryOpener } = useReactiveVar(localEditorSelectedLibraryVar);

    const openLibrary = (library: Libraries, opener: LibraryOpener): void => {
        localEditorSelectedLibraryVar({ library, opener });
    };

    const closeSelectedLibrary = () => {
        localEditorSelectedLibraryVar({ library: null, opener: null });
    };

    return { selectedLibrary, selectedLibraryOpener, openLibrary, closeSelectedLibrary };
};
