import { useEffect } from "react";
import { useEditorProgramForEmbedVideoBuilderQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { initLocalStateForEmbedVideoPageBuilder } from "../../State";

export const useEditorProgramForEmbedVideoBuilder = (programId: string) => {
    const { loading, error, data } = useEditorProgramForEmbedVideoBuilderQuery({
        variables: { programId },
        // fetchPolicy "network-only" doesn't check cache before making a network request.
        // We use "network-only" in this case in order to present the most updated data from the database in the landing page builder.
        // For example, if a user logs in to the overview page, and in the meantime some other user changes the brand configuration of the same video -
        // we would like to get the last brand configuration that is saved to the database, and not the one we have in the cache.
        fetchPolicy: process.env.JEST_WORKER_ID === undefined ? "network-only" : undefined
    });
    //If the data is loaded, initialize the local state for the landing page builder.
    //we now call this init utility only when data is loaded for the first time
    //and when moving between different programs (rather than on any change to editor program version that is part of the query).
    //This fixes the bug of the local configuration being overwritten when the program version is being updated.
    //This prevents concurrent editing (anyway it is not fully properly supported by subscription).
    //This should be a short term solution until we change the way we represent the local configuration editing state and update it according to changes to the program version.

    useEffect(() => {
        if (!loading && data?.editorProgram) {
            initLocalStateForEmbedVideoPageBuilder({ editorProgram: data.editorProgram });
        }
    }, [programId, loading]);

    return { loading, error };
};
