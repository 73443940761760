import type { GqlClientEnumAspectRatio } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramAspectRatioQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseProgramVersionAspectRatio = {
    loading: boolean;
    aspectRatio: GqlClientEnumAspectRatio;
};

export const useProgramVersionAspectRatio = (): UseProgramVersionAspectRatio => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { loading, data } = useEditorProgramAspectRatioQuery({ variables: { programId, programVersionId } });
    const aspectRatio = data?.editorProgram?.programVersion?.aspectRatio;
    return { aspectRatio, loading };
};
