import { useCallback, useEffect, useReducer, useRef } from "react";
import type { ButtonShape, ColorValues, GqlMediaById, LivePreviewSceneData, LivePreviewScenePlaceholderData, LogoUrl, PlaceholderId, RaasPlaceholderPosition, UploadedGettyUrl } from "../../types";
import { UploadedGettyUrlTarget } from "../../types";
import { handleEditorError, reportPending, setRaasPlaceholderData } from "../../Nooks";
import type { UpdateFlexiblePlaceholderPosition } from "../../Utils";
import { buildVideoSpecForImage, getPlaceholderName, isPlaceholderDynamic, isPlaceholderLogo, renderDigest } from "../../Utils";
import { v4 as uuid } from "uuid";
import type { RaasPlaceholder } from "./loader";
import { generateImageAndPlaceholders } from "./loader";
import type { LivePreviewState, RenderedPlaceholder } from "./reducer";
import { LivePreviewActionType, livePreviewInitialState, livePreviewReducer } from "./reducer";
import type {
    GqlClientEditorAnimatedWireframeFragment,
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorLibraryFontFragment,
    GqlClientEditorLibraryPlaceholderIntentFragment,
    GqlClientEnumAspectRatio,
    GqlClientStudioElementFragment
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientAssetLifecycleStage,
    GqlClientExternalLibrary,
    GqlClientMediaClassification,
    GqlClientMediaOrigin
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { SskyErrorCode } from "../../../../../common/errors";
import type { VideoAspectRatio, VideoQualityProfile } from "../../../../../common/external/videoSpec";
import { LivePreviewClient } from "./livePreviewRequestAnalytics";
import { useEditorIsPreviewM2 } from "../UseUpgradeLayoutsM2";
import { useUploadedGettyUrls } from "../UseUploadedGettyUrl/UseUploadedGettyUrls";
import type { FontsManager } from "../../../../../common/fontsUtils";
import * as _ from "lodash";
import { useLibraryAvatars } from "../UseLibraryEntities";
import { useAccountAvatars } from "../UseAccountAvatars/useAccountAvatars";


const genericError = new Error("Generic Error");

export type LivePreviewType = LivePreviewState & {
    updateFlexiblePlaceholderPosition: UpdateFlexiblePlaceholderPosition;
};

type UseLivePreviewParams = {
    scene: LivePreviewSceneData;
    assetsByMediaId: GqlMediaById;
    frame: number;
    layout: GqlClientEditorAnimatedWireframeFragment;
    colors: ColorValues;
    logoUrl: LogoUrl;
    textStyles: GqlClientEditorBrandTextStyleFragment[];
    buttonShape: ButtonShape;
    studioElements: GqlClientStudioElementFragment[];
    intents: GqlClientEditorLibraryPlaceholderIntentFragment[];
    projectId: string;
    aspectRatio?: GqlClientEnumAspectRatio;
    videoQualityProfile: VideoQualityProfile;
    livePreviewClient: LivePreviewClient;
    backgroundMedia?: GqlClientEditorAssetLibraryMediaFragment;
    ccFontsManager: FontsManager<GqlClientEditorLibraryFontFragment>;
    accountFontsManager: FontsManager<GqlClientEditorAssetLibraryFontFragment>;
    useClientPlaceholderPreview?: boolean;
};

export const useLivePreview = (params: UseLivePreviewParams): LivePreviewType => {
    const { scene: initScene, assetsByMediaId: initAssets, frame, layout, colors, logoUrl, textStyles, buttonShape,
        studioElements, projectId, intents,
        aspectRatio, videoQualityProfile, livePreviewClient, backgroundMedia,
        ccFontsManager, accountFontsManager, useClientPlaceholderPreview } = params;
    const { editorUploadedGettyUrls } = useUploadedGettyUrls();

    const { scene, assetsByMediaId } = appendMockGettyAssetsIfNeeded(editorUploadedGettyUrls, initScene, initAssets);
    const { libraryAvatars } = useLibraryAvatars();
    const { customAvatars } = useAccountAvatars();

    const idRef = useRef<string>(uuid());
    const [state, dispatch] = useReducer(livePreviewReducer, livePreviewInitialState);
    const editorIsPreviewM2 = useEditorIsPreviewM2();

    function findPlaceholder(videospecName: string): LivePreviewScenePlaceholderData {
        return scene.placeholders.find((placeholder) => getPlaceholderName(placeholder) === videospecName);
    }

    function raasPlaceholdersReducer(acc: RenderedPlaceholder[], cur: RaasPlaceholder) {
        const placeholder = findPlaceholder(cur.videoSpecName);

        if (placeholder) {
            if (livePreviewClient === LivePreviewClient.MAIN_SCENE) {
                if (isPlaceholderLogo(placeholder)) {
                    setRaasPlaceholderData("logo", { position: cur.position });
                }
                setRaasPlaceholderData(placeholder.id, { position: cur.position });
            }
            acc.push({
                id: placeholder.id,
                position: cur.position,
                rawPosition: cur.rawPosition,
                videoSpecName: placeholder.ccPlaceholder?.name
            });
        }

        return acc;
    }

    //Effect to report pending status
    const excludeReportingClients = [LivePreviewClient.SCENES_LIBRARY, LivePreviewClient.SWITCH_SCENE_TEMPLATE_LIBRARY];
    useEffect(() => {
        !excludeReportingClients.includes(livePreviewClient) && reportPending(idRef.current, state.pending);
    }, [state.pending, LivePreviewClient]);

    //Effect to report pending status on unmount
    useEffect(() => {
        const currentIdRef = idRef.current;
        return () => {
            !excludeReportingClients.includes(livePreviewClient) && reportPending(currentIdRef, false);
        };
    }, []);

    const videoSpec = scene && buildVideoSpecForImage({
        requestId: uuid(),
        scene,
        assetsByMediaId,
        frame,
        layout,
        colors,
        logoUrl,
        textStyles,
        buttonShape,
        studioElements,
        projectId,
        intents,
        aspectRatio,
        videoQualityProfile,
        backgroundMedia,
        previewM2Mode: editorIsPreviewM2,
        ccFontsManager,
        accountFontsManager,
        libraryAvatars,
        customAvatars,
        useClientPlaceholderPreview
    });

    //Effect to make request to RaaS
    useEffect(() => {
        let isCancelled: boolean = false;

        const dispatchError = (error?: any): void => {
            dispatch({
                type: LivePreviewActionType.RenderError,
                payload: { error: error || genericError }
            });
            handleEditorError({
                error: error?.lastError || error || genericError,
                sskyCode: SskyErrorCode.LivePreviewError,
                details: error?.allErrors || { requestId: videoSpec.requestid }
            });
        };

        if (videoSpec.lineup.length === 0) {
            dispatchError(new Error("videoSpec lineup is empty"));
        }

        if (scene) {
            //reset live preview when program aspect ratio changes, videoSpec.output.videoAspectRatio is equal to aspectRatio
            if (state.videoAspectRatio !== videoSpec.output.videoAspectRatio as VideoAspectRatio) {
                dispatch({
                    type: LivePreviewActionType.Reset,
                    payload: undefined
                });
            }
            dispatch({
                type: LivePreviewActionType.RenderStart,
                payload: { sceneId: scene.id }
            });

            generateImageAndPlaceholders(scene.id, videoSpec, livePreviewClient)
                .then(imageAndPlaceholders => {
                    if (!isCancelled) {
                        const imageSrc = imageAndPlaceholders.imageSrc;
                        const renderedPlaceholders = imageAndPlaceholders.placeholders.reduce(raasPlaceholdersReducer, []);
                        const viewport = imageAndPlaceholders.viewport;
                        const foregroundLayer = imageAndPlaceholders.foregroundLayer;
                        const canvases = imageAndPlaceholders.canvases;

                        dispatch({
                            type: LivePreviewActionType.RenderSuccess,
                            payload: { imageSrc, renderedPlaceholders, viewport, foregroundLayer, canvases }
                        });
                    }
                }, error => {
                    if (!isCancelled) {
                        dispatchError(error);
                    }
                });
        }
        else {
            dispatch({
                type: LivePreviewActionType.Reset,
                payload: undefined
            });
        }

        return () => {
            isCancelled = true;
        };
    }, [scene?.id, videoSpec && renderDigest(videoSpec), dispatch, livePreviewClient]);

    const updateFlexiblePlaceholderPosition = useCallback(
        (placeholderId: PlaceholderId, position: RaasPlaceholderPosition) => {
            dispatch({
                type: LivePreviewActionType.UpdateFlexiblePosition,
                payload: { placeholderId, position }
            });
        },
        [dispatch]
    );

    return {
        ...state,
        updateFlexiblePlaceholderPosition: updateFlexiblePlaceholderPosition
    };
};

const appendMockGettyAssetsIfNeeded = (
    editorUploadedGettyUrl:UploadedGettyUrl[],
    scene: LivePreviewSceneData,
    assetsByMediaId: GqlMediaById
): { scene: LivePreviewSceneData, assetsByMediaId: GqlMediaById } => {
    const tempScene = _.cloneDeep(scene);
    let tempAssets = assetsByMediaId;

    if (editorUploadedGettyUrl.length) {
        tempAssets = _.cloneDeep(tempAssets);
        tempScene.placeholders
            .map(placeholder => {
                const isStatic = !isPlaceholderDynamic(placeholder);
                const gettyUrlPlaceholder = editorUploadedGettyUrl.find(uploadedGettyUrl => (
                    uploadedGettyUrl.target === UploadedGettyUrlTarget.PLACEHOLDER
                    && uploadedGettyUrl.placeholderId === placeholder.id
                    && uploadedGettyUrl.byAudienceCaseIndex === null
                ));

                if (gettyUrlPlaceholder) {
                    const mockAsset: GqlClientEditorAssetLibraryMediaFragment = {
                        id: uuid(),
                        name: "",
                        created: "",
                        updated: "",
                        mediaLocation: "",
                        mediaUrl: gettyUrlPlaceholder.mediaUrl,
                        mediaPreviewUrl: gettyUrlPlaceholder.thumbnailUrl,
                        mediaAgifUrl: null,
                        mediaOrigin: GqlClientMediaOrigin.EXTERNAL,
                        externalLibrary: GqlClientExternalLibrary.GETTY,
                        mediaClassification: GqlClientMediaClassification.ORIGINAL,
                        mediaMetadata: {},
                        mediaFramesThumbnailsUrl: "",
                        lifecycleStage: GqlClientAssetLifecycleStage.ACTIVE
                    };

                    tempAssets[mockAsset.id] = mockAsset;
                    if (isStatic) {
                        placeholder.placeholderContent.noRules.show.static = { ...placeholder.placeholderContent.noRules.show.static, mediaId: mockAsset.id };
                    }
                    else {
                        placeholder.placeholderContent.staticFallback = { ...placeholder.placeholderContent.staticFallback, mediaId: mockAsset.id };
                    }
                }
                return placeholder;
            });
    }
    return { scene: tempScene, assetsByMediaId: tempAssets };
};
