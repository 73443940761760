import type { ReactiveVar } from "@apollo/client";
import { makeVar } from "@apollo/client";
import type { EvpConfiguration, LpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { getEmptyEvpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import type { MutableRefObject } from "react";
import type ReactAudioPlayer from "react-audio-player";
import { MEDIA_LIBRARY_MAX_VIDEO_DURATION, MEDIA_LIBRARY_MIN_VIDEO_DURATION } from "../../../../common/commonConst";
import type {
    GqlClientEditorAccountLevelDataBrandsQuery,
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorCcLibraryVersionFragment,
    GqlClientEditorLibraryFontFragment,
    GqlClientEditorProgramForEmbedVideoBuilderQuery,
    GqlClientEditorProgramForLandingPageBuilderQuery,
    GqlClientEditorProgramVersionQuery
} from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientAssetLifecycleStage, GqlClientGettySortOrder } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { DraggedItemType } from "../../../utils/dragAndDrop";
import type {
    AssetGenerationDialogInfo,
    BrandId,
    ButtonShape,
    ColorValues,
    EditedViewerProfile,
    EditorDataConnectorEnabled,
    EditorPermissions,
    HelpCenterData,
    ItemData,
    LivePreviewPendingSet,
    LocalStateTextStyle,
    LogoAssetInfo,
    NotificationData,
    MultiSelectOption,
    PlaceholderId,
    ProcessedFile,
    ProgramName,
    PublishState,
    RaasPlaceholderData,
    RestrictedActionDialogData,
    SceneId,
    SelectedLibrary,
    ThemeId,
    UploadedGettyUrl,
    EditRecordingToolDialogState,
    RecordingToolPreviewData
} from "../types";
import {
    Libraries,
    LibraryOpener,
    EditorMediaDragAndDropSnackbarState,
    MediaLibraryActiveTab,
    MediaLibraryFilterMediaSource,
    MediaLibraryFilterMediaType,
    MediaLibrarySortBy,
    MediaLibrarySortDirection,
    PreviewDialogType,
    MediaLibraryFilterMediaOrientation,
    RecordingState
} from "../types";

import { createWireframeThemeSupport } from "../Utils";
import { createInitialLpConfiguration, getInitialLpConfigurationInput, updateConfigurationWithCurrentSettings } from "../Utils/LandingPageBuilder";
import { createInitialEvpConfiguration, getInitialEvpConfigurationInput, updateEvpConfigurationWithCurrentSettings } from "../Utils/EmbedVideoPageBuilder";
import type { WithFontMetadata } from "../../../../common/fontsUtils";
import { FontsManager } from "../../../../common/fontsUtils";
import { isNumber } from "../../../../common/generalUtils";

const RESTRICTED_ACTION_DIALOG_DEFAULT_DATA: { open: boolean } & RestrictedActionDialogData = {
    open: false,
    messageTitle: "",
    message: ""
};

// account/program data
export const localEditorPermissionsVar: ReactiveVar<EditorPermissions> = makeVar({});
export const localEditorIsPreviewDialogOpenVar: ReactiveVar<boolean> = makeVar(false);
export let localEditorPreviewDialogTypeVar: ReactiveVar<PreviewDialogType> = makeVar(PreviewDialogType.Video);
export const localEditorPreviewDialogScenesVar: ReactiveVar<{ mode: "multiSelect" | "selectedScene", scenes: MultiSelectOption[] }> = makeVar({ mode: "multiSelect", scenes: [] });
export const localEditorAccountIdVar: ReactiveVar<string> = makeVar("");
export const localEditorAccountTypeVar: ReactiveVar<string> = makeVar("");
export const localEditorAccountDataVersion: ReactiveVar<string> = makeVar("");
export const localEditorAccountNameVar: ReactiveVar<string> = makeVar("");
export const localEditorProcessedFilesVar: ReactiveVar<ProcessedFile[]> = makeVar([]);
export const localEditorNotificationDataVar: ReactiveVar<NotificationData> = makeVar({
    message: "",
    type: "neutral",
    isOpen: false
});
export const localEditorPublishStateVar: ReactiveVar<PublishState> = makeVar(null);
export const localEditorIsLpChangingActiveState: ReactiveVar<boolean> = makeVar(false);
export const localEditorIsSelectedSceneIsNewVar: ReactiveVar<boolean> = makeVar(false);

// program version data
export let localEditorLivePreviewPendingSetVar: ReactiveVar<LivePreviewPendingSet>;
export let localEditorSelectedFramePerSceneVar: ReactiveVar<Record<SceneId, number>>;
export let localEditorSelectedPlaceholderIdVar: ReactiveVar<PlaceholderId>;
export let localEditorSelectedPlaceholderOpenEditVar: ReactiveVar<boolean>;
export let localEditorSelectedNarrationPlaceholderOpenEditVar: ReactiveVar<boolean>;
export let localEditorSelectedSceneIdVar: ReactiveVar<SceneId>;
export let localBrandByAudienceFallbackIdVar: ReactiveVar<BrandId>;
export let localEditedBrandIdVar: ReactiveVar<BrandId>;
export let localIsNewBrandVar: ReactiveVar<boolean>;
export let localDeleteCandidateBrandIdVar: ReactiveVar<BrandId | null>;
export let localEditedBrandLogoAssetInfoVar: ReactiveVar<LogoAssetInfo>;
export let localEditedBrandColorsVar: ReactiveVar<ColorValues>;
export let localEditedBrandThemeIdVar: ReactiveVar<ThemeId>;
export let localEditedBrandTextStylesVar: ReactiveVar<LocalStateTextStyle[]>;
export let localEditedButtonShapeValueVar: ReactiveVar<ButtonShape>;
export let localEditorWireframeThemeSupport: ReactiveVar<Record<string, boolean>>;
export let localEditorSelectedAudioPreview: ReactiveVar<ReactAudioPlayer>;
export let localEditorRaasPlaceholderData: ReactiveVar<Record<"logo" | PlaceholderId, RaasPlaceholderData>>;
export let localEditorSelectedLibraryVar: ReactiveVar<SelectedLibrary>;
export let localEditorProgramNameVar: ReactiveVar<ProgramName>;
export let localEditorDataConnectorEnabledVar: ReactiveVar<EditorDataConnectorEnabled>;
export let localEditorLandingPageConfigurationVar: ReactiveVar<LpConfiguration> = makeVar(null);
export let localEditorInitialLandingPageConfigurationVar: ReactiveVar<LpConfiguration> = makeVar(null);
export const localEditorEmbedVideoPageConfigurationVar: ReactiveVar<EvpConfiguration> = makeVar(getEmptyEvpConfiguration());
export const localEditorInitialEmbedVideoPageConfigurationVar: ReactiveVar<EvpConfiguration> = makeVar(getEmptyEvpConfiguration());
export const cCFontsManagerVar: ReactiveVar<FontsManager<GqlClientEditorLibraryFontFragment>> = makeVar(null);
export const assetLibraryFontsManagerVar: ReactiveVar<FontsManager<GqlClientEditorAssetLibraryFontFragment>> = makeVar(null);
// Layouts 2.0 preview mode - should be deleted someday (?) after we've migrated everyone to layouts 2.0
export let localEditorIsPreviewM2Layouts = makeVar(false);
export let localEditorShouldOpenVoiceLibrary = makeVar(false);


// TruffleEditingWorkSpace
export const localEditorBrandByAudienceIndicatorVar: ReactiveVar<boolean> = makeVar(false);
export const localEditorUploadedGettyUrlsVar: ReactiveVar<UploadedGettyUrl[]> = makeVar([]);
export const localEditorMediaLibraryByAudienceCaseIndex = makeVar<number | null>(null);
export const localEditorMediaDragAndDropSnackbarState = makeVar(EditorMediaDragAndDropSnackbarState.CLOSED);
export const localEditorShowDropHintFromMediaLibrary = makeVar<boolean>(false);
export const localEditorMediaLibraryDraggingFromFolderId = makeVar<string | null>(null);
export const localRestrictedActionDialog = makeVar<{ open: boolean } & RestrictedActionDialogData>(RESTRICTED_ACTION_DIALOG_DEFAULT_DATA);
export const localEditorMediaLibraryGridColumns = makeVar(2);
export const localEditorDraggedItemTypeVar = makeVar<DraggedItemType | null>(null);
export const localAiAssetGenerationDialogInfo = makeVar<AssetGenerationDialogInfo | null>(null);
export const localEditorShowReplaceAvatarHint = makeVar<boolean>(false);

// Docked Media Library
export const localEditorDockedMediaLibrarySearchValue = makeVar<string>("");
export const localEditorDockedMediaLibraryFilterMediaType = makeVar(MediaLibraryFilterMediaType.ALL);
export const localEditorDockedMediaLibraryFilterMediaSource = makeVar(MediaLibraryFilterMediaSource.ALL);
export const localEditorDockedMediaLibraryFilterStockOrientation = makeVar(MediaLibraryFilterMediaOrientation.ALL);
export const localEditorDockedMediaLibraryFilterMinVideoDuration = makeVar(MEDIA_LIBRARY_MIN_VIDEO_DURATION);
export const localEditorDockedMediaLibraryFilterMaxVideoDuration = makeVar(MEDIA_LIBRARY_MAX_VIDEO_DURATION);
export const localEditorDockedMediaLibrarySortBy = makeVar(MediaLibrarySortBy.LAST_ADDED);
export const localEditorDockedMediaLibrarySortDirection = makeVar(MediaLibrarySortDirection.TOP_TO_BOTTOM);
export const localEditorDockedMediaLibraryStockSortOrder = makeVar(GqlClientGettySortOrder.BEST_MATCH);
export const localEditorDockedMediaLibraryActiveTab = makeVar<MediaLibraryActiveTab>(MediaLibraryActiveTab.YourLibrary);
export const localEditorDockedMediaLibraryCurrentFolderId = makeVar<string | null>(null);
export const localEditorDockedMediaLibrarySelectedItemData = makeVar<ItemData | null>(null);
export const localEditorEditRecordingToolDialogState = makeVar<EditRecordingToolDialogState>({ open: false, mediaStreamType: null });
export const localEditorRecordingToolUploadedFile = makeVar<RecordingToolPreviewData>(null);
export const localEditorRecordingState = makeVar<RecordingState>(RecordingState.NO_SESSION);

// user data
export const localEditorUserNameVar: ReactiveVar<string> = makeVar("");

// utils data
export const localEditorIsAddSceneDialogOpen: ReactiveVar<boolean> = makeVar(false);
export const localEditorShowPronunciationLibraryVar: ReactiveVar<boolean> = makeVar(false);
export const localEditorVideoPlayerControlVar: ReactiveVar<{ playerKey: string, abortControllerRef: MutableRefObject<AbortController> }> = makeVar({
    playerKey: "",
    abortControllerRef: null
});
export const localEditorInAppHelpCenterPopoverVar: ReactiveVar<HelpCenterData | null> = makeVar(null);
export const localEditedViewerProfileVar: ReactiveVar<EditedViewerProfile> = makeVar({
    editedViewerProfileId: "",
    editedViewerProfileName: "",
    editedViewerProfileValues: {}
});

type InitLocalStateForEditorParams = {
    editorProgram: GqlClientEditorProgramVersionQuery["editorProgram"];
    brandConfigurationVersion: GqlClientEditorAccountLevelDataBrandsQuery["editorAccountLevelData"]["brandConfigurationVersion"]
};
const filterFontsForFontManager = <T extends { created: string, lifecycleStage?: GqlClientAssetLifecycleStage, weight?: number, familyName?: string, isBold?: boolean, isItalic?: boolean }>
    (fonts: T[]): WithFontMetadata<T>[] => {
    const fontsWithCreation = (fonts || []).map(font => ({ ...font, creationTime: new Date(font.created).getTime() }));
    //there should not be any "enabled" font that doesn't have the metadata bellow, we will filter these fonts out just in case.
    const filteredFonts = fontsWithCreation.filter(font =>
        font.lifecycleStage === GqlClientAssetLifecycleStage.ENABLED &&
        isNumber(font.weight) && font.familyName && font.isBold !== null && font.isItalic !== null);
    return filteredFonts as WithFontMetadata<T>[];
};
export const updateCcFontManagerVar = (fonts: GqlClientEditorLibraryFontFragment[]) => {
    const filteredFonts: WithFontMetadata<GqlClientEditorLibraryFontFragment>[] = filterFontsForFontManager(fonts);
    cCFontsManagerVar(new FontsManager(filteredFonts));
};

export const updateAssetLibraryFontsManagerVar = (fonts: GqlClientEditorAssetLibraryFontFragment[]) => {
    const filteredFonts: WithFontMetadata<GqlClientEditorAssetLibraryFontFragment>[] = filterFontsForFontManager(fonts);
    assetLibraryFontsManagerVar(new FontsManager(filteredFonts));
};

export const initLocalStateForEditor = ({ editorProgram, brandConfigurationVersion }: InitLocalStateForEditorParams): void => {
    localEditorLivePreviewPendingSetVar = makeVar(new Set<string>());
    localEditorSelectedFramePerSceneVar = makeVar({});
    localEditorSelectedPlaceholderIdVar = makeVar(null);
    localEditorSelectedPlaceholderOpenEditVar = makeVar(false);
    localEditorSelectedNarrationPlaceholderOpenEditVar = makeVar(false);
    localEditorSelectedSceneIdVar = makeVar(editorProgram.programVersion.sceneLineup[0] || null);
    localEditedBrandIdVar = makeVar(null);
    localDeleteCandidateBrandIdVar = makeVar(null);
    localIsNewBrandVar = makeVar(false);
    localBrandByAudienceFallbackIdVar = makeVar(editorProgram.programVersion.bcUseRules
        ? brandConfigurationVersion.brandConfigurationCollections.find(brand => brand.localId === editorProgram.programVersion.bcRules.fallback).id
        : null
    );
    localEditedBrandLogoAssetInfoVar = makeVar({ logoLocation: null, logoUrl: null });
    localEditedBrandColorsVar = makeVar({});
    localEditedBrandThemeIdVar = makeVar(null);
    localEditedBrandTextStylesVar = makeVar([]);
    localEditedButtonShapeValueVar = makeVar(null);
    localEditorSelectedAudioPreview = makeVar(null);
    localEditorRaasPlaceholderData = makeVar({});
    localEditorProgramNameVar = makeVar(editorProgram.name);
    localEditorDataConnectorEnabledVar = makeVar(editorProgram.programVersion.editorDataConnectorEnabled);
    localEditorPreviewDialogTypeVar(PreviewDialogType.Video);
    localEditorIsAddSceneDialogOpen(false);
    localEditorShowPronunciationLibraryVar(false);
    localEditorIsPreviewDialogOpenVar(false);
    localAiAssetGenerationDialogInfo(null);
    localEditorUploadedGettyUrlsVar([]);
    localEditorMediaLibraryByAudienceCaseIndex(null);
    localEditorMediaDragAndDropSnackbarState(EditorMediaDragAndDropSnackbarState.CLOSED);
    localRestrictedActionDialog(RESTRICTED_ACTION_DIALOG_DEFAULT_DATA);

    const shouldOpenVoiceLibrary = localEditorShouldOpenVoiceLibrary();
    localEditorSelectedLibraryVar = makeVar(shouldOpenVoiceLibrary ? { library: Libraries.NarratorsPanel, opener: LibraryOpener.VideoOverviewPage } : { library: null, opener: null });
    if (shouldOpenVoiceLibrary) {
        localEditorShouldOpenVoiceLibrary(false);
    }

    //Docked Media Library
    localEditorDockedMediaLibrarySearchValue("");
    localEditorDockedMediaLibraryFilterMediaType(MediaLibraryFilterMediaType.ALL);
    localEditorDockedMediaLibraryFilterMediaSource(MediaLibraryFilterMediaSource.ALL);
    localEditorDockedMediaLibraryFilterStockOrientation(MediaLibraryFilterMediaOrientation.ALL);
    localEditorDockedMediaLibraryFilterMinVideoDuration(MEDIA_LIBRARY_MIN_VIDEO_DURATION);
    localEditorDockedMediaLibraryFilterMaxVideoDuration(MEDIA_LIBRARY_MAX_VIDEO_DURATION);
    localEditorDockedMediaLibrarySortBy(MediaLibrarySortBy.LAST_ADDED);
    localEditorDockedMediaLibrarySortDirection(MediaLibrarySortDirection.TOP_TO_BOTTOM);
    localEditorDockedMediaLibraryStockSortOrder(GqlClientGettySortOrder.BEST_MATCH);
    localEditorDockedMediaLibraryActiveTab(MediaLibraryActiveTab.YourLibrary);
    localEditorDockedMediaLibraryCurrentFolderId(null);
    localEditorDockedMediaLibrarySelectedItemData(null);

    localEditorShowDropHintFromMediaLibrary(false);
    localEditorMediaLibraryDraggingFromFolderId(null);
    localEditorShowReplaceAvatarHint(false);
};

export const initCcLibraryLocalStateForEditor = (ccLibraryVersion: GqlClientEditorCcLibraryVersionFragment): void => {
    const { themes, wireframes } = ccLibraryVersion;
    localEditorWireframeThemeSupport = makeVar(createWireframeThemeSupport(wireframes, themes));
    updateCcFontManagerVar(ccLibraryVersion?.fonts);
};

type InitLocalStateForLandingPageBuilderParams = {
    editorProgram: GqlClientEditorProgramForLandingPageBuilderQuery["editorProgram"];
};

export const initLocalStateForLandingPageBuilder = ({ editorProgram }: InitLocalStateForLandingPageBuilderParams): void => {
    const initialConfigurationInput = getInitialLpConfigurationInput(editorProgram);

    const initialConfiguration = createInitialLpConfiguration(initialConfigurationInput);
    const updatedExistingConfiguration = editorProgram.landingPage?.configuration ?
        updateConfigurationWithCurrentSettings(editorProgram.landingPage?.configuration, initialConfigurationInput) :
        null;

    localEditorLandingPageConfigurationVar(updatedExistingConfiguration || initialConfiguration);
    localEditorInitialLandingPageConfigurationVar(initialConfiguration);
    updateCcFontManagerVar(editorProgram.programVersionDraft.ccLibraryVersion?.fonts);
    updateAssetLibraryFontsManagerVar(editorProgram.programVersionDraft.assetLibrary?.fonts);
};

type InitLocalStateForEmbedVideoBuilderParams = {
    editorProgram: GqlClientEditorProgramForEmbedVideoBuilderQuery["editorProgram"];
};

export const initLocalStateForEmbedVideoPageBuilder = ({ editorProgram }: InitLocalStateForEmbedVideoBuilderParams): void => {
    const initialConfigurationInput = getInitialEvpConfigurationInput(editorProgram);

    const initialConfiguration = createInitialEvpConfiguration(initialConfigurationInput);
    const updatedExistingConfiguration = editorProgram.embedVideoCode?.configuration
        ? updateEvpConfigurationWithCurrentSettings(editorProgram.embedVideoCode?.configuration, initialConfigurationInput)
        : null;

    localEditorEmbedVideoPageConfigurationVar(updatedExistingConfiguration || initialConfiguration);
    localEditorInitialEmbedVideoPageConfigurationVar(initialConfiguration);
};

/* Recording Tool */
export const recordingToolMenuAnchorVar = makeVar<HTMLElement | null>(null);
