import { useEditorProgram } from "../UseEditorProgram";
import { GqlClientEditorProgramType } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";

export type UseProgramInteractivity = {
    isInteractivitySupported: boolean
};

export const useProgramInteractivity = (): UseProgramInteractivity => {
    const programId = useEditorProgramId();
    const { editorProgram } = useEditorProgram(programId);

    return { isInteractivitySupported: editorProgram?.programType !== GqlClientEditorProgramType.ADS };
};
