import React from "react";
import { SketchPicker } from "react-color";
import { SskyTabs } from "@sundaysky/ui-component-library/Components";
import Rnd from "react-rnd";
import type { Style } from "../../../../logic/builder/editorsContainerLogic/styleUtils";
import { inlineColor } from "../../../../logic/builder/editorsContainerLogic/styleUtils";
import "./StylePicker.css";
import { mapRange } from "../../../../../common/generalUtils";

type StylePickerProps = {
    style: Style | string;
    onChange: (value: Style | string) => void;
    singleMode?: boolean; //solid color only
};

type StylePickerState = {
    selectedTint: string;
};

export default class StylePicker extends React.Component<StylePickerProps, StylePickerState> {
    state = {
        selectedTint: "Tint1"
    };

    onTintChange = (color, selectedTint: string): void => {
        if (this.props.singleMode) {
            this.props.onChange(color.hex);
        }
        else if (selectedTint === "Tint2") {
            this.props.onChange({ ...this.props.style as Style, Tint2: color.hex });
        }
        else {
            this.props.onChange({ ...this.props.style as Style, Tint1: color.hex });
        }
    };

    onRatioChange = (evt, value): void => {
        // convert from range of 0-188 (200px slider minus 12px circle width) to range of 0-1
        const updatedRatio: number = mapRange(value.x, 0, 188, 0, 1);
        this.props.onChange({ ...this.props.style as Style, Ratio: updatedRatio });
    };

    handleTabChange = (evt, selectedTabId: number): void => {
        const updatedStyle: Style = {
            ...(this.props.style as Style),
            IsOneTint: !selectedTabId
        };
        this.props.onChange(updatedStyle);
    };

    selectTint = (tintName: string): void => {
        this.setState({ selectedTint: tintName });
    };

    getSelectedTab = (style: Style): number => (style.IsOneTint ? 0 : 1);

    renderColorPicker = (style: Style | string, selectedTint: string): JSX.Element => (
        <div className="color-picker">
            <SketchPicker className={"disable-box-shadow"} color={style[selectedTint] || style}
                onChange={(evt) => this.onTintChange(evt, selectedTint)} disableAlpha={true} presetColors={[]} width={"200"} />
        </div>
    );

    renderSolidTab = (): JSX.Element => {
        const { style } = this.props;

        const { selectedTint } = this.state;

        return (
            <div className="tab-content">
                {!this.props.singleMode && <div className="grad-holder" />}
                {this.renderColorPicker(style, selectedTint)}
            </div>
        );
    };

    renderGradientTab = (): JSX.Element => {
        const { selectedTint } = this.state;

        let { style } = this.props;
        style = style as Style;

        const gradBg: string = inlineColor(style);

        const sliderPosition: { x: number; y: number } = {
            // convert from range of 0-1 to range of 0-188 (200px slider width minus 12px circle width).
            x: mapRange(style.Ratio, 0, 1, 0, 188),
            y: 0
        };

        return (
            <div className="tab-content">
                <div className="grad-holder">
                    <div className="tints">
                        <div
                            className={`single-tint${selectedTint === "Tint1" ? " selected" : ""}`}
                            style={{ backgroundColor: style.Tint1 }}
                            onClick={() => {
                                this.selectTint("Tint1");
                            }}
                        />
                        <div
                            className={`single-tint${selectedTint === "Tint2" ? " selected" : ""}`}
                            style={{ backgroundColor: style.Tint2 }}
                            onClick={() => {
                                this.selectTint("Tint2");
                            }}
                        />
                    </div>
                    <div className="slider" style={{ background: gradBg }}>
                        <Rnd default={sliderPosition} bounds="parent" enableResizing={false} onDragStop={this.onRatioChange}>
                            <div className="slider-draggable" />
                        </Rnd>
                    </div>
                </div>
                {this.renderColorPicker(style, selectedTint)}
            </div>
        );
    };

    renderTabs = () => [
        { id: 0, label: "SOLID", disabled: false, render: this.renderSolidTab },
        { id: 1, label: "GRADIENT", disabled: false, render: this.renderGradientTab }
    ];

    render(): JSX.Element {
        const { style } = this.props;

        return (
            <div className="style-picker">
                {this.props.singleMode && this.renderSolidTab()}
                {!this.props.singleMode && <SskyTabs tabClassName="tab-single" tabs={this.renderTabs()} selectedTabId={this.getSelectedTab(style as Style)} handleChange={this.handleTabChange} />}
            </div>
        );
    }
}
