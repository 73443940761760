import { useReactiveVar } from "@apollo/client";
import { localEditorDataConnectorEnabledVar } from "../../State";
import type { EditorDataConnectorEnabled } from "../../types";

export type UseEditorDataConnectorEnabled = {
    editorDataConnectorEnabled: EditorDataConnectorEnabled;
};

export const useEditorDataConnectorEnabled = (): UseEditorDataConnectorEnabled => {
    const editorDataConnectorEnabled = useReactiveVar(localEditorDataConnectorEnabledVar);

    return { editorDataConnectorEnabled };
};