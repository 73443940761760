import createHistory from "history/createBrowserHistory";
import { legacyRootPath, newNavRootPath } from "@sundaysky/smartvideo-hub-urls";
import { isNewNav } from "../../utils/newNavUtils";

const LOGIN_REDIRECT_TO = "loginRedirectTo";
const REDIRECT_FROM_LOGIN = "redirectFromLogin";

export const saveRedirectTo = () => {
    sessionStorage.setItem(LOGIN_REDIRECT_TO, `${window.location.pathname}${window.location.search}`);
};

export const removeRedirectTo = () => {
    sessionStorage.removeItem(LOGIN_REDIRECT_TO);
};

export const cleanUserName = (user: string) : string => {
    return user ? user.toLowerCase().trim() : "";
};

export const redirectAfterLogin = () => {
    const history = createHistory();
    const nextUrl = sessionStorage.getItem(LOGIN_REDIRECT_TO);
    sessionStorage.removeItem(LOGIN_REDIRECT_TO);
    const redirectTo = nextUrl || (
        isNewNav
            ? newNavRootPath()
            : legacyRootPath()
    );

    sessionStorage.setItem(REDIRECT_FROM_LOGIN, "true");

    //using new history object (not react-router-dom) we want to avoid components to render before the reload page.
    history.push(redirectTo);
    window.location.reload();
};

export const isRedirectFromLogin = (): boolean => {
    const redirectFromLogin = sessionStorage.getItem(REDIRECT_FROM_LOGIN);
    sessionStorage.removeItem(REDIRECT_FROM_LOGIN);
    return redirectFromLogin === "true";
};

export const rememberMeEmailLocalVar = "remember-me-email";
