import { useEffect, useMemo, useState } from "react";
import type { GqlClientEditorSceneWithoutCcDataFragment, GqlClientStudioElementFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEditorSceneSkippingMode } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";
import { useProjectSelectedSceneId } from "../UseProjectSelectedEntities";
import { useProjectLineup } from "../UseProjectLineup";
import { useStudioElements } from "../UseStudioElements";
import { useEditorPreviewDialogType } from "./useEditorPreviewDialogType";
import { getBrandByAudience, getLocalViewerProfileValues, getSceneWithoutCcData } from "../../Nooks";
import type { ExtendedViewerProfileItem, ProgramId, ProgramVersionId, StudioElementExtended } from "../../types";
import { PreviewDialogType } from "../../types";
import { getExtendedViewerProfileByStudioElements, getPresets } from "../../Utils";
import { getDataElementsFromPlaceholderContent } from "../../../../../common/placeholderUtils";
import { useLocalViewerProfile } from "./useLocalViewerProfile";
import type { SceneSkippingDataElements } from "../../Utils/skipSceneUtils";
import { checkIfVideoSkippedByAudience, getSceneSkippingDataElements } from "../../Utils/skipSceneUtils";
import { useEditorProgramVersionUsedDataFields } from "../UseEditorProgramVersionUsedDataFields";

export type UseVideoPreviewSceneOrder = {
    extendedFilteredViewerProfile: ExtendedViewerProfileItem[];
    getPresets: () => Record<string, string>;
    shouldDisablePlaying: boolean;
    sceneSkippingDataElements: SceneSkippingDataElements;
    scenesToPreviewIds: string[];
    sceneOrder: string[];
    sceneSkipMode: GqlClientEditorSceneSkippingMode;
    fullVideoUsedElements: StudioElementExtended[];
    usedStudioElements: GqlClientStudioElementFragment[];
    scene: GqlClientEditorSceneWithoutCcDataFragment | null;
};

export const useVideoPreviewSceneOrderLegacy = (isDraft: boolean): UseVideoPreviewSceneOrder => {
    const [scenesToPreview, setScenesToPreview] = useState<GqlClientEditorSceneWithoutCcDataFragment[]>([]);
    const [usedStudioElementIds, setUsedStudioElementIds] = useState<Set<string>>(new Set());
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { usedElements } = useEditorProgramVersionUsedDataFields(programId, programVersionId, isDraft);
    const { projectSelectedSceneId } = useProjectSelectedSceneId();
    const { projectLineup } = useProjectLineup();
    const { studioElements } = useStudioElements();
    const { previewDialogType } = useEditorPreviewDialogType();
    const previewType = isDraft ? previewDialogType : PreviewDialogType.Video;
    const scene = useMemo(() => getSceneWithoutCcData(projectSelectedSceneId), [projectSelectedSceneId]);

    const { fullVideoUsedElementsIds, fullVideoUsedElements } = useMemo(() => {
        const fullVideoUsedElementsIds = new Set<string>(
            isDraft
                ? getDraftUsedStudioElementIds(programId, programVersionId, projectLineup.map(getSceneWithoutCcData))
                : Object.keys(usedElements)
        );
        const fullVideoUsedElements = studioElements.filter(e => {
            return fullVideoUsedElementsIds.has(e.localId);
        });
        return { fullVideoUsedElementsIds, fullVideoUsedElements };
    }, []);

    const sceneOrder: string[] = scenesToPreview.map(scene => scene.name);

    useEffect(() => {
        if (previewType === PreviewDialogType.Video) {
            setScenesToPreview(projectLineup.map(getSceneWithoutCcData));
            setUsedStudioElementIds(fullVideoUsedElementsIds);
        }
        else {
            const lineUp: GqlClientEditorSceneWithoutCcDataFragment[] = [scene];
            setUsedStudioElementIds(getDraftUsedStudioElementIds(programId, programVersionId, lineUp));
            setScenesToPreview(lineUp);
        }
    }, [previewType]);

    const usedStudioElements: GqlClientStudioElementFragment[] = useMemo(() => {
        return studioElements.filter(e => {
            return usedStudioElementIds.has(e.localId);
        });
    }, [studioElements, usedStudioElementIds]);

    const {
        editedViewerProfileId,
        editedViewerProfileValues
    } = useLocalViewerProfile();

    const extendedFilteredViewerProfile: ExtendedViewerProfileItem[] = useMemo(() => {
        return getExtendedViewerProfileByStudioElements(editedViewerProfileValues, usedStudioElements);
    }, [usedStudioElements, editedViewerProfileId, editedViewerProfileValues]);

    const getLocalPresets = () => getPresets(getLocalViewerProfileValues(), usedStudioElements);

    const sceneSkipMode: GqlClientEditorSceneSkippingMode = previewType === PreviewDialogType.Scene ? scene.skipSceneMode : null;

    const { shouldDisablePlaying, sceneSkippingDataElements, scenesToPreviewIds } = useMemo(() => {
        // will calculate if video is skipped (if preview type is scene, `scenesToPreview` will include only one scene)
        const isVideoSkippedByAudience = checkIfVideoSkippedByAudience(scenesToPreview, extendedFilteredViewerProfile);

        const shouldDisablePlayingScene = (
            (sceneSkipMode === GqlClientEditorSceneSkippingMode.BY_AUDIENCE
            || sceneSkipMode === GqlClientEditorSceneSkippingMode.SHOW_BY_AUDIENCE)
            && isVideoSkippedByAudience
        );
        const shouldDisablePlayingVideo = previewType === PreviewDialogType.Video && isVideoSkippedByAudience;

        const sceneSkippingDataElements = getSceneSkippingDataElements(scenesToPreview);

        return {
            shouldDisablePlaying: shouldDisablePlayingScene || shouldDisablePlayingVideo,
            sceneSkippingDataElements,
            scenesToPreviewIds: previewType === PreviewDialogType.Scene ? [projectSelectedSceneId] : projectLineup
        };
    }, [sceneSkipMode, previewType, scenesToPreview, extendedFilteredViewerProfile]);

    return {
        extendedFilteredViewerProfile,
        getPresets: getLocalPresets,
        shouldDisablePlaying,
        sceneSkippingDataElements,
        scenesToPreviewIds,
        sceneOrder,
        sceneSkipMode,
        fullVideoUsedElements,
        usedStudioElements,
        scene
    };
};

const getDraftUsedStudioElementIds = (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    scenesToPreview: GqlClientEditorSceneWithoutCcDataFragment[]
): Set<string> => {
    let usedStudioElements: Set<string> = new Set();

    scenesToPreview.forEach(scene => {
        scene.placeholders.forEach(placeholder => {
            const placeholderUsedElements = getDataElementsFromPlaceholderContent(
                placeholder.placeholderContent,
                false);
            placeholderUsedElements.forEach(elementId => usedStudioElements.add(elementId));
        });
        if (
            (scene.skipSceneMode === GqlClientEditorSceneSkippingMode.BY_AUDIENCE
            || scene.skipSceneMode === GqlClientEditorSceneSkippingMode.SHOW_BY_AUDIENCE)
            && scene.skipSceneAudience?.by?.localId
        ) {
            usedStudioElements.add(scene.skipSceneAudience.by.localId);
        }
    });
    const brandByAudienceRules = getBrandByAudience(programId, programVersionId).bcRules;
    if (brandByAudienceRules?.by?.localId) {
        usedStudioElements.add(brandByAudienceRules.by.localId);
    }

    return usedStudioElements;
};
