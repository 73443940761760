import { useEditorProgramDataConnectorQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { AccountDataLibrary } from "../../types";
import { convertGqlDataLibraryVersionToDataLibrary } from "../../Utils";
import { useMemo } from "react";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseEditorAccountDataLibraryInfo = {
    loading: boolean;
    connectedToDataLibrary: boolean;
    accountDataLibrary: AccountDataLibrary;
}
export const useEditorAccountDataLibraryInfo = (skip?: boolean): UseEditorAccountDataLibraryInfo => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data, loading } = useEditorProgramDataConnectorQuery({ variables: { programVersionId, programId }, skip });
    const dataLibraryVersion = data?.editorProgram?.programVersion?.accountDataLibraryVersion;
    const connectedToDataLibrary = !!dataLibraryVersion;

    const accountDataLibrary = useMemo(() => {
        return connectedToDataLibrary ? convertGqlDataLibraryVersionToDataLibrary(dataLibraryVersion) : undefined;
    }, [dataLibraryVersion]);

    return { loading, accountDataLibrary, connectedToDataLibrary };
};
