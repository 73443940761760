import type { AspectRatio, LpConfiguration, MediaNode } from "@sundaysky/landing-page-skeleton-types";
import type { GqlClientEditorProgramForLandingPageBuilderQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getLocalIdFromGqlId } from "../../../../common/idUtils";
import type { PlayerAspectRatio } from "../../../../common/aspectRatioUtils";
import { getAspectRatio } from "../../../../common/aspectRatioUtils";
import { getDefaultPosterImagesPerProgram, getPosterImage, updatePosterImageValue } from "./EmbedVideoLandingPageUtils";
import type { DeltaOperation } from "quill";


export type InitialLpConfigurationInput = {
    logo: MediaNode;
    colors: Record<string, string>;
    fonts: Record<string, string>;
    defaultPosterImages: Map<PlayerAspectRatio, MediaNode>;
    analyticsToken: string;
    aspectRatio: PlayerAspectRatio
};

export const defaultLpConfigurationValues = {
    headlineText: "We've got a video for you",
    subheadlineText: "Click ‘Play’ and watch it now",
    isButtonVisible: false,
    isPrivacyPolicyVisible: false,
    buttonUrl: "",
    buttonText: "",
    disclaimerText: ""
};

export const newDefaultLpConfigurationValues = {
    ...defaultLpConfigurationValues,
    buttonText: "Click here"
};

export const defaultTextColorDofName = "primary_color";
export const defaultButtonColorDofName = "buttonText_color";

export const createDeltaFromInput = (text: string, font: string, color: string, size: number): DeltaOperation[] => {
    return [ { insert: text, attributes: { size: `${size}px`, font: font, color: color } }, { insert: "\n", attributes: { align: "center" } } ];
};

export const getFontNameForClass = (name: string) => name?.replace(/[, \W]/g, "");

export const createInitialLpConfiguration = (input: InitialLpConfigurationInput): LpConfiguration => {
    return {
        version: 6,
        getSessionEndpointPrefix: "", // if blank, the default "getSessionEndpointPrefix" is saved in the skeleton itself
        background: {
            color: "#ffffff"
        },
        loader: {
            logo: input.logo,
            color: input.colors.primary_color
        },
        header: {
            logo: input.logo
        },
        main: {
            headline: {
                delta: { "ops": createDeltaFromInput(defaultLpConfigurationValues.headlineText, input.fonts.Heading, input.colors[defaultTextColorDofName], 54) }
            },
            subheadline: {
                delta: { "ops": createDeltaFromInput(defaultLpConfigurationValues.subheadlineText, input.fonts["Sub-heading"], input.colors[defaultTextColorDofName], 28) }
            },
            playerConfiguration: {
                posterImage: getPosterImage(input.aspectRatio, input.defaultPosterImages),
                analyticsToken: input.analyticsToken,
                showLogo: true,
                playButtonColor: input.colors.primary_color,
                lockPlayerBar: false,
                addTranscriptButton: true,
                ccOnByDefault: false,
                largeCenterPlayIcon: false,
                rectangleCenterPlayIcon: false,
                logoOverlay: {
                    ...input.logo,
                    displayLogo: false
                },
                aspectRatio: input.aspectRatio as AspectRatio
            },
            surveyConfiguration: {
                surveyId: "",
                backgroundColor: "#FFFFFF",
                showAt: 0.9
            },
            buttons: [
                {
                    isVisible: defaultLpConfigurationValues.isButtonVisible,
                    url: defaultLpConfigurationValues.buttonUrl,
                    color: input.colors.primary_color,
                    text: {
                        delta: { "ops": createDeltaFromInput(
                            newDefaultLpConfigurationValues.buttonText,
                            input.fonts.Button, input.colors[defaultButtonColorDofName], 18)
                        }
                    }
                }
            ]
        },
        footer: {
            disclaimer: {
                delta: { "ops": [ { insert: "\n", attributes: { align: "center" } } ] }
            },
            showWatermark: true,
            privacyPolicy: {
                font: input.fonts.Footnote,
                isVisible: defaultLpConfigurationValues.isPrivacyPolicyVisible,
                url: "",
                text: "privacy policy",
                color: "#B2B2B2"
            }
        },
        fonts: input.fonts
    };
};

export const updateConfigurationWithCurrentSettings = (configuration: LpConfiguration, input: InitialLpConfigurationInput): LpConfiguration => {
    const fontsNamesByFontFile = Object.entries(input.fonts).reduce((acc, [fontName, location]) => {
        const fontFile = location?.split("/").pop();
        if (fontFile) {
            acc.set(fontFile, fontName);
        }
        return acc;
    }, new Map<string, string>());

    const fixFontNameForClass = (fontName: string): string => {
        if (["headline", "subheadline", "disclaimer", "button0"].includes(fontName)) {
            const fontFileFromConfiguration = configuration.fonts?.[fontName]?.split("/").pop();
            const selectedFontName = fontFileFromConfiguration && fontsNamesByFontFile.get(fontFileFromConfiguration);
            return getFontNameForClass(selectedFontName || fontName);
        }
        else {
            return getFontNameForClass(fontName);
        }
    };

    const fixOpFontNameForClass = (op) => {
        if (op?.attributes?.font) {
            return {
                ...op,
                attributes: {
                    ...op.attributes,
                    font: fixFontNameForClass(op.attributes.font)
                }
            };
        }
        return op;
    };
    return {
        ...configuration,
        main: {
            ...configuration.main,
            playerConfiguration: {
                ...configuration.main.playerConfiguration,
                posterImage: updatePosterImageValue(configuration.main.playerConfiguration.posterImage, input.defaultPosterImages, input.aspectRatio),
                analyticsToken: input.analyticsToken,
                aspectRatio: input.aspectRatio as AspectRatio
            },
            headline: {
                delta: { ops: (configuration.main.headline?.delta?.ops || []).map(fixOpFontNameForClass) }
            },
            subheadline: {
                delta: { ops: (configuration.main.subheadline?.delta?.ops || []).map(fixOpFontNameForClass) }
            },
            buttons: (configuration.main.buttons || []).map((button) => ({
                ...button,
                text: {
                    delta: { ops: (button?.text?.delta?.ops || []).map(fixOpFontNameForClass) }
                }
            }))
        },
        footer: {
            ...configuration.footer,
            disclaimer: {
                delta: { ops: (configuration.footer?.disclaimer?.delta?.ops || []).map(fixOpFontNameForClass) }
            },
            privacyPolicy: {
                ...configuration.footer?.privacyPolicy,
                font: fixFontNameForClass(configuration.footer?.privacyPolicy?.font)
            }
        },
        fonts: {
            ...input.fonts
        }
    };
};


export const getInitialLpConfigurationInput = (editorProgram: GqlClientEditorProgramForLandingPageBuilderQuery["editorProgram"]): InitialLpConfigurationInput => {

    // Default fonts from brand - only set element and font name
    const fonts = editorProgram.programVersionDraft.selectedBrandConfiguration.textStyles.reduce((acc, style) => {
        acc[style.ccTextStyle.name] = style.fontSource === "account" ? getFontNameForClass(style.accountFont.name) : getFontNameForClass(style.ccFont.name);
        return acc;
    }, {});

    // All fonts
    const ccFonts = editorProgram.programVersionDraft.ccLibraryVersion.fonts.reduce((acc, font) => {
        acc[font.name] = font.webFontUrl;
        return acc;
    }, {});

    const accountFonts = editorProgram.programVersionDraft.assetLibrary.fonts.reduce((acc, font) => {
        acc[font.name] = font.webFontUrl;
        return acc;
    }, {});

    const colors = editorProgram.programVersionDraft.selectedBrandConfiguration.colors.reduce((acc, color) => {
        acc[color.ccDof.name] = color.color;
        return acc;
    }, {});

    const logoAsset = editorProgram.programVersionDraft.selectedBrandConfiguration.logoMedia;
    const defaultPosterImages = getDefaultPosterImagesPerProgram(editorProgram);
    return {
        logo: {
            url: logoAsset?.mediaUrl ?? "",
            platformId: getLocalIdFromGqlId(logoAsset?.id) || ""
        },
        colors,
        fonts: { ...fonts, ...ccFonts, ...accountFonts },
        defaultPosterImages: defaultPosterImages,
        analyticsToken: editorProgram.saleForceId,
        aspectRatio: getAspectRatio(editorProgram.programVersionDraft.aspectRatio).playerFormat()
    };
};
