import { getApolloClient } from "../../../../apollo";
import type { GqlClientCreateShareHistoryRecordMutation,
    GqlClientCreateShareHistoryRecordMutationVariables,
    GqlClientCreateShareHistoryRecordInput } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { CreateShareHistoryRecordDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

import type { FetchResult } from "@apollo/client";

export const createShareHistoryRecord = (input: GqlClientCreateShareHistoryRecordInput): Promise<FetchResult<GqlClientCreateShareHistoryRecordMutation>> => {
    const { mutate } = getApolloClient();

    return mutate<GqlClientCreateShareHistoryRecordMutation, GqlClientCreateShareHistoryRecordMutationVariables>({
        mutation: CreateShareHistoryRecordDocument,
        variables: {
            input
        }
    });
};
