import type {
    GqlClientEditorAssetMediaUploadedPayload,
    GqlClientEditorAssetMediaUploadedPayloadError,
    GqlClientEditorAssetMediaUploadedPayloadSuccess,
    GqlClientEditorAssetMediaUploadedSubscriptionVariables,
    GqlClientMediaOrigin,
    GqlClientUploadEditorAssetMediaFromUrlInput,
    GqlClientUploadEditorAssetMediaFromUrlMutation,
    GqlClientUploadEditorAssetMediaFromUrlMutationVariables,
    GqlClientUploadEditorAssetMediaInput,
    GqlClientUploadEditorAssetMediaMutation,
    GqlClientUploadEditorAssetMediaMutationVariables,
    GqlClientUploadExternalEditorAssetImageInput,
    GqlClientUploadExternalEditorAssetImageMutation,
    GqlClientUploadExternalEditorAssetImageMutationVariables,
    GqlClientUploadExternalEditorAssetVideoInput,
    GqlClientUploadExternalEditorAssetVideoMutation,
    GqlClientUploadExternalEditorAssetVideoMutationVariables,
    GqlClientUploadOptimizedEditorAssetMediaMutation,
    GqlClientUploadOptimizedEditorAssetMediaMutationVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    GqlClientExternalSubLibrary,
    GqlClientExternalLibrary,
    EditorAssetMediaUploadedDocument,
    UploadEditorAssetMediaDocument,
    UploadEditorAssetMediaFromUrlDocument,
    UploadExternalEditorAssetImageDocument,
    UploadExternalEditorAssetVideoDocument,
    UploadOptimizedEditorAssetMediaDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getApolloClient } from "../../../../apollo";
import type { MutationOptions } from "@apollo/client";
import type {
    AssetEditMetadata,
    BackgroundMutationCallbackFn,
    BackgroundMutationCallbacks,
    BackgroundMutationPayloadResult,
    ProgramId,
    UploadMediaAssetData,
    UploadResponse,
    UploadResponseAsync,
    UploadResult
} from "../../types";
import { processUploadAssetMediaInput } from "../../Utils";
import { getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../../../common/errors";
import { EditorLibraryMediaType } from "../../../../../common/types/asset";
import type { GraphQLError } from "graphql";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import { getEditorAccountId } from "../Account";
import { executeBackgroundMutation } from "../BackgroundMutation";
import type { SubscriptionOptions } from "@apollo/client/core/watchQueryOptions";
import { getProcessedFileIndex, upsertProcessedFile } from "../ProcessedFiles";
import { handleFileUploadError, UploadFileContext } from "../Notification";

export type UploadAssetData = {
    assetId: string,
    title: string,
    assetType: EditorLibraryMediaType,
    downloadSizes: any[]
}

const getUploadedGettyAsset = async (externalAsset: UploadAssetData, programId: ProgramId, mutate, parentFolderId?: string): Promise<UploadMediaAssetData> => {
    if (externalAsset.assetType === EditorLibraryMediaType.Image) {
        const uploadEditorAssetInput: GqlClientUploadExternalEditorAssetImageInput = {
            assetId: externalAsset.assetId,
            libraryType: GqlClientExternalLibrary.GETTY,
            subLibraryType: GqlClientExternalSubLibrary.WHOLLY_OWNED,
            title: externalAsset.title,
            downloadMetadata: externalAsset.downloadSizes.map((size) => {
                return {
                    bytes: String(size.bytes),
                    height: String(size.height),
                    contentType: size.media_type
                };
            }),
            programId,
            parentFolderId
        };
        const options: MutationOptions<GqlClientUploadExternalEditorAssetImageMutation, GqlClientUploadExternalEditorAssetImageMutationVariables> = {
            mutation: UploadExternalEditorAssetImageDocument,
            variables: {
                input: uploadEditorAssetInput
            }
        };
        const { data } = await mutate(options);
        const { id, mediaLocation, mediaUrl, mediaMetadata } = (data as GqlClientUploadExternalEditorAssetImageMutation).uploadExternalEditorAssetImage.editorAssetMedia;
        if (data) {
            return {
                id,
                mediaLocation,
                mediaUrl,
                mediaMetadata
            };
        }
    }
    else if (externalAsset.assetType === EditorLibraryMediaType.Video) {
        const uploadEditorAssetInput: GqlClientUploadExternalEditorAssetVideoInput = {
            assetId: externalAsset.assetId,
            title: externalAsset.title,
            libraryType: GqlClientExternalLibrary.GETTY,
            subLibraryType: GqlClientExternalSubLibrary.WHOLLY_OWNED,
            downloadMetadata: externalAsset.downloadSizes.map((size) => {
                return {
                    bytes: String(size.bytes),
                    contentType: size.content_type,
                    format: size.format,
                    name: size.name
                };
            }),
            programId,
            parentFolderId
        };
        const options: MutationOptions<GqlClientUploadExternalEditorAssetVideoMutation, GqlClientUploadExternalEditorAssetVideoMutationVariables> = {
            mutation: UploadExternalEditorAssetVideoDocument,
            variables: {
                input: uploadEditorAssetInput
            }
        };
        const { data } = await mutate(options);
        const { id, mediaLocation, mediaUrl, mediaMetadata } = (data as GqlClientUploadExternalEditorAssetVideoMutation).uploadExternalEditorAssetVideo.editorAssetMedia;
        if (data) {
            return {
                id,
                mediaLocation,
                mediaUrl,
                mediaMetadata
            };
        }
    }
};

export async function uploadGettyExternalEditorAssetMedia(externalAsset: UploadAssetData, programId: ProgramId, parentFolderId?): UploadResponse {
    const { mutate } = getApolloClient();
    try {
        if (externalAsset) {
            const asset: UploadMediaAssetData = await getUploadedGettyAsset(externalAsset, programId, mutate, parentFolderId);
            return { uploadResult: asset };
        }
    }
    catch (error) {
        const gqlError: GraphQLError = error.graphQLErrors?.[0];
        const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.UnexpectedClientError;

        return { error: new EnhancedError(gqlError || error, sskyErrorCode) };
    }
}

export async function uploadEditorAssetMediaFromUrl(args: GqlClientUploadEditorAssetMediaFromUrlInput): UploadResponse {
    const { mutate } = getApolloClient();
    const shortFilename = args.filenameWithoutExtension.substring(0, 100);
    const updatedArgs = { ...args, filenameWithoutExtension: shortFilename };

    try {
        const options: MutationOptions<GqlClientUploadEditorAssetMediaFromUrlMutation, GqlClientUploadEditorAssetMediaFromUrlMutationVariables> = {
            mutation: UploadEditorAssetMediaFromUrlDocument,
            variables: { input: updatedArgs }
        };
        const { data } = await mutate(options);
        const { id, mediaLocation, mediaUrl, mediaMetadata } = (data as GqlClientUploadEditorAssetMediaFromUrlMutation).uploadEditorAssetMediaFromUrl.editorAssetMedia;
        if (data) {
            const asset = { id, mediaLocation, mediaUrl, mediaMetadata };
            return { uploadResult: asset };
        }
    }
    catch (error) {
        const gqlError: GraphQLError = error.graphQLErrors?.[0];
        const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.UnexpectedClientError;

        return { error: new EnhancedError(gqlError || error, sskyErrorCode) };
    }
}


export async function uploadEditorAssetMedia(
    file: File,
    programId: ProgramId,
    metadata?: AssetEditMetadata,
    parentFolderId?: string, 
    overrideFileLimits = false,
    mediaOrigin?: GqlClientMediaOrigin): UploadResponse {
    const { mutate } = getApolloClient();
    try {
        const { error, fileData } = await processUploadAssetMediaInput(file, ["video", "image"], overrideFileLimits);
        if (error) {
            return { error };
        }

        if (fileData) {
            const filename: string = file.name;
            const uploadEditorAssetInput: GqlClientUploadEditorAssetMediaInput = {
                file,
                filename,
                fileType: fileData.fileType,
                parentMediaId: metadata?.parentMediaId,
                processingData: metadata?.processingData,
                enhancedProcessingData: metadata?.enhancedProcessingData,
                saveEditedMediaToLibrary: metadata?.saveEditedMediaToLibrary,
                programId,
                postUploadingData: metadata?.postUploadingData,
                parentFolderId,
                isInternalSource: overrideFileLimits,
                mediaOrigin
            };

            const options: MutationOptions<GqlClientUploadEditorAssetMediaMutation, GqlClientUploadEditorAssetMediaMutationVariables> = {
                mutation: UploadEditorAssetMediaDocument,
                variables: {
                    input: uploadEditorAssetInput
                }
            };

            const { data } = await mutate(options);

            if (data) {
                const { id, mediaLocation, mediaUrl, mediaMetadata } = (data as GqlClientUploadEditorAssetMediaMutation).uploadEditorAssetMedia.editorAssetMedia;
                const asset: UploadMediaAssetData = {
                    id,
                    mediaLocation,
                    mediaUrl,
                    mediaMetadata
                };

                return { uploadResult: asset };
            }
        }
    }
    catch (error) {
        const gqlError: GraphQLError = error.graphQLErrors?.[0];
        const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.UnexpectedClientError;

        return { error: new EnhancedError(gqlError || error, sskyErrorCode) };
    }
}

export async function uploadOptimizedEditorAssetMedia(
    file: File,
    programId: ProgramId,
    onSuccess?: (fileName: string, fileType: string, uploadResult: UploadResult) => void,
    onError?: (name: string, error?: Error) => void,
    metadata?: AssetEditMetadata,
    parentFolderId?: string
): UploadResponseAsync {
    try {
        const accountId = getEditorAccountId();
        const { error, fileData } = await processUploadAssetMediaInput(file, ["video", "image"]);
        if (error) {
            onError(file.name, error);
            return;
        }

        if (fileData) {
            const filename: string = file.name;
            const uploadEditorAssetInput: GqlClientUploadEditorAssetMediaInput = {
                file,
                filename,
                fileType: fileData.fileType,
                parentMediaId: metadata?.parentMediaId,
                processingData: metadata?.processingData,
                programId,
                parentFolderId
            };

            const options: MutationOptions<GqlClientUploadOptimizedEditorAssetMediaMutation, Omit<GqlClientUploadOptimizedEditorAssetMediaMutationVariables, "mutationId">> = {
                mutation: UploadOptimizedEditorAssetMediaDocument,
                variables: {
                    input: uploadEditorAssetInput
                }
            };

            const onFailureCallback: BackgroundMutationCallbackFn<GqlClientEditorAssetMediaUploadedPayload> = (payloadData: GqlClientEditorAssetMediaUploadedPayloadError) => {
                const { fileName, error } = payloadData;
                if (getProcessedFileIndex(fileName) >= 0) {
                    handleFileUploadError(fileName, new EnhancedError(error, error?.sskyCode), UploadFileContext.MediaAssetLibrary);
                }
                onError?.(fileName, error);
            };

            const onSuccessCallback: BackgroundMutationCallbackFn<GqlClientEditorAssetMediaUploadedPayload> = (payloadData: GqlClientEditorAssetMediaUploadedPayloadSuccess) => {
                const { fileName, data } = payloadData;
                const mediaType = data?.editorAsset?.mediaMetadata?.mediaType;
                if (getProcessedFileIndex(fileName) >= 0) {
                    upsertProcessedFile({ fileName, type: `${mediaType}/*`, status: "success" });
                }
                onSuccess?.(fileName, fileData.fileType, data?.editorAsset);
            };

            const callbacks: BackgroundMutationCallbacks<GqlClientEditorAssetMediaUploadedPayload> = {
                onProgress: null,
                onFailure: onFailureCallback,
                onSuccess: onSuccessCallback
            };

            const subscriptionOptions: SubscriptionOptions<GqlClientEditorAssetMediaUploadedSubscriptionVariables, BackgroundMutationPayloadResult<GqlClientEditorAssetMediaUploadedPayload>> = {
                query: EditorAssetMediaUploadedDocument,
                variables: {
                    accountId
                }
            };

            await executeBackgroundMutation<GqlClientEditorAssetMediaUploadedPayload, GqlClientUploadOptimizedEditorAssetMediaMutation>(options, subscriptionOptions, callbacks);
        }
    }
    catch (error) {
        onError(file.name, error);
        return;
    }
}
