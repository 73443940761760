import { useEffect } from "react";
import type { DataElementId } from "../../../../../common/types/logic";
import type { GqlClientEditorViewerProfileFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useViewerProfiles } from "../index";
import type { ViewerProfileContent, ViewerProfileDataElementValues, ViewerProfileItemValue } from "../../types";
import { getMostRecentlyUpdatedViewerProfileId } from "../../Utils";
import { localEditedViewerProfileVar } from "../../State";
import { useReactiveVar } from "@apollo/client";
import { STATIC_DEFAULT_PROFILE_NAME } from "../../../../../common/commonConst";

export type UseLocalViewerProfile = {
    editedViewerProfileId: GqlClientEditorViewerProfileFragment["id"];
    editedViewerProfileName: GqlClientEditorViewerProfileFragment["name"];
    changeSelectedViewerProfile: (id: GqlClientEditorViewerProfileFragment["id"]) => void;
    editedViewerProfileValues: ViewerProfileDataElementValues;
    setEditedViewerProfileItemValue: (dataElementId: DataElementId, value: ViewerProfileItemValue) => void;
    isDefaultProfile: () => boolean;
}

export const useLocalViewerProfile = (): UseLocalViewerProfile => {
    const { viewerProfiles } = useViewerProfiles();
    const { editedViewerProfileId, editedViewerProfileName, editedViewerProfileValues } = useReactiveVar(localEditedViewerProfileVar);

    useEffect(() => {
        const profileId = getMostRecentlyUpdatedViewerProfileId(viewerProfiles);
        changeSelectedViewerProfile(profileId);
    }, [viewerProfiles.length]);

    const setEditedViewerProfileItemValue = (dataElementId: DataElementId, value: ViewerProfileItemValue): void => {
        localEditedViewerProfileVar({
            editedViewerProfileId,
            editedViewerProfileName,
            editedViewerProfileValues: { ...editedViewerProfileValues, [dataElementId]: value }
        });
    };

    const changeSelectedViewerProfile = (id: GqlClientEditorViewerProfileFragment["id"]) => {
        const viewerProfile = viewerProfiles.find(vp => vp.id === id);
        const initialViewerProfileValues: ViewerProfileDataElementValues = (viewerProfile?.content as ViewerProfileContent)?.items || {};
        localEditedViewerProfileVar({ editedViewerProfileId: id, editedViewerProfileName: viewerProfile?.name, editedViewerProfileValues: initialViewerProfileValues });
    };

    const isDefaultProfile = () => {
        return editedViewerProfileId === null;
    };

    return {
        editedViewerProfileId,
        editedViewerProfileName: editedViewerProfileName || STATIC_DEFAULT_PROFILE_NAME,
        editedViewerProfileValues,
        setEditedViewerProfileItemValue,
        changeSelectedViewerProfile,
        isDefaultProfile
    };
};
