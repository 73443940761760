import { SskyPromise } from "@sundaysky/promise";

export type GenerateFileUploadPromiseFunc = () => Promise<void>;

const MAX_CONCURRENT_DOMAIN_CONNECTIONS = 6;

let lastUploadRequest: Promise<void> = Promise.resolve();

export function uploadConcurrently(uploadFiles: GenerateFileUploadPromiseFunc[]): Promise<void> {
    lastUploadRequest = lastUploadRequest.finally(
        () => SskyPromise.all(uploadFiles, MAX_CONCURRENT_DOMAIN_CONNECTIONS)
    );
    return lastUploadRequest;
}
