import type {
    GqlClientEditorViewerProfileFragment,
    GqlClientLocalEditorViewerProfileQuery,
    GqlClientLocalEditorViewerProfileQueryVariables,
    GqlClientUpdateEditorViewerProfileMutation,
    GqlClientUpdateEditorViewerProfileMutationVariables,
    GqlClientUpdateEditorViewerProfileInput,
    GqlClientEditorProgram,
    GqlClientDeleteEditorViewerProfileMutationVariables,
    GqlClientDeleteEditorViewerProfileMutation,
    GqlClientCreateEditorViewerProfileInput,
    GqlClientCreateEditorViewerProfileMutation,
    GqlClientCreateEditorViewerProfileMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    LocalEditorViewerProfileDocument,
    UpdateEditorViewerProfileDocument,
    DeleteEditorViewerProfileDocument,
    CreateEditorViewerProfileDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getApolloClient } from "../../../../apollo";
import { getSskyErrorCodeFromGqlError } from "../../../../../common/errors";
import type { FetchResult } from "@apollo/client";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import { localEditedViewerProfileVar } from "../../State";

export const getViewerProfile = (viewerProfileId: GqlClientEditorViewerProfileFragment["id"]): GqlClientEditorViewerProfileFragment => {
    if (!viewerProfileId) {
        return null;
    }

    const cachedQuery = getApolloClient().readQuery<GqlClientLocalEditorViewerProfileQuery, GqlClientLocalEditorViewerProfileQueryVariables>({
        query: LocalEditorViewerProfileDocument,
        variables: { viewerProfileId }
    });

    return cachedQuery.localEditorViewerProfile;
};

export type UpdateViewerProfile = {
    viewerProfileId: GqlClientEditorViewerProfileFragment["id"],
    content: GqlClientEditorViewerProfileFragment["content"]
};

export const updateViewerProfile = async ({ viewerProfileId, content }: UpdateViewerProfile): Promise<void> => {
    const remoteViewerProfile = getViewerProfile(viewerProfileId);

    const updateEditorViewerProfileInput: GqlClientUpdateEditorViewerProfileInput = {
        id: viewerProfileId,
        name: remoteViewerProfile.name, //in the future, rename will be available and we will get name from outside
        content,
        updated: remoteViewerProfile.updated
    };

    try {
        await getApolloClient().mutate<GqlClientUpdateEditorViewerProfileMutation, GqlClientUpdateEditorViewerProfileMutationVariables>({
            mutation: UpdateEditorViewerProfileDocument,
            variables: { updateEditorViewerProfileInput }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export type CreateViewerProfile = {
    programId: GqlClientEditorProgram["id"],
    name: GqlClientEditorViewerProfileFragment["name"],
    content: GqlClientEditorViewerProfileFragment["content"]
};

export const createViewerProfile = async ({ programId, name, content }: CreateViewerProfile): Promise<FetchResult> => {
    const createEditorViewerProfileInput: GqlClientCreateEditorViewerProfileInput = {
        program_id: programId,
        name,
        content
    };

    try {
        return (await getApolloClient().mutate<GqlClientCreateEditorViewerProfileMutation, GqlClientCreateEditorViewerProfileMutationVariables>({
            mutation: CreateEditorViewerProfileDocument,
            variables: { createEditorViewerProfileInput }
        }));
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export const deleteViewerProfile = async (viewerProfileId: string): Promise<void> => {
    try {
        const result = await getApolloClient().mutate<GqlClientDeleteEditorViewerProfileMutation, GqlClientDeleteEditorViewerProfileMutationVariables>({
            mutation: DeleteEditorViewerProfileDocument,
            variables:  { input: { id : viewerProfileId } }
        });

        if (result?.errors) {
            throw result?.errors[0];
        }
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export const getLocalViewerProfileValues = () => {
    return localEditedViewerProfileVar()?.editedViewerProfileValues || {};
};
