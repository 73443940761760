import { useReactiveVar } from "@apollo/client";
import { localEditorIsPreviewDialogOpenVar } from "../../State";
import { setSelectedAudioPreview } from "../../Nooks";

export type UseEditorPreviewDialogOpen = {
    isPreviewDialogOpen: boolean;
    setIsPreviewDialogOpen: (isOpen: boolean) => void;
};

export const useEditorPreviewDialogOpen = (): UseEditorPreviewDialogOpen => {
    const isPreviewDialogOpen = useReactiveVar(localEditorIsPreviewDialogOpenVar);

    const setIsPreviewDialogOpen = (isOpen: boolean): void => {
        localEditorIsPreviewDialogOpenVar(isOpen);
        if (isOpen) {
            setSelectedAudioPreview(null);
        }
    };

    return { isPreviewDialogOpen, setIsPreviewDialogOpen };
};
