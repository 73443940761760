import type { GqlClientEditorLibraryNarratorFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientCcNarratorIntent, useEditorLibraryNarratorsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseLibraryNarrators = {
    libraryNarrators: GqlClientEditorLibraryNarratorFragment[];
};

export const useLibraryNarrators = (): UseLibraryNarrators => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();

    const { data } = useEditorLibraryNarratorsQuery({ variables: { programId, programVersionId } });
    const allLibraryNarrators = data?.editorProgram.programVersion.ccLibraryVersion?.narrators ?? [];
    const libraryNarrators = allLibraryNarrators.filter(narrator => narrator.intent !== GqlClientCcNarratorIntent.AVATAR);

    return { libraryNarrators };
};
