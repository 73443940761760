import { makeVar } from "@apollo/client";
import type { FeatureFlags } from "../logic/featureFlags/featureFlagsServices";
import type { UserData } from "../logic/user/UserServices";
import type { MutableRefObject } from "react";
import type { GqlClientEditorAssetFolderFragment, GqlClientEditorAssetLibraryMediaFragment, GqlClientEditorProgram } from "../graphql/graphqlGeneratedTypes/graphqlClient";
import type { EditHelpCenterData, HelpCenterData } from "./editor/types";
import type { VideosAndFoldersVar } from "./newNav/nooks/videosAndFolders";
import type { AssetMediaVar } from "./editor/Nooks";

export const errorMessageVar = makeVar<string | null>(null);
export const userDataVar = makeVar<UserData | null>(null);
export const activeAccountIdVar = makeVar<string | null>(null);
export const activeAccountNameVar = makeVar<string | null>(null);
export const featureFlagsVar = makeVar<FeatureFlags | null>(null);
export const scrollToBottomVar = makeVar<boolean>(false);
export const isBreadCrumbsVisibleVar = makeVar<boolean>(false);
export const mainContainerRefVar = makeVar<MutableRefObject<HTMLElement | undefined>>(undefined);
export const helpCenterDataVar = makeVar<HelpCenterData>(null);
export const helpCenterOpenVar = makeVar(false);

export const editHelpCenterEnabled = makeVar(false);
export const editHelpCenterDataVar = makeVar<EditHelpCenterData | null>(null);
export const editHelpCenterOpenVar = makeVar(false);

export const videosAndFoldersVarInitialState: VideosAndFoldersVar = {
    loading: true,
    usedVideoNames: new Set(),
    videoItemsById: new Map(),
    videosByParentFolderId: new Map(),
    rootFolder: null,
    foldersById: new Map(),
    foldersByParentFolderId: new Map(),
    accountHasPreviewLifecycleStageVideos: false
};
export const videosAndFoldersVar = makeVar<VideosAndFoldersVar>(videosAndFoldersVarInitialState);

export const assetMediaVarInitialState: AssetMediaVar = {
    loading: true,
    assetLibraryMedia: [],
    assetLibraryMediaByGqlMediaId: {},
    assetLibraryMediaByLocalMediaId: {},
    surrogateAssetMediaByMediaId: {},
    surrogateAssetMediaByName: {},
    availableAssetsInYourLibraryById: {},
    availableAssetsForSaveAsById: {},
    hiddenGettyPremiumExpiredMedia: [],
    assetLibraryId: null,
    mediaFolders: [],
    rootFolder: null,
    foldersById: new Map<string, GqlClientEditorAssetFolderFragment>(),
    foldersByParentFolderId: new Map<string, GqlClientEditorAssetFolderFragment[]>(),
    pathFromRootToFoldersById: new Map<string, {id: string; name: string}[]>(),
    mediaByFolderId: new Map<string, GqlClientEditorAssetLibraryMediaFragment[]>()
};
export const assetMediaVar = makeVar<AssetMediaVar>(assetMediaVarInitialState);

export type DeleteEditorVideoParams = Pick<GqlClientEditorProgram, "id" | "name"> & { onDeleteCallback?: () => void }
export const deleteEditorVideoVar = makeVar<DeleteEditorVideoParams>(null);

export const duplicateEditorVideoIdVar = makeVar<string>(null);
