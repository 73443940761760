import type { GqlClientEditorLibraryMusicFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorLibraryMusicQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseLibraryMusic = {
    libraryMusic: GqlClientEditorLibraryMusicFragment[];
};

export const useLibraryMusic = (): UseLibraryMusic => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorLibraryMusicQuery({ variables: { programId, programVersionId } });

    const libraryMusic = data?.editorProgram.programVersion.ccLibraryVersion?.music ?? [];

    return { libraryMusic };
};
