import type { GqlClientEditorAssetLibraryMusicFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorAssetLibraryMusicQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseAssetLibraryMusic = {
    assetLibraryMusic: GqlClientEditorAssetLibraryMusicFragment[];
};

export const useAssetLibraryMusic = (): UseAssetLibraryMusic => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorAssetLibraryMusicQuery({ variables: { programId, programVersionId } });

    const assetLibraryMusic = data?.editorProgram?.programVersion?.assetLibrary?.music ?? [];

    return { assetLibraryMusic };
};
