import type { GqlClientSocialNetworkStatus, GqlClientPostToSocialNetworkInput, GqlClientPostToSocialNetworkMutation,
    GqlClientLoggedInEditorAccountUserSocialNetworksQuery,
    GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    useLoggedInEditorAccountUserSocialNetworksLazyQuery,
    useLoggedInEditorAccountUserSocialNetworksQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { postToSocialNetwork } from "../../Nooks/ShareVideo";
import type { FetchResult, LazyQueryExecFunction } from "@apollo/client";

export type UseEditorAccountUserSocialNetwork = {
    userStatusLoading: boolean;
    userStatusData: GqlClientSocialNetworkStatus[];
    manageSocialNetworksUrl: string;
    postToSocialNetwork: (input: GqlClientPostToSocialNetworkInput) => Promise<FetchResult<GqlClientPostToSocialNetworkMutation>>;
}

export const useEditorAccountUserSocialNetwork = (): UseEditorAccountUserSocialNetwork => {
    const { loading: userStatusLoading, data: userStatusData } = useLoggedInEditorAccountUserSocialNetworksQuery({ fetchPolicy: "no-cache" });

    return {
        userStatusLoading,
        userStatusData: userStatusData?.loggedInEditorAccountUser.socialNetworks?.statuses ?? [],
        manageSocialNetworksUrl: userStatusData?.loggedInEditorAccountUser.socialNetworks?.manageSocialNetworksUrl ?? "",
        postToSocialNetwork
    };
};

export type UseLazyEditorAccountUserSocialNetwork = {
    loading: boolean,
    userSocialNetworksData: GqlClientLoggedInEditorAccountUserSocialNetworksQuery["loggedInEditorAccountUser"]["socialNetworks"];
    getUserSocialNetworks: LazyQueryExecFunction<GqlClientLoggedInEditorAccountUserSocialNetworksQuery, GqlClientLoggedInEditorAccountUserSocialNetworksQueryVariables>
}

export const useLazyEditorAccountUserSocialNetwork = (): UseLazyEditorAccountUserSocialNetwork => {
    const [getUserSocialNetworks, { loading, data }] = useLoggedInEditorAccountUserSocialNetworksLazyQuery({ fetchPolicy: "no-cache" });

    return {
        loading,
        userSocialNetworksData: data?.loggedInEditorAccountUser.socialNetworks,
        getUserSocialNetworks
    };
};
