import React, { Component } from "react";
import PropTypes from "prop-types";
import LogicPopupContainer from "../../../legacyCommon/LogicPopupContainer";
import ModifyDataElementPanel from "./ModifyDataElementPanel";
import {
    ADAVarName,
    ASSET_TYPES,
    DATA_TABLE_OUTPUT_TYPES,
    LOGIC_DATA_TYPES,
    LOGIC_MEDIA_TYPES,
    LOGIC_TYPE,
    VLX_PARAMTYPE
} from "../../../../logic/vlx/consts";
import {
    getAvailableFunctions,
    getMappingTableNameFromValue,
    getMappingTablePropertyFromValue
} from "../../../../logic/vlx/editorLogicUtils";
import { buildActionableData } from "../../../../logic/Logics/ActionableDataUtils";
import {
    filterDataElementsArrayByOrigin,
    filterDataElementsArrayByType,
    getDataElementId
} from "../../../../logic/DataElements/DataElementsManager";
import { cloneDeep } from "lodash";

import "./LogicDataSelector.css";
import HubNotifications from "../../../../logic/common/hubNotifications";
import PopupFooter from "../../../legacyCommon/PopupFooter";
import DataSelectionArea from "./DataSelectionArea";
import IconWithTooltip from "./IconWithTooltip";
import SelectedItemSection from "./SelectedItemSection";
import memoize from "memoize-one";
import { mapNumToLetter, toSentenceCase } from "../../../../../common/generalUtils";
import {
    CreativeDataElementContentTypes,
    DataElementContentTypes,
    DataElementOrigins
} from "../../../../../common/types/dataElement";
import { generateActionableData } from "../../../../logic/builder/editorsContainerLogic/prioritizedListUtils";
import { ActionableDataValueType } from "../../../../../common/types/logic";

class LogicDataSelector extends Component {
    // TODO: move to props!
    actions = getAvailableFunctions();

    state = {
        selectedValue: this.props.value,
        modificationValue: null,
        blockClickOutside: false,
        currentComparableDataElementContentType: null,
        isDataElementCompareMode: false,
        pickedActionIndex: 0,
        pickedParamIndex: null
    };

    handleClickOutside = () => {
        if (!this.state.blockClickOutside) {
            this.props.handleClickOutside();
        }
    };

    getDataFields = memoize((assets, dataElements, dataTables, prioritizedLists) => {
        return {
            assets: [...(assets || []), ...(dataTables || [])],
            dataElements: dataElements,
            prioritizedLists: prioritizedLists
        };
    });

    handleOkClicked = () => {
        const { isDataElementCompareMode, modificationValue, selectedValue } = this.state;
        const currentCloneableValue = isDataElementCompareMode ? modificationValue : selectedValue;
        let clone = currentCloneableValue ? cloneDeep(currentCloneableValue) : null;

        // #BUGFIX: Adding this chunk of code because when the user doesn't add a second parameter for the 'replace'
        // action, the param value is undefined and eval puts a ',' there (in vlx)
        clone.actions && clone.actions.forEach(action => {
            if (action && action.name === "replace" && action.params) {
                action.params.forEach(p => {
                    if (p.value == null) { // p.value is null OR undefined
                        p.value = "";
                    }
                });
            }
        });

        // Filter empty values
        if (clone && clone.actions) {
            clone.actions = clone.actions.filter((a) => a);
        }

        !isDataElementCompareMode
            ? this.props.onChange(clone)
            : this.setState({
                selectedValue: currentCloneableValue,
                modificationValue: null,
                blockClickOutside: false,
                currentComparableDataElementContentType: null,
                isDataElementCompareMode: false,
                pickedActionIndex: 0,
                pickedParamIndex: null
            });
    };

    // Notify with original value
    handleCancelClicked = () => {
        this.props.onChange(this.props.value);
    };

    handleDataElementComparedRemove = () => {
        this.removeSelectedValueDataElementParam();
    };

    handleRemoveClicked = () => {
        const { isDataElementCompareMode } = this.state;
        return isDataElementCompareMode ? this.handleDataElementComparedRemove() : this.props.onChange(null);
    };

    handleRemoveSelectedItem = () => {
        this.setState({ selectedValue: null });
    };

    setValueSetSelected = (value) => {
        let actionableData = this.props.valueSet.find((actionableData) => actionableData.name === value.name);
        this.props.onChange(actionableData);
    };

    setPlaceholderSelected = (placeholder) => {
        let obj = {
            ...placeholder,
            displayName: placeholder.name,
            refName: placeholder.name,
            mediaType: LOGIC_MEDIA_TYPES.String
        };
        if (placeholder.groupId) {
            obj.path = `${placeholder.groupId}.${placeholder.name}.value`;
        }

        return this.props.onChange(obj);
    };

    setAssetSelected = (asset) => {
        let mediaType = asset.mediaType || asset.type;
        let dataType = asset.type === "animation" ? LOGIC_DATA_TYPES.Animation : LOGIC_DATA_TYPES.Asset;
        this.props.onChange(buildActionableData(asset.name, mediaType, dataType));
    };

    setDataElementSelected = (dataElement) => {
        let obj = buildActionableData({
            id: getDataElementId(dataElement),
            mediaType: dataElement.type,
            dataType: LOGIC_DATA_TYPES.DataElement
        });

        if (this.props.selectOnClick) return this.props.onChange(obj);

        if (this.state.isDataElementCompareMode) {
            this.setSelectedValueDataElementParam(this.state.modificationValue, obj, dataElement.displayName);
        }
        else {
            this.setState({ selectedValue: obj });
        }
    };

    setSelectedValueDataElementParam = (modificationValue, actionableDataForParam, dataElementDisplayName) => {
        let modificationValueClone = cloneDeep(modificationValue);
        if (modificationValueClone && modificationValueClone.hasOwnProperty("id")) {
            let initialSelectedValueType;
            switch (actionableDataForParam.mediaType) {
                case DataElementContentTypes.Date:
                    initialSelectedValueType = VLX_PARAMTYPE.Date;
                    break;
                case DataElementContentTypes.Number:
                    initialSelectedValueType = VLX_PARAMTYPE.Numeric;
                    break;
                case DataElementContentTypes.String:
                    initialSelectedValueType = VLX_PARAMTYPE.String;
                    break;
            }
            const { pickedActionIndex, pickedParamIndex } = this.state;
            const paramIndex = Number.isInteger(pickedParamIndex) ?
                pickedParamIndex :
                this.findParamIndexByType(modificationValueClone, pickedActionIndex, initialSelectedValueType);
            modificationValueClone.actions[pickedActionIndex].params[paramIndex] = actionableDataForParam;
            modificationValueClone.actions[pickedActionIndex].params[paramIndex].displayName = dataElementDisplayName;
            this.setState({
                selectedValue: modificationValueClone,
                modificationValue: null,
                currentComparableDataElementContentType: null,
                isDataElementCompareMode: false
            });
        }
    };

    getSelectedValueDataElementParam = (pickedActionIndex, pickedParamIndex) => {
        const { selectedValue } = this.state;
        if (selectedValue && selectedValue.hasOwnProperty("id")) {
            const paramIndex = Number.isInteger(pickedParamIndex) ?
                pickedParamIndex :
                this.findParamIndexByType(selectedValue, pickedActionIndex, null);
            return selectedValue.actions[pickedActionIndex].params[paramIndex];
        }
        return null;
    };

    getDefaultComparedParamValue = (modificationValue, pickedActionIndex, pickedParamIndex) => {
        const action = this.actions.find(({ name }) => name === modificationValue.actions[pickedActionIndex].name);
        const param = action.params[pickedParamIndex];
        return param;
    };

    removeSelectedValueDataElementParam = () => {
        const { currentComparableDataElementContentType } = this.state;
        let defaultComparedParamValue;
        switch (currentComparableDataElementContentType) {
            case DataElementContentTypes.Date:
                defaultComparedParamValue = { type: VLX_PARAMTYPE.Date, text: "toDate" };
                break;
            case DataElementContentTypes.Number:
                defaultComparedParamValue = { type: VLX_PARAMTYPE.Numeric, text: "amount" };
                break;
            default:
                defaultComparedParamValue = {};
                break;
        }
        let modificationValueClone = cloneDeep(this.state.modificationValue);
        if (modificationValueClone && modificationValueClone.hasOwnProperty("id")) {
            const { pickedActionIndex, pickedParamIndex } = this.state;
            let paramIndex = this.findParamIndexByType(modificationValueClone, pickedActionIndex, null);
            if (Number.isInteger(pickedParamIndex)) {
                paramIndex = pickedParamIndex;
                defaultComparedParamValue = this.getDefaultComparedParamValue(modificationValueClone, pickedActionIndex, pickedParamIndex);
            }
            modificationValueClone.actions[pickedActionIndex].params[paramIndex] = defaultComparedParamValue;
            modificationValueClone.actions.splice(pickedActionIndex + 1, modificationValueClone.actions.length);
            this.setState({
                selectedValue: modificationValueClone,
                modificationValue: null,
                currentComparableDataElementContentType: null,
                isDataElementCompareMode: false
            });
        }
    };

    findParamIndexByType = (selectedValue, pickedActionIndex, initialSelectedValueType) => {
        const params = selectedValue.actions[pickedActionIndex].params;
        if (initialSelectedValueType) {
            return params.findIndex((el) => el.type === initialSelectedValueType || el.type === LOGIC_DATA_TYPES.DataElement);
        }
        return params.findIndex((el) => el.type === LOGIC_DATA_TYPES.DataElement);
    };

    setTextElementSelected = (textElement) => {
        this.props.onChange(buildActionableData(textElement.name));
    };

    setMapTableSelected = (mapTableAsset) => {
        let obj = buildActionableData(mapTableAsset.name, LOGIC_DATA_TYPES.MappingTable, LOGIC_DATA_TYPES.MappingTable);
        if (this.props.selectOnClick) return this.onChange(obj);

        this.setState({ selectedValue: obj });
    };

    setPrioritizedListSelected = (prioritizedList) => {
        let obj = buildActionableData({
            id: prioritizedList.id,
            displayName: prioritizedList.name,
            mediaType: LOGIC_TYPE.Prioritized,
            dataType: LOGIC_TYPE.Prioritized
        });

        if (this.props.selectOnClick) return this.onChange(obj);

        this.setState({ selectedValue: obj });
    };

    setDataStructureSelected = (item) => {
        if (item.type === ASSET_TYPES.mappingTable) {
            this.setMapTableSelected(item);
        }
        else if (item.outputType === LOGIC_TYPE.Prioritized) {
            this.setPrioritizedListSelected(item);
        }
    };

    footer = (<PopupFooter handleOkClicked={this.handleOkClicked} handleCancelClicked={this.handleCancelClicked} enterKeyConfirms={true} />);

    handleDeleteAction = (index) => {
        if (this.state.selectedValue.actions.length > index + 1) {
            this.setState({ blockClickOutside: true }, () => {
                HubNotifications.confirm(
                    "Delete Action",
                    "This will delete all following actions of the placeholder. Continue?",
                    () => {
                        this.deleteAction(index);
                        this.setState({ blockClickOutside: false });
                    },
                    () => this.setState({ blockClickOutside: false })
                );
            });
        }
        else {
            this.deleteAction(index);
        }
    };

    deleteAction = (index) => {
        let clone = { ...this.state.selectedValue };

        if (index === 0) {
            clone.actions = null;
        }
        else {
            clone.actions = [...clone.actions.slice(0, index)];
        }

        this.setState({ selectedValue: clone });
    };

    findActionCopy = (filter) => {
        let act = this.actions.find(filter);
        if (!act) return null;

        // Deeply clone the actions
        let actClone = { ...act };
        actClone.params = actClone.params.map((i) => Object.assign({}, i));
        actClone.input = actClone.input.slice(0);

        return actClone;
    };

    handleActionSelected = (index, action) => {
        let clone = {
            ...this.state.selectedValue,
            actions: this.state.selectedValue.actions
        };

        clone.actions[index] = this.findActionCopy((ac) => ac.name === action.value);

        this.setState({ selectedValue: clone });
    };

    handleAddActionClick = () => {
        // Clone selected value
        let clone = { ...this.state.selectedValue };

        clone.actions = clone.actions || [];

        // Push null (since empty strings will fail in dynamo)
        clone.actions = [...clone.actions, null];

        this.setState({ selectedValue: clone });
    };

    handleActionParameterChanged = (actionIdx, paramIdx, value) => {
        let clone = cloneDeep(this.state.selectedValue);
        let act = clone.actions[actionIdx];

        if (!act) return;

        act.params[paramIdx].value = value !== "" ? value : undefined;

        this.setState({ selectedValue: clone });
    };

    handleMapTableItemSelected = (item) => {
        let clone = { ...this.state.selectedValue };
        // Set current value media type accordingly
        clone.mediaType = item.type;
        const tableName = getMappingTableNameFromValue(clone);
        //TODO: clone.value = `${tableName}.${item.name}`; instead of this: --v  so that the table name could be easily extracted:
        clone.name = `${tableName}.${item.name}`;
        clone.id = `${tableName}.${item.id}`;

        this.setState({ selectedValue: clone });
    };

    handlePrioritizedListSlotSelected = (item) => {
        let clone = { ...this.state.selectedValue, ...item };

        this.setState({ selectedValue: clone });
    };

    concatDataStructures = (dataStructuresList) =>
        // mapping tables and prioritized list
        dataStructuresList.reduce((acc, item) => [...(item || []), ...acc], []);

    // Calc how many tab elements were provided
    getNumberOfTabs = () =>
        [
            this.getRelevantNonCreativeDataElements(this.props.dataElements),
            this.getRelevantCreativeDataElements(this.props.dataElements),
            this.props.assets,
            this.props.animations,
            this.getRelevantMappingTablesAndPrioritizedLists(this.props.mappingTables, this.props.prioritizedLists),
            this.props.valueSet,
            this.getRelevantPlaceholders(this.props.placeholders)
        ].filter((arr) => arr && arr.length).length;

    getMapTableTypeFromMediaType = (type) => {
        const { mediaType } = this.props;
        const media = [LOGIC_TYPE.Image, LOGIC_TYPE.Video, LOGIC_TYPE.Audio];

        if (media.includes(mediaType)) {
            return type === DATA_TABLE_OUTPUT_TYPES.Media ? DATA_TABLE_OUTPUT_TYPES.Media : DATA_TABLE_OUTPUT_TYPES.Url;
        }
        if (mediaType === LOGIC_TYPE.Text) return DATA_TABLE_OUTPUT_TYPES.String;
        if (mediaType === LOGIC_TYPE.Color) return DATA_TABLE_OUTPUT_TYPES.Color;
        return "unknown_type";
    };

    isOutputSameAsMediaType = (output) =>
        this.getMapTableTypeFromMediaType(output.type) === output.type || (output.type.title && this.getMapTableTypeFromMediaType(output.type.title.toLowerCase()) === output.type.title.toLowerCase());

    renderMapTableInputSelector = () => {
        const selectedMapTable = this.props.mappingTables.find((table) => table.name === this.state.selectedValue.name);
        let mapTableItems = [];
        if (selectedMapTable && selectedMapTable.mappingTableScheme && selectedMapTable.mappingTableScheme.outputs) {
            mapTableItems = selectedMapTable.mappingTableScheme.outputs
                // Filter by media type
                .filter((output) => output.type && (!this.props.mediaType || this.isOutputSameAsMediaType(output)))
                //Translate for DataSelectionArea
                .map((output) => {
                    const type = output.type.title ? output.type.title.toLowerCase() : output.type;
                    return { id: output.id, title: output.name, name: output.name, type };
                });
        }
        const dataFieldsObj = this.getDataFields(this.props.assets, this.props.dataElements, this.props.mappingTables, this.props.prioritizedLists);
        return (
            <LogicPopupContainer handleClickOutside={this.handleClickOutside} position={this.props.position}>
                <div title="Data Table" data-content-class-name="map-table-entry-selection">
                    <button className="logic-popup-remove-selected" onClick={this.handleRemoveClicked} data-testid="logic-popup-remove-selected" />
                    <SelectedItemSection selectedValue={this.state.selectedValue} dataFields={dataFieldsObj} onSelectedItemRemoveClicked={this.handleRemoveSelectedItem}>
                        <span parent-class-name="sub-title">Choose table output:</span>
                        <DataSelectionArea items={mapTableItems} className="mapping-table" onRemove={this.handleRemoveClicked} onClick={this.handleMapTableItemSelected} />
                    </SelectedItemSection>
                </div>
            </LogicPopupContainer>
        );
    };

    generatePrioritizedListSlotContentList = (prioritizedList, filterType) => {
        let prioritizedListPlaceholders = [];

        const _getPLContentObj = (prioritizedList, slotIdx, placeholder) => {
            const slotCharacter = mapNumToLetter(slotIdx);
            return {
                id: `${prioritizedList.id}---slot${slotCharacter}---${placeholder.name}`,
                name: `${prioritizedList.name} - Slot ${slotCharacter} - ${placeholder.name}`,
                path: `${prioritizedList.name}.slot${slotCharacter}.${placeholder.name}.value`,
                type: ActionableDataValueType.Variable
            };
        };

        prioritizedList.slots.forEach((slot, index) => {
            if (slot.placeholders) {
                slot.placeholders.forEach((placeholder) => {
                    if (placeholder.type === filterType) {
                        prioritizedListPlaceholders.push(_getPLContentObj(prioritizedList, index, placeholder));
                    }
                });
            }
            else if (slot.type === filterType) {
                // slot is a placeholder (not group)
                prioritizedListPlaceholders.push(_getPLContentObj(prioritizedList, index, slot));
            }
        });

        return prioritizedListPlaceholders;
    };

    renderPrioritizedListInputSelector = () => {
        const selectedPrioritizedList = this.props.prioritizedLists && this.props.prioritizedLists.find((pl) => pl.id === this.state.selectedValue.id);
        let prioritizedListSlots = [];
        let prioritizedListPlaceholders = [];
        if (selectedPrioritizedList && selectedPrioritizedList.maxSlots) {
            if (!this.props.isInValue) {
                // generate array of objects with 'Slot A', 'Slot B', 'Slot C', ...
                prioritizedListSlots = Array.from({ length: selectedPrioritizedList.maxSlots }).map((_, index) => {
                    return generateActionableData(selectedPrioritizedList, index);
                });
            }

            if (this.props.inputName === ADAVarName) {
                prioritizedListPlaceholders = this.generatePrioritizedListSlotContentList(selectedPrioritizedList, this.props.mediaType);
            }
        }
        const dataFieldsObj = this.getDataFields(this.props.assets, this.props.dataElements, this.props.mappingTables, this.props.prioritizedLists);
        const calcActionableDataTitle = (item) => {
            if (item.type === ActionableDataValueType.PrioritizedSlot) {
                return `${selectedPrioritizedList.name} - Slot ${mapNumToLetter(item.slotIdx)}`;
            }
            else {
                return item.name;
            }
        };

        return (
            <LogicPopupContainer handleClickOutside={this.handleClickOutside} position={this.props.position}>
                <div title="List" footer={this.footer} data-content-class-name="prioritized-list-entry-selection">
                    <button className="logic-popup-remove-selected" onClick={this.handleRemoveClicked} />
                    <SelectedItemSection selectedValue={this.state.selectedValue} dataFields={dataFieldsObj} onSelectedItemRemoveClicked={this.handleRemoveSelectedItem}>
                        <span parent-class-name="sub-title">Choose Slot:</span>
                        <DataSelectionArea
                            items={prioritizedListSlots.concat(...prioritizedListPlaceholders)}
                            renderItem={calcActionableDataTitle}
                            className="prioritized-list"
                            onRemove={this.handleRemoveClicked}
                            onClick={this.handlePrioritizedListSlotSelected}
                        />
                    </SelectedItemSection>
                </div>
            </LogicPopupContainer>
        );
    };

    renderModifyElementPanel = () => {
        const dataFieldsObj = {
            assets: [...(this.props.assets || []), ...(this.props.mappingTables || [])],
            dataElements: this.props.dataElements || [],
            prioritizedLists: this.props.prioritizedLists
        };
        const { isDataElementCompareMode } = this.state;
        return (
            <LogicPopupContainer handleClickOutside={this.handleClickOutside} position={this.props.position}>
                <div footer={this.footer} title="Choose data element" data-content-class-name="modify-data-element">
                    <button
                        className="logic-popup-remove-selected"
                        data-testid="logic-popup-remove-selected"
                        onClick={!isDataElementCompareMode ? this.handleRemoveClicked : this.handleDataElementComparedRemove}
                    />
                    <ModifyDataElementPanel
                        onRemoveSelectedItem={this.handleRemoveSelectedItem}
                        selectedValue={this.state.selectedValue}
                        onActionSelected={this.handleActionSelected}
                        onDeleteAction={this.handleDeleteAction}
                        onActionParameterChanged={this.handleActionParameterChanged}
                        onAddActionClick={this.handleAddActionClick}
                        dataFields={dataFieldsObj}
                        onDataElementComparison={this.initiateDataElementComparison}
                        isDataElementCompareMode={isDataElementCompareMode}
                    />
                </div>
            </LogicPopupContainer>
        );
    };

    generateTextElementsSelection = () => {
        // mimic tab content. used to inject text elements list to data elements tab.
        return {
            items: this.props.textElements,
            className: "text-elements",
            itemIdentifier: "textElements",
            title: "Text Elements",
            onClick: this.setTextElementSelected,
            renderItem: (item) => <IconWithTooltip iconName={item.iconClass} tooltipText={toSentenceCase(item.title)} title={item.title} />
        };
    };

    renderDataSelection = (settings) => {
        const { title, shortTitle, longTitle, addTextElements } = settings;

        const { isDataElementCompareMode } = this.state;
        // Clone and add onRemove to props
        const props = { ...settings, onRemove: this.handleRemoveClicked };
        delete props.title;
        delete props.shortTitle;
        delete props.longTitle;

        const divTitle = title || (this.getNumberOfTabs() > 1 ? shortTitle : longTitle);

        // Add text elements to elements list if requested (in data elements list)
        if (addTextElements && this.props.textElements && this.props.textElements.length && !isDataElementCompareMode) {
            props.textElements = this.generateTextElementsSelection();
        }

        return (
            <div title={divTitle} data-content-class-name={props.className}>
                <DataSelectionArea {...props} />
            </div>
        );
    };

    initiateDataElementComparison = (dataElementContentType, isElementSelected, pickedActionIndex, pickedParamIndex) => {
        const { selectedValue } = this.state;
        this.setState({
            selectedValue: !isElementSelected ? null : this.getSelectedValueDataElementParam(pickedActionIndex, pickedParamIndex),
            modificationValue: selectedValue,
            currentComparableDataElementContentType: dataElementContentType,
            isDataElementCompareMode: true,
            pickedActionIndex,
            pickedParamIndex
        });
    };

    sortItemsByField = (items, field) => {
        return items && items.slice().sort((item1, item2) => {
            let name1 = item1[field].toLowerCase();
            let name2 = item2[field].toLowerCase();

            return name1.localeCompare(name2);
        });
    };

    getRelevantPlaceholders = (placeholders) => {
        // descriptive transcript limit on FF and only use placeholders as vars here for now.
        return this.props.inputName === ADAVarName ? placeholders : [];
    };

    getRelevantNonCreativeDataElements = (dataElements) => {
        let relevantDataElements = filterDataElementsArrayByOrigin(dataElements, [DataElementOrigins.Derived, DataElementOrigins.System, DataElementOrigins.Feed]);
        if (this.state.currentComparableDataElementContentType) {
            relevantDataElements = filterDataElementsArrayByType(relevantDataElements, [this.state.currentComparableDataElementContentType]);
        }
        return relevantDataElements;
    };

    getRelevantCreativeDataElements = (dataElements) => {
        return filterDataElementsArrayByOrigin(dataElements, [DataElementOrigins.Creative]).filter(
            (cde) => cde.type !== CreativeDataElementContentTypes.Narration
        );
    };

    getRelevantMappingTablesAndPrioritizedLists = (mappingTables, prioritizedLists) => {
        return this.state.isDataElementCompareMode
            ? []
            : this.concatDataStructures([
                this.sortItemsByField(mappingTables, "title"),
                this.sortItemsByField(prioritizedLists, "name")
            ]);
    };

    render() {
        const selected = this.state.selectedValue;
        // If there is a selected item in state render suitable panel
        if (selected && selected.type) {
            if (
                selected.type === LOGIC_DATA_TYPES.MappingTable &&
                // If no property was selected from mapping table render select input panel
                !getMappingTablePropertyFromValue(selected)
            ) {
                return this.renderMapTableInputSelector();
            }
            else if (selected.type === LOGIC_TYPE.Prioritized) {
                return this.renderPrioritizedListInputSelector();
            }
            // Render modify panel for data elements, and mapping table elements
            if (
                selected.type === LOGIC_DATA_TYPES.DataElement ||
                selected.type === LOGIC_DATA_TYPES.MappingTable ||
                selected.type === LOGIC_TYPE.PrioritizedSlot ||
                selected.type === ActionableDataValueType.Variable ||

                // Backward compatibility
                selected.slotPath
            ) {
                return this.renderModifyElementPanel();
            }
        }
        if (this.getNumberOfTabs() === 0) {
            return (
                <LogicPopupContainer handleClickOutside={this.handleClickOutside} position={this.props.position}>
                    <div title=" No elements to show" className="empty-content">
                        😳
                        <p>Try adding assets / data-elements on the panels to the right.</p>
                    </div>
                </LogicPopupContainer>
            );
        }

        let dataElements = this.getRelevantNonCreativeDataElements(this.props.dataElements);
        let creativeDataElements = this.getRelevantCreativeDataElements(this.props.dataElements);
        let placeholders = this.getRelevantPlaceholders(this.props.placeholders);
        let mappingTableAndPrioritizedLists = this.getRelevantMappingTablesAndPrioritizedLists(this.props.mappingTables, this.props.prioritizedLists);

        return (
            <LogicPopupContainer handleClickOutside={this.handleClickOutside} selectedIndex={this.state.selectedValue ? 1 : 0} position={this.props.position}>
                {this.props.valueSet &&
                    !!this.props.valueSet.length &&
                    this.renderDataSelection({
                        //Valueset contains actionable data objects
                        items: this.props.valueSet.map((value) => {
                            return { name: value.name, title: value.displayName };
                        }),
                        className: "value-set",
                        title: "Values",
                        onClick: this.setValueSetSelected
                    })}

                {dataElements &&
                    !!dataElements.length &&
                    this.renderDataSelection({
                        items: this.sortItemsByField(dataElements, "displayName"),
                        className: "data-elements",
                        shortTitle: "Data Elements",
                        longTitle: "Choose data element",
                        itemIdentifier: "dataElement",
                        onClick: this.setDataElementSelected,
                        titleSelector: (item) => item.displayName,
                        addTextElements: true,
                        keySelector: (item) => item.id,
                        renderItem: (item) => <IconWithTooltip iconName={item.type} tooltipText={toSentenceCase(item.type)} title={item.displayName} />
                    })}

                {this.props.assets &&
                    !!this.props.assets.length &&
                    this.renderDataSelection({
                        items: this.sortItemsByField(this.props.assets, "title"),
                        className: "assets",
                        shortTitle: "Assets",
                        longTitle: "Choose an asset",
                        itemIdentifier: "asset-item",
                        onClick: this.setAssetSelected,
                        renderItem: (item) => <IconWithTooltip iconName={item.mediaType} tooltipText={toSentenceCase(item.mediaType)} title={item.title} />
                    })}

                {this.props.animations &&
                    !!this.props.animations.length &&
                    this.renderDataSelection({
                        items: this.sortItemsByField(this.props.animations, "title"),
                        className: "animations",
                        shortTitle: "Animations",
                        longTitle: "Choose an animation",
                        itemIdentifier: "animation-item",
                        onClick: this.setAssetSelected
                    })}

                {mappingTableAndPrioritizedLists &&
                    !!mappingTableAndPrioritizedLists.length &&
                    this.renderDataSelection({
                        items: mappingTableAndPrioritizedLists,
                        className: "data-structures",
                        title: "Data Structures",
                        onClick: this.setDataStructureSelected,
                        renderItem: (item) => {
                            let outputType;
                            let title;
                            if (item.outputType === LOGIC_TYPE.Prioritized) {
                                outputType = "prioritized-list";
                                title = item.name;
                            }
                            else if (item.type === ASSET_TYPES.mappingTable) {
                                outputType = "data-table";
                                title = item.title;
                            }
                            return <IconWithTooltip iconName={outputType} tooltipText={toSentenceCase(outputType)} title={title} />;
                        }
                    })}

                {placeholders &&
                    !!placeholders.length &&
                    this.renderDataSelection({
                        items: this.sortItemsByField(this.props.placeholders, "name"),
                        className: "placeholders",
                        title: "Placeholders",
                        itemIdentifier: "placeholder-item",
                        onClick: this.setPlaceholderSelected,
                        renderItem: (item) => <IconWithTooltip iconName={item.mediaType} tooltipText={toSentenceCase(item.mediaType)} title={item.name} />
                    })}

                {creativeDataElements &&
                    !!creativeDataElements.length &&
                    this.renderDataSelection({
                        items: this.sortItemsByField(creativeDataElements, "displayName"),
                        className: "creative-data-elements",
                        shortTitle: "Content Set Data",
                        longTitle: "Choose content set data",
                        itemIdentifier: "dataElement",
                        onClick: this.setDataElementSelected,
                        titleSelector: (item) => item.displayName,
                        addTextElements: true,
                        keySelector: (item) => item.id,
                        renderItem: (item) => <IconWithTooltip iconName={item.type} tooltipText={toSentenceCase(item.type)} title={item.displayName} />
                    })}
            </LogicPopupContainer>
        );
    }
}

LogicDataSelector.defaultProps = {
    selectOnClick: false
};

LogicDataSelector.propTypes = {
    assets: PropTypes.array,
    dataElements: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.object,
    inputName: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.object),
    selectOnClick: PropTypes.bool,
    handleClickOutside: PropTypes.func.isRequired,
    animations: PropTypes.array,
    mappingTables: PropTypes.array,
    prioritizedLists: PropTypes.array,
    placeholders: PropTypes.array,
    mediaType: PropTypes.string,
    textElements: PropTypes.array,
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    valueSet: PropTypes.array,
    isInValue: PropTypes.bool
};

export default LogicDataSelector;
