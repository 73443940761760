import { useEffect } from "react";
import type { GqlClientProgramEditorPublishedPayloadPending } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useProgramReviewCreatedSubscription,
    GqlClientStatus,
    useProgramEditorPublishedSubscription,
    useProgramEmbedVideoCodeUpdatedSubscription,
    useProgramLandingPageUpdatedSubscription,
    useProgramViewerProfilesUpdatedSubscription
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

import { getEditorPublishState, setEditorPublishState } from "../../Nooks";

export const useEditorProgramSubscriptions = (programId: string): void => {

    useEffect(() => {
        setEditorPublishState(null);
    }, [programId]);

    useProgramLandingPageUpdatedSubscription({
        variables: { programId }
    });

    useProgramEmbedVideoCodeUpdatedSubscription({
        variables: { programId }
    });

    useProgramEditorPublishedSubscription({
        variables: { programId },
        onData: (options) => {
            const programEditorPublished = options.data.data.programEditorPublished;
            if (programEditorPublished.status === GqlClientStatus.PENDING) {
                const { progress } = (programEditorPublished as GqlClientProgramEditorPublishedPayloadPending);
                setEditorPublishState({
                    publishStatus: GqlClientStatus.PENDING,
                    progress: Math.round(progress)
                });
            }
            else if (programEditorPublished.status === GqlClientStatus.KEEP_ALIVE) {
                const editorPublishState = getEditorPublishState();
                if (editorPublishState?.publishStatus !== GqlClientStatus.PENDING) {
                    setEditorPublishState({
                        publishStatus: GqlClientStatus.PENDING,
                        progress: 5
                    });
                }
            }
            else {
                setEditorPublishState(null);
            }
        }
    });

    useProgramViewerProfilesUpdatedSubscription({
        variables: { programId }
    });

    useProgramReviewCreatedSubscription({
        variables: { programId }
    });
};
