import { EDITING_MODE } from "../logic/common/Consts";
import PropTypes from "prop-types";

// JSX prop type
export const BuilderModeRetrieverPropType = PropTypes.shape({
    updateMode: PropTypes.func.isRequired,
    getMode: PropTypes.func.isRequired,
    resetMode: PropTypes.func.isRequired
});

// TSX prop type
export interface BuilderModeRetrieverInterface {
    updateMode: (mode: string) => void;
    getMode: () => string;
    resetMode: () => void;
}

export class BuilderModeRetriever implements BuilderModeRetrieverInterface {
    builderMode: string;

    constructor() {
        this.builderMode = EDITING_MODE;
    }

    updateMode(mode: string) {
        this.builderMode = mode;
    }

    getMode(): string {
        return this.builderMode;
    }

    resetMode() {
        this.builderMode = EDITING_MODE;
    }
}

export default BuilderModeRetriever;
