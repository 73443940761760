import { useReactiveVar } from "@apollo/client";
import type { NotificationData } from "../../types";
import { localEditorNotificationDataVar } from "../../State";
import { closeNotification } from "../../Nooks";

export type UseEditorNotification = {
    notificationData: NotificationData & { isOpen: boolean };
    closeNotification: () => void;
};

export const useEditorNotification = (): UseEditorNotification => {
    const notificationData = useReactiveVar(localEditorNotificationDataVar);

    return {
        notificationData,
        closeNotification
    };
};
