import type { NarratorId, ProgramVersionId } from "../../types";
import { setProjectSelectedNarrator } from "../../Nooks";
import type { GqlClientEditorLibraryNarratorFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorSelectedNarratorQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseProjectSelectedNarrator = {
    projectSelectedNarrator: GqlClientEditorLibraryNarratorFragment;
    setProjectSelectedNarrator: (
        programVersionId: ProgramVersionId,
        selectedNarratorId: NarratorId
    ) => Promise<void>;
};

export const useProjectSelectedNarrator = (): UseProjectSelectedNarrator => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorSelectedNarratorQuery({ variables: { programId, programVersionId } });

    const projectSelectedNarrator = data?.editorProgram?.programVersion?.selectedNarrator ?? null;

    return { projectSelectedNarrator, setProjectSelectedNarrator };
};
