import { GqlClientStatus, useProgramEditorPublishedSubscription } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { PublishState } from "../../types";
import { useState } from "react";

export const useApproveVideoStatus = (programId: string): PublishState => {

    const [videoPublishState, setVideoPublishState] = useState<PublishState | null>(null);

    useProgramEditorPublishedSubscription({
        variables: { programId },
        onData: (options) => {
            const programEditorPublished = options.data.data.programEditorPublished;
            if (programEditorPublished.status === GqlClientStatus.PENDING) {
                setVideoPublishState({
                    publishStatus: GqlClientStatus.PENDING,
                    progress: 0 //todo?
                });
            }
            else if (programEditorPublished.status !== GqlClientStatus.KEEP_ALIVE) {
                setVideoPublishState(null);
            }
        }
    });

    return videoPublishState;
};
