import type { FeatureFlagId } from "@sundaysky/smartvideo-hub-config";
import UrlUtils from "../../../logic/common/urlUtils";
import { getFeatureFlags } from "./model";
import { getAccountIdFromPath } from "../../../logic/common/routingUtils";


export const isFeatureEnabled = (
    featureFlagId: FeatureFlagId,
    overridingProgramId?: string
) => {
    const featureFlags = getFeatureFlags();
    const featureFlagObj = featureFlags && featureFlags[featureFlagId];
    if (!featureFlagObj) {
        return false;
    }

    const accountId = getAccountIdFromPath();
    const isAccountExcluded = featureFlagObj.excludedAccounts.some(
        (account) => account.accountId === accountId
    );

    if (isAccountExcluded) {
        return !(featureFlagObj.defaultValue);
    }

    const { program: programIdFromUrl } = UrlUtils.getAccountAndProgramFromPath();
    const programId = overridingProgramId ?? programIdFromUrl;
    const programKey = `${accountId}/${programId}`;
    const isProgramExcluded = featureFlagObj.excludedPrograms.some(
        (program) => (
            (program as unknown as string) === programKey || // this check is for backward compatibility, when programs were saved as plain string
                program.dynamoUniqueId === programKey ||
                program.postgresUniqueId === programKey
        )
    );

    return programId && isProgramExcluded
        ? !featureFlagObj.defaultValue
        : featureFlagObj.defaultValue;

};
