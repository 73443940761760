import type { FetchResult, MutationOptions } from "@apollo/client";
import type { BackgroundMutationCallbackFn, BackgroundMutationCallbacks, BackgroundMutationPayloadResult, ProgramId, ProgramVersionId, SceneId } from "../../types";
import { localEditorSelectedFramePerSceneVar } from "../../State";
import { IGNORE_UPDATED } from "../../../../logic/common/Consts";
import { getApolloClient } from "../../../../apollo";
import { getProjectLineup, getSelectedSceneId, setIsSelectedSceneIsNew, setSelectedPlaceholderId, setSelectedSceneId } from "../Project";
import type {
    GqlClientCreateEditorScenesFromPromptMutation,
    GqlClientCreateEditorScenesFromPromptMutationVariables,
    GqlClientCreateEditorScenesInput,
    GqlClientCreateEditorScenesMutation,
    GqlClientCreateEditorScenesMutationVariables,
    GqlClientDeleteEditorSceneInput,
    GqlClientDeleteEditorSceneMutation,
    GqlClientDeleteEditorSceneMutationVariables,
    GqlClientDuplicateEditorSceneInLineupInput,
    GqlClientDuplicateEditorSceneInLineupMutation,
    GqlClientDuplicateEditorSceneInLineupMutationVariables,
    GqlClientEditorSceneColorCombination,
    GqlClientEditorSceneFragment,
    GqlClientEditorScenesQuery,
    GqlClientEditorScenesQueryVariables,
    GqlClientEditorSceneWithoutCcDataFragment,
    GqlClientLocalEditorSceneQuery,
    GqlClientLocalEditorSceneQueryVariables,
    GqlClientLocalEditorSceneWithoutCcDataQuery,
    GqlClientLocalEditorSceneWithoutCcDataQueryVariables,
    GqlClientSceneFromPromptCreatedPayload,
    GqlClientSceneFromPromptCreatedPayloadError,
    GqlClientSceneFromPromptCreatedPayloadPending,
    GqlClientSceneFromPromptCreatedPayloadSuccess, GqlClientSceneFromPromptCreatedSubscriptionVariables,
    GqlClientUpdateEditorSceneInput,
    GqlClientUpdateEditorSceneMutation,
    GqlClientUpdateEditorSceneMutationVariables,
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeInput,
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation,
    GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables,
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeInput,
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation,
    GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateEditorScenesFromPromptDocument, SceneFromPromptCreatedDocument
    ,
    CreateEditorScenesDocument,
    DeleteEditorSceneDocument,
    DuplicateEditorSceneInLineupDocument,
    EditorScenesDocument,
    LocalEditorSceneDocument,
    LocalEditorSceneWithoutCcDataDocument,
    UpdateEditorSceneDocument,
    UpdateEditorSceneSelectedAnimatedWireframeDocument,
    UpdateScenePlaceholdersAndAnimatedWireframeDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

import { getDuplicateName } from "../../../../../common/editorUtils";
import { v4 as uuid } from "uuid";
import type { SubscriptionOptions } from "@apollo/client/core/watchQueryOptions";
import { executeBackgroundMutation } from "../BackgroundMutation";
import { reportPending } from "../LivePreview";
import { PendoService } from "../../../pendoService";

export const addScenes = async (
    programVersionId: ProgramVersionId,
    animatedWireframeId: string,
    colorCombination: GqlClientEditorSceneColorCombination,
    lineupIndex: number
): Promise<GqlClientCreateEditorScenesMutation> => {
    const { mutate } = getApolloClient();

    const input: GqlClientCreateEditorScenesInput = {
        editorProgramVersionId: programVersionId,
        editorScenes: [
            {
                ccAnimatedWireframeId: animatedWireframeId,
                colorCombination
            }
        ],
        lineupIndex
    };

    const mutationOutput = await mutate<GqlClientCreateEditorScenesMutation, GqlClientCreateEditorScenesMutationVariables>({
        mutation: CreateEditorScenesDocument,
        variables: { input }
    });

    const targetSceneId = mutationOutput.data.createEditorScenes.editorScenes[0].id;
    selectScene(targetSceneId);
    return mutationOutput.data;
};

export const addSceneFromPrompt = async (
    programVersionId: ProgramVersionId,
    prompt: string,
    lineupIndex: number,
    onProgress: BackgroundMutationCallbackFn<GqlClientSceneFromPromptCreatedPayloadPending>,
    onFailure: BackgroundMutationCallbackFn<GqlClientSceneFromPromptCreatedPayloadError>,
    onSuccess: BackgroundMutationCallbackFn<GqlClientSceneFromPromptCreatedPayloadSuccess>
): Promise<FetchResult<GqlClientCreateEditorScenesFromPromptMutation>> => {

    try {
        const mutationId = uuid();

        const mutationVariables: GqlClientCreateEditorScenesFromPromptMutationVariables = {
            input: {
                editorProgramVersionId: programVersionId,
                prompt,
                lineupIndex
            },
            mutationId
        };

        const options: MutationOptions<GqlClientCreateEditorScenesFromPromptMutation, GqlClientCreateEditorScenesFromPromptMutationVariables> = {
            mutation: CreateEditorScenesFromPromptDocument,
            variables: mutationVariables
        };

        const callbacks: BackgroundMutationCallbacks<GqlClientSceneFromPromptCreatedPayload> = {
            onProgress,
            onFailure,
            onSuccess
        };

        const subscriptionOptions: SubscriptionOptions<GqlClientSceneFromPromptCreatedSubscriptionVariables, BackgroundMutationPayloadResult<GqlClientSceneFromPromptCreatedPayload>> = {
            query: SceneFromPromptCreatedDocument,
            variables: { mutationId }
        };

        return executeBackgroundMutation<GqlClientSceneFromPromptCreatedPayload, GqlClientCreateEditorScenesFromPromptMutation>(options, subscriptionOptions, callbacks, mutationId);
    }
    catch (err) {
        // ignore
    }
};

export const duplicateSceneInLineup = async (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    sourceSceneId: string,
    sourceSceneName: string
): Promise<GqlClientDuplicateEditorSceneInLineupMutation["duplicateEditorSceneInLineup"]["editorScene"]> => {
    const { mutate } = getApolloClient();

    const livePreviewPendingId = uuid();
    reportPending(livePreviewPendingId, true);

    const projectLineup = getProjectLineup(programId, programVersionId);
    const sourceSceneIndex = projectLineup.findIndex(sceneId => sceneId === sourceSceneId);

    const scenes = getScenes(programId, programVersionId);
    const sceneNames: string[] = scenes.map(scene => scene.name.toLowerCase());
    const targetSceneName = getDuplicateName(sourceSceneName, sceneNames);

    const input: GqlClientDuplicateEditorSceneInLineupInput = {
        sourceSceneId,
        targetSceneLineupIndex: sourceSceneIndex + 1,
        targetSceneName
    };

    const { data: { duplicateEditorSceneInLineup: { editorScene } } } = await mutate<GqlClientDuplicateEditorSceneInLineupMutation, GqlClientDuplicateEditorSceneInLineupMutationVariables>({
        mutation: DuplicateEditorSceneInLineupDocument,
        variables: { input }
    });

    const targetSceneId = editorScene.id;
    selectScene(targetSceneId);


    reportPending(livePreviewPendingId, false);

    PendoService.getInstance().trackEvent("Duplicate scene", {
        originVideoId: programId,
        originSceneId: sourceSceneId,
        targetVideoId: programId,
        targetSceneId: editorScene.id || ""
    });

    return editorScene;
};

export const getScenes = (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
): GqlClientEditorSceneFragment[] => {
    const cachedQuery = getApolloClient().cache.readQuery<GqlClientEditorScenesQuery, GqlClientEditorScenesQueryVariables>({
        query: EditorScenesDocument,
        variables: { programId, programVersionId }
    });
    return cachedQuery.editorProgram.programVersion.scenes;
};

export const removeScene = (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    sceneId: SceneId
): Promise<void> => {
    const projectSelectedSceneId = getSelectedSceneId();

    const projectLineup = getProjectLineup(programId, programVersionId);
    const selectedSceneIndex = projectLineup.findIndex((sceneId) => sceneId === projectSelectedSceneId);
    const newProjectLineup = projectLineup.filter((lineupSceneId) => lineupSceneId !== sceneId);

    // Update selected scene id, if needed
    if (sceneId === projectSelectedSceneId) {
        const newSelectedSceneIndex = Math.min(selectedSceneIndex, newProjectLineup.length - 1); // if the last scene was removed, keep last scene selected
        const newSelectedSceneId = newProjectLineup[newSelectedSceneIndex] || null; // if the only scene in the lineup was removed, set 'null'
        selectScene(newSelectedSceneId);
    }

    // Call "delete scene" (it'll call a mutation that takes care of deleting the scene from both the lineup and the DB)
    return deleteScene(sceneId);
};

export const deleteScene = async (
    sceneId: SceneId
): Promise<void> => {
    try {
        const { mutate } = getApolloClient();

        const input: GqlClientDeleteEditorSceneInput = {
            sceneId
        };
        let options: MutationOptions<GqlClientDeleteEditorSceneMutation, GqlClientDeleteEditorSceneMutationVariables> = {
            mutation: DeleteEditorSceneDocument,
            variables: {
                input
            }
        };

        // The mutation response contains the programVersion with the updated lineup, which will cause the Apollo cache to update, and thereby the scene lineup to re-render correctly
        await mutate(options);
    }
    catch (e) {
        // ignore
    }
};

export const selectScene = (sceneId: SceneId, isNewScene = false): void => {
    setIsSelectedSceneIsNew(isNewScene);
    setSelectedSceneId(sceneId);
    setSelectedPlaceholderId(null);
};

export const selectFrame = (sceneId: SceneId, frameIndex: number): void => {
    localEditorSelectedFramePerSceneVar({
        ...localEditorSelectedFramePerSceneVar(),
        [sceneId]: frameIndex
    });
    setSelectedPlaceholderId(null);
};

export const getScene = (sceneId: SceneId): GqlClientEditorSceneFragment | null => {
    if (!sceneId) {
        return null;
    }

    const cachedQuery = getApolloClient().cache.readQuery<GqlClientLocalEditorSceneQuery, GqlClientLocalEditorSceneQueryVariables>({
        query: LocalEditorSceneDocument,
        variables: { sceneId }
    });
    return cachedQuery.localEditorScene;
};

export const getSceneWithoutCcData = (sceneId: SceneId): GqlClientEditorSceneWithoutCcDataFragment | null => {
    if (!sceneId) {
        return null;
    }

    const cachedQuery = getApolloClient().cache.readQuery<GqlClientLocalEditorSceneWithoutCcDataQuery, GqlClientLocalEditorSceneWithoutCcDataQueryVariables>({
        query: LocalEditorSceneWithoutCcDataDocument,
        variables: { sceneId }
    });
    return cachedQuery.localEditorScene;
};

export const updateScene = (updateSceneInput: Omit<GqlClientUpdateEditorSceneInput, "updated">): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdateEditorSceneMutation, GqlClientUpdateEditorSceneMutationVariables>({
        mutation: UpdateEditorSceneDocument,
        variables: {
            updateEditorSceneInput: {
                id: updateSceneInput.id,
                name: updateSceneInput.name,
                description: updateSceneInput.description,
                skipSceneMode: updateSceneInput.skipSceneMode,
                skipSceneAudience: updateSceneInput.skipSceneAudience,
                colorCombination: updateSceneInput.colorCombination,
                buttonAlignment: updateSceneInput.buttonAlignment,
                minimumDuration: updateSceneInput.minimumDuration,
                hasTransition: updateSceneInput.hasTransition,
                updated: IGNORE_UPDATED
            }
        }
    });
};

export const updateScenePlaceholdersAndAnimatedWireframe = (input: Omit<GqlClientUpdateScenePlaceholdersAndAnimatedWireframeInput, "updated">) => {
    const client = getApolloClient();

    return client.mutate<GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutation, GqlClientUpdateScenePlaceholdersAndAnimatedWireframeMutationVariables>({
        mutation: UpdateScenePlaceholdersAndAnimatedWireframeDocument,
        variables: {
            input: { ...input, updated: IGNORE_UPDATED }
        }
    }).catch(() => {});
};

export const selectLayout = (input: Omit<GqlClientUpdateEditorSceneSelectedAnimatedWireframeInput, "updated">): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutation, GqlClientUpdateEditorSceneSelectedAnimatedWireframeMutationVariables>({
        mutation: UpdateEditorSceneSelectedAnimatedWireframeDocument,
        variables: {
            input: {
                sceneId: input.sceneId,
                themeId: input.themeId,
                animatedWireframeId: input.animatedWireframeId,
                updated: IGNORE_UPDATED
            }
        }
    });
};
