import {
    useAccountFontLibraryChangedSubscription,
    useAccountMediaAssetLibraryChangedSubscription,
    useAccountMusicLibraryChangedSubscription,
    useAccountServiceConnectionsChangedSubscription,
    useEditorAssetEditedSubscription,
    useEditorAssetMediaUploadedSubscription,
    useAccountAvatarLibraryChangedSubscription
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";


export const useEditorMediaLibrarySubscriptions = (accountId: string): void => {

    useEditorAssetEditedSubscription({
        variables: { accountId }
    });

    useEditorAssetMediaUploadedSubscription({
        variables: { accountId }
    });

    useAccountMediaAssetLibraryChangedSubscription({
        variables: {}
    });

    useAccountFontLibraryChangedSubscription({
        variables: {}
    });
    useAccountMusicLibraryChangedSubscription({
        variables: {}
    });

    useAccountServiceConnectionsChangedSubscription({
        variables: {}
    });

    useAccountAvatarLibraryChangedSubscription({
        variables: {}
    });
};
