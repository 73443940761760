import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientPostToSocialNetworkInput,
    GqlClientPostToSocialNetworkMutation,
    GqlClientPostToSocialNetworkMutationVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    PostToSocialNetworkDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { FetchResult } from "@apollo/client";

export const postToSocialNetwork = (input: GqlClientPostToSocialNetworkInput): Promise<FetchResult<GqlClientPostToSocialNetworkMutation>> => {
    const { mutate } = getApolloClient();

    return mutate<GqlClientPostToSocialNetworkMutation, GqlClientPostToSocialNetworkMutationVariables>({
        mutation: PostToSocialNetworkDocument,
        variables: {
            input
        }
    });
};
