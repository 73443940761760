import type { TypePolicies } from "@apollo/client";
import type { FieldPolicy } from "@apollo/client/cache/inmemory/policies";
import type {
    GqlClientAcquiaDamAssetsQuery,
    GqlClientAcquiaDamAssetsQueryVariables,
    GqlClientEditorGettyMediaQuery,
    GqlClientEditorGettyMediaQueryVariables, GqlClientEditorNarrationDurationQuery
} from "../graphql/graphqlGeneratedTypes/graphqlClient";
import { ACQUIA_PAGE_SIZE, GETTY_PAGE_SIZE } from "../../common/commonConst";

/**
 * On the Editor initial load, we make a query to fetch all scenes:
 *  editorProgram(id: $programId) {
        programVersion(id: $programVersionId) {
            scenes {
                ...EditorScene
            }
        }
    }
 * But in some hooks we wish to get a single scene by id:
 *  editorScene(id: $sceneId) @client {
        ...EditorScene
    }
}
 * So, we need to tell the cache that whenever someone asks for scene(id), it should first look if it's already cached under a different reference.
 * We can do that with typePolicies.
 *
 * same goes for placeholders :)
 *
 * More info: https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
 * **/

export const typePolicies: TypePolicies = {
    Query: {
        fields: {
            localEditorScene(_, { args, toReference }) {
                return toReference({
                    __typename: "EditorScene",
                    id: args.id
                });
            },
            localEditorPlaceholder(_, { args, toReference }) {
                return toReference({
                    __typename: "EditorPlaceholder",
                    id: args.id
                });
            },
            localEditorBrand(_, { args, toReference }) {
                return toReference({
                    __typename: "BrandConfigurationCollection",
                    id: args.id
                });
            },
            localEditorViewerProfile(_, { args, toReference }) {
                return toReference({
                    __typename: "ViewerProfile",
                    id: args.id
                });
            },
            userSettings(_, { args, toReference }) {
                return toReference({
                    __typename: "UserSettings",
                    id: args.id
                });
            },
            storyTemplate(_, { args, toReference }) {
                return toReference({
                    __typename: "EditorApplication",
                    id: args.programVersionId
                });
            },
            acquiaAssets: {
                keyArgs: [
                    "categoryPath",
                    "searchTerm",
                    "sortBy",
                    "sortDirection",
                    "mediaType",
                    "orientation"
                ],
                merge(existing, incoming, { args }) {
                    const mergedAssets = existing?.assets
                        ? existing.assets.slice(0)
                        : [];

                    const { pageNumber } = (args as GqlClientAcquiaDamAssetsQueryVariables);
                    if (incoming?.assets) {
                        for (let i = 0; i < incoming.assets.length; ++i) {
                            mergedAssets[pageNumber * ACQUIA_PAGE_SIZE + i] = incoming.assets[i];
                        }
                    }

                    return {
                        __typename: "AcquiaAssetsOutput",
                        resultCount: (
                            incoming?.resultCount
                            ?? existing?.resultCount
                            ?? 0
                        ),
                        assets: mergedAssets
                    };
                }
            } satisfies FieldPolicy<GqlClientAcquiaDamAssetsQuery["acquiaAssets"]>,
            gettySearchMetadata: {
                keyArgs: [
                    "includeImages",
                    "includeVideos",
                    "searchTerm",
                    "orientation",
                    "minClipDuration",
                    "maxClipDuration",
                    "sortOrder"
                ],
                merge(existing, incoming, { args }) {
                    const mergedAssets = existing?.assets
                        ? existing.assets.slice(0)
                        : [];

                    const { pageNumber } = (args as GqlClientEditorGettyMediaQueryVariables);
                    if (incoming?.assets) {
                        for (let i = 0; i < incoming.assets.length; ++i) {
                            mergedAssets[pageNumber * GETTY_PAGE_SIZE + i] = incoming.assets[i];
                        }
                    }

                    return {
                        __typename: "GettySearchMetadataOutput",
                        resultCount: (
                            incoming?.resultCount
                            ?? existing?.resultCount
                            ?? 0
                        ),
                        assets: mergedAssets
                    };
                }
            } satisfies FieldPolicy<GqlClientEditorGettyMediaQuery["gettySearchMetadata"]>,
            editorNarrationDuration: {
                merge(existing, incoming) {
                    if (incoming?.duration && incoming?.isExact) {
                        return incoming;
                    }
                    else {
                        return existing;
                    }
                }
            } satisfies FieldPolicy<GqlClientEditorNarrationDurationQuery["editorNarrationDuration"]>
        }
    }
};
