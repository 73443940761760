import type { Libraries, LibraryOpener, ProgramId, ProgramVersionId, ThemeId } from "../../types";
import { findEntityById } from "../../Utils";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientEditorLibraryThemeFragment,
    GqlClientEditorLibraryThemesQuery,
    GqlClientEditorLibraryThemesQueryVariables,
    GqlClientEditorProgramDataConnectorQuery,
    GqlClientEditorProgramDataConnectorQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EditorLibraryThemesDocument, EditorProgramDataConnectorDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { localEditorSelectedLibraryVar } from "../../State";

export const getLibraryTheme = (
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    themeId: ThemeId
): GqlClientEditorLibraryThemeFragment => {
    const { editorProgram } = getApolloClient().cache.readQuery<GqlClientEditorLibraryThemesQuery, GqlClientEditorLibraryThemesQueryVariables>({
        query: EditorLibraryThemesDocument,
        variables: {
            programId,
            programVersionId
        }
    });

    const libraryThemes = editorProgram.programVersion.ccLibraryVersion.themes;

    return findEntityById<GqlClientEditorLibraryThemeFragment>(libraryThemes, themeId);
};

export const getAccountDataLibraryForProgram = (
    programId: ProgramId,
    programVersionId: ProgramVersionId
) => {
    const cachedQuery = getApolloClient().cache.readQuery<GqlClientEditorProgramDataConnectorQuery, GqlClientEditorProgramDataConnectorQueryVariables>({
        query: EditorProgramDataConnectorDocument,
        variables: {
            programId,
            programVersionId
        }
    });

    return cachedQuery?.editorProgram?.programVersion?.accountDataLibraryVersion;
};

export const openLibrary = (library: Libraries, opener: LibraryOpener): void => {
    localEditorSelectedLibraryVar({ library, opener });
};

