import { useEffect, useState } from "react";
import TimeUtils from "../../../../logic/common/timeUtils";
import moment from "moment";
import type { GqlClientEditorProgramVersion } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramVersionLastEditedQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useActiveAccount } from "../../../newNav/hooks/model";
import { getLocalEditorUserDetails } from "../../Nooks";
import { useEditorLibrariesVersionSaved } from "../UseEditorLibrariesVersionSaved";
import { useEditorProgramVersionAndBrandCollectionSubscription, useEditorProgramVersionChangedSubscriptions } from "../UseEditorSubscriptions";

export type UseEditorProgramVersionSaved = {
    savedTimeDate?: Date;
    readableSavedTimeDate?: string;
    savedBy?: string;
    isSaving: boolean;
};

type EditorProgramSaved = {
    savedTimeDate: Date;
    savedBy: string;
}

type EditorProgramVersionLastEditedInfo = Pick<GqlClientEditorProgramVersion, "id" | "anyUpdated" | "anyUpdatedBy">

const getReadableTimeDate = (date: Date):string => {
    if (moment(date).isSame(moment(), "day")) {
        return TimeUtils.getDesignSystemReadableTimeDate(date.getTime());
    }
    return TimeUtils.getDesignSystemReadableTimeDate(date.getTime());
};
/*
Program version last saved should be the latest of program, brand configuration or data library changes.
 */
const calcEditorProgramVersionSaved = (editorProgramVersion: EditorProgramVersionLastEditedInfo, librariesTimeSaved: Date, librariesSavedBy: string): EditorProgramSaved => {

    const programVersionTimeSaved = new Date(editorProgramVersion.anyUpdated);
    if (!librariesTimeSaved || programVersionTimeSaved.getTime() > librariesTimeSaved.getTime()) {
        return {
            savedTimeDate: programVersionTimeSaved,
            savedBy: editorProgramVersion.anyUpdatedBy
        };
    }
    return {
        savedTimeDate: librariesTimeSaved,
        savedBy: librariesSavedBy
    };
};

export const useEditorProgramVersionSaved = (programId: string, programVersionId: string): UseEditorProgramVersionSaved => {
    const [isSaving, setIsSaving] = useState(false);
    const { accountId } = useActiveAccount();
    const { data, loading } = useEditorProgramVersionLastEditedQuery(
        { variables: { programId, programVersionId }, skip: !programId || !programVersionId
        });
    const programVersion = data?.editorProgram.programVersion;
    const { librariesSavedTimeDate, librariesSavedBy } = useEditorLibrariesVersionSaved(programId, programVersionId, loading);
    const [editorProgramSaved, setEditorProgramSaved] = useState<EditorProgramSaved | null>(
        programVersion ? calcEditorProgramVersionSaved(programVersion, librariesSavedTimeDate, librariesSavedBy) : null
    );
    useEditorProgramVersionAndBrandCollectionSubscription(accountId, programVersionId);
    useEditorProgramVersionChangedSubscriptions(programVersionId);
    const readableSavedTimeDate = editorProgramSaved ? getReadableTimeDate(editorProgramSaved.savedTimeDate) : "";

    const updateEditorProgramVersionSaved = (programVersion: EditorProgramVersionLastEditedInfo) => {
        const updatedEditorProgramSaved = calcEditorProgramVersionSaved(programVersion, librariesSavedTimeDate, librariesSavedBy);
        if (editorProgramSaved?.savedTimeDate.getTime() !== updatedEditorProgramSaved.savedTimeDate.getTime()) {
            const localUserName = getLocalEditorUserDetails();
            //we don't want to show the saving indication if the user is not the one who saved.
            //we don't want to show the saving indication if the local editor saved indication is not yet initialized.
            if (editorProgramSaved && localUserName === updatedEditorProgramSaved.savedBy) {
                setIsSaving(true);
                setTimeout(() => {
                    setIsSaving(false);
                }, 1500);
            }
            setTimeout(() => {
                setEditorProgramSaved({
                    savedTimeDate: updatedEditorProgramSaved.savedTimeDate,
                    savedBy: updatedEditorProgramSaved.savedBy
                });
            }, localUserName === updatedEditorProgramSaved.savedBy ? 500 : 0);
        }
    };
    
    useEffect(() => {
        programVersion && updateEditorProgramVersionSaved(programVersion);
    }, 
    [programVersion?.anyUpdated, programId, programVersionId, librariesSavedTimeDate]);

    return { ...(editorProgramSaved || {}), isSaving, readableSavedTimeDate };
};
