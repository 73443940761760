import React from "react";
import classNames from "classnames";
import type { Style } from "../../../logic/builder/editorsContainerLogic/styleUtils";
import { inlineColor, styleIsEmpty } from "../../../logic/builder/editorsContainerLogic/styleUtils";
import "./StylePreview.css";
import StylePicker from "../../legacyCommon/ComponentLibrary/StylePicker/StylePicker";
import { SskyPopover } from "@sundaysky/ui-component-library/Components";

type StylePreviewProps = {
    style: Style | string; //for single mode use hex string
    singleMode?: boolean; //solid color only
    readOnly?: boolean;
    onChange?: (style: Style | string) => void;
    disablePopover?: boolean;
    popoverClassName?: string;
};

type StylePreviewState = {
    anchorEl: HTMLDivElement;
    currentStyle: Style | string;
};

const initialStyle: Style = {
    IsOneTint: true,
    Tint1: "#000000",
    Tint2: "#7F7F7F",
    Ratio: 0.5
};

export default class StylePreview extends React.Component<StylePreviewProps, StylePreviewState> {
    getStyleForState = () => {
        if (styleIsEmpty(this.props.style)) {
            return { ...initialStyle };
        }
        else if (typeof this.props.style === "object") {
            return { ...this.props.style };
        }
        else {
            return this.props.style;
        }
    };

    state = {
        anchorEl: null,
        currentStyle: this.getStyleForState()
    };

    componentDidUpdate(prevProps: Readonly<StylePreviewProps>, prevState: Readonly<StylePreviewState>, snapshot?: any): void {
        const _arePrimitiveObjectsIdentical = (obj1: object, obj2: object): boolean => Object.keys(obj1).every((prop) => obj2[prop] === obj1[prop]);

        if (prevProps.style !== this.props.style || (this.props.style && prevProps.style && typeof this.props.style === "object" &&
            typeof prevProps.style === "object" && !_arePrimitiveObjectsIdentical(prevProps.style, this.props.style))) {
            this.setState({ currentStyle: this.getStyleForState() });
        }
    }

    handleOpenPicker = (evt): void => {
        this.setState({ anchorEl: evt.currentTarget });
    };

    handleClosePicker = (evt): void => {
        evt.stopPropagation();
        this.props.onChange(this.state.currentStyle);
        this.setState({ anchorEl: null });
    };

    handleStyleChange = (style: Style | string): void => {
        let updatedStyle;
        if (this.props.singleMode) {
            updatedStyle = style;
        }
        else {
            updatedStyle = { ...(style as Style) };
        }
        this.setState({ currentStyle: updatedStyle });
    };

    renderPickerPopoverContent = (): JSX.Element => {
        const { popoverClassName, singleMode } = this.props;
        const { anchorEl, currentStyle } = this.state;

        return (
            <SskyPopover open={Boolean(anchorEl)} anchor={anchorEl} onClose={this.handleClosePicker} paperProps={{ className: classNames("style-picker-popover", popoverClassName) }}>
                <StylePicker style={currentStyle} onChange={this.handleStyleChange} singleMode={singleMode} />
            </SskyPopover>
        );
    };

    render(): JSX.Element {
        const { readOnly, style, disablePopover } = this.props;

        const { currentStyle } = this.state;

        const isClickable = readOnly === false || disablePopover === false;

        if (styleIsEmpty(style)) {
            return (
                <div className={classNames("style-preview", { clickable: isClickable }, "no-style")} onClick={this.handleOpenPicker}>
                    {!readOnly && !disablePopover && this.renderPickerPopoverContent()}
                </div>
            );
        }

        return (
            <div className={classNames("style-preview", { clickable: isClickable })} style={{ background: inlineColor(currentStyle) }} onClick={this.handleOpenPicker}>
                {!readOnly && !disablePopover && this.renderPickerPopoverContent()}
            </div>
        );
    }
}
