import type { GqlClientEditorLibraryAvatarFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorLibraryAvatarsQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseLibraryAvatars = {
    libraryAvatars: GqlClientEditorLibraryAvatarFragment[];
};

export const useLibraryAvatars = (): UseLibraryAvatars => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();

    const { data } = useEditorLibraryAvatarsQuery({ variables: { programId, programVersionId } });
    const libraryAvatars = data?.editorProgram.programVersion.ccLibraryVersion?.avatars ?? [];

    return { libraryAvatars };
};
