import { useEditorDefaultBrandIdQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { BrandId } from "../../types";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

type UseDefaultBrandId = {
    defaultBrandId: BrandId;
    defaultBrandLocalId
};

export const useDefaultBrandId = (): UseDefaultBrandId => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorDefaultBrandIdQuery({ variables: { programId, programVersionId } });
    
    const defaultBrandId = data?.editorProgram?.programVersion?.brandConfigurationVersion.defaultBrandConfigurationCollection.id;
    const defaultBrandLocalId = data?.editorProgram?.programVersion?.brandConfigurationVersion.defaultBrandConfigurationCollection.localId;

    return { defaultBrandId, defaultBrandLocalId };
};
