import React from "react";
import PropTypes from "prop-types";

import "./DataSelectionArea.css";

class DataSelectionArea extends React.Component {
    state = {
        searchTerm: ""
    };

    onSearchChange = (e) => {
        this.setState({ searchTerm: e.target.value });
    };

    applySearch = (item) =>
        item &&
        (!this.state.searchTerm ||
            (this.props.titleSelector(item) &&
                this.props
                    .titleSelector(item)
                    .toLowerCase()
                    .includes(this.state.searchTerm.toLowerCase())));

    renderEmptyContent = (text) => {
        if (this.props.emptyContent) {
            return this.props.emptyContent;
        }
        return <div className="empty-content">{text || this.props.emptyContentText}</div>;
    };

    renderTextElements = () => {
        const { className, itemIdentifier, onClick, renderItem } = this.props.textElements;
        // add text elements and divider to data element selection
        return this.props.textElements.items.map((item, index) =>
            this.renderSelectionItem({
                item,
                className,
                itemIdentifier,
                onClick,
                renderItem,
                index
            })
        );
    };

    renderSelectionItem = (settings) => {
        const { item, className, itemIdentifier, keySelector, onClick, renderItem, index } = { ...this.props, ...settings };
        const id = `${itemIdentifier}-${keySelector(item)}`;
        const testId = `${item.displayName}-${item.type}`;
        return (
            <li className={`data-item ${className}`} id={id} onClick={() => onClick(item)} key={keySelector(item) || index} data-testid={`data-element-list-item-${testId}`}>
                {renderItem(item)}
            </li>
        );
    };

    render() {
        let { items, className, onRemove, itemIdentifier, hideSearch } = this.props;

        if (!itemIdentifier) {
            itemIdentifier = className;
        }

        const filteredItems = items.filter(this.applySearch);
        const trimmedFilteredItems = filteredItems && filteredItems.length > 0 ? filteredItems.slice(0, 300) : filteredItems;
        const shouldTextElementsBeRendered = this.props.itemIdentifier === "dataElement" && !!this.props.textElements && !!this.props.textElements.items && !!this.props.textElements.items.length;

        return (
            <div className={`popup-${className}`}>
                <button className="logic-popup-remove-selected" onClick={onRemove} />
                {!hideSearch && (
                    <div className="search-bar-container">
                        <input id={`${itemIdentifier}-search-bar`} className={`${className}-search-bar`} onChange={this.onSearchChange} value={this.state.searchTerm} placeholder="Search" />
                        <span className="search-icon" />
                    </div>
                )}
                {this.props.items && !!this.props.items.length && (
                    <ul className="data-selection-list">
                        {shouldTextElementsBeRendered && this.renderTextElements()}
                        {shouldTextElementsBeRendered && <li key="divider0" className="divider" />}
                        {trimmedFilteredItems.map((item, index) => this.renderSelectionItem({ item, index }))}
                        {!filteredItems.length && this.renderEmptyContent("No items found")}
                    </ul>
                )}
                {(!this.props.items || !this.props.items.length) && this.renderEmptyContent()}
            </div>
        );
    }
}

DataSelectionArea.defaultProps = {
    className: "general-selection-area",
    keySelector: (item) => item.name,
    titleSelector: (item) => item.title,
    renderItem: (item) => item.title,
    emptyContentText: "Nothing to Display :{"
};

DataSelectionArea.propTypes = {
    onClick: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    items: PropTypes.array,
    className: PropTypes.string,
    longTitle: PropTypes.string,
    shortTitle: PropTypes.string,
    title: PropTypes.string,
    keySelector: PropTypes.func,
    titleSelector: PropTypes.func,
    renderItem: PropTypes.func,
    itemIdentifier: PropTypes.string,
    emptyContentText: PropTypes.string,
    emptyContent: PropTypes.element,
    hideSearch: PropTypes.bool,
    textElements: PropTypes.object
};

export default DataSelectionArea;
