import { useEffect, useRef } from "react";
import { calculateRecommendedVideoBitrate } from "../../../../../../common/videoQualityUtils";
import { StreamRecorder } from "../../../Utils/mediaRecorder";
import { transcodeToMp4 } from "../../../../../utils/ffmpegUtils";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { isFeatureEnabled } from "../../../../newNav/nooks/featureFlags";
import { useEditRecordingToolDialog } from "../../UseEditRecordingToolDialogState/useEditRecordingToolDialog";
import { RecorderLoggingEventType, reportRecorderError } from "../../../../../logic/common/logging/recorderClientLogging";
import { useHistory } from "react-router-dom";
import { useRecordingState } from "../useRecoringState";
import { RecordingState } from "../../../types";

export type RecorderConfig = {
    mimeType: string;
    onDataAvailable?: (blob: Blob) => void;
    onStop?: (finalBlob: Blob) => void;
    onError?: (error) => void;
};

export const useRecorder = (config: RecorderConfig) => {

    const recorderRef = useRef<StreamRecorder | MediaRecorder>(null);
    const chunksRef = useRef<Blob[]>([]);
    const { editRecordingToolDialogState, closeEditRecordingToolDialog } = useEditRecordingToolDialog();
    const editRecordingToolDialogOpen = editRecordingToolDialogState.open;

    const isEncoderRecorderFeatureFlag = isFeatureEnabled(featureFlagConst._EDITOR_ENCODER_RECORDER);
    const isRecorderToolPreviewDialog = !isFeatureEnabled(featureFlagConst._EDITOR_RECORDER_TOOL_SKIP_PREVIEW_DIALOG);

    const { setRecordingState } = useRecordingState();
    const history = useHistory();
    const state = recorderRef?.current?.state;
    const shouldBlockLeavingEditor = (state && state !== "inactive") || editRecordingToolDialogOpen;

    useEffect(() => {
        // Listener for browser refresh/close
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (shouldBlockLeavingEditor) {
                event.preventDefault();
                event.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Block in-app navigation
        const unblock = history.block((location, action) => {
            if (shouldBlockLeavingEditor) {
                const confirmNavigation = window.confirm("You have unsaved changes. Are you sure you want to leave?");
                // If user cancels, return false to block the navigation.
                if (!confirmNavigation) {
                    return false;
                }
                else {
                    void cleanupRecorder();
                    setRecordingState(RecordingState.NO_SESSION);
                    closeEditRecordingToolDialog();
                }
            }
            return;
        });

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            unblock();
        };
    }, [shouldBlockLeavingEditor, history]);

    const cleanupRecorder = async () => {
        if (recorderRef.current) {
            if (isEncoderRecorderFeatureFlag) {
                await (recorderRef.current as StreamRecorder).cleanUp();
            }
            else {
                const recorder = recorderRef.current as MediaRecorder;
                recorder.onstop = null;
                recorder.ondataavailable = null;
                recorder.stop();

                chunksRef.current = [];
            }
            recorderRef.current = null;
        }
    };

    const initializeRecorder = async (stream: MediaStream) => {
        if (!stream) return;

        try {
            // Clean up any existing recorder
            await cleanupRecorder();

            const mediaTrackSettings = stream.getVideoTracks()[0].getSettings();
            const { width, height, frameRate } = mediaTrackSettings;

            let recorder;

            const options: MediaRecorderOptions = {
                mimeType: config.mimeType,
                videoBitsPerSecond: calculateRecommendedVideoBitrate(width, height, frameRate),
                audioBitsPerSecond: 192_000
            };

            // TODO: Remove this once the feature flag is removed
            if (isEncoderRecorderFeatureFlag) {
                recorder = initializeStreamRecorder(options, stream);
            }
            else {
                recorder = initializeMediaRecorder(options, stream);
            }

            recorderRef.current = recorder;
        }
        catch (err) {
            reportRecorderError(err, {
                recorderEventType: RecorderLoggingEventType.RecordingFailedToInitialize,
                metadata: { state, shouldBlockLeavingEditor, config }
            });
        }
    };

    const startRecording = () => {
        recorderRef.current?.start();
    };

    const stopRecording = async () => {
        await recorderRef.current?.stop();
        recorderRef.current = null;
    };

    const pauseRecording = () => {
        recorderRef.current?.pause();
    };

    const resumeRecording = () => {
        recorderRef.current?.resume();
    };

    const restartRecording = async (stream: MediaStream) => {
        await initializeRecorder(stream);
    };

    const initializeStreamRecorder = (options: MediaRecorderOptions, stream: MediaStream) => {

        if (!isRecorderToolPreviewDialog) {
            options.mimeType = "video/mp4; codecs=\"avc1.640033, mp4a.40.2\"";
        }
        const recorder = new StreamRecorder(stream, options);
        recorder.onStop = async (buffer: ArrayBuffer[]) => {
            const blob = new Blob(buffer, { type: options.mimeType });
            config.onStop?.(blob);
        };

        recorder.onError = (error) => {
            cleanupRecorder();
            config.onError?.(error);
        };
        return recorder;
    };

    const initializeMediaRecorder = (options: MediaRecorderOptions, stream: MediaStream) => {
        if (!isRecorderToolPreviewDialog) {
            options.mimeType = "video/webm; codecs=h264";
        }
        const recorder = new MediaRecorder(stream, options);

        recorder.ondataavailable = (event) => {
            if (event.data?.size > 0) {
                chunksRef.current.push(event.data);
                config.onDataAvailable?.(event.data);
            }
        };

        recorder.onstop = async () => {
            const blob = new Blob(chunksRef.current, { type: options.mimeType });

            if (isRecorderToolPreviewDialog) {
                config.onStop?.(blob);
                chunksRef.current = [];
                return;
            }

            const finalBlob = await transcodeToMp4(blob);
            config.onStop?.(finalBlob);
            chunksRef.current = [];
        };

        recorder.onerror = (error) => {
            cleanupRecorder();
            config.onError?.(error);
        };
        return recorder;
    };

    return {
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        restartRecording,
        initializeRecorder
    };
};
