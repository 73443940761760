import { useReactiveVar } from "@apollo/client";
import { localEditorRecordingState } from "../../State";
import type { RecordingState } from "../../types";

type UseRecordingState = {
    recordingState: RecordingState;
    setRecordingState: (recordingState: RecordingState) => void;
};

const setRecordingState = (recordingState: RecordingState) => {
    localEditorRecordingState(recordingState);
};

export const useRecordingState = (): UseRecordingState => {
    const recordingState = useReactiveVar(localEditorRecordingState);

    return { recordingState, setRecordingState };
};
