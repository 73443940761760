import { getSskyErrorCodeFromGqlError } from "../../../../../common/errors";
import type {
    GqlClientCreateEditorProgramFromStoryTemplateMutation,
    GqlClientCreateEditorProgramFromStoryTemplateMutationVariables, GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables,
    GqlClientGalleryStoryTemplateFragment,
    GqlClientPublishStoryTemplateMutation,
    GqlClientPublishStoryTemplateMutationVariables,
    GqlClientRemoveStoryTemplateMutation,
    GqlClientRemoveStoryTemplateMutationVariables
    , GqlClientEnumAspectRatio, GqlClientNewlyCreatedEditorProgramFragment
    , GqlClientCreateOrUpdateEditorApplicationInput, GqlClientCreateOrUpdateEditorApplicationMutation } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    CreateEditorProgramDocument,
    CreateEditorProgramFromStoryTemplateDocument, CreateOrUpdateEditorApplicationDocument,
    GqlClientStoryTemplatesGalleryType, GqlClientStoryTemplatesReviewGalleryIndication,
    PublishStoryTemplateDocument,
    RemoveStoryTemplateDocument
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getApolloClient } from "../../../../apollo";
import { CC_LIBRARY_ID } from "../../../../../common/commonConst";
import { getGQLEditorProgramType } from "../../../../logic/common/programUtils";
import type { ProgramType } from "../../../../../common/types/program";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";

export const publishStoryTemplateToProduction = async (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]): Promise<void> => {
    try {
        const result = await getApolloClient().mutate<GqlClientPublishStoryTemplateMutation, GqlClientPublishStoryTemplateMutationVariables>({
            mutation: PublishStoryTemplateDocument,
            variables: {
                input: {
                    storyTemplateVersionId: storyTemplateId
                }
            }
        });

        if (result?.errors) {
            throw result?.errors[0];
        }
    }
    catch (error) {
        const gqlError = error.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || error, sskyErrorCode);
    }
};

export const removeStoryTemplateFromGallery = async (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"], galleryType: GqlClientStoryTemplatesGalleryType): Promise<void> => {
    try {
        const result = await getApolloClient().mutate<GqlClientRemoveStoryTemplateMutation, GqlClientRemoveStoryTemplateMutationVariables>({
            mutation: RemoveStoryTemplateDocument,
            variables: {
                input: {
                    galleryType,
                    storyTemplateVersionId: storyTemplateId
                }
            }
        });

        if (result?.errors) {
            throw result?.errors[0];
        }
    }
    catch (error) {
        const gqlError = error.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || error, sskyErrorCode);
    }

};

export const removeStoryTemplateFromAllEnvs = async (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"], stIndication: GqlClientStoryTemplatesReviewGalleryIndication): Promise<void> => {

    if (templateExistsInProduction(stIndication)) {
        await removeStoryTemplateFromGallery(storyTemplateId, GqlClientStoryTemplatesGalleryType.PRODUCTION);
    }
    await removeStoryTemplateFromGallery(storyTemplateId, GqlClientStoryTemplatesGalleryType.QA);

};

export const templateExistsInProduction = (statusIndication: GqlClientStoryTemplatesReviewGalleryIndication): boolean => {
    return statusIndication != null && statusIndication != GqlClientStoryTemplatesReviewGalleryIndication.NOT_PUBLISHED;
};

export const createDraftProgramFromStoryEditor = async (storyTemplateId: GqlClientGalleryStoryTemplateFragment["id"]): Promise<GqlClientNewlyCreatedEditorProgramFragment> => {
    try {
        const mutationVariables: GqlClientCreateEditorProgramFromStoryTemplateMutationVariables = {
            input: {
                sourceStoryTemplateProgramVersionId: storyTemplateId
            }
        };

        const result = await getApolloClient().mutate<GqlClientCreateEditorProgramFromStoryTemplateMutation, GqlClientCreateEditorProgramFromStoryTemplateMutationVariables>({
            mutation: CreateEditorProgramFromStoryTemplateDocument,
            variables: mutationVariables
        });

        if (result?.errors) {
            throw result?.errors[0];
        }

        return result.data.createEditorProgramFromStoryTemplate.editorProgram;
    }
    catch (error) {
        const gqlError = error.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || error, sskyErrorCode);
    }
};

export const createFromScratchProgramFromStoryEditor = async (
    projectDisplayName: string,
    aspectRatio: GqlClientEnumAspectRatio,
    programType: ProgramType
): Promise<GqlClientNewlyCreatedEditorProgramFragment> => {
    try {
        const mutationVariables: GqlClientCreateEditorProgramMutationVariables = {
            input: {
                name: projectDisplayName,
                type: getGQLEditorProgramType(programType),
                accountCcLibraryId: CC_LIBRARY_ID,
                aspectRatio: aspectRatio
            }
        };

        const result = await getApolloClient().mutate<GqlClientCreateEditorProgramMutation, GqlClientCreateEditorProgramMutationVariables>({
            mutation: CreateEditorProgramDocument,
            variables: mutationVariables });

        if (result?.errors) {
            throw result?.errors[0];
        }

        return result.data.createEditorProgram.editorProgram;
    }
    catch (error) {
        const gqlError = error.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || error, sskyErrorCode);
    }
};

export const updateApplication = async (updatedApplicationInput: GqlClientCreateOrUpdateEditorApplicationInput): Promise<void> => {
    try {
        const result = await getApolloClient().mutate<GqlClientCreateOrUpdateEditorApplicationMutation>({
            mutation: CreateOrUpdateEditorApplicationDocument,
            variables: {
                input: updatedApplicationInput
            }
        });

        if (result?.errors) {
            throw result?.errors[0];
        }
    }
    catch (error) {
        const gqlError = error.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || error, sskyErrorCode);
    }
};

