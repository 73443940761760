import { useMemo } from "react";
import type { ThemeId } from "../../types";
import { useLibraryThemes } from "../UseLibraryEntities";
import { BreathingType } from "../../../../../common/types/editorPlaceholder";
import { GqlClientCcDofType } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export type UseThemeDof = {
    themeBreathingValue: BreathingType | null;
};

export const useThemeDofs = (themeId: ThemeId): UseThemeDof => {
    const { libraryThemes } = useLibraryThemes();

    const themeDofs = useMemo(() => {
        const ret: UseThemeDof = {
            themeBreathingValue: null
        };

        const theme = libraryThemes.find(({ id }) => id === themeId);

        if (!theme) return ret;

        const breathingDof = theme.dofValues.find(({ dof }) => dof.type === GqlClientCcDofType.BREATHING);
        if (breathingDof?.value) {
            const breathing = JSON.parse(breathingDof.value);
            if (breathing.type === BreathingType.In.toLowerCase()) {
                ret.themeBreathingValue = BreathingType.In;
            }
        }

        return ret;
    }, [themeId, libraryThemes]);

    return themeDofs;
};
