import type { FetchResult } from "@apollo/client";
import { isCropModeDisabledForAvatarId, replaceDisabledCropMode } from "../../../../../common/avatar";
import { buildBreathingSetting, buildColorSetting, buildDurationSetting, buildMediaSoundSetting, buildOverrideLogoSetting } from "../../../../../common/editorUtils";
import {
    getAvatarCropSetting,
    getDefaultColorSetting,
    getDefaultTextBackgroundColorSetting,
    getLogoOverrideSetting,
    getSoundSetting, getTextOrNarrationText, isPlaceholderContentNoRulesWithPersonalization,
    updateMediaFallbackIfNeeded
} from "../../../../../common/placeholderUtils";
import type {
    Adjustment,
    AdjustmentSetting,
    AlignmentPair,
    AvatarCropPreset,
    AvatarCropSetting,
    AvatarPlaceholderContent,
    BreathingType,
    ButtonPlaceholderContent,
    ButtonShowContentV9,
    ButtonShowV9,
    CaseType,
    ColorSetting,
    DataElementDescriptor,
    DurationType,
    DynamicRichTextAtoms,
    DynamicTextAtomContent,
    DynamicTextAtoms,
    DynamicTextShow,
    FontSizeSetting,
    HiddenSetting,
    MediaAlignmentSetting,
    MediaPlaceholderContent,
    NarrationPlaceholderContent, NarrationShow,
    NarratorVoiceData,
    PlaceholderSettingsVersion10,
    RichTextAtom,
    SettingAtoms,
    SettingKey,
    StaticAvatarShowV1,
    StaticButtonShowV9,
    StaticMediaShowV8,
    StaticRichTextAtoms,
    StaticTextAtoms,
    StaticTextShow,
    SwitchBy,
    TextFit,
    TextFitSetting,
    TextHorizontalAlignmentSetting,
    TextPlaceholderContent,
    TextStyleSetting,
    TextVerticalAlignmentSetting } from "../../../../../common/types/editorPlaceholder";
import {
    AvatarSettingsKey,
    ButtonShowContentItemElement,
    DEFAULT_CONTENT,
    FontSizeUnit,
    GlobalSettingKey,
    HorizontalAlignment,
    MediaSettingKey,
    NarrationSettingsKey,
    PlaceholderShowType,
    RichTextStyleSetting,
    TextSettingKey,
    VerticalAlignment
} from "../../../../../common/types/editorPlaceholder";
import type { GqlClientCcDof, GqlClientEditorPlaceholderFragment, GqlClientUpdateEditorAvatarPlaceholderMutation } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientCcPlaceholderType } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { deepCloneObj } from "../../../../logic/common/generalUtils";
import type { PlaceholderId, ProgramId, ProgramVersionId } from "../../types";
import type { RaasPositionable, UpdateFlexiblePlaceholderPosition } from "../../Utils";
import { getEditedBrandLogoAssetInfo } from "../BrandLibrary/logo";
import { setSuccessNotification } from "../Notification";
import { getPlaceholder, updateAvatarPlaceholder, updatePlaceholder } from "../Placeholder";

export type SetMediaShowFunction = (show: StaticMediaShowV8) => void;
export type SetMediaShowTypeFunction = (mediaType: PlaceholderShowType) => void;
export type SetMediaDynamicFunction = (mediaType: DataElementDescriptor) => void;
export type SetMediaStaticFallbackFunction = (staticFallback: StaticMediaShowV8) => void;
export type SetTextShowFunction = (defaultText: DynamicTextShow) => void;
export type SetTextShowRichTextFunction = (showValue: DynamicRichTextAtoms) => void;
export type SetTextStaticFallbackFunction = (staticFallbackValue: StaticTextShow) => void;
export type SetRichTextStaticFallbackFunction = (staticFallbackValue: StaticRichTextAtoms) => void;
export type UpdateTextCaseShowFunction = (showValue: DynamicTextShow, index: number) => void;
export type UpdateTextCaseShowRichTextFunction = (showValue: DynamicRichTextAtoms, index: number) => void;
export type SetSelectedDofColorFunction = (ccDofLocalId: GqlClientCcDof["localId"]) => void;
export type SetCustomColorFunction = (color: string | null) => void;
export type ClearCustomColorFunction = () => void;
export type UpdateMediaCaseShowFunction = (caseShow: StaticMediaShowV8, index: number) => void;
export type UpdateMediaCaseShowTypeFunction = (mediaType: PlaceholderShowType, index: number) => void;
export type UpdateMediaCaseDynamicFunction = (mediaType: DataElementDescriptor, index: number) => void;
export type SetButtonContentItemFunction = (elem: ButtonShowContentItemElement, value: DynamicTextShow, isContentSameAsButtonText: boolean) => void;
export type SetButtonContentItemRichTextFunction = (value: DynamicRichTextAtoms) => void;
export type SetButtonStaticFallbackContentItemFunction = (elem: ButtonShowContentItemElement, value: DynamicTextShow, isContentSameAsButtonText: boolean) => void;
export type SetStaticFallbackContentItemRichTextFunction = (content: StaticRichTextAtoms) => void;
export type UpdateButtonCaseFunction = (elem: ButtonShowContentItemElement, value: DynamicTextShow, isContentSameAsButtonText: boolean, caseIndex: number) => void;
export type UpdateButtonCaseRichTextFunction = (value: DynamicRichTextAtoms, caseIndex: number) => void;
export type SetTextSizeFunction = (size: number | null) => void;
export type SetTextHorizontalAlignmentFunction = (horizontalAlignment: HorizontalAlignment) => void;
export type SetTextVerticalAlignmentFunction = (verticalAlignment: VerticalAlignment) => void;
export type ResetTextPlaceholderSettingsFunction = () => void;
export type SetUseRulesFunction = (useRules: boolean) => void;
export type SetTextStyleFunction = (style: string) => void;
export type SetTextFitFunction = (fit: TextFit) => void;
export type SetAvatarShowFunction = (show: StaticAvatarShowV1) => void;
export type SetNarrationVoiceFunction = (narratorVoice: NarratorVoiceData) => void;
export type SetNarrationShowTextAndSpeedFunction = (text: DynamicTextShow, speed: number) => void;
export type SetNarrationStaticFallbackShowSTextAndSpeedFunction = (text: StaticTextShow, speed: number) => void;
export type UpdateNarrationCaseShowTextAndSpeedFunction = (text: DynamicTextShow, speed: number, index: number) => void;

export type TextPlaceholderSetters = {
    setShowRichText: SetTextShowRichTextFunction;
    setStaticFallbackRichText: SetRichTextStaticFallbackFunction;
    updateCaseShowRichText: UpdateTextCaseShowRichTextFunction;
    setFontSize: SetTextSizeFunction;
    setTextHorizontalAlignment: SetTextHorizontalAlignmentFunction;
    setTextVerticalAlignment: SetTextVerticalAlignmentFunction;
    setSelectedBackgroundDofColor: SetSelectedDofColorFunction;
    setCustomBackgroundColor: SetCustomColorFunction;
    clearBackgroundColor: ClearCustomColorFunction;
    resetTextPlaceholderSettings: ResetTextPlaceholderSettingsFunction;
    setUseRules: SetUseRulesFunction;
    setTextStyle: SetTextStyleFunction;
    setTextFit: SetTextFitFunction;
};

export type NarrationPlaceholderSetters = {
    // TODO: Delete these when deleting _EDITOR_TTS_ADDITIONAL_CONTROLS FF.
    setShowText: SetTextShowFunction;
    setStaticFallbackText: SetTextStaticFallbackFunction;
    updateCaseShowText: UpdateTextCaseShowFunction;

    setShowTextAndSpeed: SetNarrationShowTextAndSpeedFunction;
    setStaticFallbackShowTextAndSpeed: SetNarrationStaticFallbackShowSTextAndSpeedFunction;
    updateCaseShowTextAndSpeed: UpdateNarrationCaseShowTextAndSpeedFunction;
    setUseRules: SetUseRulesFunction;
    setVoice: SetNarrationVoiceFunction;
};

export type SharedSetters = {
    setRulesBy: (rulesBy: SwitchBy) => void;
    addCase: (newCase: CaseType) => void;
    removeCase: (index: number) => void;
    updateCaseAudience: (newAudiences: string[], index: number) => void;
    setSelectedDofColor: SetSelectedDofColorFunction;
    setCustomColor: SetCustomColorFunction;
    clearCustomColor: ClearCustomColorFunction;
    setIsHidden: (isHidden: boolean) => void;
};

export type MediaPlaceholderSetters = {
    setShow: SetMediaShowFunction;
    setShowType: SetMediaShowTypeFunction;
    setAlignment: (alignment: AlignmentPair) => void;
    setAdjustment: (adjustment: Adjustment) => void;
    setUseCustomAlignment: (useCustomAlignment: boolean) => void;
    setStaticFallback: SetMediaStaticFallbackFunction;
    setDynamic: SetMediaDynamicFunction;
    updateCaseShow: UpdateMediaCaseShowFunction;
    updateCaseShowType: UpdateMediaCaseShowTypeFunction;
    updateCaseDynamic: UpdateMediaCaseDynamicFunction;
    setUseRules: SetUseRulesFunction;
    setBreathing: (breathing: BreathingType) => void;
    setUseSound: (useSound: boolean) => void;
    toggleUseSound: () => void;
    toggleOverrideLogo: () => void;
    setDuration: (duration: DurationType) => void;
};

export type AvatarPlaceholderSetters = {
    setShow: SetAvatarShowFunction;
    setCrop: (
        crop: AvatarCropPreset,
        foregroundPosition: RaasPositionable,
        updateFlexiblePlaceholderPosition: UpdateFlexiblePlaceholderPosition
    ) => void;
    //todo: rules (+setShowType)
};

export type ButtonPlaceholderSetters = {
    setNoRulesContentItem: SetButtonContentItemFunction;
    setNoRulesContentItemRichText: SetButtonContentItemRichTextFunction;
    setStaticFallbackContentItem: SetButtonStaticFallbackContentItemFunction;
    setStaticFallbackContentItemRichText: SetStaticFallbackContentItemRichTextFunction;
    updateCaseShow: UpdateButtonCaseFunction;
    updateCaseShowRichText: UpdateButtonCaseRichTextFunction;
    setUseRules: SetUseRulesFunction;
};

export function getPlaceholderSetters(
    programId: ProgramId,
    programVersionId: ProgramVersionId,
    placeholderId: PlaceholderId
) {
    const setTextOrNarrationUseRules: (useRules: boolean) => void = (useRules: boolean) => {
        withPlaceholder((placeholder) => {
            const placeholderContent: TextPlaceholderContent | NarrationPlaceholderContent = placeholder.placeholderContent;
            let staticFallback = placeholderContent.staticFallback;
            if (useRules && !isShowTextEmpty(getTextOrNarrationText(placeholderContent.placeholderType, placeholderContent.staticFallback) as StaticTextAtoms | StaticRichTextAtoms)) {
                if (!isPlaceholderContentNoRulesWithPersonalization(placeholderContent)) {
                    staticFallback = deepCloneObj(placeholderContent.noRules.show) as StaticRichTextAtoms | NarrationShow<StaticTextAtoms>;
                }
            }

            updatePlaceholder({
                ...placeholder,
                placeholderContent: {
                    ...placeholderContent,
                    useRules,
                    staticFallback
                }
            });
        });
    };

    // Wrap for the functions that need to use an updated placeholder instance
    const withPlaceholder = (updateFunction: (placeholder: GqlClientEditorPlaceholderFragment) => void): void => {
        const placeholder = getPlaceholder(placeholderId);
        updateFunction(placeholder);
    };

    // region Validations
    const assertPlaceholderType = (...allowedTypes: GqlClientCcPlaceholderType[]): void => {
        withPlaceholder((placeholder) => {
            if (!allowedTypes.includes(placeholder.ccPlaceholder.intent.type)) {
                throw Error(`Wrong placeholder type. You tried to use ${allowedTypes.join("/")} setter function but placeholder type is "${placeholder.ccPlaceholder.intent.type}"`);
            }
        });
    };

    const getValueTypeErrorMessage = (expectedType: string, value: DynamicTextAtomContent, type: GqlClientCcPlaceholderType): string => {
        return `Wrong placeholder value type. ${type} placeholder value should be of type ${expectedType}, but got ${typeof value}`;
    };

    const assertDynamicTextShow = (value: DynamicTextShow): void => {
        value.forEach(part => {
            //TODO: add check for data element when available
            if (typeof part !== "string") {
                if (!part.localId) {
                    withPlaceholder((placeholder) => {
                        const type = placeholder.ccPlaceholder.intent.type;
                        throw Error(getValueTypeErrorMessage("LiteralText | DataElementDescriptor", part, type));
                    });
                }
            }
        });
    };

    const getDefaultAlignment = (): AlignmentPair => ({
        horizontal: HorizontalAlignment.Center,
        vertical: VerticalAlignment.Center
    });

    const assertTypeAndDynamicTextShow = (type: GqlClientCcPlaceholderType, value: DynamicTextShow): void => {
        assertPlaceholderType(type);
        assertDynamicTextShow(value);
    };
    // endregion

    const clearCasesValueIds = (cases: CaseType[]) => {
        return cases.map(variation => ({ valueIds: [], show: variation.show }));
    };

    const sharedSetters: SharedSetters = {
        setRulesBy: (rulesBy: SwitchBy): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            by: rulesBy,
                            cases: clearCasesValueIds(placeholder.placeholderContent.rules.cases)
                        }
                    }
                });
            });
        },
        addCase: (newCase: CaseType): void => {
            withPlaceholder((placeholder) => {
                const newCases = placeholder.placeholderContent.rules.cases.concat([newCase]);
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            ...placeholder.placeholderContent.rules,
                            cases: newCases
                        }
                    }
                });
            });
        },
        removeCase: (index: number): void => {
            withPlaceholder((placeholder) => {
                let newCases = [...placeholder.placeholderContent.rules.cases];
                newCases.splice(index, 1);
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            ...placeholder.placeholderContent.rules,
                            cases: newCases
                        }
                    }
                }).then(() => {
                    setSuccessNotification({
                        message: "Variation deleted",
                        showCloseButton: true

                    });
                });
            });
        },
        updateCaseAudience: (newAudiences: string[], index: number): void => {
            withPlaceholder((placeholder) => {
                const newCases = placeholder.placeholderContent.rules.cases.slice(0);
                newCases[index] = { ...newCases[index], valueIds: newAudiences };
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            ...placeholder.placeholderContent.rules,
                            cases: newCases
                        }
                    }
                });
            });
        },
        setSelectedDofColor: (ccDofLocalId: GqlClientCcDof["localId"]) => {
            withPlaceholder((placeholder) => {
                const settings: ColorSetting = {
                    useValue: true,
                    value: {
                        customColor: getDefaultColorSetting(placeholder.placeholderSettings)?.value?.customColor || null,
                        ccDofLocalId
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.Color, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setCustomColor: (color: string | null): void => {
            withPlaceholder((placeholder) => {
                const settings: ColorSetting = {
                    useValue: true,
                    value: {
                        customColor: color,
                        ccDofLocalId: null
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.Color, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        clearCustomColor: (): void => {
            withPlaceholder((placeholder) => {
                const value = getDefaultColorSetting(placeholder.placeholderSettings)?.value;

                const settings: ColorSetting = {
                    useValue: false,
                    value: { ...(value ? value : buildColorSetting().value) }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.Color, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setIsHidden: (isHidden: boolean): void => {
            withPlaceholder((placeholder) => {
                const settings: HiddenSetting = {
                    useValue: true,
                    value: { isHidden }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, GlobalSettingKey.IsHidden, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        }
    };

    const textPlaceholderSetters: TextPlaceholderSetters = {
        setUseRules: setTextOrNarrationUseRules,
        setShowRichText: (showValue: DynamicRichTextAtoms): void => {
            assertTypeAndDynamicTextShow(GqlClientCcPlaceholderType.TEXT, showValue.map(atom => atom.content));

            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        noRules: {
                            show: showValue
                        }
                    }
                });
            });
        },
        setStaticFallbackRichText: (staticFallbackValue: StaticRichTextAtoms): void => {
            assertPlaceholderType(GqlClientCcPlaceholderType.TEXT);
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        staticFallback: staticFallbackValue
                    }
                });
            });
        },
        updateCaseShowRichText: (showValue: DynamicRichTextAtoms, index: number): void => {
            withPlaceholder((placeholder) => {
                const newCases = placeholder.placeholderContent.rules.cases.slice(0);
                newCases[index] = { ...newCases[index], show: showValue };
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            ...placeholder.placeholderContent.rules,
                            cases: newCases
                        }
                    }
                });
            });
        },
        setFontSize: (size: number | null): void => {
            withPlaceholder((placeholder) => {
                const settings: FontSizeSetting = {
                    useValue: true,
                    value: {
                        fontSize: {
                            value: size,
                            unit: FontSizeUnit.EM
                        }
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.FontSize, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setTextHorizontalAlignment: (horizontalAlignment: HorizontalAlignment): void => {
            withPlaceholder((placeholder) => {
                const settings: TextHorizontalAlignmentSetting = {
                    useValue: true,
                    value: {
                        horizontalAlignment
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.HorizontalAlignment, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setTextVerticalAlignment: (verticalAlignment: VerticalAlignment): void => {
            withPlaceholder((placeholder) => {
                const settings: TextVerticalAlignmentSetting = {
                    useValue: true,
                    value: {
                        verticalAlignment
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.VerticalAlignment, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setSelectedBackgroundDofColor: (ccDofLocalId: GqlClientCcDof["localId"]) => {
            withPlaceholder((placeholder) => {
                const settings: ColorSetting = {
                    useValue: true,
                    value: {
                        customColor: getDefaultTextBackgroundColorSetting(placeholder.placeholderSettings)?.value?.customColor || null,
                        ccDofLocalId
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.BackgroundColor, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setCustomBackgroundColor: (color: string | null): void => {
            withPlaceholder((placeholder) => {
                const settings: ColorSetting = {
                    useValue: true,
                    value: {
                        customColor: color,
                        ccDofLocalId: null
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.BackgroundColor, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setTextStyle: (style: string): void => {
            withPlaceholder((placeholder) => {
                const settings: TextStyleSetting = {
                    useValue: true,
                    value: {
                        style
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.Style, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setTextFit: (fit: TextFit): void => {
            withPlaceholder((placeholder) => {
                const settings: TextFitSetting = {
                    useValue: true,
                    value: {
                        fit
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.Fit, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        clearBackgroundColor: (): void => {
            withPlaceholder((placeholder) => {
                const value = getDefaultTextBackgroundColorSetting(placeholder.placeholderSettings)?.value;

                const settings: ColorSetting = {
                    useValue: false,
                    value: { ...(value ? value : buildColorSetting().value) }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, TextSettingKey.BackgroundColor, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        resetTextPlaceholderSettings: (): void => {
            withPlaceholder((placeholder) => {

                const placeholderSettings: PlaceholderSettingsVersion10 = deepCloneObj(placeholder.placeholderSettings);
                const settings = placeholderSettings.contents?.[DEFAULT_CONTENT].settings;
                if (settings[TextSettingKey.BackgroundColor]?.useValue) {
                    settings[TextSettingKey.BackgroundColor].useValue = false;
                }
                if (settings[TextSettingKey.HorizontalAlignment]?.useValue) {
                    settings[TextSettingKey.HorizontalAlignment].useValue = false;
                }
                if (settings[TextSettingKey.VerticalAlignment]?.useValue) {
                    settings[TextSettingKey.VerticalAlignment].useValue = false;
                }
                if (settings[TextSettingKey.Color]?.useValue) {
                    settings[TextSettingKey.Color].useValue = false;
                }
                if (settings[TextSettingKey.FontSize]?.useValue) {
                    settings[TextSettingKey.FontSize] = {
                        useValue: true,
                        value: {
                            fontSize: {
                                value: 100,
                                unit: FontSizeUnit.EM
                            }
                        }
                    };

                }
                if (settings[TextSettingKey.Style]?.useValue) {
                    settings[TextSettingKey.Style].useValue = false;
                }
                if (settings[TextSettingKey.Fit]?.useValue) {
                    settings[TextSettingKey.Fit].useValue = false;
                }
                // reset rich text styles
                const placeholderContent = resetPlaceholderRichTextFormatting(placeholder.placeholderContent);
                updatePlaceholder({ ...placeholder, placeholderSettings, placeholderContent });
            });
        }
    };

    const mediaPlaceholderSetters: MediaPlaceholderSetters = {
        setShow: (show: StaticMediaShowV8): void => {
            if (show !== undefined) {
                withPlaceholder((placeholder) => {
                    updatePlaceholder({
                        ...placeholder,
                        placeholderContent: updateMediaStaticShow(placeholder.placeholderContent, show)
                    });
                });
            }
        },
        setShowType: (mediaShowType: PlaceholderShowType): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateMediaPhShowType(placeholder.placeholderContent, mediaShowType)
                });
            });
        },
        setDynamic: (dynamic: DataElementDescriptor): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateMediaDynamicShow(placeholder.placeholderContent, dynamic)
                });
            });
        },
        setStaticFallback: (staticFallback: StaticMediaShowV8): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateMediaStaticFallback(placeholder.placeholderContent, staticFallback)
                });
            });
        },
        setAlignment: (mediaAlignment: AlignmentPair): void => {
            withPlaceholder((placeholder) => {
                const settings: MediaAlignmentSetting = {
                    useValue: true,
                    value: {
                        mediaAlignment
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Alignment, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setAdjustment: (mediaAdjustment: Adjustment): void => {
            withPlaceholder((placeholder) => {
                const settings: AdjustmentSetting = {
                    useValue: true,
                    value: {
                        mediaAdjustment
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Adjustment, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setUseCustomAlignment: (useCustomValue: boolean): void => {
            withPlaceholder((placeholder) => {
                const settings: MediaAlignmentSetting = {
                    useValue: useCustomValue,
                    value: {
                        mediaAlignment: getDefaultAlignment()
                    }
                };
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Alignment, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        updateCaseShow: (caseShow: StaticMediaShowV8, index: number): void => {
            if (caseShow !== undefined) {
                withPlaceholder((placeholder) => {
                    updatePlaceholder({
                        ...placeholder,
                        placeholderContent: updateMediaCaseStaticShow(placeholder.placeholderContent, caseShow, index)
                    });
                });
            }
        },
        updateCaseShowType: (mediaShowType: PlaceholderShowType, index: number): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateMediaCasePhShowType(placeholder.placeholderContent, mediaShowType, index)
                });
            });
        },
        updateCaseDynamic: (dynamic: DataElementDescriptor, index: number): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateMediaCaseDynamicShow(placeholder.placeholderContent, dynamic, index)
                });
            });
        },
        setUseRules: (useRules: boolean): void => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateMediaUseRules(placeholder.placeholderContent, useRules)
                });
            });
        },
        setBreathing: (breathing: BreathingType): void => {
            withPlaceholder((placeholder) => {
                const settings = buildBreathingSetting({ breathing });
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Breathing, settings);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        toggleUseSound: (): void => {
            withPlaceholder((placeholder) => {
                const soundSetting = getSoundSetting(placeholder.placeholderSettings) ?? buildMediaSoundSetting();
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Sound, {
                    ...soundSetting,
                    useValue: !soundSetting.useValue
                });
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        toggleOverrideLogo: (): void => {
            withPlaceholder((placeholder) => {
                const setting = getLogoOverrideSetting(placeholder.placeholderSettings) ?? buildOverrideLogoSetting();
                const overrideLogo = !setting.useValue;
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.OverrideLogo, {
                    ...setting,
                    useValue: overrideLogo,
                    value: {
                        initialized: true
                    }
                });
                let placeholderContent = placeholder.placeholderContent;
                if (overrideLogo && !setting?.value?.initialized) {
                    const brandLogo = getEditedBrandLogoAssetInfo(programId, programVersionId);
                    placeholderContent = updateMediaStaticShow(placeholder.placeholderContent, {
                        mediaId: brandLogo?.logoMediaId
                    });
                }
                updatePlaceholder({
                    ...placeholder,
                    placeholderSettings,
                    placeholderContent
                });
            });
        },
        setDuration: (duration: DurationType) => {
            withPlaceholder((placeholder) => {
                const setting = buildDurationSetting(true, duration);
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Duration, setting);
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        },
        setUseSound: (useSound: boolean): void => {
            withPlaceholder((placeholder) => {
                const setting = getSoundSetting(placeholder.placeholderSettings) ?? buildMediaSoundSetting();
                const placeholderSettings = updatePlaceholderSettingAtom(placeholder.placeholderSettings, DEFAULT_CONTENT, MediaSettingKey.Sound, {
                    ...setting,
                    useValue: useSound
                });
                updatePlaceholder({ ...placeholder, placeholderSettings });
            });
        }
    };

    const narrationPlaceholderSetters: NarrationPlaceholderSetters = {
        setUseRules: setTextOrNarrationUseRules,
        setShowText: (value: DynamicTextShow) => {
            assertTypeAndDynamicTextShow(GqlClientCcPlaceholderType.NARRATION, value);
            const showText: DynamicTextAtoms | StaticTextAtoms = getTextAtoms(value);

            withPlaceholder(placeholder => updatePlaceholder({
                ...placeholder,
                placeholderContent: {
                    ...placeholder.placeholderContent,
                    noRules: {
                        show: {
                            ...placeholder.placeholderContent.noRules.show,
                            text: showText
                        }
                    }
                }
            }));
        },
        setStaticFallbackText: (value: StaticTextShow) => {
            assertTypeAndDynamicTextShow(GqlClientCcPlaceholderType.NARRATION, [value]);

            withPlaceholder(placeholder => updatePlaceholder({
                ...placeholder,
                placeholderContent: {
                    ...placeholder.placeholderContent,
                    staticFallback: {
                        ...placeholder.placeholderContent.staticFallback,
                        text: getTextAtoms([value])
                    }
                }
            }));
        },
        updateCaseShowText: (showValue: DynamicTextShow, index: number) => {
            withPlaceholder(placeholder => {
                const newCases = [...placeholder.placeholderContent.rules.cases];
                const showText = getTextAtoms(showValue);
                newCases[index] = {
                    ...newCases[index],
                    show: {
                        ...newCases[index].show,
                        text: showText
                    }
                };

                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            ...placeholder.placeholderContent.rules,
                            cases: newCases
                        }
                    }
                });
            });
        },
        setShowTextAndSpeed: (text: DynamicTextShow, speed: number): void => {
            assertTypeAndDynamicTextShow(GqlClientCcPlaceholderType.NARRATION, text);
            const showText: DynamicTextAtoms | StaticTextAtoms = getTextAtoms(text);

            withPlaceholder(placeholder => updatePlaceholder({
                ...placeholder,
                placeholderContent: {
                    ...placeholder.placeholderContent,
                    noRules: {
                        show: {
                            text: showText,
                            speed
                        }
                    }
                }
            }));
        },
        setStaticFallbackShowTextAndSpeed: (text: StaticTextShow, speed: number) => {
            assertTypeAndDynamicTextShow(GqlClientCcPlaceholderType.NARRATION, [text]);

            withPlaceholder(placeholder => updatePlaceholder({
                ...placeholder,
                placeholderContent: {
                    ...placeholder.placeholderContent,
                    staticFallback: {
                        text: getTextAtoms([text]),
                        speed
                    }
                }
            }));
        },
        updateCaseShowTextAndSpeed: (text: DynamicTextShow, speed: number, index: number) => {
            withPlaceholder(placeholder => {
                const newCases = [...placeholder.placeholderContent.rules.cases];
                const showText = getTextAtoms(text);
                newCases[index] = {
                    ...newCases[index],
                    show: {
                        text: showText,
                        speed
                    }
                };

                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholder.placeholderContent,
                        rules: {
                            ...placeholder.placeholderContent.rules,
                            cases: newCases
                        }
                    }
                });
            });
        },
        setVoice: (narratorVoice: NarratorVoiceData): void => {
            withPlaceholder((placeholder) => {
                void updatePlaceholder({
                    ...placeholder,
                    placeholderSettings: updatePlaceholderSettingAtom(
                        placeholder.placeholderSettings,
                        DEFAULT_CONTENT,
                        NarrationSettingsKey.AlternativeVoice,
                        {
                            useValue: !!narratorVoice,
                            value: narratorVoice
                                ? {
                                    narratorVoice
                                }
                                : null
                        }
                    )
                });
            });
        }
    };

    const buttonPlaceholderSetters: ButtonPlaceholderSetters = {
        setUseRules: (useRules: boolean): void => {
            withPlaceholder((placeholder) => {
                const placeholderContent = placeholder.placeholderContent as ButtonPlaceholderContent;
                let isFallbackEmpty = true;
                let staticFallback = deepCloneObj(placeholderContent.staticFallback);

                if (useRules) {
                    for (const [key, value] of Object.entries(placeholderContent.noRules.show.actionShow.OpenUrl)) {
                        const fallbackPart: ButtonShowContentV9<StaticTextAtoms, StaticRichTextAtoms>[ButtonShowContentItemElement] = staticFallback.actionShow.OpenUrl[key];
                        const isFallbackPartContentEmpty = !fallbackPart.content.some(atom => !!atom);
                        const isPartContentStatic = !value.content.some((atom) => typeof atom.content !== "string");
                        if (!isFallbackPartContentEmpty) {
                            isFallbackEmpty = false;
                        }
                        if (isFallbackPartContentEmpty && isPartContentStatic) {
                            staticFallback.actionShow.OpenUrl[key] = deepCloneObj(value);
                        }
                    }
                }

                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: {
                        ...placeholderContent,
                        useRules,
                        staticFallback: isFallbackEmpty ? staticFallback : placeholderContent.staticFallback
                    }
                });
            });
        },
        setNoRulesContentItem: (elem: ButtonShowContentItemElement, value: DynamicTextShow, isContentSameAsButtonText: boolean) => {
            withPlaceholder((placeholder) => {
                const content: DynamicTextAtoms = getTextAtoms(value);
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateContentItem(placeholder.placeholderContent, elem, content, isContentSameAsButtonText)
                });
            });
        },
        setNoRulesContentItemRichText: (value: DynamicRichTextAtoms) => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateContentItem(placeholder.placeholderContent, ButtonShowContentItemElement.Text, value, true)
                });
            });
        },
        setStaticFallbackContentItem: (elem: ButtonShowContentItemElement, value: DynamicTextShow, isContentSameAsButtonText: boolean) => {
            withPlaceholder((placeholder) => {
                const content = getTextAtoms(value) as StaticTextAtoms;
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateStaticFallbackContentItem(placeholder.placeholderContent, elem, content, isContentSameAsButtonText)
                });
            });
        },
        setStaticFallbackContentItemRichText: (content: StaticRichTextAtoms) => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateStaticFallbackContentItem(placeholder.placeholderContent, ButtonShowContentItemElement.Text, content, true)
                });
            });
        },
        updateCaseShow: (elem: ButtonShowContentItemElement, value: DynamicTextShow, isContentSameAsButtonText: boolean, caseIndex: number) => {
            withPlaceholder((placeholder) => {
                const content: DynamicTextAtoms = getTextAtoms(value);
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateButtonCaseShow(placeholder.placeholderContent, elem, content, isContentSameAsButtonText, caseIndex)
                });
            });
        },
        updateCaseShowRichText: (value: DynamicRichTextAtoms, caseIndex: number) => {
            withPlaceholder((placeholder) => {
                updatePlaceholder({
                    ...placeholder,
                    placeholderContent: updateButtonCaseShow(placeholder.placeholderContent, ButtonShowContentItemElement.Text, value, true, caseIndex)
                });
            });
        }
    };

    const avatarPlaceholderSetters: AvatarPlaceholderSetters = {
        setShow: (show: StaticAvatarShowV1) => {
            withPlaceholder((placeholder) => {
                const currentCrop = getAvatarCropSetting(placeholder.placeholderSettings).value.crop;
                updateAvatarPlaceholder({
                    ...placeholder,
                    placeholderContent: updateAvatarStaticShow(placeholder.placeholderContent, show),
                    placeholderSettings: isCropModeDisabledForAvatarId(show.avatarId, currentCrop)
                        ? updatePlaceholderSettingAtom(
                            placeholder.placeholderSettings,
                            DEFAULT_CONTENT,
                            AvatarSettingsKey.Crop,
                            {
                                useValue: true,
                                value: {
                                    crop: replaceDisabledCropMode(currentCrop)
                                }
                            } as AvatarCropSetting
                        )
                        : placeholder.placeholderSettings
                }).catch(() => {
                    // Ignore error
                });
            });
        },
        setCrop(
            crop,
            foregroundPosition,
            updateFlexiblePlaceholderPosition
        ) {
            withPlaceholder(async (placeholder) => {
                let response: FetchResult<GqlClientUpdateEditorAvatarPlaceholderMutation>;
                try {
                    response = await updateAvatarPlaceholder({
                        ...placeholder,
                        placeholderSettings: updatePlaceholderSettingAtom(
                            placeholder.placeholderSettings,
                            DEFAULT_CONTENT,
                            AvatarSettingsKey.Crop,
                            {
                                useValue: true,
                                value: {
                                    crop
                                }
                            } as AvatarCropSetting
                        )
                    });
                }
                catch (e) {
                    // Ignore error
                }
                if (!response) return;
                const updatedPlaceholder = response.data?.updateEditorAvatarPlaceholder.editorPlaceholders.find(
                    placeholder => placeholder.id === placeholderId
                );
                if (!updatedPlaceholder) return;
                const { flexibleSettings } = updatedPlaceholder;
                updateFlexiblePlaceholderPosition(
                    placeholder.id,
                    {
                        x: foregroundPosition.rawPosition.width * flexibleSettings.left / 100,
                        y: foregroundPosition.rawPosition.height * flexibleSettings.top / 100,
                        width: foregroundPosition.rawPosition.width * flexibleSettings.width / 100,
                        height: foregroundPosition.rawPosition.height * flexibleSettings.height / 100
                    }
                );
            });
        }
    };

    return { sharedSetters, textPlaceholderSetters, mediaPlaceholderSetters, narrationPlaceholderSetters, buttonPlaceholderSetters, avatarPlaceholderSetters };
}

const isShowTextEmpty = (showText: StaticTextAtoms | StaticRichTextAtoms): boolean => {
    return showText?.some(atom => !!atom.content);
};

const getTextAtoms = (dynamicTextShow: DynamicTextShow): DynamicTextAtoms => {
    return dynamicTextShow?.map(value => ({ content: value })) || [];
};

//reset bold and italic styles
const resetRichTextAtomFormatting = <T>(richTextAtom: RichTextAtom<T>): RichTextAtom<T> => {
    return { 
        ...richTextAtom, 
        style: {
            ...richTextAtom.style,
            bold: RichTextStyleSetting.UNSET,
            italic: RichTextStyleSetting.UNSET
        } 
    };
};

const resetRichTextAtomsFormatting = <T>(richTextAtoms: RichTextAtom<T>[]): RichTextAtom<T>[] => {
    return richTextAtoms.map(resetRichTextAtomFormatting);
};

const resetPlaceholderRichTextFormatting = (placeholderContent: TextPlaceholderContent): TextPlaceholderContent => {
    const updatedPlaceholderContent: TextPlaceholderContent = deepCloneObj(placeholderContent);
    updatedPlaceholderContent.noRules.show = resetRichTextAtomsFormatting(updatedPlaceholderContent.noRules.show);
    updatedPlaceholderContent.staticFallback = resetRichTextAtomsFormatting(updatedPlaceholderContent.staticFallback);
    updatedPlaceholderContent.rules.cases = updatedPlaceholderContent.rules.cases.map((variation) => {
        return {
            ...variation,
            show: resetRichTextAtomsFormatting(variation.show)
        };
    });
    return updatedPlaceholderContent;
};

// region update avatar placeholder

type UpdateAvatarPlaceholderContent = (phContent: AvatarPlaceholderContent, ...args) => AvatarPlaceholderContent;

export const updateAvatarStaticShow: UpdateAvatarPlaceholderContent = (phContent, staticShow: StaticAvatarShowV1) => {
    const updatedPhContent = deepCloneObj(phContent);
    updatedPhContent.noRules.show.static = staticShow;
    updatedPhContent.noRules.show.showType = PlaceholderShowType.Static;
    return updatedPhContent;
};
// endregion

// region update media placeholder
// use (and add) typed functions to enforce placeholder content shape
type UpdateMediaPlaceholderContent = (phContent: MediaPlaceholderContent, ...args) => MediaPlaceholderContent;

export const updateMediaStaticShow: UpdateMediaPlaceholderContent = (phContent, staticShow: StaticMediaShowV8) => {
    const updatedPhContent = deepCloneObj(phContent);
    updatedPhContent.noRules.show.static = deepCloneObj(staticShow);
    return updatedPhContent;
};

export const updateMediaCaseStaticShow: UpdateMediaPlaceholderContent = (phContent, staticShow: StaticMediaShowV8, index: number) => {
    const updatedPhContent = deepCloneObj(phContent);
    const newCases = updatedPhContent.rules.cases.slice(0);
    newCases[index] = { ...newCases[index], show: { ...newCases[index].show, static: deepCloneObj(staticShow) } };
    updatedPhContent.rules.cases = newCases;
    return updatedPhContent;
};

const updateMediaDynamicShow: UpdateMediaPlaceholderContent = (phContent, dynamic: DataElementDescriptor) => {
    const updatedPhContent = deepCloneObj(phContent);
    updatedPhContent.noRules.show.dynamic = dynamic;
    return updatedPhContent;
};

export const updateMediaCaseDynamicShow: UpdateMediaPlaceholderContent = (phContent, dynamic: DataElementDescriptor, index: number) => {
    const updatedPhContent = deepCloneObj(phContent);
    const newCases = updatedPhContent.rules.cases.slice(0);
    newCases[index] = { ...newCases[index], show: { ...newCases[index].show, dynamic } };
    updatedPhContent.rules.cases = newCases;
    return updatedPhContent;
};

const updateMediaPhShowType: UpdateMediaPlaceholderContent = (phContent, mediaShowType: PlaceholderShowType) => {
    const updatedPhContent = deepCloneObj(phContent);
    updatedPhContent.noRules.show.showType = mediaShowType;

    // copy static media to fallback if it's empty
    if (mediaShowType === PlaceholderShowType.Dynamic) {
        updateMediaFallbackIfNeeded(updatedPhContent);
    }
    return updatedPhContent;
};

const updateMediaUseRules: UpdateMediaPlaceholderContent = (phContent, useRules) => {
    const updatedPhContent = deepCloneObj(phContent);
    updatedPhContent.useRules = useRules;

    if (useRules && !phContent?.useRules) {
        updateMediaFallbackIfNeeded(updatedPhContent);
    }
    return updatedPhContent;
};

export const updateMediaCasePhShowType: UpdateMediaPlaceholderContent = (phContent, mediaShowType: PlaceholderShowType, index: number) => {
    const updatedPhContent = deepCloneObj(phContent);
    const newCases = updatedPhContent.rules.cases.slice(0);
    newCases[index] = { ...newCases[index], show: { ...newCases[index].show, showType: mediaShowType } };
    updatedPhContent.rules.cases = newCases;

    return updatedPhContent;
};

export const updateMediaStaticFallback: UpdateMediaPlaceholderContent = (phContent, staticFallback: StaticMediaShowV8) => {
    return {
        ...phContent,
        staticFallback: deepCloneObj(staticFallback)
    };
};

// endregion

// region update CTA button placeholder
const updateButtonShowContentItems = (
    buttonShow: ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms>,
    elemName: ButtonShowContentItemElement,
    content: StaticTextAtoms | StaticRichTextAtoms | DynamicTextAtoms | DynamicRichTextAtoms,
    isContentSameAsButtonText: boolean
): ButtonShowV9<DynamicTextAtoms, DynamicRichTextAtoms> => {
    const { useAction, actionShow } = buttonShow;
    return {
        ...buttonShow,
        actionShow: {
            ...actionShow,
            [useAction]: {
                ...actionShow[useAction],
                [elemName]: {
                    content,
                    isContentSameAsButtonText
                }
            }
        }
    };
};

const updateContentItem =
    (phContent: ButtonPlaceholderContent, elem: ButtonShowContentItemElement, content: DynamicTextAtoms | DynamicRichTextAtoms, isContentSameAsButtonText: boolean) => {
        const updatedPhContent = deepCloneObj(phContent);
        updatedPhContent.noRules.show = updateButtonShowContentItems(updatedPhContent.noRules.show, elem, content, isContentSameAsButtonText);
        return updatedPhContent;
    };

const updateStaticFallbackContentItem =
    (phContent: ButtonPlaceholderContent, elem: ButtonShowContentItemElement, content: StaticTextAtoms | StaticRichTextAtoms, isContentSameAsButtonText: boolean) => {
        const updatedPhContent = deepCloneObj(phContent);
        updatedPhContent.staticFallback = updateButtonShowContentItems(updatedPhContent.staticFallback, elem, content, isContentSameAsButtonText) as StaticButtonShowV9;
        return updatedPhContent;
    };

const updateButtonCaseShow =
    (phContent: ButtonPlaceholderContent, elem: ButtonShowContentItemElement, content: DynamicTextAtoms | DynamicRichTextAtoms, isContentSameAsButtonText: boolean, caseIndex: number) => {
        const updatedPhContent = deepCloneObj(phContent);
        updatedPhContent.rules.cases[caseIndex].show = updateButtonShowContentItems(updatedPhContent.rules.cases[caseIndex].show, elem, content, isContentSameAsButtonText);
        return updatedPhContent;
    };
// endregion

// region placeholder settings
type UpdatePlaceholderSettings = (phSettings: PlaceholderSettingsVersion10, ...args) => PlaceholderSettingsVersion10;

export const updatePlaceholderSettingAtom: UpdatePlaceholderSettings = (
    phSettings: PlaceholderSettingsVersion10,
    content: string,
    settingKey: SettingKey,
    setting: SettingAtoms
): PlaceholderSettingsVersion10 => {
    const updatedSettings = deepCloneObj(phSettings);
    const settings = updatedSettings.contents?.[content].settings;
    settings[settingKey] = setting;
    return updatedSettings;
};

// endregion
