import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { getApolloClient } from "../../../apollo";
import type { GqlClientHelpCenterEntriesQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { HelpCenterEntriesDocument } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { EditHelpCenterData, HelpCenterData } from "../../editor/types";
import { editHelpCenterDataVar, editHelpCenterEnabled, editHelpCenterOpenVar, helpCenterDataVar, helpCenterOpenVar } from "../../model";
import { PendoService } from "../../pendoService";
import { isFeatureEnabled } from "./featureFlags";

export function helpCenterArticleUrl(articleId: string, sectionId?: string): string {
    return `https://help.sundaysky.com/hc/en-us/articles/${articleId}${sectionId ? `#${sectionId}` : ""}`;
}

export function closeHelpCenter(): void {
    helpCenterOpenVar(false);
}

export function setHelpCenterData(data: HelpCenterData): void {
    helpCenterDataVar(data);
}

export enum InAppHelpCenterTrackingSource {
    Editor = "Editor",
    SearchResult = "SearchResult",
    RelatedArticle = "RelatedArticle",
    InternalLink = "InternalLink" // Currently not tracked
}

export type OpenArticleInHelpCenterOptions<Variants extends Record<string, string>> = {
    buttonId: string;
    articleId: string;
    sectionId?: string;
    anchorRef?: HTMLElement;
} & ((
    {
        trackingSource: InAppHelpCenterTrackingSource.Editor;
        trackingDialogName: string;
    } & ({
        variants: Variants;
        selectedVariant: Extract<keyof Variants, string>;
    } | {
        variants?: never;
        selectedVariant?: never;
    })
) | {
    trackingSource: InAppHelpCenterTrackingSource.SearchResult;
    trackingQuery: string;
    trackingOriginArticleId: string;
    trackingOriginSectionId: string;
    trackingOriginArticleTitle: string;
    trackingTargetArticleTitle: string;
} | {
    trackingSource: InAppHelpCenterTrackingSource.RelatedArticle;
    trackingOriginArticleId: string;
    trackingOriginSectionId: string;
    trackingOriginArticleTitle: string;
    trackingTargetArticleTitle: string;
} | {
    trackingSource: InAppHelpCenterTrackingSource.InternalLink;
}) ;

export async function openArticleInHelpCenter<
    Variants extends Record<string, string> = never
>(
    options: OpenArticleInHelpCenterOptions<Variants>
): Promise<void> {
    const isEditHelpCenterLinksEnabled = isFeatureEnabled(featureFlagConst._EDITOR_EDIT_HELP_CENTER_LINKS);
    if (
        getEditHelpCenterEnabled()
        && options.trackingSource === InAppHelpCenterTrackingSource.Editor
    ) {
        setEditHelpCenterData({
            buttonId: options.buttonId,
            variants: options.variants,
            selectedVariant: options.selectedVariant,
            anchorEl: options.anchorRef
        });
        setEditHelpCenterOpen(true);
        return;
    }

    const current = helpCenterDataVar();
    const rect = options?.anchorRef?.getBoundingClientRect();

    let articleId = options.articleId;
    let sectionId = options.sectionId;
    if (
        isEditHelpCenterLinksEnabled
        && options.trackingSource === InAppHelpCenterTrackingSource.Editor
    ) {
        const client = getApolloClient();
        const { data } = await client.query<GqlClientHelpCenterEntriesQuery>({
            query: HelpCenterEntriesDocument
        });
        const entry = data?.helpCenterEntries.find(entry => (
            entry.buttonId === options.buttonId
            && entry.variant === (options.selectedVariant ?? null)
        ));
        if (!entry) return;
        articleId = entry.articleId;
        sectionId = entry.sectionId;
    }

    helpCenterDataVar({
        articleHistory: (current?.articleHistory || []).concat({
            articleId: articleId,
            sectionId: sectionId
        }),
        anchorPosition: current?.anchorPosition
            ? current.anchorPosition
            : { top: rect.top, left: rect.left }
    });
    helpCenterOpenVar(true);

    if (options.trackingSource === InAppHelpCenterTrackingSource.Editor) {
        PendoService.getInstance().trackEvent(
            "Help center open dialog",
            {
                dialogName: options.trackingDialogName,
                targetHelpArticle: helpCenterArticleUrl(articleId, sectionId)
            }
        );
    }
    if (options.trackingSource === InAppHelpCenterTrackingSource.SearchResult) {
        PendoService.getInstance().trackEvent(
            "Help center search",
            {
                originHelpArticle: helpCenterArticleUrl(options.trackingOriginArticleId, options.trackingOriginSectionId),
                originHelpArticleName: options.trackingOriginArticleTitle,
                targetHelpArticle: helpCenterArticleUrl(articleId, sectionId),
                targetHelpArticleName: options.trackingTargetArticleTitle,
                searchString: options.trackingQuery
            }
        );
    }
    if (options.trackingSource === InAppHelpCenterTrackingSource.RelatedArticle) {
        PendoService.getInstance().trackEvent(
            "Help center related article clicked",
            {
                originHelpArticle: helpCenterArticleUrl(options.trackingOriginArticleId, options.trackingOriginSectionId),
                originHelpArticleName: options.trackingOriginArticleTitle,
                targetHelpArticle: helpCenterArticleUrl(articleId, sectionId),
                targetHelpArticleName: options.trackingTargetArticleTitle
            }
        );
    }
}

export function navigateBackInHelpCenter(): void {
    const current = helpCenterDataVar();
    const articleHistory = current?.articleHistory || [];
    articleHistory.pop();
    helpCenterDataVar({
        articleHistory,
        anchorPosition: current?.anchorPosition
    });
}

export function getEditHelpCenterEnabled(): boolean {
    return editHelpCenterEnabled();
}

export function setEditHelpCenterEnabled(value: boolean): void {
    editHelpCenterEnabled(value);
}

export function setEditHelpCenterData(data: EditHelpCenterData): void {
    editHelpCenterDataVar(data);
}

export function setEditHelpCenterOpen(value: boolean): void {
    editHelpCenterOpenVar(value);
}

export function preloadHelpCenterEntries(): void {
    const client = getApolloClient();
    void client.query({ query: HelpCenterEntriesDocument });
}
