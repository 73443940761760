import type {
    GqlClientEditEditorAssetMediaInput,
    GqlClientEditEditorAssetMediaMutation,
    GqlClientEditEditorAssetMediaMutationVariables,
    GqlClientEditorAssetEditedPayload,
    GqlClientEditorAssetEditedSubscriptionVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EditEditorAssetMediaDocument, EditorAssetEditedDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { FetchResult, MutationOptions } from "@apollo/client";
import type { LegacyAssetEditMetadata, BackgroundMutationCallbacks, BackgroundMutationPayloadResult, OnFileEditErrorFn, OnFileEditSuccessFn, ProgramId } from "../../types";
import { getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../../../common/errors";
import type { GraphQLError } from "graphql";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import { executeBackgroundMutation } from "../BackgroundMutation/backgroundMutation";
import { getEditorAccountId } from "../Account";
import type { SubscriptionOptions } from "@apollo/client/core/watchQueryOptions";


export async function editEditorAssetMedia(metadata: LegacyAssetEditMetadata, programId: ProgramId, onSuccess: OnFileEditSuccessFn, onFailure: OnFileEditErrorFn):
    Promise<{ error?: Error, result?: FetchResult<GqlClientEditEditorAssetMediaMutation> }> {
    try {
        if (metadata) {
            const accountId = getEditorAccountId();

            const uploadEditorAssetInput: GqlClientEditEditorAssetMediaInput = {
                parentMediaId: metadata?.parentMediaId,
                presentedMediaName: metadata?.presentedMediaName,
                processingData: metadata?.processingData,
                programId,
                postEditingData: metadata?.postEditingData
            };

            const options: MutationOptions<GqlClientEditEditorAssetMediaMutation, GqlClientEditEditorAssetMediaMutationVariables> = {
                mutation: EditEditorAssetMediaDocument,
                variables: {
                    input: uploadEditorAssetInput
                }
            };

            const callbacks: BackgroundMutationCallbacks<GqlClientEditorAssetEditedPayload> = {
                onProgress: null,
                onFailure,
                onSuccess
            };

            const subscriptionOptions: SubscriptionOptions<GqlClientEditorAssetEditedSubscriptionVariables, BackgroundMutationPayloadResult<GqlClientEditorAssetEditedPayload>> = {
                query: EditorAssetEditedDocument,
                variables: {
                    accountId
                }
            };

            return { result: await executeBackgroundMutation<GqlClientEditorAssetEditedPayload, GqlClientEditEditorAssetMediaMutation>(options, subscriptionOptions, callbacks) };
        }
        else {
            return {
                error: new EnhancedError(
                    new Error("No given trimming information was to perform the action"),
                    SskyErrorCode.AssetMediaEditError)
            };
        }
    }
    catch (error) {
        const gqlError: GraphQLError = error.graphQLErrors?.[0];
        const sskyErrorCode = gqlError ? getSskyErrorCodeFromGqlError(gqlError) : SskyErrorCode.UnexpectedClientError;

        return { error: new EnhancedError(gqlError || error, sskyErrorCode) };
    }
}
