import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import { faEllipsisH as farEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons/faExternalLinkSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Theme } from "@mui/material";
import { Box, Button, Card, ClickAwayListener, Divider, Icon, IconButton, Link, ListItemButton, ListItemText, Paper, Popover, Popper, styled, Typography } from "@mui/material";
import type { SxProps } from "@mui/system";
import { createDraggableTransition, scrollOnHoverSxProps, Search } from "@sundaysky/smartvideo-hub-truffle-component-library";
import { debounce } from "lodash";
import type { FC, FocusEventHandler } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TruffleCircularProgress } from "../../../../../../newNav/components/common/TruffleCircularProgress";
import { PendoService } from "../../../../../../pendoService";
import { useHelpCenterData, useHelpCenterOpen } from "../../../../../../newNav/hooks/useInAppHelpCenter";
import { closeHelpCenter, helpCenterArticleUrl, InAppHelpCenterTrackingSource, navigateBackInHelpCenter, openArticleInHelpCenter, setHelpCenterData } from "../../../../../../newNav/nooks/helpCenter";
import { dialogXButtonSxProps } from "../../../common/CommonSxProps";
import type { ArticleData } from "../../../utils/zendeskUtils";
import { fetchZendeskArticle, searchZendeskArticles } from "../../../utils/zendeskUtils";
import { SpinnerContainerSxProps } from "./DataLibrary/DataLibraryUtils";


const contactSupportImage = require("../../../../../../../images/svg/in-app-help-center-contact-support.svg");

const popoverContentSxProps: (isSearchClicked: boolean) => SxProps<Theme> = (isSearchClicked) => ({
    ...scrollOnHoverSxProps,
    scrollbarGutter: "auto",
    paddingTop: "16px",
    paddingLeft: "16px",
    overflow: "auto",
    height: isSearchClicked ? "calc(100% - 120px)" : "calc(100% - 57px)",
    display: "flex",
    flexDirection: "column",
    gap: "8px"
});

const StyledDraggableIcon = styled(FontAwesomeIcon)({
    position: "absolute",
    top: "-4px",
    left: "50%",
    transform: "translateX(-50%)",
    color: "white",
    "& svg": {
        width: "16px",
        height: "16px"
    }
});

const popperContentSxProps: SxProps<Theme> = {
    ...scrollOnHoverSxProps,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    width: "400px",
    py: "8px",
    px: "0px",
    minHeight: "48px",
    maxHeight: "200px",
    mx: "6px",
    borderRadius: "4px"
};

const noResultsContainerProps: SxProps<Theme> = {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
};

const StyledIframe = styled("iframe")({
    border: "none",
    width: "100%"
});

const TransitionComponent = createDraggableTransition({
    draggableProps: {
        handle: ".header",
        bounds: "parent"
    }
});

type ArticleIframeMessage = {
    type: "openArticle";
    articleId: string;
    sectionId?: string;
} | {
    type: "articleResize";
    height: number;
};

export const HelpCenterPopover: FC = (): JSX.Element => {
    const helpCenterData = useHelpCenterData();
    const open = useHelpCenterOpen();

    return (
        <Popover
            open={open}
            anchorPosition={helpCenterData?.anchorPosition}
            anchorReference="anchorPosition"
            hideBackdrop
            disableEnforceFocus
            sx={(theme) => ({ pointerEvents: "none", zIndex: theme.zIndex.snackbar - 1 })}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            TransitionComponent={TransitionComponent}
            TransitionProps={{
                onExited: () => setHelpCenterData(null)
            }}
        >
            <HelpCenterPopoverContent/>
        </Popover>
    );
};

export const HelpCenterPopoverContent: FC = (): JSX.Element => {
    const helpCenterData = useHelpCenterData();
    const [articleContent, setArticleContent] = useState<ArticleData>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const searchResultsAnchorRef = useRef<HTMLDivElement>();
    const [articleSearchResultsOpen, setArticleSearchResultsOpen] = useState(false);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const iframeRef = useRef<HTMLIFrameElement>();
    const contentRef = useRef<HTMLDivElement>();

    const currentArticle = helpCenterData?.articleHistory?.[helpCenterData.articleHistory.length - 1];
    const canNavigateBack = (helpCenterData?.articleHistory?.length || 0) > 1;

    useEffect(
        () => {
            const handleMessage = (event: MessageEvent<ArticleIframeMessage>) => {
                if (event.data.type === "openArticle") {
                    void openArticleInHelpCenter({
                        buttonId: "3e8755bf-a23e-45b3-92d2-d53a6da204ef",
                        articleId: event.data.articleId,
                        sectionId: event.data.sectionId,
                        trackingSource: InAppHelpCenterTrackingSource.InternalLink
                    });
                }
                if (event.data.type === "articleResize") {
                    iframeRef.current.style.height = `${event.data.height}px`;
                }
            };
            window.addEventListener("message", handleMessage);
            return () => window.removeEventListener("message", handleMessage);
        },
        []
    );

    useEffect(
        () => {
            if (currentArticle.sectionId && iframeRef.current) {
                iframeRef.current.contentWindow.location.hash = `#${currentArticle.sectionId}`;
            }
        },
        [currentArticle.articleId, currentArticle.sectionId]
    );

    useEffect(() => {
        fetchZendeskArticle(currentArticle.articleId)
            .then((data) => {
                setArticleContent(data);
            });
    }, [currentArticle.articleId]);

    const onFocus: FocusEventHandler<HTMLInputElement> = () => {
        setArticleSearchResultsOpen(true);
    };

    const closeSearch = () => {
        setArticleSearchResultsOpen(false);
    };

    const renderSearchResults = () => {
        if (searchResults.length === 0 && searchTerm !== "") {
            return (
                <Box sx={noResultsContainerProps}>
                    <Typography>
                        {`No results for "${searchTerm}"`}
                    </Typography>
                </Box>
            );
        }

        return searchResults.map((article) => (
            <ListItemButton
                key={article.id}
                onClick={() => {
                    void openArticleInHelpCenter({
                        buttonId: "59a9f944-b3ef-4a52-b244-c2631a6ec157",
                        articleId: article.id,
                        trackingSource: InAppHelpCenterTrackingSource.SearchResult,
                        trackingQuery: searchTerm,
                        trackingOriginArticleId: currentArticle.articleId,
                        trackingOriginSectionId: currentArticle.sectionId,
                        trackingOriginArticleTitle: articleContent?.title,
                        trackingTargetArticleTitle: article.title
                    });
                    setSearchResults([]);
                }}
            >
                <ListItemText primary={boldSubString(article.title, searchTerm)}/>
            </ListItemButton>
        ));
    };

    const debouncedSetSearchResults = useCallback(debounce((searchTerm: string) => {
        searchZendeskArticles(searchTerm).then((data) => setSearchResults(data));
    }, 500), []);

    const boldSubString = (str: string, autoCompleteVal: string) => {
        const boldIndex = str.toLowerCase().indexOf(autoCompleteVal);
        if (boldIndex >= 0) {
            return (
                <>
                    {str.substring(0, boldIndex)}
                    <b>
                        {str.substring(boldIndex, boldIndex + autoCompleteVal.length)}
                    </b>
                    {str.substring(boldIndex + autoCompleteVal.length, str.length)}
                </>
            );
        }
        return <>{str}</>;
    };

    const topRightButtons = (): JSX.Element => {
        return (
            <Box>
                <IconButton
                    sx={{
                        ...dialogXButtonSxProps,
                        color: isSearchClicked ? "primary.main" : "action.active",
                        backgroundColor: isSearchClicked ? "action.selected" : "transparent"
                    }}
                    onClick={() => {
                        setShowSearchInput(!showSearchInput);
                        setIsSearchClicked(!isSearchClicked);
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} style={{ fontSize: "16px" }}/>
                </IconButton>
                <IconButton
                    sx={dialogXButtonSxProps}
                    data-tracking-id="tracking-id-help-center-open-in-tab"
                    onClick={() => window.open(helpCenterArticleUrl(currentArticle.articleId, currentArticle.sectionId), "_blank")}
                >
                    <FontAwesomeIcon icon={faExternalLinkSquare} style={{ fontSize: "16px" }}/>
                </IconButton>
                <IconButton
                    sx={dialogXButtonSxProps}
                    onClick={closeHelpCenter}
                >
                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: "16px" }}/>
                </IconButton>
            </Box>
        );
    };

    return (
        <Paper
            elevation={24}
            sx={{ paddingBottom: "16px", pointerEvents: "auto", height: "500px", width: "450px", resize: "both", overflow: "auto" }}
        >
            <Box className="header" sx={{ cursor: "move", position: "relative", backgroundColor: "secondary.main", height: "8px", width: "100%" }}>
                <StyledDraggableIcon icon={farEllipsisH}/>
            </Box>

            <Box sx={{ padding: "8px 8px 8px 16px", height: "48px", display: "flex", alignItems: "center" }}>
                {canNavigateBack && (
                    <IconButton
                        sx={dialogXButtonSxProps}
                        data-tracking-id="tracking-id-help-center-navigate-back"
                        onClick={navigateBackInHelpCenter}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "16px" }}/>
                    </IconButton>
                )}
                <Typography
                    variant="subtitle1"
                    sx={{ marginRight: "auto" }}
                >
                    Help Center
                </Typography>

                {topRightButtons()}
            </Box>

            {!showSearchInput && <Divider sx={{ width: "100%" }}/>}

            {showSearchInput && (
                <Box sx={{ width: "100%", backgroundColor: "action.hover", padding: "16px" }}>
                    <ClickAwayListener onClickAway={closeSearch}>
                        <Box ref={searchResultsAnchorRef}>
                            <Search
                                sx={{ height: "32px", backgroundColor: "white", ".MuiOutlinedInput-notchedOutline": { border: "1px solid primary.light" } }}
                                value={searchTerm}
                                onFocus={onFocus}
                                placeholder="Search articles..."
                                onChange={async (event) => {
                                    setSearchTerm(event.target.value);
                                    debouncedSetSearchResults(event.target.value);
                                }}
                                onClear={() => {
                                    setSearchTerm("");
                                    setSearchResults([]);
                                }}
                                numberOfResults={searchResults?.length}
                            />
                        </Box>
                    </ClickAwayListener>
                    <Popper
                        open={articleSearchResultsOpen}
                        disablePortal
                        anchorEl={searchResultsAnchorRef.current}
                        placement={"bottom"}
                    >
                        {searchTerm !== "" && (
                            <Card sx={popperContentSxProps} elevation={8}>
                                {renderSearchResults()}
                            </Card>
                        )}
                    </Popper>
                </Box>
            )}

            <Box ref={contentRef} sx={popoverContentSxProps(isSearchClicked)}>
                {articleContent
                    ? <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <Typography variant={"h6"} sx={{ fontWeight: "600" }} color={"secondary.main"}>
                            {articleContent.title}
                        </Typography>
                        <StyledIframe
                            ref={iframeRef}
                            title="Help Center Article"
                            srcDoc={articleContent.body}
                            onLoad={(e) => {
                                contentRef.current?.scrollTo({ behavior: "instant", top: 0 });
                                if (currentArticle.sectionId) {
                                    e.currentTarget.contentWindow.location.hash = `#${currentArticle.sectionId}`;
                                }
                            }}
                        />
                        <Box>
                            <Typography variant={"subtitle2"} sx={{ marginBottom: "8px" }}>More articles from this section</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                {articleContent.relatedArticles.map((article) => (
                                    <Box key={article.id} sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                        <Link
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                void openArticleInHelpCenter({
                                                    buttonId: "ef034fe5-65ce-490b-8167-4f5a64286eb9",
                                                    articleId: article.id,
                                                    trackingSource: InAppHelpCenterTrackingSource.RelatedArticle,
                                                    trackingOriginArticleId: currentArticle.articleId,
                                                    trackingOriginSectionId: currentArticle.sectionId,
                                                    trackingOriginArticleTitle: articleContent.title,
                                                    trackingTargetArticleTitle: article.title
                                                });
                                            }}
                                            variant={"body1"}
                                        >
                                            {article.title}
                                        </Link>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", marginTop: "16px" }}>
                            <Icon component={"img"} src={contactSupportImage} alt="Contact Support" sx={{ width: "304px", height: "81px" }}/>
                            <Box>
                                <Typography variant={"h5"} color={"secondary.main"}>Still have questions?</Typography>
                                <Typography variant={"body1"} sx={{ marginTop: "8px" }}>SundaySky Support is here for you</Typography>
                            </Box>
                            <Button
                                variant={"contained"}
                                size={"medium"}
                                color={"primary"}
                                endIcon={<FontAwesomeIcon icon={faExternalLinkSquare} />}
                                sx={{ width: "fit-content", marginBottom: "16px" }}
                                onClick={() => {
                                    PendoService.getInstance().trackEvent(
                                        "Help center contact support clicked",
                                        {
                                            originHelpArticle: helpCenterArticleUrl(currentArticle.articleId, currentArticle.sectionId),
                                            originHelpArticleName: articleContent.title
                                        }
                                    );
                                    window.open("https://sundaysky.zendesk.com/hc/en-us/requests/new", "_blank");
                                }}
                            >
                                Contact Support
                            </Button>
                        </Box>
                    </Box>
                    : <Box sx={SpinnerContainerSxProps}>
                        <TruffleCircularProgress/>
                    </Box>
                }
            </Box>
        </Paper>
    );
};
