import type { LpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import { getSskyErrorCodeFromGqlError, SskyErrorCode } from "../../../../../common/errors";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreateOrUpdateLandingPageInput,
    GqlClientCreateOrUpdateLandingPageMutation,
    GqlClientCreateOrUpdateLandingPageMutationVariables,
    GqlClientEditorProgram,
    GqlClientEditorProgramForLandingPageBuilderQuery,
    GqlClientEditorProgramForLandingPageBuilderQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { CreateOrUpdateLandingPageDocument, EditorProgramForLandingPageBuilderDocument, GqlClientAssetLifecycleStage } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";
import { isFeatureEnabled } from "../../../newNav/nooks/featureFlags";
import { localEditorInitialLandingPageConfigurationVar, localEditorIsLpChangingActiveState, localEditorLandingPageConfigurationVar } from "../../State";
import { getCurrentFullStoryUrl } from "../../Utils";
import { createInitialLpConfiguration, getInitialLpConfigurationInput, updateConfigurationWithCurrentSettings } from "../../Utils/LandingPageBuilder";
import { handleEditorError } from "../Notification";


export type LandingPageDetails = {
    id: string,
    updated: string,
    isActive: boolean,
    lifecycleStage: GqlClientAssetLifecycleStage,
    updatedBy: string
};

export const setLandingPageConfiguration = (configuration: LpConfiguration) => {
    localEditorLandingPageConfigurationVar(configuration);
};
export const setLandingPageInitialConfiguration = (configuration: LpConfiguration) => {
    localEditorInitialLandingPageConfigurationVar(configuration);
};

export const resetLandingPageConfiguration = () => {
    localEditorLandingPageConfigurationVar(localEditorInitialLandingPageConfigurationVar());
};

export const getLandingPageConfiguration = () => {
    return localEditorLandingPageConfigurationVar();
};

export type CreateOrUpdateLandingPageInput = {
    programId: GqlClientEditorProgram["id"],
    landingPageDetails: LandingPageDetails;
    editedConfiguration?: LpConfiguration;
};

export const createOrUpdateLandingPage = async ({
    programId,
    landingPageDetails,
    editedConfiguration
}: CreateOrUpdateLandingPageInput) => {
    const client = getApolloClient();
    const isNewVideoPageEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);

    const configuration = isNewVideoPageEnabled
        ? editedConfiguration || await getUpdatedLandingPageConfiguration(programId)
        : getLandingPageConfiguration();
    const createOrUpdateLandingPageInput: GqlClientCreateOrUpdateLandingPageInput = {
        program_id: programId,
        landing_page_id: landingPageDetails?.id,
        configuration,
        is_active: landingPageDetails?.isActive ?? false,
        lifecycle_stage: landingPageDetails?.lifecycleStage ?? GqlClientAssetLifecycleStage.ENABLED,
        fsUrl: getCurrentFullStoryUrl()
    };

    try {
        await client.mutate<GqlClientCreateOrUpdateLandingPageMutation, GqlClientCreateOrUpdateLandingPageMutationVariables>({
            mutation: CreateOrUpdateLandingPageDocument,
            variables: { createOrUpdateLandingPageInput }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};

export const setEditorIsLpChangingActiveState = (isChangingActiveState: boolean) => {
    localEditorIsLpChangingActiveState(isChangingActiveState);
};

export const changeLandingPageActiveState = async (
    programId: string,
    landingPageDetails: LandingPageDetails,
    isActive: boolean
) => {
    try {
        setEditorIsLpChangingActiveState(true);
        await createOrUpdateLandingPage({
            programId,
            landingPageDetails: { ...landingPageDetails, isActive }
        });
        setEditorIsLpChangingActiveState(false);
    }
    catch (error) {
        setEditorIsLpChangingActiveState(false);
        handleEditorError({ error, sskyCode: isActive ? SskyErrorCode.LandingPageActivationError : SskyErrorCode.LandingPageDeactivationError });
    }
};

export async function getUpdatedLandingPageConfiguration(programId: string): Promise<LpConfiguration> {
    const client = getApolloClient();
    const { data } = await client.query<
        GqlClientEditorProgramForLandingPageBuilderQuery,
        GqlClientEditorProgramForLandingPageBuilderQueryVariables
    >({
        query: EditorProgramForLandingPageBuilderDocument,
        variables: {
            programId
        }
    });
    const updateConfigurationInput = getInitialLpConfigurationInput(data.editorProgram);
    if (data?.editorProgram?.landingPage?.configuration) {
        return updateConfigurationWithCurrentSettings(
            data.editorProgram.landingPage.configuration,
            updateConfigurationInput
        );
    }
    else {
        return createInitialLpConfiguration(updateConfigurationInput);
    }
}
