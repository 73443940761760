import { useMemo } from "react";
import type { GqlClientEditorAssetFolderFragment, GqlClientEditorAssetLibraryMediaFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useAssetLibraryMedia } from "./useAssetLibraryMedia";


export type UseMediaFolderOutput = {
    name: string | undefined;
    parentFolder: GqlClientEditorAssetFolderFragment | null;
    folders: GqlClientEditorAssetFolderFragment[];
    media: GqlClientEditorAssetLibraryMediaFragment[];
    selectedFolderId: string;
    pathFromRoot: { id: string, name: string }[];
    rootFolder: GqlClientEditorAssetFolderFragment;
    foldersById: Map<string, GqlClientEditorAssetFolderFragment>;
    foldersByParentFolderId: Map<string, GqlClientEditorAssetFolderFragment[]>;
    mediaByFolderId: Map<string, GqlClientEditorAssetLibraryMediaFragment[]>;
} | null;

export const useMediaFolder = (folderId: string | null = null): UseMediaFolderOutput => {
    const { rootFolder, foldersById, foldersByParentFolderId, mediaByFolderId, pathFromRootToFoldersById } = useAssetLibraryMedia();

    const selectedFolderId = folderId ?? rootFolder?.id;
    const selectedFolder = foldersById.get(selectedFolderId);
    if (!selectedFolder) {
        return null;
    }
    const pathFromRoot = pathFromRootToFoldersById.get(selectedFolderId);

    return {
        name: selectedFolder.name,
        parentFolder: foldersById.get(selectedFolder.parentFolder?.id) ?? null,
        folders: foldersByParentFolderId.get(selectedFolderId) ?? [],
        media: mediaByFolderId.get(selectedFolderId) ?? [],
        selectedFolderId,
        pathFromRoot,
        rootFolder,
        foldersById,
        foldersByParentFolderId,
        mediaByFolderId
    };
};
