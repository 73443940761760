import { getApolloClient } from "../../../../apollo";
import { UpsertUserSettingsDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { 
    GqlClientUpsertUserSettingsInput,
    GqlClientUpsertUserSettingsMutation,
    GqlClientUpsertUserSettingsMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getSskyErrorCodeFromGqlError } from "../../../../../common/errors";
import { EnhancedError } from "../../../errorBoundary/Components/EnhancedError";

export const setEditorExternalLibraries = async (externalLibraries: GqlClientUpsertUserSettingsInput) => {
    try {
        await getApolloClient().mutate<GqlClientUpsertUserSettingsMutation, GqlClientUpsertUserSettingsMutationVariables>({
            mutation: UpsertUserSettingsDocument,
            variables: { upsertUserSettingsInput: externalLibraries }
        });
    }
    catch (err) {
        const gqlError = err.graphQLErrors?.[0];
        const sskyErrorCode = getSskyErrorCodeFromGqlError(gqlError);

        throw new EnhancedError(gqlError || err, sskyErrorCode);
    }
};
