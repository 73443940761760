import type { Lineup, ProgramVersionId } from "../../types";
import { useEditorSceneLineupQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { setProjectLineup } from "../../Nooks";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseProjectLineup = {
    projectLineup: Lineup;
    setProjectLineup: (
        programVersionId: ProgramVersionId,
        projectLineup: Lineup,
        optimistic: boolean
    ) => void;
};

export const useProjectLineup = (): UseProjectLineup => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data } = useEditorSceneLineupQuery({
        variables: { programId, programVersionId },
        skip: !programId || !programVersionId
    });

    const projectLineup = data?.editorProgram?.programVersion?.sceneLineup ?? [];

    return { projectLineup, setProjectLineup };
};
