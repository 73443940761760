import React from "react";
import ReactTooltip from "react-tooltip";
import LogicDataSelector from "../logic/LogicDataSelector/LogicDataSelector";
import HubSemanticDropDown from "../../legacyCommon/HubSemanticDropDown";
import HubContentEditable from "../../legacyCommon/HubContentEditable";
import HubNotifications from "../../../logic/common/hubNotifications";
import MappingTablesUtils from "../../../logic/MappingTables/MappingTablesUtils";
import { SskyMenu } from "@sundaysky/ui-component-library/Components";
// @ts-ignore
import { Button, Table } from "semantic-ui-react";
import "./HubMappingTableData.css";
import { getAssetTitleFromName } from "../../../logic/vlx/editorLogicUtils";
import { getDataElementDisplayName, getDataElementId } from "../../../logic/DataElements/DataElementsManager";
import { ASSET_TYPES, CURATED_ASSETS_MEDIA_TYPES } from "../../../logic/vlx/consts";
import StylePreview from "../logic/StylePreview";
import { CELL } from "./HubMappingTable";
import HubAddActionButton from "../../legacyCommon/ComponentLibrary/Buttons/HubAddActionButton";
import type { Asset } from "../../../../common/types/asset";
import type { DataElement } from "../../../../common/types/dataElement";
import type { ActionableData } from "../../../../common/types/logic";

type ValidationResult = { result: boolean };

export interface HubMappingTableDataProps {
    assets: Asset[],
    dataElements: DataElement[],
    inputHeaders: any[],
    outputHeaders: any[],
    rows: any[],
    onRowAdd: (index: number) => void,
    onRowRemove: (index: number) => void,
    onRowClear: (index: number) => void,
    onInputHeaderChange: (header: ActionableData) => void,
    onOutputHeaderChange: (title: string, e: Event) => void,
    onOutputColumnAdd: (index: number) => void,
    onOutputColumnRemove: (index: number) => void,
    onOutputColumnClear: (index: number) => void,
    onOutputTypeChange: (type: any) => void,
    onCellChanged: (value: any, colIndex: number, rowIndex: number, cellType?: string) => void,
    isViewOnly: boolean,
    outputHeadersValidation: ValidationResult[]
}

export interface HubMappingTableDataState {
    rows: any[],
    rowPopUpAnchor: JSX.Element,
    rowIndex: number,
    headerPopUpAnchor: JSX.Element,
    headerIndex: number,
    showDataElementsPopUp: boolean
}

class HubMappingTableData extends React.Component<HubMappingTableDataProps, HubMappingTableDataState> {
    constructor(props) {
        super(props);
        this.state = {
            rows: props.rows,
            rowPopUpAnchor: null,
            rowIndex: -1,
            headerPopUpAnchor: null,
            headerIndex: -1,
            showDataElementsPopUp: undefined
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let { rows } = this.state;

        if (this.props.rows !== nextProps.rows) {
            rows = nextProps.rows;
        }

        this.setState({
            rows
        });
    }

    handleClickOutside = () => {
        this.setState({ showDataElementsPopUp: false });
    };

    handleAssetClickOutside = (rowIndex, index) => {
        let { rows } = this.state;
        rows[rowIndex].cells[index].showAssetsPopUp = false;
        this.setState({ rows, showDataElementsPopUp: false });
    };

    onInputHeaderClicked = () => {
        this.setState({ showDataElementsPopUp: true });
    };

    onDataElementsChange = (item: ActionableData) => {
        this.setState({ showDataElementsPopUp: false });
        this.props.onInputHeaderChange(item);
    };

    onAssetCellClicked = (index, rowIndex) => {
        let { rows } = this.state;
        rows[rowIndex].cells[index].showAssetsPopUp = true;
        this.setState({ rows });
    };

    onAssetChange = (item, index, rowIndex) => {
        let { rows } = this.state;
        rows[rowIndex].cells[index].showAssetsPopUp = false;
        this.props.onCellChanged(item, index, rowIndex, CELL.Media.cellType);
        this.setState({ rows });
    };

    rowContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            rowPopUpAnchor: e.target,
            rowIndex: parseInt(e.target.id)
        });
    };

    onRowAddAbove = () => {
        this.props.onRowAdd(this.state.rowIndex);
        this.handleRowPopUpClose();
    };

    onRowAddBelow = () => {
        this.props.onRowAdd(this.state.rowIndex + 1);
        this.handleRowPopUpClose();
    };

    onRowRemove = () => {
        HubNotifications.confirm("Remove Row", "Are you sure you want to remove the row?", () => {
            this.props.onRowRemove(this.state.rowIndex);
            this.handleRowPopUpClose();
        });
    };

    onRowClear = () => {
        HubNotifications.confirm("Clear Row", "Are you sure you want to clear the row?", () => {
            this.props.onRowClear(this.state.rowIndex);
            this.handleRowPopUpClose();
        });
    };

    headerContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            headerPopUpAnchor: e.target,
            headerIndex: parseInt(e.target.id)
        });
    };

    onOutputColumnAddAfter = () => {
        this.props.onOutputColumnAdd(this.state.headerIndex + 1);
        this.handleHeaderPopUpClose();
    };

    onOutputColumnAddBefore = () => {
        this.props.onOutputColumnAdd(this.state.headerIndex);
        this.handleHeaderPopUpClose();
    };

    onOutputColumnRemove = () => {
        HubNotifications.confirm("Remove Column", "Are you sure you want to remove the column?", () => {
            this.props.onOutputColumnRemove(this.state.headerIndex);
            this.handleHeaderPopUpClose();
        });
    };

    onOutputColumnClear = () => {
        HubNotifications.confirm("Clear Column", "Are you sure you want to clear the column?", () => {
            this.props.onOutputColumnClear(this.state.headerIndex);
            this.handleHeaderPopUpClose();
        });
    };

    handleHeaderPopUpClose = () => {
        this.setState({
            headerPopUpAnchor: null,
            headerIndex: -1
        });
    };

    handleRowPopUpClose = () => {
        this.setState({
            rowPopUpAnchor: null,
            rowIndex: -1
        });
    };

    renderInputHeaders = (header, index) => {
        // header.value should hold a(n old) copy of a data element, get the latest display name
        const dataElement = (this.props.dataElements || []).find((de) => getDataElementId(de) === getDataElementId(header.value));
        const displayName = getDataElementDisplayName(dataElement) || header.value.name || header.value.id;

        let headerStyle = {
            padding: "5px 11px",
            backgroundColor: "#F2FBFD"
        };

        let inputHeaderClassName = header.missing ? "missingDataElement" : "dataElement";
        if (this.props.isViewOnly) {
            inputHeaderClassName += " read-only";
        }

        let content = (
            <div className="inputHeader">
                {header.missing && <ReactTooltip id={"tooltip_" + index} delayShow={100} place="top" effect="solid" />}

                <div className={inputHeaderClassName} onClick={this.onInputHeaderClicked} data-for={"tooltip_" + index} data-tip="Missing Data Element">
                    {displayName}
                </div>

                {this.state.showDataElementsPopUp && !this.props.isViewOnly && (
                    <LogicDataSelector
                        dataElements={this.props.dataElements}
                        value={header.value}
                        selectOnClick={true}
                        onChange={this.onDataElementsChange}
                        handleClickOutside={this.handleClickOutside}
                    />
                )}
            </div>
        );

        return <Table.HeaderCell style={headerStyle} key={index} content={content} />;
    };

    renderOutputHeaders = (header, index) => {
        let headerStyle = {
            padding: "5px 11px",
            backgroundColor: "#F2FBFD"
        };

        let outputHeadersTypes;
        if (this.props.isViewOnly) {
            const value = this.props.outputHeaders[index].type ? this.props.outputHeaders[index].type.name : "?";
            outputHeadersTypes = <div className="col-xs-2 outputHeaderType read-only">{value}</div>;
        }
        else {
            let ddItems = [
                { name: CELL.String.char, title: CELL.String.title, id: index },
                { name: CELL.Media.char, title: CELL.Media.title, id: index }
            ];
            ddItems.push({ name: CELL.Color.char, title: CELL.Color.title, id: index });
            ddItems.push({ name: CELL.URL.char, title: CELL.URL.title, id: index }); // url should be after color (UX request)
            outputHeadersTypes = (
                <HubSemanticDropDown
                    className="col-xs-2 outputHeaderType"
                    value={this.props.outputHeaders[index].type ? this.props.outputHeaders[index].type.name : "?"}
                    items={ddItems}
                    onChange={this.props.onOutputTypeChange}
                />
            );
        }

        let content = (
            <div className="outputHeader row">
                {outputHeadersTypes}

                <HubContentEditable
                    id={index.toString()}
                    className="col-xs-9 outputHeaderValue"
                    value={header.text}
                    placeholder="Enter Text"
                    onConfirm={this.props.onOutputHeaderChange}
                    disabled={this.props.isViewOnly}
                />
                {!this.props.isViewOnly && <div id={index} className="col-xs-1 headerContextMenuIcon" onClick={this.headerContextMenu} />}
            </div>
        );

        return <Table.HeaderCell style={headerStyle} key={index} content={content} />;
    };

    renderInputCell = (index, rowIndex, cell) => {
        return (
            <Table.Cell style={{ background: "white" }} key={index}>
                <HubContentEditable
                    id={rowIndex.toString() + "/" + index.toString()}
                    className="dataCell"
                    value={cell.text}
                    onConfirm={(value) => this.props.onCellChanged(value, index, rowIndex)}
                    disabled={this.props.isViewOnly}
                />
            </Table.Cell>
        );
    };

    // renders both string and url cell types
    renderStringCell = (index, rowIndex, cell, type) => {
        return (
            <Table.Cell key={index}>
                <HubContentEditable
                    id={rowIndex.toString() + "/" + index.toString()}
                    className="dataCell"
                    value={cell.text}
                    onConfirm={(value) => this.props.onCellChanged(value, index, rowIndex, type)}
                    disabled={this.props.isViewOnly}
                />
            </Table.Cell>
        );
    };

    renderMediaCell = (index, rowIndex, cell) => {
        let assets = this.props.assets.filter(
            (asset) =>
                asset.type === ASSET_TYPES.curated &&
                (asset.mediaType === CURATED_ASSETS_MEDIA_TYPES.Video || asset.mediaType === CURATED_ASSETS_MEDIA_TYPES.Image || asset.mediaType === CURATED_ASSETS_MEDIA_TYPES.Audio) &&
                !asset.deleted &&
                !asset.archived
        );

        // Check if for some reason the asset is not preset (backward compatibility or asset was archived)
        if (!cell.missing && !assets.find((asset) => asset.name === cell.value.name)) {
            cell.missing = true;
        }

        let assetTitle = getAssetTitleFromName(cell.value.name, assets);

        let content = (
            <div className={cell.missing ? "missingAssetCell" : "assetCell"}>
                {assetTitle}
                {!this.props.isViewOnly && <Button className="icon ellipsis" onClick={() => this.onAssetCellClicked(index, rowIndex)} />}

                {cell.showAssetsPopUp && !this.props.isViewOnly && (
                    <LogicDataSelector
                        assets={assets}
                        value={cell.value}
                        onChange={(item) => this.onAssetChange(item, index, rowIndex)}
                        handleClickOutside={() => this.handleAssetClickOutside(rowIndex, index)}
                    />
                )}
            </div>
        );

        return <Table.Cell key={index} content={content} />;
    };

    renderColorCell = (index, rowIndex, cell) => {
        return (
            <Table.Cell key={index}>
                <StylePreview style={cell.color} readOnly={this.props.isViewOnly} onChange={(value) => this.props.onCellChanged(value, index, rowIndex, CELL.Color.cellType)} />
            </Table.Cell>
        );
    };

    renderIndexCell = (rowIndex) => {
        let cellStyle = {
            backgroundColor: "#F2FBFD",
            color: "#777777",
            fontWeight: 500,
            padding: 0,
            textAlign: "center",
            minWidth: "40px"
        };

        let content = (
            <div id={rowIndex} className="indexCell" onContextMenu={this.rowContextMenu}>
                {parseInt(rowIndex) + 1}
            </div>
        );

        return <Table.Cell key={rowIndex + "_cell"} content={content} style={cellStyle} />;
    };

    headerPopUpItems = [
        { content: "Add column before", onClick: this.onOutputColumnAddBefore },
        { content: "Add column after", onClick: this.onOutputColumnAddAfter },
        { content: "Clear column", onClick: this.onOutputColumnClear },
        { content: "Remove column", onClick: this.onOutputColumnRemove }
    ];

    rowPopUpItems = [
        { content: "Insert row above", onClick: this.onRowAddAbove },
        { content: "Insert row below", onClick: this.onRowAddBelow },
        { content: "Clear row", onClick: this.onRowClear },
        { content: "Remove row", onClick: this.onRowRemove }
    ];

    render() {
        let inputHeaders = this.props.inputHeaders.map((header, index) => {
            return this.renderInputHeaders(header, index);
        });

        let outputHeaders = this.props.outputHeaders.map((header, index) => {
            return this.renderOutputHeaders(header, index);
        });

        let rows = this.state.rows.map((row, rowIndex) => {
            let cells = row.cells.map((cell, index) => {
                if (index === 0) {
                    return this.renderInputCell(index, rowIndex, cell);
                }

                const outputHeaderIndex = index - 1;
                const { type } = this.props.outputHeaders[outputHeaderIndex];
                if (type) {
                    const cellType = MappingTablesUtils.getTypeFromTitle(type.title);
                    if (cellType === CELL.String.cellType || cellType === CELL.URL.cellType) {
                        return this.renderStringCell(index, rowIndex, cell, cellType);
                    }
                    else if (cellType === CELL.Media.cellType) {
                        return this.renderMediaCell(index, rowIndex, cell);
                    }
                    else if (cellType === CELL.Color.cellType) {
                        return this.renderColorCell(index, rowIndex, cell);
                    }
                }

                return <Table.Cell key={index} />;
            });

            cells.splice(0, 0, this.renderIndexCell(rowIndex));

            return <Table.Row key={rowIndex}>{cells}</Table.Row>;
        });

        let cellsCount = this.state.rows[0].cells.length.toString();
        return (
            <div className="hubMappingTableData">
                <Table celled structured className="mappingTable">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ backgroundColor: "#F2FBFD" }} rowSpan={"2"} />
                            <Table.HeaderCell
                                style={{
                                    backgroundColor: "#F2FBFD",
                                    color: "#777777",
                                    fontSize: "12px",
                                    fontWeight: 700
                                }}>
                                input
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                style={{
                                    backgroundColor: "#F2FBFD",
                                    color: "#777777",
                                    fontSize: "12px",
                                    fontWeight: 700
                                }}
                                colSpan={cellsCount}>
                                output
                                <span
                                    style={{
                                        color: "#D72828",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        marginLeft: "17px"
                                    }}>
                                    {this.props.outputHeadersValidation.some((validation) => !validation.result) ? "Enter column type & name" : ""}
                                </span>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            {inputHeaders}
                            {outputHeaders}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>{rows}</Table.Body>
                </Table>

                <SskyMenu open={Boolean(this.state.headerPopUpAnchor)} anchor={this.state.headerPopUpAnchor} onClose={this.handleHeaderPopUpClose} items={this.headerPopUpItems} />
                <SskyMenu open={Boolean(this.state.rowPopUpAnchor)} anchor={this.state.rowPopUpAnchor} onClose={this.handleRowPopUpClose} items={this.rowPopUpItems} />
                {!this.props.isViewOnly && <HubAddActionButton onClick={() => this.props.onRowAdd(this.state.rows.length)} tooltip={"Add row"} dataTestId="dataTable-add-row" />}
            </div>
        );
    }
}

export default HubMappingTableData;
