import {
    useAccountDataLibraryForImportDataChangedSubscription,
    useDataLibraryImportDataUpdatedSubscription,
    useDataLibraryImportHistoryChangedSubscription
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";


export const useGetLinksSubscriptions = (dataLibraryId: string): void => {

    useDataLibraryImportHistoryChangedSubscription({
        variables: { dataLibraryId },
        skip: !dataLibraryId
    });

    useDataLibraryImportDataUpdatedSubscription({
        variables: { dataLibraryId },
        skip: !dataLibraryId
    });

    useAccountDataLibraryForImportDataChangedSubscription({
        variables: { dataLibraryId },
        skip: !dataLibraryId
    });

};

