import React from "react";
import PropTypes from "prop-types";
import { getElementInfoFromActionableData } from "../../../../logic/vlx/editorLogicUtils";

const SelectedItemSection = (props) => {
    const renderChildren = () => {
        if (!props.children) return null;

        // Avoid nulls
        let children = props.children.length ? props.children.filter((c) => c && (!Array.isArray(c) || c.length)) : [props.children];

        if (!children || (Array.isArray(children) && !children.length)) return null;

        return children.map((child, idx) => {
            if (!child) return;
            if (Array.isArray(child)) {
                if (child.length > 1) return child;
                child = child.pop();
            }
            const childProps = { ...(child.props || {}) };
            const containerClass = childProps["parent-class-name"] || "";
            delete childProps["parent-class-name"];

            return (
                <li key={idx} className={containerClass}>
                    <child.type {...childProps} />
                </li>
            );
        });
    };
    const selectedValueObj = getElementInfoFromActionableData(props.selectedValue, props.dataFields) || {};
    const selectedValue = selectedValueObj.displayName;

    return (
        <div className="popup-selected">
            <ul>
                <button className="remove-selected" id="modify-panel-remove-selected" data-testid="modify-panel-remove-selected-x-button" onClick={props.onSelectedItemRemoveClicked}>
                    x
                </button>
                <li className="selected-title">{selectedValue !== "\n" ? selectedValue : "Line Break"}</li>
                {renderChildren()}
            </ul>
        </div>
    );
};

SelectedItemSection.propTypes = {
    selectedValue: PropTypes.object,
    dataFields: PropTypes.shape({
        assets: PropTypes.array,
        dataElements: PropTypes.array
    }),
    onSelectedItemRemoveClicked: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
        PropTypes.bool // To support conditional rendering
    ])
};

export default SelectedItemSection;
