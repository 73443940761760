import { useProgramVersionEditorBrandRulesUpdatedSubscription } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { localBrandByAudienceFallbackIdVar } from "../../State";
import { getBrands, setBrandByAudienceFallbackId } from "../../Nooks";
import type { ProgramVersionId } from "../../types";
import { useEditorProgramId } from "../UseEditorProgramId";

export const useEditorProgramVersionBrandRulesSubscriptions = (programVersionId: ProgramVersionId): void => {
    const programId = useEditorProgramId();
    useProgramVersionEditorBrandRulesUpdatedSubscription({
        variables: { programVersionId },
        onData: (options) => {
            const newSavedData = options.data.data.programVersionEditorBrandRulesUpdated;
            if (newSavedData.bcUseRules && newSavedData.bcRules?.fallback) {
                const brandId = getBrands(programId, programVersionId).find(brand => brand.localId === newSavedData.bcRules.fallback).id;
                if (brandId !== localBrandByAudienceFallbackIdVar()) {
                    setBrandByAudienceFallbackId(brandId);
                }                
            }
            else if (!newSavedData.bcUseRules && localBrandByAudienceFallbackIdVar()) {
                setBrandByAudienceFallbackId(null);
            }
        }
    });
};
