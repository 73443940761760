import {
    useEditorStudioElementsQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import {
    convertProgramDynamicElementToStudioElement,
    convertSystemDynamicElementToStudioElement,
    getSystemElements, isReservedIdWord
} from "../../Utils";
import type { StudioElementExtended } from "../../types";
import { useMemo } from "react";
import { useEditorDataConnectorEnabled } from "../UseEditorDataConnectorEnabled";
import { useEditorDynamicElements } from "../UseEditorDynamicElements";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseStudioElements = {
    studioElements: StudioElementExtended[];
};

export const useStudioElements = (): UseStudioElements => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { data: studioElementsData } = useEditorStudioElementsQuery({ variables: { programId, programVersionId } });
    const studioElements = studioElementsData?.editorProgram?.programVersion?.studioElements ?? [];

    const { dynamicElements: programDynamicElements } = useEditorDynamicElements(programId, programVersionId);
    const dynamicElementsAsStudioElements = useMemo(() => {
        const programDynamicElementsAsStudioElements = programDynamicElements.map(de => convertProgramDynamicElementToStudioElement(de, false));

        const systemElements = getSystemElements();
        const systemElementsAsStudioElements = systemElements.map(convertSystemDynamicElementToStudioElement);

        return programDynamicElementsAsStudioElements.concat(systemElementsAsStudioElements);
    }, [programDynamicElements]);

    const { editorDataConnectorEnabled } = useEditorDataConnectorEnabled();
    const outputStudioElements = editorDataConnectorEnabled
        ? dynamicElementsAsStudioElements
        : studioElements;

    return { studioElements: outputStudioElements };
};

export type ByAudienceStudioElementsOption = {
    id: string;
    label: string;
    disabled: boolean;
}

export const useByAudienceStudioElementOptions = (studioElements: StudioElementExtended[]): ByAudienceStudioElementsOption[] => {
    const eligibleStudioElements: ByAudienceStudioElementsOption[] = useMemo(() => {
        return studioElements
            .filter(studioElement => !isReservedIdWord(studioElement.name))
            .map(studioElement => ({
                id: studioElement.localId,
                label: studioElement.name,
                disabled: !studioElement.valueSet?.length
            }));
    }, [studioElements]);

    return eligibleStudioElements;
};
