import type { GqlClientAcquiaDamCategoryFragment, GqlClientAcquiaDamSubCategoriesQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useAcquiaDamSubCategoriesQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloQueryResult } from "@apollo/client";

type UseAcquiaDamSubCategories = {
    loadingCategories: boolean;
    currentCategory: GqlClientAcquiaDamCategoryFragment;
    subCategories: GqlClientAcquiaDamCategoryFragment[];
    reFetchSubCategories: () => Promise<ApolloQueryResult<GqlClientAcquiaDamSubCategoriesQuery>>;
    subCategoriesError: boolean;
};

export const useAcquiaDamSubCategories = (path: string): UseAcquiaDamSubCategories => {
    const { data, loading, refetch, error } = useAcquiaDamSubCategoriesQuery({ variables: { path } });
    const currentCategory = data?.acquiaCategory;
    const subCategories = data?.acquiaCategory?.subCategories || [];

    return { loadingCategories: loading, currentCategory, subCategories, reFetchSubCategories: refetch, subCategoriesError: !!error };
};
