import { useReactiveVar } from "@apollo/client";
import { localEditorProcessedFilesVar } from "../../State";
import type { ProcessedFile } from "../../types";

export type UseProcessedFiles = {
    processedFiles: ProcessedFile[];
    clearProcessedFiles: () => void;
};

export const useProcessedFiles = (): UseProcessedFiles => {
    const processedFiles = useReactiveVar(localEditorProcessedFilesVar);

    const clearProcessedFiles = (): void => {
        localEditorProcessedFilesVar([]);
    };

    return { processedFiles, clearProcessedFiles };
};