import { useEffect, useReducer, useState } from "react";
import { getApolloClient } from "../../../../apollo";
import { initLocalStateForEditor } from "../../State";
import type {
    GqlClientEditorAccountLevelDataAvatarsQuery,
    GqlClientEditorAccountLevelDataBrandsQuery,
    GqlClientEditorAccountLevelDataMusicQuery,
    GqlClientEditorProgramVersionQuery as EditorProgramVersionQuery,
    GqlClientEditorProgramVersionQueryVariables as EditorProgramVersionQueryVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EditorProgramVersionDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { EditorProgramVersionAction, EditorProgramVersionErrorAction, EditorProgramVersionSetupState, EditorProgramVersionSuccessAction } from "./reducer";
import { EditorProgramVersionActionType, editorProgramVersionInitialState, editorProgramVersionReducer } from "./reducer";
import type { QueryOptions } from "@apollo/client/core/watchQueryOptions";
import {
    useAccountDataLibraryForProgramVersionSubscriptions,
    useAccountDataLibraryVersionSubscription,
    useEditorProgramVersionBrandRulesSubscriptions,
    useEditorProgramVersionSceneSubscriptions
} from "../UseEditorSubscriptions";
import type { ApolloQueryResult } from "@apollo/client";
import { getAccountEditorLevelAvatarsData, getAccountEditorLevelBrandData, getAccountEditorLevelMusicData, loadAccountEditorLevelFontsData } from "../../Nooks";

type AccountLevelDataArray = [
    ApolloQueryResult<EditorProgramVersionQuery>,
    ApolloQueryResult<GqlClientEditorAccountLevelDataBrandsQuery>,
    ApolloQueryResult<GqlClientEditorAccountLevelDataMusicQuery>,
    ApolloQueryResult<GqlClientEditorAccountLevelDataAvatarsQuery>,
    () => void
];

const getEditorProgramAndAccountLevelData = async (programId: string, programVersionId: string, isDraft: boolean): Promise<AccountLevelDataArray> => {
    const fetchOptions: QueryOptions<EditorProgramVersionQueryVariables> = {
        query: EditorProgramVersionDocument,
        variables: { programId, programVersionId }
    };
    if (process.env.JEST_WORKER_ID === undefined && isDraft) {
        fetchOptions.fetchPolicy = "network-only";
    }

    return Promise.all([
        getApolloClient().query<EditorProgramVersionQuery, EditorProgramVersionQueryVariables>(fetchOptions),
        getAccountEditorLevelBrandData(),
        getAccountEditorLevelMusicData(),
        getAccountEditorLevelAvatarsData(),
        loadAccountEditorLevelFontsData()
    ]);
};

export const useEditorProgramVersion = (programId: string, programVersionId: string, isDraft: boolean = true): EditorProgramVersionSetupState => {
    const [state, dispatch] = useReducer(editorProgramVersionReducer, editorProgramVersionInitialState);
    const dispatchAction = <A extends EditorProgramVersionAction>(action: A): void => {
        dispatch(action);
    };
    const [accountDataLibraryVersionId, setAccountDataLibraryVersionId] = useState<string | null>(null);

    useEffect(() => {
        setAccountDataLibraryVersionId(null);
        getEditorProgramAndAccountLevelData(programId, programVersionId, isDraft)
            .then(([editorProgramResult, editorAccountLevelBrandDataResult, _ ]) => {
                const { editorProgram } = editorProgramResult.data;
                const { brandConfigurationVersion } = editorAccountLevelBrandDataResult.data.editorAccountLevelData;
                initLocalStateForEditor({ editorProgram, brandConfigurationVersion });
                dispatchAction<EditorProgramVersionSuccessAction>({ type: EditorProgramVersionActionType.Success, payload: null });
                setAccountDataLibraryVersionId(editorProgram.programVersion.accountDataLibraryVersion?.id);
            })
            .catch((err) => {
                dispatchAction<EditorProgramVersionErrorAction>({ type: EditorProgramVersionActionType.Error, payload: err });
            });
    }, [programId, programVersionId]);

    useEditorProgramVersionSceneSubscriptions(programVersionId);
    useAccountDataLibraryVersionSubscription(accountDataLibraryVersionId);
    useEditorProgramVersionBrandRulesSubscriptions(programVersionId);
    useAccountDataLibraryForProgramVersionSubscriptions(programVersionId);
    return state;
};
