import type { GqlClientEditorStockLibraryMusicFragment,
    GqlClientEditorStockMusicAiRecommendationSearchQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useEditorAccount } from "../UseEditorAccount";
import {
    useEditorStockMusicAiRecommendationSearchQuery
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloQueryResult } from "@apollo/client";
import { NetworkStatus } from "@apollo/client/core";
import { useEditorProgramId } from "../UseEditorProgramId";
import { useEditorProgramVersionId } from "../UseEditorProgramVersionId";

export type UseStockLibraryMusic = {
    loading: boolean;
    totalSearchResults: number;
    assetLibraryMusic: GqlClientEditorStockLibraryMusicFragment[];
    refetch: () => Promise<ApolloQueryResult<GqlClientEditorStockMusicAiRecommendationSearchQuery>>;
};

export const useAIRecommendedStockLibraryMusic = (page: number): UseStockLibraryMusic => {
    const programId = useEditorProgramId();
    const programVersionId = useEditorProgramVersionId();
    const { editorAccountId } = useEditorAccount();

    const { data, loading, refetch, networkStatus } = useEditorStockMusicAiRecommendationSearchQuery(
        { variables: { userID: editorAccountId, projectID: programId, programVersionID: programVersionId, page },
            notifyOnNetworkStatusChange: true });

    const assetLibraryMusic = data?.editorStockMusicAIRecommendationSearch?.tracks ?? [];
    const totalSearchResults = data?.editorStockMusicAIRecommendationSearch?.numberOfResults ?? 0;

    return { totalSearchResults, assetLibraryMusic, loading: loading || networkStatus === NetworkStatus.refetch, refetch };
};
