import { EventType, Rive, RiveEventType } from "@rive-app/canvas";
import type { StateMachineInput } from "@rive-app/react-canvas";
import { Alignment, Fit, Layout } from "@rive-app/react-canvas";
import { closeRetakeDialog, openRetakeDialog, removeCountdownAnimation, removeSettings, removeTimeLimitScreen, showCountdownAnimationPipWindow } from "../pipWindow/pipWindow";

const pipControls = require("../../../../../images/screen_record_pip_control.riv");
const countdown = require("../../../../../images/screen_record_countdown.riv");

const STATE_MACHINE = "State Machine 1";

export enum RecordingEvents {
    Play = "Play Event",
    Stop = "Stop Event",
    Pause = "Pause Event",
    Record = "Record Event",
    StartRecording = "Start Record",
    Restart = "Restart Event",
    Retake = "Dialogue Retake"
}

export enum RiveEvents {
    LoadingFinished = "Loading Finished",
    Enable = "Enable",
    Refresh = "Refresh"
}

enum HoverEvents {
    RefreshHover = "Refresh Hover",
    RefreshUnHover = "Refresh Unhover",
    StopHover = "Stop Hover",
    StopUnHover = "Stop Unhover",
    PausePlayHover = "Puase/Play Hover",
    PausePlayUnHover = "Pause/Play Unhover"
}

/**
 * Sends a message to the provided MessagePort.
 */
const sendMessage = (messagePort: MessagePort, eventName: RecordingEvents): void => {
    messagePort?.postMessage(eventName);
};

/**
 * Handles hover-related cursor changes.
 */
const handleHoverEvent = (canvas: HTMLCanvasElement, eventName: HoverEvents): void => {
    const hoverEvents = new Set([
        HoverEvents.RefreshHover,
        HoverEvents.StopHover,
        HoverEvents.PausePlayHover
    ]);

    canvas.style.cursor = hoverEvents.has(eventName) ? "pointer" : "default";
};

/**
 * Finds and fires a state machine input by name
 */
const fireInput = (rive: Rive, stateMachineName: string, inputName: string): void => {
    const inputs = rive.stateMachineInputs(stateMachineName);
    inputs?.find((i: StateMachineInput) => i.name === inputName)?.fire();
};

/**
 * Handles the restart recording flow
 */
const handleRestart = (rive: Rive, pipWindow: Window, messagePort: MessagePort): void => {
    openRetakeDialog(
        pipWindow,
        () => {
            closeRetakeDialog(pipWindow);
            removeTimeLimitScreen(pipWindow);
            fireInput(rive, STATE_MACHINE, RecordingEvents.Retake);
            sendMessage(messagePort, RecordingEvents.Retake);
        },
        () => closeRetakeDialog(pipWindow)
    );
};

/**
 * Handles Rive events and dispatches actions accordingly.
 */
const onRiveEventReceived = (
    riveEvent: any,
    rive: Rive,
    pipWindow: Window,
    canvas: HTMLCanvasElement,
    messagePort: MessagePort
): void => {
    const { name } = riveEvent.data;

    if (Object.values(RecordingEvents).includes(name as RecordingEvents)) {
        sendMessage(messagePort, name as RecordingEvents);

        switch (name) {
            case RecordingEvents.Stop:
                window.focus();
                break;
            case RecordingEvents.Record:
                showCountdownAnimationPipWindow(pipWindow, messagePort);
                break;
            case RecordingEvents.Restart:
                handleRestart(rive, pipWindow, messagePort);
                break;
        }
    }

    else if (Object.values(HoverEvents).includes(name as HoverEvents)) {
        handleHoverEvent(canvas, name as HoverEvents);
    }
};

/**
 * Creates the main recording animation window
 */
export const createAnimatedRecordWindow = (pipWindow: Window, canvas: HTMLCanvasElement, messagePort: MessagePort) => {

    const rive = new Rive({
        canvas,
        src: pipControls,
        stateMachines: "State Machine 1",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center
        }),
        onLoad: () => {
            rive.resizeDrawingSurfaceToCanvas();
        }
    });

    rive.on(EventType.RiveEvent, (event) =>
        onRiveEventReceived(event, rive, pipWindow, canvas, messagePort)
    );

    messagePort.onmessage = (event) => {

        if (event.data === RiveEvents.LoadingFinished) {
            fireInput(rive, STATE_MACHINE, RiveEvents.Enable);
        }
        else if (event.data === RecordingEvents.StartRecording) {
            removeCountdownAnimation(pipWindow);
            fireInput(rive, STATE_MACHINE, RecordingEvents.StartRecording);
            removeSettings(pipWindow);
        }
        else if (event.data === RecordingEvents.Restart) {
            fireInput(rive, STATE_MACHINE, RiveEvents.Refresh);
        }
    };

    return rive;
};

/**
 * Creates the countdown animation
 */
export const countdownAnimation = (canvas: HTMLCanvasElement, messagePort: MessagePort) => {
    const rive = new Rive({
        canvas,
        src: countdown,
        stateMachines: "State Machine 1",
        autoplay: true,
        layout: new Layout({ alignment: Alignment.Center }),
        onLoad: () => rive.resizeDrawingSurfaceToCanvas()
    });

    rive.on(EventType.RiveEvent, (event: any) => {
        if (event.data.type === RiveEventType.General && event.data.name === RecordingEvents.StartRecording) {
            sendMessage(messagePort, RecordingEvents.StartRecording);
        }
    });

    return rive;
};
