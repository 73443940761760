import React from "react";
import PropTypes from "prop-types";
import { Tab } from "semantic-ui-react";

import "./LogicPopupContainer.css";
import enhanceWithClickOutside from "react-click-outside";

class LogicPopupContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: props.selectedIndex
        };
    }

    handleClickOutside = this.props.handleClickOutside || (() => {});

    renderComponent(ChildComponent, isTab = false) {
        const { footer, title } = ChildComponent.props;
        const contentClassName = ChildComponent.props["data-content-class-name"];
        const rest = Object.assign({}, ChildComponent.props);
        // Remove so it won't render on DOM
        delete rest.footer;
        delete rest.title;
        delete rest["data-content-class-name"];

        const popupContentClassName = "logic-popup-content" + (contentClassName ? " " + contentClassName : "");

        return (
            <div>
                {!isTab && (
                    <div className="logic-popup-title">
                        <span>{title}</span>
                    </div>
                )}
                <div className={popupContentClassName}>
                    <ChildComponent.type {...rest} />
                </div>
                {footer && <div className="logic-popup-footer clearfix">{footer}</div>}
            </div>
        );
    }

    getTabPanesFromChildren(children) {
        if (children.length < 2) return;
        // Make tabs stretch vs fit the popup
        const className = children.length == 2 ? "stretch" : "fit";
        return children.map((childComponent) => {
            return {
                menuItem: { key: childComponent.props.title, content: childComponent.props.title, className },
                render: () => this.renderComponent(childComponent, true)
            };
        });
    }

    onTabChange = (event, { activeIndex }) => {
        this.setState({
            activeIndex
        });
    };

    render() {
        if (!this.props.children) return null;

        // Avoid nulls
        let children = this.props.children.length ? this.props.children.filter((c) => c) : this.props.children;

        if (!children || (Array.isArray(children) && !children.length)) return null;

        const className = "logic-popup-container " + (this.props.className || "");

        const style = {};
        if (this.props.position) {
            style.left = this.props.position.x;
            style.top = this.props.position.y;
            style.position = "fixed";
        }

        if (children.length && children.length > 1) {
            const panes = this.getTabPanesFromChildren(children);
            return (
                <div className={className} style={style}>
                    <Tab menu={{ secondary: true, pointing: true, className: "popup-menu" }} panes={panes} onTabChange={this.onTabChange} activeIndex={this.state.activeIndex} />
                </div>
            );
        }

        return (
            <div className={className} style={style}>
                {this.renderComponent(children.length ? children[0] : children)}
            </div>
        );
    }
}

LogicPopupContainer.propTypes = {
    className: PropTypes.string,
    selectedIndex: PropTypes.number,
    handleClickOutside: PropTypes.func,
    position: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
        PropTypes.bool // To support conditional rendering
    ])
};

export default enhanceWithClickOutside(LogicPopupContainer);
