import { localEditorShowPronunciationLibraryVar } from "../../State";
import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientCreatePronunciationDefinitionMutation,
    GqlClientCreatePronunciationDefinitionMutationVariables,
    GqlClientDeletePronunciationDefinitionMutation,
    GqlClientDeletePronunciationDefinitionMutationVariables,
    GqlClientUpdatePronunciationDefinitionMutation,
    GqlClientUpdatePronunciationDefinitionMutationVariables
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { CreatePronunciationDefinitionDocument, DeletePronunciationDefinitionDocument, UpdatePronunciationDefinitionDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const setShowPronunciationLibrary = (isOpen: boolean) => localEditorShowPronunciationLibraryVar(isOpen);

export const createPronunciationDefinition = (pronunciationLibraryVersionId: string, source: string, target: string): void => {
    const client = getApolloClient();

    client.mutate<GqlClientCreatePronunciationDefinitionMutation, GqlClientCreatePronunciationDefinitionMutationVariables>({
        mutation: CreatePronunciationDefinitionDocument,
        variables: {
            input: {
                pronunciationLibraryVersionId,
                source,
                target
            }
        }
    }).catch(() => {/* do nothing */});
};

export const updatePronunciationDefinition = (pronunciationDefinitionId: string, source: string, target: string): void => {
    const client = getApolloClient();

    client.mutate<GqlClientUpdatePronunciationDefinitionMutation, GqlClientUpdatePronunciationDefinitionMutationVariables>({
        mutation: UpdatePronunciationDefinitionDocument,
        variables: {
            input: {
                pronunciationDefinitionId,
                source,
                target
            }
        }
    }).catch(() => {/* do nothing */});
};

export const deletePronunciationDefinition = (pronunciationDefinitionId: string): void => {
    const client = getApolloClient();

    client.mutate<GqlClientDeletePronunciationDefinitionMutation, GqlClientDeletePronunciationDefinitionMutationVariables>({
        mutation: DeletePronunciationDefinitionDocument,
        variables: {
            input: { id: pronunciationDefinitionId }
        }
    }).catch(() => {/* do nothing */});
};
