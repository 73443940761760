import { useReactiveVar } from "@apollo/client";
import type {
    GqlClientEditorAssetLibraryFontFragment,
    GqlClientEditorAssetLibraryMediaFragment,
    GqlClientEditorBrandFragment,
    GqlClientEditorBrandTextStyleFragment,
    GqlClientEditorLibraryFontFragment
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { GqlClientEditorAssetSource, useLocalEditorBrandQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { setEditedBrandColors, setEditedBrandLogoAssetInfo, setEditedBrandTextStyle, setEditedButtonShapeValue } from "../../Nooks";
import { localEditedBrandColorsVar, localEditedBrandLogoAssetInfoVar, localEditedBrandTextStylesVar, localEditedButtonShapeValueVar } from "../../State";
import type { BrandId, ButtonShape, ColorName, ColorValues, LocalStateTextStyle, LogoAssetInfo, TextStyleId } from "../../types";
import { findEntityById, getTextStyleFont, parseRemoteBrandColors } from "../../Utils";
import type { UseAssetLibraryFontForStyling } from "../UseAssetLibrary";
import { useAssetLibraryFontForStyling, useAssetLibraryFonts, useAssetLibraryMedia } from "../UseAssetLibrary";
import type { UseLibraryFontForStyling } from "../UseLibraryEntities";
import { useLibraryFontForStyling, useLibraryFonts } from "../UseLibraryEntities";

type ParseTextStyleParams = {
    remoteTextStyles: GqlClientEditorBrandTextStyleFragment[];
    localStateTextStyles: LocalStateTextStyle[];
    ccLibraryFonts: GqlClientEditorLibraryFontFragment[];
    accountFonts: GqlClientEditorAssetLibraryFontFragment[];
};

const parseTextStyles = ({ remoteTextStyles, localStateTextStyles, ccLibraryFonts, accountFonts }: ParseTextStyleParams): GqlClientEditorBrandTextStyleFragment[] => {
    return remoteTextStyles.map((remoteTextStyle) => {
        const localStateTextStyle = findEntityById(localStateTextStyles, remoteTextStyle.id);

        const parsedTextStyle: GqlClientEditorBrandTextStyleFragment = {
            ...remoteTextStyle,
            ...localStateTextStyle
        };

        if (localStateTextStyle?.fontId) {
            const ccLibraryFont = findEntityById(ccLibraryFonts, localStateTextStyle.fontId);
            const accountFont = findEntityById(accountFonts, localStateTextStyle.fontId);

            parsedTextStyle.fontSource = ccLibraryFont ? GqlClientEditorAssetSource.CC : GqlClientEditorAssetSource.ACCOUNT;
            parsedTextStyle.ccFont = ccLibraryFont ?? null;
            parsedTextStyle.accountFont = accountFont ?? null;
            delete parsedTextStyle["fontId"]; // fontId only exists in local state
        }
        if (localStateTextStyle?.textStyleId) {
            delete parsedTextStyle["textStyleId"]; // textStyleId only exists in local state
        }

        return parsedTextStyle;
    });
};

export type UseBrand = {
    brandName: GqlClientEditorBrandFragment["name"];
    brandLogoAssetInfo: LogoAssetInfo;
    brandLogoMediaAsset: GqlClientEditorAssetLibraryMediaFragment;
    setBrandLogoAssetInfo: (logoAssetInfo: LogoAssetInfo) => void;
    brandColorNames: ColorName[];
    brandColorValues: ColorValues;
    setBrandColorValue: (colorValue: ColorValues) => void;
    brandTextStyles: GqlClientEditorBrandTextStyleFragment[];
    setBrandTextStyle: (textStyleId: TextStyleId, updatedTextStyle: LocalStateTextStyle) => void;
    brandButtonShape: ButtonShape;
    setBrandButtonShape: (buttonShape: ButtonShape) => void;
};

export const useBrand = (brandId: BrandId): UseBrand => {
    const { data } = useLocalEditorBrandQuery({ variables: { brandId } });
    const remoteBrand = data.localEditorBrand;

    const localLogoAssetInfo = useReactiveVar(localEditedBrandLogoAssetInfoVar);
    const localColorValues = useReactiveVar(localEditedBrandColorsVar);
    const localStateTextStyles = useReactiveVar(localEditedBrandTextStylesVar);
    const localButtonShapeValue = useReactiveVar(localEditedButtonShapeValueVar);

    const { libraryFonts: ccLibraryFonts } = useLibraryFonts();
    const { assetLibraryFonts: accountFonts } = useAssetLibraryFonts();

    const { remoteColorNames, remoteColorValues } = parseRemoteBrandColors(remoteBrand.colors);
    const brandTextStyles = parseTextStyles({
        remoteTextStyles: remoteBrand.textStyles,
        localStateTextStyles,
        ccLibraryFonts,
        accountFonts
    });

    const brandButtonShape: ButtonShape = (localButtonShapeValue ?? remoteBrand.buttonShape) as ButtonShape;

    const logoMediaId: string = localLogoAssetInfo.logoMediaId ?? remoteBrand.logoMedia?.id;

    const brandLogoAssetInfo = {
        logoLocation:  localLogoAssetInfo.logoLocation ?? remoteBrand.logoLocation,
        logoMediaId
    };

    const { assetLibraryMediaByGqlMediaId } = useAssetLibraryMedia();

    const brandLogoMediaAsset: GqlClientEditorAssetLibraryMediaFragment = assetLibraryMediaByGqlMediaId[logoMediaId];

    return {
        brandName: remoteBrand.name,
        brandLogoAssetInfo,
        brandLogoMediaAsset,
        setBrandLogoAssetInfo: setEditedBrandLogoAssetInfo,
        brandColorNames: remoteColorNames,
        brandColorValues: { ...remoteColorValues, ...localColorValues },
        setBrandColorValue: setEditedBrandColors,
        brandTextStyles,
        setBrandTextStyle: setEditedBrandTextStyle,
        brandButtonShape: brandButtonShape,
        setBrandButtonShape: setEditedButtonShapeValue
    };
};

export type UseTextStyleFontForStyling = UseAssetLibraryFontForStyling | UseLibraryFontForStyling;
export const useTextStyleFontForStyling = (textStyle: GqlClientEditorBrandTextStyleFragment): UseTextStyleFontForStyling => {
    const font = textStyle ? getTextStyleFont(textStyle) : null;
    const ccFontForStyling = useLibraryFontForStyling(font?.id);
    const accountFontForStyling = useAssetLibraryFontForStyling(font?.id);
    return textStyle ? textStyle.fontSource === GqlClientEditorAssetSource.CC ? ccFontForStyling : accountFontForStyling : null;
};
