import type { PreviewDialogType } from "../../types";
import { useReactiveVar } from "@apollo/client";
import { localEditorPreviewDialogTypeVar } from "../../State";
import { setPreviewDialogType } from "../../Nooks/Preview";

export type UseEditorPreviewDialogType = {
    previewDialogType: PreviewDialogType;
    setPreviewDialogType: (previewType: PreviewDialogType) => void;
};

export const useEditorPreviewDialogType = (): UseEditorPreviewDialogType => {
    const previewDialogType = useReactiveVar(localEditorPreviewDialogTypeVar);

    return { previewDialogType, setPreviewDialogType };
};
