import type { SceneId, ThemeId } from "../../types";
import type {
    GqlClientEditorLibraryWireframeFragment,
    GqlClientEditorSceneColorCombination,
    GqlClientEditorSceneFragment,
    GqlClientEditorSceneSkippingMode,
    GqlClientEnumHorizontalAlignment
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useLocalEditorSceneQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { selectLayout, updateScene } from "../../Nooks";
import type { SceneSkippingAudience } from "../../../../../common/types/editorSceneAudience";

type SceneName = GqlClientEditorSceneFragment["name"];
type SceneDescription = GqlClientEditorSceneFragment["description"];
type LayoutId = GqlClientEditorLibraryWireframeFragment["animatedWireframes"][0]["id"];

export type UseScene = {
    scene: GqlClientEditorSceneFragment;
    setSceneName: (name: SceneName) => void;
    setSceneDescription: (description: SceneDescription) => void;
    setSceneSelectedLayout: (themeId: ThemeId, layoutId: LayoutId) => void;
    setSceneSkipMode: (skipSceneMode: GqlClientEditorSceneSkippingMode) => void;
    setSceneSkipByAudienceBy: (skipSceneAudienceBy: SceneSkippingAudience["by"]) => void;
    setSceneSkipByAudienceByValueIds: (skipSceneAudienceValueIds: SceneSkippingAudience["valueIds"]) => void;
    setSceneColorCombination: (sceneColorCombination: GqlClientEditorSceneColorCombination) => void;
    setButtonAlignment: (buttonAlignment: GqlClientEnumHorizontalAlignment) => void;
    setSceneMinimumDuration: (minimumDuration: number) => void;
    setSceneTransition: (hasTransition: boolean) => void;
};

export const useScene = (sceneId: SceneId): UseScene => {
    const { data } = useLocalEditorSceneQuery({ variables: { sceneId }, skip: !sceneId });

    const scene = data?.localEditorScene ?? null;

    const setSceneName = (name: SceneName): void => {
        updateScene({
            id: scene.id,
            name
        });
    };

    const setSceneDescription = (description: SceneDescription): void => {
        updateScene({
            id: scene.id,
            description
        });
    };

    const setSceneSkipMode = (skipSceneMode: GqlClientEditorSceneSkippingMode) => {
        updateScene({
            id: scene.id,
            skipSceneMode
        });
    };

    const setSceneSkipByAudienceBy = (skipSceneAudienceBy: SceneSkippingAudience["by"]) => {
        updateScene({
            id: scene.id,
            skipSceneAudience: {
                version: 1,
                by: skipSceneAudienceBy,
                valueIds: skipSceneAudienceBy === scene.skipSceneAudience?.by ? scene.skipSceneAudience?.valueIds : []
            } as SceneSkippingAudience
        });
    };

    const setSceneSkipByAudienceByValueIds = (skipSceneAudienceValueIds: SceneSkippingAudience["valueIds"]) => {
        updateScene({
            id: scene.id,
            skipSceneAudience: {
                version: 1,
                by: scene.skipSceneAudience?.by,
                valueIds: skipSceneAudienceValueIds
            } as SceneSkippingAudience
        });
    };

    const setSceneSelectedLayout = (themeId: ThemeId, layoutId: LayoutId): void => {
        selectLayout({
            sceneId: scene.id,
            themeId,
            animatedWireframeId: layoutId
        });
    };

    const setSceneColorCombination = (sceneColorCombination: GqlClientEditorSceneColorCombination) => {
        updateScene({
            id: scene.id,
            colorCombination: sceneColorCombination
        });
    };

    const setButtonAlignment = (buttonAlignment: GqlClientEnumHorizontalAlignment) => {
        updateScene({
            id: scene.id,
            buttonAlignment
        });
    };

    const setSceneMinimumDuration = (minimumDuration: number) => {
        updateScene({
            id: scene.id,
            minimumDuration
        });
    };

    const setSceneTransition = (hasTransition: boolean) => {
        updateScene({
            id: scene.id,
            hasTransition
        });
    };

    return {
        scene,
        setSceneName,
        setSceneDescription,
        setSceneSelectedLayout,
        setSceneSkipMode,
        setSceneSkipByAudienceBy,
        setSceneSkipByAudienceByValueIds,
        setSceneColorCombination,
        setButtonAlignment,
        setSceneMinimumDuration,
        setSceneTransition
    };
};
