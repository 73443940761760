type CachedValue<V> = {
    value: V,
    lastAccessTime: number
}

export type Cache<OUTER_KEY, INNER_KEY, VALUE> = {
    get: (outerKey: OUTER_KEY, innerKey: INNER_KEY) => VALUE,
    set: (outerKey: OUTER_KEY, innerKey: INNER_KEY, value: VALUE) => void
};

export function createCache<OUTER_KEY, INNER_KEY, VALUE>(maxSize: number): Cache<OUTER_KEY, INNER_KEY, VALUE> {
    const outerMap = new Map<OUTER_KEY, Map<INNER_KEY, CachedValue<VALUE>>>();

    function get(outerKey: OUTER_KEY, innerKey: INNER_KEY): VALUE | undefined {
        const innerMap = outerMap.get(outerKey);
        if (innerMap) {
            const cachedValue = innerMap.get(innerKey);
            if (cachedValue) {
                cachedValue.lastAccessTime = now();
                return cachedValue.value;
            }
        }
    }

    function set(outerKey: OUTER_KEY, innerKey: INNER_KEY, value: VALUE): void {
        let innerMap = outerMap.get(outerKey);
        if (!innerMap) {
            innerMap = new Map<INNER_KEY, CachedValue<VALUE>>();
            outerMap.set(outerKey, innerMap);
        }

        const cachedValue = { value, lastAccessTime: now() };

        if (!innerMap.has(innerKey) && innerMap.size >= maxSize) {
            const keyToDelete = lruKey(innerMap);
            innerMap.delete(keyToDelete);
        }

        innerMap.set(innerKey, cachedValue);
    }

    return {
        get,
        set
    };
}

function lruKey<INNER_KEY, VALUE>(map: Map<INNER_KEY, CachedValue<VALUE>>): INNER_KEY {
    let lruKey;
    let lruLastAccessTime;

    map.forEach((value: CachedValue<VALUE>, innerKey: INNER_KEY) => {
        if (!lruKey || value.lastAccessTime < lruLastAccessTime) {
            lruKey = innerKey;
            lruLastAccessTime = value.lastAccessTime;
        }
    });

    return lruKey;
}

function now() {
    return new Date().getTime();
}

