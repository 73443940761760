import { ApolloProvider } from "@apollo/client";
import type { AuthServiceInterface } from "@sundaysky/smartvideo-hub-auth";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import {
    legacyLoginPath,
    newNavAdminPath,
    newNavBatchVideoReviewPath,
    newNavEditorPath,
    newNavLoginPath,
    newNavNewVideoPagePath,
    newNavStoryTemplatesReviewGalleryPath,
    newNavVideoReviewPath,
    newNavAccountTierManagementPath,
    nextVideoGeneratorPath
} from "@sundaysky/smartvideo-hub-urls";
import type { FC } from "react";
import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { isPermitted, permissions, roles } from "../../common/rolesPermissionsConst";
import { getApolloClient } from "../apollo";
import { usePendoUpdateVideoIdEffect } from "../utils/hooks/usePendoUpdateVideoIdEffect";
import { isNewNav } from "../utils/newNavUtils";
import { TimedComponent } from "../utils/timedActivityReportingUtils";
import { EditHelpCenterPopover } from "./editor/Components/Root/Components/EditHelpCenterPopover";
import { HelpCenterPopover } from "./editor/Components/TruffleEditingWorkSpace/Components/Libraries/Components/HelpCenterPopover";
import { EditorProgramIdContext } from "./editor/Hooks";
import { DelayedSpinner } from "./newNav/components/common/DelayedSpinner";
import { useUserData } from "./newNav/hooks/model";
import { isFeatureEnabled } from "./newNav/nooks/featureFlags";
import { closeHelpCenter } from "./newNav/nooks/helpCenter";

const LoginPage = lazy(() => import("./login/HubLoginPage").then(m => ({ default: m.HubLoginPage })));
const MainContainer = lazy(() => import("./newNav/components/main/MainContainer").then(m => ({ default: m.MainContainer })));
const VideoReviewPage = lazy(() => import("./videoReview/components/VideoReviewPage").then(m => ({ default: m.VideoReviewPage })));

const StoryTemplatesReviewGalleryApolloWrapper = lazy(() => import("./editor/Components/StoryTemplatesGalleryNewNav/StoryTemplatesReviewGalleryApolloWrapper")
    .then(m => ({ default: m.StoryTemplatesReviewGalleryApolloWrapper })));
const OldTruffleEditorContainer = lazy(() => import("./editor/Components/TruffleEditingWorkSpace/OldEditor").then(m => ({ default: m.OldEditorContainer })));
const TruffleEditorContainer = lazy(() => import("./editor/Components/TruffleEditingWorkSpace/Editor").then(m => ({ default: m.EditorContainer })));
const VideoReviewHomepage = lazy(() => import("./videoReview/components/VideoReviewHomepage").then(m => ({ default: m.VideoReviewHomepage })));
const AdminViewPageContainer = lazy(() => import("./adminView/components/AdminViewPage").then(m => ({ default: m.AdminViewPage })));
const VideoOverviewPage = lazy(() => import("./editor/Components/VideoOverviewPage/VideoOverviewPage").then(m => ({ default: m.VideoOverviewPage })));
const NextVideoForAccount = lazy(() => import("./newNav/components/PromptToVideo/NextVideoForAccount").then(m => ({ default: m.NextVideoForAccount })));
const AccountTierManagementPage = lazy(() => import("./accountTierManagement/components/AccountTierManagementPage").then(m => ({ default: m.AccountTierManagementPage })));

export type NewNavigationRouterProps = {
    authService: AuthServiceInterface;
}

export const NewNavigationRouter: FC<NewNavigationRouterProps> = (props) => {
    const isNewVideoOverviewEnabled = isFeatureEnabled(featureFlagConst._EDITOR_NEW_VIDEO_PAGE);

    const logout = async () => {
        await props.authService.logout();
        window.location.href = isNewNav ? newNavLoginPath() : legacyLoginPath();
    };

    usePendoUpdateVideoIdEffect();

    const userData = useUserData();

    const isEditorResponsivenessEnabled = isFeatureEnabled(featureFlagConst._EDITOR_RESPONSIVENESS);

    const history = useHistory();
    useEffect(
        () => history.listen((newLocation) => {
            const changedAt = Date.now();
            const lastLocation = window["data-lastLocation"];
            if (lastLocation.currentPathname !== newLocation.pathname) {
                window["data-lastLocation"] = {
                    lastPathname: lastLocation.currentPathname,
                    currentPathname: newLocation.pathname,
                    changedAt
                };
                closeHelpCenter();
            }
        }),
        [history]
    );


    return (
        <Suspense fallback={<TimedComponent name="NewNavigationRouter"><DelayedSpinner/></TimedComponent>}>
            <Switch>
                <Route
                    exact
                    path={newNavLoginPath.pattern}
                >
                    <LoginPage authService={props.authService}/>
                </Route>
                {isPermitted(userData?.userRoles, permissions.editStoryTemplatesGallery) && (
                    <Route
                        exact
                        path={newNavStoryTemplatesReviewGalleryPath.pattern}
                    >
                        <StoryTemplatesReviewGalleryApolloWrapper/>
                    </Route>
                )}
                {isPermitted(userData?.userRoles, permissions.reviewEditorPrograms) && (
                    <Route
                        exact
                        path={[newNavVideoReviewPath.pattern, newNavBatchVideoReviewPath.pattern]}
                        render={(props) => (
                            <ApolloProvider client={getApolloClient()}>
                                <VideoReviewPage {...props}/>
                                <EditHelpCenterPopover />
                            </ApolloProvider>
                        )}
                    />
                )}
                <Route
                    path={newNavEditorPath.pattern}
                    render={(routerProps) => (
                        <ApolloProvider client={getApolloClient()}>
                            <EditorProgramIdContext.Provider value={routerProps.match.params.videoId}>
                                {isEditorResponsivenessEnabled
                                    ? <TruffleEditorContainer
                                        videoId={routerProps.match.params.videoId || null}
                                        logout={logout}
                                    />
                                    : <OldTruffleEditorContainer
                                        videoId={routerProps.match.params.videoId || null}
                                        logout={logout}
                                    />
                                }
                            </EditorProgramIdContext.Provider>
                            <EditHelpCenterPopover />
                        </ApolloProvider>
                    )}
                />
                {(
                    !isPermitted(userData?.userRoles, permissions.getEditorPrograms)
                    && !isPermitted(userData?.userRoles, permissions.getProjects)
                    && isPermitted(userData?.userRoles, permissions.reviewEditorPrograms)
                ) && (
                    <Route path="*">
                        <VideoReviewHomepage/>
                    </Route>
                )}
                {userData?.userRoles?.includes(roles.internal) && (
                    <Route
                        path={newNavAdminPath.pattern}
                        render={(routerProps) => (
                            <AdminViewPageContainer {...routerProps}/>
                        )}
                    />
                )}
                {isNewVideoOverviewEnabled && (
                    <Route
                        exact
                        path={newNavNewVideoPagePath.pattern}
                        render={(routerProps) => {
                            return (
                                <ApolloProvider client={getApolloClient()}>
                                    <EditorProgramIdContext.Provider value={routerProps.match.params.videoId}>
                                        <VideoOverviewPage logout={logout} programId={routerProps.match.params.videoId}/>
                                    </EditorProgramIdContext.Provider>
                                    <EditHelpCenterPopover />
                                </ApolloProvider>
                            );
                        }}
                    />
                )}
                {userData?.userRoles?.includes(roles.internal) && <Route
                    exact
                    path={nextVideoGeneratorPath.pattern}
                >
                    <NextVideoForAccount />
                </Route>
                }
                {isPermitted(userData?.userRoles, permissions.accountTierManagement) && (
                    <Route exact path={newNavAccountTierManagementPath.pattern}>
                        <ApolloProvider client={getApolloClient()}>
                            <AccountTierManagementPage/>
                            <EditHelpCenterPopover />
                        </ApolloProvider>
                    </Route>
                )}
                <Route
                    path="*"
                    render={(props) => (
                        <MainContainer logout={logout} {...props}/>
                    )}
                />
            </Switch>
            <HelpCenterPopover />
        </Suspense>
    );
};
