import { useReactiveVar } from "@apollo/client";
import { setSelectedNarrationPlaceholderOpenEdit } from "../../Nooks";
import { localEditorSelectedNarrationPlaceholderOpenEditVar } from "../../State";
import type { StandardSetterFn } from "../../types";

export type UseProjectSelectedNarrationPlaceholderOpenEdit = {
    projectSelectedNarrationPlaceholderOpenEdit: boolean;
    setProjectSelectedNarrationPlaceholderOpenEdit: StandardSetterFn<boolean>;
};

export const useProjectSelectedNarrationPlaceholderOpenEdit = (): UseProjectSelectedNarrationPlaceholderOpenEdit => {
    const projectSelectedNarrationPlaceholderOpenEdit = useReactiveVar(localEditorSelectedNarrationPlaceholderOpenEditVar);

    return { projectSelectedNarrationPlaceholderOpenEdit, setProjectSelectedNarrationPlaceholderOpenEdit: setSelectedNarrationPlaceholderOpenEdit };
};
