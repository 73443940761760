import { getApolloClient } from "../../../../apollo";
import type {
    GqlClientAvatarInfo,
    GqlClientEditorNarrationDurationQuery,
    GqlClientEditorNarrationDurationQueryVariables,
    GqlClientNarrationDuration
} from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EditorNarrationDurationDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export const getNarrationDuration = async (
    text: string,
    rate: number,
    service: string,
    narrator: string,
    avatarInfo?: GqlClientAvatarInfo
): Promise<GqlClientNarrationDuration> => {
    const client = getApolloClient();
    const result = await client.query<GqlClientEditorNarrationDurationQuery, GqlClientEditorNarrationDurationQueryVariables>({
        query: EditorNarrationDurationDocument,
        variables: {
            text,
            rate,
            service,
            narrator,
            avatarInfo
        }
    }).catch(() => { /* do nothing */ });
    return result ? result.data?.editorNarrationDuration : null;
};
