import { styled } from "@mui/material";
import type { FC } from "react";
import React, { useEffect, useState } from "react";

const loadingAnimation = require("../../../../images/new-nav-loading.gif");

export type SundaySkyLogoSpinnerProps = {
    dataTestId?: string;
}

export const SundaySkyLogoSpinner: FC<SundaySkyLogoSpinnerProps> = ({ dataTestId }) => {
    return (
        <StyledLogoContainer data-testid={dataTestId}>
            <img
                alt="Animated SundaySky Logo"
                src={loadingAnimation}
            />
        </StyledLogoContainer>
    );
};

export type DelayedSpinnerProps = {
    /**
     * Delay in milliseconds before displaying the spinner.
     */
    delay?: number;
    dataTestId?: string;
}

/**
 * Spinner that is displayed after a delay. Default delay is 1 second.
 */
export const DelayedSpinner: FC<DelayedSpinnerProps> = ({ delay = 1000, dataTestId }) => {

    const [show, setShow] = useState(!delay);

    useEffect(
        () => {
            if (delay) {
                const timeout = setTimeout(
                    () => setShow(true),
                    delay
                );
                return () => clearTimeout(timeout);
            }
        },
        [delay]
    );

    return show
        ? <SundaySkyLogoSpinner dataTestId={dataTestId}/>
        : null;
};

const StyledLogoContainer = styled("div")({
    minHeight: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});
