import type { GqlClientMediaCropHintsFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useMediaCropHintsLazyQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { ApolloError } from "@apollo/client";

export type UseMediaCropHints = {
    mediaCropHints?: GqlClientMediaCropHintsFragment;
    loading?: boolean;
    error?: ApolloError;
};

export const useLazyMediaCropHints = (url: string, aspectRatio: number): [() => void, UseMediaCropHints] => {
    const [ get, { loading, error, data } ] = useMediaCropHintsLazyQuery({ variables: { url, aspectRatio } });
    const hints = data?.mediaCropHints;
    return [ get, {
        mediaCropHints: hints,
        loading,
        error
    }];
};
