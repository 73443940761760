import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { EnhancedError } from "./EnhancedError";
import type { Theme } from "@mui/material";
import { Box, Link, Typography } from "@mui/material";
import type { SxProps } from "@mui/system";
import { SskyErrorCode } from "../../../../common/errors";
import { handleUnexpectedError } from "../../../logic/common/errorHandlingUtils";
import { NewNavThemeProvider } from "../../../utils/themeUtils";
import { openContactSupportForm, reloadPage } from "../../editor/Utils";


const logo = require("../../../images/svg/logo-light-square.svg");
const laptop = require("../../../images/laptop.png");

type ErrorFallbackPageProps = {
    error: Error;
};

export const ErrorFallbackPage: FC<ErrorFallbackPageProps> = ({ error }: ErrorFallbackPageProps): JSX.Element => {
    const enhancedError = useMemo(() => new EnhancedError(error, SskyErrorCode.ClientRuntimeError), []);
    const containerSxProps: SxProps<Theme> = {
        gap: "80px",
        top: "0",
        left: "0",
        color: "common.white",
        width: "100vw",
        height: "100vh",
        display: "flex",
        zIndex: "1150",
        position: "fixed",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "secondary.main",

        "& img:first-of-type": {
            top: "0",
            left: "0",
            width: "64px",
            height: "64px",
            margin: "20px",
            position: "fixed"
        },

        "& img:last-of-type": {
            width: "486px",
            height: "293px"
        }
    };

    const contentSxProps: SxProps<Theme> = {
        gap: "50px",
        height: "293px",
        display: "flex",
        maxWidth: "333px",
        flexDirection: "column",
        justifyContent: "space-between"
    };

    useEffect(() => {
        handleUnexpectedError({ error: enhancedError }).catch(() => {});
    }, [enhancedError]);

    const handleReloadPageClick = (): void => {
        reloadPage();
    };

    const handleContactSupportClick = (): void => {
        openContactSupportForm(enhancedError.getErrorId());
    };

    return (
        <NewNavThemeProvider>
            <Box sx={containerSxProps}>
                <img
                    src={logo}
                    alt="SundaySky Logo"
                />
                <Box sx={contentSxProps}>
                    <Typography variant="h2">
                Oops, something went wrong
                    </Typography>
                    <Typography variant="body1">
                        Please&nbsp;
                        <Link
                            onClick={handleReloadPageClick}
                            color="primary.light"
                        >
                            refresh the page
                        </Link>
                        &nbsp;or&nbsp;
                        <Link
                            onClick={handleContactSupportClick}
                            color="primary.light"
                        >
                            contact Support.
                        </Link>
                    </Typography>
                </Box>
                <img
                    src={laptop}
                    alt="Laptop Graphic"
                />
            </Box>
        </NewNavThemeProvider>
    );
};
