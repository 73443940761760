import { useReactiveVar } from "@apollo/client";
import { featureFlagConst } from "@sundaysky/smartvideo-hub-config";
import type { MouseEventHandler } from "react";
import { useCallback, useMemo } from "react";
import type { GqlClientHelpCenterEntriesQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useHelpCenterEntriesQuery } from "../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { editHelpCenterDataVar, editHelpCenterEnabled, editHelpCenterOpenVar } from "../../model";
import { isFeatureEnabled } from "../nooks/featureFlags";
import { helpCenterArticleUrl } from "../nooks/helpCenter";

export function useEditHelpCenterEnabled() {
    return useReactiveVar(editHelpCenterEnabled);
}

export function useEditHelpCenterData() {
    return useReactiveVar(editHelpCenterDataVar);
}

export function useEditHelpCenterOpen() {
    return useReactiveVar(editHelpCenterOpenVar);
}

export function useHelpCenterEntry(
    buttonId: string,
    selectedVariant: string | null
): GqlClientHelpCenterEntriesQuery["helpCenterEntries"][number] | null {
    const { data } = useHelpCenterEntriesQuery();
    return useMemo(
        () => data?.helpCenterEntries.find(entry => (
            entry.buttonId === buttonId
            && entry.variant === (selectedVariant ?? null)
        )),
        [
            data?.helpCenterEntries,
            buttonId,
            selectedVariant
        ]
    );
}

export type UseLearnMoreButtonOptions<
    Variants extends Record<string, string>
> = {
    buttonId: string;
    articleId: string;
    sectionId?: string;
    onClick?: MouseEventHandler<HTMLElement>;
} & ({
    variants: Variants;
    selectedVariant: Extract<keyof Variants, string>;
} | {
    variants?: never;
    selectedVariant?: never;
});

export type UseLearnMoreButtonOutput = {
    href: string;
    target: string;
    rel: string;
    onClick?: MouseEventHandler<HTMLElement>;
}

export function useLearnMoreButton<
    Variants extends Record<string, string>
>(
    options: UseLearnMoreButtonOptions<Variants>
): UseLearnMoreButtonOutput {
    const isEditHelpCenterLinksEnabled = isFeatureEnabled(featureFlagConst._EDITOR_EDIT_HELP_CENTER_LINKS);
    const editHelpCenterEnabled = useEditHelpCenterEnabled();
    const entry = useHelpCenterEntry(options.buttonId, options.selectedVariant);

    const onClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            if (editHelpCenterEnabled) {
                e.preventDefault();
                editHelpCenterOpenVar(true);
                editHelpCenterDataVar({
                    buttonId: options.buttonId,
                    variants: options.variants,
                    selectedVariant: options.selectedVariant,
                    anchorEl: e.currentTarget
                });
            }
            options.onClick?.(e);
        },
        [
            editHelpCenterEnabled,
            options.buttonId,
            options.variants,
            options.selectedVariant,
            options.onClick
        ]
    );

    if (isEditHelpCenterLinksEnabled) {
        if (entry) return {
            href: helpCenterArticleUrl(entry.articleId, entry.sectionId),
            target: "_blank",
            rel: "noopener noreferrer",
            onClick
        };
        else return {
            href: undefined,
            target: undefined,
            rel: undefined,
            onClick
        };
    }
    else return {
        href: helpCenterArticleUrl(options.articleId, options.sectionId),
        target: "_blank",
        rel: "noopener noreferrer",
        onClick
    };
}
