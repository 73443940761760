import type { FC, PropsWithChildren } from "react";
import React from "react";
import type { ErrorBoundaryPropsWithRender } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallbackPage } from "./Components/ErrorFallbackPage";

const fallbackRender: ErrorBoundaryPropsWithRender["fallbackRender"] = ({ error }) => {
    return <ErrorFallbackPage error={error}/>;
};

type MainErrorBoundaryProps = PropsWithChildren<{}>;

export const MainErrorBoundary: FC<MainErrorBoundaryProps> = ({ children }: MainErrorBoundaryProps): JSX.Element => {
    return (
        <ErrorBoundary fallbackRender={fallbackRender}>
            {children}
        </ErrorBoundary>
    );
};
