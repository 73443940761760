import { GqlClientStoryTemplatesGalleryType, useGetGalleryStoryTemplatesQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { GqlClientGalleryStoryTemplateFragment } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";

export type UseGalleryStoryTemplates = {
    storyTemplates: GqlClientGalleryStoryTemplateFragment[];
    loading: boolean;
};

export const useGalleryStoryTemplates = (): UseGalleryStoryTemplates => {
    const { data, loading } = useGetGalleryStoryTemplatesQuery({ variables: {
        galleryType: GqlClientStoryTemplatesGalleryType.PRODUCTION,
        includeChildren: true
    } });
    const storyTemplates = data?.galleryStoryTemplates?.slice().sort((template1, template2) => template1.name?.localeCompare(template2.name)) || [];

    return { storyTemplates, loading };
};
