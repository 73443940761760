import { useEditorProgramDataConnectorQuery } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import type { DataLibraryDynamicElement } from "../../types";
import { convertSystemDynamicElementToDataLibraryDynamicElement, getDataLibraryDataElementsFromProgramVersion, getSystemElements, isReservedIdWord } from "../../Utils";
import { useMemo } from "react";
import { DynamicElement } from "@sundaysky/customer-data-common-goblin-ds";
export type SerializedDynamicElement = DataLibraryDynamicElement & { serializedData: DynamicElement, origin: "account" | "system" }
export type UseEditorDynamicElements = {
    dynamicElements: DataLibraryDynamicElement[];
    parsedDynamicElements: SerializedDynamicElement[],
    parsedDynamicElementsWithoutIdElement: SerializedDynamicElement[],
    parsedDynamicAndSystemElementsWithoutIdElement: SerializedDynamicElement[]
    loading: boolean;
}
export const useEditorDynamicElements = (programId: string, programVersionId: string): UseEditorDynamicElements => {
    const { data: dynamicElementsData, loading } = useEditorProgramDataConnectorQuery({ variables: { programVersionId, programId }, skip: !programId || !programVersionId });
    const [ dynamicElements, parsedDynamicElements, parsedDynamicElementsWithoutIdElement, parsedDynamicAndSystemElementsWithoutIdElement] = useMemo(() => {
        const dynamicElements: DataLibraryDynamicElement[] = getDataLibraryDataElementsFromProgramVersion(dynamicElementsData?.editorProgram?.programVersion);
        const parsedDynamicElements: SerializedDynamicElement[] = dynamicElements.map(de => {
            return {
                ...de,
                serializedData: DynamicElement.deserialize(de.data),
                origin: "account"
            };
        });

        const systemElements: SerializedDynamicElement[] = getSystemElements().map(se => {
            return {
                ...convertSystemDynamicElementToDataLibraryDynamicElement(se),
                serializedData: se,
                origin: "system"
            };
        });

        const parsedDynamicElementsWithoutIdElement = parsedDynamicElements.filter(de => !isReservedIdWord(de?.serializedData?.target?.name));
        const parsedDynamicAndSystemElementsWithoutIdElement = parsedDynamicElementsWithoutIdElement.concat(systemElements);

        return [dynamicElements, parsedDynamicElements, parsedDynamicElementsWithoutIdElement, parsedDynamicAndSystemElementsWithoutIdElement];
    },
    [dynamicElementsData?.editorProgram?.programVersion]);

    return { dynamicElements, parsedDynamicElements, parsedDynamicElementsWithoutIdElement, parsedDynamicAndSystemElementsWithoutIdElement, loading };
};
