import { useBrand } from "./useBrand";
import { extractFilenameFromPath } from "../../Utils";
import { useActiveBrandId } from "./useActiveBrandId";
import { getEncodedAssetUrl } from "../../../../../common/assetUploadUtils";
import { GqlClientMediaOrigin } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { useLegacyAssetsLocationBasePath } from "../";
import type { UseActiveBrandLogo } from "../../types";


export const useActiveBrandLogo = (): UseActiveBrandLogo => {
    const { activeBrandId } = useActiveBrandId();
    const { brandLogoAssetInfo, brandLogoMediaAsset } = useBrand(activeBrandId);
    const basePath: string = useLegacyAssetsLocationBasePath();

    const logoUrlFromLocation: string = brandLogoAssetInfo.logoLocation ? getEncodedAssetUrl(basePath, brandLogoAssetInfo.logoLocation) : "";

    const activeBrandLogo = {
        logoMediaId: brandLogoMediaAsset ? brandLogoMediaAsset.id : null,
        logoUrl: brandLogoMediaAsset ? brandLogoMediaAsset.mediaUrl : logoUrlFromLocation,
        logoPreviewUrl: brandLogoMediaAsset ? brandLogoMediaAsset.mediaPreviewUrl : logoUrlFromLocation,
        logoName: brandLogoMediaAsset ? brandLogoMediaAsset.name : extractFilenameFromPath(logoUrlFromLocation),
        logoOrigin: brandLogoMediaAsset ? brandLogoMediaAsset.mediaOrigin : GqlClientMediaOrigin.SURROGATE
    };

    return activeBrandLogo;
};
