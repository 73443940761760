import React, { Component } from "react";
import PropTypes from "prop-types";
import "./HubContentEditable.css";
import { cloneDeep } from "lodash";
class HubContentEditable extends Component {
    constructor(props) {
        super(props);

        let { isValid, validMessage } = this.extractValidationResult(props.validateFunc, props.value);

        this.onChange = this._onChange.bind(this);
        this.onConfirm = this._onConfirm.bind(this);
        this.onCancel = this._onCancel.bind(this);
        this.onKeyDown = this._onKeyDown.bind(this);
        this.onFocus = this._onFocus.bind(this);
        this.onBlur = this._onBlur.bind(this);

        this.state = {
            isValid: isValid,
            validMessage: validMessage,
            value: props.value,
            initialValue: props.value
        };
    }

    extractValidationResult(validateFunc, value, prevVal) {
        let validationObj = validateFunc ? validateFunc(value, prevVal) : true;
        let isValid;
        let validMessage;
        if (typeof validationObj === "boolean") {
            isValid = validationObj;
        }
        else {
            isValid = validationObj.result;
            validMessage = validationObj.message;
        }

        return { isValid, validMessage };
    }

    componentDidMount() {
        if (this.props.isFocused && this.input) {
            this.input.focus();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let update = false;
        for (let prop in nextProps) {
            if (nextProps.hasOwnProperty(prop)) {
                update = update || nextProps[prop] !== this.props[prop];
            }
        }
        return update || this.state !== nextState;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isFocused && this.input) {
            this.input.focus();
        }
        if (!this.state.value) {
            this.setValue(nextProps.value, nextProps);
        }
        if (this.props.id !== nextProps.id) {
            this.setValue(nextProps.value, nextProps);
        }
        if (this.props.value !== nextProps.value) {
            this.setState({ initialValue: nextProps.value });
            this.setValue(nextProps.value, nextProps);
        }
    }

    setValue(val, props, cb) {
        this.setState({ value: val }, cb);
        if (props.validateFunc) {
            this.setState(this.extractValidationResult(props.validateFunc, val, this.state.initialValue));
        }
    }

    _onChange(e) {
        // editable content returns non breaking space on first and last spaces. replacing to regular space
        const nonBreakingSpace = String.fromCharCode(160);
        let replaceNBSP = new RegExp(nonBreakingSpace, "g");
        this.setValue(e.target.innerText.replace(replaceNBSP, " "), this.props);
    }

    _onCancel() {
        this.setState({ initialValue: undefined }, () => this.setState({ initialValue: this.props.value }));
        this.setValue(this.state.initialValue, this.props);

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    _onConfirm() {
        if (this.input) {
            this.input.blur();
        }
    }

    _onBlur(e, event, isCanceled) {
        if (this.state.value !== this.state.initialValue) {
            if (this.state.isValid && !isCanceled) {
                this.props.onConfirm(this.state.value, e);
            }
            else {
                this.onCancel();
            }
        }
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    _onFocus(e) {
        this.setState({ initialValue: this.props.value }, () => {
            if (this.props.onClick) {
                this.props.onClick(e);
            }
        });
    }

    // _calcInputWidth(value){
    //     if (!value){
    //         return '0';
    //     }
    //     return `${(value.length + 2) * this.props.fontSize}px`;
    // }

    _onKeyDown(e) {
        let preventDefault = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }
        };
        let isPrintableKey = e.key && e.key.length === 1;

        if (e.key === "Enter") {
            this.input.blur();
            if (this.props.onEnterClicked) {
                this.props.onEnterClicked();
            }
            preventDefault(e);
        }
        else if (e.key === "Escape") {
            this.onBlur({}, {}, true);
            preventDefault(e);
        }
        else if (this.props.inputRegExp && isPrintableKey) {
            let regexp = new RegExp(this.props.inputRegExp);
            if (!regexp.test(e.target.innerText + e.key)) {
                preventDefault(e);
            }
        }
    }

    render() {
        const valid = this.state.isValid ? "" : " invalid";
        const disabled = this.props.disabled ? "disabled" : "";
        const className = `hubContentEditable ${disabled} ${valid} ${this.props.className}`;
        // Remove gaps or unwanted spaces
        const formattedClassName = className
            .split(" ")
            .filter((t) => t)
            .join(" ");
        return (
            <span className={formattedClassName}>
                {this.props.showIcons && <div className={this.props.iconClass + "-ph-icon col-xs-1"} />}
                <div className="inline-block">
                    <div
                        data-testid={`hub-content-editable-${this.props.placeholder}`}
                        spellCheck="false"
                        id={this.props.id}
                        className={`contentEditable${this.props.disabled ? " disabled" : ""}`}
                        contentEditable={!this.props.disabled}
                        placeholder={this.props.placeholder}
                        onClick={this.onFocus}
                        onInput={this.onChange}
                        onKeyDown={this.onKeyDown}
                        suppressContentEditableWarning={true}
                        onBlur={this.onBlur}
                        ref={(elem) => (this.input = elem)}
                        onMouseEnter={this.props.onMouseEnter}
                        onMouseLeave={this.props.onMouseLeave}>
                        {this.state.initialValue}
                    </div>
                </div>
                {!!this.state.validMessage && <label>{this.state.validMessage}</label>}
                {this.props.children}
            </span>
        );
    }
}

HubContentEditable.defaultProps = {
    className: "",
    fontSize: 9
};

HubContentEditable.propTypes = {
    id: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onEnterClicked: PropTypes.func,
    validateFunc: PropTypes.func,
    value: PropTypes.string,
    children: PropTypes.any,
    placeholder: PropTypes.string,
    isFocused: PropTypes.bool,
    className: PropTypes.string,
    fontSize: PropTypes.number,
    showIcons: PropTypes.bool,
    iconClass: PropTypes.string,
    disabled: PropTypes.bool,
    inputRegExp: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};

export default HubContentEditable;
