import { useProgramVersionsAndBcCollectionsEditorChangedSubscription } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { getBrands, setBrandByAudienceFallbackId } from "../../Nooks";
import { localBrandByAudienceFallbackIdVar } from "../../State";
import { useEditorProgramId } from "../UseEditorProgramId";

export const useEditorProgramVersionAndBrandCollectionSubscription = (accountId: string, programVersionId: string): void => {
    const programId = useEditorProgramId();
    useProgramVersionsAndBcCollectionsEditorChangedSubscription({
        variables: { accountId },
        onData: (options) => {
            const editorProgramVersion =
                options.data.data.programVersionsAndBcCollectionsEditorChanged.editorProgramVersions.find(currEditorProgramVersion => currEditorProgramVersion.id === programVersionId);
            if (editorProgramVersion) {
                if (editorProgramVersion.bcUseRules && editorProgramVersion.bcRules?.fallback) {
                    const brandId = getBrands(programId, programVersionId).find(brand => brand.localId === editorProgramVersion.bcRules.fallback).id;
                    if (brandId !== localBrandByAudienceFallbackIdVar()) {
                        setBrandByAudienceFallbackId(brandId);
                    }
                }
                else if (!editorProgramVersion.bcUseRules && localBrandByAudienceFallbackIdVar()) {
                    setBrandByAudienceFallbackId(null);
                }
            }
        }
    });
};
