import { useReactiveVar } from "@apollo/client";
import { setSelectedPlaceholderOpenEdit } from "../../Nooks";
import { localEditorSelectedPlaceholderOpenEditVar } from "../../State";
import type { StandardSetterFn } from "../../types";

export type UseProjectSelectedPlaceholderOpenEdit = {
    projectSelectedPlaceholderOpenEdit: boolean;
    setProjectSelectedPlaceholderOpenEdit: StandardSetterFn<boolean>;
};

export const useProjectSelectedPlaceholderOpenEdit = (): UseProjectSelectedPlaceholderOpenEdit => {
    const projectSelectedPlaceholderOpenEdit = useReactiveVar(localEditorSelectedPlaceholderOpenEditVar);

    return { projectSelectedPlaceholderOpenEdit, setProjectSelectedPlaceholderOpenEdit: setSelectedPlaceholderOpenEdit };
};
