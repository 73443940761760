import type { RpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { getEmptyRpConfiguration } from "@sundaysky/landing-page-skeleton-types";
import { newNavVideoPagePath } from "@sundaysky/smartvideo-hub-urls";
import { getAspectRatio } from "../../../../../common/aspectRatioUtils";
import { SskyErrorCode } from "../../../../../common/errors";
import { PreviewContext, VideoSessionRetention } from "../../../../../common/types/restApiTypes";
import { getApolloClient } from "../../../../apollo";
import type { GqlClientEditorProgramForReviewPageBuilderQuery, GqlClientEditorProgramForReviewPageBuilderQueryVariables } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { EditorProgramForReviewPageBuilderDocument } from "../../../../graphql/graphqlGeneratedTypes/graphqlClient";
import { activeAccountIdVar } from "../../../model";
import { getCurrentFullStoryUrl } from "../../Utils";
import { getLandingPagePreviewUrl } from "../../Utils/landingPageServices";
import { closeNotification, handleEditorError, setErrorNotification, setNeutralNotification, setSuccessNotification } from "../Notification";


export async function getReviewPageConfiguration(
    programId: string,
    videoName: string,
    videoProfile?: RpConfiguration["main"]["videoProfile"]
): Promise<RpConfiguration> {
    const client = getApolloClient();
    const { data } = await client.query<
        GqlClientEditorProgramForReviewPageBuilderQuery,
        GqlClientEditorProgramForReviewPageBuilderQueryVariables
    >({
        query: EditorProgramForReviewPageBuilderDocument,
        variables: {
            programId
        }
    });
    const emptyConfiguration = getEmptyRpConfiguration();
    return {
        ...emptyConfiguration,
        header: {
            videoUrl: window.location.origin + newNavVideoPagePath({
                accountId: activeAccountIdVar(),
                videoId: programId
            }),
            videoName
        },
        main: {
            playerConfiguration: {
                ...emptyConfiguration.main.playerConfiguration,
                aspectRatio: getAspectRatio(data.editorProgram.programVersionDraft.aspectRatio).playerFormat(),
                posterImage: data.editorProgram.programVersionDraft.thumbnail && {
                    url: data.editorProgram.programVersionDraft.thumbnail.url,
                    platformId: data.editorProgram.programVersionDraft.thumbnail.url
                }
            },
            videoProfile
        }
    };
}

export async function createReviewLink(
    accountId: string,
    programId: string,
    programName: string,
    programVersionId: string,
    profileName: string,
    presets: Record<string, string>,
    sceneOrder?: string[]
): Promise<void> {
    try {
        setNeutralNotification({
            message: "Creating preview link...",
            duration: null
        });
        const configuration = await getReviewPageConfiguration(
            programId,
            programName,
            Object.keys(presets).length > 0
                ? { name: profileName, presets }
                : undefined
        );
        const { previewUrl } = await getLandingPagePreviewUrl(
            accountId,
            programId,
            {
                programVersionId,
                presets,
                retention: VideoSessionRetention.MONTH,
                configuration,
                fsUrl: getCurrentFullStoryUrl(),
                overridingFields: {
                    context: PreviewContext.DRAFT_REVIEWLINK
                },
                sceneOrder
            }
        );
        closeNotification();
        await navigator.clipboard.writeText(previewUrl);
        setSuccessNotification({
            message: "Link Copied - Will Expire in 30 Days",
            actionButton: {
                label: "Open page",
                onClick() {
                    window.open(previewUrl, "_blank");
                }
            }
        });
    }
    catch (error) {
        handleEditorError({
            error,
            sskyCode: SskyErrorCode.ReviewPageOutOfAppPreviewError
        });
        setErrorNotification({
            message: "Failed to create preview link",
            actionButton: {
                label: "Try Again",
                onClick: () => createReviewLink(
                    accountId,
                    programId,
                    programName,
                    programVersionId,
                    profileName,
                    presets
                )
            }
        });
    }
}
